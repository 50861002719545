export const AnswerTypeLabel: Record<string, string> = {
    atleast_one_correct: "At least 1 correct",
    atleast_2_correct: "At least 2 correct",
    atleast_3_correct: "At least 3 correct",
    atleast_4_correct: "At least 4 correct",
    atleast_5_correct: "At least 5 correct",
    all_correct: "All must be correct",
    contains: "Contains"
};

export const QuestionTypeLabel: Record<string, string> = {
    mcq: "MCQ",
    free_text: "Free Text",
    connector: "Connector",
    file_upload: "File Upload"
};

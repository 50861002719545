export const MusicMediumQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "Sorry, you're out of tune.",
            imageSrc: "https://i.imgur.com/TFlhuKC.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Not bad, you have an average score.",
            imageSrc: "https://i.imgur.com/FMlgGwT.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Hey now, you're a rockstar!",
            imageSrc: "https://i.imgur.com/HwtJL76.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "Niall, Louis, and Zayn were all in what band? ",
            questionImageSrc: "https://i.imgur.com/aDg1iq8.jpg",
            answerDescription: "Pop star Harry Styles is perhaps the most famous past member of this band.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. One Direction",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. BTS",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Westlife",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Backstreet Boys",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "Finish these lyrics: “I would do anything for love but____”",
            questionImageSrc: "https://i.imgur.com/HgReu5Z.jpg",
            answerDescription: "Did you know that Meat Loaf won a Grammy for 'I'd Do Anything for Love?'",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. not for you",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. I don’t want to",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. I won’t do that",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. it’s not wise to do that",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "What is unusual about the band “The Gorillaz”?",
            questionImageSrc: "https://i.imgur.com/BfzJwVK.jpg",
            answerDescription:
                "Did you know that they are credited by Guinness World Records for being the Most Successful Virtual Band?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. They are all children",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. They are all deaf",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. They don't sing; they only whistle",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. They are a virtual band",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "Who is the lead singer of “A-ha”?",
            questionImageSrc: "https://i.imgur.com/r8k8Hsy.jpg",
            answerDescription: "Did you know that A-ha was formed in Norway by Norwegian musicians?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Bono",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Axl Rose",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Malcolm Young",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Morten Harket",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Which of these songs did Elton John not sing?",
            questionImageSrc: "https://i.imgur.com/oNBjGHp.jpg",
            answerDescription: "The song Lay All Your Love on Me was written and recorded by ABBA.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Crocodile Rock",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Lay All Your Love on Me",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Nikita",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Sacrifice",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "Which singer states, “We Are Never Ever Getting Back Together”?",
            questionImageSrc: "https://i.imgur.com/jGt67NA.jpg",
            answerDescription:
                "This Taylor Swift song holds the title of “Fastest Selling Single in Digital History” by Guinness World Records.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Beyonce",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Lady Gaga",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Britney Spears",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Taylor Swift",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "For which movie did Pharrell record the song “Happy”?",
            questionImageSrc: "https://i.imgur.com/Yw2ztk0.jpg",
            answerDescription: "This upbeat single was the most successful song of 2014 in the U.S and U.K.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Minions",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Despicable Me 2",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Trolls",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. The Lego Movie",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "How many members does the group “BTS” have?",
            questionImageSrc: "https://i.imgur.com/N9b5Uzj.jpg",
            answerDescription:
                "'BTS' stands for 'Bangtan Sonyeondan', which translates to 'Bulletproof Boy Scouts' in English.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 9",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 4",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 7",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. 6",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "Which song does Vanilla Ice sample in “Ice Ice Baby”?",
            questionImageSrc: "https://i.imgur.com/lO3ordu.jpg",
            answerDescription:
                "Did you know that Vanilla Ice did not give writing credits to the songwriters of “Under pressure” when he released “Ice Ice Baby”?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Under Pressure",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Radio Ga Ga",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Cold As Ice",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Let's Dance",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "Who had a hit with “The Moon Represents My Heart”?",
            questionImageSrc: "https://i.imgur.com/E6QWWKt.jpg",
            answerDescription:
                "Although this song was written in the 1970s, it is still one of the most iconic Chinese songs.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Brenda Lee",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Teresa Teng",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. The Beatles",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Dusty Springfield",
                    isCorrect: false
                }
            ]
        }
    ]
};

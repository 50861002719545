import { Grid } from "@mui/material";
import { Formik } from "formik";
import { FC, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { FieldValidation } from "../../../../../constants/FieldValidation";
import LoadingButtonWrapper from "../../../../CommonComponent/v2/button/LoadingButtonWrapper";
import { CancelButton, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import { OptionType } from "../../../../CommonComponent/v2/fields/Type";
import { GameDetailType } from "./Type";
import AnswerSettingsForm from "./updategameform/AnswerSettingsForm";
import GameDetailsForm from "./updategameform/GameDetailsForm";
import GameIdentificationForm from "./updategameform/GameIdentificationForm";
import GameQuestionForm from "./updategameform/GameQuestionForm";
import GameTemplateForm from "./updategameform/GameTemplateForm";
import MoreSettingsForm from "./updategameform/MoreSettingsForm";
import { GameInitialValue, GameValidationSchema, displayErrors } from "./Util";
import ConfirmationBox from "../../../../CommonComponent/v2/ConfirmationBox";

type GameFormProps = {
    gameDetails?: GameDetailType;
    gameType: string;
    updateQuiz: (values: GameDetailType, setSubmitting: (isSubmitting: boolean) => void) => void;
    tagOptions: OptionType[];
    gameId: string;
};
const GameForm: FC<GameFormProps> = ({ gameDetails, gameType, updateQuiz, tagOptions, gameId }) => {
    const history = useHistory();
    const [openGlobalModal, setOpenGlobalModal] = useState(false);

    const timelimitValidationCheck = (values: GameDetailType, handleSubmit: () => void) => {
        if (
            (parseInt(values.gameDetails.timeLimit.minutes) === 0 &&
                parseInt(values.gameDetails.timeLimit.seconds) === 0) ||
            (isNaN(parseInt(values.gameDetails.timeLimit.minutes)) &&
                isNaN(parseInt(values.gameDetails.timeLimit.seconds))) ||
            !FieldValidation.quizTimeLimitValidation(
                parseInt(values.gameDetails.timeLimit.minutes),
                parseInt(values.gameDetails.timeLimit.seconds)
            )
        ) {
            toast.error("Please enter valid game time limit");
            return;
        }
        handleSubmit();
    };

    return (
        <Grid container>
            <Formik
                initialValues={GameInitialValue(gameType, gameDetails)}
                enableReinitialize
                onSubmit={(values, { setSubmitting }) => {
                    updateQuiz(values, setSubmitting);
                }}
                validationSchema={GameValidationSchema}>
                {({ values, isSubmitting, handleSubmit, errors }) => (
                    <Grid item xs={12}>
                        <Grid container maxWidth={"576px"}>
                            <Grid item xs={12}>
                                <GameIdentificationForm />
                            </Grid>
                            <Grid item xs={12}>
                                <GameDetailsForm />
                            </Grid>
                            <Grid item xs={12}>
                                <GameQuestionForm gameId={gameId} gameType={gameType} tagOptions={tagOptions} />
                            </Grid>
                            <Grid item xs={12}>
                                <AnswerSettingsForm />
                            </Grid>
                            <Grid item xs={12}>
                                <MoreSettingsForm gameType={gameType} />
                            </Grid>
                            <Grid item xs={12}>
                                <GameTemplateForm />
                            </Grid>
                            <Grid my={"80px"} item xs={10}>
                                <Grid container alignItems={"center"} spacing={"20px"} justifyContent={"center"}>
                                    <Grid item>
                                        <CancelButton
                                            onClick={() =>
                                                history.push(`/admin/v2/gamelibrary/${gameId}`, {
                                                    gameType: gameType
                                                })
                                            }>
                                            Cancel
                                        </CancelButton>
                                    </Grid>
                                    <Grid item>
                                        <LoadingButtonWrapper loading={isSubmitting}>
                                            <PrimaryButton
                                                onClick={() => {
                                                    if (Object.keys(errors).length !== 0) {
                                                        displayErrors(errors);
                                                        return;
                                                    }
                                                    if (values.gameIdentification.visibilityType === "global") {
                                                        setOpenGlobalModal(true);
                                                        return;
                                                    }
                                                    timelimitValidationCheck(values, handleSubmit);
                                                }}
                                                style={{ padding: "16px 18px" }}>
                                                Save game
                                            </PrimaryButton>
                                        </LoadingButtonWrapper>
                                    </Grid>
                                    {openGlobalModal && (
                                        <ConfirmationBox
                                            type={"update"}
                                            title={`Save Game`}
                                            message={`${
                                                values.gameIdentification.visibilityType === "global" &&
                                                "This game will be globally available to all identities. Are you sure?"
                                            }
                                        `}
                                            onConfirm={() => {
                                                timelimitValidationCheck(values, handleSubmit);
                                            }}
                                            onClose={() => setOpenGlobalModal(false)}
                                            isLoading={isSubmitting}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </Grid>
    );
};

export default GameForm;

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { EventType } from "../addeventdetail/Type";
import { getEventDetails, updateEventDetails } from "./Util";

export const useEditEventDetailHook = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [eventDetails, setEventDetails] = useState<EventType | undefined>();
    const history = useHistory();
    const { id } = useParams<{
        id: string;
    }>();

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            getEventDetails(id)
                .then((res) => {
                    setEventDetails(res);
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => setIsLoading(false));
        }
    }, []);

    const saveData = (values: EventType, setIsSubmitting: (value: boolean) => void) => {
        setIsSubmitting(true);
        if (id) {
            updateEventDetails(id, values)
                .then(() => {
                    toast.success("Event updated", SiteConstants.successToastConfiguration);
                    history.push(`/admin/event/${id}/info`);
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => setIsSubmitting(false));
            return;
        }
    };

    return {
        eventDetails,
        id,
        isLoading,
        history,
        saveData
    };
};

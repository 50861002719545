import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { EditReferenceType } from "./Type";
import { getReferenceDetails, updateReferenceDetails } from "./Utils";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { useAdminContext } from "../../../context/AdminAuthContext";
import { getPermissions } from "../../../../../utils/PermissionUtils";

export const useEditReference = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState("details");
    const [referenceDetails, setReferenceDetails] = useState<EditReferenceType | undefined>();
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "referenceList");

    const { id } = useParams<{
        id: string;
    }>();
    const Id: number = parseInt(id);
    useEffect(() => {
        if (id) {
            setIsLoading(true);
            getReferenceDetails(Id)
                .then((res) => setReferenceDetails(res))
                .catch((error) => toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration))
                .finally(() => setIsLoading(false));
        }
    }, []);

    const saveData = (values: EditReferenceType, setIsSubmitting: (value: boolean) => void) => {
        setIsSubmitting(true);
        if (id) {
            updateReferenceDetails(Id, values)
                .then(() => {
                    toast.success("Reference updated", SiteConstants.successToastConfiguration);
                    history.push(`/admin/v2/referencelistmanager`);
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => setIsSubmitting(false));
            return;
        }
    };
    return {
        selectedTab,
        referenceDetails,
        isLoading,
        setIsLoading,
        setSelectedTab,
        setReferenceDetails,
        saveData,
        permission
    };
};

import { Grid } from "@mui/material";
import { FC } from "react";
import { Modal } from "react-bootstrap";
import { darkPrimaryColor } from "../../../../constants/ColorConstant";
import { CaptionText, Header1 } from "../../../CommonComponent/v2/CommonStyle";
import LoadingWrapper from "../../../CommonComponent/v2/LoadingWrapper";
import { useLivePerformanceHook } from "./Hooks";
import { LiveTeamPerformanceModal } from "./Style";

type LiveTeamPerformanceProps = {
    handleClose: () => void;
};
const LiveTeamPerformance: FC<LiveTeamPerformanceProps> = ({ handleClose }) => {
    const { liveteamPerformance, isLoading } = useLivePerformanceHook();

    const renderPerformance = () => {
        if (!liveteamPerformance) {
            return (
                <Grid container justifyContent={"center"}>
                    <Grid item>
                        <CaptionText>No data found</CaptionText>
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid container spacing={"20px"}>
                <Grid item xs={3}>
                    <CaptionText color={darkPrimaryColor} fontSize={16} fontWeight={400}>
                        Team name
                    </CaptionText>
                    <CaptionText mt={"10px"} fontSize={14} fontWeight={400}>
                        {liveteamPerformance.teamName}
                    </CaptionText>
                </Grid>
                <Grid item xs={3}>
                    <CaptionText color={darkPrimaryColor} fontSize={16} fontWeight={400}>
                        Question answer
                    </CaptionText>
                    <CaptionText mt={"10px"} fontSize={14} fontWeight={400}>
                        {liveteamPerformance.totalQuestionAnswers}
                    </CaptionText>
                </Grid>
                <Grid item xs={3}>
                    <CaptionText color={darkPrimaryColor} fontSize={16} fontWeight={400}>
                        Correct answer
                    </CaptionText>
                    <CaptionText mt={"10px"} fontSize={14} fontWeight={400}>
                        {liveteamPerformance.totalCorrectAnswers}
                    </CaptionText>
                </Grid>
                <Grid item xs={3}>
                    <CaptionText color={darkPrimaryColor} fontSize={16} fontWeight={400}>
                        Wrong answer
                    </CaptionText>
                    <CaptionText mt={"10px"} fontSize={14} fontWeight={400}>
                        {liveteamPerformance.totalWrongAnswers}
                    </CaptionText>
                </Grid>
            </Grid>
        );
    };

    return (
        <LiveTeamPerformanceModal show={true} size="lg" centered>
            <Modal.Header closeButton onClick={handleClose}>
                <Header1 fontsize="24px">Live team performance</Header1>
            </Modal.Header>
            <Modal.Body
                style={{
                    height: 300
                }}>
                <LoadingWrapper loadingHeight={"300px"} isLoading={isLoading}>
                    {renderPerformance()}
                </LoadingWrapper>
            </Modal.Body>
        </LiveTeamPerformanceModal>
    );
};

export default LiveTeamPerformance;

import { useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../../../utils/APIErrorMessages";
import { EventCodeListType } from "../Type";
import { editCodeUtil } from "./Util";
import { useState } from "react";

export const useEditCode = () => {
    const { id } = useParams<{
        id: string;
    }>();
    const [loading, setLoading] = useState(false);
    const editCode = (values: EventCodeListType, refreshList: () => void) => {
        setLoading(true);
        editCodeUtil(values, id)
            .then(() => {
                toast.success("Code updated", SiteConstants.successToastConfiguration);
                refreshList();
            })
            .catch((error) => toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration))
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        editCode,
        loading
    };
};

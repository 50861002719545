import { Grid } from "@mui/material";
import { FC } from "react";
import SearchHeader from "../../../CommonComponent/v2/searchheader/SearchHeader";
import GameDeleteDialog from "../gamelibrary/GameDeleteDialog";
import { useTagsListView } from "./Hooks";
import TagList from "./TagList";
import UpdateTagModal from "./UpdateTagModal";
import { filteredTagList } from "./Util";

type TagsListViewProps = {
    refreshTagList: () => void;
};
const TagsListView: FC<TagsListViewProps> = ({ refreshTagList }) => {
    const {
        selectedDeleteTag,
        selectedEditTag,
        setSelectedDeleteTag,
        setSelectedEditTag,
        searchText,
        setSearchText,
        isLoading,
        tagList,
        usageList,
        handleTagDelete,
        fetchTagList
    } = useTagsListView(refreshTagList);

    return (
        <Grid container>
            <Grid item xs={12} mt={"30px"}>
                <SearchHeader
                    searchText={searchText}
                    setSearchText={setSearchText}
                    showAddButton
                    onButtonClick={() =>
                        setSelectedEditTag({
                            id: -1,
                            tagName: ""
                        })
                    }
                    buttonLabel={"Add new tag"}
                />
            </Grid>
            <Grid item xs={12} mt={"30px"}>
                <TagList
                    setSelectedDeleteTag={setSelectedDeleteTag}
                    setSelectedEditTag={setSelectedEditTag}
                    loading={isLoading.listLoading}
                    tagList={filteredTagList(tagList, searchText)}
                />
            </Grid>
            {selectedEditTag && (
                <UpdateTagModal
                    refreshList={fetchTagList}
                    tagInfo={selectedEditTag}
                    allTags={tagList}
                    handleClose={() => setSelectedEditTag(null)}
                />
            )}
            {selectedDeleteTag && (
                <GameDeleteDialog
                    usageList={usageList}
                    title={`Delete "${selectedDeleteTag.tagName}" tag`}
                    message={
                        usageList.length === 0
                            ? [
                                  `
                        Are you sure you want to delete ${selectedDeleteTag.tagName} tag
                        `
                              ]
                            : [
                                  `
                        The tag "${selectedDeleteTag.tagName}" is being used in the following challenges. Deleting this tag will remove it from all challenges.`
                              ]
                    }
                    showDeleteButton
                    onConfirm={handleTagDelete}
                    onClose={() => setSelectedDeleteTag(null)}
                    isLoading={isLoading.usageListLoading}
                />
            )}
        </Grid>
    );
};

export default TagsListView;

import { differenceBy } from "lodash";
import { object, ref, string } from "yup";
import { ApiConstants } from "../../../../../constants/ApiConstant";
import { Client } from "../../../../Base/Client";
import { dropDownType } from "../../../EventManager/EventDetails/utils/type";
import { RoleType } from "../../../Roles/Type";
import { UserDetailType, UserFormDetailType } from "../Type";

export const userDetailFormInitialValues: UserFormDetailType = {
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
    identityId: ""
};

export const userDetailPasswordInitialValues = {
    email: "",
    password: "",
    confirmPassword: ""
};

export const ChangePasswordValidationSchema = object().shape({
    password: string()
        .required("Password is Mandatory")
        .min(8, "Minimum 8 character")
        .max(64, "Maximum 64 character")
        .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
        .matches(/[a-z]/, "Password must contain at least 1 lowercase letter")
        .matches(/[0-9]/, "Password must contain at least 1 number")
        .matches(/[!@#$%^&*]/, "Password must contain at least 1 special character")
        .required("Password is Mandatory"),
    confirmPassword: string()
        .required("Confirm password is required")
        .oneOf([ref("password"), null], "Passwords must match")
});

export const UserDetailValidationSchema = () =>
    object().shape({
        email: string().email("Please enter correct email id").required("Email is Mandatory"),
        firstName: string().required("First Name is Mandatory")
    });

export const transformIdentityDropdown = (data: any) => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        value: item.id,
        label: item.name
    }));
};

const transformDetailsData = (data: any): UserDetailType => ({
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    identityId: data.identity.id
});

export const getSpecificUserdetail = (userId: number, forceRefresh = false): any =>
    Client.getInstance()
        .getData(ApiConstants.getUserDetailApiUrl(userId), forceRefresh)
        .then((res) => Promise.resolve(transformDetailsData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const addUser = (value: UserDetailType): any =>
    Client.getInstance()
        .createData(ApiConstants.addUserDetailApiUrl(), {
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            identityId: value.identityId
        })
        .then((res) => Promise.resolve(res.data.data.id))
        .catch((err) => Promise.reject(err));

export const updateUser = (value: UserDetailType, userId: number): any =>
    Client.getInstance()
        .updateData(ApiConstants.updateUserDetailApiUrl(userId), {
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            identityId: value.identityId,
            status: "active"
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const getChangeUserPassword = (email: string, password: string) =>
    Client.getInstance()
        .updateData(ApiConstants.changeUserPasswordApiUrl(), {
            email: email,
            password: password
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const tranformRoleDropdown = (list: RoleType[]): dropDownType[] => {
    if (!list) {
        return [];
    }
    return list.map((item: RoleType) => ({
        value: item.id,
        label: item.name
    }));
};

const transformUserRole = (data: any): RoleType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any, index: number) => ({
        index: index + 1,
        id: item.roleId,
        name: item.role.name
    }));
};
export const getUserRolesList = (userId: number): Promise<any> =>
    Client.getInstance()
        .getData(ApiConstants.getUserRoleApiUrl(userId), true)
        .then((res) => Promise.resolve(transformUserRole(res.data.data)))
        .catch((err) => Promise.reject(err));

export const filterSelectedRoleDropdown = (rolesList: dropDownType[], selectedRoles: dropDownType[]) => {
    if (!rolesList || !selectedRoles) {
        return [];
    }
    return differenceBy(rolesList, selectedRoles, "value");
};

export const addRolesToUserList = (userId: number, userRoles: dropDownType[]): Promise<any> =>
    Client.getInstance()
        .createData(ApiConstants.addUserRoleApiUrl(userId), {
            roleIds: userRoles.map((r) => r.value).join(";")
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

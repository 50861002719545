import { CircularProgress, Drawer, Grid } from "@mui/material";
import { IdentityListtype } from "../Type";
import { Header1, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import FormikTextInput from "../../../../CommonComponent/v2/fields/FormikTextInput";
import { useUpdateCredit } from "../Hooks";
import { Formik } from "formik";
import { updateCreditValidationSchema } from "../Util";

type UpdateCreditDrawerProps = {
    handleClose: () => void;
    selectedIdentity: IdentityListtype;
    refreshData: () => void;
};
const UpdateCreditDrawer = ({ handleClose, selectedIdentity, refreshData }: UpdateCreditDrawerProps) => {
    const { onUpdateCredit, loading } = useUpdateCredit(selectedIdentity, refreshData);
    return (
        <Drawer
            anchor={"right"}
            open
            PaperProps={{
                style: {
                    width: "30%"
                }
            }}
            onClose={handleClose}>
            <Grid container p={"30px"}>
                <Header1>Update Credits</Header1>
                <Formik
                    initialValues={{
                        credit: 0
                    }}
                    validationSchema={updateCreditValidationSchema}
                    onSubmit={onUpdateCredit}
                    validateOnMount={true}
                    enableReinitialize={true}>
                    {({ handleSubmit }) => (
                        <Grid container mt={"40px"}>
                            <Grid item xs={12}>
                                <FormikTextInput id={"credit"} name={"credit"} label={"Credit"} fullWidth />
                            </Grid>
                            <Grid item xs={12} mt={"20px"}>
                                <PrimaryButton
                                    disabled={loading}
                                    startIcon={loading && <CircularProgress size={"14px"} />}
                                    fullWidth
                                    onClick={() => handleSubmit()}>
                                    Update Credit
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Grid>
        </Drawer>
    );
};

export default UpdateCreditDrawer;

import { Grid } from "@mui/material";
import { SelectedPaymentPlanInfoType } from "./type";
import { useState } from "react";
import CardDetailModal from "./CardDetailModal";
import BasicPlanCardView from "./BasicPlanCardView";
import PremiumPlanCard from "./PremiumPlanCard";
import { BASIC_PLAN_AMOUNT } from "../../../../constants/SiteConstant";

const SubscriptionView = () => {
    const [openPaymentModal, setOpenPaymentModal] = useState<SelectedPaymentPlanInfoType | null>(null);

    const handlePayment = async () => {
        setOpenPaymentModal(null);
    };

    return (
        <>
            <Grid
                container
                style={{ padding: "0px 40px" }}
                columnSpacing={"30px"}
                alignItems={"stretch"}
                justifyContent={"center"}>
                <Grid item xs={12} md={5}>
                    <BasicPlanCardView
                        handlePaymentClick={(credit) =>
                            setOpenPaymentModal({
                                credit,
                                plan: "basic",
                                price: BASIC_PLAN_AMOUNT
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <PremiumPlanCard />
                </Grid>
                {openPaymentModal && (
                    <CardDetailModal
                        paymentComplete={handlePayment}
                        planInfo={openPaymentModal}
                        handleClose={() => setOpenPaymentModal(null)}
                    />
                )}
            </Grid>
        </>
    );
};

export default SubscriptionView;

import Styled from "@emotion/styled";
import { Button, Grid, Paper, SvgIcon, Typography } from "@mui/material";

type ButtonProps = {
    background?: string;
    disabled?: boolean;
    textcolor?: string;
    height?: number;
    fontWeight?: number;
    fontsize?: string;
};
export const PrimaryButton = Styled(Button)<ButtonProps>`
    background: ${(props) => props.background || "#2F80ED"};
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    padding: 16px 40px;
    font-weight: 700;
    font-size: ${(props) => props.fontsize || "12px"};
    line-height: 18px;
    text-align: center;
    color: #FCFCFC;
    height: 100%;
    outline: none !important;
    padding: 16px 57px;
    text-transform: capitalize;
    ${(props) =>
        props.disabled &&
        `
    background:#C2C2C2;
    color: #828282;
    `}
    &:hover {
        opacity: 0.8;
        background: ${(props) => props.background || "#2F80ED"};
        ${(props) =>
            props.disabled &&
            `
            background:#C2C2C2;
            color: #828282;
        `}
    }
`;

export const PrimaryOutlinedButton = Styled(Button)<ButtonProps>`
    background: ${(props) => props.background || "#FCFCFC"};
    border: 1px solid ${(props) => props.textcolor || "#1F71B6"};
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: ${(props) => props.fontsize || "12px"};
    line-height: 18px;
    outline: none !important;
    text-transform: capitalize;
    text-align: center;
    color: ${(props) => props.textcolor || "#1F71B6"};
    padding: 16px 57px;
`;

export const CancelButton = Styled(Button)<ButtonProps>`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: ${(props) => props.fontsize || "12px"};
    line-height: 18px;
    text-align: center;
    color: #EB5757;
    &:hover {
        opacity: 0.8;
        background: #ffff;
    }
`;

export const TextButton = Styled(Button)<ButtonProps>`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: ${(props) => props.fontsize || "12px"};
    text-transform: none;
    display: flex;
    outline: none !important;
    gap: 10px;
    align-items: center;
    line-height: 18px;
    color: #1F71B6;
`;

type TextProps = {
    fontWeight?: string | number;
    color?: string;
    fontSize?: number;
};
export const CaptionText = Styled(Typography)<TextProps>`
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: ${(props) => props.fontWeight || 400} !important;
    font-size: ${(props) => props.fontSize || 16}px !important;
    line-height: 18px !important;
    color: ${(props) => props.color || "#828282"} !important;
`;

export const EntryContainer = Styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: 10px;
`;

type Header1Props = {
    fontsize?: string;
};
export const Header1 = Styled(Typography)<Header1Props>`
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: ${(props) => props.fontsize || "28px"} !important;
    line-height: 36px !important;
    color: #333333 !important;
`;

export const Header2 = Styled(Typography)<Header1Props>`
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: ${(props) => props.fontsize || "22px"} !important;
    line-height: 27px !important;
    color: #333333 !important;
`;

export const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 16px;
  margin: 12px;
`;

type SvgWrapperProps = {
    additionalstyles?: any;
};
export const SvgWrapper = Styled(SvgIcon)<SvgWrapperProps>`
  ${(props) => props.additionalstyles}
`;

export const CardContainer = Styled(Paper)`
    background: #FCFCFC;
    border: 1px solid #C2C2C2;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
`;

export const IconContainer = Styled.img`
    cursor: pointer;
`;

export const SubText = Styled.span<TextProps>`
    font-family: 'Poppins';
    font-weight: ${(props) => props.fontWeight || "normal"};
    color: ${(props) => props.color || "#828282"};
    font-size: ${(props) => props.fontSize || 16}px;
`;

export const StyledTag = Styled.div`
    background: rgba(31, 113, 182, 0.1);
    border-radius: 10px;
    padding: 5px 16px;
`;

type ProgressProps = {
    progressColor?: string;
    height?: string;
    width?: string;
};
export const StyledCircularProgress = Styled.span<ProgressProps>`
    & .MuiCircularProgress-root {
        color: ${(props) => props.progressColor || "#2F80ED"} !important;
        height: ${(props) => props.height || "40px"} !important;
        width: ${(props) => props.width || "40px"} !important;
    }
`;

export const StyledVerticalStepper = Styled.div`
 & .MuiSvgIcon-root {
    font-size: 36px;
 }
 & .MuiStepConnector-line {
    margin-left: 6px;
 }
`;

export const SuccessTextfieldButton = Styled(Button)<ButtonProps>`
    background: ${(props) => props.background || "#EDF5EA"};
    border-radius: 10px; 
    font-family: 'Poppins';
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 400};
    text-transform: capitalize;
    font-size: 12px;
    height: ${(props) => `${props.height}px` || "100%"};
    line-height: 18px;
    color: #65B44A;
    border-color: #65B44A;
`;

export const ErrorTextfieldButton = Styled(SuccessTextfieldButton)`
    background: ${(props) => props.background || "#FAECEC"};
    color: #EB5757;
    border-color: #EB5757;
`;

export const CheckingTextfieldButton = Styled(SuccessTextfieldButton)`
    background: #e7edf5;
    color: #2f81ed;
`;

export const StyledTag2 = Styled(Grid)`
    background: #E6EEf5;
    border-radius: 10px;
    padding: 16px 20px;
    display: flex;
    gap: 20px;
`;

import { object, string } from "yup";
import { Client } from "../../Base/Client";
import { ApiConstants } from "../../../constants/ApiConstant";
import { ForgotPasswordUserType } from "./type";

export const forgotPasswordValidation = object().shape({
    email: string().email("Please enter correct email").required("Please enter email")
});

export const forgotPasswordInitialValue = {
    email: ""
};

export const forgotPasswordUserEmail = (userData: ForgotPasswordUserType) => {
    return Client.getInstance()
        .createData(ApiConstants.forgotPasswordApiUrl(), {
            email: userData.email
        })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

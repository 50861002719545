import { checkLinkStatusAction, removeReferenceCoverImage, removeReferenceDecriptionImage } from "./Utils";
import { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../constants/SiteConstant";

export const useLaunchReferenceHook = () => {
    const [availableType, setAvailableType] = useState<string>("notchecked");
    const [openQrModal, setOpenQrModal] = useState("");
    const [isChecking, setIsChecking] = useState<boolean>(false);
    const { id } = useParams<{
        id: string;
    }>();

    const checkLinkStatus = (newLinkName: string) => {
        setIsChecking(true);
        checkLinkStatusAction(newLinkName)
            .then(() => {
                setAvailableType("notavailable");
            })
            .catch(() => {
                setAvailableType("available");
            })
            .finally(() => setIsChecking(false));
    };

    const handleRemoveImage = (type: string): void => {
        if (!id) {
            return;
        }
        if (type === "cover") {
            removeReferenceCoverImage(parseInt(id)).then(() => {
                toast.success("Cover image removed", SiteConstants.successToastConfiguration);
            });
            return;
        }

        removeReferenceDecriptionImage(parseInt(id)).then(() => {
            toast.success("Cover image removed", SiteConstants.successToastConfiguration);
        });
    };

    return {
        availableType,
        setAvailableType,
        openQrModal,
        setOpenQrModal,
        checkLinkStatus,
        isChecking,
        handleRemoveImage
    };
};

import Style from "@emotion/styled";
import { redColor, purpleColor } from "../../../../constants/Styles";

export const SmallText = Style.p`
margin:0;
padding:0;
font-size: 16px;
color : ${redColor};
font-weight: bold;
text-align: center;
`;

export const ScoreText = Style.p`
color: ${redColor};
margin: 10px 0;
padding:0;
text-align:center;
font-size: 42px;
font-weight: bold;
`;

export const DescriptionText = Style.p`
margin:0;
padding:0;
padding-right: 20px;
font-size: 16px;
color : ${purpleColor};
`;

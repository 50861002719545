import { FC } from "react";
import { CircularProgress, Grid } from "@mui/material";

type LoadingButtonWrapperProps = {
    loading: boolean;
    children: JSX.Element;
};

const LoadingButtonWrapper: FC<LoadingButtonWrapperProps> = ({ loading, children }) => {
    if (loading) {
        return (
            <Grid container justifyContent={"center"}>
                <CircularProgress />
            </Grid>
        );
    }

    return <>{children}</>;
};

export default LoadingButtonWrapper;

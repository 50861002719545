export class HelperFunctions {
    public static capitalizeFirstLetter = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

    public static getFileType = (fileName: string): string => {
        const fileType = fileName.split(".");
        return fileType[fileType.length - 1].toLowerCase();
    };

    public static replaceString = (totalMarks: string, answerPoints: string, text: string) => {
        const replaceStringObject: any = {
            "{score}": totalMarks,
            "{questionscore}": answerPoints
        };
        return text.replace(/{score}|{questionscore}/gi, (matched: string) => replaceStringObject[matched]);
    };
}

export const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getFileName = (fileName: string) => {
    const fileNameArray = fileName.split("_");
    fileNameArray.shift();
    return fileNameArray.join("_");
};

export const downloadFileFromUrl = (filePath: string) => {
    window.open(filePath, "_blank");
    fetch(filePath)
        .then(response => response.blob())
        .then(blob => {
            const blobUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.download = filePath.replace(/^.*[\\\\/]/, '');
            a.href = blobUrl;
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
};

import React from "react";
import { Stack } from "@fluentui/react";
import { Button, Col, Row } from "react-bootstrap";
import { MCQAnswerOption } from "../../../../data/question/types";
import { setOptionButtonClass } from "./util/util";

interface McqQuestionViewProps {
    mcqOptions: MCQAnswerOption[];
    isQuestionTimeEnds: boolean;
    disableAnswerSection: boolean;
    answerSelected: (id: string) => void;
    participantAnswer: string;
    selectedOptions: string[];
    isSubmitDisable: boolean;
    showSkipButton: boolean;
    isAnswerSubmitted: boolean;
    answerGivenByParticipant: () => void;
    nextQuestion: () => void;
    optionColor: string;
    isVideoPlayed: boolean;
    isVideoQuestion: boolean;
}

const McqQuestionView: React.FC<McqQuestionViewProps> = ({
    answerGivenByParticipant,
    nextQuestion,
    isAnswerSubmitted,
    showSkipButton,
    isSubmitDisable,
    selectedOptions,
    participantAnswer,
    answerSelected,
    disableAnswerSection,
    isQuestionTimeEnds,
    mcqOptions,
    optionColor
}) => {
    return (
        <Stack>
            <Row>
                {mcqOptions &&
                    mcqOptions.map((item: MCQAnswerOption) => {
                        return (
                            <Col
                                md={3}
                                key={`${item.id}-col`}
                                className={
                                    isQuestionTimeEnds || disableAnswerSection || isAnswerSubmitted
                                        ? "pointer-event-none"
                                        : undefined
                                }>
                                <Button
                                    style={{ backgroundColor: optionColor }}
                                    className={setOptionButtonClass(selectedOptions, item.id.toString())}
                                    key={`${item.id}-button`}
                                    onClick={() => answerSelected(item.id.toString())}>
                                    {item.text}
                                </Button>
                            </Col>
                        );
                    })}
                <Col md={3}>
                    <Button
                        disabled={
                            participantAnswer === "" || isQuestionTimeEnds || disableAnswerSection || isSubmitDisable
                        }
                        className={
                            participantAnswer === ""
                                ? "pointer-event-none submit-button-option"
                                : "submit-button-option cursor"
                        }
                        onClick={answerGivenByParticipant}>
                        Submit
                    </Button>
                </Col>
            </Row>
            <Stack horizontal horizontalAlign={"space-between"}>
                {showSkipButton && !isAnswerSubmitted && !isQuestionTimeEnds && (
                    <Stack className={"margin-top-10"}>
                        <Button className={"answer-submit"} onClick={nextQuestion}>
                            Skip question
                        </Button>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default McqQuestionView;

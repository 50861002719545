import { Grid } from "@mui/material";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { useRefrenceListHook } from "./Hooks";
import { FC } from "react";
import { ReferenceListViewItem, ReferenceDeleteType, ReferenceCloneType } from "./Types";
import { useHistory } from "react-router";

type RefrenceListProps = {
    refrenceList: ReferenceListViewItem[];
    isLoading: boolean;
    setCloneReferenceId: (id: ReferenceCloneType) => void;
    setSelectedReferenceDeleteId: (id: ReferenceDeleteType) => void;
};

const RefrenceList: FC<RefrenceListProps> = ({
    isLoading,
    refrenceList,
    setCloneReferenceId,
    setSelectedReferenceDeleteId
}) => {
    const { refrenceListColumns } = useRefrenceListHook();
    const history = useHistory();
    return (
        <Grid height={"calc(100vh - 230px)"}>
            <DataGridWrapper
                columns={refrenceListColumns(setCloneReferenceId, setSelectedReferenceDeleteId, (id) => {
                    history.push(`/admin/v2/referencelistmanager/${id}/update`);
                })}
                rows={refrenceList as any}
                loading={isLoading}
            />
        </Grid>
    );
};

export default RefrenceList;

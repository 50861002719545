export const MusicQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "Sorry, you're way off key.",
            imageSrc: "https://i.imgur.com/TFlhuKC.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Not bad, you have an average score.",
            imageSrc: "https://i.imgur.com/FMlgGwT.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Hey now, you're a rockstar!",
            imageSrc: "https://i.imgur.com/HwtJL76.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "Which actor has hit songs like “Miami” and “Switch”?",
            questionImageSrc: "https://i.imgur.com/mn3DHhS.jpg",
            answerDescription:
                "Did you know that Will Smith won four Grammys in total? He won two for his solo music and two with DJ Jazzy Jeff ",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Jennifer Lopez",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Will Smith",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Queen Latifah",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Justin Bieber",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "Both Adele and Lionel Richie have a hit song titled ______.",
            questionImageSrc: "https://i.imgur.com/aw60yWm.jpg",
            answerDescription:
                "Did you know that both songs topped the Billboard charts, and Adele’s “Hello” won the Song of the Year award at the Grammys?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Hello",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Someone Like You",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Rumour Has It",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Easy on Me",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Name the legendary lead singer of Queen.",
            questionImageSrc: "https://i.imgur.com/9BN3Xl9.jpg",
            answerDescription:
                "Did you know that Freddie Mercury was born in an African state formerly known as Zanzibar?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Sting",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Liam Gallagher",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Mick Jagger",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Freddie Mercury",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "Which singer’s hits include “Back to Black” and “Valerie”?",
            questionImageSrc: "https://i.imgur.com/YsCIj9I.jpg",
            answerDescription: "Amy Winehouse won both a Grammy and a BRIT award for her album Back to Black.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Fergie",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Amy Winehouse",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Dua Lipa",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Leona Lewis",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Which one of the Beatles wrote, “Here Comes the Sun”?",
            questionImageSrc: "https://i.imgur.com/Rw7aiKF.jpg",
            answerDescription:
                "Did you know that Harrison began writing the lyrics of “Here Comes the Sun” in England, and completed them a couple months later in Italy?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. John Lennon",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Paul McCartney",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Ringo Starr",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. George Harrison",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "Which British singer sang the hit “It’s Not Unusual”?",
            questionImageSrc: "https://i.imgur.com/nfVZQBF.jpg",
            answerDescription: "This chart-topping song was Sir Tom Jones’ second single.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Chris Martin",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Tom Jones",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Eric Clapton",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Frank Sinatra",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "Which Coldplay song starts “When you try your best but….”?",
            questionImageSrc: "https://i.imgur.com/RnPjum0.jpg",
            answerDescription:
                "The lyrics of the song originated from lead singer Chris Martin’s relationship with Gwyneth Paltrow.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Yellow",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Viva la Vida",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Fix You",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. My Universe",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Candle in the Wind was originally written in tribute to ______.",
            questionImageSrc: "https://i.imgur.com/yIEG8xv.jpg",
            answerDescription: "The original version of this international hit was written in 1973.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Billie Holiday",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Marilyn Monroe",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Princess Diana",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Quentin Crisp",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "Which country is Rihanna from?",
            questionImageSrc: "https://i.imgur.com/tJbnFfw.jpg",
            answerDescription:
                "The Order of National Heroes, Barbados’ highest honour, was bestowed on Rihanna in 2021",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Barbados",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. South Africa",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Canada",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. The Bahamas",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "What song did Run-DMC and Aerosmith collaborate on?",
            questionImageSrc: "https://i.imgur.com/hXJKfo4.jpg",
            answerDescription:
                "This single is iconic because it is considered the pioneering song of the rap-rock genre.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. It’s Tricky",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. King of Rock",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Walk This Way",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Love to Love You Baby",
                    isCorrect: false
                }
            ]
        }
    ]
};

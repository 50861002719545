import { Stack } from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import {
    FREE_TEXT_DOES_NOT_CONTAINS,
    FREE_TEXT_CONTAINS,
    FREE_TEXT_EXACT_MATCH,
    NON_SCORING_QUESTION,
    CORRECT_ANSWER_COUNT,
    WRONG_ANSWER_COUNT,
    QUESTION_TIMER_TYPE,
    ALL_CORRECT,
    ATLEAST_ONE_CORRECT,
    ATLEAST_TWO_CORRECT,
    ATLEAST_THREE_CORRECT,
    ATLEAST_FOUR_CORRECT,
    ATLEAST_FIVE_CORRECT,
    CONNECTOR,
    CONNECTOR_SKIP
} from "../../../data/question/types";
import tool from "../../Images/tool.png";
import { difference, intersection } from "lodash";
import { answerType } from "./RocketTemplateUtils/RocketTemplateTypes";
import Timer from "../../QuizView/Timer";
import { Button } from "react-bootstrap";
import { FieldValidation } from "../../../constants/FieldValidation";
import ScrollDownControl from "./ScrollDownControl";
import { ThemedMediumStackTokens } from "../../../constants/Styles";
import { HelperFunctions } from "../../../constants/HelperFns";
import { Icon } from "@fluentui/react/lib/Icon";
import { QuestionScrollViewContainer } from "./style";

type IQuizQuestionVeiwNativeProps = {
    answerGivenByParticipant: string;
    question?: string;
    coverImage?: string;
    correctAnswerPoints: string;
    wrongAnswerPoints: string;
    correctAnswerOfQuestion: string;
    textAnswerType: string;
    correctAnswerText: string;
    wrongAnswerText: string;
    totalMarks: number;
    setTotalMarks: (marks: number) => void;
    increaseCorrectAnswerCount: () => void;
    increaseWrongAnswerCount: () => void;
    questionIsNonScoring: string;
    saveProgress: (answerType: answerType, answerCount: number, totalScore: number) => void;
    correctAnswerCount: number;
    wrongAnswerCount: number;
    timing: string;
    getTiming: (time: string) => void;
    optionsId?: string[];
    hideTimer: boolean;
    fetchNextQuestion: (saveProgress: boolean, userAttemptType: string, quizType?: string) => void;
    timeoutText: string;
    documentSelected: (coverImage: string) => void;
    bottomQuestionText: string;
    nextButtonText: string;
    connectorTiming: string;
    getConnectorTiming: (time: string) => void;
    connectorQuizIsWorking: boolean;
    submitResponseIsCompleted: boolean;
    questionTimeFinished: boolean;
    connectorIsClosable?: boolean;
    hideConnectorTime: boolean;
    gameBackgroundColor: string;
    answerResultImage: string;
    answerResultText: string;
    showProgressInPercentage: boolean;
    correctAnswerBackgroundColor: string;
    wrongAnswerBackgroundColor: string;
};

const QuizQuestionView: React.FC<IQuizQuestionVeiwNativeProps> = ({
    answerGivenByParticipant,
    coverImage,
    question,
    correctAnswerPoints,
    wrongAnswerPoints,
    correctAnswerOfQuestion,
    textAnswerType,
    correctAnswerText,
    wrongAnswerText,
    setTotalMarks,
    totalMarks,
    increaseCorrectAnswerCount,
    increaseWrongAnswerCount,
    questionIsNonScoring,
    saveProgress,
    correctAnswerCount,
    wrongAnswerCount,
    timing,
    getTiming,
    getConnectorTiming,
    optionsId,
    hideTimer,
    fetchNextQuestion,
    timeoutText,
    documentSelected,
    bottomQuestionText,
    nextButtonText,
    connectorTiming,
    connectorQuizIsWorking,
    submitResponseIsCompleted,
    questionTimeFinished,
    connectorIsClosable,
    hideConnectorTime,
    gameBackgroundColor,
    answerResultImage,
    answerResultText,
    showProgressInPercentage,
    correctAnswerBackgroundColor,
    wrongAnswerBackgroundColor
}) => {
    const [showDownwardIcon, setDownwardIcon] = useState(false);
    const [coverImageLoading, setCoverImageLoading] = useState(false);

    const scrollview = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let shouldDownwardIconDisplay =
            !!scrollview.current && scrollview.current.offsetHeight < scrollview.current.scrollHeight;

        if (
            shouldDownwardIconDisplay &&
            scrollview.current &&
            Math.abs(scrollview.current.scrollHeight - scrollview.current.scrollTop - 5).toFixed(0) <
            scrollview.current.clientHeight.toString()
        ) {
            shouldDownwardIconDisplay = false;
        }

        setDownwardIcon(shouldDownwardIconDisplay);
    }, [
        scrollview.current,
        scrollview.current && scrollview.current.scrollHeight,
        scrollview.current && scrollview.current.scrollTop,
        question
    ]);

    useEffect(() => {
        if (answerGivenByParticipant !== "") {
            getTotalMarks();
        }
    }, [answerGivenByParticipant]);

    const moveScrollBarDown = () => {
        scrollview.current?.scrollTo(0, scrollview.current.scrollTop + 50);
    };

    const getTotalMarks = (): void => {
        if (questionIsNonScoring) {
            if (connectorQuizIsWorking) {
                saveProgress({ type: NON_SCORING_QUESTION, quizType: CONNECTOR }, 0, totalMarks);
                return;
            }
            saveProgress({ type: NON_SCORING_QUESTION }, 0, totalMarks);
            return;
        }
        let correctAnswerArray = correctAnswerOfQuestion.split(",");
        correctAnswerArray = correctAnswerArray.map((element: string) => element.trim().toLowerCase());

        const answersArray = answerGivenByParticipant.split(",").map((element: string) => element.trim().toLowerCase());

        if (textAnswerType === ALL_CORRECT) {
            if (answersArray.length !== correctAnswerArray.length) {
                increaseWrongAnswerCount();
                const totalScore = totalMarks + parseInt(wrongAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                    return;
                }
                saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
                return;
            }
            if (difference(answersArray, correctAnswerArray).length === 0) {
                increaseCorrectAnswerCount();
                const totalScore = totalMarks + parseInt(correctAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress(
                        { type: CORRECT_ANSWER_COUNT, quizType: CONNECTOR },
                        correctAnswerCount + 1,
                        totalScore
                    );
                    return;
                }
                saveProgress({ type: CORRECT_ANSWER_COUNT }, correctAnswerCount + 1, totalScore);
                return;
            }
            increaseWrongAnswerCount();
            const totalScore = totalMarks + parseInt(wrongAnswerPoints);
            totalScore && setTotalMarks(totalScore);
            if (connectorQuizIsWorking) {
                saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                return;
            }
            saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
            return;
        }

        if (textAnswerType === ATLEAST_ONE_CORRECT) {
            const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
            const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);
            if (intersectWrongAnswerOptions.length === 0) {
                increaseCorrectAnswerCount();
                const totalScore = totalMarks + parseInt(correctAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress(
                        { type: CORRECT_ANSWER_COUNT, quizType: CONNECTOR },
                        correctAnswerCount + 1,
                        totalScore
                    );
                    return;
                }
                saveProgress({ type: CORRECT_ANSWER_COUNT }, correctAnswerCount + 1, totalScore);
                return;
            }
            increaseWrongAnswerCount();
            const totalScore = totalMarks + parseInt(wrongAnswerPoints);
            totalScore && setTotalMarks(totalScore);
            if (connectorQuizIsWorking) {
                saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                return;
            }
            saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
            return;
        }

        if (textAnswerType === ATLEAST_TWO_CORRECT) {
            const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
            const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);

            if (intersectWrongAnswerOptions.length !== 0) {
                increaseWrongAnswerCount();
                const totalScore = totalMarks + parseInt(wrongAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                    return;
                }
                saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
                return;
            }
            if (intersection(correctAnswerArray, answersArray).length >= 2) {
                increaseCorrectAnswerCount();
                const totalScore = totalMarks + parseInt(correctAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress(
                        { type: CORRECT_ANSWER_COUNT, quizType: CONNECTOR },
                        correctAnswerCount + 1,
                        totalScore
                    );
                    return;
                }
                saveProgress({ type: CORRECT_ANSWER_COUNT }, correctAnswerCount + 1, totalScore);
                return;
            }
            increaseWrongAnswerCount();
            const totalScore = totalMarks + parseInt(wrongAnswerPoints);
            totalScore && setTotalMarks(totalScore);
            if (connectorQuizIsWorking) {
                saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                return;
            }
            saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
            return;
        }

        if (textAnswerType === ATLEAST_THREE_CORRECT) {
            const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
            const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);
            if (intersectWrongAnswerOptions.length !== 0) {
                increaseWrongAnswerCount();
                const totalScore = totalMarks + parseInt(wrongAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                    return;
                }
                saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
                return;
            }
            if (intersection(correctAnswerArray, answersArray).length >= 3) {
                increaseCorrectAnswerCount();
                const totalScore = totalMarks + parseInt(correctAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress(
                        { type: CORRECT_ANSWER_COUNT, quizType: CONNECTOR },
                        correctAnswerCount + 1,
                        totalScore
                    );
                    return;
                }
                saveProgress({ type: CORRECT_ANSWER_COUNT }, correctAnswerCount + 1, totalScore);
                return;
            }
            increaseWrongAnswerCount();
            const totalScore = totalMarks + parseInt(wrongAnswerPoints);
            totalScore && setTotalMarks(totalScore);
            if (connectorQuizIsWorking) {
                saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                return;
            }
            saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
            return;
        }

        if (textAnswerType === ATLEAST_FOUR_CORRECT) {
            const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
            const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);
            if (intersectWrongAnswerOptions.length !== 0) {
                increaseWrongAnswerCount();
                const totalScore = totalMarks + parseInt(wrongAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                    return;
                }
                saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
                return;
            }
            if (intersection(correctAnswerArray, answersArray).length >= 4) {
                increaseCorrectAnswerCount();
                const totalScore = totalMarks + parseInt(correctAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress(
                        { type: CORRECT_ANSWER_COUNT, quizType: CONNECTOR },
                        correctAnswerCount + 1,
                        totalScore
                    );
                    return;
                }
                saveProgress({ type: CORRECT_ANSWER_COUNT }, correctAnswerCount + 1, totalScore);
                return;
            }
            increaseWrongAnswerCount();
            const totalScore = totalMarks + parseInt(wrongAnswerPoints);
            totalScore && setTotalMarks(totalScore);
            if (connectorQuizIsWorking) {
                saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                return;
            }
            saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
            return;
        }

        if (textAnswerType === ATLEAST_FIVE_CORRECT) {
            const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
            const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);
            if (intersectWrongAnswerOptions.length !== 0) {
                increaseWrongAnswerCount();
                const totalScore = totalMarks + parseInt(wrongAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                    return;
                }
                saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
                return;
            }
            if (intersection(correctAnswerArray, answersArray).length >= 5) {
                increaseCorrectAnswerCount();
                const totalScore = totalMarks + parseInt(correctAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress(
                        { type: CORRECT_ANSWER_COUNT, quizType: CONNECTOR },
                        correctAnswerCount + 1,
                        totalScore
                    );
                    return;
                }
                saveProgress({ type: CORRECT_ANSWER_COUNT }, correctAnswerCount + 1, totalScore);
                return;
            }
            increaseWrongAnswerCount();
            const totalScore = totalMarks + parseInt(wrongAnswerPoints);
            totalScore && setTotalMarks(totalScore);
            if (connectorQuizIsWorking) {
                saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                return;
            }
            saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
            return;
        }

        if (textAnswerType === FREE_TEXT_CONTAINS) {
            for (const answer of correctAnswerArray) {
                if (FieldValidation.freeTextContainsValidation(answer, answersArray[0])) {
                    increaseCorrectAnswerCount();
                    const totalScore = totalMarks + parseInt(correctAnswerPoints);
                    totalScore && setTotalMarks(totalScore);
                    if (connectorQuizIsWorking) {
                        saveProgress(
                            { type: CORRECT_ANSWER_COUNT, quizType: CONNECTOR },
                            correctAnswerCount + 1,
                            totalScore
                        );
                        return;
                    }
                    saveProgress({ type: CORRECT_ANSWER_COUNT }, correctAnswerCount + 1, totalScore);
                    return;
                }
            }
            increaseWrongAnswerCount();
            const totalScore = totalMarks + parseInt(wrongAnswerPoints);
            totalScore && setTotalMarks(totalScore);
            if (connectorQuizIsWorking) {
                saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                return;
            }
            saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
            return;
        }

        if (textAnswerType === FREE_TEXT_EXACT_MATCH) {
            if (answersArray.length !== correctAnswerArray.length) {
                increaseWrongAnswerCount();
                const totalScore = totalMarks + parseInt(wrongAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                    return;
                }
                saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
                return;
            }
            const result = difference(answersArray, correctAnswerArray);
            if (result.length === 0) {
                increaseCorrectAnswerCount();
                const totalScore = totalMarks + parseInt(correctAnswerPoints);
                totalScore && setTotalMarks(totalScore);
                if (connectorQuizIsWorking) {
                    saveProgress(
                        { type: CORRECT_ANSWER_COUNT, quizType: CONNECTOR },
                        correctAnswerCount + 1,
                        totalScore
                    );
                    return;
                }
                saveProgress({ type: CORRECT_ANSWER_COUNT }, correctAnswerCount + 1, totalScore);
                return;
            }
            increaseWrongAnswerCount();
            const totalScore = totalMarks + parseInt(wrongAnswerPoints);
            totalScore && setTotalMarks(totalScore);
            if (connectorQuizIsWorking) {
                saveProgress({ type: WRONG_ANSWER_COUNT, quizType: CONNECTOR }, wrongAnswerCount + 1, totalScore);
                return;
            }
            saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
            return;
        }

        if (textAnswerType === FREE_TEXT_DOES_NOT_CONTAINS) {
            for (const answer of correctAnswerArray) {
                if (FieldValidation.freeTextContainsValidation(answer, answersArray[0])) {
                    increaseWrongAnswerCount();
                    const totalScore = totalMarks + parseInt(wrongAnswerPoints);
                    totalScore && setTotalMarks(totalScore);
                    if (connectorQuizIsWorking) {
                        saveProgress(
                            { type: WRONG_ANSWER_COUNT, quizType: CONNECTOR },
                            wrongAnswerCount + 1,
                            totalScore
                        );
                        return;
                    }
                    saveProgress({ type: WRONG_ANSWER_COUNT }, wrongAnswerCount + 1, totalScore);
                    return;
                }
            }
            increaseCorrectAnswerCount();
            const totalScore = totalMarks + parseInt(correctAnswerPoints);
            totalScore && setTotalMarks(totalScore);
            if (connectorQuizIsWorking) {
                saveProgress({ type: CORRECT_ANSWER_COUNT, quizType: CONNECTOR }, correctAnswerCount + 1, totalScore);
                return;
            }
            saveProgress({ type: CORRECT_ANSWER_COUNT }, correctAnswerCount + 1, totalScore);
            return;
        }
    };

    const getAnswerResult = (): boolean => {
        let correctAnswerArray = correctAnswerOfQuestion.split(",");
        correctAnswerArray = correctAnswerArray.map((element: string) => element.trim().toLowerCase());
        const answersArray = answerGivenByParticipant.split(",").map((element: string) => element.trim().toLowerCase());

        if (textAnswerType === ATLEAST_ONE_CORRECT) {
            const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
            const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);
            if (intersectWrongAnswerOptions.length === 0) {
                return true;
            }
            return false;
        }

        if (textAnswerType === ATLEAST_TWO_CORRECT) {
            const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
            const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);

            if (intersectWrongAnswerOptions.length !== 0) {
                return false;
            }
            if (intersection(correctAnswerArray, answersArray).length >= 2) {
                return true;
            }
            return false;
        }
        if (textAnswerType === ATLEAST_THREE_CORRECT) {
            const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
            const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);

            if (intersectWrongAnswerOptions.length !== 0) {
                return false;
            }
            if (intersection(correctAnswerArray, answersArray).length >= 3) {
                return true;
            }
            return false;
        }

        if (textAnswerType === ATLEAST_FOUR_CORRECT) {
            const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
            const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);

            if (intersectWrongAnswerOptions.length !== 0) {
                return false;
            }
            if (intersection(correctAnswerArray, answersArray).length >= 4) {
                return true;
            }
            return false;
        }

        if (textAnswerType === ATLEAST_FIVE_CORRECT) {
            const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
            const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);

            if (intersectWrongAnswerOptions.length !== 0) {
                return false;
            }
            if (intersection(correctAnswerArray, answersArray).length >= 5) {
                return true;
            }
            return false;
        }

        if (textAnswerType === FREE_TEXT_CONTAINS) {
            for (const answer of correctAnswerArray) {
                if (FieldValidation.freeTextContainsValidation(answer, answersArray[0])) {
                    return true;
                }
            }
            return false;
        }

        if (textAnswerType === FREE_TEXT_EXACT_MATCH || textAnswerType === ALL_CORRECT) {
            if (answersArray.length !== correctAnswerArray.length) {
                return false;
            }
            const result = difference(answersArray, correctAnswerArray);
            return result.length === 0;
        }

        if (textAnswerType === FREE_TEXT_DOES_NOT_CONTAINS) {
            for (const answer of correctAnswerArray) {
                if (FieldValidation.freeTextContainsValidation(answer, answersArray[0])) {
                    return false;
                }
            }
            return true;
        }

        return false;
    };

    const questionText = (): JSX.Element => {
        return (
            <>
                {connectorIsClosable && (
                    <div className={"connector-back-button"}>
                        <Stack.Item>
                            <Button
                                className={"answer-submit back-button-style"}
                                onClick={() => fetchNextQuestion(false, CONNECTOR_SKIP, CONNECTOR)}>
                                Back
                            </Button>
                        </Stack.Item>
                    </div>
                )}
                <Stack
                    horizontal
                    verticalAlign={"center"}
                    horizontalAlign={"space-between"}
                    tokens={ThemedMediumStackTokens}
                    className={"questionTimerSection"}>
                    <Stack.Item>
                        <span
                            className={
                                connectorQuizIsWorking && hideConnectorTime ? "question-Timing" : "display-none"
                            }>
                            <Timer
                                type={QUESTION_TIMER_TYPE}
                                timer={connectorTiming}
                                getTiming={getConnectorTiming}
                                key={"quizTimer"}
                                eventFinishes={false}
                            />
                        </span>
                    </Stack.Item>
                    <Stack.Item>
                        <span className={!hideTimer ? "question-Timing" : "display-none"}>
                            <Timer
                                type={QUESTION_TIMER_TYPE}
                                timer={timing}
                                getTiming={getTiming}
                                key={"quizTimer"}
                                eventFinishes={false}
                            />
                        </span>
                    </Stack.Item>
                </Stack>
                {question && (
                    <div
                        className={"quizQuestionview quill-editor-text-align"}
                        dangerouslySetInnerHTML={{
                            __html: question
                        }}
                    />
                )}
                {coverImage && (
                    <Stack horizontalAlign={"center"}>
                        {coverImageLoading ? null : <div className={"coverImageLoader"} />}
                        <img
                            src={coverImage}
                            className={"question-cover-image cursor"}
                            alt={""}
                            onClick={() => coverImage && documentSelected(coverImage)}
                            onLoad={() => setCoverImageLoading(true)}
                        />
                        <Icon
                            iconName="ZoomIn"
                            className={"zoom-image"}
                            onClick={() => coverImage && documentSelected(coverImage)}
                        />
                    </Stack>
                )}

                <div
                    className={"quizQuestionview quill-editor-text-align"}
                    dangerouslySetInnerHTML={{
                        __html: bottomQuestionText || ""
                    }}
                />

                {scrollview.current && showDownwardIcon && (
                    <ScrollDownControl
                        moveScrollBarDown={moveScrollBarDown}
                        height={scrollview.current?.offsetHeight}
                    />
                )}
            </>
        );
    };

    const correctAnswerInfo = (): JSX.Element => {
        return (
            <Stack verticalAlign={"center"} horizontalAlign={"center"} className={"height-100per"}>
                {correctAnswerText !== "" && (
                    <div
                        className={"correct-answer-text margin-p-0 quill-editor-text-align"}
                        dangerouslySetInnerHTML={{
                            __html: correctAnswerText
                        }}
                    />
                )}

                {!questionIsNonScoring && (
                    <>
                        <span className={"correct-answer-text info-color"}>
                            {answerResultText !== ""
                                ? HelperFunctions.replaceString(
                                    totalMarks.toString(),
                                    correctAnswerPoints,
                                    answerResultText
                                )
                                : `${correctAnswerPoints} ${showProgressInPercentage && "%"
                                } has been added to your ship's total repair status`}
                        </span>
                        <Stack horizontal verticalAlign={"center"} horizontalAlign={"center"}>
                            <img
                                src={answerResultImage === "" ? tool : answerResultImage}
                                className={"answer-result-image"}
                                alt={"Result"}
                            />
                            <span className={"points-text info-color"}>
                                +{correctAnswerPoints}
                                {showProgressInPercentage && "%"}
                            </span>
                        </Stack>
                    </>
                )}

                <Stack horizontalAlign={"center"}>
                    <Button
                        disabled={!submitResponseIsCompleted}
                        className={"answer-submit width-250px"}
                        onClick={() => fetchNextQuestion(true, CORRECT_ANSWER_COUNT)}>
                        {submitResponseIsCompleted ? nextButtonText : "Saving Progress"}
                    </Button>
                </Stack>
            </Stack>
        );
    };

    const wrongAnswerInfo = (): JSX.Element => {
        return (
            <Stack verticalAlign={"center"} horizontalAlign={"center"} className={"height-100per"}>
                {wrongAnswerText !== "" && (
                    <span
                        className={"correct-answer-text margin-p-0 quill-editor-text-align"}
                        dangerouslySetInnerHTML={{
                            __html: wrongAnswerText
                        }}
                    />
                )}

                {!questionIsNonScoring && (
                    <>
                        <span className={"correct-answer-text info-color"}>
                            {answerResultText !== ""
                                ? HelperFunctions.replaceString(
                                    totalMarks.toString(),
                                    wrongAnswerPoints,
                                    answerResultText
                                )
                                : `${wrongAnswerPoints}${showProgressInPercentage && "%"
                                } has been taken from your ship's total repair status`}
                        </span>
                        <Stack horizontal verticalAlign={"center"}>
                            <img
                                src={answerResultImage === "" ? tool : answerResultImage}
                                className={"answer-result-image"}
                                alt={"Result"}
                            />
                            <span className={"points-text info-color"}>
                                {wrongAnswerPoints}
                                {showProgressInPercentage && "%"}
                            </span>
                        </Stack>
                    </>
                )}
                <Stack horizontalAlign={"center"}>
                    <Button
                        disabled={!submitResponseIsCompleted}
                        className={"answer-submit width-250px"}
                        onClick={() => fetchNextQuestion(true, WRONG_ANSWER_COUNT)}>
                        {submitResponseIsCompleted ? nextButtonText : "Saving Progress"}
                    </Button>
                </Stack>
            </Stack>
        );
    };

    const timeoutTextInfo = (): JSX.Element => {
        return (
            <Stack verticalAlign={"center"} horizontalAlign={"center"} className={"height-100per"}>
                <span
                    className={"correct-answer-text margin-p-0 quill-editor-text-align"}
                    dangerouslySetInnerHTML={{
                        __html: timeoutText
                    }}
                />

                <Stack horizontalAlign={"center"}>
                    <Button
                        disabled={!submitResponseIsCompleted}
                        className={"answer-submit"}
                        onClick={() => fetchNextQuestion(true, WRONG_ANSWER_COUNT)}>
                        {submitResponseIsCompleted ? nextButtonText : "Saving Progress"}
                    </Button>
                </Stack>
            </Stack>
        );
    };
    return (
        <QuestionScrollViewContainer
            style={{
                backgroundColor:
                    answerGivenByParticipant === ""
                        ? gameBackgroundColor
                        : getAnswerResult()
                            ? correctAnswerBackgroundColor
                            : wrongAnswerBackgroundColor
            }}
            ref={scrollview}
            className={
                answerGivenByParticipant === ""
                    ? !hideTimer || connectorIsClosable
                        ? "question-view padding-top-0"
                        : "question-view"
                    : !questionIsNonScoring
                        ? getAnswerResult()
                            ? "question-view correct-answer-view"
                            : "question-view wrong-answer-view"
                        : "question-view padding-top-0"
            }>
            {questionTimeFinished
                ? timeoutTextInfo()
                : answerGivenByParticipant === ""
                    ? questionText()
                    : getAnswerResult()
                        ? correctAnswerInfo()
                        : wrongAnswerInfo()}
        </QuestionScrollViewContainer>
    );
};

export default QuizQuestionView;

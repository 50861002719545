import Styled from "@emotion/styled";
import { IconButton } from "@fluentui/react";

type DotContainerProps = {
    color?: string;
};
export const DotContainer = Styled(IconButton)<DotContainerProps>`
    i {
        font-size: 60px;
        color: ${(props) => props.color || "#fff"};
    }
`;

export const AnimalHardQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "Sorry, you might want to visit the zoo soon.",
            imageSrc: "https://i.imgur.com/sBtunwK.jpeg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Not bad, you know quite a bit about animals",
            imageSrc: "https://i.imgur.com/sBtunwK.jpeg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Awesome! You're a true animal lover",
            imageSrc: "https://i.imgur.com/sBtunwK.jpeg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "What animal is this?",
            questionImageSrc: "https://i.imgur.com/TxTZV8A.jpg",
            answerDescription:
                "Unlike seals, sea lions have visible ears and long flippers which enable them to “walk” on land.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. seal ",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. sea cow",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. sea lion",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. orca",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "What is this Australian animal?",
            questionImageSrc: "https://i.imgur.com/73cSJcv.jpg",
            answerDescription: "Did you know that this friendly marsupial is famous for its supposed smile?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. anteater",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. wallaby",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. lemur",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. quokka",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Which of these birds is a peafowl?",
            questionImageSrc: "https://i.imgur.com/IGfCqxE.jpg",
            answerDescription:
                "Did you know there are only three known species of peafowl? Although they are all commonly referred to as peacocks, the male bird is called a peacock and the female is called a peahen. ",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. A",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. B",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. C",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. D",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "Name this animal from the cat family.",
            questionImageSrc: "https://i.imgur.com/3k3GOLf.jpg",
            answerDescription: "Lynx can be found in North America and Europe.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. lynx",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. panther",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. cheetah",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. jaguar",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "In which country will you find these animals?",
            questionImageSrc: "https://i.imgur.com/ZMNdAxO.jpg",
            answerDescription: "These infamous animals can reach up to 10 feet in length.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Spain",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Vietnam",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. South Africa",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Indonesia",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "What is this strange-looking sea creature?",
            questionImageSrc: "https://i.imgur.com/J1vR0ne.jpg",
            answerDescription:
                "The goblin shark is the only living animal in its ancient family of sharks, the Mitsukurinidae family.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. bow shark",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. anglerfish",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. goblin shark",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. hammerhead shark",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "All of these animals are marsupials except:",
            questionImageSrc: "https://i.imgur.com/9wU2Z0B.jpg",
            answerDescription: "Did you know that the anteater’s closest relative is the sloth?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. koala",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Tasmanian devil",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. opossum",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. anteater",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "What part of a black mamba is actually black?",
            questionImageSrc: "https://i.imgur.com/tRHqB9p.jpg",
            answerDescription:
                "The black mamba gets its name from the colour of the inside of its mouth. It’s skin it typically brown or grey.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. skin",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. mouth",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. nostrils",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. tail",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "What hummingbird species has a beak longer than its body?",
            questionImageSrc: "https://i.imgur.com/hLU5xBH.jpg",
            answerDescription:
                "Did you know that the male and female sword-billed hummingbirds can be distinguished by their colours?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Anna's hummingbird",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. narwhal hummingbird",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. sword-billed hummingbird",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. long-billed hummingbird",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "Complete the statement: A diurnal animal ________.",
            questionImageSrc: "https://i.imgur.com/vLPAuNB.jpg",
            answerDescription:
                "Diurnal means “active chiefly in the daytime”. It comes from the Latin word “diurnus”, which means “daily”.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. has only 2 feet",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. has 3 stomachs",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. is typically active only during the day",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. is mostly active at night",
                    isCorrect: false
                }
            ]
        }
    ]
};

import { Grid } from "@mui/material";
import ConfirmationBox from "../../../../../CommonComponent/v2/ConfirmationBox";
import DataGridWrapper from "../../../../../CommonComponent/v2/datagrid/DataGrid";
import LoadingWrapper from "../../../../../CommonComponent/v2/LoadingWrapper";
import SearchHeader from "../../../../../CommonComponent/v2/searchheader/SearchHeader";
import AddHintModal from "./AddHintModal";
import { useQuestionHook } from "./Hooks";

const QuestionHint = () => {
    const {
        onDeleteHint,
        openAddModal,
        setOpenAddModal,
        setSelectedDeleteId,
        setSelectedEditItem,
        hintList,
        hintListColumns,
        isLoading,
        selectedDeleteId,
        selectedEditItem,
        fetchQuestionHints,
        permission
    } = useQuestionHook();

    return (
        <Grid>
            <Grid mb={"20px"} container>
                <Grid item xs={12}>
                    <SearchHeader
                        showAddButton={!!(permission && permission.includes("add"))}
                        onButtonClick={() => setOpenAddModal(true)}
                        buttonLabel={"Add Hint"}
                        hideSearch
                    />
                </Grid>
            </Grid>
            <Grid height={"calc(100vh - 520px)"}>
                <LoadingWrapper
                    isLoading={isLoading}
                    loadingHeight={"0px"}
                    isNoItem={hintList.length === 0}
                    emptyTileLabel={`No Hints found`}
                    emptyTileSubLabel="Try adding a Hint"
                >
                    <DataGridWrapper columns={hintListColumns} rows={hintList as any} loading={isLoading} />
                </LoadingWrapper>
            </Grid>
            {selectedDeleteId && (
                <ConfirmationBox
                    title={`Delete hint`}
                    message={"Please confirm if you really want to delete this hint"}
                    onConfirm={onDeleteHint}
                    onClose={() => setSelectedDeleteId(null)}
                />
            )}
            {(openAddModal || selectedEditItem) && (
                <AddHintModal
                    handleRefresh={() => {
                        fetchQuestionHints(true);
                        setOpenAddModal(false);
                        setSelectedEditItem(undefined);
                    }}
                    hintDetail={selectedEditItem}
                    handleClose={() => {
                        setOpenAddModal(false);
                        setSelectedEditItem(undefined);
                    }}
                />
            )}
        </Grid>
    );
};

export default QuestionHint;

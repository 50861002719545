import { Dialog, Grid } from "@mui/material";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { FC } from "react";
import { VALID_IMAGE, VALID_PDF } from "../../../../../../../constants/SiteConstant";
import LoadingButtonWrapper from "../../../../../../CommonComponent/v2/button/LoadingButtonWrapper";
import { CancelButton, CaptionText, Header1, PrimaryButton } from "../../../../../../CommonComponent/v2/CommonStyle";
import FileDrop from "../../../../../../CommonComponent/v2/fields/FileDrop";
import FormikTextInput from "../../../../../../CommonComponent/v2/fields/FormikTextInput";
import LoadingWrapper from "../../../../../../CommonComponent/v2/LoadingWrapper";
import { useUpdateAttachmentHook } from "./Hooks";
import { UpdateAttachmentValidationSchema } from "./Util";

type UpdateAttachmentModalProps = {
    handleClose: () => void;
    attachmentId: string;
    refreshData: () => void;
};

const UpdateAttachmentModal: FC<UpdateAttachmentModalProps> = ({ handleClose, attachmentId, refreshData }) => {
    const { updateAttachment, isLoading, attachmentDetails } = useUpdateAttachmentHook(attachmentId);
    return (
        <Dialog fullWidth maxWidth={"md"} open onClose={handleClose}>
            <Grid py={"40px"}>
                <Grid container justifyContent={"center"}>
                    <Grid item>
                        <Header1>Add attachment</Header1>
                        <CaptionText mt={"10px"}>Please add attachment title and file</CaptionText>
                    </Grid>
                </Grid>
                <LoadingWrapper isLoading={isLoading}>
                    <Formik
                        initialValues={attachmentDetails}
                        validationSchema={UpdateAttachmentValidationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            updateAttachment(values, setSubmitting, refreshData);
                        }}
                        enableReinitialize
                    >
                        {({ errors, values, isSubmitting, handleSubmit }) => (
                            <Grid mt={"30px"} container spacing={"30px"} justifyContent={"center"}>
                                <Grid item xs={7}>
                                    <FormikTextInput
                                        name={"attachmentTitle"}
                                        id={"attachmentTitle"}
                                        label={"Attachment title"}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <FileDrop
                                        fileSize={5}
                                        errorMessage={"Please upload pdf only"}
                                        logoInfo={"Attachment image or pdf (less than 5 MB)"}
                                        name={"attachmentFile"}
                                        selectedFile={values.attachmentFile}
                                        validFilesList={[...VALID_PDF, ...VALID_IMAGE]}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <Grid alignItems={"center"} spacing={"20px"} container justifyContent={"center"}>
                                        <Grid item>
                                            <CancelButton onClick={handleClose}>Cancel</CancelButton>
                                        </Grid>
                                        <Grid item>
                                            <LoadingButtonWrapper loading={isSubmitting}>
                                                <PrimaryButton
                                                    onClick={() => handleSubmit()}
                                                    disabled={!isEmpty(errors) || !values.attachmentFile}
                                                >
                                                    {attachmentId !== "-1" ? "Update" : "Add"}
                                                </PrimaryButton>
                                            </LoadingButtonWrapper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </LoadingWrapper>
            </Grid>
        </Dialog>
    );
};

export default UpdateAttachmentModal;

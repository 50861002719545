import { Drawer, Grid, Typography } from "@mui/material";
import React, { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";

type SideDrawerProps = {
    open: boolean;
    handleClose: () => void;
    children: React.ReactNode;
    title: string;
    width: string;
};
const SideDrawer: FC<SideDrawerProps> = ({ handleClose, open, children, title, width }) => {
    return (
        <Drawer
            anchor={"right"}
            open={open}
            PaperProps={{
                style: {
                    width: width
                }
            }}
            onClose={handleClose}>
            <Grid padding={"10px 20px 0px 20px"} alignItems={"center"} container justifyContent={"space-between"}>
                <Typography fontWeight={"bold"} variant={"h6"}>
                    {title}
                </Typography>
                <CloseIcon onClick={handleClose} />
            </Grid>
            <Grid margin={"20px"}>{children}</Grid>
        </Drawer>
    );
};

export default SideDrawer;

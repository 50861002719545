import { Grid } from "@mui/material";
import React from "react";
import LoadingWrapper from "../../../../../CommonComponent/v2/LoadingWrapper";
import { useGetSurveyDetailHook } from "./Hooks";
import SurveyCard from "./SurveyCard";

const SurveyDetail = () => {
    const { isLoading, surveyDetails } = useGetSurveyDetailHook();
    return (
        <Grid pb={"50px"}>
            <LoadingWrapper loadingHeight="300px" isNoItem={surveyDetails.length === 0} isLoading={isLoading}>
                <Grid container spacing={"30px"}>
                    {surveyDetails.map((surveyInfo) => (
                        <Grid item key={surveyInfo.id} xs={6}>
                            <SurveyCard surveyInfo={surveyInfo} />
                        </Grid>
                    ))}
                </Grid>
            </LoadingWrapper>
        </Grid>
    );
};

export default SurveyDetail;

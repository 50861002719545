import { Icon, IconButton, Stack } from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { FieldValidation } from "../../../../constants/FieldValidation";
import "./style.scss";

type MiniEventDocumentModalProps = {
    document: string;
    documentType: string;
    documentClose: () => void;
};
const MiniEventDocumentModal: React.FC<MiniEventDocumentModalProps> = ({ document, documentClose, documentType }) => {
    const scrollview = useRef<HTMLDivElement>(null);
    const [showDownwardIcon, setDownwardIcon] = useState(false);

    useEffect(() => {
        if (scrollview.current && scrollview.current.offsetHeight < scrollview.current.scrollHeight) {
            setDownwardIcon(true);
        }
        if (
            scrollview.current &&
            Math.abs(scrollview.current.scrollHeight - scrollview.current.scrollTop).toFixed(0) ===
                scrollview.current.clientHeight.toString()
        ) {
            setDownwardIcon(false);
        }
    }, [scrollview.current, scrollview.current && scrollview.current.scrollTop, document]);

    const moveScrollBarDown = () => {
        scrollview.current?.scrollTo(0, scrollview.current.scrollTop + 50);
    };

    return (
        <div ref={scrollview} className={"document-modal-parent max-width-100per"}>
            <Stack horizontalAlign={"end"}>
                <IconButton
                    className={"close-icon"}
                    iconProps={{ iconName: "Cancel" }}
                    size={30}
                    onClick={() => documentClose()}
                />
            </Stack>
            {FieldValidation.imageTypes.includes(documentType) ? (
                <Stack horizontalAlign={"center"}>
                    <embed src={document} className={"max-width-100per height-auto"} />
                    {showDownwardIcon && (
                        <Stack
                            horizontalAlign={"center"}
                            className={"scroll-icon-document-modal"}
                            style={{
                                top: scrollview.current?.offsetHeight && scrollview.current?.offsetHeight - 40,
                                width: scrollview.current?.offsetHeight && scrollview.current?.offsetWidth - 40
                            }}>
                            <Icon iconName="DrillDownSolid" onClick={moveScrollBarDown} className={"cursor"} />
                        </Stack>
                    )}
                </Stack>
            ) : (
                <embed src={document} className={"document-modal-pdf"} />
            )}
        </div>
    );
};

export default MiniEventDocumentModal;

export const initialSetupInfoWizard = {
    wifiConnection: {
        iconName: "WifiEthernet",
        title: "Use fast internet connection",
        body: "Move close to your internet access point. Disable VPN if you use it."
    },
    closeApplications: {
        iconName: "WindowEdit",
        title: "Close other applications and browser tabs",
        body: "Some applications(eg. Microsoft Office) or pages (eg. Youtube) can decrease performance of your machine."
    },
    closeCommunication: {
        iconName: "Video",
        title: "Close communication and messaging applications",
        body:
            "Applications like Skype, Google Meet, Slack, Microsoft, Teams, Webex etc. can interupt your camera or microphone."
    }
};

import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { FC, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { deleteEventFiles, getEventFiles } from "./utils/eventFileApi";
import { EventFileViewProps, FileDataType } from "./utils/type";
import "./EventDetailsView.scss";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { donwloadEventFiles } from "./utils/downloadFilesApi";
import { Button, Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import { sortFilesList } from "./utils/helperFunctions";
import { addDefaultSrc } from "../../../../utils/JambarUtils";
import { getFileName } from "../../../../constants/HelperFns";
import DeleteConfirmationDialog from "../../../CommonComponent/DeleteConfirmationDialog";
import TextFieldWrapper from "../../../CommonComponent/TextFieldWrapper";

const EventFilesView: FC<EventFileViewProps> = ({ eventId }) => {
    const [filesData, setFilesData] = useState<FileDataType[]>([]);
    const [selectedFile, setSelectedFile] = useState<{
        fileName: string;
        id: number;
    } | null>(null);
    const [filterFiles, setFilteredFiles] = useState<FileDataType[]>([]);
    const [filterSearchText, setFilterSearchText] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getEventFiles(eventId)
            .then((data) => {
                const newFileData = data.map((item: FileDataType) => ({
                    ...item,
                    type: item.fileLocation.split(".").pop() || "",
                    fileName: item.fileLocation.split("/").pop() || ""
                }));
                setFilesData(sortFilesList(newFileData));
                setFilteredFiles(sortFilesList(newFileData));
            })
            .catch((error) => toast.error(error, SiteConstants.deleteToastConfiguration))
            .finally(() => setIsLoading(false));
    }, []);

    const handleTextChange = (text: string) => {
        setFilterSearchText(text);
        if (text === "") {
            setFilteredFiles(filesData);
            return;
        }
        setFilteredFiles(
            filesData.filter(
                (file: FileDataType) =>
                    file.teamName.toLowerCase().includes(text.toLowerCase()) ||
                    file.question.title.toLowerCase().includes(text.toLowerCase())
            )
        );
    };

    const handleDelete = () => {
        selectedFile &&
            deleteEventFiles(selectedFile.id)
                .then(() => {
                    toast.success("File deleted", SiteConstants.successToastConfiguration);
                    setFilesData(filesData.filter((file: FileDataType) => file.id !== selectedFile.id));
                    setSelectedFile(null);
                })
                .catch(() =>
                    toast.success("Something went wrong. Please try again", SiteConstants.deleteToastConfiguration)
                );
    };

    if (isLoading) {
        return (
            <Grid container style={{ height: 300 }} justifyContent="center" alignItems="center" className="loading-container">
                <Spinner size={SpinnerSize.large} />
            </Grid>
        );
    }
    if (filesData.length !== 0) {
        return (
            <div style={{ overflow: "hidden" }} className="section-background section-border padding-10 overflowy-auto">
                <Grid
                    className={"margin-top-10"}
                    style={{ padding: "0px 20px" }}
                    container
                    justifyContent={"space-between"}>
                    <TextFieldWrapper
                        onTextFieldValueChange={(text) => handleTextChange(text)}
                        value={filterSearchText}
                        placeholder={"Search"}
                    />
                    <Button onClick={() => donwloadEventFiles(eventId)}>
                        Download Files
                    </Button>
                </Grid>
                <Grid className="margin-top-30" container spacing={5}>
                    {filterFiles.length > 0 ? (
                        filterFiles.map((item) => (
                            <Grid item xs={3} key={item.id}>
                                <Card style={{ height: "100%" }}>
                                    <CardActionArea>
                                        {item.type && !FieldValidation.videoType.includes(item.type) ? (
                                            <img
                                                src={item.fileLocation}
                                                width={"100%"}
                                                height={150}
                                                onError={addDefaultSrc}
                                                className="black-border"
                                                alt={"filelocation"}
                                            />
                                        ) : (
                                            <video width="100%" height={145} controls>
                                                <source src={item.fileLocation} />
                                            </video>
                                        )}
                                        <CardContent>
                                            <div className="file-card">
                                                <div>
                                                    <p className="cardSubText">Name:</p>
                                                    <span className="cardLabel"> {item.participantName}</span>
                                                </div>
                                                <div>
                                                    <p className="cardSubText">Code:</p>
                                                    <span className="cardLabel">{item.participantCode}</span>
                                                </div>
                                                <div>
                                                    <p className="cardSubText">Created At:</p>
                                                    <span className="cardLabel">
                                                        {JambarDateUtil.dateFormat(
                                                            new Date(item.createdAt),
                                                            "DD/MM/YYYY"
                                                        )}
                                                    </span>
                                                </div>
                                                <div>
                                                    <p className="cardSubText">File Name:</p>
                                                    <span className="cardLabel">{getFileName(item.fileName)}</span>
                                                </div>
                                                <div>
                                                    <p className="cardSubText">Question:</p>
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.question.title
                                                        }}
                                                        className="cardLabel"
                                                    />
                                                </div>
                                                <div>
                                                    <p className="cardSubText">Team:</p>
                                                    <span className="cardLabel">{item.teamName}</span>
                                                </div>
                                                <Grid container>
                                                    <Button
                                                        onClick={() => window.open(item.fileLocation, "Download")}
                                                        size={"small"}
                                                        style={{
                                                            textTransform: "none",
                                                            fontWeight: "bold",
                                                            paddingLeft: 0
                                                        }}
                                                        variant={"text"}
                                                        color={"primary"}>
                                                        Download
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            setSelectedFile({
                                                                fileName: item.fileName,
                                                                id: item.id
                                                            })
                                                        }
                                                        size={"small"}
                                                        style={{
                                                            textTransform: "none",
                                                            fontWeight: "bold",
                                                            paddingLeft: 0
                                                        }}
                                                        variant={"text"}
                                                        color={"secondary"}>
                                                        Delete
                                                    </Button>
                                                </Grid>
                                            </div>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))
                    ) : (
                        <Grid style={{ height: 500 }} container justifyContent={"center"}>
                            <Typography className="font-size-20">No searched file found</Typography>
                        </Grid>
                    )}
                </Grid>
                {selectedFile && (
                    <DeleteConfirmationDialog
                        entryName={selectedFile.fileName}
                        onConfirm={handleDelete}
                        isShown={!!selectedFile}
                        onDismiss={() => setSelectedFile(null)}
                    />
                )}
            </div>
        );
    }
    return (
        <>
            <ToastContainer />
            <Stack verticalAlign="center" style={{ height: "200px" }} horizontalAlign="center">
                <Stack.Item align="center">
                    <p className="font-size-20">No Files</p>
                </Stack.Item>
            </Stack>
        </>
    );
};

export default EventFilesView;

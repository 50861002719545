import { ApiConstants } from "../../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { extractHtmlContent } from "../../../../utils/JambarUtils";
import { Client } from "../../../Base/Client";
import { EventListItem, EventUrls } from "./Type";

const transformData = (data: any): EventListItem[] => {
    if (!data) {
        return [];
    }
    return data.map(
        (event: any) =>
            ({
                id: event.id,
                eventName: event.name,
                createdBy: `${event.createdByUser.firstName} ${event.createdByUser.lastName}`,
                startDate: JambarDateUtil.dateFormat(event.eventStartDate, "MM/DD/YYYY"),
                endDate: JambarDateUtil.dateFormat(event.eventEndDate, "MM/DD/YYYY"),
                gameTitle: extractHtmlContent(event.quiz.title),
                createdOn: JambarDateUtil.dateFormat(event.createdAt, "MM/DD/YYYY"),
                noOfTeams: event.teamCount,
                game: event.quizId,
                updatedBy: `${event.updatedByUser.firstName} ${event.updatedByUser.lastName}`,
                updatedOn: JambarDateUtil.dateFormat(event.updatedAt, "MM/DD/YYYY"),
                isClosed: event.isClosed,
                fileLocation: event.fileLocation,
                type: event.type || "",
                eventLink: event.eventLink,
                isLive: event.isLive || false,
                isPublic: event.isPublic,
                isFreeCode: event.event_participant_codes.find((item) => !item.isPaid)
            } as EventListItem)
    );
};

export const getEventListData = (forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getEventListApiUrl(), forceRefresh)
        .then((response: any) => Promise.resolve(transformData(response.data.data)))
        .catch((error: any) => Promise.reject(error));

const filterDataBySearchText = (rows: EventListItem[], searchText: string) => {
    return rows.filter(
        (row: EventListItem) =>
            (searchText && row.eventName.toLowerCase().includes(searchText.toLowerCase())) ||
            row.gameTitle.toLowerCase().includes(searchText.toLowerCase()) ||
            row.startDate.toLowerCase().includes(searchText.toLowerCase()) ||
            row.endDate.toLowerCase().includes(searchText.toLowerCase()) ||
            row.createdOn.toLowerCase().includes(searchText.toLowerCase()) ||
            row.updatedBy.toLowerCase().includes(searchText.toLowerCase()) ||
            row.updatedOn.toLowerCase().includes(searchText.toLowerCase())
    );
};

export const filterDataByTab = (rows: EventListItem[], selectedTab: string, searchText: string): any => {
    if (selectedTab === "archived") {
        return filterDataBySearchText(
            rows.filter((row) => new Date(row.endDate).getTime() < new Date().getTime() && !row.isClosed),
            searchText
        );
    }
    if (selectedTab === "systemPublic") {
        return filterDataBySearchText(
            rows.filter((row) => row.createdBy === "System Public"),
            searchText
        );
    }
    if (selectedTab === "upcoming") {
        return filterDataBySearchText(
            rows.filter(
                (row) =>
                    ((new Date(row.startDate).getTime() < new Date().getTime() &&
                        new Date(row.endDate).getTime() > new Date().getTime()) ||
                        new Date(row.startDate).getTime() > new Date().getTime()) &&
                    !row.isClosed &&
                    row.createdBy !== "System Public"
            ),
            searchText
        );
    }
    if (selectedTab === "closed") {
        return filterDataBySearchText(
            rows.filter((row) => row.isClosed),
            searchText
        );
    }
    return filterDataBySearchText(rows, searchText);
};

export const getIsActive = (startDate: string, endDate: string) => {
    return new Date(startDate).getTime() < new Date().getTime() && new Date(endDate).getTime() > new Date().getTime();
};

export const initialUrls = {
    eventUrl: "",
    firstReferenceUrl: "",
    leaderboardUrl: "",
    participantFilesUrl: "",
    secondRefrenceUrl: ""
};

export const getEventUrl = (type: string, eventUrl: string) => {
    if (type === "isPublic") {
        return `${window.location.origin}/publicevent/${eventUrl}`;
    }
    if (type === "mini") {
        return `${window.location.origin}/minievent/${eventUrl}`;
    }
    return `${window.location.origin}/event/${eventUrl}`;
};

export const transformUrls = (data: any): EventUrls => {
    if (!data) {
        return initialUrls;
    }
    return {
        eventUrl: getEventUrl(data.type, data.eventLink),
        participantFilesUrl: data.participantFilesLink
            ? `${window.location.origin}/participant/files?participantFilesLink=${data.participantFilesLink}`
            : "",
        firstReferenceUrl:
            data.referenceList.length !== 0 && data.referenceList[0].referenceLink
                ? `${window.location.origin}/reflist/${data.eventLink}/${data.referenceList[0].referenceLink}`
                : "",
        secondRefrenceUrl:
            data.referenceList.length === 2 && data.referenceList[1].referenceLink
                ? `${window.location.origin}/reflist/${data.eventLink}/${data.referenceList[1].referenceLink}`
                : "",
        leaderboardUrl: data.leaderboardLink ? `${window.location.origin}/leaderboard/${data.leaderboardLink}` : ""
    };
};

export const getEventDetails = (eventId: string) => {
    return Client.getInstance()
        .getData(ApiConstants.getEventDetailApiUrl(eventId))
        .then((response: any) => Promise.resolve(transformUrls(response.data.data)))
        .catch((error: any) => Promise.reject(error));
};

export const onConfirmDelete = (eventId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteEventApiUrl(eventId.toString()))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const onMakeEventLiveStatus = (eventId: number, live: boolean) =>
    Client.getInstance()
        .updateData(ApiConstants.markEventAsLiveApiUrl(eventId.toString(), live ? 1 : 0), {})
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const handleLinkClick = (type: string, isPublic: boolean, eventLink: string) => {
    if (type === "mini") {
        window.open(`${window.location.origin}/minievent/${eventLink}`);
        return;
    }
    if (isPublic) {
        window.open(`${window.location.origin}/publicevent/${eventLink}`);
        return;
    }
    window.open(`${window.location.origin}/event/${eventLink}`);
};

import { IColumn, IconButton, Panel, PanelType } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { ReferenceList } from "./referenceListDataTypes";
import DeleteConfirmationDialog from "../../CommonComponent/DeleteConfirmationDialog";
import { Client } from "../../Base/Client";
import { ApiConstants } from "../../../constants/ApiConstant";
import { SiteConstants } from "../../../constants/SiteConstant";
import { toast, ToastContainer } from "react-toastify";
import ReferenceDetailView from "./ReferenceDetail/ReferenceDetailView";
import { JambarDateUtil } from "../../../constants/JambarDateUtils";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import FixedHeaderSortingDetailsList from "../../CommonComponent/FixedHeaderSortingDetilsList";
import { usageListType } from "../../../data/question/types";
import { getPermissions } from "../../../utils/PermissionUtils";
import { useAdminContext } from "../context/AdminAuthContext";
import ConfirmationDialog from "../../CommonComponent/ConfirmationDialog";
import { onConfirmReferenceClone } from "./Util";

const ReferenceLibrary: React.FC = () => {
    const [searchText, setSearchText] = useState("");
    const [showAddEditPanel, setAddEditPanelDisplay] = useState(false);
    const [selectedReferenceId, setSelectedReferenceId] = useState(-1);
    const [referenceList, setReferenceList] = useState<ReferenceList[]>([]);
    const [showDeleteConfirmationDialog, setDeleteConfirmationDialogDisplay] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [usageList, setUsageList] = useState<usageListType[]>([]);
    const [usageLoading, setUsageLoading] = useState<boolean>(false);
    const [cloneReferenceId, setCloneReferenceId] = useState<number>(-1);

    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "referenceList");

    useEffect(() => {
        fetchReferenceListData();
    }, []);

    const fetchReferenceListData = (forceRefresh = false): void => {
        setLoading(true);
        if (!(permission && permission.includes("fetch"))) {
            setReferenceList([]);
            setLoading(false);
            return;
        }
        Client.getInstance()
            .getData(ApiConstants.getReferenceListApiUrl(), forceRefresh)
            .then((response: any) => {
                const reference: ReferenceList[] = [];
                response.data.data.map((element: any) =>
                    reference.push({
                        id: element.id,
                        title: element.name,
                        createdBy: `${element.createdByUser.firstName} ${element.createdByUser.lastName}`,
                        createdDate: JambarDateUtil.formatDate(element.createdAt),
                        lastUpdatedBy: `${element.updatedByUser.firstName} ${element.updatedByUser.lastName}`,
                        lastUpdatedDate: JambarDateUtil.formatDate(element.updatedAt)
                    })
                );
                setReferenceList(reference);
                setLoading(false);
            });
    };

    const onOpenEditPanel = (id: number) => {
        setSelectedReferenceId(id);
        setAddEditPanelDisplay(true);
    };

    const onDeleteReference = (id: number) => {
        setSelectedReferenceId(id);
        setDeleteConfirmationDialogDisplay(true);
    };

    const onConfirmDelete = () => {
        setUsageLoading(true);
        Client.getInstance()
            .deleteData(ApiConstants.deleteReferenceApiUrl(selectedReferenceId))
            .then(() => {
                setUsageLoading(false);
                setDeleteConfirmationDialogDisplay(false);
                toast.success("Reference deleted", SiteConstants.deleteToastConfiguration);
                setSelectedReferenceId(-1);
                fetchReferenceListData(true);
            })
            .catch((error) => {
                if (error.response.data.data.event) {
                    setUsageList(error.response.data.data.event);
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                    setUsageLoading(false);
                    return;
                }
                setDeleteConfirmationDialogDisplay(false);
                setSelectedReferenceId(-1);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const onCancelDelete = () => {
        setSelectedReferenceId(-1);
        setDeleteConfirmationDialogDisplay(false);
        setUsageList([]);
    };

    const onCloseAddReferencePanel = (id: number): void => {
        setSelectedReferenceId(id);
        fetchReferenceListData(true);
    };

    const getFilteredItems = (): ReferenceList[] => {
        const filteredItems =
            searchText === ""
                ? referenceList
                : referenceList.filter((item: ReferenceList) => {
                      return includeInFilteredItems(searchText.toLowerCase(), item);
                  });
        return filteredItems;
    };

    const includeInFilteredItems = (searchText: string, item: ReferenceList): boolean => {
        if (item.id && item.id.toString().includes(searchText)) {
            return true;
        }
        if (item.title && item.title.toLowerCase().includes(searchText)) {
            return true;
        }
        if (item.createdBy && item.createdBy.toLowerCase().includes(searchText)) {
            return true;
        }
        if (item.createdDate && item.createdDate.includes(searchText)) {
            return true;
        }
        if (item.lastUpdatedBy && item.lastUpdatedBy.toLowerCase().includes(searchText)) {
            return true;
        }
        if (item.lastUpdatedDate && item.lastUpdatedDate.includes(searchText)) {
            return true;
        }
        return false;
    };

    const referenceListColumns: IColumn[] = [
        {
            key: "title",
            name: "Title",
            fieldName: "title",
            minWidth: SiteConstants.listColumnLarge
        },
        {
            key: "createdBy",
            name: "Created By",
            fieldName: "createdBy",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        },
        {
            key: "lastUpdatedBy",
            name: "Updated By",
            fieldName: "lastUpdatedBy",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        }
    ];

    const referenceMenuItems = [
        {
            key: "clone",
            name: "",
            fieldName: "clone",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "update",
            name: "",
            fieldName: "update",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "delete",
            name: "",
            fieldName: "delete",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        }
    ];

    const getColumns = () => {
        if (permission) {
            return [
                ...referenceListColumns,
                ...referenceMenuItems.filter((column) => permission && permission.includes(column.key))
            ];
        }
        return referenceListColumns;
    };

    const renderItemColumn = (
        item: ReferenceList,
        index: number | undefined,
        column: IColumn | undefined
    ): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "createdBy":
                return (
                    <>
                        {item.createdBy}
                        <br />
                        on <i>{item.createdDate}</i>
                    </>
                );
            case "lastUpdatedBy":
                return (
                    <>
                        {item.lastUpdatedBy}
                        <br />
                        on <i>{item.lastUpdatedDate}</i>
                    </>
                );
            case "clone":
                return (
                    <IconButton
                        iconProps={{ iconName: "DependencyAdd" }}
                        title="Duplicate"
                        onClick={() => setCloneReferenceId(item.id)}
                    />
                );
            case "update":
                return (
                    <IconButton
                        iconProps={{ iconName: "Edit" }}
                        title="Edit"
                        onClick={() => item.id && onOpenEditPanel(item.id)}
                    />
                );

            case "delete":
                return (
                    <IconButton
                        iconProps={{ iconName: "delete" }}
                        title="Delete"
                        onClick={() => item.id && onDeleteReference(item.id)}
                    />
                );
            default: {
                const fieldContent = item[column.fieldName as keyof ReferenceList] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    const cloneReferenceItem = () => {
        onConfirmReferenceClone(cloneReferenceId)
            .then((id) => {
                setSelectedReferenceId(id);
                setAddEditPanelDisplay(true);
                setCloneReferenceId(-1);
                toast.success("Challenge cloned", SiteConstants.successToastConfiguration);
                fetchReferenceListData(true);
            })
            .catch((err) => {
                setSelectedReferenceId(-1);
                setCloneReferenceId(-1);
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            });
    };

    return (
        <>
            <ToastContainer />
            <FixedHeaderSortingDetailsList
                onSearchTextChange={(text: string) => setSearchText(text)}
                searchText={searchText}
                onAddButtonClick={() => {
                    setAddEditPanelDisplay(true);
                    setSelectedReferenceId(-1);
                }}
                hideAddNewButton={!(permission && permission.includes("add"))}
                columns={getColumns()}
                isLoading={isLoading}
                items={getFilteredItems()}
                onRenderItemColumn={renderItemColumn}
            />
            <DeleteConfirmationDialog
                entryName={"reference"}
                isShown={showDeleteConfirmationDialog}
                onConfirm={onConfirmDelete}
                onDismiss={onCancelDelete}
                usageList={usageList}
                usedBy="event"
                hideButton={usageList.length !== 0}
                loading={usageLoading}
            />
            <ConfirmationDialog
                entryName={"reference"}
                dialogHeader={"Confirm Clone"}
                isShown={cloneReferenceId !== -1}
                onConfirm={cloneReferenceItem}
                actionName={"clone"}
                onDismiss={() => setCloneReferenceId(-1)}
            />
            <Panel
                isLightDismiss
                isOpen={showAddEditPanel}
                onDismiss={() => {
                    setAddEditPanelDisplay(false);
                    setSelectedReferenceId(-1);
                }}
                headerText={selectedReferenceId === -1 ? "Add Reference List" : "Edit Reference List"}
                type={PanelType.large}>
                <ReferenceDetailView
                    referenceId={selectedReferenceId}
                    closeAddPanel={onCloseAddReferencePanel}
                    refreshReferenceList={() => fetchReferenceListData(true)}
                />
            </Panel>
        </>
    );
};

export default ReferenceLibrary;

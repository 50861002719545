import { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../../../utils/APIErrorMessages";
import { useAdminContext } from "../../../../../context/AdminAuthContext";
import { BulkCodeType, BulkTransformCodeType } from "../generatebulkcode/Util";
import { CodeType } from "../Type";
import { addBulkEventCode, addEventCode, generateBulkCode } from "./Util";
import { useHistory } from "react-router";
import ErrorToast from "./ErrorToast";

export const useAddNewCodeHook = () => {
    const [selectedTab, setSelectedTab] = useState("single");
    const { id } = useParams<{
        id: string;
    }>();
    const [loading, setLoading] = useState(false);
    const { userDetail, getCreditInfo } = useAdminContext();
    const userName = `${userDetail?.firstName} ${userDetail?.lastName}`;
    const history = useHistory();

    const codeTabs = [
        {
            label: "Generate single code",
            value: "single"
        },
        {
            label: "Generate bulk code",
            value: "bulk"
        }
    ];

    const addNewCode = (values: CodeType, handleClose: () => void) => {
        setLoading(true);
        addEventCode(id, values)
            .then(() => {
                toast.success("Code added", SiteConstants.successToastConfiguration);
                getCreditInfo();
                handleClose();
            })
            .catch((error) => {
                toast.error(
                    (props) => (
                        <ErrorToast
                            errorText={getErrorMessage(error)}
                            handleBuyClick={() => history.push("/admin/subscription")}
                            handleCloseClick={() => props.closeToast()}
                        />
                    ),
                    {
                        position: "top-right",
                        autoClose: false
                    }
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const addBulkCode = (values: BulkCodeType, refreshList: () => void, eventCodeList: string[]) => {
        const bulkCode: BulkTransformCodeType[] = generateBulkCode(values, eventCodeList);
        setLoading(true);
        addBulkEventCode(id, bulkCode)
            .then((response: string) => {
                toast.success(response, SiteConstants.successToastConfiguration);
                getCreditInfo();
                refreshList();
            })
            .catch((error) => {
                toast.error(
                    (props) => (
                        <ErrorToast
                            errorText={getErrorMessage(error)}
                            handleBuyClick={() => history.push("/admin/subscription")}
                            handleCloseClick={() => props.closeToast()}
                        />
                    ),
                    {
                        position: "top-right",
                        autoClose: false
                    }
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        selectedTab,
        setSelectedTab,
        codeTabs,
        addNewCode,
        addBulkCode,
        userName,
        loading,
        setLoading
    };
};

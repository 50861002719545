import { object, string } from "yup";
import { Client } from "../../../../../../Base/Client";
import { ApiConstants } from "../../../../../../../constants/ApiConstant";
import { AttachmentType, BreadCrumbType } from "./AttachmentTypes";

export const attachmentBreadcrumbValues = (refId: string, attachmentId: string): BreadCrumbType[] => [
    {
        label: "Reference list / Edit reference list",
        url: `/admin/v2/referencelistmanager/${refId}/update`
    },
    {
        label: attachmentId ? "Edit Attachemnt" : "Add new attachment"
    }
];
export const addAttachmentInitialValues = (attachmentDetails?: AttachmentType): AttachmentType => ({
    title: attachmentDetails?.title || "",
    imageFile: attachmentDetails?.imageFile || null,
    type: attachmentDetails?.type || "image",
    attachmentFile: attachmentDetails?.attachmentFile || null
});

export const validationSchema = object().shape({
    title: string().required("Title is required")
});

export const reverseTransformData = (values: AttachmentType): any => {
    const attachmentData = new FormData();
    attachmentData.append("title", values.title);
    if (values.type === "image" && typeof values.imageFile !== "string") {
        attachmentData.append("referenceListAttachment", values.imageFile as Blob);
        return attachmentData;
    }
    if (values.type === "file" && typeof values.attachmentFile !== "string") {
        attachmentData.append("referenceListAttachment", values.attachmentFile as Blob);
        return attachmentData;
    }
    return attachmentData;
};

export const addNewAttachment = (id: number, values: AttachmentType) => {
    return Client.getInstance()
        .createData(ApiConstants.addReferenceListAttachmentApiUrl(id), reverseTransformData(values))
        .then((response: any) => Promise.resolve(response.data.data.id))
        .catch((error) => Promise.reject(error));
};

export const updateAttachmentDetails = (id: number, attachmentId: number, values: AttachmentType) =>
    Client.getInstance()
        .updateData(ApiConstants.updateReferenceListAttachmentsApiUrl(id, attachmentId), reverseTransformData(values))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

const transformData = (data: any): AttachmentType => {
    return {
        title: data.title || "",
        imageFile: data.fileName.split(".").pop() !== "pdf" ? data.fileLocation || null : null,
        attachmentFile: data.fileName.split(".").pop() === "pdf" ? data.fileLocation || null : null,
        type: data.fileName.split(".").pop() === "pdf" ? "file" : "image"
    };
};

export const getAttachmentDetails = (id: number, attachmentId: number) =>
    Client.getInstance()
        .getData(ApiConstants.getReferenceListAttachmentDetailsApiUrl(id, attachmentId), true)
        .then((response) => Promise.resolve(transformData(response.data.data)))
        .catch((error) => Promise.reject(error));

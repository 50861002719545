import { intersection } from "lodash";
import { toast } from "react-toastify";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { Client } from "../../../Base/Client";
import { EventListItem } from "../../EventManager/EventListDataTypes";

export const getFileListFilteredItems = (list: EventListItem[], searchText: string): any => {
    if (searchText === "") {
        return list;
    }
    const trimSearchTextArray = searchText.trim().toLowerCase().split(" ");
    return list.filter(
        (row: EventListItem) =>
            intersection(row.eventName.trim().toLowerCase().split(" "), trimSearchTextArray)
                .length === trimSearchTextArray.length ||
            intersection(row.gameTitle.trim().toLowerCase().split(" "), trimSearchTextArray)
                .length === trimSearchTextArray.length ||
            row.startDate.toLowerCase().includes(searchText.toLowerCase())
    );
};

export const getFilterFileListByType = (list: EventListItem[], type: string): any => {
    if (type === "active") {
        return list.filter((event) => !event.isClosed);
    }
    return list.filter((event) => event.isClosed);
};

export const downloadEventFiles = (eventId: number) => {
    return new Promise<any>((reject) => {
        toast.success("File downloading has started", SiteConstants.successToastConfiguration);
        return Client.getInstance()
            .downloadZip(ApiConstants.getEventParticipantFileDownloadApiUrl(eventId))
            .then((res) => {
                const blob = new Blob([res.data], { type: "application/zip" });
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = downloadUrl;
                a.download = `eventFiles_${eventId}.zip`;
                document.body.appendChild(a);
                a.click();
                toast.success("File has been downloaded", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                toast.error("Files not found", SiteConstants.deleteToastConfiguration);
                reject(error);
            });
    });
};

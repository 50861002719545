export const correctAnswerColor = `#00CEA5`;
export const backgroundContainerColor = `#C4C4C442`;
export const secondaryBackgroundColor = `#C4C4C4`;
export const wrongAnswerColor = `#FF4869`;
export const primaryTextColor = `#2F2F2F`;
export const secondaryTextColor = `#515F99`;
export const captionTextColor = `#000000`;
export const hoverPrimaryButtonColor = `#14facc`;
export const gameEndColor = `#D10D68`;
export const quizResultTextColor = `#5141A8`;
export const skipButtonColor = `#1D696F`;
export const whiteColor = `#ffff`;
export const drawerColor = `#F2F2F2`;
export const headerColor = `#109648`;
export const darkColor = "#333333";

import React from "react";
import "react-quill/dist/quill.snow.css";
import "./TextEditor.scss";
import ReactQuill from "react-quill"


type IEditorProps = {
    value?: string;
    onChange?: (newValue: string) => void;
    basicControl?: boolean;
    className?: string;
    showFontSizeControl?: boolean;
    isSimple?: boolean;
    id?: string;
};
const TextEditor: React.FC<IEditorProps> = ({
    value,
    onChange,
    basicControl,
    className,
    showFontSizeControl,
    isSimple,
    id
}) => {
    const getValue = (value: string) => {
        if (value.substr(value.length - 11) === "<p><br></p>") {
            return (value = value.substr(0, value.length - 11) + "<p>&#8205;</p>");
        }
        return value;
    };
    return (
        <ReactQuill
            value={value && getValue(value)}
            id={id}
            onChange={onChange}
            className={className}
            modules={
                isSimple
                    ? {
                          toolbar: []
                      }
                    : basicControl
                    ? {
                          toolbar: [
                              [showFontSizeControl && { header: [1, 2, false] }, "bold", "italic", "underline"],
                              [{ align: [] }],
                              [{ color: [] }]
                          ]
                      }
                    : {
                          toolbar: [
                              [{ header: [1, 2, false] }],
                              ["bold", "italic", "underline"],
                              [{ list: "ordered" }, { list: "bullet" }],
                              [{ align: [] }],
                              ["image"],
                              ["video"],
                              [{ color: [] }]
                          ]
                      }
            }
            formats={
                basicControl
                    ? ["header", "bold", "italic", "underline", "color", "align"]
                    : [
                          "header",
                          "bold",
                          "italic",
                          "underline",
                          "list",
                          "bullet",
                          "align",
                          "indent",
                          "image",
                          "video",
                          "color"
                      ]
            }
        />
    );
};
export default TextEditor;

import { IColumn, Stack } from "@fluentui/react";
import React, { useEffect, useState, FC } from "react";
import { toast, ToastContainer } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import FixedHeaderSortingDetailsList from "../../../CommonComponent/FixedHeaderSortingDetilsList";
import { assignRightToResource, getAllRights, getResourceList, revokeRightToResource, transformResourceColumnList } from "./Action";
import { renderResourcesListColumn, ResourceListColumns } from "./GridUtil";
import { ResourceType } from "./Type";
import { getResourceListFilteredItems } from "./Util";

type ResourceRightDetailPanelProps = {
    roleId: number;
};

const ResourceRightDetailPanel: FC<ResourceRightDetailPanelProps> = ({ roleId }) => {
    const [searchText, setSearchText] = useState("");
    const [allResources, setAllResources] = useState<ResourceType[]>([]);
    const [column, setColumns] = useState(ResourceListColumns);

    const fetchData = () => {
        getAllRights(roleId)
            .then((res) => {
                setAllResources(res.resources);
            })
            .catch((err) => {
                setAllResources([]);
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            });
    };

    useEffect(() => {
        getResourceList().then((res) => {
            setColumns([...ResourceListColumns, ...transformResourceColumnList(res)]);
        });
        fetchData();
    }, []);

    const assignRights = (resourceId: number, rightId: number) => {
        assignRightToResource(roleId, resourceId, rightId)
            .then(() => {
                toast.success("Rights assigned successfully", SiteConstants.successToastConfiguration);
                fetchData();
            })
            .catch((err) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            });
    };

    const revokeRights = (rolesId: number, resourceId: number, rightId: number) => {
        revokeRightToResource(rolesId, resourceId, rightId)
            .then(() => {
                toast.success("Rights unassigned successfully", SiteConstants.successToastConfiguration);
                fetchData();
            })
            .catch((err) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            });
    };

    return (
        <>
            <ToastContainer />
            <Stack className={"section-background section-border overflowy-auto event-codes-list-container"}>
                <FixedHeaderSortingDetailsList
                    onSearchTextChange={(text: string) => setSearchText(text)}
                    searchText={searchText}
                    hideAddNewButton
                    columns={column}
                    isLoading={false}
                    searchFieldWidth={"30%"}
                    items={getResourceListFilteredItems(allResources, searchText)}
                    onRenderItemColumn={(item: any, index: number | undefined, column: IColumn | undefined) =>
                        renderResourcesListColumn(item, index, column, assignRights, revokeRights)
                    }
                />
            </Stack>
        </>
    );
};

export default ResourceRightDetailPanel;

import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ParticipantRefernceView from "./ReferenceItemInformation";
import QuizDocument from "../QuizDocument";
import "../RocketTemplateView.scss";
import ReferenceLogoView from "./ReferenceItemInfo";
import { useHistory, useParams } from "react-router";
import { Client } from "../../../Base/Client";
import { ApiConstants } from "../../../../constants/ApiConstant";
import ReferenceViewCoverImage from "./ReferenceItemCoverImage";
import QuizDocumentModal from "../QuizDocumentModal";
import { ReferenceInfoType, ReferenceItemDetails } from "./ReferenceItemDataTypes";
import { FieldValidation } from "../../../../constants/FieldValidation";
import JambarLogoView from "../QuizTitleLogoView";
import {
    ReferenceAttachmentTransformData,
    ReferenceItemTransformData,
    RefrenceTransformData
} from "./ReferenceItemTransformData";
import { attachmentType, SelectedDocumentType } from "../RocketTemplateUtils/RocketTemplateTypes";
import { SelectedDocumentInitialValues } from "../RocketTemplateUtils/RocketTemplateInitialValues";
import BackgroundImage from "../../../Images/background.png";
import { EventClosedMessage } from "../../../../constants/SiteConstant";

const FlightCrewView = () => {
    const { link, eventLink } = useParams<{ link: string; eventLink: string }>();
    const [referenceInfo, setReferenceInfoData] = useState<ReferenceInfoType>({
        title: "",
        description: "",
        referenceLogo: "",
        name: "",
        descriptionImage: "",
        coverImage: "",
        attachmentTitle: "",
        backgroundColor: "",
        backgroundImage: "",
        buttonColor: ""
    });
    const [eventInfo, setEventInfo] = useState({
        companyLogo: "",
        eventLogo: ""
    });
    const [refresnceItems, setRefresnceItems] = useState<ReferenceItemDetails[]>([]);
    const [showReferenceInfo, setReferenceInfo] = useState(false);
    const [loading, setLoading] = useState(true);
    const [attachmentList, setAttachmentList] = useState<attachmentType[]>([]);
    const [selectedDocument, setSelectedDocument] = useState<SelectedDocumentType>(SelectedDocumentInitialValues);
    const history = useHistory();

    const checkEventEnded = (message: string) => {
        message === EventClosedMessage &&
            history.push("/thankyou", {
                eventClosed: true
            });
    };

    const fetchReferenceDetail = (): void => {
        Client.getInstance()
            .getData(ApiConstants.getEventDataApiUrl(eventLink))
            .then((res: any) => {
                setEventInfo({
                    companyLogo: res.data.data.event.companyLogo || "",
                    eventLogo: res.data.data.event.logo || ""
                });
                Client.getInstance()
                    .getData(ApiConstants.getReferenceDetailsLinkApiUrl(link), true)
                    .then((response: any) => {
                        setReferenceInfoData(RefrenceTransformData(response));
                        document.title = response.data.data.name;
                        setRefresnceItems(ReferenceItemTransformData(response));
                        setLoading(false);
                        fetchReferenceAttachment(response.data.data.id);
                    });
            })
            .catch(() => {
                window.location.href = "/referenceerror";
            })
            .finally(() => setLoading(false));
    };

    const fetchReferenceAttachment = (id: string): void => {
        Client.getInstance()
            .getData(ApiConstants.getReferenceListAttachmentsApiUrl(parseInt(id)))
            .then((response) => {
                setAttachmentList(ReferenceAttachmentTransformData(response));
            });
    };

    useEffect(() => {
        fetchReferenceDetail();
    }, []);

    const checkEventPresent = () => {
        Client.getInstance()
            .getData(ApiConstants.getEventDataApiUrl(eventLink), true)
            .catch((err) => {
                checkEventEnded(err.response.data.message);
            });
    };

    useEffect(() => {
        document.addEventListener("click", checkEventPresent, true);
        return () => {
            document.removeEventListener("click", checkEventPresent, true);
        };
    }, []);

    if (loading) {
        return <Spinner className={"loading-component"} size={SpinnerSize.large} label={"Loading...."} />;
    }

    return (
        <div
            className={"main-container"}
            style={{
                backgroundImage: `url('${
                    referenceInfo.backgroundImage ? referenceInfo.backgroundImage : BackgroundImage
                }')`
            }}>
            {selectedDocument.documentSelected !== "" && (
                <div className={"document-cover-image-view position-absolute zIndex-9999 width-100per"}>
                    <div
                        className={
                            FieldValidation.imageTypes.includes(selectedDocument.selectedDocumentType)
                                ? "document-cover-image-section"
                                : "document-cover-image-section width-100per"
                        }>
                        <QuizDocumentModal
                            document={selectedDocument.documentSelected}
                            documentType={selectedDocument.selectedDocumentType}
                            documentClose={() => {
                                setSelectedDocument((prevState) => ({
                                    ...prevState,
                                    documentSelected: "",
                                    selectedDocumentType: ""
                                }));
                            }}
                            key={selectedDocument.documentSelected}
                        />
                    </div>
                </div>
            )}
            <Stack
                horizontal
                className={
                    selectedDocument.documentSelected
                        ? "quiz-container opacity-point3 pointer-event-none"
                        : "quiz-container"
                }>
                <Row className={"height-100per width-100per margin-0"}>
                    <Col sm={3} className={"margin-0 padding-0 height-100per"}>
                        <div className={"left-section-logo height-100per"}>
                            <JambarLogoView companyLogo={eventInfo.companyLogo} eventLogo={eventInfo.eventLogo} />
                        </div>
                        <div className={"left-section-reference-info height-100per"}>
                            <ReferenceLogoView
                                logo={referenceInfo.referenceLogo}
                                title={referenceInfo.name}
                                gameBackgroundColor={referenceInfo.backgroundColor}
                                infoBackgroundColor={referenceInfo.buttonColor}
                            />
                        </div>
                        <div className={"left-section-reference-document height-100per"}>
                            <QuizDocument
                                selectedDocumentType={(documentUrl: string, fileType: string) => {
                                    setSelectedDocument((prevState) => ({
                                        ...prevState,
                                        documentSelected: documentUrl,
                                        selectedDocumentType: fileType
                                    }));
                                }}
                                attachmentType={attachmentList}
                                attachmentTitle={referenceInfo.attachmentTitle}
                                gameBackgroundColor={referenceInfo.backgroundColor}
                            />
                        </div>
                    </Col>
                    <Col sm={6} className={"margin-0 padding-0 height-100per middle-section-ref-container"}>
                        <div className={"middle-section-reference"}>
                            <ParticipantRefernceView
                                title={referenceInfo.title || referenceInfo.name}
                                description={referenceInfo.description}
                                refrenceItems={refresnceItems}
                                showReferenceInfo={showReferenceInfo}
                                getReferenceInfo={() => setReferenceInfo(true)}
                                descriptionImage={referenceInfo.descriptionImage}
                                selectedDescriptionImage={(image: string) => {
                                    setSelectedDocument((prevState) => ({
                                        ...prevState,
                                        documentSelected: image,
                                        selectedDocumentType: "jpg"
                                    }));
                                }}
                                gameBackgroundColor={referenceInfo.backgroundColor}
                                buttonColor={referenceInfo.buttonColor}
                            />
                        </div>
                    </Col>
                    <Col sm={3} className={"margin-0 padding-0 height-100per middle-section-cover-container"}>
                        <div className={"right-section-refrence"}>
                            <ReferenceViewCoverImage
                                hideReferenceItemInfo={() => setReferenceInfo(false)}
                                displayReferenceItemInfo={showReferenceInfo}
                                coverImage={referenceInfo.coverImage}
                                gameBackgroundColor={referenceInfo.backgroundColor}
                            />
                        </div>
                    </Col>
                </Row>
            </Stack>
        </div>
    );
};

export default FlightCrewView;

import { object, string } from "yup";
import { ApiConstants } from "../../../../../constants/ApiConstant";
import { Client } from "../../../../Base/Client";

export const IdentityDetailValidationSchema = object().shape({
    identityName: string().required("Identity name is Mandatory")
});

export const addIdentity = (name: string): any =>
    Client.getInstance()
        .createData(ApiConstants.addIdentitiyApiUrl(), {
            name: name
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const updateIdentity = (name: string, id: number): any =>
    Client.getInstance()
        .updateData(ApiConstants.updateIdentitiyApiUrl(id), {
            name: name
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

import { Dialog } from "@mui/material";
import { FC } from "react";
import AddQuestion from "../addquestion";
import { EditGameType } from "../../gamelibrary/updategame/updategameform/Type";

type UpdateQuestionModalProps = {
    open: boolean;
    handleClose: () => void;
    selectedQuestionInfo: EditGameType;
    refreshList: () => void;
};
const UpdateQuestionModal: FC<UpdateQuestionModalProps> = ({
    open,
    handleClose,
    selectedQuestionInfo,
    refreshList
}) => {
    return (
        <Dialog
            PaperProps={{
                sx: {
                    padding: "20px"
                }
            }}
            maxWidth={"xl"}
            fullWidth
            open={open}
            onClose={handleClose}>
            <AddQuestion
                selectedQuestionId={{
                    questionId: selectedQuestionInfo.questionId,
                    gameId: selectedQuestionInfo.gameId
                }}
                handleClose={handleClose}
                refreshList={refreshList}
            />
        </Dialog>
    );
};

export default UpdateQuestionModal;

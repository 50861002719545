import { toast } from "react-toastify";
import { forgotPasswordUserEmail } from "./util";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import { SiteConstants } from "../../../constants/SiteConstant";
import { useHistory } from "react-router";
import { useState } from "react";
import { ForgotPasswordUserType } from "./type";

export const useForgotPassword = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const forgotPasswordSubmit = (value: ForgotPasswordUserType) => {
        setLoading(true);
        forgotPasswordUserEmail(value)
            .then(() => {
                toast.success("Password reset Successfully", SiteConstants.successToastConfiguration);
                history.push("/admin/login");
            })
            .catch((err) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        forgotPasswordSubmit,
        loading
    };
};

export const MovieQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "You don't go to the cinema, do you?",
            imageSrc: "https://i.imgur.com/xuYg8m9.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Pretty good, you know a bit about movies.",
            imageSrc: "https://i.imgur.com/tkkmFzG.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Fantastic! You know a lot about movies.",
            imageSrc: "https://i.imgur.com/yzTWkdB.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "What movie is this image taken from?",
            questionImageSrc: "https://i.imgur.com/5i5Qp2m.jpg",
            answerDescription:
                "Did you know that George Lucas, the brains behind Star Wars, also created the Indiana Jones franchise?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Ace Ventura",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Indiana Jones",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. James Bond",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Jurassic Park",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "What is this Marvel character’s name?",
            questionImageSrc: "https://i.imgur.com/YXtfh0n.jpg",
            answerDescription: "Gamora, the adopted daughter of Thanos, is played by Zoe Saldana.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Melina Vostokoff",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Drax",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Gamora",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Kara Zor-El",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Who does he play in the Fast & Furious franchise?",
            questionImageSrc: "https://i.imgur.com/XTd8t1o.jpg",
            answerDescription:
                "Dwayne Johnson has played the role of Hobbs in five films in the Fast & Furious franchise.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Luke Hobbs",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Tej Parker",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Roman Pearce",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Dominic Toretto",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "This is the cast of what classic movie?",
            questionImageSrc: "https://i.imgur.com/bQkEfmF.jpg",
            answerDescription:
                "This iconic movie was filmed in Austria and released in 1965. It won 5 Oscars, including the Best Picture award.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. The Good, the Bad, and the Ugly",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. The Godfather",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Star Wars",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. The Sound of Music",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "What relation does Erik Killmonger have to T'Challa?",
            questionImageSrc: "https://i.imgur.com/JH0mvZT.jpg",
            answerDescription:
                "Erik Killmonger/ Stevens is also known as N'Jadaka. His father was the brother of T'Challa’s father.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. brother-in-law",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. cousin",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. brother",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. no relation",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "Which Marvel character works as a parking valet in San Francisco?",
            questionImageSrc: "https://i.imgur.com/rNfJXxD.jpg",
            answerDescription: "After Shang-Chi moves to the U.S, he chooses to go by the name “Shaun”.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Black Widow",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Shang-Chi",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Baby Groot",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Bruce Banner",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "In the movie Suicide Squad, which actress plays Harley Quinn?",
            questionImageSrc: "https://i.imgur.com/s0WyFWB.jpg",
            answerDescription:
                "Viola Davis also has a role in Suicide Squad as Amanda Waller. Neither Angelina Jolie nor Lupita Nyong'o appears in this movie.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Margot Robbie",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Lupita Nyong'o",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Viola Davis",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Angelina Jolie",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "What was the highest-grossing comedy film of 1990?",
            questionImageSrc: "https://i.imgur.com/hXQKNwO.jpg",
            answerDescription:
                "Released in November 1990, this holiday favourite grossed $476.7 million at the international box office.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Titanic",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B.Shrek",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Mrs. Doubtfire",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Home Alone",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "What iconic car is used in Back to the Future?",
            questionImageSrc: "https://i.imgur.com/RLtFdqZ.jpg",
            answerDescription:
                "Did you know that the DeLorean was the only car manufactured by the DeLorean Motor Company (DMC)?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Mini 1000",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Dodge Charger",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. DMC DeLorean",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Aston Martin DB5",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "In the movie Wonder Woman, who does she ultimately destroy?",
            questionImageSrc: "https://i.imgur.com/kf48lA3.jpg",
            answerDescription: "In this 2017 movie, Ares, a son of Zeus, disguises himself as Sir Patrick Morgan.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Ares",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Green Goblin",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Poison Ivy",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Giganta",
                    isCorrect: false
                }
            ]
        }
    ]
};

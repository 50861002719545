export const SportsQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "Looks like you don't watch sports at all!",
            imageSrc: "https://i.imgur.com/Aksk6Ik.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Good try! Better luck next time.",
            imageSrc: "https://i.imgur.com/Vp4fJQ6.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Congrats! You're a trivia champion.",
            imageSrc: "https://i.imgur.com/OmyDrVM.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "Which basketball player currently has the most 3-pointers in the NBA?",
            questionImageSrc: "https://i.imgur.com/zl0RBfm.jpg",
            answerDescription:
                "In 2021, Curry broke the standing record of 2,973 3-pointers that was set in 2014. Did you know that Stephen’s dad played in the NBA, and his younger brother currently plays with the 76ers?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Stephen Curry",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Michael Jordan",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Tiger Woods",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Kobe Bryant",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "Who has the most goals for Argentina’s national football team?",
            questionImageSrc: "https://i.imgur.com/EyAYpGo.jpg",
            answerDescription:
                "Did you know that Messi began playing on the Argentina men’s national team in 2005, and currently has the most Ballon d'Or awards?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Diego Maradona",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Pelé",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. David Beckham",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Lionel Messi",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Who is this tennis player?",
            questionImageSrc: "https://i.imgur.com/eLhjBFM.jpg",
            answerDescription:
                "In 2018, at the age of 18, Naomi Osaka beat her role model Serena Williams to win the US Open, her first Grand Slam title.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Serena Williams",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Naomi Osaka",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Maria Sharapova",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Petra Kvitová",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "Which country has the most FIFA World Cup titles?",
            questionImageSrc: "https://i.imgur.com/f9B19Pm.jpg",
            answerDescription:
                "Brazil’s men’s national team has won a total of 5 World Cups. Did you know that Ronaldo has the most goals for Brazil at the World Cup?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. England",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Brazil",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Honduras",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Italy",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Who is this golfer?",
            questionImageSrc: "https://i.imgur.com/xayCIVI.jpg",
            answerDescription:
                "As a child, Tiger Woods was a prodigy and won his first Masters Tournament when he was only 21 years old.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Lewis Hamilton",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Phil Mickelson",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Bubba Watson",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Tiger Woods",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "How many of his 72 fights did this boxer lose?",
            questionImageSrc: "https://i.imgur.com/87iwKsP.jpg",
            answerDescription:
                "Did you know that Manny Pacquiao also played basketball, recorded several songs, served in the Philippines Army, and is running for president of his country?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 41",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 30",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 8",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. 10",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "Who has the most Olympic medals in this game?",
            questionImageSrc: "https://i.imgur.com/K3mQ27W.jpg",
            answerDescription:
                "As of 2022, China has won half of the total number of medals ever given out in table tennis at the Olympics.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Jamaica",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. China",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Canada",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Japan",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "What is this sport called?",
            questionImageSrc: "https://i.imgur.com/IgbdNEZ.jpg",
            answerDescription:
                "The surface that this game is played on is called the curling sheet, and the equipment used to play is called stones and brooms.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. curling",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. speed skating",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. bobsleigh",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. ice hockey",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "Name this piece of equipment that is used in badminton.",
            questionImageSrc: "https://i.imgur.com/J2su8br.jpg",
            answerDescription:
                "The shuttlecock, also known as the shuttle, is typically made from cork and bird feathers.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. ball",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. racket",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. shuttlecock",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. puck",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "Who has the most home runs in MLB baseball?",
            questionImageSrc: "https://i.imgur.com/9AXhaRl.jpg",
            answerDescription:
                "Did you know that in baseball, a home run is worth 4 points and can be achieved if the batter hits the ball out of the outfield (into the stands)?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Babe Ruth",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Barry Bonds",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Floyd Mayweather",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Colin Kaepernick",
                    isCorrect: false
                }
            ]
        }
    ]
};

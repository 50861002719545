import { Box, CircularProgress, Grid } from "@mui/material";
import { useState } from "react";
import SelectWrapper from "../../../../CommonComponent/v2/fields/SelectWrapper";
import {
    BatchChallengeChangeOptions,
    BulkUpdateVisibiltyTypeOptions,
    VisibiltyTypeOptions
} from "../../../../../constants/DropdownOption";
import { PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import { OptionType } from "../../../../CommonComponent/v2/fields/Type";
import { FILTER_OPTION_NAME, SelectedFilterType } from "../Type";
import MultiSelectWrapper from "../../../../CommonComponent/v2/fields/MultiSelectWrapper";

type BatchQuestionUpdateSectionProps = {
    confirmGameSet: (selectedOption: SelectedFilterType, resolve: () => void) => void;
    tagOptions: OptionType[];
    applyDisable?: boolean;
    hideTags?: boolean;
    identityDropdownOptions: OptionType[];
};

const BatchQuestionUpdateSection = ({
    confirmGameSet,
    tagOptions,
    applyDisable = false,
    hideTags = false,
    identityDropdownOptions
}: BatchQuestionUpdateSectionProps) => {
    const [selectedAction, setSelectedAction] = useState<FILTER_OPTION_NAME>(FILTER_OPTION_NAME.CHANGE_VISIBILITY);
    const [selectedVisibility, setSelectedVisibility] = useState(VisibiltyTypeOptions[0].value);
    const [selectedLimitedVisiblity, setSelectedLimitedVisiblity] = useState<string[]>([]);
    const [selectedTag, setSelectedTag] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const renderView = () => {
        if (selectedAction === FILTER_OPTION_NAME.CHANGE_VISIBILITY) {
            return (
                <>
                    {
                        <Box display={"flex"} gap={"10px"}>
                            <SelectWrapper
                                options={BulkUpdateVisibiltyTypeOptions}
                                value={selectedVisibility}
                                handleChange={setSelectedVisibility}
                                label={"Visibility"}
                                style={{ borderRadius: "10px" }}
                            />
                            {selectedVisibility === "limited" && (
                                <MultiSelectWrapper
                                    options={identityDropdownOptions || []}
                                    value={selectedLimitedVisiblity}
                                    handleOptionUpdate={setSelectedLimitedVisiblity}
                                    label={"Identity"}
                                    multiple
                                    style={{ borderRadius: "10px" }}
                                />
                            )}
                        </Box>
                    }
                </>
            );
        }

        return (
            <Box display={"flex"} gap={"10px"}>
                <MultiSelectWrapper
                    options={tagOptions}
                    value={selectedTag}
                    handleOptionUpdate={setSelectedTag}
                    label={"Tags"}
                    style={{ borderRadius: "10px" }}
                />
            </Box>
        );
    };
    const getSelectedOption = () => {
        if (selectedAction === FILTER_OPTION_NAME.CHANGE_VISIBILITY) {
            return selectedVisibility;
        }
        if ([FILTER_OPTION_NAME.ADD_TAGS, FILTER_OPTION_NAME.REMOVE_TAGS].includes(selectedAction)) {
            return selectedTag;
        }
    };
    return (
        <Grid container columnSpacing={"20px"}>
            <Grid item xs={3}>
                <SelectWrapper
                    options={BatchChallengeChangeOptions(hideTags).filter((item) => !item.hide)}
                    value={selectedAction}
                    handleChange={(value: string) => setSelectedAction(value as FILTER_OPTION_NAME)}
                    label={"Action"}
                    style={{ borderRadius: "10px" }}
                />
            </Grid>
            <Grid item xs={3}>
                {renderView()}
            </Grid>
            <Grid item xs={3}>
                <PrimaryButton
                    disabled={
                        applyDisable ||
                        isLoading ||
                        (selectedVisibility === "limited" && selectedLimitedVisiblity.length === 0)
                    }
                    startIcon={isLoading && <CircularProgress size={"16px"} />}
                    onClick={() => {
                        setIsLoading(true);
                        confirmGameSet(
                            {
                                selectedFilterName: selectedAction,
                                selectedFilterValue: getSelectedOption(),
                                identityFilterOption: selectedLimitedVisiblity
                            },
                            () => setIsLoading(false)
                        );
                    }}>
                    Apply
                </PrimaryButton>
            </Grid>
        </Grid>
    );
};

export default BatchQuestionUpdateSection;

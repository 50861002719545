import React, { FC } from "react";
import TimeoutImage from "../../../Images/Wildgoat/Timeout.svg";
import {
    WildGoatsCenterContainer,
    ContentBox,
    MainImage,
    WildGoatsContainerScroll,
    WildGoatsCorrectCenterContainer
} from "../CommonStyledComponents/CommonStyle";
import { ScoreText, SmallText, DescriptionText } from "../WrongAnswer/Style";

type QuestionTimeoutProps = {
    timeoutText: string;
    timeoutAnswerHeading: string;
    timeoutAnswerImage: string;
};
const QuestionTimeout: FC<QuestionTimeoutProps> = ({ timeoutText, timeoutAnswerHeading, timeoutAnswerImage }) => {
    return (
        <WildGoatsCorrectCenterContainer>
            <WildGoatsContainerScroll>
                <WildGoatsCenterContainer>
                    <ContentBox>
                        <WildGoatsCenterContainer>
                            <MainImage src={timeoutAnswerImage || TimeoutImage} alt="Timeout" />
                        </WildGoatsCenterContainer>
                        <SmallText style={{ fontSize: 24 }}>{timeoutAnswerHeading || "Sorry !"}</SmallText>
                        {!timeoutAnswerHeading && <ScoreText style={{ fontSize: 20 }}>It's a timeout.</ScoreText>}
                        <DescriptionText>
                            {timeoutText ? (
                                <span
                                    className={"quill-editor-text-align"}
                                    dangerouslySetInnerHTML={{
                                        __html: timeoutText
                                    }}
                                />
                            ) : (
                                `You did not answer the question within the time. Avoid taking too much time next time. This have
                        a negative impact on your achievements and slows down the adventure.`
                            )}
                        </DescriptionText>
                    </ContentBox>
                </WildGoatsCenterContainer>
            </WildGoatsContainerScroll>
        </WildGoatsCorrectCenterContainer>
    );
};

export default QuestionTimeout;

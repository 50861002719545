import React from "react";
import { usePathChallengeTeamName } from "./hook";
import {
    ContentBox,
    CustomInputBox,
    InfoText,
    LoginCircleButtonBig,
    MainImage,
    MainText,
    WildGoatsCenterContainer,
    WildGoatsContainer
} from "../../../WildGoat/CommonStyledComponents/CommonStyle";
import Header from "../../../WildGoat/Header/Header";
import loadingGif from "../../../../Images/loading.gif";
import TeamFlag from "../../../../Images/Wildgoat/teamFlag.svg";
import { getOffsetColor } from "../../../WildGoat/Util/Util";
import { InputGroup } from "react-bootstrap";
import { Icon } from "@fluentui/react";
import {
    pathQuizPrimaryColor,
    pathQuizSecondaryColor,
    pathQuizTertiaryColor
} from "../../../../../constants/ColorConstant";

const PathChallengeTeamName = () => {
    const { info, loading, name, setName, forwardToQuiz } = usePathChallengeTeamName();
    return (
        <WildGoatsContainer
            bgcolor={info.state.quiz.quizConfig?.wildgoatPrimaryColor || pathQuizPrimaryColor}
            bgcolor_offset={getOffsetColor(info.state.quiz.quizConfig?.wildgoatPrimaryColor || pathQuizPrimaryColor)}>
            <Header
                quizTitle={info.state.quiz.displayTitle}
                companyLogo={info.state.data.companyLogo}
                quizLogo={info.state.data.logo}
                secondaryColor={info.state.quiz.quizConfig?.wildgoatSecondaryColor || pathQuizSecondaryColor}
            />
            <WildGoatsCenterContainer style={{ marginTop: 11 }}>
                <ContentBox>
                    <WildGoatsCenterContainer>
                        <MainImage src={TeamFlag} alt="teamFlag" />
                    </WildGoatsCenterContainer>
                    <MainText>Team name</MainText>
                    <InfoText>Please enter your team name</InfoText>
                    <InputGroup size="sm" className={"margin-top-15"}>
                        <CustomInputBox
                            placeholder={"Enter name here"}
                            value={name}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setName(target.value)}
                        />
                    </InputGroup>
                    <LoginCircleButtonBig
                        secondaryColor={info.state.quiz.quizConfig?.wildgoatSecondaryColor || pathQuizSecondaryColor}
                        tertiarycolor={info.state.quiz.quizConfig?.wildgoatTertiaryColor || pathQuizTertiaryColor}
                        disabled={name === "" || loading}
                        onClick={forwardToQuiz}>
                        {loading ? (
                            <img src={loadingGif} style={{ height: 10 }} alt={"loading"} />
                        ) : (
                            <Icon iconName={"ChevronRight"} style={{ fontSize: 20 }} />
                        )}
                    </LoginCircleButtonBig>
                </ContentBox>
            </WildGoatsCenterContainer>
        </WildGoatsContainer>
    );
};

export default PathChallengeTeamName;

import { Stack } from "@fluentui/react";
import React, { FC, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import FixedHeaderSortingDetailsList from "../../../CommonComponent/FixedHeaderSortingDetilsList";
import { getEmailList } from "./Action";
import { ParticpantEmailColumns, renderParticpantListColumn } from "./ParticipantGrid";
import { getParticipantEmailListFilteredItems } from "./utils/helperFunctions";
import { ParticipantEmailType } from "./utils/type";

type ParticipantEmailListProps = {
    eventId: number;
};
const ParticipantEmailList: FC<ParticipantEmailListProps> = ({ eventId }) => {
    const [rows, setRows] = useState<ParticipantEmailType[]>([]);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getEmailList(eventId)
            .then((res) => {
                setRows(res);
            })
            .catch((err: any) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                setRows([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            <ToastContainer />
            <Stack className={"section-background section-border overflowy-auto event-codes-list-container"}>
                <FixedHeaderSortingDetailsList
                    onSearchTextChange={(text: string) => setSearchText(text)}
                    searchText={searchText}
                    hideAddNewButton
                    searchFieldWithoutLabel={true}
                    columns={ParticpantEmailColumns}
                    isLoading={isLoading}
                    items={getParticipantEmailListFilteredItems(rows, searchText)}
                    onRenderItemColumn={renderParticpantListColumn}
                    label={"Emails"}
                    searchFieldWidth={"30%"}
                />
            </Stack>
        </>
    );
};

export default ParticipantEmailList;

import { QuizType } from "./type";
import {
    ANIMAL,
    ANIMAL_HARD,
    ANIMAL_MEDIUM,
    GEOGRAPHY_GAME,
    GEOGRAPHY_HARD,
    GEOGRAPHY_JAMBAR,
    GEOGRAPHY_JAMBAR_MEDIUM,
    GEOGRAPHY_MEDIUM,
    HARRYPOTTER,
    MARVEL,
    MONEYHEIST,
    MOVIE,
    MOVIEMEDIUM,
    MOVIE_HARD,
    MUSIC,
    MUSIC_HARD,
    MUSIC_MEDIUM,
    RED_NOTICE,
    SHERLOCK,
    SPORTS,
    SPORTS_HARD,
    SPORTS_MEDIUM,
    SQUIDGAME
} from "./constants";
import { GeographyEndings, GeographyQuestions } from "../../data/websitegames/GeographyQuizData";
import { MusicQuizData } from "../../data/websitegames/MusicQuizData";
import { MovieQuizData } from "../../data/websitegames/MovieQuizData";
import { SportsQuizData } from "../../data/websitegames/SportsQuizData";
import { RedNoticeQuizData } from "../../data/websitegames/RedNoticeQuizData";
import { AnimalQuizData } from "../../data/websitegames/AnimalQuizData";
import { GeographyMediumQuizData } from "../../data/websitegames/GeographyMediumQuizData";
import { MusicMediumQuizData } from "../../data/websitegames/MusicMediumQuizData";
import { AnimalMediumQuizData } from "../../data/websitegames/AnimalMediumQuizData";
import { SportsMediumQuizData } from "../../data/websitegames/SportsMediumQuizData";
import { GeographyJambarQuizData } from "../../data/websitegames/GeogrpahyJambarQuizData";
import { GeographyHardQuizData } from "../../data/websitegames/GeographyHardQuizData";
import { MovieHardQuizData } from "../../data/websitegames/MoviewHardQuiz";
import { MusicHardQuizData } from "../../data/websitegames/MusicHardQuizData";
import { AnimalHardQuizData } from "../../data/websitegames/AnimalHardQuizData";
import { SportsHardQuizData } from "../../data/websitegames/SportsHardQuizData";
import { MarvelQuizData } from "../../data/websitegames/MarvelQuizData";
import { SherlockQuizData } from "../../data/websitegames/SherlockQuizData";
import { SquidGameQuizData } from "../../data/websitegames/SquidGameQuizData";
import { HarryPotterQuizData } from "../../data/websitegames/HarryPotterQuizData";
import { MoneyHeistQuizData } from "../../data/websitegames/MoneyHeistQuizData";
import { MovieMediumQuizData } from "../../data/websitegames/MovieMediumQuizData";

export const quizzes: QuizType[] = [
    {
        quizId: RED_NOTICE,
        quizTitle: "Red Notice Move Trivia",
        quizDescription:
            "How well do you know the 2021 Netflix release of the Movie Red Notice with Dwayne Johnson and Ryan Reynolds",
        endings: RedNoticeQuizData.endings,
        questions: RedNoticeQuizData.questions
    },
    {
        quizId: GEOGRAPHY_GAME,
        quizTitle: "Geography quiz to Play Online",
        quizDescription:
            "Geography is a field of science devoted to the study of the lands, features, inhabitants, and phenomena of the Earth and planets. Take a spin around and see how many of these Geography facts you know!",
        endings: GeographyEndings,
        questions: GeographyQuestions
    },
    {
        quizId: MUSIC,
        quizTitle: "Music Trivia",
        quizDescription:
            "How well do you know your music throughout the ages? Take this quiz to find out how much you know.",
        endings: MusicQuizData.endings,
        questions: MusicQuizData.questions
    },
    {
        quizId: MOVIE,
        quizTitle: "Movie Trivia",
        quizDescription: "How much do you know about movies? Find out by taking this fun quiz.",
        endings: MovieQuizData.endings,
        questions: MovieQuizData.questions
    },
    {
        quizId: SPORTS,
        quizTitle: "Sports Trivia",
        quizDescription: "How big of a sports fan are you?",
        endings: SportsQuizData.endings,
        questions: SportsQuizData.questions
    },
    {
        quizId: ANIMAL,
        quizTitle: "Animal Trivia",
        quizDescription: "Are you a true animal lover? Take this quiz to find out how much you know about animals.",
        endings: AnimalQuizData.endings,
        questions: AnimalQuizData.questions
    },
    {
        quizId: GEOGRAPHY_MEDIUM,
        quizTitle: "Geography Trivia - medium difficulty",
        quizDescription: "How much do you know about your awesome planet?",
        endings: GeographyMediumQuizData.endings,
        questions: GeographyMediumQuizData.questions
    },
    {
        quizId: MUSIC_MEDIUM,
        quizTitle: "Music Trivia",
        quizDescription: "How well do you know music? Take this quiz to find out if you're a rockstar!",
        endings: MusicMediumQuizData.endings,
        questions: MusicMediumQuizData.questions
    },
    {
        quizId: ANIMAL_MEDIUM,
        quizTitle: "Animal Trivia - medium difficulty",
        quizDescription: "Are you a true animal lover? Take this quiz to find out how much you know about animals.",
        endings: AnimalMediumQuizData.endings,
        questions: AnimalMediumQuizData.questions
    },
    {
        quizId: SPORTS_MEDIUM,
        quizTitle: "Sports Trivia - medium difficulty",
        quizDescription: "Are you a real sports fan?",
        endings: SportsMediumQuizData.endings,
        questions: SportsMediumQuizData.questions
    },
    {
        quizId: GEOGRAPHY_JAMBAR,
        quizTitle: "Geography Trivia",
        quizDescription:
            "Are you stuck at home with that feeling of wanderlust? Travel around the globe with this geography quiz.",
        endings: GeographyJambarQuizData.endings,
        questions: GeographyJambarQuizData.questions
    },
    {
        quizId: GEOGRAPHY_JAMBAR_MEDIUM,
        quizTitle: "Geography Trivia",
        quizDescription:
            "Are you stuck at home with that feeling of wanderlust? Travel around the globe with this geography quiz.",
        endings: GeographyJambarQuizData.endings,
        questions: GeographyJambarQuizData.questions
    },
    {
        quizId: GEOGRAPHY_HARD,
        quizTitle: "Geography Trivia - hard",
        quizDescription: "How much do you know about our amazing planet?",
        endings: GeographyHardQuizData.endings,
        questions: GeographyHardQuizData.questions
    },
    {
        quizId: MOVIE_HARD,
        quizTitle: "Movie Trivia - hard",
        quizDescription: "How much do you know about movies? Find out by taking this fun quiz.",
        endings: MovieHardQuizData.endings,
        questions: MovieHardQuizData.questions
    },
    {
        quizId: MUSIC_HARD,
        quizTitle: "Music Trivia",
        quizDescription: "Are you a true music fan? Take this quiz to find out!",
        endings: MusicHardQuizData.endings,
        questions: MusicHardQuizData.questions
    },
    {
        quizId: ANIMAL_HARD,
        quizTitle: "Animal Trivia - hard",
        quizDescription: "Are you a true animal lover? Take this quiz to find out how much you know about animals.",
        endings: AnimalHardQuizData.endings,
        questions: AnimalHardQuizData.questions
    },
    {
        quizId: SPORTS_HARD,
        quizTitle: "Sports Trivia",
        quizDescription: "How big of a sports fan are you?",
        endings: SportsHardQuizData.endings,
        questions: SportsHardQuizData.questions
    },
    {
        quizId: MARVEL,
        quizTitle: "Marvel movie quiz",
        quizDescription: "Are you a real Marvel fan? Find out by taking this fun quiz.",
        endings: MarvelQuizData.endings,
        questions: MarvelQuizData.questions
    },
    {
        quizId: SHERLOCK,
        quizTitle: "SHERLOCK HOLMES QUIZ",
        quizDescription: "How much do you know about the Sherlock Holmes film series?",
        endings: SherlockQuizData.endings,
        questions: SherlockQuizData.questions
    },
    {
        quizId: SQUIDGAME,
        quizTitle: "Squid Game",
        quizDescription: "How much do you know about the TV series Squid Game? Take this fun quiz to find out.",
        endings: SquidGameQuizData.endings,
        questions: SquidGameQuizData.questions
    },
    {
        quizId: HARRYPOTTER,
        quizTitle: "Harry Potter quiz",
        quizDescription: "Are you a real Harry Potter fan? Let's find out!",
        endings: HarryPotterQuizData.endings,
        questions: HarryPotterQuizData.questions
    },
    {
        quizId: MONEYHEIST,
        quizTitle: "Money Heist quiz",
        quizDescription: "How much do you know about the TV series Money Heist? Take this quiz to find out.",
        endings: MoneyHeistQuizData.endings,
        questions: MoneyHeistQuizData.questions
    },
    {
        quizId: MONEYHEIST,
        quizTitle: "Money Heist quiz",
        quizDescription: "How much do you know about the TV series Money Heist? Take this quiz to find out.",
        endings: MoneyHeistQuizData.endings,
        questions: MoneyHeistQuizData.questions
    },
    {
        quizId: MOVIEMEDIUM,
        quizTitle: "Movie Trivia - medium difficulty",
        quizDescription: "How much do you know about movies? Find out by taking this fun quiz.",
        endings: MovieMediumQuizData.endings,
        questions: MovieMediumQuizData.questions
    }
];

import { Grid } from "@mui/material";
import React, { FC } from "react";
import { DataCaptionText, DataHeaderText, PrimaryButton } from "../../../CommonComponent/Style";
import { EventContainer, EventList, EventTitle } from "./Style";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { successColor } from "../../../../constants/ColorConstant";
import { EventListItem } from "../../EventManager/EventListDataTypes";
import moment from "moment";
import { useHistory } from "react-router";
import LoadingWrapper from "../../../CommonComponent/LoadingWrapper";

type DashboardEventListProps = {
    upcomingEvents: EventListItem[];
    isLoading: boolean;
};
const DashboardEventList: FC<DashboardEventListProps> = ({ upcomingEvents, isLoading }) => {
    const history = useHistory();

    return (
        <EventContainer height={"100%"}>
            <Grid padding={"26px 22px"} container justifyContent={"space-between"}>
                <Grid item>
                    <EventTitle>Next Upcoming Events</EventTitle>
                </Grid>
                <Grid item>
                    <PrimaryButton onClick={() => history.push("/admin/eventmanager")} variant="contained">
                        See All
                    </PrimaryButton>
                </Grid>
            </Grid>
            <LoadingWrapper isLoading={isLoading}>
                {upcomingEvents.map((eventItem) => (
                    <EventList
                        key={eventItem.id}
                        alignItems={"center"}
                        padding={"20px"}
                        container
                        justifyContent={"space-between"}>
                        <Grid item xs={1}>
                            <FiberManualRecordIcon
                                style={{
                                    color: successColor
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <img src={eventItem.fileLocation} style={{ height: 61, width: 71 }} alt={"event"} />
                        </Grid>
                        <Grid item xs={7}>
                            <DataHeaderText>{eventItem.eventName}</DataHeaderText>
                            <DataCaptionText>{eventItem.gameTitle}</DataCaptionText>
                        </Grid>
                        <Grid item xs={2}>
                            <DataCaptionText>
                                {moment(eventItem.startDate).format("DD MMM YYYY")} -{" "}
                                {moment(eventItem.endDate).format("DD MMM YYYY")}
                            </DataCaptionText>
                        </Grid>
                    </EventList>
                ))}
            </LoadingWrapper>
        </EventContainer>
    );
};

export default DashboardEventList;

import { Stack } from "@fluentui/react";
import React from "react";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";

type IReferenceLogoViewNativeProps = {
    logo: string;
    title: string;
    gameBackgroundColor: string;
    infoBackgroundColor: string;
};

const ReferenceLogoView: React.FC<IReferenceLogoViewNativeProps> = ({ logo, title, gameBackgroundColor, infoBackgroundColor }) => {
    return (
        <Stack className={"quiz-info"} tokens={ThemedMediumStackTokens} style={{ background: gameBackgroundColor }}>
            <Stack style={{ backgroundColor: infoBackgroundColor }} className={"quiz-title height-25per"} horizontalAlign="center" verticalAlign="center">
                <span>{title}</span>
            </Stack>
            <Stack style={{ backgroundColor: infoBackgroundColor }} className={"quiz-title height-60per"} horizontalAlign="center" verticalAlign="center">
                <img src={logo} className={"max-height-100per max-width-100per"} />
            </Stack>
        </Stack>
    );
};

export default ReferenceLogoView;

import { CircularProgress, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { FC, useEffect } from "react";
import {
    CancelButton,
    CaptionText,
    IconContainer,
    PrimaryButton,
    StyledTag
} from "../../../../../../CommonComponent/v2/CommonStyle";
import FormikSingleDatePicker from "../../../../../../CommonComponent/v2/fields/FormikSingleDatePicker";
import FormikTextInput from "../../../../../../CommonComponent/v2/fields/FormikTextInput";
import CopyIcon from "../../../../../../Images/icons/copy.svg";
import { darkColor } from "../../../../../../WebsiteGame/colorConstant";
import { useAdminContext } from "../../../../../context/AdminAuthContext";
import { getEventCode } from "../../../../../EventManager/EventDetails/Util";
import { CodeType } from "../Type";

type SingleCodeFormProps = {
    eventIdsList: string[];
    closeModal: () => void;
    loading: boolean;
};
const SingleCodeForm: FC<SingleCodeFormProps> = ({ eventIdsList, closeModal, loading }) => {
    const { setFieldValue, values, errors, handleSubmit } = useFormikContext();
    const data: CodeType = values as CodeType;
    const { userDetail } = useAdminContext();

    useEffect(() => {
        setFieldValue("participantCode", getEventCode(eventIdsList));
        userDetail && setFieldValue("generatedBy", `${userDetail.firstName} ${userDetail.lastName}`);
    }, []);

    return (
        <Grid container mt={"37px"} justifyContent={"center"}>
            <Grid item xs={8} textAlign={"center"}>
                <CaptionText
                    color={darkColor}
                    fontWeight={700}
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                    gap={"30px"}>
                    Code
                    <StyledTag>
                        <CaptionText fontWeight={500} color={"#1F71B6"}>
                            {data.participantCode}
                        </CaptionText>
                    </StyledTag>
                    <IconContainer
                        onClick={() => window.navigator.clipboard.writeText(data.participantCode)}
                        src={CopyIcon}
                    />
                </CaptionText>
                <Grid container mt={"35px"} spacing={"20px"}>
                    <Grid item xs={6}>
                        <FormikSingleDatePicker name={"startDate"} dateLabel={"Valid from"} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikSingleDatePicker name={"endDate"} dateLabel={"Upto"} fullWidth />
                    </Grid>
                </Grid>
                <Grid container mt={"10px"} spacing={"20px"}>
                    <Grid item xs={12}>
                        <FormikTextInput name={"notes"} id={"notes"} multiline label={"Notes..."} rows={5} fullWidth />
                    </Grid>
                </Grid>
                <Grid container mt={"62px"} justifyContent={"center"}>
                    <Grid item>
                        <div style={{ display: "flex", gap: "20px" }}>
                            <CancelButton onClick={closeModal}>Cancel</CancelButton>
                            <PrimaryButton
                                onClick={() => handleSubmit()}
                                startIcon={loading && <CircularProgress size={"small"} />}
                                disabled={loading || !isEmpty(errors)}>
                                Save Code
                            </PrimaryButton>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SingleCodeForm;

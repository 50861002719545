import React, { useEffect, useRef, useState } from "react";
import { Stack } from "@fluentui/react";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import Timer from "../../../QuizView/Timer";
import { QUESTION_TIMER_TYPE } from "../../../../data/question/types";
import ScrollDownControl from "../ScrollDownControl";
import tool from "../../../Images/tool.png";
import { HelperFunctions } from "../../../../constants/HelperFns";
import SocketQuestionText from "./SocketQuestionText";
import { setColor } from "./util/util";
import { QuestionScrollViewContainer } from "../style";
import { SocketGame } from "../../../../constants/SiteConstant";

interface SocketQuestionViewProps {
    gameBackgroundColor: string;
    answerGiven: boolean;
    isQuestionNonScoring: string;
    answerResult: string;
    isQuestionTimeFinished: boolean;
    isTimerShow: boolean;
    setQuestionTiming: (time: string) => void;
    timing: string;
    questionText: string;
    coverImage: string;
    bottomQuestionText: string;
    showDocumentSelected: (coverImage: string) => void;
    timeoutText: string;
    correctAnswerText: string;
    answerResultInfoText: string;
    totalScore: string;
    correctAnswerPoints: string;
    wrongAnswerPoints: string;
    answerResultInfoImage: string;
    wrongAnswerText: string;
    isProgressInPercentage: boolean;
    videoLocation: string;
    setIsVideoPlayed: () => void;
    correctAnswerBackgroundColor: string;
    wrongAnswerBackgroundColor: string;
}

const SocketQuestionView: React.FC<SocketQuestionViewProps> = ({
    wrongAnswerText,
    answerResultInfoImage,
    correctAnswerPoints,
    wrongAnswerPoints,
    totalScore,
    answerResultInfoText,
    correctAnswerText,
    timeoutText,
    showDocumentSelected,
    bottomQuestionText,
    coverImage,
    questionText,
    timing,
    gameBackgroundColor,
    answerGiven,
    answerResult,
    isQuestionNonScoring,
    isQuestionTimeFinished,
    isTimerShow,
    setQuestionTiming,
    isProgressInPercentage,
    videoLocation,
    setIsVideoPlayed,
    correctAnswerBackgroundColor,
    wrongAnswerBackgroundColor
}) => {
    const [showDownwardIcon, setDownwardIcon] = useState(false);

    const scrollview = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let shouldDownwardIconDisplay =
            !!scrollview.current && scrollview.current.offsetHeight - 10 < scrollview.current.scrollHeight;

        if (
            shouldDownwardIconDisplay &&
            scrollview.current &&
            Math.abs(scrollview.current.scrollHeight - scrollview.current.scrollTop - 10).toFixed(0) <
                scrollview.current.clientHeight.toString()
        ) {
            shouldDownwardIconDisplay = false;
        }

        setDownwardIcon(shouldDownwardIconDisplay);
    }, [
        scrollview.current,
        scrollview.current && scrollview.current.scrollHeight,
        scrollview.current && scrollview.current.scrollTop,
        questionText
    ]);

    const moveScrollBarDown = () => {
        scrollview.current?.scrollTo(0, scrollview.current.scrollTop + 50);
    };

    const showQuestionText = (): JSX.Element => {
        return (
            <>
                <Stack
                    horizontal
                    verticalAlign={"center"}
                    horizontalAlign={"space-between"}
                    tokens={ThemedMediumStackTokens}
                    className={"questionTimerSection"}>
                    <Stack.Item>
                        <span className={!isTimerShow ? "question-Timing" : "display-none"}>
                            <Timer
                                type={QUESTION_TIMER_TYPE}
                                timer={timing}
                                getTiming={setQuestionTiming}
                                key={"quizTimer"}
                                eventFinishes={false}
                                quizType={SocketGame}
                            />
                        </span>
                    </Stack.Item>
                </Stack>
                <SocketQuestionText
                    bottomQuestionText={bottomQuestionText}
                    coverImage={coverImage}
                    questionText={questionText}
                    showDocumentSelected={showDocumentSelected}
                    videoLocation={videoLocation}
                    setIsVideoPlayed={setIsVideoPlayed}
                />
                {scrollview.current && showDownwardIcon && (
                    <ScrollDownControl
                        moveScrollBarDown={moveScrollBarDown}
                        height={scrollview.current?.offsetHeight}
                    />
                )}
            </>
        );
    };

    const correctAnswerInfo = (): JSX.Element => {
        return (
            <Stack verticalAlign={"center"} horizontalAlign={"center"} className={"height-100per"}>
                {correctAnswerText !== "" && (
                    <div
                        className={"correct-answer-text margin-p-0 quill-editor-text-align"}
                        dangerouslySetInnerHTML={{
                            __html: correctAnswerText
                        }}
                    />
                )}

                {!isQuestionNonScoring && (
                    <>
                        <span className={"correct-answer-text info-color"}>
                            {answerResultInfoText !== ""
                                ? HelperFunctions.replaceString(
                                      totalScore.toString(),
                                      correctAnswerPoints,
                                      answerResultInfoText
                                  )
                                : `${correctAnswerPoints} ${
                                      isProgressInPercentage && "%"
                                  } has been added to your ship's total repair status`}
                        </span>
                        <Stack horizontal verticalAlign={"center"} horizontalAlign={"center"}>
                            <img
                                src={answerResultInfoImage === "" ? tool : answerResultInfoImage}
                                className={"answer-result-image"}
                                alt={"Result"}
                            />
                            <span className={"points-text info-color"}>
                                +{correctAnswerPoints}
                                {isProgressInPercentage && "%"}
                            </span>
                        </Stack>
                    </>
                )}
            </Stack>
        );
    };

    const wrongAnswerInfo = (): JSX.Element => {
        return (
            <Stack verticalAlign={"center"} horizontalAlign={"center"} className={"height-100per"}>
                {wrongAnswerText !== "" && (
                    <span
                        className={"correct-answer-text margin-p-0 quill-editor-text-align"}
                        dangerouslySetInnerHTML={{
                            __html: wrongAnswerText
                        }}
                    />
                )}

                {!isQuestionNonScoring && (
                    <>
                        <span className={"correct-answer-text info-color"}>
                            {answerResultInfoText !== ""
                                ? HelperFunctions.replaceString(
                                      totalScore.toString(),
                                      wrongAnswerPoints,
                                      answerResultInfoText
                                  )
                                : `${wrongAnswerPoints}${
                                      isProgressInPercentage && "%"
                                  } has been taken from your ship's total repair status`}
                        </span>
                        <Stack horizontal verticalAlign={"center"}>
                            <img
                                src={answerResultInfoImage === "" ? tool : answerResultInfoImage}
                                className={"answer-result-image"}
                                alt={"Result"}
                            />
                            <span className={"points-text info-color"}>
                                {wrongAnswerPoints}
                                {isProgressInPercentage && "%"}
                            </span>
                        </Stack>
                    </>
                )}
            </Stack>
        );
    };

    const timeoutTextInfo = (): JSX.Element => {
        return (
            <Stack verticalAlign={"center"} horizontalAlign={"center"} className={"height-100per"}>
                <span
                    className={"correct-answer-text margin-p-0 quill-editor-text-align"}
                    dangerouslySetInnerHTML={{
                        __html: timeoutText
                    }}
                />
            </Stack>
        );
    };

    return (
        <QuestionScrollViewContainer
            style={{
                backgroundColor:
                    answerGiven && !isQuestionNonScoring
                        ? setColor(answerResult, correctAnswerBackgroundColor, wrongAnswerBackgroundColor)
                        : gameBackgroundColor
            }}
            ref={scrollview}
            className={
                answerGiven && !isQuestionNonScoring
                    ? answerResult === "correct"
                        ? "question-view correct-answer-view"
                        : "question-view wrong-answer-view"
                    : videoLocation
                    ? "video-question-view"
                    : "question-view padding-top-0"
            }>
            {isQuestionTimeFinished
                ? timeoutTextInfo()
                : !answerGiven
                ? showQuestionText()
                : answerResult === "correct"
                ? correctAnswerInfo()
                : wrongAnswerInfo()}
        </QuestionScrollViewContainer>
    );
};

export default SocketQuestionView;

import React, { FC } from 'react';
import DataGridWrapper from '../../../CommonComponent/v2/datagrid/DataGrid';
import { Grid } from '@mui/material';
import { useBranchQuestionListHook } from './Hooks';
import { BranchQuestionListType } from './Type';

type BranchQuestionListProps = {
    questionList: BranchQuestionListType[]
    handleRemoveQuestion: (questionId: number) => void
}
const BranchQuestionList: FC<BranchQuestionListProps> = ({
    questionList,
    handleRemoveQuestion
}) => {
    const { questionListColumns } = useBranchQuestionListHook();
    return (
        <Grid height={"400px"} mt={"30px"}>
            <DataGridWrapper
                columns={questionListColumns(handleRemoveQuestion)}
                rows={questionList as any}
            />
        </Grid>
    );
};

export default BranchQuestionList;

import Styled from "@emotion/styled";

export const FileImage = Styled.img`
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 16/9;
`;

export const VideoFile = Styled.video`
    border-radius: 10px;
    height: 100%;
    aspect-ratio: 16/9;
    width: 100%;
    height: 100%;
    background: black;
`;

export const FileIconContainer = Styled.div`
    display: flex;
    gap: 20px;
    position: relative;
    bottom: calc(100% - 27px);
    justify-content: end;
`;

import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { StyledColumn, StyledHeader } from "../../../../CommonComponent/v2/datagrid/DataGridStyle";
import { SelectUserDeleteType, transferOwnerType, UserListType } from "../Type";
import { getAllUserList } from "../Util";
import { transformTransferOwnerShip } from "./Util";
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai";

export const useTransferModalHooks = (selectDelete: SelectUserDeleteType) => {
    const [userList, setUserList] = useState<transferOwnerType[]>([]);
    const [transferId, setTransferId] = useState(-1);

    useEffect(() => {
        getAllUserList(false)
            .then((res: UserListType[]) => {
                setUserList(
                    transformTransferOwnerShip(res).filter(
                        (item) =>
                            parseInt(item.id) !== selectDelete.id && item.identityName === selectDelete.identityName
                    )
                );
            })
            .catch((err: any) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            });
    }, []);

    const UserTransferOwnerListColumns = (handleChange: (id: transferOwnerType) => void): GridColDef[] => [
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            renderHeader: () => <StyledHeader label="Email" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.email} />
        },
        {
            field: "firstName",
            headerName: "Name",
            flex: 1,
            renderHeader: () => <StyledHeader label="Name" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.firstName} />
        },
        {
            field: "identityName",
            headerName: "Identity",
            flex: 1,
            renderHeader: () => <StyledHeader label="Identity" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.identityName} />
        },
        {
            field: "Action",
            headerName: "Action",
            renderHeader: () => <StyledHeader label="Account selection" />,
            renderCell: (params: GridCellParams) =>
                params.row.isSelected ? (
                    <AiOutlineCheck
                        style={{
                            fontSize: 20,
                            cursor: "pointer",
                            color: "#65B44A"
                        }}
                        onClick={() => {
                            handleChange({
                                ...params.row,
                                isSelected: false
                            });
                        }}
                    />
                ) : (
                    <AiOutlinePlus
                        style={{
                            fontSize: 20,
                            cursor: "pointer",
                            color: "#1F71B6"
                        }}
                        onClick={() => {
                            handleChange({
                                ...params.row,
                                isSelected: true
                            });
                        }}
                    />
                )
        }
    ];

    return {
        userList,
        setUserList,
        UserTransferOwnerListColumns,
        transferId,
        setTransferId
    };
};

import React, { FC, useMemo } from "react";
import FileDropZone from "../../RocketTemplate/SocketGame/FileUpload/FileDropZone";
import { toast } from "react-toastify";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { SiteConstants, MaxUploadFileSize, VALID_VIDEO, VALID_IMAGE } from "../../../../constants/SiteConstant";
import UploadProgressBar from "../../RocketTemplate/SocketGame/UploadProgressBar";
import { Button, Col, Row } from "react-bootstrap";
import { CloseIcon, PhotoArea } from "./Style";
import { WildGoatsCenterContainer } from "../CommonStyledComponents/CommonStyle";
import { checkIfFilesAreCorrectType } from "../../../../utils/JambarUtils";

type PhotoQuestionProps = {
    uploadedPercentage?: number;
    uploadFiles: (files: File[]) => void;
    isFileUploadFailed: boolean;
    setFiles: (files: File[]) => void;
    files: File[];
    primaryColor: string;
};

const PhotoQuestion: FC<PhotoQuestionProps> = ({
    uploadedPercentage,
    uploadFiles,
    isFileUploadFailed,
    setFiles,
    files,
    primaryColor
}) => {
    const addFile = (file: File) => {
        const fileSize = file.size / 1024 / 1024;
        if (!FieldValidation.videoTypeValidation(file.name) && !FieldValidation.imageValidationByExtension(file.name)) {
            toast.success("File must be a video or image", SiteConstants.deleteToastConfiguration);
            return;
        }
        if (FieldValidation.videoTypeValidation(file.name) && fileSize > MaxUploadFileSize) {
            toast.success("Video must be less then 100mb", SiteConstants.deleteToastConfiguration);
            return;
        }
        if (FieldValidation.imageValidationByExtension(file.name) && fileSize > MaxUploadFileSize) {
            toast.success("Image must be less then 100mb", SiteConstants.deleteToastConfiguration);
            return;
        }
        uploadFiles && uploadFiles([file]);
        setFiles([...files, file]);
    };

    const renderFilesUploaded = useMemo(() => {
        return files.map((item: any) => (
            <div className={"fileContainer"} key={item.id} style={{ marginTop: 20 }}>
                <PhotoArea height={"auto"} className="dropzone height-100per">
                    <WildGoatsCenterContainer style={{ height: "100%" }}>
                        <CloseIcon
                            iconName="ChromeClose"
                            onClick={() => setFiles(files.filter((file: any) => file.id !== item.id))}
                            style={{ marginLeft: 10, zIndex: 999 }}
                        />
                        {
                            checkIfFilesAreCorrectType([item], VALID_VIDEO) && <video style={{
                                maxHeight: 200,
                                maxWidth: "100%"
                            }} controls>
                                <source src={URL.createObjectURL(item)} type="video/mp4" />
                                <source src={URL.createObjectURL(item)} type="video/webm" />
                            </video>
                        }
                        {
                            checkIfFilesAreCorrectType([item], VALID_IMAGE) && <img
                                style={{
                                    maxHeight: 200,
                                    maxWidth: "100%"
                                }}
                                src={URL.createObjectURL(item)}
                                alt="addPhotoIcon"
                            />
                        }
                    </WildGoatsCenterContainer>
                </PhotoArea>
                <br />
            </div>
        ));
    }, [files]);

    return (
        <>
            {uploadedPercentage && uploadedPercentage > 0 && files.length > 0 ? (
                <UploadProgressBar primaryColor={primaryColor} progress={uploadedPercentage} />
            ) : (
                ""
            )}
            <Row>
                <Col>{files.length > 0 && renderFilesUploaded}</Col>
                {files.length !== 0 && isFileUploadFailed && (
                    <Col>
                        <Button
                            className={
                                files.length === 0
                                    ? "pointer-event-none submit-button-option"
                                    : "submit-button-option cursor"
                            }
                            onClick={() => {
                                uploadFiles(files);
                            }}>
                            Retry
                        </Button>
                    </Col>
                )}
            </Row>
            {files.length === 0 && <FileDropZone setSelectedFile={addFile} isMobile={true} />}
        </>
    );
};

export default PhotoQuestion;

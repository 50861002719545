import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    OutlinedInputProps,
    TextFieldProps
} from "@mui/material";
import { useField } from "formik";
import { FC, useEffect, useState } from "react";
import { useDebounce } from "../../../../commonhooks/Hooks";
import { ColorInput } from "../../../Admin/v2/referencelistmanager/addNewReference/Style";
import { ColorChecker, StyledPasswordTextField, StyledTextField } from "./Style";

type FormikTextInputProps = {
    id: string;
    name: string;
    textfieldType?: "textField" | "password" | "color";
    setColor?: (color: string) => void;
} & OutlinedInputProps &
    TextFieldProps;

const FormikTextInput: FC<FormikTextInputProps> = ({ id, name, textfieldType, setColor, ...rest }) => {
    const [field, { value, error, touched }, { setTouched, setValue }] = useField(name);
    const [showPassword, setShowPassword] = useState(false);
    const [text, setText] = useState(value);

    const debounceValue = useDebounce(text, 100);

    useEffect(() => {
        setValue(debounceValue);
    }, [debounceValue]);

    if (textfieldType === "password") {
        return (
            <StyledPasswordTextField error={!!error && !!touched} fullWidth variant="outlined">
                <InputLabel>{rest.label}</InputLabel>
                <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    id={id}
                    {...field}
                    name={name}
                    onChange={({ target }) => setText(target.value)}
                    value={text}
                    autoComplete={"new-password"}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    {...rest}
                />
                {!!error && !!touched && <FormHelperText style={{ fontSize: "1rem" }}>{error}</FormHelperText>}
            </StyledPasswordTextField>
        );
    }

    if (textfieldType === "color") {
        return (
            <Grid container spacing={"10px"}>
                <Grid item xs={11}>
                    <StyledTextField
                        {...field}
                        id={id}
                        name={name}
                        onBlur={() => setTouched(true)}
                        autoComplete={"off"}
                        onChange={({ target }) => setText(target.value)}
                        value={text}
                        error={!!error && !!touched}
                        helperText={!!error && !!touched ? error : ""}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton>
                                        <ColorInput
                                            color={text}
                                            type="color"
                                            id="head"
                                            name="backgroundColor"
                                            value={text}
                                            onChange={({ target }) => {
                                                setText(target.value);
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        {...rest}
                    />
                </Grid>
                <Grid item xs={1}>
                    <ColorChecker backgroundcolor={text} />
                </Grid>
            </Grid>
        );
    }
    return (
        <StyledTextField
            {...field}
            id={id}
            name={name}
            onBlur={() => setTouched(true)}
            autoComplete={"off"}
            onChange={({ target }) => setText(target.value)}
            value={text}
            error={!!error && !!touched}
            helperText={!!error && !!touched ? error : ""}
            variant="outlined"
            {...rest}
        />
    );
};

export default FormikTextInput;

export const CustomTextfield: FC<FormikTextInputProps> = ({ label, ...rest }) => (
    <StyledTextField
        autoComplete={"off"}
        variant="outlined"
        sx={{
            "& legend": { display: label ? "" : "none" },
            "& fieldset": { top: label ? "" : 0 }
        }}
        {...rest}
    />
);

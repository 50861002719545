import { Question, QuestionTags } from "../data/question/types";

export const filterQuestions = (searchText: string, selectedTag: number, questionList: Question[]): Question[] => {
    const filteredItems =
        searchText === "" && selectedTag === -1
            ? questionList
            : questionList.filter((question: Question) =>
                  matchQuestionData(
                      searchText.toLowerCase(),
                      selectedTag,
                      question,
                      question.tags
                          .map((tag: QuestionTags) => tag.value)
                          .toString()
                          .toLowerCase()
                  )
              );
    return filteredItems;
};

const itemMatchesSearchText = (item: string, searchText: string) => item.toLowerCase().includes(searchText);

const dateMatchesSearchText = (date: string, searchText: string) => date.includes(searchText);

const matchQuestionData = (searchText: string, tagId: number, question: Question, questionTags: string): boolean =>
    (itemMatchesSearchText(question.title, searchText) ||
        itemMatchesSearchText(questionTags, searchText) ||
        itemMatchesSearchText(question.createdBy, searchText) ||
        dateMatchesSearchText(question.createdDate, searchText) ||
        itemMatchesSearchText(question.lastUpdatedBy, searchText) ||
        dateMatchesSearchText(question.lastUpdatedDate, searchText)) &&
    (tagId > -1 ? !!question.tags.find((tag) => tag.id === tagId) : true);

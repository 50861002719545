import { IColumn } from "@fluentui/react";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { ParticipantEmailType } from "./utils/type";

export const renderParticpantListColumn = (
    item: any,
    _: number | undefined,
    column: IColumn | undefined
): JSX.Element => {
    if (!column) {
        return <span />;
    }

    switch (column.key) {
        case "email":
            return <span>{item.email}</span>;

        default: {
            const fieldContent = item[column.fieldName as keyof ParticipantEmailType] as string;
            return <span>{fieldContent}</span>;
        }
    }
};

export const ParticpantEmailColumns: IColumn[] = [
    {
        key: "id",
        name: "Id",
        fieldName: "id",
        minWidth: SiteConstants.listColumnSmall,
        maxWidth: SiteConstants.listColumnSmall
    },
    {
        key: "email",
        name: "Email",
        fieldName: "email",
        minWidth: SiteConstants.listColumnMedium,
        maxWidth: SiteConstants.listColumnLarge
    }
];

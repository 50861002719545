import { object, ref, string } from "yup";
import { IdentityListtype, RegsiterUserFormDetailType } from "./type";
import { ApiConstants } from "../../../constants/ApiConstant";
import { Client } from "../../Base/Client";
import { JambarDateUtil } from "../../../constants/JambarDateUtils";

export const registerDetailFormInitialValues = ({
    email,
    firstName,
    lastName
}: {
    email?: string;
    firstName?: string;
    lastName?: string;
}): RegsiterUserFormDetailType => ({
    firstName: firstName,
    lastName: lastName,
    password: "",
    confirmPassword: "",
    email: email || ""
});

export const ChangePasswordValidationSchema = object().shape({
    password: string()
        .required("Password is Mandatory")
        .min(8, "Minimum 8 character")
        .max(64, "Maximum 64 character")
        .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
        .matches(/[a-z]/, "Password must contain at least 1 lowercase letter")
        .matches(/[0-9]/, "Password must contain at least 1 number")
        .matches(/[!@#$%^&*]/, "Password must contain at least 1 special character")
        .required("Password is Mandatory"),
    confirmPassword: string()
        .required("Confirm password is required")
        .oneOf([ref("password"), null], "Passwords must match")
});

export const UserDetailValidationSchema = () =>
    object().shape({
        email: string().email("Please enter correct email id").required("Email is Mandatory"),
        firstName: string().required("First Name is Mandatory"),
        password: string().when("isCompany", {
            is: () => false,
            then: string()
                .min(8, "Minimum 8 character")
                .max(64, "Maximum 64 character")
                .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
                .matches(/[a-z]/, "Password must contain at least 1 lowercase letter")
                .matches(/[0-9]/, "Password must contain at least 1 number")
                .matches(/[!@#$%^&*]/, "Password must contain at least 1 special character")
                .required("Password is Mandatory"),
            otherwise: string()
        }),
        confirmPassword: string().when("isCompany", {
            is: () => false,
            then: string()
                .required("Confirm password is required")
                .oneOf([ref("password"), null], "Passwords must match"),
            otherwise: string()
        })
    });

export const registerNewUserInfo = (value: RegsiterUserFormDetailType, userId: string, verificationCode: string): any =>
    Client.getInstance()
        .updateData(ApiConstants.signupNewUserApiUrl(userId), {
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            password: value.password,
            verificationCode: verificationCode
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

const identitytranformData = (data: any): IdentityListtype[] => {
    if (!data) {
        return [];
    }
    return data.map(
        (item: any) =>
            ({
                id: item.id,
                createdAt: JambarDateUtil.formatDate(item.createdAt),
                name: item.name,
                updatedAt: JambarDateUtil.formatDate(item.updatedAt),
                createdBy: item.createdBy,
                subscriptionStatus: item.subscriptionStatus
            } as IdentityListtype)
    );
};

export const getAllIdentitiesList = (forceRefresh = false): any =>
    Client.getInstance()
        .getData(ApiConstants.getAllIdentitiesList(), forceRefresh)
        .then((res) => Promise.resolve(identitytranformData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const transformIdentityDropdown = (data: any) => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        value: item.id,
        label: item.name
    }));
};

import { toast } from "react-toastify";
import { RegsiterUserFormDetailType } from "./type";
import { getAllIdentitiesList, registerNewUserInfo, transformIdentityDropdown } from "./util";
import { SiteConstants } from "../../../constants/SiteConstant";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import { useEffect, useState } from "react";
import { OptionType } from "../../CommonComponent/v2/fields/Type";
import { useHistory } from "react-router";
import { ApiConstants } from "../../../constants/ApiConstant";
import { Client } from "../../Base/Client";
import { LoginUtil } from "../../../utils/LoginUtils";
import { useAdminContext } from "../context/AdminAuthContext";
import { transformLoginData } from "../Login/util";

export const useRegister = () => {
    const [loading, setLoading] = useState(false);
    const { setUserDetail } = useAdminContext();
    const searchParams = new URLSearchParams(window.location.search);
    const history = useHistory();
    const registerNewUser = (values: RegsiterUserFormDetailType) => {
        setLoading(true);
        const userId = searchParams.get("userId");
        const verificationCode = searchParams.get("verificationCode");
        userId &&
            verificationCode &&
            registerNewUserInfo(values, userId, verificationCode)
                .then(() => {
                    toast.success(
                        "Congratulations ! Your account has been created",
                        SiteConstants.successToastConfiguration
                    );
                    authenticateUser(values);
                })
                .catch((err: any) => {
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => {
                    setLoading(false);
                });
    };

    const authenticateUser = (values: RegsiterUserFormDetailType): void => {
        setLoading(true);
        Client.getInstance()
            .createData(ApiConstants.authenticateUserApiUrl(), { email: values.email, password: values.password })
            .then((response: any) => {
                Client.getInstance().setAuthenticationClient(response.headers.authorization);
                LoginUtil.setUserSession(response.headers.authorization, response.data);
                setUserDetail(transformLoginData(response.data.data));
                setTimeout(() => {
                    history.push("/admin/login");
                }, 1000);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    return {
        loading,
        setLoading,
        registerNewUser,
        searchParams
    };
};

export const useRegisterFormHook = () => {
    const [identityDropdown, setIdentityDropdown] = useState<OptionType[]>([]);
    useEffect(() => {
        getAllIdentitiesList().then((res: any) => {
            setIdentityDropdown(transformIdentityDropdown(res));
        });
    }, []);

    return {
        identityDropdown
    };
};

import { Label } from "@fluentui/react";
import React, { FC, useCallback, useEffect, useState } from "react";
import { MCQAnswerOption } from "../../../../data/question/types";
import { ScrollViewContainer } from "../style";

type SocketGameAnswerHistoryProps = {
    gameBackgroundColor: string;
    mcqOptions: MCQAnswerOption[];
};
const SocketGameAnswerHistory: FC<SocketGameAnswerHistoryProps> = ({ gameBackgroundColor, mcqOptions }) => {
    const [answerHistory, setAnswerHistory] = useState<
        {
            submittedAnswer: string;
            participantName: string;
        }[]
    >([]);

    useEffect(() => {
        if (localStorage.getItem("currentAnswerHistory")) {
            setAnswerHistory(JSON.parse(localStorage.getItem("currentAnswerHistory") || "[]"));
        }
    }, [localStorage.getItem("currentAnswerHistory")]);

    const renderSubmittedAnswer = useCallback(
        (answerText: string) => {
            if (mcqOptions.length > 0) {
                const selectedAnswerId = answerText.split(";");
                const selectedAnswerText: string[] = [];
                mcqOptions.map((item) => {
                    if (selectedAnswerId.includes(item.id.toString())) {
                        selectedAnswerText.push(item.text);
                    }
                });
                return selectedAnswerText.join("; ");
            }
            return answerText;
        },
        [mcqOptions]
    );

    return (
        <ScrollViewContainer className={"quiz-answer-history-view"} style={{ backgroundColor: gameBackgroundColor }}>
            <Label className={"attachment-title"}>Answer history</Label>
            {answerHistory.map((item, index) => (
                <div key={index}>
                    <span className={"answerSection"}>
                        Attempt {index + 1}: {renderSubmittedAnswer(item.submittedAnswer)} - {item.participantName}
                    </span>
                </div>
            ))}
        </ScrollViewContainer>
    );
};

export default SocketGameAnswerHistory;

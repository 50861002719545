import { toast } from "react-toastify";
import { VerifyUserType } from "./type";
import { verifyUserEmail } from "./util";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import { SiteConstants } from "../../../constants/SiteConstant";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";

export const useVerifyUser = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const [error, setError] = useState(false);

    useEffect(() => {
        verifyUserSubmit({
            email:
                searchParams.get("email").split(" ").length > 1
                    ? searchParams.get("email").split(" ").join("+")
                    : searchParams.get("email"),
            verifyCode: searchParams.get("verificationCode")
        });
    }, []);

    const verifyUserSubmit = (value: VerifyUserType) => {
        setLoading(true);
        setError(false);
        verifyUserEmail(value)
            .then((res) => {
                toast.success(
                    "Thank you for verifying your email. Verification is complete.",
                    SiteConstants.successToastConfiguration
                );
                history.push(
                    `/register?email=${value.email}&firstName=${res.firstName}&lastName=${res.lastName}&userId=${
                        res.userId
                    }&verificationCode=${searchParams.get("verificationCode")}`
                );
            })
            .catch((err) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        verifyUserSubmit,
        loading,
        searchParams,
        error,
        history
    };
};

import { Stack } from "office-ui-fabric-react";
import Styled from "@emotion/styled";
import { scrollColor } from "../../../constants/Styles";

export const QuestionScrollViewContainer = Styled.div`
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px ${scrollColor};
        border-radius: 10px;
        filter: brightness(200%);
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${scrollColor};
        filter: brightness(100%);
        border-radius: 10px;
    }
`;

export const ScrollViewContainer = Styled(Stack)`
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
        filter: brightness(200%);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d2d1d1;
        filter: brightness(100%);
        border-radius: 10px;
    }
`;

import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import { whiteColor } from "../../../../../constants/Styles";
import { CancelButton, CaptionText, Header1, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import FormikSelectAutocomplete from "../../../../CommonComponent/v2/fields/FormikSelectAutocomplete";
import FormikSingleDatePicker from "../../../../CommonComponent/v2/fields/FormikSingleDatePicker";
import { useDecideGameHook } from "./Hooks";
import { GameButton } from "./Style";
import { EventType } from "./Type";
import { gameOptions } from "./Util";

type DecideGameProps = {
    isUpdate?: boolean;
    nextButtonClick?: () => void;
    backButtonClick?: () => void;
};
const DecideGame: FC<DecideGameProps> = ({ nextButtonClick, backButtonClick, isUpdate = false }) => {
    const { values, errors, setValues }: any = useFormikContext();
    const data: EventType = values as EventType;
    const { gameList, permissionQuizFullGame, permissionQuizPhysicalGame, permissionQuizSocketGame } =
        useDecideGameHook();

    const renderImage = (
        isUpdate: boolean,
        gameType: string,
        currentGameType: string,
        disabledIcon?: JSX.Element,
        activeIcon?: JSX.Element,
        inActiveIcon?: JSX.Element
    ) => {
        if (isUpdate) {
            return <>{disabledIcon}</>;
        }
        if (gameType === currentGameType) {
            return <>{inActiveIcon}</>;
        }
        return <>{activeIcon}</>;
    };

    return (
        <Grid container width={"576px"} py={isUpdate ? "15px" : "40px"}>
            <Grid item xs={12}>
                <Header1>{"Event Design"}</Header1>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    key={data.gameType}
                    display={"flex"}
                    flexWrap={"nowrap"}
                    overflow={"auto"}
                    container
                    gap={"20px"}
                    py={"20px"}
                    mt={"30px"}>
                    {gameOptions(
                        permissionQuizFullGame?.includes("fetch"),
                        permissionQuizSocketGame?.includes("fetch"),
                        permissionQuizPhysicalGame?.includes("fetch")
                    )
                        .filter((item) => item.toShow)
                        .map((item) => (
                            <GameButton
                                textAlign={"center"}
                                item
                                xs={4}
                                style={{
                                    boxShadow: "1px 1px 10px #efefef",
                                    border: "1px #efefef solid"
                                }}
                                variant={isUpdate ? "secondary" : "primary"}
                                key={`${item.id}`}
                                isdisabled={isUpdate && data.gameType !== item.value}
                                isactive={data.gameType === item.value}
                                onClick={() => {
                                    setValues({
                                        ...values,
                                        gameType: item.value,
                                        gameId: ""
                                    });
                                }}>
                                {renderImage(
                                    isUpdate,
                                    data.gameType,
                                    item.value,
                                    item.isDisabledIcon,
                                    item.icon,
                                    item.inActiveicon
                                )}
                                <CaptionText
                                    mt={"5px"}
                                    color={isUpdate ? "#828282" : data.gameType === item.value ? whiteColor : "#1F71B6"}
                                    textAlign={"center"}>
                                    {item.title}
                                </CaptionText>
                            </GameButton>
                        ))}
                </Grid>
            </Grid>
            <Grid item xs={12} mt={"30px"}>
                <FormikSelectAutocomplete
                    options={gameList
                        .filter((item) => item.type === data.gameType)
                        .map((opt) => ({
                            value: opt.value,
                            label: opt.label || ""
                        }))}
                    name={"gameId"}
                />
            </Grid>
            <Grid item xs={12} mt={"30px"}>
                <Grid container columnSpacing={"20px"}>
                    <Grid item xs={6}>
                        <FormikSingleDatePicker name={"startDate"} dateLabel={"Valid from"} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikSingleDatePicker name={"endDate"} dateLabel={"Upto"} fullWidth />
                    </Grid>
                </Grid>
            </Grid>

            {!isUpdate && nextButtonClick && (
                <Grid item xs={12}>
                    <Grid container mt={"68px"} justifyContent={"center"}>
                        <Grid item>
                            <div style={{ display: "flex", gap: "20px" }}>
                                <CancelButton fontsize="16px" onClick={backButtonClick}>
                                    Back
                                </CancelButton>
                                <PrimaryButton
                                    fontsize="16px"
                                    onClick={nextButtonClick}
                                    disabled={errors.gameType || errors.gameId || errors.startDate || errors.endDate}>
                                    Next
                                </PrimaryButton>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default DecideGame;

import Styled from "@emotion/styled";
import { Grid } from "@mui/material";

type GameButtonProps = {
    isactive: boolean;
    isdisabled?: boolean;
    variant?: "primary" | "secondary";
};
export const GameButton = Styled(Grid)<GameButtonProps>`
    background: ${(props) => (props.isactive ? "#1F71B6" : "#FCFCFC")} ;
    box-shadow: 0px 22px 80px rgba(0, 0, 0, 0.0168519), 0px 14.2593px 46.8519px rgba(0, 0, 0, 0.0274815), 0px 8.47407px 25.4815px rgba(0, 0, 0, 0.035), 0px 4.4px 13px rgba(0, 0, 0, 0.0425185), 0px 1.79259px 6.51852px rgba(0, 0, 0, 0.0531481), 0px 0.407407px 3.14815px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 34px 24px 26px 24px;
    height: 148px;
    width: 178px;
    cursor: pointer;
    ${(props) =>
        props.isdisabled &&
        `
        display: none;
    `}
    ${(props) =>
        props.variant === "secondary" &&
        `
        pointer-events: none;
        background: #F2F2F2 !important; 
    `}

    svg {
        color: ${(props) => (props.isactive ? "#1F71B6" : "#FCFCFC")} ;
        ${(props) =>
            props.variant === "secondary" &&
            `
        color: #828282 !important; 
    `}
    }
`;

export const StyledLogo = Styled.img`
    height: 100%;
    border-radius: 50%;
    aspect-ratio: 16/9;
    width: 100%;
    object-fit: contain;
`;

import Question1 from "../../Component/WebsiteGame/image/question1.jpg";
import Question2 from "../../Component/WebsiteGame/image/question2.jpg";
import Question3 from "../../Component/WebsiteGame/image/question3.jpg";
import Question4 from "../../Component/WebsiteGame/image/question4.jpg";
import Question5 from "../../Component/WebsiteGame/image/question5.jpg";
import Question6 from "../../Component/WebsiteGame/image/question6.jpg";
import Question7 from "../../Component/WebsiteGame/image/question7.jpg";
import Question8 from "../../Component/WebsiteGame/image/question8.jpg";
import Question9 from "../../Component/WebsiteGame/image/question9.jpg";
import Question10 from "../../Component/WebsiteGame/image/question10.jpg";

export const RedNoticeQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 2,
            text: "Poor",
            imageSrc: "https://i.imgur.com/xuYg8m9.jpg"
        },
        {
            scoreMin: 3,
            scoreMax: 7,
            text: "Average",
            imageSrc: "https://i.imgur.com/tkkmFzG.jpg"
        },
        {
            scoreMin: 8,
            scoreMax: 10,
            text: "Excellent",
            imageSrc: "https://i.imgur.com/yzTWkdB.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle:
                "The name of the famous painting seen early in the movie that Nolan Booth (Ryan Reynolds) had previously stolen was ‘’Lady with a **** Hat’’",
            questionImageSrc: Question1,
            answerDescription:
                "The Lady with the Red Hat - This is actually a true life oil on canvas artwork from 1918 painted by artist and painter William Strang. It resides in real life at the Kelvingrove Art Gallery and Museum in Glasgow, Scotland.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                { id: 1, text: "A. Red", isCorrect: true },
                { id: 2, text: "B. Grey", isCorrect: false },
                { id: 3, text: "C. Black", isCorrect: false },
                { id: 4, text: "D. Purple", isCorrect: false }
            ]
        },
        {
            questionId: 2,
            questionTitle: "What brand of tequila is Dwayne Johnson seen drinking in the movie?",
            questionImageSrc: Question2,
            answerDescription:
                "Created in a small town in the highlands of Jalisco, founder Dwayne Johnson worked with a Mexican family-owned distillery to build a unique home on the grounds solely for Teremana - Destilería Teremana de Agave",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                { id: 1, text: "A. Don Julio 1942", isCorrect: false },
                { id: 2, text: "B. Casamigos Reposado", isCorrect: false },
                { id: 3, text: "C. El Tesoro", isCorrect: false },
                { id: 4, text: "D. Teremana", isCorrect: true }
            ]
        },
        {
            questionId: 3,
            questionTitle:
                'The boat that Nolan Booth (Ryan Reynolds) is steering onto the beach in Bali has the wording "We’re Gonna Make It, Rose" printed on it, a reference to which film?',
            questionImageSrc: Question3,
            answerDescription: "It refers to the Oscar-winning film, Titanic.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                { id: 1, text: "A. Deadpool", isCorrect: false },
                { id: 2, text: "B. Titanic", isCorrect: true },
                { id: 3, text: "C. Jumanji", isCorrect: false },
                { id: 4, text: "D. Rampage", isCorrect: false }
            ]
        },
        {
            questionId: 4,
            questionTitle: "By the time Red Notice was released, what was the reported cost of the project?",
            questionImageSrc: Question4,
            answerDescription:
                "The film was greenlit with an estimated production budget of $160 million, by the time it was released the reported cost had reached $200 million, making it the most-costly in Netflix’s history.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                { id: 1, text: "A. 150 million USD", isCorrect: false },
                { id: 2, text: "B. 200 million USD", isCorrect: true },
                { id: 3, text: "C. 250 million USD", isCorrect: false },
                { id: 4, text: "D. 300 million USD", isCorrect: false }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Which well known musician provided a cameo in Red Notice?",
            questionImageSrc: Question5,
            answerDescription:
                "Ed Sheeran did indeed cameo in Red Notice and was looking forward to meeting the main cast, when he turned up the main cast had already filmed their bits and were not there!.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                { id: 1, text: "A. Katy Perry", isCorrect: false },
                { id: 2, text: "B. Justic Beiber", isCorrect: false },
                { id: 3, text: "C. Julstin Timerlake", isCorrect: false },
                { id: 4, text: "D. Ed Sheeran", isCorrect: true }
            ]
        },
        {
            questionId: 6,
            questionTitle:
                'In 2021 "Red Notice" became the most watched movie in Netlix history, with over 328 million view hours.  Which film held the the previous record?',
            questionImageSrc: Question6,
            answerDescription:
                "Ed Sheeran did indeed cameo in Red Notice and was looking forward to meeting the main cast, when he turned up the main cast had already filmed their bits and were not there!.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                { id: 1, text: "A. Thunder Force", isCorrect: false },
                { id: 2, text: "B. Fatherhood", isCorrect: false },
                { id: 3, text: "C. Bird Box", isCorrect: true },
                { id: 4, text: "D. Army Of The Dead", isCorrect: false }
            ]
        },
        {
            questionId: 7,
            questionTitle:
                'A "****" Notice is a global police alert for a missing person. It is published for victims of parental abductions, criminal abductions (kidnappings) or unexplained disappearances.',
            questionImageSrc: Question7,
            answerDescription:
                "A yellow notice is for missing persons. A Red Notice is an international wanted persons notice, but it is not an arrest warrant. ",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                { id: 1, text: "A. Red", isCorrect: false },
                { id: 2, text: "B. Yellow", isCorrect: true },
                { id: 3, text: "C. Green", isCorrect: false },
                { id: 4, text: "D. Blue", isCorrect: false }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Is the story behind Cleopatra Eggs real in Red Notice?",
            questionImageSrc: Question8,
            answerDescription:
                "Cleopatra Eggs in Red Notice was sheer fiction and were the invention of director and writer Rawson Marshall Thurber.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                { id: 1, text: "A. Yes", isCorrect: false },
                { id: 2, text: "B. No", isCorrect: true }
            ]
        },
        {
            questionId: 9,
            questionTitle: "What is Dwayne Johnsons character called in Red Notice?",
            questionImageSrc: Question9,
            answerDescription: "Johnson portrays an FBI profiler named John Hartley",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                { id: 1, text: "A. John Winters", isCorrect: false },
                { id: 2, text: "B. Nolan Booth", isCorrect: false },
                { id: 3, text: "C. John Hartley", isCorrect: true },
                { id: 4, text: "D. Nolan Switch", isCorrect: false }
            ]
        },
        {
            questionId: 10,
            questionTitle: 'Sarah Black played by Gal Gadot in Red Notice is also known as "The ****"?',
            questionImageSrc: Question10,
            answerDescription:
                "Sarah Black, also known as The Bishop, is the main antagonist of Red Notice. She is #1 greatest art thief in the world and the lover/accomplice of Agent John Hartley",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                { id: 1, text: "A. The Knight", isCorrect: false },
                { id: 2, text: "B. The Bishop", isCorrect: true },
                { id: 3, text: "C. The Rook", isCorrect: false },
                { id: 4, text: "D. The Pawn", isCorrect: false }
            ]
        }
    ]
};

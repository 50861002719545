import { Image, Label, PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import TextFieldWrapper from "../../../CommonComponent/TextFieldWrapper";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { Client } from "../../../Base/Client";
import { ApiConstants } from "../../../../constants/ApiConstant";

type QuizAttachmentDetailsViewProps = {
    attachmentId: number;
    closePanel: () => void;
    quizId: number;
};

const QuizAttachmentDetailsView: React.FC<QuizAttachmentDetailsViewProps> = ({ attachmentId, closePanel, quizId }) => {
    const [title, setTitle] = useState("");
    const [attachment, setAttachment] = useState("");
    const [attachmentType, setAttachmentType] = useState("");
    const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [attachmentFile, setAttachmentFile] = useState<File | null>();
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

    useEffect(() => {
        if (attachmentId !== -1) {
            setShowLoadingSpinner(true);
            Client.getInstance()
                .getData(ApiConstants.getQuizAttachmentDetailsApiUrl(quizId, attachmentId), true)
                .then((response) => {
                    setTitle(response.data.data.title);
                    setAttachment(response.data.data.fileLocation);
                    const fileType = response.data.data.fileName.split(".");
                    setAttachmentType(fileType[fileType.length - 1].toLowerCase());
                    setShowLoadingSpinner(false);
                })
                .catch(() => {
                    setShowLoadingSpinner(false);
                });
        }
    }, []);

    const reverseTransformData = (): any => {
        const attachmentData = new FormData();
        attachmentData.append("title", title);
        attachmentData.append("quizAttachment", attachmentFile as Blob);
        return attachmentData;
    };

    const onSaveAttachment = (): void => {
        if (title === "" || attachment === "") {
            setShowValidationErrorMessage(true);
            return;
        }
        setShowValidationErrorMessage(false);
        setLoading(true);
        if (attachmentId !== -1) {
            Client.getInstance()
                .updateData(ApiConstants.updateQuizAttachmentApiUrl(quizId, attachmentId), reverseTransformData())
                .then(() => {
                    setLoading(false);
                    closePanel();
                })
                .catch(() => {
                    setLoading(false);
                });
            return;
        }
        Client.getInstance()
            .createData(ApiConstants.addQuizAttachmentApiUrl(quizId), reverseTransformData())
            .then(() => {
                setLoading(false);
                closePanel();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleFileChange = (event: any): void => {
        if (event.target.files.length === 0) {
            setAttachment("");
            setFileUploadErrorMessage("");
            return;
        }
        const type = event.target.files[0].type;
        setAttachmentType(type.split("/")[1]);
        const size: number = event.target.files[0].size;
        if (!FieldValidation.imageTypeValidation(type) && !FieldValidation.pdfFileValidation(type)) {
            setAttachment("");
            setFileUploadErrorMessage("Please select an image or pdf file");
            return;
        }
        if (FieldValidation.attachmentSizeValidation(size)) {
            setAttachment("");
            setFileUploadErrorMessage("File size should be less than 5 MB");
            return;
        }
        setAttachment(URL.createObjectURL(event.target.files[0]));
        setFileUploadErrorMessage("");
        setAttachmentFile(event.target.files[0]);
    };

    const getFileView = (): JSX.Element => {
        if (attachment && attachmentType === "pdf") {
            return (
                <Stack.Item className={"quiz-attachment-container"}>
                    <iframe width="100%" className={"attachmentPdfTc"} src={attachment}></iframe>
                </Stack.Item>
            );
        }
        return (
            <Stack.Item>
                <Image className={"attachment-image attachmentImageTc"} src={attachment} alt="Cover Image" />
            </Stack.Item>
        );
    };

    return (
        <>
            {showLoadingSpinner ? (
                <Spinner className={"loading-spinner"} size={SpinnerSize.large} label={"Loading..."} />
            ) : (
                <Stack tokens={ThemedMediumStackTokens}>
                    <Stack tokens={ThemedMediumStackTokens} className={"section-background section-border"}>
                        <Stack.Item grow>
                            <TextFieldWrapper
                                onTextFieldValueChange={(text) => setTitle(text)}
                                value={title}
                                label={"Title"}
                                errorMessage={title === "" ? "Title is mandatory" : ""}
                                id={"attachmentTitle"}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Label>File</Label>
                            <Form>
                                <Form.Group>
                                    <Form.File
                                        id={"attachmentInput"}
                                        onChange={(event: any) => handleFileChange(event)}
                                    />
                                    {fileUploadErrorMessage !== "" ? (
                                        <span className={"error-message"}>{fileUploadErrorMessage}</span>
                                    ) : (
                                        ""
                                    )}
                                    {attachment === "" && fileUploadErrorMessage === "" ? (
                                        <span className={"error-message"}>File is mandatory</span>
                                    ) : (
                                        ""
                                    )}
                                </Form.Group>
                            </Form>
                            {attachment === "" ? null : getFileView()}
                        </Stack.Item>
                    </Stack>
                    <Stack horizontalAlign={"center"}>
                        {isLoading ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : (
                            <PrimaryButton
                                className={"saveAttachmentTc"}
                                iconProps={{ iconName: "Save" }}
                                text={"Save"}
                                onClick={onSaveAttachment}
                            />
                        )}
                    </Stack>

                    <Stack horizontal horizontalAlign={"center"} className={"margin-bottom-15"}>
                        {showValidationErrorMessage && (
                            <span className={"error-message"}>Please fill in all the mandatory fields</span>
                        )}
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default QuizAttachmentDetailsView;

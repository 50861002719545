/* eslint-disable @typescript-eslint/no-redeclare */
import { FC } from "react";
import Styled from "@emotion/styled";
import { Paper, Button } from "@mui/material";

type HeaderTextUi = {
    label: string;
    setValue: () => void;
};
const StyledButton = Styled(Button)`
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 16px;
    text-transform: capitalize;
    outline: none !important;
    display: flex;
    align-items: center;
    color: #1F71B6;
`;
const StyledPaper = Styled(Paper)`
    border: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 5px;
    box-sizing: border-box;
    `;
export const HeaderTextUi: FC<HeaderTextUi> = ({ setValue, label }) => {
    return (
        <StyledPaper variant="outlined">
            <StyledButton onClick={setValue}>{label}</StyledButton>
        </StyledPaper>
    );
};

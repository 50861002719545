import { Grid } from "@mui/material";
import moment from "moment";
import { FC } from "react";
import CircularProgressWrapper from "../../../../../CommonComponent/v2/CircularProgressWrapper";
import { CaptionText, CardContainer } from "../../../../../CommonComponent/v2/CommonStyle";
import { darkColor } from "../../../../../WebsiteGame/colorConstant";
import { SurveyType } from "./Type";

type SurveyCardProps = {
    surveyInfo: SurveyType;
};
const SurveyCard: FC<SurveyCardProps> = ({ surveyInfo }) => {
    return (
        <CardContainer style={{ padding: 0 }} elevation={0}>
            <Grid container p={"20px"} spacing={"20px"}>
                <Grid item xs={4}>
                    <CaptionText color={"#828282"} fontWeight={600}>
                        Participant
                    </CaptionText>
                    <CaptionText color={darkColor}>{surveyInfo.participantName}</CaptionText>
                </Grid>
                <Grid item xs={4}>
                    <CaptionText color={"#828282"} fontWeight={600}>
                        Date
                    </CaptionText>
                    <CaptionText color={darkColor}>{moment(surveyInfo.createdAt).format("DD/MM/YYYY")}</CaptionText>
                </Grid>
                <Grid item xs={4}>
                    <CaptionText color={"#828282"} fontWeight={600}>
                        Company
                    </CaptionText>
                    <CaptionText color={darkColor}>{surveyInfo.surveyDetails.companyName}</CaptionText>
                </Grid>
                <Grid item xs={4}>
                    <CaptionText color={"#828282"} fontWeight={600}>
                        Played before
                    </CaptionText>
                    <CaptionText color={darkColor}>{surveyInfo.surveyDetails.playedBefore}</CaptionText>
                </Grid>
                <Grid item xs={4}>
                    <CaptionText color={"#828282"} fontWeight={600}>
                        Code
                    </CaptionText>
                    <CaptionText color={darkColor}>{surveyInfo.participantCode}</CaptionText>
                </Grid>
            </Grid>
            <hr style={{ margin: "20px 0px" }} />
            <Grid container p={"20px"} justifyContent={"space-around"}>
                <Grid item xs={3} textAlign={"center"}>
                    <CircularProgressWrapper
                        progressColor={"#1F71B6"}
                        height={"86px"}
                        width={"86px"}
                        progressValue={surveyInfo.surveyDetails.overallExpereincePoints * 10}
                    />
                    <CaptionText mt={"15px"} fontWeight={700} color={darkColor}>
                        Overall expereince
                    </CaptionText>
                </Grid>
                <Grid item xs={3} textAlign={"center"}>
                    <CircularProgressWrapper
                        progressColor={"#65B44A"}
                        height={"86px"}
                        width={"86px"}
                        progressValue={parseInt(surveyInfo.surveyDetails.enjoyPoints) * 10}
                    />
                    <CaptionText mt={"15px"} fontWeight={700} color={darkColor}>
                        Enjoy points
                    </CaptionText>
                </Grid>
                <Grid item xs={3} textAlign={"center"}>
                    <CircularProgressWrapper
                        progressColor={"#F2C94C"}
                        height={"86px"}
                        width={"86px"}
                        progressValue={surveyInfo.surveyDetails.teamConnectPoints * 10}
                    />
                    <CaptionText mt={"15px"} fontWeight={700} color={darkColor}>
                        Team collaboration
                    </CaptionText>
                </Grid>
            </Grid>
            <hr style={{ margin: "20px 0px" }} />
            <Grid container p={"20px"} textAlign={"center"}>
                <Grid item xs={12}>
                    <CaptionText textAlign={"center"} color={darkColor} fontWeight={700}>
                        Likes
                    </CaptionText>
                    <CaptionText textAlign={"center"} color={darkColor}>
                        {surveyInfo.surveyDetails.likes}
                    </CaptionText>
                </Grid>
            </Grid>
            <hr style={{ margin: "20px 0px" }} />
            <Grid container p={"20px"}>
                <Grid item xs={12}>
                    <CaptionText textAlign={"center"} color={darkColor} fontWeight={700}>
                        Mistakes
                    </CaptionText>
                    <CaptionText textAlign={"center"} color={darkColor}>
                        {surveyInfo.surveyDetails.mistakes}
                    </CaptionText>
                </Grid>
            </Grid>
            <hr style={{ margin: "20px 0px" }} />
            <Grid container p={"20px"} textAlign={"center"}>
                <Grid item xs={12}>
                    <CaptionText textAlign={"center"} color={darkColor} fontWeight={700}>
                        Improvements
                    </CaptionText>
                    <CaptionText textAlign={"center"} color={darkColor}>
                        {surveyInfo.surveyDetails.improvement}
                    </CaptionText>
                </Grid>
            </Grid>
        </CardContainer>
    );
};

export default SurveyCard;

import { IColumn, IconButton, PrimaryButton, SpinnerSize, Stack, Text, Spinner, DefaultButton } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { Client } from "../../../Base/Client";
import { SortingDetailsList } from "../../../Base/SortingDetailList";
import DeleteConfirmationDialog from "../../../CommonComponent/DeleteConfirmationDialog";
import ListSearch from "../../../CommonComponent/ListSearch";
import TextFieldWrapper from "../../../CommonComponent/TextFieldWrapper";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import { toast, ToastContainer } from "react-toastify";
import { tagsType, usageListType } from "../../../../data/question/types";
import { transformTagsUsageList } from "./util";
import { useAdminContext } from "../../context/AdminAuthContext";
import { getPermissions } from "../../../../utils/PermissionUtils";

export type TagsListItem = {
    id: number;
    tagName: string;
};

type QuestionTagModalProps = {
    closeModal: () => void;
    fetchQuestionList: () => void;
};

const QuestionTagModal: React.FC<QuestionTagModalProps> = ({ closeModal, fetchQuestionList }) => {
    const [tagsList, setTagsList] = useState<TagsListItem[]>([]);
    const [isListLoading, setListLoading] = useState(true);
    const [searchTag, setSearchTag] = useState("");
    const [showTagAddEditField, setShowTagAddEditField] = useState(false);
    const [showDeleteConfirmationDialog, setDeleteConfirmationDialogDisplay] = useState(false);
    const [selectedTagId, setselectedTagId] = useState(-1);
    const [listkey, setListkey] = useState("initialKey");
    const [isLoading, setLoading] = useState(false);
    const [tagName, setTagName] = useState("");
    const [editTagId, setEditTagId] = useState(-1);
    const [usageList, setUsageList] = useState<usageListType[]>([]);
    const [tagUsageLoading, setTagUsageLoading] = useState<boolean>(false);
    const { userDetail } = useAdminContext();

    const permission: string[] | null = getPermissions(userDetail, "tag");

    useEffect(() => {
        fetchAllTags();
    }, []);

    const fetchAllTags = (forceRefresh = false): void => {
        if (!(permission && permission.includes("fetch"))) {
            setTagsList([]);
            setListLoading(false);
            return;
        }
        Client.getInstance()
            .getData(ApiConstants.getTagsListApiUrl(), forceRefresh)
            .then((response: any) => {
                const tagArray = response.data.data.map((tag: any) => ({ id: tag.id, tagName: tag.value }));
                setTagsList(tagArray);
                setListkey(Math.random().toString(36).substring(7));
                setListLoading(false);
            });
    };

    const onSaveTag = () => {
        if (tagName.trim() === "") {
            return;
        }
        setLoading(true);
        if (editTagId !== -1) {
            Client.getInstance()
                .updateData(ApiConstants.updateTagApiUrl(editTagId), { value: tagName })
                .then(() => {
                    setLoading(false);
                    setTagName("");
                    setEditTagId(-1);
                    setShowTagAddEditField(false);
                    fetchAllTags(true);
                    fetchQuestionList();
                })
                .catch(() => {
                    setLoading(false);
                });
            return;
        }
        Client.getInstance()
            .createData(ApiConstants.addTagApiUrl(), { value: tagName })
            .then(() => {
                setLoading(false);
                setTagName("");
                setShowTagAddEditField(false);
                fetchAllTags(true);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const listColumns: IColumn[] = [
        {
            key: "tagName",
            name: "Tag Name",
            fieldName: "tagName",
            minWidth: SiteConstants.listColumnSmall
        }
    ];

    const tagMenuItems = [
        {
            key: "update",
            name: "",
            fieldName: "update",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "delete",
            name: "",
            fieldName: "delete",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        }
    ];

    const getColumns = () => {
        if (permission) {
            return [...listColumns, ...tagMenuItems.filter((column) => permission && permission.includes(column.key))];
        }
        return listColumns;
    };

    const getFilteredItems = (): any => {
        const filteredItems =
            searchTag === ""
                ? tagsList
                : tagsList.filter((item: TagsListItem) => {
                      return includeInFilteredItems(searchTag.toLowerCase(), item);
                  });
        return filteredItems;
    };

    const includeInFilteredItems = (searchText: string, item: TagsListItem): boolean => {
        if (item.tagName && item.tagName.toLowerCase().includes(searchText)) {
            return true;
        }
        return false;
    };

    const renderItemColumn = (
        item: TagsListItem,
        index: number | undefined,
        column: IColumn | undefined
    ): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "select":
                return (
                    <IconButton
                        iconProps={{ iconName: "Edit" }}
                        title="Edit"
                        onClick={() => {
                            setShowTagAddEditField(true);
                            setEditTagId(item.id);
                            setTagName(item.tagName);
                        }}
                    />
                );

            case "delete":
                return (
                    <IconButton iconProps={{ iconName: "delete" }} title="Delete" onClick={() => deleteTag(item.id)} />
                );
            default: {
                const fieldContent = item[column.fieldName as keyof TagsListItem] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    const fetchTagsUsage = (forceRefresh = true, id: number): void => {
        Client.getInstance()
            .getData(ApiConstants.getTagsUsageApiUrl(id), forceRefresh)
            .then((response) => {
                const tagsUsageList: tagsType[] = response.data.data.question;
                setUsageList(transformTagsUsageList(tagsUsageList));
                setTagUsageLoading(false);
            });
    };

    const deleteTag = (tagId: number) => {
        setTagUsageLoading(true);
        fetchTagsUsage(true, tagId);
        setselectedTagId(tagId);
        setDeleteConfirmationDialogDisplay(true);
    };

    const onConfirmDelete = (): void => {
        Client.getInstance()
            .deleteData(ApiConstants.deleteTagApiUrl(selectedTagId))
            .then(() => {
                setDeleteConfirmationDialogDisplay(false);
                fetchAllTags(true);
                if (selectedTagId === editTagId) {
                    setEditTagId(-1);
                    setShowTagAddEditField(false);
                    setTagName("");
                }
                setselectedTagId(-1);
                fetchQuestionList();
            })
            .catch((error) => {
                setDeleteConfirmationDialogDisplay(false);
                setselectedTagId(-1);
                toast.error(error.response.data.message, SiteConstants.deleteToastConfiguration);
            });
    };

    return (
        <Stack className={"padding-10 height-100per"}>
            <ToastContainer />
            <Stack.Item className={"position-sticky"}>
                <Stack horizontal className={"margin-bottom-10"}>
                    <Stack.Item grow>
                        <Text variant={"xLarge"}>Manage Tags</Text>
                    </Stack.Item>
                    <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        styles={SiteConstants.closeIconColor}
                        onClick={closeModal}
                    />
                </Stack>

                {showTagAddEditField ? (
                    <Stack horizontal tokens={ThemedMediumStackTokens} className={"section-background"}>
                        <Stack.Item grow={4}>
                            <TextFieldWrapper
                                placeholder={"Tag name"}
                                value={tagName}
                                onTextFieldValueChange={(value: string) => setTagName(value)}
                                errorMessage={tagName.trim() === "" ? "Tag name is mandatory" : ""}
                            />
                        </Stack.Item>
                        <Stack.Item grow={8}>
                            <Stack horizontal tokens={ThemedMediumStackTokens} horizontalAlign={"end"}>
                                {isLoading ? (
                                    <Spinner size={SpinnerSize.large} />
                                ) : (
                                    <PrimaryButton text={"Save"} iconProps={{ iconName: "save" }} onClick={onSaveTag} />
                                )}

                                <DefaultButton
                                    text={"Cancel"}
                                    iconProps={{ iconName: "Cancel" }}
                                    onClick={() => {
                                        setShowTagAddEditField(false);
                                        setEditTagId(-1);
                                        setTagName("");
                                    }}
                                />
                            </Stack>
                        </Stack.Item>
                    </Stack>
                ) : (
                    <ListSearch
                        onSearchTextChange={(text: string) => setSearchTag(text)}
                        searchText={searchTag}
                        hideAddNewButton={!(permission && permission.includes("add"))}
                        onAddButtonClick={() => {
                            setShowTagAddEditField(true);
                            setEditTagId(-1);
                        }}
                        searchFieldWithoutLabel={true}
                    />
                )}
            </Stack.Item>
            <Stack key={listkey} className={"height-100per overflowy-auto"}>
                <SortingDetailsList
                    columns={getColumns()}
                    isLoading={isListLoading}
                    items={getFilteredItems()}
                    onRenderItemColumn={renderItemColumn}
                />
            </Stack>

            <DeleteConfirmationDialog
                entryName={"tag"}
                isShown={showDeleteConfirmationDialog}
                onConfirm={onConfirmDelete}
                onDismiss={() => setDeleteConfirmationDialogDisplay(false)}
                usageList={usageList}
                loading={tagUsageLoading}
                usedBy="question"
            />
        </Stack>
    );
};

export default QuestionTagModal;

import { useEffect, useState } from "react";
import { ApiConstants } from "../../constants/ApiConstant";
import { Grid, Paper, SliderThumb, Typography } from "@mui/material";
import { getDistance } from "../../utils/JambarUtils";
import { PersonDistanceSlider } from "./Style";
import walking from "../Images/walking.png";

interface PlayerThumbComponentProps extends React.HTMLAttributes<unknown> { }

const LiveLocationParticipantGame = () => {
    const [targetCoords, setTargetCoords] = useState<{
        latitude: number;
        longitude: number;
    }>({
        latitude: 0,
        longitude: 0
    });

    const [targetPlace, setTargetPlace] = useState<string>("");
    const [question, setQuestion] = useState<string>("");
    const [words, setWords] = useState<string>("");
    const [currentPosCoord, setCurrentPosCoords] = useState<{
        latitude: number;
        longitude: number;
    }>({
        latitude: 0,
        longitude: 0
    });
    const [distance, setDistance] = useState(0);
    const [initialDistance, setInitialDistance] = useState(0);

    useEffect(() => {
        if (localStorage.getItem("3wordsAddress")) {
            const addresses = localStorage.getItem("3wordsAddress") || "";
            setWords(addresses);
            fetchCoords(addresses);
        }
    }, []);

    const fetchCoords = (word: string) => {
        fetch(ApiConstants.getLocationApiUrl(word))
            .then((res) => res.json())
            .then((data) => {
                setTargetPlace(data.nearestPlace);
                setTargetCoords({
                    latitude: data.coordinates.lat,
                    longitude: data.coordinates.lng
                });
                getLocation();
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
            });
    };

    useEffect(() => {
        if (currentPosCoord.latitude !== 0 && currentPosCoord.longitude) {
            const distance = getDistance(
                currentPosCoord.latitude,
                currentPosCoord.longitude,
                targetCoords.latitude,
                targetCoords.longitude
            );
            if (initialDistance === 0) {
                setInitialDistance(distance);
            }
            setDistance(distance);

            distance < 100 && getQuestion();
        }
    }, [currentPosCoord]);

    const getQuestion = () => {
        setQuestion("Welcome to my home");
    };

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(showPosition, () => alert("Please allow location"));
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const showPosition = (position: GeolocationPosition) => {
        setCurrentPosCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        });
    };

    const PlayerThumbComponent = (props: PlayerThumbComponentProps) => {
        const { children, ...other } = props;
        return (
            <SliderThumb
                style={{
                    height: 0,
                    width: 0
                }}
                {...other}>
                {children}
                <img style={{ position: "absolute", height: 40 }} src={walking} alt={"walking"} />
            </SliderThumb>
        );
    };

    const getResult = () => Math.round((distance / initialDistance) * 100);

    const renderDistance = () => {
        if (distance <= 100) {
            return "Congrats you are in range";
        }

        return `${distance.toFixed(0)}m left`;
    };
    return (
        <Grid container padding={"40px"} height={"100vh"} bgcolor={"#A8DB86"} justifyContent={"center"}>
            <Grid item xs={9}>
                <Typography mb={"20px"} textAlign={"center"} color={"white"} variant={"h4"}>
                    Hey! you have to reach the position with these words {words}
                </Typography>
                <PersonDistanceSlider
                    components={{ Thumb: PlayerThumbComponent }}
                    value={distance <= 100 ? 100 : 100 - getResult()}
                    key={getResult()}
                    valueLabelDisplay="auto"
                    valueLabelFormat={() => `${renderDistance()}`}
                />

                <Grid mt={"20px"} justifyContent={"center"} container spacing={"20px"}>
                    <Grid item xs={6}>
                        <Paper
                            elevation={2}
                            style={{
                                padding: 20,
                                height: "100%"
                            }}>
                            <Typography textAlign={"center"} variant={"h6"} fontWeight={"bold"}>
                                Player Info:
                            </Typography>
                            <Typography variant={"h6"}>Target distance: {initialDistance.toFixed(0)} m</Typography>
                            <Typography variant={"h6"}>
                                Current distance from target distance: {distance.toFixed(0)} m
                            </Typography>
                            <Typography variant={"h6"}>Current latitude: {currentPosCoord.latitude}</Typography>
                            <Typography variant={"h6"}>Current longitude: {currentPosCoord.longitude}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper
                            elevation={2}
                            style={{
                                padding: 20,
                                height: "100%"
                            }}>
                            <Typography textAlign={"center"} variant={"h6"} fontWeight={"bold"}>
                                Target Location:
                            </Typography>
                            <Typography variant={"h6"}>Question latitude: {targetCoords.latitude}</Typography>
                            <Typography variant={"h6"}>Question longitude: {targetCoords.longitude}</Typography>
                            <Typography variant={"h6"}>Nearest place: {targetPlace}</Typography>
                        </Paper>
                    </Grid>
                    {question && (
                        <Grid item xs={12}>
                            <Paper
                                style={{
                                    padding: 20,
                                    height: "100%",
                                    width: "100%"
                                }}
                                elevation={2}>
                                <Typography textAlign={"center"} variant={"h6"} fontWeight={"bold"}>
                                    Question
                                </Typography>
                                <Typography variant={"h6"}>{question}</Typography>
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LiveLocationParticipantGame;

/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useHistory } from "react-router";
import {
    AnswerType,
    ConnectorQuestionInfoTypes,
    CurrentQuestionInfoType,
    InfoLocationType,
    PathQuizType,
    QuestionConnector,
    QuestionContextType,
    QuizConfigType,
    QuizInfoType,
    QuizUserSessionInfoType,
    TimersEndType
} from "../type";
import { useEffect, useState } from "react";
import { fetchEventDetail, validateEventCode } from "../loginflow/util";
import {
    ConnectorQuestionInitialValues,
    PathQuizConfigInitialValues,
    PathQuizInfoInitialValues,
    PathQuizUserSessionInfoInitialValues,
    currentQueTransformData,
    getAnswerResult,
    getNextQuestion,
    getTotalMarks,
    pathQuizConfigTransformData,
    pathQuizInfoTransformData,
    quizUserSessionInfoTransformData,
    saveCurrentQuestion,
    saveProgressAfterXSeconds
} from "./util";
import { getRemainingTime } from "../../../../../utils/TimeUtils";
import {
    CONNECTOR,
    CORRECT_ANSWER_COUNT,
    EVENT_TIMEOUT,
    MCQAnswerOption,
    SKIP_QUESTION,
    WRONG_ANSWER_COUNT
} from "../../../../../data/question/types";
import { FieldValidation } from "../../../../../constants/FieldValidation";
import { EventClosedMessage, SaveProgressInterval } from "../../../../../constants/SiteConstant";
import { TimerEndInitialValues } from "../../../RocketTemplate/RocketTemplateUtils/RocketTemplateInitialValues";

export const usePathChallengeQuiz = () => {
    const [loading, setLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState<PathQuizType | null>(null);
    const [quizConfig, setQuizConfig] = useState<QuizConfigType>(PathQuizConfigInitialValues);
    const [quizInfoData, setQuizInfoData] = useState<QuizInfoType>(PathQuizInfoInitialValues);
    const [currentQuestionInfo, setCurrentQuestionInfo] = useState<CurrentQuestionInfoType>();
    const [connectorQuestionInfo, setConnectorQuestionInfo] =
        useState<ConnectorQuestionInfoTypes>(ConnectorQuestionInitialValues);
    const [questionTiming, setQuestionTiming] = useState<string>("00:00:00");
    const [quizUserSessionInfoData, setQuizUserSessionInfoData] = useState<QuizUserSessionInfoType>(
        PathQuizUserSessionInfoInitialValues
    );
    const [answerResult, setAnswerResult] = useState<string>("");
    const [participantAnswer, setParticipantAnswer] = useState<string>("");
    const [questionContext, setQuestionContext] = useState<QuestionContextType>();
    const history = useHistory();
    const [endQuiz, showEndQuiz] = useState(false);
    const [totalTimeTakeninEvent, setTotalTimeTakenInEvent] = useState("");
    const [timerEnd, setTimerEnd] = useState<TimersEndType>(TimerEndInitialValues);
    const [saveData, setSaveData] = useState(0);
    const [refreshKey, setRefreshKey] = useState("initialKey");
    const [initialEventTime, setInitialEventTime] = useState<string>("");

    const info: {
        state: InfoLocationType;
    } = useLocation();

    const fetchEvent = (): void => {
        setLoading(true);
        validateEventCode(info.state.eventLink, info.state.code)
            .then((res) => {
                setQuizInfoData(pathQuizInfoTransformData(res));
                setQuizConfig(pathQuizConfigTransformData(res));
                fetchEventDetail(info.state.eventLink)
                    .then((event) => {
                        setEventDetails(event);
                        setInitialEventTime(res.data.data.quiz.timeLimit);
                        fetchNextQuestion(
                            res.data.data.quiz.timeLimit,
                            res.data.data.quiz.initialScore ? res.data.data.quiz.initialScore : 0
                        );
                    })
                    .catch(() => {})
                    .finally(() => {
                        setLoading(false);
                    });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchEvent();
        const interval = setInterval(() => {
            setSaveData(Math.random);
        }, SaveProgressInterval);
        return () => clearInterval(interval);
    }, []);

    const updateParticipantAnswer = (answer: string) => {
        setParticipantAnswer(answer);
    };

    const connectorQuestionSubmit = (connectorSelected: QuestionConnector) => {
        answerSubmit(false, connectorSelected);
    };

    const answerSubmit = (isSkipped?: boolean, connectorSelected?: QuestionConnector) => {
        if (currentQuestionInfo.type === CONNECTOR) {
            goToNextQuestion({
                userAttemptType: SKIP_QUESTION,
                quizType: currentQuestionInfo.type,
                connectorSelected: connectorSelected,
                totalMarks: quizUserSessionInfoData.totalMarks
            });
            return;
        }
        const totalMarks = getTotalMarks(
            getAnswerResult(participantAnswer, currentQuestionInfo) ? "correct" : "wrong",
            quizUserSessionInfoData,
            currentQuestionInfo,
            quizInfoData
        );

        updateMarksWithQuestionCount(
            getAnswerResult(participantAnswer, currentQuestionInfo) ? "correct" : "wrong",
            totalMarks
        );
        if (isSkipped) {
            setAnswerResult("skip");
            return;
        } else {
            setAnswerResult(getAnswerResult(participantAnswer, currentQuestionInfo) ? "correct" : "wrong");
        }

        goToNextQuestion({
            userAttemptType: isSkipped
                ? SKIP_QUESTION
                : getAnswerResult(participantAnswer, currentQuestionInfo)
                ? CORRECT_ANSWER_COUNT
                : WRONG_ANSWER_COUNT,
            quizType: currentQuestionInfo.type,
            totalMarks
        });
    };

    useEffect(() => {
        if (connectorQuestionInfo.connectorQuizIsWorking) {
            saveProgressAfterXSeconds(quizInfoData, connectorQuestionInfo, quizUserSessionInfoData);
        }
    }, [saveData]);

    const goToNextQuestion = (nextQuestionData: {
        userAttemptType: string;
        quizType?: string;
        connectorSelected?: QuestionConnector;
        totalMarks?: number;
    }) => {
        const { userAttemptType, connectorSelected, quizType, totalMarks } = nextQuestionData;
        saveProgressTest({
            answerType: { type: userAttemptType, quizType: quizType },
            answerCount: quizUserSessionInfoData.correctAnswerCount + quizUserSessionInfoData.wrongAnswerCount + 1,
            totalScore: totalMarks,
            connectorQuizId: connectorSelected?.quizId
        });
    };

    const checkEventEnded = (message: string) => {
        message === EventClosedMessage &&
            history.push("/thankyou", {
                eventClosed: true
            });
    };

    const saveProgressTest = ({
        answerCount,
        answerType,
        totalScore,
        connectorQuizId,
        timeout
    }: {
        answerType: AnswerType;
        answerCount: number;
        totalScore: number;
        connectorQuizId?: number;
        timeout?: boolean;
    }): void => {
        saveCurrentQuestion({
            answerCount,
            answerType,
            connectorQuestionInfo: connectorQuestionInfo,
            currentQuestionInfo,
            info: info.state,
            questionContext,
            questionTiming,
            timeout,
            quizInfoData,
            quizUserSessionInfoData,
            totalScore,
            connectorQuizId: questionContext.connectorQuizId || connectorQuizId
        })
            .then(() => {
                fetchNextQuestion(initialEventTime);
                if (answerType.type === CONNECTOR) {
                    setTimerEnd((prevState) => ({ ...prevState, connectorQuizEnds: false }));
                }
            })
            .catch((error) => {
                checkEventEnded(error.response.data.message);
            });
    };

    const fetchNextQuestion = (eventTime: string, initialProgress = 0) => {
        setTimerEnd((prevState) => ({ ...prevState, connectorQuizEnds: false }));
        setConnectorQuestionInfo((prevState) => ({ ...prevState, connectorQuizIsWorking: false }));

        getNextQuestion(info.state.eventLink, info.state.code).then((response: any) => {
            const data = response.data.data;
            updateParticipantAnswer("");
            setQuestionContext(data.context);
            setTimeout(() => {
                setAnswerResult("");
            }, 1000);
            setConnectorQuestionInfo((prevState) => ({ ...prevState, connectorQuizIsWorking: false }));
            if (data?.context?.connectorQuizId) {
                setConnectorQuestionInfo((prevState) => ({
                    ...prevState,
                    connectorTotalTime: data.context.questionTimeLimit,
                    connectorQuizIsWorking: !!data.context.connectorQuizId
                }));
            } else {
                data.nextQuestionInfo &&
                    data.nextQuestionInfo.type === "connector" &&
                    setConnectorQuestionInfo((prevState) => ({
                        ...prevState,
                        connectorTotalTime: data.nextQuestionInfo.timeLimit
                    }));

                data.nextQuestionInfo &&
                    setConnectorQuestionInfo((prevState) => ({
                        ...prevState,
                        connectorQuizIsWorking: data.nextQuestionInfo.type === "connector"
                    }));
            }
            if (
                data?.context?.quizId === data?.userQuizSessionInfo?.quizId &&
                data.nextQuestionInfo.type === "connector"
            ) {
                setConnectorQuestionInfo((prevState) => ({
                    ...prevState,
                    timeTakenInConnector: data.userQuizSessionInfo.timeTakenInConnector
                        ? getRemainingTime(
                              data.nextQuestionInfo.timeLimit,
                              data.userQuizSessionInfo.timeTakenInConnector
                          )
                        : data.nextQuestionInfo.timeLimit
                }));
            }
            data.context &&
                setConnectorQuestionInfo((prevState) => ({
                    ...prevState,
                    showConnectorTimer: data.context.showConnectorTimer
                }));

            data.context &&
                setConnectorQuestionInfo((prevState) => ({
                    ...prevState,
                    connectorIsClosable: data.context.isConnectorQuizClosable
                }));

            if (data?.context?.playedConnectorOptionQuizIds) {
                const quizIds = data.context.playedConnectorOptionQuizIds.split(",");
                setConnectorQuestionInfo((prevState) => ({ ...prevState, playedConnectorOptionQuizIds: quizIds }));
            }
            if (data?.userQuizSessionInfo?.timeTakenInQuestion) {
                data.nextQuestionInfo &&
                    setQuestionTiming(
                        getRemainingTime(data.nextQuestionInfo.timeLimit, data.userQuizSessionInfo.timeTakenInQuestion)
                    );
            } else {
                data.nextQuestionInfo && setQuestionTiming(data.nextQuestionInfo.timeLimit);
            }
            if (data.userQuizSessionInfo) {
                setQuizUserSessionInfoData(quizUserSessionInfoTransformData(eventTime, response));
                data.context?.questionTimeLimit &&
                    data.userQuizSessionInfo?.timeTakenInConnector &&
                    setConnectorQuestionInfo((prevState) => ({
                        ...prevState,
                        timeTakenInConnector: getRemainingTime(
                            data.context.questionTimeLimit,
                            data.userQuizSessionInfo.timeTakenInConnector
                        )
                    }));
            } else {
                setQuizUserSessionInfoData((prevState) => ({ ...prevState, eventTimer: eventTime }));
                setQuizUserSessionInfoData((prevState) => ({ ...prevState, totalMarks: initialProgress }));
            }

            if (data.message) {
                showEndQuiz(true);
                if (data.userQuizSessionInfo.userAttemptType === "quiz-timeout") {
                    setTotalTimeTakenInEvent("00:00:00");
                    return;
                }
                setTotalTimeTakenInEvent(
                    getRemainingTime(quizInfoData.initialEventTimer, data.userQuizSessionInfo.timeTakenInQuiz)
                );
                return;
            }
            setCurrentQuestionInfo(currentQueTransformData(data.nextQuestionInfo));
            setRefreshKey(FieldValidation.generateAlphaNumericCode());
        });
    };

    const updateMarksWithQuestionCount = (type: string, marks: number) => {
        if (type === "correct") {
            setQuizUserSessionInfoData((prevState) => ({
                ...prevState,
                totalMarks: marks,
                correctAnswerCount: prevState.correctAnswerCount + 1
            }));
        }
        if (type === "wrong") {
            setQuizUserSessionInfoData((prevState) => ({
                ...prevState,
                totalMarks: marks,
                wrongAnswerCount: prevState.wrongAnswerCount + 1
            }));
        }
    };

    const getQuestionTiming = (time: string): void => {
        if (time === "0:0:0" && !timerEnd.questionTimeEnds) {
            setTimerEnd((prevState) => ({ ...prevState, questionTimeEnds: true }));
            return;
        }
        time !== "" && setQuestionTiming(time);
    };

    const getEventTiming = (time: string): void => {
        if (time === "0:0:0" && !timerEnd.eventEnds) {
            setTimerEnd((prevState) => ({ ...prevState, eventEnds: true }));
            return;
        }
        time !== "" && setQuizUserSessionInfoData((prevState) => ({ ...prevState, eventTimer: time }));
    };

    const getConnectorTiming = (time: string): void => {
        if (connectorQuestionInfo.connectorQuizIsWorking && time === "0:0:0" && !timerEnd.connectorQuizEnds) {
            setTimerEnd((prevState) => ({ ...prevState, connectorQuizEnds: true }));
            return;
        }
        time !== "" && setConnectorQuestionInfo((prevState) => ({ ...prevState, timeTakenInConnector: time }));
    };

    useEffect(() => {
        if (timerEnd.eventEnds) {
            saveProgressWhenEventEnds();
        }
    }, [timerEnd.eventEnds]);

    const saveProgressWhenEventEnds = (): void => {
        saveProgressTest({
            answerType: {
                type: EVENT_TIMEOUT
            },
            answerCount: 0,
            totalScore: quizUserSessionInfoData.totalMarks
        });
    };

    useEffect(() => {
        if (timerEnd.connectorQuizEnds) {
            saveProgressForConnectorWhenTimeOuts();
        }
    }, [timerEnd.connectorQuizEnds]);

    const saveProgressForConnectorWhenTimeOuts = (): void => {
        saveProgressTest({
            answerType: { type: CONNECTOR, quizType: CONNECTOR },
            answerCount: 0,
            totalScore: quizUserSessionInfoData.totalMarks,
            connectorQuizId: questionContext?.connectorQuizId,
            timeout: true
        });
    };

    useEffect(() => {
        if (timerEnd.questionTimeEnds) {
            saveProgressForQuestionWhenTimeOuts();
        }
    }, [timerEnd.questionTimeEnds]);

    const saveProgressForQuestionWhenTimeOuts = (): void => {
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, wrongAnswerCount: prevState.wrongAnswerCount + 1 }));
        let marksAfterTimeOut: number = quizUserSessionInfoData.totalMarks;
        marksAfterTimeOut = marksAfterTimeOut + parseInt(quizInfoData.wrongAnswerPoints);
        if (currentQuestionInfo && currentQuestionInfo?.wrongPoints !== 0) {
            marksAfterTimeOut = marksAfterTimeOut + currentQuestionInfo.wrongPoints;
        }
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, totalMarks: marksAfterTimeOut }));
        if (connectorQuestionInfo.connectorQuizIsWorking) {
            saveProgressTest({
                answerType: { type: WRONG_ANSWER_COUNT, quizType: CONNECTOR },
                answerCount: quizUserSessionInfoData.wrongAnswerCount + 1,
                totalScore: marksAfterTimeOut,
                timeout: true
            });
            return;
        }
    };

    return {
        eventInfo: eventDetails?.event,
        quizInfo: eventDetails?.quiz,
        loading,
        quizConfig,
        quizInfoData,
        currentQuestionInfo,
        info,
        questionTiming,
        quizUserSessionInfoData,
        answerSubmit,
        updateParticipantAnswer,
        answerResult,
        participantAnswer,
        setQuestionTiming,
        connectorQuestionSubmit,
        connectorQuestionInfo,
        endQuiz,
        totalTimeTakeninEvent,
        updateMarksWithQuestionCount,
        getQuestionTiming,
        getEventTiming,
        getConnectorTiming,
        refreshKey
    };
};

export const usePathGameQuestionSection = (
    participantAnswer: string,
    updateParticipantAnswer: (answer: string) => void
) => {
    const getParticipantAnswer = () => {
        if (participantAnswer) {
            return participantAnswer.split(";");
        }
        return [];
    };
    const [isAnswerSubmitting, setIsAnswerSubmitting] = useState(false);
    const [submitQuestion, setSubmitQuestion] = useState<boolean>(false);
    const [skipQuestion, setSkipQuestion] = useState<boolean>(false);
    const [fullScreenImage, setFullScreenImage] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string[]>(getParticipantAnswer);
    const [files, setFiles] = useState<File[]>([]);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [drawerContent, setDrawerContent] = useState<string>("");
    const [selectedConnectorQuestion, setSelectedConnectorQuestion] = useState<QuestionConnector | null>(null);

    useEffect(() => {
        setSelectedConnectorQuestion(null);
    }, []);

    const updateSelectedQuestionConnector = (option: QuestionConnector) => {
        if (selectedConnectorQuestion?.id === option.id) {
            setSelectedConnectorQuestion(null);
            return;
        }

        setSelectedConnectorQuestion(option);
    };

    const answerSelected = (option: MCQAnswerOption) => {
        let selectedOptionArray = [];
        if (selectedOption.includes(option.id.toString())) {
            selectedOptionArray = selectedOption.filter((item: string) => item !== option.id.toString());
            setSelectedOption(selectedOptionArray);
            updateParticipantAnswer(selectedOptionArray.join(";"));
            return;
        }
        selectedOptionArray = [...selectedOption, option.id.toString()];
        setSelectedOption(selectedOptionArray);
        updateParticipantAnswer(selectedOptionArray.join(";").toString());
    };

    return {
        isAnswerSubmitting,
        setIsAnswerSubmitting,
        submitQuestion,
        setSubmitQuestion,
        skipQuestion,
        setSkipQuestion,
        fullScreenImage,
        setFullScreenImage,
        selectedOption,
        setSelectedOption,
        files,
        setFiles,
        openDrawer,
        setOpenDrawer,
        drawerContent,
        setDrawerContent,
        answerSelected,
        updateSelectedQuestionConnector,
        selectedConnectorQuestion
    };
};

export const HarryPotterQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "You're not a true Harry Potter fan.",
            imageSrc: "https://i.imgur.com/qK6L3ut.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "You know a bit about Harry Potter movies.",
            imageSrc: "https://i.imgur.com/HAJR3T8.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Great job! Harry would be proud.",
            imageSrc: "https://i.imgur.com/TwUSvAc.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "Who is this character?",
            questionImageSrc: "https://i.imgur.com/60PAtlG.jpg",
            answerDescription:
                "Did you know that David Thewlis, who played Lupin, also played Ares in the Justice league movie?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Severus Snape",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Remus Lupin",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Rubeus Hagrid",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Arthur Weasley",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "What is this character’s name?",
            questionImageSrc: "https://i.imgur.com/tE41NBW.jpg",
            answerDescription: "Luna is Xenophilius’ daughter and first appears in the fifth Harry Potter film.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Lestrange",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Hermione",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Luna",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Cho",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Which of these does not appear in film series?",
            questionImageSrc: "https://i.imgur.com/5KFVQJd.jpg",
            answerDescription:
                "The Elder Wand (named after the type of wood it is made from) appears several times in the Harry Potter books and films.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Time-Turner",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Sneakoscope",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Junior wand",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Invisibility cloak",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "Name the group led by Voldemort.",
            questionImageSrc: "https://i.imgur.com/1L3LZ8F.jpg",
            answerDescription: "This group and members of the group appear many times in the harry Potter series.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. The Goblins",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Death Eaters",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Deathly Hallows",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. The Muggles",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Who was revealed as Salazar Slytherin’s heir?",
            questionImageSrc: "https://i.imgur.com/svMDgpL.jpg",
            answerDescription: "This was revealed in the second film in the series, The Chamber of Secrets.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Hagrid",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Myrtle",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Draco ",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Ginny",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "Can you correctly name Harry's aunt and uncle?",
            questionImageSrc: "https://i.imgur.com/eKZuslF.jpg",
            answerDescription: "Harry became an orphan when his parents died at the hands of Voldemort.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Petunia and Vernon",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Minerva and Godrick",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Dolores and Marty",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Minerva and Dursley",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "Who said, 'I am what I am an’ I’m not ashamed'?",
            questionImageSrc: "https://i.imgur.com/Xy6ApAC.jpg",
            answerDescription: "Hagrid said this because he wasn’t ashamed of being a half-giant.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Hagrid",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Snape",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Dumbledore",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Harry",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Which character does Ralph Fiennes play in the film series? ",
            questionImageSrc: "https://i.imgur.com/XFtwuOc.jpg",
            answerDescription:
                "Did you know that this talented actor has played the role of ‘M’ in a few James Bond films?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Dumbledore",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Lord Voldemort",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Lucius Malfoy",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Ron Weasley",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "Which of these filmmakers has never directed a Harry Potter film?",
            questionImageSrc: "https://i.imgur.com/1wC1oVC.jpg",
            answerDescription:
                "Although he’d already directed several successful fantasy films including E.T, Spielberg turned down the offer to direct the first movie in the Harry Potter series.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Mike Newell",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. David Yates",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Chris Columbus",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Steven Spielberg",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "What is this piece of quidditch gear called?",
            questionImageSrc: "https://i.imgur.com/fbIUT3o.jpg",
            answerDescription: "The player in the position of Seeker is responsible for catching the snitch.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. snitch",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. cauldron",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. whistle",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. batter",
                    isCorrect: false
                }
            ]
        }
    ]
};

import { Checkbox, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { useField } from "formik";
import { FC } from "react";
import { StyledSelectField } from "./Style";
import { OptionType } from "./Type";

type FormikSelectProps = {
    id: string;
    name: string;
    label: string;
    options: OptionType[];
} & SelectProps;

const FormikSelect: FC<FormikSelectProps> = ({ id, name, options, label, ...rest }) => {
    const [field, { error, touched }] = useField(name);

    return (
        <StyledSelectField fullWidth error={!!error && !!touched}>
            <InputLabel>{label}</InputLabel>
            <Select {...field} label={label} {...rest}>
                {options.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
            {!!error && !!touched && <FormHelperText sx={{ fontSize: "1rem" }}>{error}</FormHelperText>}
        </StyledSelectField>
    );
};

export default FormikSelect;

export const FormikMultiSelect: FC<FormikSelectProps> = ({ id, name, options, label, ...rest }) => {
    const [field, { value, error, touched }] = useField(name);

    const renderValue = (selectedIds: string[]) => {
        const optionsLabels: string[] = [];
        selectedIds.forEach((id) => {
            optionsLabels.push(options.find((option) => option.value === id)?.label ?? "");
        });
        return optionsLabels.join(", ");
    };

    return (
        <StyledSelectField fullWidth error={!!error && !!touched}>
            <InputLabel>{label}</InputLabel>
            <Select {...field} label={label} multiple renderValue={(selected) => renderValue(selected)} {...rest}>
                {options.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={value.indexOf(opt.value) > -1} />
                        {opt.label}
                    </MenuItem>
                ))}
            </Select>
            {!!error && !!touched && <FormHelperText>{error}</FormHelperText>}
        </StyledSelectField>
    );
};

import Style from "@emotion/styled";
import { avatarBackgroundColor } from "../../../../constants/Styles";

export const AvatarContainer = Style.div`
display: flex;
align-items: center;
justify-content: space-between;
gap: 10px;
flex-wrap: wrap;
margin-bottom: 20px;
`;

export const AvatarImage = Style.img`
height: 45px;
width: 45px;
@media (max-width: 280px) {
    height: 35px;
    width: 35px;
} 
`;

export const ImageCircle = Style.div`
border-radius: 50%;
padding: 10px;
@media (max-width: 280px) {
    padding: 8px;
} 
background: ${avatarBackgroundColor};
`;

import { Stack } from "@fluentui/react";
import React from "react";
import "../RocketTemplateView.scss";
import { Icon } from "@fluentui/react/lib/Icon";

type IRefrenceItemDescriptionModalNativeProps = {
    title: string;
    description: string;
    descriptionImage: string;
    bottomDescriptionText: string;
    showDescriptionImageModal: () => void;
    gameBackgroundColor: string;
};

const RefrenceItemDescriptionModal: React.FC<IRefrenceItemDescriptionModalNativeProps> = ({
    description,
    title,
    descriptionImage,
    bottomDescriptionText,
    showDescriptionImageModal,
    gameBackgroundColor
}) => {
    return (
        <Stack style={{ backgroundColor: gameBackgroundColor }}>
            <h1>{title}</h1>
            <div
                className={"margin-top-10 quill-editor-text-align margin-p-0 image-width-100per quizQuestionview"}
                dangerouslySetInnerHTML={{
                    __html: description
                }}
            />
            {descriptionImage && (
                <Stack horizontalAlign={"center"}>
                    <img
                        src={descriptionImage}
                        onClick={showDescriptionImageModal}
                        alt={"Description image"}
                        className={"max-width-400 cursor"}
                    />
                    <Icon iconName="ZoomIn" className={"zoom-image"} onClick={showDescriptionImageModal} />
                </Stack>
            )}
            <div
                className={"margin-top-10 quill-editor-text-align margin-p-0 image-width-100per quizQuestionview"}
                dangerouslySetInnerHTML={{
                    __html: bottomDescriptionText
                }}
            />
        </Stack>
    );
};

export default RefrenceItemDescriptionModal;

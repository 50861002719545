import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { WildGoatsCenterContainer } from "../../../WildGoat/CommonStyledComponents/CommonStyle";
import { PhotoArea } from "../../../WildGoat/PhotoQuestion/Style";
import "./FileUploadstyle.scss";
import addPhotoIcon from "../../../../Images/Wildgoat/addPhotoIcon.svg";

type FileDropZoneProps = {
    setSelectedFile: (file: File) => void;
    isMobile?: boolean;
};

const FileDropZone: React.FC<FileDropZoneProps> = ({ setSelectedFile, isMobile = false }) => {
    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles[0].id = Math.random();
            setSelectedFile(acceptedFiles[0]);
        },
        [setSelectedFile]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    if (isMobile) {
        return (
            <PhotoArea {...getRootProps({ className: "dropzone height-100per" })}>
                <input {...getInputProps()} />
                <WildGoatsCenterContainer style={{ height: "100%" }}>
                    <img src={addPhotoIcon} alt="addPhotoIcon" />
                </WildGoatsCenterContainer>
            </PhotoArea>
        );
    }
    return (
        <div>
            {isDragActive ? (
                <div {...getRootProps({ className: "dropzone height-100per" })}>
                    <input {...getInputProps()} />
                    <p className={"dropContainerText"}>
                        Drop File
                        <br />
                        Max file size allowed: 100 MB
                    </p>
                </div>
            ) : (
                <div {...getRootProps({ className: "dropzone height-100per" })}>
                    <input {...getInputProps()} />
                    <p className={"dropContainerText"}>
                        Drag and drop or click to upload.
                        <br />
                        Max: 100 MB. After upload wait for submit button to activate
                    </p>
                </div>
            )}
        </div>
    );
};

export default FileDropZone;

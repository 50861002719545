import { Grid } from "@mui/material";
import { FC } from "react";
import { FlowBoxValueType } from "./Type";
import { AiFillEdit } from "react-icons/ai";

type FlowChartBoxProps = {
    boxValue: FlowBoxValueType;
    onEditClick: () => void;
};
const FlowChartBox: FC<FlowChartBoxProps> = ({ boxValue, onEditClick }) => {
    const { description, title, value } = boxValue;
    return (
        <div>
            <Grid container justifyContent={"flex-end"}>
                <AiFillEdit onClick={onEditClick} style={{ cursor: "pointer", fontSize: 20 }} />
            </Grid>
            <h3>{title}</h3>
            <p>{description}</p>
            <p>{value}</p>
        </div>
    );
};

export default FlowChartBox;

import { ApiConstants } from "../../../../constants/ApiConstant";
import { CreatePaymentDetailType, PaymentIntentType } from "./type";
import { Client } from "../../../Base/Client";

const reverseTransformData = (data: PaymentIntentType) => {
    return {
        userName: data.userDetail,
        promoCode: data.promoCode.toString(),
        credits: data.credit,
        shipping: {
            name: data.shippingDetails.name,
            address: {
                line1: data.shippingDetails.address.line1,
                postal_code: data.shippingDetails.address.postalCode,
                city: data.shippingDetails.address.city,
                state: data.shippingDetails.address.state,
                country: data.shippingDetails.address.country
            }
        }
    };
};
export const createPaymentIntent = async (data: PaymentIntentType) => {
    return Client.getInstance()
        .createData(ApiConstants.createPaymentApiUrl(), reverseTransformData(data))
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch(() => {
            return Promise.reject();
        });
};

export const updateCreatePaymentDetails = async (data: CreatePaymentDetailType) => {
    return Client.getInstance()
        .updateData(ApiConstants.createPaymentUpdateApiUrl(), {
            userName: data.userName,
            clientSecret: data.clientSecret,
            status: data.status
        })
        .then(() => {
            return Promise.resolve();
        })
        .catch((err) => {
            return Promise.reject();
        });
};

export const validatePromoCodeStatus = async (promoCode: string) => {
    return Client.getInstance()
        .getData(ApiConstants.validatePromoCodeApiUrl(promoCode), true)
        .then((res) => Promise.resolve(res?.data?.data?.value))
        .catch(() => Promise.reject());
};

import { DefaultButton, Image, Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { ADVANCE_TEXT_EDITOR_CLASS } from "../../../../data/question/types";
import { Client } from "../../../Base/Client";
import TextEditor from "../../../CommonComponent/TextEditor";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import { Form } from "react-bootstrap";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { toast, ToastContainer } from "react-toastify";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { useFormik } from "formik";
import * as yup from "yup";

type ReferenceItemsDetailsViewProps = {
    closeAddPanel: (referenceItemId: number) => void;
    referenceItemId: number;
    referenceListId: number;
    refreshReferenceItemList: () => void;
};

type ReferenceItemsDetails = {
    title: string;
    topDescriptionText: string;
    bottomDescriptionText: string;
    descriptionImage: string;
};

const ReferenceItemsDetailsView: React.FC<ReferenceItemsDetailsViewProps> = ({
    closeAddPanel,
    referenceItemId,
    referenceListId,
    refreshReferenceItemList
}) => {
    const referenceItemsDetailsInitialValue: ReferenceItemsDetails = {
        bottomDescriptionText: "",
        descriptionImage: "",
        title: "",
        topDescriptionText: ""
    };

    const referenceItemsDetailsValidation = yup.object().shape({
        title: yup.string().required("Title is mandatory"),
        descriptionImage: yup.string(),
        bottomDescriptionText: yup.string(),
        topDescriptionText: yup.string()
    });

    const formik = useFormik({
        initialValues: referenceItemsDetailsInitialValue,
        onSubmit: () => onSaveReferenceItem(),
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: referenceItemsDetailsValidation
    });

    const [descriptionImageFile, setDescriptionImageFile] = useState<File | undefined>();
    const [imageUploadErrorMessage, setImageUploadErrorMessage] = useState("");
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [isRemovingDescriptionImage, setIsRemovingDescriptionImage] = useState(false);

    useEffect(() => {
        if (referenceItemId !== -1) {
            setIsContentLoading(true);
            Client.getInstance()
                .getData(ApiConstants.getReferenceItemDetailsApiUrl(referenceItemId), true)
                .then((response) => {
                    const data = response.data.data;
                    formik.setValues({
                        bottomDescriptionText: data.description2 ? data.description2 : "",
                        descriptionImage: data.descriptionImageFileLocation ? data.descriptionImageFileLocation : "",
                        title: data.name,
                        topDescriptionText: data.description ? data.description : ""
                    });
                    setIsContentLoading(false);
                })
                .catch(() => {
                    setIsContentLoading(false);
                });
        }
    }, []);

    const reverseTransformData = (): any => {
        const referenceListData = new FormData();
        referenceListData.append("name", formik.values.title);
        referenceListData.append("description", formik.values.topDescriptionText);
        referenceListData.append("descriptionImage", descriptionImageFile as Blob);
        referenceListData.append("description2", formik.values.bottomDescriptionText);
        return referenceListData;
    };

    const onSaveReferenceItem = (): void => {
        if (imageUploadErrorMessage) {
            formik.setSubmitting(false);
            return;
        }
        if (
            FieldValidation.quillTextEditorValidation(formik.values.topDescriptionText) &&
            FieldValidation.quillTextEditorValidation(formik.values.bottomDescriptionText) &&
            formik.values.descriptionImage === ""
        ) {
            formik.setErrors({
                bottomDescriptionText: "Description is mandatory",
                descriptionImage: "Description is mandatory",
                topDescriptionText: "Description is mandatory"
            });
            formik.setSubmitting(false);
            return;
        }

        if (referenceItemId !== -1) {
            Client.getInstance()
                .updateData(ApiConstants.updateReferenceItemApiUrl(referenceItemId), reverseTransformData())
                .then(() => {
                    formik.setSubmitting(false);
                    toast.success("Reference item updated", SiteConstants.successToastConfiguration);
                    refreshReferenceItemList();
                })
                .catch((error) => {
                    formik.setSubmitting(false);
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
            return;
        }
        Client.getInstance()
            .createData(ApiConstants.addReferenceItemApiUrl(), reverseTransformData())
            .then((referenceItemResponse) => {
                Client.getInstance()
                    .getData(ApiConstants.getReferenceDetailsApiUrl(referenceListId), true)
                    .then((referenceListDetailsResponse: any) => {
                        const referenceListData = referenceListDetailsResponse.data.data;
                        const referenceItemsId = referenceListData.reference_list_items.map(
                            (element: any) => element.referenceItemId
                        );
                        Client.getInstance()
                            .updateData(ApiConstants.updateReferenceApiUrl(referenceListId), {
                                name: referenceListData.name,
                                description: referenceListData.description,
                                referenceItems: [...referenceItemsId, referenceItemResponse.data.data.id].join(",")
                            })
                            .then(() => {
                                formik.setSubmitting(false);
                                closeAddPanel(referenceItemResponse.data.data.id);
                                toast.success("Reference item added", SiteConstants.successToastConfiguration);
                            })
                            .catch((error) => {
                                formik.setSubmitting(false);
                                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                            });
                    })
                    .catch((error) => {
                        formik.setSubmitting(false);
                        toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                    });
            })
            .catch((error) => {
                formik.setSubmitting(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || (event.target.files && event.target.files.length === 0)) {
            return;
        }
        if (!FieldValidation.imageTypeValidation(event.target.files[0].type)) {
            setImageUploadErrorMessage("Please select an image file");
            formik.setFieldValue("descriptionImage", "");
            setDescriptionImageFile(undefined);
            return;
        }
        if (FieldValidation.imageSizeValidation(event.target.files[0].size)) {
            setImageUploadErrorMessage("Image size should be less than 2 MB");
            formik.setFieldValue("descriptionImage", "");
            setDescriptionImageFile(undefined);
            return;
        }
        setImageUploadErrorMessage("");
        formik.setFieldValue("descriptionImage", URL.createObjectURL(event.target.files[0]));
        setDescriptionImageFile(event.target.files[0]);
        return;
    };

    const removeDescriptionImage = (): void => {
        if (referenceItemId === -1) {
            formik.setFieldValue("descriptionImage", "");
            setDescriptionImageFile(undefined);
            return;
        }
        setIsRemovingDescriptionImage(true);
        Client.getInstance()
            .deleteData(ApiConstants.removeReferenceitemDescrptionImage(referenceItemId))
            .then(() => {
                setIsRemovingDescriptionImage(false);
                formik.setFieldValue("descriptionImage", "");
                setDescriptionImageFile(undefined);
                toast.success("Description image removed", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                setIsRemovingDescriptionImage(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    if (isContentLoading) {
        return <Spinner className={"loading-spinner"} size={SpinnerSize.large} label={"Loading..."} />;
    }

    return (
        <>
            <ToastContainer />
            <Stack tokens={ThemedMediumStackTokens}>
                <Stack tokens={ThemedMediumStackTokens} className="section-background section-border padding-10">
                    <Stack.Item grow>
                        <TextField
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            name={"title"}
                            label={"Title"}
                            errorMessage={formik.errors.title}
                        />
                    </Stack.Item>

                    <Stack.Item grow>
                        <Label>Top description text</Label>
                        <TextEditor
                            className={`${ADVANCE_TEXT_EDITOR_CLASS} description-text quill-blue-background`}
                            value={formik.values.topDescriptionText}
                            onChange={(text: string) => formik.setFieldValue("topDescriptionText", text)}
                        />
                        {FieldValidation.quillTextEditorValidation(formik.values.bottomDescriptionText) &&
                            FieldValidation.quillTextEditorValidation(formik.values.topDescriptionText) &&
                            formik.values.descriptionImage === "" && (
                                <span className={"error-message"}>Description is mandatory</span>
                            )}
                    </Stack.Item>

                    <Stack.Item>
                        <Label>Description Image</Label>
                        <Stack horizontal>
                            <Stack.Item grow>
                                <Form.Group>
                                    <Form.File
                                        value={formik.values.descriptionImage === "" ? "" : undefined}
                                        onChange={(event: any) => handleFileChange(event)}
                                    />
                                    {imageUploadErrorMessage && (
                                        <span className={"error-message"}>{imageUploadErrorMessage}</span>
                                    )}
                                </Form.Group>
                            </Stack.Item>
                            <Stack horizontalAlign={"end"} verticalAlign={"start"}>
                                {isRemovingDescriptionImage ? (
                                    <Spinner size={SpinnerSize.large} />
                                ) : (
                                    <DefaultButton
                                        onClick={removeDescriptionImage}
                                        className={"dashed-border-default-button"}
                                        text={"Remove description image"}
                                    />
                                )}
                            </Stack>
                        </Stack>
                        {formik.values.descriptionImage && (
                            <Image
                                src={formik.values.descriptionImage}
                                alt="Description image"
                                className={"reference-cover-image"}
                            />
                        )}
                    </Stack.Item>
                    <Stack.Item grow>
                        <Label>Bottom description text</Label>
                        <TextEditor
                            className={`${ADVANCE_TEXT_EDITOR_CLASS} description-text quill-blue-background`}
                            value={formik.values.bottomDescriptionText}
                            onChange={(text: string) => formik.setFieldValue("bottomDescriptionText", text)}
                        />
                        {FieldValidation.quillTextEditorValidation(formik.values.bottomDescriptionText) &&
                            FieldValidation.quillTextEditorValidation(formik.values.topDescriptionText) &&
                            formik.values.descriptionImage === "" && (
                                <span className={"error-message"}>Description is mandatory</span>
                            )}
                    </Stack.Item>
                </Stack>
                <Stack horizontalAlign={"center"}>
                    {formik.isSubmitting ? (
                        <Spinner size={SpinnerSize.large} />
                    ) : (
                        <PrimaryButton
                            iconProps={{ iconName: "Save" }}
                            text={"Save"}
                            onClick={() => formik.handleSubmit()}
                        />
                    )}
                </Stack>

                <Stack horizontal horizontalAlign={"center"} className={"margin-bottom-15"}>
                    {(!formik.isValid || imageUploadErrorMessage) && (
                        <span className={"error-message"}>Please fill in all the mandatory fields</span>
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default ReferenceItemsDetailsView;

import React, { useEffect } from "react";
import { Stack } from "@fluentui/react";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import { Button, FormControl, InputGroup } from "react-bootstrap";

interface TextFieldQuestionViewProps {
    participantAnswer: string;
    updateParticipantAnswer: (text: string) => void;
    isQuestionTimeEnds: boolean;
    disableAnswerSection: boolean;
    isSubmitDisable: boolean;
    answerGivenByParticipant: () => void;
    showSkipButton: boolean;
    isAnswerSubmitted: boolean;
    nextQuestion: () => void;
    isVideoPlayed: boolean;
    isVideoQuestion: boolean;
}
const TextFieldQuestionView: React.FC<TextFieldQuestionViewProps> = ({
    disableAnswerSection,
    isQuestionTimeEnds,
    isSubmitDisable,
    participantAnswer,
    updateParticipantAnswer,
    answerGivenByParticipant,
    showSkipButton,
    isAnswerSubmitted,
    nextQuestion,
    isVideoPlayed,
    isVideoQuestion
}) => {
    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                participantAnswer !== "" && answerGivenByParticipant();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [participantAnswer]);

    return (
        <Stack>
            <Stack horizontal tokens={ThemedMediumStackTokens}>
                <Stack.Item grow={1}>
                    <InputGroup size="lg">
                        <FormControl
                            className={"answer-input"}
                            value={participantAnswer}
                            onChange={(ev) => {
                                updateParticipantAnswer(ev.target.value);
                            }}
                            disabled={(disableAnswerSection && isSubmitDisable) || (!isVideoPlayed && isVideoQuestion)}
                        />
                    </InputGroup>
                </Stack.Item>
                <Stack.Item>
                    <Button
                        disabled={
                            participantAnswer === "" || isQuestionTimeEnds || disableAnswerSection || isSubmitDisable
                        }
                        className={"answer-submit"}
                        onClick={answerGivenByParticipant}>
                        Submit
                    </Button>
                </Stack.Item>
            </Stack>
            <Stack horizontal horizontalAlign={"space-between"}>
                {showSkipButton && !isAnswerSubmitted && !isQuestionTimeEnds && (
                    <Stack className={"margin-top-10"}>
                        <Button
                            className={!isQuestionTimeEnds && !isAnswerSubmitted ? "answer-submit" : "display-none"}
                            onClick={nextQuestion}>
                            Skip question
                        </Button>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default TextFieldQuestionView;

import React, { useState } from "react";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { MCQAnswerOption } from "../../../../data/question/types";
import McqQuestionView from "./McqQuestionView";
import TextFieldQuestionView from "./TextFieldQuestionView";
import { ScrollViewContainer } from "../style";

interface SocketGameAnswerViewProps {
    gameBackgroundColor: string;
    answerHeader: string;
    mcqOptions?: MCQAnswerOption[];
    isQuestionTimeEnds: boolean;
    updateParticipantAnswer: (answer: string) => void;
    setHintDisable: (hintUseType: boolean) => void;
    showSkipButton: boolean;
    saveAnswer: () => void;
    participantAnswer: string;
    isSubmitDisable: boolean;
    optionColor: string;
    isVideoPlayed: boolean;
    isVideoQuestion: boolean;
}

const SocketGameAnswerView: React.FC<SocketGameAnswerViewProps> = ({
    saveAnswer,
    setHintDisable,
    answerHeader,
    gameBackgroundColor,
    mcqOptions,
    isQuestionTimeEnds,
    updateParticipantAnswer,
    showSkipButton,
    participantAnswer,
    isSubmitDisable,
    optionColor,
    isVideoPlayed,
    isVideoQuestion
}) => {
    const getParticipantAnswer = () => {
        if (participantAnswer) {
            return participantAnswer.split(";");
        }
        return [];
    };
    const [selectedOption, setSelectedOption] = useState<string[]>(getParticipantAnswer);
    const [disableAnswerSection, setDisableAsnswerSection] = useState(false);
    const [answerSubmitted, setAnswerSubmitted] = useState(false);

    const answerSelected = (optionId: string) => {
        let selectedOptionArray = [];
        if (selectedOption.includes(optionId)) {
            selectedOptionArray = selectedOption.filter((item: string) => item !== optionId);
            setSelectedOption(selectedOptionArray);
            updateParticipantAnswer(selectedOptionArray.join(";"));
            return;
        }
        selectedOptionArray = [...selectedOption, optionId];
        setSelectedOption(selectedOptionArray);
        updateParticipantAnswer(selectedOptionArray.join(";").toString());
    };

    const answerGivenByParticipant = () => {
        saveAnswer();
        setDisableAsnswerSection(true);
        setHintDisable(true);
        setAnswerSubmitted(true);
    };

    const nextQuestion = (): void => {
        saveAnswer();
        setDisableAsnswerSection(false);
        setSelectedOption([]);
        setHintDisable(false);
    };

    const getAnswerView = () => {
        if (mcqOptions) {
            return (
                <McqQuestionView
                    answerGivenByParticipant={answerGivenByParticipant}
                    answerSelected={answerSelected}
                    isAnswerSubmitted={answerSubmitted}
                    disableAnswerSection={disableAnswerSection}
                    isQuestionTimeEnds={isQuestionTimeEnds}
                    isSubmitDisable={isSubmitDisable}
                    mcqOptions={mcqOptions}
                    nextQuestion={nextQuestion}
                    participantAnswer={participantAnswer}
                    selectedOptions={selectedOption}
                    showSkipButton={showSkipButton}
                    optionColor={optionColor}
                    isVideoPlayed={isVideoPlayed}
                    isVideoQuestion={isVideoQuestion}
                />
            );
        }
        return (
            <TextFieldQuestionView
                answerGivenByParticipant={answerGivenByParticipant}
                disableAnswerSection={disableAnswerSection}
                isAnswerSubmitted={answerSubmitted}
                isQuestionTimeEnds={isQuestionTimeEnds}
                isSubmitDisable={isSubmitDisable}
                nextQuestion={nextQuestion}
                participantAnswer={participantAnswer}
                showSkipButton={showSkipButton}
                updateParticipantAnswer={updateParticipantAnswer}
                isVideoPlayed={isVideoPlayed}
                isVideoQuestion={isVideoQuestion}
            />
        );
    };

    return (
        <ScrollViewContainer
            className={"quiz-answer-view"}
            tokens={ThemedMediumStackTokens}
            style={{ backgroundColor: gameBackgroundColor }}>
            <div
                dangerouslySetInnerHTML={{
                    __html: answerHeader && !FieldValidation.quillTextEditorValidation(answerHeader) ? answerHeader : ""
                }}
                className={
                    answerHeader && !FieldValidation.quillTextEditorValidation(answerHeader)
                        ? "margin-p-0 answer-title quill-editor-text-align"
                        : "component-title margin-p-0"
                }
            />
            {getAnswerView()}
        </ScrollViewContainer>
    );
};

export default SocketGameAnswerView;

import { Grid } from "@mui/material";
import { FC } from "react";
import Logo from "../../Images/logo.svg";
import { LobbyTitle, LobbyDescription, LobbyButton } from "./Style";

type LobbyViewProps = {
    handleSubmit: () => void;
};

const LobbyView: FC<LobbyViewProps> = ({ handleSubmit }) => (
    <Grid container alignItems={"center"} justifyContent="center">
        <Grid borderRadius={"8px 8px 0px 0px"} bgcolor={"#F2F4F7"} padding={"14px"} item xs={12}>
            <Grid container justifyContent={"center"}>
                <img src={Logo} alt={"jambar-logo"} style={{ height: 100 }} />
            </Grid>
        </Grid>
        <Grid item xs={12} padding={"44px"}>
            <LobbyTitle>Get Ready</LobbyTitle>
            <LobbyDescription marginTop={"22px"}>You have successfully logged into the game.</LobbyDescription>
            <LobbyDescription marginTop={"22px"}>
                Please wait on this page until the organiser tells you to start.
            </LobbyDescription>
        </Grid>
        <Grid padding={"39px"} item xs={12}>
            <Grid container justifyContent={"center"}>
                <LobbyButton variant={"contained"} onClick={handleSubmit}>
                    Start
                </LobbyButton>
            </Grid>
        </Grid>
    </Grid>
);

export default LobbyView;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { fetchEventDetail, validateEventCode } from "./util";
import { useHistory, useLocation } from "react-router";
import { EventPathDetailsType, InfoLocationType, PathQuizType, QuizPathDetailsType } from "../type";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { SiteConstants } from "../../../../../constants/SiteConstant";

export const usePathChallenge = () => {
    const [eventDetails, setEventDetails] = useState<PathQuizType | null>(null);
    const [eventLink, setEventLink] = useState<string>("");
    const [loading, setLoading] = useState(false);

    const fetchEvent = (eventLink: string): void => {
        setEventLink(`pathchallenge/${eventLink}`);
        setLoading(true);
        fetchEventDetail(`pathchallenge/${eventLink}`)
            .then((res) => {
                setEventDetails(res);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchEventId = (): string => {
        const url = window.location.href;
        const urlSplit = url.split("/");
        fetchEvent(urlSplit[urlSplit.length - 1]);
        return urlSplit[urlSplit.length - 1];
    };

    useEffect(() => {
        fetchEventId();
    }, []);

    return {
        eventLink,
        eventDetails,
        loading
    };
};

export const usePathChallengeCodeInput = (
    eventInfo: EventPathDetailsType,
    quizInfo: QuizPathDetailsType,
    eventLink: string
) => {
    const [code, setCode] = useState<string>("");
    const history = useHistory();
    const [loginFailed, setLoginFailed] = useState(false);
    const [name, setName] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const moveToTeamNameLink = () => {
        setLoading(true);
        validateEventCode(eventLink, code)
            .then(() => {
                history.push(`/event/pathchallenge/${eventInfo.id}/teamname`, {
                    code: code.toUpperCase(),
                    eventLink: eventLink,
                    eventId: eventInfo.id,
                    quizId: quizInfo.id,
                    participantName: name,
                    quiz: quizInfo,
                    data: eventInfo,
                    isMultibranch: quizInfo.isMultiBranchQuiz
                });
            })
            .catch((err) => {
                console.log("err", err);
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        code,
        setCode,
        history,
        loginFailed,
        setLoginFailed,
        name,
        setName,
        loading,
        setLoading,
        moveToTeamNameLink
    };
};

export const usePathChallengeTeamName = () => {
    const [name, setName] = useState<string>("");
    const info: {
        state: InfoLocationType;
    } = useLocation();
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    const forwardToQuiz = () => {
        history.push(`/event/pathchallenge/${info.state.eventId}/quiz`, {
            code: info.state.code,
            eventLink: info.state.eventLink,
            eventId: info.state.eventId,
            quizId: info.state.quizId,
            teamName: name,
            isMultibranch: info.state.isMultibranch,
            participantName: info.state.participantName
        });
        return;
    };

    return {
        name,
        setName,
        loading,
        setLoading,
        info,
        forwardToQuiz
    };
};

import avatar1 from "../../../Images/Wildgoat/Avatars/Avatar1.svg";
import avatar2 from "../../../Images/Wildgoat/Avatars/Avatar2.svg";
import avatar3 from "../../../Images/Wildgoat/Avatars/Avatar3.svg";
import avatar4 from "../../../Images/Wildgoat/Avatars/Avatar4.svg";
import avatar5 from "../../../Images/Wildgoat/Avatars/Avatar5.svg";
import avatar6 from "../../../Images/Wildgoat/Avatars/Avatar6.svg";
import avatar7 from "../../../Images/Wildgoat/Avatars/Avatar7.svg";
import avatar8 from "../../../Images/Wildgoat/Avatars/Avatar8.svg";
import avatar9 from "../../../Images/Wildgoat/Avatars/Avatar9.svg";
import avatar10 from "../../../Images/Wildgoat/Avatars/Avatar10.svg";
import avatar11 from "../../../Images/Wildgoat/Avatars/Avatar11.png";

export const avatarData = [
    {
        name: "avatar1",
        src: avatar1
    },
    {
        name: "avatar2",
        src: avatar2
    },
    {
        name: "avatar3",
        src: avatar3
    },
    {
        name: "avatar4",
        src: avatar4
    },
    {
        name: "avatar5",
        src: avatar5
    },
    {
        name: "avatar6",
        src: avatar6
    },
    {
        name: "avatar7",
        src: avatar7
    },
    {
        name: "avatar8",
        src: avatar8
    },
    {
        name: "avatar9",
        src: avatar9
    },
    {
        name: "avatar10",
        src: avatar10
    },
    {
        name: "avatar11",
        src: avatar11
    },
    {
        name: "avatar12",
        src: avatar6
    }
];

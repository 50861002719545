import { IColumn, IconButton } from "@fluentui/react";
import React, { useState, useMemo } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Client } from "../../Base/Client";
import DeleteConfirmationDialog from "../../CommonComponent/DeleteConfirmationDialog";
import { ApiConstants } from "../../../constants/ApiConstant";
import { EventListItem } from "./EventListDataTypes";
import { SiteConstants } from "../../../constants/SiteConstant";
import "./EventDetails/EventDetailsView.scss";
import ConfirmationDialog from "../../CommonComponent/ConfirmationDialog";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import FixedHeaderSortingDetailsList from "../../CommonComponent/FixedHeaderSortingDetilsList";
import { useAdminContext } from "../context/AdminAuthContext";
import { getPermissions } from "../../../utils/PermissionUtils";

type EventsListViewProps = {
    eventsList: EventListItem[];
    isListLoading: boolean;
    showEventDetailsPanel: (eventId: number) => void;
    refreshList?: () => void;
};

const EventsListView: React.FC<EventsListViewProps> = ({
    eventsList,
    isListLoading,
    showEventDetailsPanel,
    refreshList
}) => {
    const [searchText, setSearchText] = useState("");
    const [selectedEventId, setSelectedEventId] = useState(-1);
    const [showDeleteConfirmationDialog, setDeleteConfirmationDialogDisplay] = useState(false);
    const [showCloneConfirmationDialog, setCloneConfirmationDialogDisplay] = useState(false);

    const { userDetail } = useAdminContext();

    const permission: string[] | null = getPermissions(userDetail, "event");

    const list = useMemo(() => eventsList, [eventsList]);

    const onDeleteEvent = (id: number): void => {
        setSelectedEventId(id);
        setDeleteConfirmationDialogDisplay(true);
    };

    const onConfirmDelete = (): void => {
        Client.getInstance()
            .deleteData(ApiConstants.deleteEventApiUrl(selectedEventId.toString()))
            .then(() => {
                toast.success("Event deleted", SiteConstants.deleteToastConfiguration);
                setSelectedEventId(-1);
                setDeleteConfirmationDialogDisplay(false);
                refreshList && refreshList();
            })
            .catch((error) => {
                setSelectedEventId(-1);
                setDeleteConfirmationDialogDisplay(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const onCancelDelete = (): void => {
        setSelectedEventId(-1);
        setDeleteConfirmationDialogDisplay(false);
    };

    const onConfirmEventClone = () => {
        Client.getInstance()
            .createData(ApiConstants.cloneEventApiUrl(selectedEventId), {})
            .then((response) => {
                showEventDetailsPanel(response.data.data.id);
                setSelectedEventId(response.data.data.id);
                setCloneConfirmationDialogDisplay(false);
                refreshList && refreshList();
                toast.success("Event cloned", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                setCloneConfirmationDialogDisplay(false);
                setSelectedEventId(-1);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const listColumns: IColumn[] = [
        {
            key: "eventName",
            name: "Event",
            fieldName: "eventName",
            minWidth: SiteConstants.listColumnMedium
        },
        {
            key: "dateRange",
            name: "Date Range",
            fieldName: "dateRange",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnSmall
        },
        {
            key: "gameTitle",
            name: "Game Title",
            fieldName: "gameTitle",
            minWidth: SiteConstants.listColumnMedium,
            maxWidth: SiteConstants.listColumnLarge
        },
        {
            key: "noOfTeams",
            name: "No. of Teams",
            fieldName: "noOfTeams",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnSmall
        },
        {
            key: "createdBy",
            name: "Created By",
            fieldName: "createdBy",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        },
        {
            key: "lastUpdatedBy",
            name: "Updated By",
            fieldName: "lastUpdatedBy",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        }
    ];

    const eventMenuItems = [
        {
            key: "clone",
            name: "",
            fieldName: "clone",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "update",
            name: "",
            fieldName: "update",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "delete",
            name: "",
            fieldName: "delete",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        }
    ];

    const getColumns = () => {
        if (permission) {
            return [
                ...listColumns,
                ...eventMenuItems.filter((column) => permission && permission.includes(column.key))
            ];
        }
        return listColumns;
    };

    const getFilteredItems = (): any => {
        const filteredItems =
            searchText === ""
                ? list
                : list.filter((item: EventListItem) => {
                      return includeInFilteredItems(searchText.toLowerCase(), item);
                  });
        return filteredItems;
    };

    const includeInFilteredItems = (searchText: string, item: EventListItem): boolean => {
        if (item.eventName && item.eventName.toLowerCase().includes(searchText)) {
            return true;
        }

        if (item.createdBy && item.createdBy.toLowerCase().includes(searchText)) {
            return true;
        }

        if (item.gameTitle && item.gameTitle.toLowerCase().includes(searchText)) {
            return true;
        }

        if (item.startDate && item.startDate.includes(searchText)) {
            return true;
        }
        if (item.endDate && item.endDate.includes(searchText)) {
            return true;
        }

        if (item.createdOn && item.createdOn.includes(searchText)) {
            return true;
        }

        if (item.noOfTeams && item.noOfTeams.toString().includes(searchText)) {
            return true;
        }

        if (item.id && item.id.toString().includes(searchText)) {
            return true;
        }

        if (item.updatedBy && item.updatedBy.toLowerCase().includes(searchText)) {
            return true;
        }

        if (item.updatedOn && item.updatedOn.includes(searchText)) {
            return true;
        }
        return false;
    };

    const renderItemColumn = (
        item: EventListItem,
        index: number | undefined,
        column: IColumn | undefined
    ): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "gameTitle":
                return <span>{item.gameTitle}</span>;
            case "createdBy":
                return (
                    <span>
                        {item.createdBy}
                        <br />
                        on <i>{item.createdOn}</i>
                    </span>
                );
            case "lastUpdatedBy":
                return (
                    <span>
                        {item.updatedBy}
                        <br />
                        on <i>{item.updatedOn}</i>
                    </span>
                );
            case "delete":
                return (
                    <IconButton
                        iconProps={{ iconName: "delete" }}
                        title="Delete"
                        onClick={() => onDeleteEvent(item.id)}
                    />
                );
            case "update":
                return (
                    <IconButton
                        iconProps={{ iconName: "Edit" }}
                        title="Edit"
                        onClick={() => showEventDetailsPanel(item.id)}
                    />
                );
            case "clone":
                return (
                    <IconButton
                        iconProps={{ iconName: "DependencyAdd" }}
                        title="Duplicate"
                        onClick={() => {
                            setSelectedEventId(item.id);
                            setCloneConfirmationDialogDisplay(true);
                        }}
                    />
                );
            case "dateRange":
                return (
                    <span>
                        <i>{item.startDate}</i>
                        <br />
                        to <i>{item.endDate}</i>
                    </span>
                );

            default: {
                const fieldContent = item[column.fieldName as keyof EventListItem] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    return (
        <>
            <ToastContainer />
            <FixedHeaderSortingDetailsList
                onSearchTextChange={(text: string) => setSearchText(text)}
                searchText={searchText}
                hideAddNewButton={!(permission && permission.includes("add"))}
                onAddButtonClick={() => showEventDetailsPanel(-1)}
                columns={getColumns()}
                isLoading={isListLoading}
                items={getFilteredItems()}
                onRenderItemColumn={renderItemColumn}
            />
            <DeleteConfirmationDialog
                entryName={"event"}
                isShown={showDeleteConfirmationDialog}
                onConfirm={onConfirmDelete}
                onDismiss={onCancelDelete}
            />
            <ConfirmationDialog
                entryName={"event"}
                dialogHeader={"Confirm Clone"}
                isShown={showCloneConfirmationDialog}
                onConfirm={onConfirmEventClone}
                actionName={"clone"}
                onDismiss={() => {
                    setSelectedEventId(-1);
                    setCloneConfirmationDialogDisplay(false);
                }}
            />
        </>
    );
};

export default EventsListView;

import {
    bulkQuestionUsageListType,
    Question,
    tagsType,
    usageListType,
    timePenalty
} from "../../../../data/question/types";

export const transformTagsUsageList = (usageList: tagsType[]): usageListType[] =>
    usageList.map(({ id, title }) => ({ id, name: title }));

const getQuestionTitle = (id: number, questionList: Question[]) => {
    const detail = questionList.filter((item) => item.itemId === id);
    return detail[0].title;
};

export const transformBulkUsageList = (usageList: bulkQuestionUsageListType[], questionList: Question[]) =>
    usageList.map((item) => ({ ...item, name: getQuestionTitle(item.questionId, questionList) }));

export const transformTimePenalty = (timePenalty: timePenalty[]) => {
    const timePenaltyValues = timePenalty.map((item) => Number(item.value)).join(";");
    return timePenaltyValues ? timePenaltyValues : "";
};

export const getTimePenalty = (data: string) => {
    const timePenaltyValues = data.split(";");
    return timePenaltyValues.map((item, index) => ({ id: index + 1, value: item }));
};

export const removeTimePenalty = (timePenalty: timePenalty[], maxAttempts: string) => {
    const reduceAmount = timePenalty.length - Number(maxAttempts);
    for (let x = 0; x < reduceAmount; x++) {
        timePenalty.pop();
    }
    return timePenalty;
};

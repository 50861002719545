import { Dialog, Grid } from "@mui/material";
import { Formik } from "formik";
import { FC } from "react";
import LoadingButtonWrapper from "../../../CommonComponent/v2/button/LoadingButtonWrapper";
import { CancelButton, Header1, PrimaryButton } from "../../../CommonComponent/v2/CommonStyle";
import FormikTextInput from "../../../CommonComponent/v2/fields/FormikTextInput";
import { useUpdateTagModal } from "./Hooks";
import { TagListItem } from "./Type";
import { UpdateTagValidationSchema } from "./Util";

type UpdateTagModalProps = {
    tagInfo: TagListItem;
    handleClose: () => void;
    refreshList: () => void;
    allTags: TagListItem[];
};
const UpdateTagModal: FC<UpdateTagModalProps> = ({ tagInfo, handleClose, refreshList, allTags }) => {
    const { updateTag } = useUpdateTagModal();
    return (
        <Dialog open onClose={handleClose} maxWidth={"sm"} fullWidth>
            <Grid container px={"40px"} py={"20px"}>
                <Grid item xs={12}>
                    <Header1>{tagInfo.id !== -1 ? "Update tag" : "Add tag"}</Header1>
                </Grid>
                <Grid item xs={12} mt={"30px"}>
                    <Formik
                        initialValues={{
                            id: tagInfo.id,
                            tagName: tagInfo.tagName
                        }}
                        validationSchema={UpdateTagValidationSchema}
                        enableReinitialize
                        validateOnMount
                        onSubmit={(values, { setSubmitting, setFieldError }) => {
                            if(tagInfo.id === -1 && allTags.find((item) => item.tagName === values.tagName)){
                                setFieldError("tagName", "This tag is already present");
                                setSubmitting(false);
                                return;
                            }
                            updateTag(values, setSubmitting, refreshList);
                        }}
                    >
                        {({ handleSubmit, isSubmitting }) => (
                            <Grid container spacing={"20px"}>
                                <Grid item xs={12}>
                                    <FormikTextInput id={"tagName"} name={"tagName"} fullWidth label={"Tag name"} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid alignItems={"center"} spacing={"10px"} container justifyContent={"center"}>
                                        <Grid item xs={2}>
                                            <CancelButton fullWidth onClick={handleClose}>
                                                Cancel
                                            </CancelButton>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <LoadingButtonWrapper loading={isSubmitting}>
                                                <PrimaryButton onClick={() => handleSubmit()} fullWidth>
                                                    Save
                                                </PrimaryButton>
                                            </LoadingButtonWrapper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default UpdateTagModal;

import React, { FC, useEffect, useMemo, useState } from "react";
import { GameQuestionListItem } from "../../Type";
import { Box, Checkbox, Grid, IconButton, Menu, MenuItem, TablePagination } from "@mui/material";
import {
    StyledActionColumn,
    StyledColumn,
    StyledHeader
} from "../../../../../../CommonComponent/v2/datagrid/DataGridStyle";
import { extractHtmlContent } from "../../../../../../../utils/JambarUtils";
import { useAdminContext } from "../../../../../context/AdminAuthContext";
import { getPermissions } from "../../../../../../../utils/PermissionUtils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { QUESTION_PER_PAGE } from "../../../../../../../constants/SiteConstant";
import { TablePaginationContainer } from "./Style";

type PaginationQuestionProps = {
    questionList: GameQuestionListItem[];
    handleViewClick: (id: string) => void;
    selectedRows: GameQuestionListItem[];
    setSelectedRows: (selectedRows: GameQuestionListItem[]) => void;
};
const PaginationQuestion: FC<PaginationQuestionProps> = ({
    questionList,
    handleViewClick,
    setSelectedRows,
    selectedRows
}) => {
    const { userDetail } = useAdminContext();
    const permissionQuizFullGame: string[] | null = getPermissions(userDetail, "quiz_fullgame");
    const permissionQuizSocketGame: string[] | null = getPermissions(userDetail, "quiz_socket");
    const permissionQuizPhysicalGame: string[] | null = getPermissions(userDetail, "quiz_wildgoat");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [selectedQuestionFilter, setSelectedQuestionFilter] = useState("");

    const [currentPageSelections, setCurrentPageSelections] = useState<number[]>([]);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const totalItems = questionList.length;

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPage(newPage);
    };

    const startIndex = currentPage * QUESTION_PER_PAGE;
    const endIndex = startIndex + QUESTION_PER_PAGE;

    const currentItems = useMemo(() => {
        return questionList.slice(startIndex, endIndex);
    }, [questionList, startIndex, endIndex]);

    const handleRowSelect = (rowData: GameQuestionListItem) => {
        if (selectedRows.find((item) => item.id === rowData.id)) {
            setSelectedRows(selectedRows.filter((row) => row.id !== rowData.id));
            return;
        }
        const data: GameQuestionListItem | undefined = questionList.find((row) => row.id === rowData.id);
        if (data) {
            setSelectedRows([...selectedRows, data]);
        }
    };

    useEffect(() => {
        currentPageSelections.length !== 0 && handleCheckBoxAllSelection();
    }, [currentPageSelections]);

    const handleCheckBoxAllSelection = () => {
        const row: GameQuestionListItem[] = [];
        currentPageSelections.forEach((item) => {
            row.push(...questionList.slice(item * QUESTION_PER_PAGE, item * QUESTION_PER_PAGE + QUESTION_PER_PAGE));
        });
        setSelectedRows(row);
    };
    const handleAllSelection = () => {
        if (currentPageSelections.includes(currentPage)) {
            const newSelections = currentPageSelections.filter((item) => item !== currentPage);
            setCurrentPageSelections(newSelections);
            const rows: GameQuestionListItem[] = [];
            newSelections.forEach((item) => {
                rows.push(
                    ...questionList.slice(item * QUESTION_PER_PAGE, item * QUESTION_PER_PAGE + QUESTION_PER_PAGE)
                );
            });
            setSelectedRows(rows);
            return;
        }

        setCurrentPageSelections((prev) => [...prev, currentPage]);
    };

    return (
        <Grid container>
            <Grid item xs={12} height={"500px"} overflow={"auto"}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            borderBottom={"2px #efefef solid"}
                            px={"10px"}
                            py={"5px"}
                            alignItems={"center"}
                            display={"flex"}
                            gap={"20px"}>
                            <Box width={50}>
                                <Box display={"flex"}>
                                    <Checkbox
                                        size="small"
                                        key={startIndex}
                                        checked={
                                            currentPageSelections.includes(startIndex / QUESTION_PER_PAGE) ||
                                            selectedQuestionFilter === "ALL"
                                        }
                                        onClick={() => {
                                            handleAllSelection();
                                        }}
                                    />
                                    <IconButton style={{ position: "relative", right: 10 }} onClick={handleClick}>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                </Box>
                                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                                    <MenuItem
                                        onClick={() => {
                                            setSelectedQuestionFilter("ALL");
                                            setSelectedRows(questionList);
                                        }}>
                                        All
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            handleAllSelection();
                                        }}>
                                        Current Page
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setSelectedRows([]);
                                            setCurrentPageSelections([]);
                                            setSelectedQuestionFilter("");
                                        }}>
                                        None
                                    </MenuItem>
                                </Menu>
                            </Box>
                            <Box
                                width={60}
                                borderRight={"2px #efefef solid"}
                                pl={"20px"}
                                borderLeft={"2px #efefef solid"}>
                                <StyledHeader label="#" />
                            </Box>
                            <Box flex={1} borderRight={"2px #efefef solid"}>
                                <StyledHeader label="Challenge title" />
                            </Box>
                            <Box width={180}>
                                <StyledHeader label="Action" />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            {currentItems.map((item) => (
                                <Grid item xs={12} key={item.id}>
                                    <Box alignItems={"center"} px={"10px"} py={"5px"} display={"flex"} gap={"20px"}>
                                        <Box width={50}>
                                            <Checkbox
                                                size="small"
                                                onClick={() => handleRowSelect(item)}
                                                checked={selectedRows.findIndex((row) => row.id === item.id) !== -1}
                                            />
                                        </Box>
                                        <Box width={60} pl={"20px"}>
                                            <StyledColumn label={`${item.index}`} />
                                        </Box>
                                        <Box flex={1}>
                                            <StyledColumn label={extractHtmlContent(item.questionTitle)} />
                                        </Box>
                                        <Box width={30}>
                                            <StyledActionColumn
                                                isViewAvailaible={{
                                                    isAvailable: !!(
                                                        permissionQuizFullGame?.includes("update") ||
                                                        permissionQuizSocketGame?.includes("update") ||
                                                        permissionQuizPhysicalGame?.includes("update")
                                                    ),
                                                    onClick: () => handleViewClick(item.id.toString())
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <TablePaginationContainer item xs={12}>
                <TablePagination
                    component="div"
                    count={totalItems}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    rowsPerPage={QUESTION_PER_PAGE}
                    rowsPerPageOptions={[]}
                />
            </TablePaginationContainer>
        </Grid>
    );
};

export default PaginationQuestion;

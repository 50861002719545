import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import AdminView from "./Component/Admin/AdminView";
import ParticipantLogin from "./Component/Participant/ParticipantLogin";
import EventLoginView from "./Component/Participant/EventLoginView";
import FlightCrewView from "./Component/Participant/RocketTemplate/FlightCrewView/ReferenceItemView";
import ReferenceItemErrorPage from "./Component/Participant/RocketTemplate/FlightCrewView/ReferenceItemErrorPage";
import { initializeIcons } from "office-ui-fabric-react";
import PublicLeaderboardView from "./Component/Participant/PublicLeaderboard/PublicLeaderboardView";
import SocketGameView from "./Component/Participant/RocketTemplate/SocketGame/SocketGameView";
import ThankYouPage from "./Component/CommonComponent/ThankYouPage";
import WebsiteGameView from "./Component/WebsiteGame/WebsiteGameView";
import MiniLoginView from "./Component/Participant/MiniEvent/Login/LoginView";
import MiniEventRoutes from "./Component/Participant/MiniEvent/MiniEventRoutes";
import { AdminLoginDataProvider } from "./Component/Admin/context/AdminAuthContext";
import LiveLocationParticipantGame from "./Component/Poc/LiveLocationParticipantGame";
import { ToastContainer } from "react-toastify";
import WildgoatRoute from "./Component/Participant/WildGoat/WildgoatRoute";
import Register from "./Component/Admin/register/Register";
import PageNotFound from "./Component/custom404/PageNotFound";
import PathChallengeRoute from "./Component/Participant/pathchallenge/PathChallengeRoute";
import ForgotPassword from "./Component/Admin/forgotpassword/ForgotPassword";
import VerifyUser from "./Component/Admin/verifyuser/VerifyUser";
import QRReaderView from "./Component/Participant/WildGoat/QrReader/QRReaderView";
import PublicGameView from "./Component/Participant/publicgame/PublicGameView";

const App = () => {
    initializeIcons();
    //Routes
    return (
        <BrowserRouter>
            <ToastContainer />
            <Switch>
                <Route exact path={"/"} render={() => <Redirect to="/admin/login" />} />
                <Route exact path="/referenceerror">
                    <ReferenceItemErrorPage />
                </Route>
                <Route exact path="/thankyou">
                    <ThankYouPage />
                </Route>
                <Route exact path="/socket">
                    <SocketGameView />
                </Route>
                <Route exact path="/websitegame">
                    <WebsiteGameView />
                </Route>
                <Route exact path="/livelocationgame">
                    <LiveLocationParticipantGame />
                </Route>
                <Route path={"/wildgoat/"}>
                    <WildgoatRoute />
                </Route>
                <Route path={"/event/pathchallenge/"}>
                    <PathChallengeRoute />
                </Route>
                <Route path={"/admin/"}>
                    <AdminLoginDataProvider>
                        <AdminView />
                    </AdminLoginDataProvider>
                </Route>
                <Route path={"/participant/"}>
                    <ParticipantLogin />
                </Route>
                <Route path={"/mini/participant/"}>
                    <MiniEventRoutes />
                </Route>
                <Route path="/minievent">
                    <MiniLoginView />
                </Route>
                <Route path={"/register"}>
                    <Register />
                </Route>
                <Route path="/event">
                    <EventLoginView />
                </Route>
                <Route path="/leaderboard/:link">
                    <PublicLeaderboardView />
                </Route>
                <Route path="/reflist/:eventLink/:link">
                    <FlightCrewView />
                </Route>
                <Route path="/forgotpassword">
                    <ForgotPassword />
                </Route>
                <Route path="/verifyuser">
                    <VerifyUser />
                </Route>
                <Route path="/publicevent/*">
                    <PublicGameView />
                </Route>
                <Route path={"*"}>
                    <PageNotFound />
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default App;

import { Grid } from "@mui/material";
import { FC } from "react";
import { CancelButton, PrimaryOutlinedButton } from "../../../../../CommonComponent/v2/CommonStyle";

type EventHeaderProps = {
    isEventClosed: boolean;
    closeEvent: () => void;
    cloneEvent: () => void;
    showClone?: boolean;
    showCloseEvent?: boolean;
    updateEvent: () => void;
    showUpdateEvent?: boolean;
};

const EventHeader: FC<EventHeaderProps> = ({
    isEventClosed,
    closeEvent,
    cloneEvent,
    showClone,
    showCloseEvent,
    updateEvent,
    showUpdateEvent
}) => {
    return (
        <Grid mt={"20px"} container alignItems={"center"} justifyContent={"flex-end"}>
            <Grid item xs={12}>
                <Grid container justifyContent={"flex-end"} spacing={"30px"}>
                    {showCloseEvent && (
                        <Grid item>
                            {!isEventClosed ? (
                                <CancelButton fontsize="16px" style={{ padding: "16px 19px" }}>
                                    This event is closed
                                </CancelButton>
                            ) : (
                                <PrimaryOutlinedButton
                                    fontsize="16px"
                                    onClick={closeEvent}
                                    style={{ padding: "16px 19px" }}
                                    textcolor={"#EB5757"}>
                                    Close this event
                                </PrimaryOutlinedButton>
                            )}
                        </Grid>
                    )}
                    {showUpdateEvent && (
                        <Grid item>
                            <PrimaryOutlinedButton
                                fontsize="16px"
                                onClick={updateEvent}
                                style={{ padding: "16px 19px" }}>
                                Update this event
                            </PrimaryOutlinedButton>
                        </Grid>
                    )}
                    {showClone && (
                        <Grid item>
                            <PrimaryOutlinedButton
                                fontsize="16px"
                                onClick={cloneEvent}
                                style={{ padding: "16px 19px" }}>
                                Duplicate this event
                            </PrimaryOutlinedButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EventHeader;

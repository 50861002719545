import { ApiConstants } from "../../../../constants/ApiConstant";
import { Client } from "../../../Base/Client";
import { ReferenceListViewItem } from "./Types";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { intersection } from "lodash";

const transformData = (data: any): ReferenceListViewItem[] => {
    if (!data) return [];
    return data.map((event: any) => ({
        id: event.id,
        title: event.name,
        createdBy: `${event.createdByUser.firstName} ${event.createdByUser.lastName}`,
        createdDate: JambarDateUtil.formatDate(event.createdAt),
        lastUpdatedBy: `${event.updatedByUser.firstName} ${event.updatedByUser.lastName}`,
        lastUpdatedDate: JambarDateUtil.formatDate(event.updatedAt)
    }));
};

export const getReferenceListViewData = (forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getReferenceListApiUrl(), forceRefresh)
        .then((response: any) => Promise.resolve(transformData(response.data.data)))
        .catch((error: any) => Promise.reject(error));

export const onConfirmReferenceClone = (referenceId: number) =>
    Client.getInstance()
        .createData(ApiConstants.getCloneReferenceApiUrl(referenceId), {})
        .then((response: any) => Promise.resolve(response.data.data.id))
        .catch((error) => Promise.reject(error));

export const onConfirmDelete = (referenceId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteReferenceApiUrl(referenceId))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const filterDataBySearchText = (searchText: string, rows: ReferenceListViewItem[]) => {
    const trimSearchTextArray = searchText.trim().toLowerCase().split(" ");
    return rows.filter(
        (row: ReferenceListViewItem) =>
        (searchText !== "" &&
            intersection(row.title.trim().toLowerCase().split(" "), trimSearchTextArray)
                .length === trimSearchTextArray.length ||
            intersection(row.createdBy.trim().toLowerCase().split(" "), trimSearchTextArray)
                .length === trimSearchTextArray.length ||
            row.createdDate.toLowerCase().includes(searchText.toLowerCase()) ||
            intersection(row.lastUpdatedBy.trim().toLowerCase().split(" "), trimSearchTextArray)
                .length === trimSearchTextArray.length ||
            row.lastUpdatedDate.toLowerCase().includes(searchText.toLowerCase())
        ));
};

import * as React from "react";
import { Label, TextField } from "@fluentui/react";
import "./SameLineLabelTextField.scss";

type ISameLineLabelAndTextFieldProps = {
    label?: string | JSX.Element;
    textFieldValue: string;
    onTextFieldValueChanged?: (
        newValue: string,
        event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    description?: string;
    isMultiline?: boolean;
    width?: number;
    rows?: number;
    disabled?: boolean;
    required?: boolean;
    errorMessage?: string;
};

const SameLineLabelTextField: React.FC<ISameLineLabelAndTextFieldProps> = ({
    textFieldValue,
    label,
    onTextFieldValueChanged,
    description,
    isMultiline,
    width,
    rows,
    disabled,
    required,
    errorMessage
}) => {
    const _onTextFieldValueChanged = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        text?: string
    ): void => {
        const newText = text ? text : "";
        onTextFieldValueChanged && onTextFieldValueChanged(newText, event);
    };

    return (
        <div className="same-line-label-textfield-container">
            <Label style={{ width: width }} className="label margin-right-5">
                {label}
            </Label>
            <TextField
                multiline={isMultiline}
                className="textfield"
                value={textFieldValue}
                description={description}
                onChange={_onTextFieldValueChanged}
                rows={rows}
                disabled={disabled}
                required={required}
                errorMessage={errorMessage}
            />
        </div>
    );
};

export default SameLineLabelTextField;

import { Grid } from "@mui/material";
import React, { useState } from "react";
import QuestionIdentificationForm from "./QuestionIdentificationForm";
import QuestionDetailsForm from "./QuestionDetailsForm";
import AnswerDetailsForm from "./AnswerDetailsForm";
import QuestionSettingsForm from "./QuestionSettingsForm";
import { CancelButton, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import LoadingButtonWrapper from "../../../../CommonComponent/v2/button/LoadingButtonWrapper";
import GameDeleteDialog from "../../gamelibrary/GameDeleteDialog";
import { useFormikContext } from "formik";
import { QuestionDetailType } from "./Type";
import { isEmpty } from "lodash";
import { getLast } from "./Util";
import { UsageListType } from "../../gamelibrary/Type";

type QuestionFormProps = {
    removeCoverImage: () => void;
    handleCancel: () => void;
    questionId: string;
    gameId: string;
    usageList: UsageListType[];
    isLoading: boolean;
};
const QuestionForm = ({
    removeCoverImage,
    handleCancel,
    questionId,
    gameId,
    usageList,
    isLoading
}: QuestionFormProps) => {
    const { handleSubmit, values, isSubmitting, validateForm } = useFormikContext();
    const data = values as QuestionDetailType;
    const [openSaveQuestionModal, setOpenSaveQuestionModal] = useState<number | null>(null);

    return (
        <Grid item xs={12}>
            <Grid container maxWidth={"576px"}>
                <Grid item xs={12}>
                    <QuestionIdentificationForm />
                </Grid>
                <Grid item xs={12}>
                    <QuestionDetailsForm removeCoverImage={removeCoverImage} />
                </Grid>
                <Grid item xs={12}>
                    <AnswerDetailsForm gameId={data?.questionIdentification?.gameType || gameId} />
                </Grid>
                <Grid item xs={12}>
                    <QuestionSettingsForm />
                </Grid>
                <Grid my={"80px"} item xs={12}>
                    <Grid container alignItems={"center"} spacing={"20px"} justifyContent={"center"}>
                        <Grid item>
                            <CancelButton
                                onClick={() => {
                                    handleCancel();
                                    // handleClose && handleClose();
                                    // history.push(`/admin/v2/questionlibrary?pageNo=${pageNo}`);
                                }}>
                                Cancel
                            </CancelButton>
                        </Grid>
                        <Grid item>
                            <LoadingButtonWrapper loading={isSubmitting}>
                                <PrimaryButton
                                    onClick={async () => {
                                        const validationErrors = await validateForm();
                                        if (!isEmpty(validationErrors)) {
                                            getLast(validationErrors);
                                            return;
                                        }
                                        questionId ? setOpenSaveQuestionModal(parseInt(questionId)) : handleSubmit();
                                    }}
                                    style={{ padding: "16px 18px" }}>
                                    Save challenge
                                </PrimaryButton>
                            </LoadingButtonWrapper>
                        </Grid>
                        {openSaveQuestionModal && (
                            <GameDeleteDialog
                                usageList={usageList.slice(0, 5)}
                                type={"update"}
                                title={`Save Challenge`}
                                confirmLoading={isSubmitting}
                                message={
                                    usageList.length === 0
                                        ? [`Are you sure you want to save this challenge?`]
                                        : [
                                              "This challenge is currently being used in the following game(s). If you save, any edits made will also affect those games.",
                                              "Alternatively, you can duplicate the current challenge to avoid affecting other games."
                                          ]
                                }
                                showDeleteButton
                                onConfirm={handleSubmit}
                                onClose={() => setOpenSaveQuestionModal(null)}
                                isLoading={isLoading}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default QuestionForm;

import { Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { useUserHook } from "./Hooks";
import { SelectUserDeleteType, UserListType } from "./Type";
import { getAllUserList, getUserListFilteredItems } from "./Util";

type UserListProps = {
    searchText: string;
    setSelectedId: (id: number) => void;
    selectedUserDeleteId: (userInfo: SelectUserDeleteType) => void;
};
const UsersList: FC<UserListProps> = ({ searchText, setSelectedId, selectedUserDeleteId }) => {
    const { loading, setLoading, allUsers, setAllUsers, permission, UserListColumns, isSuperAdmin } = useUserHook();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        if (!(permission && permission.includes("fetch"))) {
            setAllUsers([]);
            return;
        }
        setLoading(true);
        getAllUserList(true)
            .then((res: UserListType[]) => {
                setAllUsers(res);
                setLoading(false);
            })
            .catch((err: any) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            });
    };

    const handleDelete = (userInfo: SelectUserDeleteType) => {
        selectedUserDeleteId(userInfo);
    };

    const handleEdit = (id: string) => {
        setSelectedId(parseInt(id));
    };
    const hideSubscription = allUsers.filter((item) => item.subscriptionStatus);

    return (
        <Grid height={"calc(100vh - 303px)"}>
            <DataGridWrapper
                columns={UserListColumns(handleDelete, handleEdit)}
                rows={getUserListFilteredItems(allUsers, searchText)}
                loading={loading}
                key={hideSubscription.length}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            subscriptionStatus: isSuperAdmin || hideSubscription.length !== 0,
                            identityCredit: isSuperAdmin,
                            identityName: isSuperAdmin
                        }
                    }
                }}
            />
        </Grid>
    );
};

export default UsersList;

import { Route } from "react-router-dom";
import PickAvatar from "./PickAvatar/PickAvatar";
import TeamName from "./TeamName/TeamName";
import TeamNameQuestion from "./TeamNameQuestion/TeamNameQuestion";
import CodeInputView from "./WelcomeScreens/CodeInputView";
import WildgoatGameView from "./WildgoatGameView";
import WildGoatLeaderboard from "./WildGoatLeaderboard/WildGoatLeaderboard";

const WildgoatRoute = () => (
    <>
        <Route exact path="/wildgoat">
            <WildgoatGameView />
        </Route>
        <Route exact path="/wildgoat/codeinput">
            <CodeInputView />
        </Route>
        <Route exact path="/wildgoat/leaderboard">
            <WildGoatLeaderboard />
        </Route>
        <Route exact path="/wildgoat/teamname">
            <TeamName />
        </Route>
        <Route exact path="/wildgoat/pickavatar">
            <PickAvatar />
        </Route>
        <Route exact path="/wildgoat/teamNameQuestion">
            <TeamNameQuestion />
        </Route>
    </>
);

export default WildgoatRoute;

export const MarvelQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "You don't know much about Marvel.",
            imageSrc: "https://i.imgur.com/jN4FdS4.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Not bad, you have some knowledge of Marvel films.",
            imageSrc: "https://i.imgur.com/mMQpQNv.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Great! The Avengers would be proud.",
            imageSrc: "https://i.imgur.com/UnD9Tty.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "Who is this Marvel character?",
            questionImageSrc: "https://i.imgur.com/YBo1Ac7.jpg",
            answerDescription: "Did you know that the Ant-Man’s real name is Scott Lang?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Ant-Man",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Groot",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Wasp",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Atom",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "Who is also known as the War Machine?",
            questionImageSrc: "https://i.imgur.com/UnSDXTJ.jpg",
            answerDescription: "James Rhodes is the best friend of Tony Stark, who is also known as Iron man.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Tony Stark",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Steve Rogers",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. James Rhodes",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Bucky Barnes",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Before Doctor Strange became a sorcerer, what was his profession?",
            questionImageSrc: "https://i.imgur.com/GhehISA.jpg",
            answerDescription:
                "After injuring his hands, Doctor Strange initially seeks out the Ancient One's healing to enable him to return to his job.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. engineer",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. neurosurgeon",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. journalist",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. CEO",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "What colour is the Space Stone?",
            questionImageSrc: "https://i.imgur.com/upRbn6c.jpg",
            answerDescription: "In the MCU, the Tesseract, also known as the Cosmic Cube, held the Space Stone.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. yellow",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. green",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. red ",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. blue",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Which of these actors has never played Bruce Banner?",
            questionImageSrc: "https://i.imgur.com/qCXNjPN.jpg",
            answerDescription: "In the MCU, Josh Brolin plays the role of Thanos.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Josh Brolin",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Edward Norton",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Mark Ruffalo",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Eric Bana",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "This character’s real name is _____.",
            questionImageSrc: "https://i.imgur.com/dxQaHSr.jpg",
            answerDescription: "Also known as “The Thing”, Ben is a member of the Fantastic Four.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Reed Richards",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Ben Grimm",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Ben Storm",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Bruce Banner",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "What is Razor Fist’s relation to Shang-Chi?",
            questionImageSrc: "https://i.imgur.com/cSadEAN.jpg",
            answerDescription: "Also known as Mattias, Razor Fist was adopted by Shang-Chi’s father.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. adopted brother",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. cousin",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. best friend",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. co-worker",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "In the movie The Wolverine, what is Ichirō Yashida’s alias?",
            questionImageSrc: "https://i.imgur.com/xH1JTXv.jpg",
            answerDescription:
                "In this movie, Ichirō Yashida, a Japanese World War 2 veteran who Wolverine saves during the war, tries to harness Wolverine’s healing abilities for himself.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Yukio",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Sabretooth",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Dr. Green",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Silver Samurai",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "In which feature film does Venom first appear?",
            questionImageSrc: "https://i.imgur.com/HBLLT04.jpg",
            answerDescription:
                "This film stars Tobey Maguire as Spider-Man, who unknowingly becomes corrupted by the Venom symbiote.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. The Amazing Spider-Man 2",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Iron Man",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. The Avengers",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Spider-Man 3",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "In the movie The Eternals, who plays the character Ajak?",
            questionImageSrc: "https://i.imgur.com/8ZnjjbT.jpg",
            answerDescription: "In this movie, Angelina Jolie plays the role of Thena. ",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Lupita Nyong'o",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Zoe Saldana",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Salma Hayek",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Angelina Jolie",
                    isCorrect: false
                }
            ]
        }
    ]
};

import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import { PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import FormikTextInput from "../../../../CommonComponent/v2/fields/FormikTextInput";
import LoadingWrapper from "../../../../CommonComponent/v2/LoadingWrapper";
import PasswordValidator from "../../../../CommonComponent/v2/PasswordValidator";
import { useChangePasswordHook } from "./Hooks";

type UserChangePasswordProps = {
    userId: number;
};
const UserChangePassword: FC<UserChangePasswordProps> = ({ userId }) => {
    const { setFieldValue, handleSubmit, values } = useFormikContext();
    const { loading } = useChangePasswordHook(userId, setFieldValue);
    const { password } = values as {
        password: string;
    };

    return (
        <LoadingWrapper isLoading={loading}>
            <Grid container spacing={"15px"} mt={"30px"}>
                <Grid item xs={12}>
                    <FormikTextInput id={"email"} disabled name={"email"} label={"Email"} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <FormikTextInput
                        id={"password"}
                        name={"password"}
                        label={"Password"}
                        textfieldType={"password"}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <PasswordValidator value={password} />
                </Grid>
                <Grid item xs={12}>
                    <FormikTextInput
                        id={"confirmPassword"}
                        name={"confirmPassword"}
                        textfieldType={"password"}
                        label={"Confirm Password"}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={"center"}>
                        <PrimaryButton
                            fontsize="16px"
                            style={{
                                height: 50,
                                padding: 16
                            }}
                            onClick={() => handleSubmit()}>
                            Change Password
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </LoadingWrapper>
    );
};

export default UserChangePassword;

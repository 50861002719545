import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import FormikTextInput from "../../../../CommonComponent/v2/fields/FormikTextInput";

const IdentityForm = () => {
    const { handleSubmit } = useFormikContext();

    return (
        <Grid container spacing={"15px"} mt={"30px"}>
            <Grid item xs={12}>
                <FormikTextInput id={"identityName"} name={"identityName"} label={"Identity name"} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent={"center"}>
                    <PrimaryButton
                        style={{
                            height: 50,
                            width: 182,
                            padding: 16
                        }}
                        onClick={() => handleSubmit()}>
                        Save Identity
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default IdentityForm;

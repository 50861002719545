export const videoBackground = `#181818`;
export const selectedButtonColor = `#219EBC`;
export const primaryButtonColor = `#007BFF`;
export const darkPrimaryColor = `#333333`;
export const darkPrimaryColor100 = `#7A7A7A`;
export const darkPrimaryColor200 = `#707070`;
export const darkPrimaryColor300 = `#A2A2A2`;
export const lightPrimaryColor = `#DEF4FF`;
export const lightPrimaryColor100 = `#EFEFEF`;
export const lightPrimaryColor200 = `#ffff`;
export const successColor = `#2AB12F`;
export const dangerColor = `#B12A2A`;
export const boxShadowColor = `#00000029`;
export const wildgoatPrimaryColor = `#66c9e2`;
export const wildgoatSecondaryColor = `#004E64`;
export const wildgoatTertiaryColor = `#d2d1d1`;
export const dangerColorLight = `#FFE6EE`;
export const linkColor = "#1F71B6";
export const primaryDarkColor = `#333333`;
export const deleteColor = `#EB5757`;
export const CaptionTextColor = `#BDBDBD`;
export const darkLightColor = `#828282`;
export const sucessLightColor = `#6cc070`;
export const errorLightColor = `#ff9494`;
export const pathQuizPrimaryColor = `#D8B2D8`;
export const pathQuizSecondaryColor = `#a2c3dd`;
export const pathQuizTertiaryColor = `pink`;

/*
1. Font COlor - 
2. Background Color -
3. Background Color after click -
*/

import { Button, Modal } from "react-bootstrap";
import Style from "@emotion/styled";
import {
    redColor,
    whiteColor,
    wildgoatTextFieldLabelColor,
    lightBlueColor,
    scoreBoxColor
} from "../../../../constants/Styles";

export const CustomModal = Style(Modal)`
padding: 2px;
border-radius: 35px;
& .modal-content {
    border-radius: 35px;
}
`;

type HeadTextProps = {
    fontcolor?: string;
};

export const HeadText = Style.p<HeadTextProps>`
margin: 0;
padding: 0;
font-weight: bold;
font-size: 16px;
color: ${(props) => props.fontcolor || wildgoatTextFieldLabelColor};
margin-bottom: 5px;
`;

type SubTextProps = {
    fontcolor?: string;
};
export const SubText = Style.p<SubTextProps>`
margin: 0;
padding: 0;
font-size: 16px;
color: ${(props) => props.fontcolor || wildgoatTextFieldLabelColor};
margin-bottom: 5px;
`;

export const ModalContainer = Style.div`
margin: 15px 10px;
`;

export const LighButton = Style(Button)`
background-color: ${scoreBoxColor};
color: ${redColor};
border-radius: 25px;
padding: 8px 20px;
font-weight: 500;
width: 100%;
box-shadow: 0px 15px 44px -19px rgb(0 0 0);
border: none;
&:active, &:hover {
    background: ${scoreBoxColor} !important;
    color: ${redColor} !important;
}

`;

type GreyButtonProps = {
    backgroundcolor?: string;
    fontcolor?: string;
};

export const GreyButton = Style(Button)<GreyButtonProps>`
background-color: ${(props) => props.backgroundcolor || lightBlueColor};
color: ${(props) => props.fontcolor || wildgoatTextFieldLabelColor};
font-weight: 500;
border-radius: 25px;
padding: 8px 20px;
width: 100%;
border: none;
&:active, &:hover {
    background-color: ${(props) => props.backgroundcolor || lightBlueColor} !important;
    color: ${(props) => props.fontcolor || wildgoatTextFieldLabelColor}; !important;
}
`;

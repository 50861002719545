import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import ImageDrop from '../../../CommonComponent/v2/fields/ImageDrop';
import { UserDetailsType } from "./Type";
import { CaptionText, TextButton } from '../../../CommonComponent/v2/CommonStyle';
import camera from "../../../Images/icons/camera.svg";
import FormikTextInput from "../../../CommonComponent/v2/fields/FormikTextInput";
import Avatars from "./Avatars";
import dropDownArrow from "../../../Images/icons/dropDownArrow.svg";
import { useProfile } from "./Hooks";
const ProfileTab = () => {
    const { values, setFieldValue } = useFormikContext();
    const data = values as UserDetailsType;
    const { showDropDown, setShowDropDown } = useProfile();

    const handleDropDown = () => {
        setShowDropDown(!showDropDown);
    };
    return (

        <Grid container spacing={"20px"}>
            <Grid item xs={4}>
                <ImageDrop
                    selectedFile={data.profileImage}
                    imageSize={2}
                    name={"profileImage"}
                    variant="tertiary"
                />

            </Grid>

            <Grid item xs={8} >
                <CaptionText fontWeight={700}>Profile Image</CaptionText>
                <TextButton onClick={() => {
                    document.getElementById("fileUpload")?.click();
                }}>
                    <img src={camera} />
                    change profile image
                </TextButton>
                <CaptionText mt={"20px"} fontWeight={700} mb={"5px"}>or choose an avatar</CaptionText>
                <Avatars setFieldValue={setFieldValue} />
            </Grid>
            <Grid item xs={12}>
                <FormikTextInput id={"firstName"} name={"firstName"} label={"First Name"} fullWidth style={{ backgroundColor: "white" }} />
            </Grid>
            <Grid item xs={12} >
                <FormikTextInput id={"lastName"} name={"lastName"} label={"Last Name"} fullWidth style={{ backgroundColor: "white" }} />
            </Grid>
            <Grid item xs={12} >
                <FormikTextInput id={"email"} name={"email"} label={"Email address"} fullWidth />
            </Grid>
            <Grid item xs={12} >
                <FormikTextInput id={"password"} name={"password"} label={"Password"} fullWidth textfieldType={"password"} />
            </Grid>
            <Grid item xs={12} >
                <TextButton onClick={handleDropDown}>
                    Change password <img src={dropDownArrow} />
                </TextButton>

            </Grid>
            {
                showDropDown && <>
                    <Grid item xs={12} >
                        <FormikTextInput id={"newPassword"} name={"newPassword"} label={"New password"} fullWidth textfieldType={"password"} />
                    </Grid>
                    <Grid item xs={12} >
                        <FormikTextInput id={"confirmNewPassword"} name={"confirmNewPassword"} label={"confirm Password"} fullWidth textfieldType={"password"} />
                    </Grid>
                </>
            }
        </Grid>

    );
};

export default ProfileTab;
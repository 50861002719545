import React, { FC } from "react";
import { GameEndButton, QuizEndContainer, QuizResultContainer, QuizResultTitleText } from "./style";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Grid } from "@mui/material";
import { ImageContainer } from "./style";
import { quizResultTextColor } from "./colorConstant";
import { EndingType } from "./type";

type WebsiteGameEndProps = {
    correctAnswerCount: number;
    totalQuestion: number;
    restartQuiz: () => void;
    endingInfo?: EndingType;
};
const WebsiteGameEnd: FC<WebsiteGameEndProps> = ({ correctAnswerCount, totalQuestion, endingInfo }) => (
    <QuizEndContainer container direction="column" justifyContent="center" alignItems="center">
        <QuizResultContainer item xs={12} md={5}>
            <Grid container alignItems={"center"}>
                <Grid item xs={12} md={6}>
                    <div style={{ width: 150, height: 150 }}>
                        <CircularProgressbar
                            value={(correctAnswerCount / totalQuestion) * 100}
                            text={`${correctAnswerCount}/${totalQuestion}`}
                            styles={buildStyles({
                                textColor: quizResultTextColor,
                                pathColor: quizResultTextColor,
                                trailColor: "grey"
                            })}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <QuizResultTitleText>{endingInfo?.text}</QuizResultTitleText>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <ImageContainer src={endingInfo?.imageSrc} style={{ width: "100%" }} />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <GameEndButton style={{ pointerEvents: "none" }}>Close this tab to try another quiz</GameEndButton>
                </Grid>
            </Grid>
        </QuizResultContainer>
    </QuizEndContainer>
);

export default WebsiteGameEnd;

import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from "@mui/material";
import { useField } from "formik";
import { FC } from "react";
import { StyledRadioGroupContainer } from "./Style";
import { OptionType } from "./Type";

type FormikRadioGroupProps = {
    radioOptions: OptionType[];
    name: string;
    labelfontweight?: number;
} & RadioGroupProps;
const FormikRadioGroup: FC<FormikRadioGroupProps> = ({ radioOptions, name, labelfontweight, ...rest }) => {
    const [field] = useField(name);

    return (
        <StyledRadioGroupContainer labelfontweight={labelfontweight}>
            <RadioGroup {...rest} {...field}>
                {radioOptions.map((item) => (
                    <FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.label} />
                ))}
            </RadioGroup>
        </StyledRadioGroupContainer>
    );
};

export default FormikRadioGroup;

import { ClassNameMap } from "@mui/material/";
import { CSSProperties } from "react";
import { number, date, object } from "yup";
import { getEventCode } from "../Util";
import { bulkCodeType, FileDataType, ParticipantEmailType, referenceListType } from "./type";

export const eventCodeValidation = object().shape({
    numberOfCode: number().required("Enter number of code"),
    startDate: date().typeError("Please select a valid date").required("From date is mandatory"),
    endDate: date()
        .when(
            "startDate",
            (startDate: Date, schema: any) =>
                startDate && schema.min(startDate, "To date should be greater than From date")
        )
        .typeError("Please select a valid date")
        .required("To date is mandatory")
});

export const generateBulkCode = (
    numberOfCode: number,
    startDate: string,
    endDate: string,
    excludeEventCodes: string[]
): bulkCodeType[] => {
    const bulkCode = [];
    for (let i = 0; i < numberOfCode; i++) {
        const code = {
            startDate,
            endDate,
            participantCode: getEventCode(excludeEventCodes)
        };
        bulkCode.push(code);
    }
    return bulkCode;
};

export const customSearchAbleDropDownStyles: CSSProperties = {
    border: "1px solid rgb(96, 94, 92);",
    borderRadius: "2px"
};

export const selectStyle: any = {
    control: (provided: any) => ({
        ...provided,
        ...customSearchAbleDropDownStyles
    })
};

export const customNewSearchAbleDropDownStyles = (isError: boolean): CSSProperties => ({
    border: isError ? "1px solid #d32f2f" : "1px solid #C2C2C2",
    borderRadius: "10px",
    background: "#FCFCFC",
    height: "50px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#BDBDBD",
    padding: "0px 5px",
    zIndex: 100
});

export const newSelectStyle = (isError = false): any => ({
    control: (provided: any) => {
        return {
            ...provided,
            ...customNewSearchAbleDropDownStyles(isError)
        };
    },
    menu: (provided: any) => {
        return {
            ...provided,
            zIndex: 100
        };
    }
});

export const getReferenceId = (referenceLists: referenceListType[], valueOne: number, valueTwo: number) => {
    const refList: referenceListType[] = referenceLists.filter(
        (item: any) => item.id === valueOne || item.id === valueTwo
    );
    const refIds = refList.map((list: referenceListType) => list.id).join(";");
    return refIds ? refIds : null;
};

export const getReferenceCode = (referenceLists: referenceListType[], value: number) => {
    const refList = referenceLists.find((item: referenceListType) => item.id === value);
    return refList ? refList.referenceLink : "";
};

export const getPointsBarClass = (classes: ClassNameMap<any>, points: number) => {
    return points <= 40 ? classes.redBackground : points <= 70 ? classes.orangeBackground : classes.greenBackground;
};

export const sortFilesList = (fileList: FileDataType[]) =>
    fileList.sort((a: FileDataType, b: FileDataType) =>
        a.teamName.toLowerCase() > b.teamName.toLowerCase()
            ? 1
            : a.teamName === b.teamName
            ? a.createdAt > b.createdAt
                ? 1
                : -1
            : -1
    );

export const getParticipantEmailListFilteredItems = (list: ParticipantEmailType[], searchText: string): any =>
    list.filter(
        (item: ParticipantEmailType) => searchText === "" || includeInFilteredItems(searchText.toLowerCase(), item)
    );

const includeInFilteredItems = (searchText: string, item: ParticipantEmailType): boolean => {
    if (item.id.toString().includes(searchText)) {
        return true;
    }

    if (item.email.toLowerCase().includes(searchText)) {
        return true;
    }

    return false;
};

import { IColumn } from "office-ui-fabric-react";
import { SiteConstants } from "../../../../constants/SiteConstant";

export const leaderBoardListColumns: IColumn[] = [
    {
        key: "position",
        name: "Position",
        fieldName: "position",
        minWidth: SiteConstants.listColumn55,
        maxWidth: SiteConstants.listColumn55
    },
    {
        key: "teamName",
        name: "Team name",
        fieldName: "teamName",
        minWidth: SiteConstants.listColumnSmall,
        maxWidth: SiteConstants.listColumnMedium
    },
    {
        key: "participantCode",
        name: "Participant code",
        fieldName: "participantCode",
        minWidth: SiteConstants.listColumnXSmall,
        maxWidth: SiteConstants.listColumn120
    },
    {
        key: "status",
        name: "Status",
        fieldName: "status",
        minWidth: SiteConstants.listColumnXSmall,
        maxWidth: SiteConstants.listColumnXSmall
    },
    {
        key: "startTime",
        name: "Start time",
        fieldName: "startTime",
        minWidth: SiteConstants.listColumnXSmall,
        maxWidth: SiteConstants.listColumnXSmall
    },
    {
        key: "score",
        name: "Score",
        fieldName: "score",
        minWidth: SiteConstants.listColumnXSmall,
        maxWidth: SiteConstants.listColumnXSmall
    },
    {
        key: "questionAnsweredCount",
        name: "No. of questions answered",
        fieldName: "questionAnsweredCount",
        minWidth: SiteConstants.listColumnXSmall,
        maxWidth: SiteConstants.listColumn170
    },
    {
        key: "correctAnswersCount",
        name: "No. of correct answers",
        fieldName: "correctAnswersCount",
        minWidth: SiteConstants.listColumnXSmall,
        maxWidth: SiteConstants.listColumn150
    },
    {
        key: "wrongAnswersCount",
        name: "No. of wrong answers",
        fieldName: "wrongAnswersCount",
        minWidth: SiteConstants.listColumnXSmall,
        maxWidth: SiteConstants.listColumn150
    },
    {
        key: "hintsUsedCount",
        name: "No. of Hints used",
        fieldName: "hintsUsedCount",
        minWidth: SiteConstants.listColumnXSmall,
        maxWidth: SiteConstants.listColumn120
    },
    {
        key: "timeTakenInQuiz",
        name: "Time taken in quiz",
        fieldName: "timeTakenInQuiz",
        minWidth: SiteConstants.listColumnSmall,
        maxWidth: SiteConstants.listColumnMedium
    },
    {
        key: "timeLeftInConnector",
        name: "Time left in connector",
        fieldName: "timeLeftInConnector",
        minWidth: SiteConstants.listColumnSmall,
        maxWidth: SiteConstants.listColumnMedium
    },
    {
        key: "timeLeftInQuiz",
        name: "Time left in quiz",
        fieldName: "timeLeftInQuiz",
        minWidth: SiteConstants.listColumnSmall,
        maxWidth: SiteConstants.listColumnMedium
    }
];

export const ActiveEvent = "activeEvent";
export const ClosedEvent = "closedEvent";

import { Icon } from "@fluentui/react";
import { MobileStepper, Typography } from "@mui/material";
import React, { FC } from "react";
import { Col, Button, Row } from "react-bootstrap";
import { SetupWizardParent } from "../InitialSetupStyle";
import { ConfigContainer, ConfigInfoTitleCol, DotsStepper } from "./ConfigsStyle";

type ConfigsCheckProps = {
    activeStep: number;
    iconName: string;
    title: string;
    body: string;
    moveToNextScreen: () => void;
};

const ConfigsCheck: FC<ConfigsCheckProps> = ({ activeStep, body, iconName, title, moveToNextScreen }) => {
    return (
        <ConfigContainer>
            <SetupWizardParent>
                <Col xs={12}>
                    <h3>Welcome and join the game</h3>
                </Col>
                <Col xs={12}>
                    <h5>Before you join, let's make sure game experience will be great</h5>
                </Col>
                <ConfigInfoTitleCol md={7} xs={12}>
                    <Row style={{ alignItems: "center" }}>
                        <Col md={3} xs={12}>
                            <Icon iconName={iconName} style={{ fontSize: 90 }} />
                        </Col>
                        <Col md={9} xs={12}>
                            <div>
                                <h5>{title}</h5>
                                <Typography variant={"body1"}>{body}</Typography>
                            </div>
                        </Col>
                    </Row>
                </ConfigInfoTitleCol>
                <DotsStepper xs={12}>
                    <MobileStepper
                        variant="dots"
                        steps={4}
                        position="static"
                        activeStep={activeStep - 1}
                        nextButton={() => <div />}
                        backButton={() => <div />}
                    />
                </DotsStepper>
                <Col style={{ marginTop: 40 }}>
                    <Button onClick={moveToNextScreen}>Continue</Button>
                </Col>
            </SetupWizardParent>
        </ConfigContainer>
    );
};

export default ConfigsCheck;

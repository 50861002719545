import { ApiConstants } from "../../../../constants/ApiConstant";
import { Client } from "../../../Base/Client";
import { LiveTeamPerformanceType } from "./Type";

const transformData = (data: any): LiveTeamPerformanceType | null => {
    if (!data) {
        return null;
    }

    return {
        teamName: data.teamName,
        totalCorrectAnswers: data.totalCorrectAnswers,
        totalQuestionAnswers: data.totalQuestionAnswers,
        totalWrongAnswers: data.totalWrongAnswers
    };
};
export const getLiveTeamPerformanceList = (eventId: string, partcipantCode: string) =>
    Client.getInstance()
        .getData(ApiConstants.getLiveTeamPerformanceApiUrl(eventId, partcipantCode), true)
        .then((res) => Promise.resolve(transformData(res.data.data)))
        .catch(() => Promise.reject());

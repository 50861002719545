import { SubText, HeadText, ModalContainer, LighButton, GreyButton } from "../../../WildGoat/WildgoatDialog/Style";
import EllipseImage from "../../../../Images/Wildgoat/Ellipse.svg";
import { Dialog, Grid } from "@mui/material";
import { FlexBox } from "../../../WildGoat/QuestionTypeView/Style";
import { WildGoatsCenterContainer } from "../../../WildGoat/CommonStyledComponents/CommonStyle";

type PathGameDialogProps = {
    show: boolean;
    onHide: () => void;
    onSubmit: () => void;
    modalTitle: string;
    modalSubText: string;
    modalImageSrc?: string;
    cancelButtonText: string;
    submitButtonText: string;
    hideCancelButton?: boolean;
    backgroundColor?: string;
};

const PathGameDialog = ({
    show,
    onHide,
    onSubmit,
    modalTitle,
    modalSubText,
    cancelButtonText,
    submitButtonText,
    modalImageSrc,
    hideCancelButton,
    backgroundColor
}: PathGameDialogProps) => {
    return (
        <Dialog open={show} onClose={onHide} maxWidth={"md"} fullWidth>
            <Grid container>
                <ModalContainer>
                    {modalImageSrc && (
                        <WildGoatsCenterContainer style={{ marginBottom: 20 }}>
                            <img src={modalImageSrc} alt="modalImage" />
                            <img src={EllipseImage} alt="EllipseImage" />
                        </WildGoatsCenterContainer>
                    )}
                    <HeadText>{modalTitle}</HeadText>
                    <SubText>{modalSubText}</SubText>
                    <FlexBox>
                        {!hideCancelButton && (
                            <LighButton className="margin-right-5" onClick={onHide}>
                                {cancelButtonText}
                            </LighButton>
                        )}
                        <GreyButton backgroundcolor={backgroundColor} onClick={onSubmit}>
                            {submitButtonText}
                        </GreyButton>
                    </FlexBox>
                </ModalContainer>
            </Grid>
        </Dialog>
    );
};

export default PathGameDialog;

import { array, lazy, object, string } from "yup";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig } from "../../../../../utils/JambarUtils";
import { VALID_IMAGE } from "../../../../../constants/SiteConstant";
import { BreadCrumbType, AddReferenceType } from "./Type";
import { Client } from "../../../../Base/Client";
import { ApiConstants } from "../../../../../constants/ApiConstant";
import { EditReferenceType } from "../editReference/Type";

export const referenceBreadcrumbValues: BreadCrumbType[] = [
    {
        label: "Reference list",
        url: "/admin/v2/referenceList"
    },
    {
        label: "Add new reference"
    }
];

export const addReferenceInitialValues = (referenceDetails?: AddReferenceType): AddReferenceType => ({
    title: referenceDetails?.title || "",
    description: referenceDetails?.description || "",
    referenceListCode: referenceDetails?.referenceListCode || "",
    centreHeading: referenceDetails?.centreHeading || "",
    attachmentTitle: referenceDetails?.attachmentTitle || "",
    backgroundColor: referenceDetails?.backgroundColor || "",
    buttonColor: referenceDetails?.buttonColor || "",
    coverImage: referenceDetails?.coverImage || null,
    descriptionImage: referenceDetails?.descriptionImage || null,
    backgroundImage: referenceDetails?.backgroundImage || null,
    progressImage: referenceDetails?.progressImage || null
});

export const editReferenceInitialValue = (referenceDetails?: EditReferenceType): EditReferenceType => ({
    ...addReferenceInitialValues(referenceDetails),
    referendeListItems: referenceDetails?.referendeListItems || ""
});

export const addReferenceTabs = [
    {
        label: "Details",
        value: "detail",
        toShow: true
    },
    {
        label: "Template",
        value: "template",
        toShow: true
    }
];
export const validationSchema = object().shape(
    {
        title: string().required("Title is required"),
        description: string().required("Description is required"),
        referenceListCode: string(),
        coverImage: lazy((val) =>
            Array.isArray(val)
                ? array()
                    .min(1, "Please select an image")
                    .test(
                        "is-big-file",
                        "Image must be less then 2MB",
                        (value) => !value || checkIfFilesAreTooBig(value, 8)
                    )
                    .test(
                        "is-correct-file",
                        "Please upload image only",
                        (value) => !value || checkIfFilesAreCorrectType(value, VALID_IMAGE)
                    )
                : string()
                    .nullable()
        ),
        descriptionImage: lazy((val) =>
            Array.isArray(val)
                ? array()
                    .max(1, "Please select an image")
                    .test(
                        "is-big-file",
                        "Image must be less then 2MB",
                        (value) => !value || checkIfFilesAreTooBig(value, 8)
                    )
                    .test(
                        "is-correct-file",
                        "Please upload image only",
                        (value) => !value || checkIfFilesAreCorrectType(value, VALID_IMAGE)
                    )
                : string()
                    .nullable()
        ),
        backgroundImage: lazy((val) =>
            Array.isArray(val)
                ? array()
                    .min(1, "Please select an image")
                    .test(
                        "is-big-file",
                        "Image must be less then 2MB",
                        (value) => !value || checkIfFilesAreTooBig(value, 8)
                    )
                    .test(
                        "is-correct-file",
                        "Please upload image only",
                        (value) => !value || checkIfFilesAreCorrectType(value, VALID_IMAGE)
                    )
                : string()
                    .nullable()
        ),
        progressImage: lazy((val) =>
            Array.isArray(val)
                ? array()
                    .max(1, "Please select an image")
                    .test(
                        "is-big-file",
                        "Image must be less then 2MB",
                        (value) => !value || checkIfFilesAreTooBig(value, 8)
                    )
                    .test(
                        "is-correct-file",
                        "Please upload image only",
                        (value) => !value || checkIfFilesAreCorrectType(value, VALID_IMAGE)
                    )
                : string()
                    .nullable()
        )
    }
);

export const reverseTransformData = (values: AddReferenceType): any => {
    const referenceData = new FormData();
    referenceData.append("name", values.title);
    referenceData.append("centerHeading", values.centreHeading);
    referenceData.append("attachmentTitle", values.attachmentTitle);
    referenceData.append("backgroundColor", values.backgroundColor);
    referenceData.append("baseButtonColor", values.buttonColor);
    referenceData.append("backgroundImage", values.backgroundImage as Blob);
    referenceData.append("progressImage", values.progressImage as Blob);
    referenceData.append("description", values.description);
    referenceData.append("referenceListImage", values.coverImage as Blob);
    referenceData.append("descriptionImage", values.descriptionImage as Blob);
    values.referenceListCode && referenceData.append("referenceLink", values.referenceListCode);
    return referenceData;
};
export const addNewReference = (values: AddReferenceType) => {
    return Client.getInstance()
        .createData(ApiConstants.addReferenceApiUrl(), reverseTransformData(values))
        .then((response: any) => Promise.resolve(response.data.data.id))
        .catch((error) => Promise.reject(error));

};
import { IColumn, IconButton } from "@fluentui/react";
import { SiteConstants } from "../../../constants/SiteConstant";
import { RoleType } from "./Type";

export const renderRoleListColumn = (
    item: any,
    _: number | undefined,
    column: IColumn | undefined,
    showRoleDetailsPanel: (roleId: RoleType) => void,
    showRightsDetailsPanel: (roleId: RoleType) => void,
    onDeleteEvent: (roleId: number) => void
): JSX.Element => {
    if (!column) {
        return <span />;
    }

    switch (column.key) {
        case "name":
            return <span>{item.name}</span>;

        case "update":
            return (
                <IconButton
                    iconProps={{ iconName: "Edit" }}
                    title="Edit"
                    onClick={() =>
                        showRoleDetailsPanel({
                            id: item.id,
                            name: item.name,
                            sno: item.sno
                        })
                    }
                />
            );

        case "change-permission":
            return (
                <IconButton
                    iconProps={{ iconName: "StoreLogo16" }}
                    title="Resource and Rights"
                    onClick={() =>
                        showRightsDetailsPanel({
                            id: item.id,
                            name: item.name,
                            sno: item.sno
                        })
                    }
                />
            );

        case "delete":
            return (
                <IconButton iconProps={{ iconName: "delete" }} title="Delete" onClick={() => onDeleteEvent(item.id)} />
            );
        default: {
            const fieldContent = item[column.fieldName as keyof RoleType] as string;
            return <span>{fieldContent}</span>;
        }
    }
};

export const RoleListColumns: IColumn[] = [
    {
        key: "sno",
        name: "S.No",
        fieldName: "sno",
        minWidth: SiteConstants.listColumnSmall,
        maxWidth: SiteConstants.listColumnSmall
    },
    {
        key: "name",
        name: "Role",
        fieldName: "name",
        minWidth: SiteConstants.listColumnMedium
    }
];

export const RoleMenuColumns: IColumn[] = [
    {
        key: "update",
        name: "",
        fieldName: "update",
        minWidth: SiteConstants.listColumnIcon,
        maxWidth: SiteConstants.listColumnIcon
    },
    {
        key: "change-permission",
        name: "",
        fieldName: "change-permission",
        minWidth: SiteConstants.listColumnIcon,
        maxWidth: SiteConstants.listColumnIcon
    },
    {
        key: "delete",
        name: "",
        fieldName: "delete",
        minWidth: SiteConstants.listColumnIcon,
        maxWidth: SiteConstants.listColumnIcon
    }
];

import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import { MenuText, MenuItemButton } from "./Style";
import JambarLogo from "../../../Images/logo.svg";
import Header from "./Header";
import { HIDE_SIDEBAR_URL, drawerWidth, superAdmin } from "../../../../constants/SiteConstant";
import { useHistory, useLocation } from "react-router";
import { whiteColor } from "../../../../constants/Styles";
import { drawerColor } from "../../../WebsiteGame/colorConstant";
import { useAdminContext } from "../../context/AdminAuthContext";
import { getPermissions } from "../../../../utils/PermissionUtils";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LeaderBoardIcon from "../../../Images/icons/leaderboard.svg";
import GameIcon from "../../../Images/icons/game_icon.svg";
import ChallengeIcon from "../../../Images/icons/challenges_icon.svg";
import EventIcon from "../../../Images/icons/event_icon.svg";
import UserIcon from "../../../Images/icons/user.svg";
import FileIcon from "../../../Images/icons/file.svg";
import RoleIcon from "../../../Images/icons/role.svg";
import { SideMenuType } from "./Type";
import AccordianMenu from "./AccordianMenu";
import SpiralImage from "../../../Images/icons/spiral.svg";
import { MdOutlineSubscriptions } from "react-icons/md";

type SideBarProps = {
    children: React.ReactNode;
};

const SideBar: FC<SideBarProps> = ({ children }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const history = useHistory();

    const { userDetail } = useAdminContext();
    const IS_SUPER_ADMIN = userDetail?.currentRoles.find((item) => item.name === superAdmin);

    const permissionQuestion: string[] | null = getPermissions(userDetail, "question");
    const permissionReferenceLib: string[] | null = getPermissions(userDetail, "referenceList");
    const permissionEvent: string[] | null = getPermissions(userDetail, "event");
    // const permissionUser: string[] | null = getPermissions(userDetail, "user");
    const permissionQuizFullGame: string[] | null = getPermissions(userDetail, "quiz_fullgame");
    const permissionQuizSocketGame: string[] | null = getPermissions(userDetail, "quiz_socket");
    const permissionQuizPhysicalGame: string[] | null = getPermissions(userDetail, "quiz_wildgoat");
    const permissionLeaderboard: string[] | null = getPermissions(userDetail, "leaderboard");
    const permissionRoles: string[] | null = getPermissions(userDetail, "role");

    const [activeKey, setActiveKey] = useState<string>(history.location.pathname);

    useEffect(() => {
        setActiveKey(history.location.pathname);
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const SideNavbarOption: SideMenuType[] = [
        {
            id: 20,
            title: "Events",
            url: "/admin/event",
            icon: <img src={EventIcon} style={{ height: 20 }} />,
            toShow: permissionEvent?.includes("fetch")
        },
        {
            id: 21,
            title: "Games",
            url: "/admin/v2/gamelibrary",
            icon: <img src={GameIcon} style={{ height: 20 }} />,
            toShow:
                permissionQuizFullGame?.includes("fetch") ||
                permissionQuizSocketGame?.includes("fetch") ||
                permissionQuizPhysicalGame?.includes("fetch")
        },
        {
            id: 22,
            title: "Challenges",
            url: "/admin/v2/questionlibrary",
            icon: <img src={ChallengeIcon} style={{ height: 20 }} />,
            toShow: permissionQuestion?.includes("fetch")
        },
        {
            id: 23,
            title: "Ref. games library",
            url: "/admin/v2/referencelistmanager",
            icon: <img src={LeaderBoardIcon} style={{ height: 20 }} />,
            toShow: permissionReferenceLib?.includes("fetch")
        },
        {
            id: 8,
            title: "Leaderboards",
            icon: <img src={LeaderBoardIcon} style={{ height: 20 }} />,
            url: "/admin/leaderboard",
            toShow: permissionLeaderboard?.includes("fetch")
        },
        {
            id: 9,
            title: "Clients",
            icon: <img src={UserIcon} style={{ height: 20 }} />,
            url: "/admin/users",
            toShow: !!IS_SUPER_ADMIN
        },
        {
            id: 10,
            title: "Event Media",
            icon: <img src={FileIcon} style={{ height: 20 }} />,
            url: "/admin/files",
            toShow: permissionEvent?.includes("fetch")
        },
        {
            id: 11,
            title: "Role",
            icon: <img src={RoleIcon} style={{ height: 20 }} alt={"roles"} />,
            url: "/admin/roles",
            toShow: permissionRoles?.includes("fetch") && userDetail?.identity.name === "jambar"
        },
        {
            id: 11,
            title: "Payments",
            icon: <MdOutlineSubscriptions style={{ fontSize: 20 }} />,
            url: "/admin/subscription",
            toShow: false
        },
        {
            id: 12,
            title: "Payments List",
            icon: <LockOpenIcon />,
            url: "/admin/paymentlist",
            toShow: false
        },
        {
            id: 12,
            title: "Password",
            icon: <LockOpenIcon />,
            url: "/admin/password",
            toShow: false
        }
    ];

    const renderMenu = (menuOption: SideMenuType) => {
        if (menuOption.subMenu && menuOption.subMenu?.length > 0 && menuOption.toShow) {
            return (
                <AccordianMenu
                    key={`${menuOption.id}${history.location.pathname}`}
                    menuInfo={menuOption}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                />
            );
        }
        return (
            <MenuItemButton
                onClick={() => {
                    menuOption.url && setActiveKey(menuOption.url);
                    menuOption.url && history.push(menuOption.url);
                }}
                isactive={window.location.pathname.includes(menuOption.url || "") ? 1 : 0}
                key={`${menuOption.id}${history.location.pathname}`}>
                <ListItemIcon style={{ width: 30, marginRight: 3, minWidth: 0 }}>{menuOption.icon}</ListItemIcon>
                <MenuText
                    isactive={window.location.pathname.includes(menuOption.url || "") ? 1 : 0}
                    style={{ color: whiteColor }}
                    primary={menuOption.title}
                />
            </MenuItemButton>
        );
    };
    const drawer = useCallback(
        () => (
            <Grid bgcolor={drawerColor} padding={"15px"} overflow={"auto"} minHeight={"calc(100vh)"}>
                <Grid container height={"100%"} justifyContent={"space-between"} direction={"row"}>
                    <Grid item style={{ zIndex: 1, width: "100%" }}>
                        <Grid container justifyContent={"center"}>
                            <img style={{ height: 64 }} src={JambarLogo} alt={"logo"} />
                        </Grid>
                        <Box>
                            {SideNavbarOption.filter((item) => item.toShow).map((menuOption) => {
                                return <Fragment key={menuOption.id}>{renderMenu(menuOption)}</Fragment>;
                            })}
                        </Box>
                    </Grid>
                    <Grid item position={"fixed"} bottom={0} marginBottom={"40px"}>
                        <img style={{ zIndex: 1 }} src={SpiralImage} alt={"logo"} />
                    </Grid>
                </Grid>
            </Grid>
        ),
        [activeKey]
    );

    const location = useLocation();

    const sideDrawerWidth = !HIDE_SIDEBAR_URL.includes(location.pathname) ? drawerWidth : 0;
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Header drawerWidth={sideDrawerWidth} handleDrawerToggle={handleDrawerToggle} />
            <Box
                component="nav"
                sx={{
                    width: { sm: sideDrawerWidth },
                    flexShrink: { sm: 0 }
                }}>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true
                    }}
                    PaperProps={{
                        style: {
                            backgroundColor: drawerColor
                        }
                    }}>
                    {drawer()}
                </Drawer>
                {!HIDE_SIDEBAR_URL.includes(location.pathname) && (
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: "none", sm: "block" },
                            "& .MuiDrawer-paper": { boxSizing: "border-box", width: sideDrawerWidth }
                        }}
                        PaperProps={{
                            style: {
                                backgroundColor: whiteColor,
                                borderRight: 0
                            }
                        }}
                        open>
                        {drawer()}
                    </Drawer>
                )}
            </Box>
            <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${sideDrawerWidth}px)` } }}>
                <Toolbar />
                <Grid height={"calc(100vh - 120px)"} marginTop={"10px"}>
                    {children}
                </Grid>
            </Box>
        </Box>
    );
};

export default SideBar;

export const AnimalQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "You're not a true animal lover",
            imageSrc: "https://i.imgur.com/TpybpVU.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Not bad, you seem to like animals",
            imageSrc: "https://i.imgur.com/zY0KnYy.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Fantastic! You're a true animal lover",
            imageSrc: "https://i.imgur.com/VrqLKw3.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "What is this sea creature?",
            questionImageSrc: "https://i.imgur.com/j3MmUti.jpg",
            answerDescription:
                "Stingrays are much smaller than manta rays, and their mouths are located on the underside of their body, while mantas’ mouths are at the front of their heads.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. blobfish",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. stingray",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. eel",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. manta ray",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "To which continent is this type of lizard native?",
            questionImageSrc: "https://i.imgur.com/grun80Y.jpg",
            answerDescription:
                "These flying/ gliding lizards are called Draco lizards and can be found in the region of South-eastern Asia. Their “wings” enable them to glide between trees and avoid predators.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Asia",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. North America",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Africa",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Europe",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "What type of animal is this?",
            questionImageSrc: "https://i.imgur.com/pYldoor.jpg",
            answerDescription:
                "These adorable but endangered animals can be found in the Southwestern region of China and in Nepal and are possibly related to raccoons.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. desert fox",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. red panda",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. lynx",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. red bear",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "What type of bird has the longest wingspan?",
            questionImageSrc: "https://i.imgur.com/nbLidgk.jpg",
            answerDescription:
                "Did you know that many albatrosses live for more than 50 years, and their wingspans can reach up to 12 feet?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. seagull",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. ostrich",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. albatross",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. eagle",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Can you correctly guess what type of animal this is?",
            questionImageSrc: "https://i.imgur.com/rh4n8Nz.jpg",
            answerDescription:
                "Did you know that the Chinese pangolin’s greatest predator is the human? It is a critically endangered species.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. capybara",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. anteater",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. hedgehog",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. pangolin",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "When hermit crabs change their shells, that process is called ______.",
            questionImageSrc: "https://i.imgur.com/sYSBrk0.jpg",
            answerDescription:
                "Did you know that hermit crabs often use plastic and metal garbage as shells, and doing this can kill them?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. hopping out",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. moulting",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. respiration",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. morphing",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "Can you tell which statement about sloths is not true?",
            questionImageSrc: "https://i.imgur.com/iVyUP1e.jpg",
            answerDescription:
                "The koala’s diet is mainly made up of eucalyptus leaves, whereas sloths eat a variety of foliage. Sloths also digest food very slowly, which explains why they don’t poo more often.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. They only eat eucalyptus leaves",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. They are pretty good swimmers",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. They do not have front teeth",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. They poo only once per week",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Where would you find this species of bird - the weka?",
            questionImageSrc: "https://i.imgur.com/ZImfGxc.jpg",
            answerDescription:
                "The weka is a flightless bird, as you might have guessed. It is only one of several flightless birds only found in New Zealand.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. India",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Antarctica",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. New Zealand",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. South Africa",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "What type of mollusc lives in this shell?",
            questionImageSrc: "https://i.imgur.com/zDDGQFs.jpg",
            answerDescription: "Did you know that conch shells make pretty good (and pretty loud) musical instruments?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. razor clam",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. conch",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. squid",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. scallop",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "All of these statements about squids are factual except:",
            questionImageSrc: "https://i.imgur.com/PtH5ykZ.jpg",
            answerDescription:
                "Both squids and octopuses can produce ink, which they squirt when they feel threatened. An even more interesting fact is that some squid species can emit light from their bodies.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. They have 3 hearts",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. They do not make ink",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. They can produce light",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. They can change colour",
                    isCorrect: false
                }
            ]
        }
    ]
};

import { ApiConstants } from "../../../constants/ApiConstant";
import { Client } from "../../Base/Client";
import { RoleType } from "./Type";

const transaformData = (data: any): RoleType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any, index: number) => ({
        sno: index + 1,
        id: item.id,
        name: item.name
    }));
};

export const getRoles = (forceRefresh = false): any =>
    Client.getInstance()
        .getData(ApiConstants.getRolesListApiUrl(), forceRefresh)
        .then((res: any) => Promise.resolve(transaformData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const addRole = (name: string): any =>
    Client.getInstance()
        .createData(ApiConstants.addRolesApiUrl(), {
            name: name
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const updateRole = (name: string, id: number): any =>
    Client.getInstance()
        .updateData(ApiConstants.updateRoleApiUrl(id), {
            name: name
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const deleteRole = (id: number): any =>
    Client.getInstance()
        .deleteData(ApiConstants.updateRoleApiUrl(id))
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

import { ApiConstants } from "../../../../constants/ApiConstant";
import { Client } from "../../../Base/Client";

const transformData = (data: any) => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        id: item.id,
        email: item.email
    }));
};

export const getEmailList = (eventId: number) =>
    Client.getInstance()
        .getData(ApiConstants.getEmailListApiUrl(eventId), true)
        .then((res) => Promise.resolve(transformData(res.data.data)))
        .catch((err) => Promise.reject(err));

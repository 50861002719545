import { ApiConstants } from "../../../constants/ApiConstant";
import { Client } from "../../Base/Client";

export const changePassword = (currentPassword: string, newPassword: string) =>
    Client.getInstance()
        .updateData(ApiConstants.changePasswordApiUrl(), {
            oldPassword: currentPassword,
            newPassword: newPassword
        })
        .then((res) => Promise.resolve(res))
        .catch((err) => Promise.reject(err));

import { Grid, IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import { darkPrimaryColor, primaryDarkColor } from "../../../../../../constants/ColorConstant";
import { FreeTextAnswerTypeOptions } from "../../../../../../constants/DropdownOption";
import { CaptionText, TextButton } from "../../../../../CommonComponent/v2/CommonStyle";
import FormikRadioGroup from "../../../../../CommonComponent/v2/fields/FormikRadioGroup";
import FormikTextInput from "../../../../../CommonComponent/v2/fields/FormikTextInput";
import { QuestionDetailType } from "../Type";
import DeleteIcon from "../../../../../Images/icons/delete.svg";

const FreeTextQuestion = () => {
    const { values, setFieldValue } = useFormikContext();
    const data = values as QuestionDetailType;

    const renderAnswerField = () => {
        if (
            data.answerDetails.freeText.correctionCriteriaType === "contains" ||
            data.answerDetails.freeText.correctionCriteriaType === "does_not_contain"
        ) {
            return (
                <Grid mt={"10px"}>
                    <CaptionText fontWeight={700} color={primaryDarkColor}>
                        {data.answerDetails.freeText.correctionCriteriaType === "contains"
                            ? "Contain following phrase(s)"
                            : "Doesn't Contain following phrase(s)"}
                    </CaptionText>
                    {data.answerDetails.freeText.freeTextAnswerFields.map((freeText, index) => (
                        <Grid container mt={"10px"} alignItems={"center"}>
                            <Grid item xs={11}>
                                <FormikTextInput
                                    fullWidth
                                    name={`answerDetails.freeText.freeTextAnswerFields[${index}].text`}
                                    id={`answerDetails.freeText.freeTextAnswerFields[${index}].text`}
                                    label={"Type a phrase"}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                {index !== 0 && (
                                    <IconButton
                                        onClick={() => {
                                            setFieldValue(
                                                "answerDetails.freeText.freeTextAnswerFields",
                                                data.answerDetails.freeText.freeTextAnswerFields.filter(
                                                    (item) => item.id !== freeText.id
                                                )
                                            );
                                        }}>
                                        <img src={DeleteIcon} style={{ height: 18, cursor: "pointer" }} />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container mt={"20px"} justifyContent={"center"}>
                        <TextButton
                            onClick={() =>
                                setFieldValue("answerDetails.freeText.freeTextAnswerFields", [
                                    ...data.answerDetails.freeText.freeTextAnswerFields,
                                    {
                                        id: Math.random(),
                                        text: ""
                                    }
                                ])
                            }>
                            + Add new phrase
                        </TextButton>
                    </Grid>
                </Grid>
            );
        }
        if (data.answerDetails.freeText.correctionCriteriaType === "exact_match") {
            return (
                <Grid mt={"10px"}>
                    <CaptionText color={darkPrimaryColor} fontWeight={700} mb={"10px"}>
                        Exactly matches the following
                    </CaptionText>
                    <FormikTextInput
                        placeholder="Type answer..."
                        rows={3}
                        fullWidth
                        multiline
                        name={`answerDetails.freeText.freeTextAnswerFields[0].text`}
                        id={`answerDetails.freeText.freeTextAnswerFields[0].text`}
                        label={"Type answer..."}
                    />
                </Grid>
            );
        }
        return <></>;
    };

    return (
        <Grid container width={"570px"}>
            <Grid item xs={12}>
                <CaptionText color={darkPrimaryColor} fontWeight={700}>
                    Text Match
                </CaptionText>
                <FormikRadioGroup
                    row
                    labelfontweight={400}
                    radioOptions={FreeTextAnswerTypeOptions}
                    name={"answerDetails.freeText.correctionCriteriaType"}
                />
            </Grid>
            <Grid item xs={12}>
                {renderAnswerField()}
            </Grid>
        </Grid>
    );
};

export default FreeTextQuestion;

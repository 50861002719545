export const getMenuName = (isSuperAdmin: boolean): Record<string, string> => ({
    questionbank: "Question library",
    referencelibrary: "Reference library",
    referencelistmanager: "Reference games library",
    quiz: "Quiz",
    eventmanager: "Events",
    leaderboard: "Leaderboard",
    users: isSuperAdmin ? "Clients" : "Users",
    files: "Event Media",
    roles: "Roles",
    password: "Change Password",
    jambar: "Jambar",
    event: "Events",
    dashboard: "Dashboard",
    questionlibrary: "Challenges",
    gamelibrary: "Games",
    paymentlist: "Payment List",
    subscription: "Payment"
});

import Styled from "@emotion/styled";
import { TextField, FormControl, Grid } from "@mui/material";

type StyledTextFieldProps = {
    textColor?: string;
};
export const StyledTextField = Styled(TextField)<StyledTextFieldProps>`
    & .MuiOutlinedInput-root {
        background: #FCFCFC;
        border-radius: 10px;
        color: ${(props) => props.textColor || "black"}
    }

    & .Mui-error {
        font-size: 1rem;
    }
`;

export const StyledPasswordTextField = Styled(FormControl)`
    & .MuiOutlinedInput-root {
        background: #FCFCFC;
        border-radius: 10px;
    }
`;

export const StyledSelectField = Styled(FormControl)`
    & .MuiOutlinedInput-root {
        background: #FCFCFC;
        border-radius: 10px;
    }
`;

type LogoContainerProps = {
    variant: "primary" | "secondary" | "tertiary";
};
export const LogoContainer = Styled.div<LogoContainerProps>`
    background: #FCFCFC;
    margin-top: 10px;
    ${(props) =>
        props.variant === "primary" &&
        `
        height: 145px;
        width: 145px;
        border-radius: 100px;
    `};
    ${(props) =>
        props.variant === "secondary" &&
        `
        height: 170px;
        width: 302px;
        border-radius: 10px;
    `};
    ${(props) =>
        props.variant === "tertiary" &&
        `
        height: 145px;
        width: 145px;
        border-radius: 10px;
    `};
`;

export const FileUploadContainer = Styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 20px;
    cursor: pointer;
    padding: 20px;
    flex-direction: column;
`;

export const DateContainer = Styled(Grid)`

.DateRangePicker {
    width: 100%;
}
.DateRangePickerInput {
width: 100%;
 display: flex;
 gap: 20px;
}
.DateInput {
    width: 100%;
}
.DateInput_input {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 9px;
}

.DateRangePickerInput_arrow {
    display: none;
}
`;

export const StyleSingleDatePickerTextfield = Styled(TextField)`
& .MuiOutlinedInput-root {
        background: #FCFCFC;
        border-radius: 10px;
    }
`;

type StyledRadioGroupContainerProps = {
    labelfontweight?: number;
};
export const StyledRadioGroupContainer = Styled.div<StyledRadioGroupContainerProps>`
    & .MuiTypography-root {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: ${(props) => props.labelfontweight || 700};
        font-size: 12px;
        line-height: 18px;
        color: #333333;  
    }
`;

type StyledTextEditorContainerProps = {
    iserror?: boolean;
};

export const StyledTextEditorContainer = Styled.div<StyledTextEditorContainerProps>`

 & .ql-toolbar {
    border-radius: 0px 0px 10px 10px;
    position: absolute;
    ${(props) => props.iserror && `border: 1px solid #d32f2f;`}
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
 }
 & .ql-container {
    height: 180px;
    border: 0px;
 }
 & .ql-editor {
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #ccc;
    ${(props) => props.iserror && `border: 1px solid #d32f2f;`}
    border-bottom: 0px;
    background-color:#F2F2F2;
 }

 & .ql-editing {
    left: 0px !important;
 }
`;

export const EditorWrapper = Styled.div`
    position: relative;
`;

export const EditorContainer = Styled.div`
    height: 180px;
`;

type ColorCheckerProps = {
    backgroundcolor?: string;
};
export const ColorChecker = Styled.div<ColorCheckerProps>`
    background: ${(props) => props.backgroundcolor || "#ffffff"};
    border-radius: 10px;
    height: 100%;
    width: 50px
`;

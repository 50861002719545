import Styled from "@emotion/styled";
import { ListItemButton, ListItemText, Typography, Accordion } from "@mui/material";

type MenuTextProps = {
    isactive: number;
    textweight?: string;
    textsize?: string;
};
export const MenuText = Styled(ListItemText)<MenuTextProps>`
span {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: ${(props) => props.textweight || 700} !important;
    font-size: ${(props) => props.textsize || "16px"} !important;
    line-height: 18px !important;
    color: ${(props) => (props.isactive ? "#1F71B6" : "#828282")} !important;
}  
`;

type MenuItemButtonProps = {
    isactive: number;
    mt?: string;
    hoverBg?: string;
    borderRadius?: string;
};

export const MenuItemButton = Styled(ListItemButton)<MenuItemButtonProps>`
    background-color: ${(props) => (props.isactive ? "#DDE5EC" : "inherit")} !important;
    border-radius: ${(props) => props.borderRadius || "10px"} !important;
    margin-top:${(props) => props.mt || "10px"}  !important;
    width: 100% !important;
    &:hover{
        background-color: ${(props) => props.hoverBg || "#DDE5EC"}  !important;
    }
    svg {
        color: ${(props) => (props.isactive ? "#1F71B6" : "#828282")} !important;
    }
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    padding: 8px 16px !important;
    
`;

export const HeaderText = Styled(Typography)`
    text-align: center;
    font: normal normal normal 20px/27px Nunito;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
`;

export const InfoText = Styled(Typography)`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    color: #1F71B6;
`;

export const NotificationContainer = Styled.div`
    background: #FCFCFC;
    border: 1px solid #C2C2C2;
    border-radius: 10px;
    padding: 12px;
`;

export const AppVersionText = Styled(Typography)`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #828282;
`;

export const AccordianMenuText = Styled(Typography)`
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 18px !important;
        color: #828282 !important;  
    display: flex !important;
    gap: 11px !important;
`;

export const StyledAccordian = Styled(Accordion)`
    background: inherit;
`;

import { CircularProgress, Grid } from "@mui/material";
import { FC } from "react";
import NoItemImage from "../../Images/icons/noItem.svg";
import { CaptionText } from "./CommonStyle";

type LoadingWrapperProps = {
    isLoading?: boolean;
    isNoItem?: boolean;
    emptyTileLabel?: string;
    emptyTileSubLabel?: string;
    children: React.ReactNode;
    loadingHeight?: string;
};
const LoadingWrapper: FC<LoadingWrapperProps> = ({
    isLoading,
    isNoItem,
    emptyTileLabel,
    emptyTileSubLabel,
    children,
    loadingHeight
}) => {
    if (isLoading) {
        return (
            <Grid height={loadingHeight || "500px"} container justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Grid>
        );
    }

    if (isNoItem) {
        return (
            <Grid height={loadingHeight || "500px"} container justifyContent={"center"} alignItems={"center"}>
                <Grid item textAlign={"center"} xs={5}>
                    <img src={NoItemImage} alt={"No item"} />
                    <CaptionText mt={"15px"} textAlign={"center"}>
                        {emptyTileLabel || "Not found anything"}
                    </CaptionText>
                    {emptyTileSubLabel && (
                        <CaptionText mt={"10px"} textAlign={"center"} fontWeight={"bold"} color={"#1F71B6"}>
                            {emptyTileSubLabel}
                        </CaptionText>
                    )}
                </Grid>
            </Grid>
        );
    }
    return <>{children}</>;
};

export default LoadingWrapper;

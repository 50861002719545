export const SherlockQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "Oh dear, your score is quite poor.",
            imageSrc: "https://i.imgur.com/uI50FK4.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Not bad, you know a bit about Homes and Watson.",
            imageSrc: "https://i.imgur.com/sj4yoq0.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Great! You must be a detective yourself!",
            imageSrc: "https://i.imgur.com/14899LJ.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "Who played the role of Watson in the film series?",
            questionImageSrc: "https://i.imgur.com/WaNfZnw.jpg",
            answerDescription:
                "Did you know that this character first appeared in the book A Study in Scarlet? It was also the first time his partner, Sherlock Holmes, was introduced.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Robert Downey Jr",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Jude Law",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Anthony Hopkins",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Benedict Cumberbatch",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "What is Sherlock Holmes’ address?",
            questionImageSrc: "https://i.imgur.com/5WhQuKF.jpg",
            answerDescription: "Did you know that there is a Sherlock Holmes Museum on Baker Street in London?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Windsor SL4 1NJ",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 10 Downing Street",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 221B Baker Street",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. 22 Jump Street",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Which actress played Irene Adler in Sherlock Holmes?",
            questionImageSrc: "https://i.imgur.com/GElwdLu.jpg",
            answerDescription: "Rachel McAdams is perhaps best known for her role in the famous rom-com The Notebook.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Rachel McAdams",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Keira Knightley",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Helen Mirren",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Naomi Watts",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "What character does this actor play?",
            questionImageSrc: "https://i.imgur.com/xNnzYt9.jpg",
            answerDescription: "This actor, Mark Strong, also plays the character Sinestro in the film Green Lantern.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Dredger",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Inspector Lestrade",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Sherlock Holmes",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Lord Blackwood",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "On which British landmark did Lord Blackwood die?",
            questionImageSrc: "https://i.imgur.com/WrMxir4.jpg",
            answerDescription: "In this movie, the bridge is still under construction.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Tower Bridge",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Brighton Palace Pier",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Windsor Castle",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Stonehenge",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "Who does this actor play in A Game of Shadows?  ________.",
            questionImageSrc: "https://i.imgur.com/HS1akwq.jpg",
            answerDescription: "Did you know that Jared Harris played the role of Lane Pryce in Mad Men?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. James Moriarty",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Inspector Lestrade",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Manouche",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Watson",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "Sherlock Holmes was the ____ highest-grossing film of 2009 worldwide.",
            questionImageSrc: "https://i.imgur.com/cJNHmfU.jpg",
            answerDescription:
                "The highest-grossing film of that year was Avatar, which made more than $2.8 billion worldwide.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. second",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. tenth",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. eighth",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. fifth",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "What is the name of Sherlock’s brother?",
            questionImageSrc: "https://i.imgur.com/SUwIHOv.jpg",
            answerDescription: "Mycroft works for the government and actually has impressive detective skills.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Miguel",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Marty",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Mycroft",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Mario",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "In 'A Game of Shadows', what was Moriarty’s plan?",
            questionImageSrc: "https://i.imgur.com/5NvTACp.jpg",
            answerDescription:
                "Professor Moriarty planned to profit from his investments in companies that would have been crucial in manufacturing goods for the war.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. to kill the king of France",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. to start a war",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. to become the most powerful sorcerer",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. to steal the crown jewels",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "Who composed the music used in A Game of Shadows?",
            questionImageSrc: "https://i.imgur.com/mPUBw2a.jpg",
            answerDescription:
                "The soundtrack album featured 18 tracks, a couple of which were written by other composers.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Tim Burton",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Hans Zimmer",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Alfred Hitchcock",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Guy Ritchie",
                    isCorrect: false
                }
            ]
        }
    ]
};

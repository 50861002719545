import { FC, useCallback, useEffect, useState } from "react";
import { useDropzone, DropzoneProps } from "react-dropzone";
import { FileUploadContainer, LogoContainer } from "./Style";
import AddIcon from "../../../Images/icons/addfile.svg";
import { CaptionText } from "../CommonStyle";
import CropImageModal from "../../CropImage/CropImageModal";
import { getCroppedImg, readFile } from "../../CropImage/Util";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig } from "../../../../utils/JambarUtils";
import { VALID_IMAGE } from "../../../../constants/SiteConstant";
import { useField } from "formik";
import { CaptionTextColor, dangerColor } from "../../../../constants/ColorConstant";
import DeleteIcon from "../../../Images/icons/delete.svg";
import { Grid, IconButton } from "@mui/material";
import { whiteColor } from "../../../../constants/Styles";
import { FileInfoType } from "./Type";

type ImageDropProps = {
    logoInfo?: string;
    selectedFile?: File | null | string;
    imageSize?: number;
    fileInfo?: FileInfoType;
    setFileInfo?: (fileInfo: FileInfoType) => void;
    name: string;
    variant?: "primary" | "secondary" | "tertiary";
    removeImage?: () => void;
} & DropzoneProps;

const ImageDrop: FC<ImageDropProps> = ({
    logoInfo,
    selectedFile,
    imageSize,
    name,
    fileInfo,
    setFileInfo,
    variant = "primary",
    removeImage
}) => {
    // eslint-disable-next-line no-empty-pattern
    const [_, { error }, { setError, setValue }] = useField(name);

    const [selectedImage, setSelectedImage] = useState<string>("");
    const [imgSrc, setImgSrc] = useState<string>("");

    useEffect(() => {
        if (typeof selectedFile === "string") {
            setImgSrc(selectedFile);
            return;
        }
        selectedFile &&
            readFile(selectedFile).then((res) => {
                setImgSrc(res);
            });
    }, [selectedFile]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles[0].id = Math.random();
            const file = acceptedFiles[0];
            if (setError && !checkIfFilesAreCorrectType(acceptedFiles, VALID_IMAGE)) {
                setError("Please upload image only");
                return;
            }

            if (imageSize && !checkIfFilesAreTooBig(acceptedFiles, imageSize)) {
                setError(`Image must be less then ${imageSize} MB`);
                return;
            }
            if (["secondary", "tertiary"].includes(variant)) {
                setValue(file);
                setFileInfo && setFileInfo({
                    fileName: file.name,
                    fileSize: (file.size / 1024 / 1024).toFixed(2)
                });
            }
            readFile(file).then((res) => {
                setSelectedImage(res);
            });
        },
        [imageSize, setError]
    );

    const showCroppedImage = useCallback(
        async (croppedAreaPixels) => {
            getCroppedImg(selectedImage, croppedAreaPixels).then((croppedImage) => {
                croppedImage && setValue([croppedImage.file]);
                setSelectedImage("");
            });
        },
        [selectedImage]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div>
            {variant === "primary" && selectedImage && (
                <CropImageModal
                    storeImage={showCroppedImage}
                    imgSrc={selectedImage}
                    handleClose={() => setSelectedImage("")}
                />
            )}
            {removeImage && imgSrc && (
                <IconButton
                    style={{
                        position: "absolute",
                        marginLeft: 256,
                        marginTop: 45,
                        backgroundColor: whiteColor
                    }}
                    onClick={() => {
                        setValue(null);
                        setImgSrc("");
                        removeImage();
                    }}
                >
                    <img src={DeleteIcon} alt={"deleteIcon"} />
                </IconButton>
            )}
            <Grid container spacing={"30px"} alignItems={"center"}>
                <Grid item>
                    {isDragActive ? (
                        <LogoContainer variant={variant} {...getRootProps({ className: "dropzone height-100per" })}>
                            <input id={"fileUpload"} {...getInputProps()} />
                            <FileUploadContainer style={{ padding: imgSrc ? 0 : 20 }}>
                                {imgSrc ? (
                                    <img
                                        src={imgSrc}
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: variant === "primary" ? "50%" : "10px"
                                        }}
                                    />
                                ) : (
                                    <>
                                        <img src={AddIcon} style={{ height: 28 }} />
                                        <CaptionText color={CaptionTextColor} textAlign={"center"}>
                                            {logoInfo}
                                        </CaptionText>
                                    </>
                                )}
                            </FileUploadContainer>
                        </LogoContainer>
                    ) : (
                        <LogoContainer variant={variant} {...getRootProps({ className: "dropzone height-100per" })}>
                            <input id={"fileUpload"} {...getInputProps()} />
                            <FileUploadContainer style={{ padding: imgSrc ? 0 : 20 }}>
                                {imgSrc ? (
                                    <img
                                        src={imgSrc}
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "contain",
                                            borderRadius: variant === "primary" ? "50%" : "10px"
                                        }}
                                    />
                                ) : (
                                    <>
                                        <img src={AddIcon} style={{ height: 28 }} />
                                        <CaptionText color={CaptionTextColor} textAlign={"center"}>
                                            {logoInfo}
                                        </CaptionText>
                                    </>
                                )}
                            </FileUploadContainer>
                        </LogoContainer>
                    )}
                    {error && <CaptionText style={{position: 'absolute'}} color={dangerColor}>{error}</CaptionText>}
                </Grid>
                {
                    fileInfo?.fileName && <Grid item>
                        <CaptionText>
                            File name : {fileInfo?.fileName}
                        </CaptionText>
                        <CaptionText>
                            File size: {fileInfo?.fileSize} MB
                        </CaptionText>
                    </Grid>
                }
            </Grid>
        </div>
    );
};

export default ImageDrop;

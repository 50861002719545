import React, { useState } from "react";
import {
    CenterContainer,
    BackgroundContainer,
    CrownRectangle,
    PositionText,
    AvatarContainer,
    PosAvatarBox,
    RunnerUpLogo,
    ScoreBox,
    PlayerDetail,
    LogoContainer,
    PlayerLogo,
    NameText,
    ScoreText,
    ScoreBoxContainer,
    WinnerLogo
} from "./Style";
import Crown from "../../../Images/Wildgoat/crown.svg";
import AonLogo from "../../../Images/Wildgoat/aonLogo.png";
import HpLogo from "../../../Images/Wildgoat/hpLogo.png";
import MicrosoftLogo from "../../../Images/Wildgoat/microsoftLogo.png";
import MotorolaLogo from "../../../Images/Wildgoat/motorolaLogo.png";
import { getOffsetColor } from "../Util/Util";
import { linkColor } from "../../../../constants/Styles";

const WildGoatLeaderboard = () => {
    const [backgroundColor] = useState<string>(linkColor);

    return (
        <CenterContainer>
            <BackgroundContainer bgcolor={backgroundColor} bgcolor_offset={getOffsetColor(backgroundColor)}>
                <div>
                    <CenterContainer>
                        <CrownRectangle>
                            <div>
                                <img src={Crown} alt="crown" width={40} height={20} />
                            </div>
                            <div>
                                <PositionText>
                                    1<sup>st</sup>
                                </PositionText>
                            </div>
                        </CrownRectangle>
                    </CenterContainer>
                    <CenterContainer>
                        <AvatarContainer>
                            <PosAvatarBox>
                                <PositionText>
                                    2<sup>nd</sup>
                                </PositionText>
                                <RunnerUpLogo src={AonLogo} alt="position-2" />
                            </PosAvatarBox>
                            <PosAvatarBox>
                                <WinnerLogo src={HpLogo} alt="position-1" />
                            </PosAvatarBox>
                            <PosAvatarBox>
                                <PositionText>
                                    3<sup>rd</sup>
                                </PositionText>
                                <RunnerUpLogo src={MicrosoftLogo} alt="position-3" />
                            </PosAvatarBox>
                        </AvatarContainer>
                    </CenterContainer>
                </div>
            </BackgroundContainer>
            <ScoreBoxContainer>
                <ScoreBox>
                    <PlayerDetail>
                        <PositionText>
                            4<sup>th</sup>
                        </PositionText>
                        <LogoContainer>
                            <PlayerLogo src={AonLogo} alt="logo" />
                        </LogoContainer>
                        <NameText>william_uk42</NameText>
                    </PlayerDetail>
                    <div>
                        <ScoreText>9223</ScoreText>
                    </div>
                </ScoreBox>
                <ScoreBox>
                    <PlayerDetail>
                        <PositionText>
                            5<sup>th</sup>
                        </PositionText>
                        <LogoContainer>
                            <PlayerLogo src={MotorolaLogo} alt="logo" />
                        </LogoContainer>
                        <NameText>olvie_ks</NameText>
                    </PlayerDetail>
                    <div>
                        <ScoreText>8123</ScoreText>
                    </div>
                </ScoreBox>
            </ScoreBoxContainer>
        </CenterContainer>
    );
};

export default WildGoatLeaderboard;

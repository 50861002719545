import Style from "@emotion/styled";
import { scoreBoxColor } from "../../../../constants/Styles";
import { Icon } from "@fluentui/react";

type PhotoAreaProps = {
    height?: string;
};
export const PhotoArea = Style.div`
width:100%;
height: ${(props: PhotoAreaProps) => props.height || "25vh"};
border-radius: 35px;
background-color: ${scoreBoxColor};
margin-top: 10px;
`;

export const CloseIcon = Style(Icon)`
    margin-left: 10px;
    position: absolute;
    right: 30px;
    cursor: pointer;
    font-size: 14px;
    color: white;
    padding: 15px;
    background: black;
    border-radius: 50%;
    margin-bottom: 114px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

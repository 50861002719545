import React from "react";
import { FC } from "react";
import { MCQAnswerOption } from "../../../data/question/types";
import CorrectAnswer from "./CorrectAnswer/CorrectAnswer";
import Loader from "./Loader";
import QuestionTimeout from "./QuestionTimeout/QuestionTimeout";
import QuestionView from "./QuestionTypeView/QuestionView";
import SkipAnswerView from "./SkipAnswer/SkipAnswerView";
import WildgoatTrigger from "./WildgoatTrigger";
import { WildgoatConfigType, WildgoatTriggerQuestionType } from "./WildgoatType";
import WrongAnswer from "./WrongAnswer/WrongAnswer";

interface WildgoatGameQuesionViewProps {
    mcqOptions: MCQAnswerOption[];
    questionTextone: string;
    questionTexttwo: string;
    questionTime: string;
    setQuestionTiming: (time: string) => void;
    backgroundColor: string;
    teamPoints: number;
    correctAnswerPoints: number;
    participantAnswer: string;
    mcqColor: string;
    updateParticipantAnswer: (answer: string) => void;
    saveProgress: ({ isSkipped, submittedAnswer }: { isSkipped?: boolean; submittedAnswer?: string }) => void;
    answerResult: string;
    type: string;
    wrongAnswerPoints: number;
    wrongAnswerText: string;
    correctAnswerText: string;
    questionCoverImage: string;
    hideTimer: boolean;
    loading: boolean;
    questionTitle: string;
    uploadFile: (file: File[]) => void;
    uploadedPercentage: number;
    isFileUploadFailed: boolean;
    isQuestionNonScoring: string;
    isSkippingAllowed: boolean;
    timeoutText: string;
    isFileUploading: boolean;
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    userName: string;
    teamName: string;
    videoLocation?: string;
    youtubeVideoLink?: string;
    totalPointsText?: string;
    quizConfig: WildgoatConfigType;
    triggers: WildgoatTriggerQuestionType[];
    setIsReachedPosition: (isReached: boolean) => void;
    isPositionReached: boolean;
    isForceCorrect: boolean;
}

const WildgoatGameQuesionView: FC<WildgoatGameQuesionViewProps> = ({
    mcqOptions,
    questionTextone,
    questionTexttwo,
    questionTime,
    setQuestionTiming,
    backgroundColor,
    teamPoints,
    correctAnswerPoints,
    participantAnswer,
    mcqColor,
    updateParticipantAnswer,
    saveProgress,
    answerResult,
    type,
    wrongAnswerPoints,
    wrongAnswerText,
    correctAnswerText,
    questionCoverImage,
    hideTimer,
    loading,
    questionTitle,
    uploadFile,
    uploadedPercentage,
    isFileUploadFailed,
    isQuestionNonScoring,
    isSkippingAllowed,
    timeoutText,
    isFileUploading,
    primaryColor,
    secondaryColor,
    tertiaryColor,
    userName,
    teamName,
    videoLocation,
    youtubeVideoLink,
    totalPointsText,
    quizConfig,
    triggers,
    setIsReachedPosition,
    isPositionReached,
    isForceCorrect
}) => {
    const {
        wildgoatCorrectAnswerHeading,
        wildgoatCorrectAnswerImage,
        wildgoatTimeoutAnswerImage,
        wildgoatTimeoutHeading,
        wildgoatWrongAnswerHeading,
        wildgoatWrongAnswerImage,
        totalScoreVisibility
    } = quizConfig;

    if (answerResult === "skip") {
        return <SkipAnswerView />;
    }
    if (answerResult === "timeout") {
        return (
            <QuestionTimeout
                timeoutAnswerHeading={wildgoatTimeoutHeading}
                timeoutAnswerImage={wildgoatTimeoutAnswerImage}
                timeoutText={timeoutText}
            />
        );
    }
    if (answerResult === "correct") {
        return (
            <CorrectAnswer
                secondaryColor={primaryColor}
                correctAnswerPoints={isQuestionNonScoring ? 0 : correctAnswerPoints}
                correctAnswerText={correctAnswerText}
                correctAnswerHeading={wildgoatCorrectAnswerHeading}
                correctAnswerImage={wildgoatCorrectAnswerImage}
            />
        );
    }

    if (answerResult === "wrong") {
        return (
            <WrongAnswer
                wrongAnswerPoints={isQuestionNonScoring ? 0 : wrongAnswerPoints}
                wrongAnswerText={wrongAnswerText}
                wrongAnswerHeading={wildgoatWrongAnswerHeading}
                wrongAnswerImage={wildgoatWrongAnswerImage}
                isForceCorrect={isForceCorrect}
            />
        );
    }

    if (loading) {
        return <Loader />;
    }

    if (triggers.length > 0 && !isPositionReached) {
        return <WildgoatTrigger setIsReachedPosition={setIsReachedPosition} trigger={triggers[0]} />;
    }
    return (
        <QuestionView
            saveProgress={saveProgress}
            backgroundColor={backgroundColor}
            videoLocation={videoLocation}
            youtubeVideoLink={youtubeVideoLink}
            mcqOptions={mcqOptions}
            questionTextone={questionTextone}
            questionTexttwo={questionTexttwo}
            totalPointsText={totalPointsText}
            totalScoreVisibility={totalScoreVisibility}
            questionTime={questionTime}
            setQuestionTiming={setQuestionTiming}
            isSkipAllowed={isSkippingAllowed}
            teamPoints={teamPoints}
            correctAnswerPoints={correctAnswerPoints}
            mcqColor={mcqColor}
            questionTitle={questionTitle}
            participantAnswer={participantAnswer}
            updateParticipantAnswer={(answer: string) => {
                updateParticipantAnswer(answer);
            }}
            questionCoverImage={questionCoverImage}
            type={type}
            hideTimer={hideTimer}
            uploadFile={uploadFile}
            uploadedPercentage={uploadedPercentage}
            isFileUploadFailed={isFileUploadFailed}
            isQuestionNonScoring={isQuestionNonScoring}
            isFileUploading={isFileUploading}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            tertiaryColor={tertiaryColor}
            userName={userName}
            teamName={teamName}
        />
    );
};

export default WildgoatGameQuesionView;

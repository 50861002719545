import { useMemo } from "react";
import { BrowserCheckLogos } from "./BrowserCheckStyle";
import { browsersList } from "./data";

export const useShowBrowserList = () =>
    useMemo(() => {
        return browsersList.map((browser) => (
            <BrowserCheckLogos
                onClick={() => window.open(browser.downloadUrl, "_blank")}
                sm={4}
                xs={6}
                key={browser.id}>
                <img src={browser.imgUrl} height={100} />
                <span>Download {browser.title}</span>
            </BrowserCheckLogos>
        ));
    }, []);

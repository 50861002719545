import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { addNewAttachment, getAttachmentDetails, updateAttachmentDetails } from "./EditAttachmentUtils";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../../../utils/APIErrorMessages";
import { AttachmentType } from "./AttachmentTypes";

export const useAddAttachment = () => {
    const history = useHistory();
    const [attachmentDetails, setAttachmentDetails] = useState<AttachmentType | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const { id, attachmentId } = useParams<{
        id: string;
        attachmentId: string;
    }>();
    const Id: number = parseInt(id);

    useEffect(() => {
        if (attachmentId && Id) {
            setIsLoading(true);
            getAttachmentDetails(Id, parseInt(attachmentId))
                .then((res) => {
                    setAttachmentDetails(res);
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => setIsLoading(false));
        }
    }, []);

    const saveData = (values: AttachmentType, setIsSubmitting: (value: boolean) => void) => {
        setIsSubmitting(true);
        if (attachmentId) {
            updateAttachmentDetails(Id, parseInt(attachmentId), values)
                .then(() => {
                    toast.success("Attachment updated", SiteConstants.successToastConfiguration);
                    history.push(`/admin/v2/referencelistmanager/${id}/update`);
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => setIsSubmitting(false));
            return;
        }
        addNewAttachment(Id, values)
            .then(() => {
                toast.success("Attachment added", SiteConstants.successToastConfiguration);
                history.push(`/admin/v2/referencelistmanager/${id}/update`);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => setIsSubmitting(false));
    };
    return {
        attachmentDetails,
        setAttachmentDetails,
        saveData,
        isLoading,
        id,
        attachmentId
    };
};

import { Grid } from "@mui/material";
import { Formik } from "formik";
import { Header1, PrimaryButton } from "../../../CommonComponent/v2/CommonStyle";
import TabWrapper from "../../../CommonComponent/v2/tab/Tab";
import ProfileTab from "./ProfileTab";
import { validationSchema, userDetailInitialValues, tabsOptions } from "./Util";
import { useProfile } from "./Hooks";

const ProfileSettings = (): JSX.Element => {

    const {
        selectedTab,
        setSelectedTab,
        saveData
    } = useProfile();

    const renderTab = () => {
        if (selectedTab == "profile") {
            return (<ProfileTab />);
        }
        return (<div></div>);
    };

    return (
        <Grid container spacing={"20px"} padding={"20px"}>
            <Grid item xs={12}>
                <Header1>
                    Profile & settings
                </Header1>
            </Grid>
            <Grid item xs={12} mt={"20px"} >

                <TabWrapper
                    tabsOptions={tabsOptions}
                    handleChange={setSelectedTab}
                    selectedTab={selectedTab}
                />
            </Grid>
            <Formik
                initialValues={userDetailInitialValues()}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    saveData(values, setSubmitting);
                }}
                enableReinitialize
                validateOnMount>
                {({ handleSubmit }) => (
                    <Grid item xs={6}>
                        {renderTab()}
                        <Grid container justifyContent={"center"} mt={"40px"}>
                            <PrimaryButton
                                onClick={() => handleSubmit()}>
                                Update
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                )}
            </Formik>

        </Grid>
    );
};
export default ProfileSettings;
import { Grid } from "@mui/material";
import { FC } from "react";
import Select from "react-select";
import { CaptionText, Header1, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import { darkColor } from "../../../../WebsiteGame/colorConstant";
import { useUserRoleHook } from "./Hooks";
import { UserRoleCard, UserRoleContainer } from "./Style";
import { addRolesToUserList, filterSelectedRoleDropdown } from "./Util";
import DeleteIcon from "../../../../Images/icons/delete.svg";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { newSelectStyle } from "../../../EventManager/EventDetails/utils/helperFunctions";

type UserRoleProps = {
    userId: number;
};
const UserRole: FC<UserRoleProps> = ({ userId }) => {
    const { userRolesDropdown, selectedRoles, handleOptionChange, permissionRoles, userInfo } = useUserRoleHook(userId);

    const saveRoles = () => {
        userId &&
            addRolesToUserList(userId, selectedRoles)
                .then(() => {
                    toast.success("Roles has been assigned", SiteConstants.successToastConfiguration);
                })
                .catch((err) => {
                    toast.error(err, SiteConstants.deleteToastConfiguration);
                });
    };

    return (
        <Grid mt={"30px"}>
            <Header1>Assign role(s)</Header1>
            <CaptionText>
                Preview the added user from here and add one or more user roles for the dashboard.
            </CaptionText>
            <Grid container spacing={"20px"}>
                <Grid item xs={10} mt={"20px"}>
                    <Grid container px={"20px"} py={"5px"}>
                        <Grid item xs={6}>
                            <CaptionText fontWeight={"bold"}>Email Address</CaptionText>
                        </Grid>
                        <Grid item xs={6}>
                            <CaptionText fontWeight={"bold"}>Name</CaptionText>
                        </Grid>
                    </Grid>
                    <UserRoleContainer container>
                        <Grid item xs={6}>
                            <CaptionText color={darkColor}>{userInfo?.email}</CaptionText>
                        </Grid>
                        <Grid item xs={6}>
                            <CaptionText color={darkColor}>
                                {userInfo?.firstName} {userInfo?.lastName}
                            </CaptionText>
                        </Grid>
                    </UserRoleContainer>
                </Grid>
                {permissionRoles?.includes("fetch") && (
                    <Grid item xs={10}>
                        <CaptionText mb={"5px"} fontWeight={"bold"}>
                            Select a user role
                        </CaptionText>
                        <Select
                            options={filterSelectedRoleDropdown(userRolesDropdown, selectedRoles)}
                            placeholder="Select a user role"
                            isClearable
                            styles={newSelectStyle()}
                            onChange={(value) => {
                                value && handleOptionChange(value);
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={10}>
                    <CaptionText mb={"5px"} fontWeight={"bold"}>
                        Add user roles
                    </CaptionText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container gap={"20px"}>
                        {selectedRoles &&
                            selectedRoles.map((role) => (
                                <UserRoleCard item key={role.value}>
                                    <CaptionText color={"#1F71B6"} fontWeight={"bold"}>
                                        {role.label}
                                    </CaptionText>
                                    <img
                                        onClick={() => handleOptionChange(role)}
                                        src={DeleteIcon}
                                        alt={"delete"}
                                        style={{ height: 18 }}
                                    />
                                </UserRoleCard>
                            ))}
                    </Grid>
                </Grid>
                <Grid container mt={"10px"} alignItems={"center"} spacing={"30px"} justifyContent={"center"}>
                    <Grid item>
                        <PrimaryButton fontsize="16px" onClick={saveRoles} variant={"contained"}>
                            Save Roles
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserRole;

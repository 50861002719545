import { Grid } from "@mui/material";
import DataGridWrapper from "../../../../../CommonComponent/v2/datagrid/DataGrid";
import LoadingWrapper from "../../../../../CommonComponent/v2/LoadingWrapper";
import SearchHeader from "../../../../../CommonComponent/v2/searchheader/SearchHeader";
import { useParticpantEmailHook } from "./Hooks";
import { getParticipantEmailListFilteredItems } from "./Util";

const ParticpantEmail = () => {
    const { isLoading, participantEmail, useColumns, searchText, setSearchText } = useParticpantEmailHook();

    return (
        <Grid>
            <Grid mb={"20px"} container>
                <Grid item xs={12}>
                    <SearchHeader
                        searchText={searchText}
                        setSearchText={setSearchText}
                        searchPlaceholder={"Search participant email"}
                        buttonLabel={"Add new Code"}
                    />
                </Grid>
            </Grid>
            <LoadingWrapper loadingHeight="300px" isLoading={isLoading} isNoItem={participantEmail.length === 0}>
                <Grid height={"calc(100vh - 390px)"}>
                    <DataGridWrapper
                        columns={useColumns}
                        rows={getParticipantEmailListFilteredItems(participantEmail, searchText)}
                        loading={isLoading}
                    />
                </Grid>
            </LoadingWrapper>
        </Grid>
    );
};

export default ParticpantEmail;

/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { toast } from "react-toastify";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { useIdentityHook } from "./Hooks";
import { IdentityListtype, SelectIdentityType } from "./Type";
import { getAllIdentitiesList, getIdentityListFilteredItems } from "./Util";
import UpdateCreditDrawer from "./updatecredit/UpdateCreditDrawer";

type IdentityListProps = {
    searchText: string;
    setSelectedIdentity: (identity: SelectIdentityType) => void;
    setSelectedDeleteUserId: (userId: number) => void;
};
const IdentitiesList: FC<IdentityListProps> = ({ searchText, setSelectedIdentity, setSelectedDeleteUserId }) => {
    const {
        allIdentity,
        setAllIdentity,
        loading,
        setLoading,
        IdentityListColumns,
        setUpdateUserForCredit,
        updateUserForCredit
    } = useIdentityHook();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setLoading(true);
        getAllIdentitiesList(true)
            .then((res: IdentityListtype[]) => {
                setAllIdentity(res);
                setUpdateUserForCredit(null);
            })
            .catch(() => {
                toast.error("Something went wrong");
                setAllIdentity([]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = (id: string) => {
        setSelectedDeleteUserId(parseInt(id));
    };
    const handleEdit = (identityInfo: SelectIdentityType) => {
        setSelectedIdentity(identityInfo);
    };
    return (
        <Grid height={"calc(100vh - 303px)"}>
            <DataGridWrapper
                columns={IdentityListColumns(handleDelete, handleEdit)}
                rows={getIdentityListFilteredItems(allIdentity, searchText)}
                loading={loading}
            />
            {updateUserForCredit && (
                <UpdateCreditDrawer
                    handleClose={() => setUpdateUserForCredit(null)}
                    selectedIdentity={updateUserForCredit}
                    refreshData={fetchData}
                />
            )}
        </Grid>
    );
};

export default IdentitiesList;

import React, { FC, useEffect, useState } from "react";
import { readFile } from "../../../../CommonComponent/CropImage/Util";
import { FileUploadContainer, LogoContainer } from "../../../../CommonComponent/v2/fields/Style";
import { StyledLogo } from "./Style";

type LogoRenderProps = {
    file: File | string | null;
};
const LogoRender: FC<LogoRenderProps> = ({ file }) => {
    const [imgSrc, setImgSrc] = useState<string>("");

    useEffect(() => {
        if (typeof file === "string") {
            setImgSrc(file);
            return;
        }
        file &&
            readFile(file).then((res) => {
                setImgSrc(res);
            });
    }, [file]);

    return (
        <LogoContainer variant={"primary"}>
            <FileUploadContainer style={{ padding: imgSrc ? 0 : 20 }}>
                {imgSrc && <StyledLogo src={imgSrc} alt={""} />}
            </FileUploadContainer>
        </LogoContainer>
    );
};

export default LogoRender;

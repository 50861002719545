export const LoginUtil = {
    getUser: () => {
        const userStr = localStorage.getItem("user");
        return userStr ? JSON.parse(userStr) : null;
    },
    getToken: () => {
        return localStorage?.getItem("token");
    },
    removeUserSession: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
    },
    setUserSession: (token, user) => {
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
    }
};

import { Grid } from "@mui/material";
import React from "react";
import { DataCaptionText } from "../../../CommonComponent/Style";
import { EventContainer, EventTitle } from "./Style";

const DashboardLatestNews = () => (
    <EventContainer height={"100%"}>
        <Grid container padding={"26px 22px"}>
            <Grid item xs={12}>
                <EventTitle>Latest News from Jambar</EventTitle>
            </Grid>
            <Grid item xs={12}>
                <DataCaptionText mt={"10px"}>
                    Description - Your spaceship Aurora has been severely damaged by a storm of orbit’s debris and
                    numerous areas of the spaceship have been critically damaged. Your team needs to pull together
                    all their skillsets to repair the spaceship before the time runs out and make it back to earth
                    safely.
                </DataCaptionText>
                <DataCaptionText mt={"10px"}>
                    Description - Your spaceship Aurora has been severely damaged by a storm of orbit’s debris and
                    numerous areas of the spaceship have been critically damaged. Your team needs to pull together
                    all their skillsets to repair the spaceship before the time runs out and make it back to earth
                    safely.
                </DataCaptionText>
                <DataCaptionText mt={"10px"}>
                    Description - Your spaceship Aurora has been severely damaged by a storm of orbit’s debris and
                    numerous areas of the spaceship have been critically damaged. Your team needs to pull together
                    all their skillsets to repair the spaceship before the time runs out and make it back to earth
                    safely.
                </DataCaptionText>
                <DataCaptionText mt={"10px"}>
                    Description - Your spaceship Aurora has been severely damaged by a storm of orbit’s debris and
                    numerous areas of the spaceship have been critically damaged. Your team needs to pull together
                    all their skillsets to repair the spaceship before the time runs out and make it back to earth
                    safely.
                </DataCaptionText>
            </Grid>
        </Grid>
    </EventContainer>
);

export default DashboardLatestNews;

import React from "react";

import { Dropdown, IDropdownOption } from "@fluentui/react";

type DropdownWrapperProps = {
    onChangeDropdownOption: (
        key: string,
        option?: IDropdownOption,
        event?: React.FormEvent<HTMLDivElement>,
        index?: number
    ) => void;
    disabled?: boolean;
    placeholder?: string;
    label?: string;
    options: IDropdownOption[];
    selectedKey?: string | number;
    width?: number;
    errorMessage?: string;
    className?: string;
    titleHeight?: number;
};

const DropdownWrapper: React.FC<DropdownWrapperProps> = ({
    onChangeDropdownOption,
    placeholder,
    disabled,
    label,
    options,
    selectedKey,
    width,
    errorMessage,
    titleHeight,
    className
}) => {
    const onDropdownOptionChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ): void => {
        if (option === undefined) {
            return;
        }

        onChangeDropdownOption(option.key as string, option, event, index);
    };

    return (
        <Dropdown
            onChange={onDropdownOptionChange}
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            options={options}
            selectedKey={selectedKey}
            styles={{ root: { width: width }, title: { height: titleHeight } }}
            errorMessage={errorMessage}
            className={className}
        />
    );
};

export default DropdownWrapper;

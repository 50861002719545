export const cacheImages = async (srcArray: string[]) => {
    const promises = await srcArray.map((src: string) => {
        return new Promise((resolve, reject) => {
            const img: any = new Image();
            img.src = src;
            img.onload = resolve;
            img.onerror = reject;
        });
    });
    await Promise.all(promises);
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { io } from "socket.io-client";
import { ApiConstants } from "../../../constants/ApiConstant";
import { lightPrimaryColor200 } from "../../../constants/ColorConstant";
import { FieldValidation } from "../../../constants/FieldValidation";
import { successToastConfiguration } from "../../../constants/SiteConstant";
import { getRemainingTime } from "../../../utils/TimeUtils";
import { Client } from "../../Base/Client";
import {
    QuizInfoTransformData,
    QuizUserSessionInfoTransformData,
    CurrentQuestionTransformData
} from "../RocketTemplate/RocketTemplateTransformedData";
import {
    QuizInfoInitialValues,
    QuizUserSessionInfoInitialValues,
    TimerEndInitialValues
} from "../RocketTemplate/RocketTemplateUtils/RocketTemplateInitialValues";
import {
    QuizInfoType,
    QuizUserSessionInfoType,
    TimersEndType,
    currentQuestionInfoType
} from "../RocketTemplate/RocketTemplateUtils/RocketTemplateTypes";
import { fetchQuizInfo } from "../RocketTemplate/SocketGame/api/api";
import {
    socketBooleanStatesType,
    socketBooleanStatesInitialValues
} from "../RocketTemplate/SocketGame/util/SocketInitialValues";
import {
    socketCurrentQuestionTransformData,
    socketQuizUserSessionInfoTransformData
} from "../RocketTemplate/SocketGame/util/SocketTransformData";
import { WildGoatsContainer } from "./CommonStyledComponents/CommonStyle";
import Header from "./Header/Header";
import { getOffsetColor } from "./Util/Util";
import WildgoatGameQuesionView from "./WildgoatGameQuesionView";
import { WildgoatConfigInitialValues } from "./WildgoatInitialValues";
import WildGoatSummaryView from "./WildGoatSummary/WildGoatSummaryView";
import { WildgoatConfigTransformData } from "./WildgoatTransformData";
import { QuizContextType, WildgoatConfigType, WildgoatTriggerQuestionType } from "./WildgoatType";

const WildgoatGameView = () => {
    const info: any = useLocation();
    const [quizInfoData, setQuizInfoData] = useState<QuizInfoType>(QuizInfoInitialValues);
    const [quizUserSessionInfoData, setQuizUserSessionInfoData] = useState<QuizUserSessionInfoType>(
        QuizUserSessionInfoInitialValues
    );
    const [isQuestionTriggered, setIsQuestionTriggered] = useState(false);
    const [quizConfig, setQuizConfig] = useState<WildgoatConfigType>(WildgoatConfigInitialValues);
    const [timerEnd, setTimerEnd] = useState<TimersEndType>(TimerEndInitialValues);
    const [answerText, setSelectedAnswerText] = useState("");
    const [socketBooleanStates, setSocketBooleanStates] = useState<socketBooleanStatesType>(
        socketBooleanStatesInitialValues
    );
    const [currentQuestionInfo, setCurrentQuestionInfo] = useState<currentQuestionInfoType>();
    const [questionTiming, setQuestionTiming] = useState("00:00:00");
    //eslint-disable-next-line
    const [totalTimeTakeninEvent, setTotalTimeTakenInEvent] = useState("");
    const [answerResultType, setAnswerResultType] = useState("");
    const [uploadPercent, setUploadPercent] = useState(0);
    const [refreshInfo, setRefreshInfo] = useState("initialKey");
    const [triggerQuestion, setTriggerQuestions] = useState<WildgoatTriggerQuestionType[]>([]);
    const [quizContext, setQuizContext] = useState<QuizContextType | null>(null);
    const questionInitialTime = useRef<string>("");
    const socket = useRef<any>();

    const startSocket = (eventTimeLimit: string, quizConfiguration: WildgoatConfigType) => {
        socket.current = io(ApiConstants.connectSocket(), {
            transports: ["websocket"]
        });
        socket.current.on("connect", () => {
            socket.current.emit("joinTeamEvent", {
                eventId: info.state.eventId,
                participantCode: info.state.code
            });
        });

        socket.current.on("fetchNextQuestionEvent", (data: any) => {
            fetchSocketNextQuestion(data, eventTimeLimit);
        });

        socket.current.on("answerSubmittedEvent", (data: any) => {
            if (data) {
                setSocketBooleanStates((prevState) => ({
                    ...prevState,
                    disableSubmit: true,
                    answerGiven: true,
                    isVideoPlayed: false
                }));
                setSelectedAnswerText(data.submittedAnswer);
                setAnswerResultType(data.result.userAttemptType);
                setRefreshInfo(Math.random().toString());
                setQuizUserSessionInfoData((prevState) => ({
                    ...prevState,
                    totalHintUsed: data.result.hintsUsedCount,
                    totalMarks: data.result.quizScore,
                    eventTimer: data ? getRemainingTime(eventTimeLimit, data.result.timeTakenInQuiz) : "",
                    correctAnswerCount: data.result.correctAnswersCount,
                    wrongAnswerCount: data.result.wrongAnswersCount
                }));
                if (data.result.userAttemptType === "timeout") {
                    setTimerEnd((prevState) => ({ ...prevState, questionTimeEnds: true }));
                    toast.success(`Question time ends`, successToastConfiguration(quizConfiguration.primaryColor));
                } else {
                    toast.success(
                        `Answer given by ${data.participantName}`,
                        successToastConfiguration(quizConfiguration.primaryColor)
                    );
                }
                if (
                    data.result.isNonScoringQuestion &&
                    data.question.correctAnswerText === "" &&
                    data.question.wrongAnswerText === ""
                ) {
                    fetchNextQuestion(eventTimeLimit);
                } else {
                    setTimeout(() => {
                        if (data.participantName === info.state.participantName) {
                            fetchNextQuestion(eventTimeLimit);
                        }
                    }, 5000);
                }
            }
        });

        socket.current.on("locationReached", (data: any) => {
            setIsQuestionTriggered(true);
            toast.success(
                `Player ${data.participantName} reached the location`,
                successToastConfiguration(quizConfig.primaryColor)
            );
        });

        socket.current.on("hintUsed", (data: any) => {
            setQuizUserSessionInfoData((prevState) => ({
                ...prevState,
                hintsAlreadyUsed: data.result.usedQuestionHintIds,
                totalHintUsed: data.result.hintsUsedCount,
                totalMarks: data.result.quizScore,
                hintUsed:
                    data.result.usedQuestionHintIds === ""
                        ? 0
                        : data.result.usedQuestionHintIds?.split(",").length || 0,
                eventTimer: getRemainingTime(eventTimeLimit, data.result.timeTakenInQuiz)
            }));

            setRefreshInfo(Math.random().toString());
            setQuestionTiming(getRemainingTime(questionInitialTime.current, data.result.timeTakenInQuestion));
            toast.success(`Hint taken by ${data.participantName}`, successToastConfiguration(quizConfig.primaryColor));
        });
    };

    useEffect(() => {
        fetchQuizData();
    }, []);

    const fetchSocketNextQuestion = (data: any, eventTime: string, initialProgress = 0) => {
        setSelectedAnswerText("");
        setTriggerQuestions([]);
        setIsQuestionTriggered(false);
        setSocketBooleanStates((prevState) => ({
            ...prevState,
            disableSubmit: false,
            answerGiven: false,
            hintDisable: false,
            loading: false,
            isVideoPlayed: false
        }));
        setAnswerResultType("");
        setTimerEnd((prevState) => ({ ...prevState, questionTimeEnds: false }));
        setTimerEnd((prevState) => ({ ...prevState, connectorQuizEnds: false }));
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, hintUsed: 0, hintsAlreadyUsed: "" }));
        if (data.message) {
            setSocketBooleanStates((prevState) => ({ ...prevState, endQuiz: true }));
            if (data.userQuizSessionInfo.userAttemptType === "quiz-timeout") {
                setTotalTimeTakenInEvent("00:00:00");
                return;
            }
            setTotalTimeTakenInEvent(getRemainingTime(eventTime, data.userQuizSessionInfo.timeTakenInQuiz));
            return;
        }
        data.nextQuestionInfo && (questionInitialTime.current = data.nextQuestionInfo.timeLimit);
        if (data?.userQuizSessionInfo?.timeTakenInQuestion) {
            data.nextQuestionInfo &&
                setQuestionTiming(
                    getRemainingTime(data.nextQuestionInfo.timeLimit, data.userQuizSessionInfo.timeTakenInQuestion)
                );
        } else {
            data.nextQuestionInfo && setQuestionTiming(data.nextQuestionInfo.timeLimit);
        }
        setCurrentQuestionInfo(socketCurrentQuestionTransformData(data.nextQuestionInfo));
        currentQuestionTransformData(data.nextQuestionInfo);
        if (data.userQuizSessionInfo) {
            setQuizUserSessionInfoData(socketQuizUserSessionInfoTransformData(eventTime, data.userQuizSessionInfo));
        } else {
            setQuizUserSessionInfoData((prevState) => ({ ...prevState, eventTimer: eventTime }));
            setQuizUserSessionInfoData((prevState) => ({ ...prevState, totalMarks: initialProgress }));
        }
        setRefreshInfo(FieldValidation.generateAlphaNumericCode());
    };

    useEffect(() => {
        if (timerEnd.eventEnds) {
            saveProgress({
                timeout: true,
                type: "quiz"
            });
        }
    }, [timerEnd.eventEnds]);

    useEffect(() => {
        if (timerEnd.questionTimeEnds) {
            saveProgress({
                timeout: true,
                type: "question"
            });
        }
    }, [timerEnd.questionTimeEnds]);

    const emitFetchNextQuestionEvent = (data: any) => {
        socket.current.emit("fetchNextQuestionEvent", {
            eventId: info.state.eventId,
            participantCode: info.state.code,
            nextQuestion: { ...data }
        });
    };

    useEffect(() => {
        if (quizConfig.tabTitle) {
            document.title = quizConfig.tabTitle;
        }
    }, [quizConfig.tabTitle]);

    const fetchQuizData = (): void => {
        fetchQuizInfo({
            eventLink: info.state.eventLink,
            code: info.state.code
        }).then((response) => {
            setQuizInfoData(QuizInfoTransformData(response));
            setQuizConfig(WildgoatConfigTransformData(response));
            startSocket(response.data.data.quiz.timeLimit, WildgoatConfigTransformData(response));
            fetchNextQuestion(
                response.data.data.quiz.timeLimit,
                response.data.data.quiz.initialScore ? response.data.data.quiz.initialScore : 0
            );
        });
    };

    const fetchNextQuestion = (eventTime: string, initialProgress = 0): void => {
        setSelectedAnswerText("");
        setTriggerQuestions([]);
        setSocketBooleanStates((prevState) => ({
            ...prevState,
            disableSubmit: false,
            answerGiven: false,
            hintDisable: false,
            loading: true,
            isVideoPlayed: false
        }));
        setAnswerResultType("");
        setTimerEnd((prevState) => ({ ...prevState, questionTimeEnds: false }));
        setTimerEnd((prevState) => ({ ...prevState, connectorQuizEnds: false }));
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, hintUsed: 0, hintsAlreadyUsed: "" }));
        Client.getInstance()
            .getData(
                info.state.isMultibranch
                    ? ApiConstants.fetchNextMultibranchQuestionApiUrl(info.state.eventLink, info.state.code)
                    : ApiConstants.fetchNextQuestionApiUrl(info.state.eventLink, info.state.code),
                true
            )
            .then((response: any) => {
                const data = response.data.data;
                if (data.message) {
                    setSocketBooleanStates((prevState) => ({ ...prevState, endQuiz: true }));
                    emitFetchNextQuestionEvent(data);
                    if (data.userQuizSessionInfo.userAttemptType === "quiz-timeout") {
                        setTotalTimeTakenInEvent("00:00:00");
                        return;
                    }
                    setTotalTimeTakenInEvent(getRemainingTime(eventTime, data.userQuizSessionInfo.timeTakenInQuiz));
                    return;
                }
                if (data.context) {
                    setQuizContext({
                        branchId: data.context.branchId,
                        connectorQuizId: data.context.connectorQuizId,
                        connectQuestionId: data.context.connectorQuestionId,
                        questionId: data.context.questionId,
                        quizId: data.context.quizId
                    });
                }
                data.nextQuestionInfo && (questionInitialTime.current = data.nextQuestionInfo.timeLimit);
                if (data?.userQuizSessionInfo?.timeTakenInQuestion) {
                    data.nextQuestionInfo &&
                        setQuestionTiming(
                            getRemainingTime(
                                data.nextQuestionInfo.timeLimit,
                                data.userQuizSessionInfo.timeTakenInQuestion
                            )
                        );
                } else {
                    data.nextQuestionInfo && setQuestionTiming(data.nextQuestionInfo.timeLimit);
                }
                if (data.userQuizSessionInfo) {
                    setQuizUserSessionInfoData(QuizUserSessionInfoTransformData(eventTime, response));
                } else {
                    setQuizUserSessionInfoData((prevState) => ({ ...prevState, eventTimer: eventTime }));
                    setQuizUserSessionInfoData((prevState) => ({ ...prevState, totalMarks: initialProgress }));
                }
                data.nextQuestionInfo && setTriggerQuestions(data.nextQuestionInfo.triggers);
                currentQuestionTransformData(data.nextQuestionInfo);
                setRefreshInfo(FieldValidation.generateAlphaNumericCode());
                emitFetchNextQuestionEvent(data);
            })
            .catch((err) => {
                if (err.response.data.message === "Invalid participantCode") {
                    setSocketBooleanStates((prevState) => ({ ...prevState, endQuiz: true }));
                }
            })
            .finally(() => {
                setSocketBooleanStates((prevState) => ({ ...prevState, loading: false }));
            });
    };

    const currentQuestionTransformData = (data: any): void => {
        setSocketBooleanStates((prevState) => ({
            ...prevState,
            allowSkipping: true,
            hideTimer: data.timeLimit === "00:00:00"
        }));
        setCurrentQuestionInfo(CurrentQuestionTransformData(data));
    };

    //TODO use event time.
    //eslint-disable-next-line
    const getEventTiming = (time: string): void => {
        if (time === "0:0:0" && !timerEnd.eventEnds) {
            setTimerEnd((prevState) => ({ ...prevState, eventEnds: true }));
            return;
        }
        time !== "" && setQuizUserSessionInfoData((prevState) => ({ ...prevState, eventTimer: time }));
    };

    const getQuestionTiming = (time: string): void => {
        if (time === "0:0:0" && !timerEnd.questionTimeEnds) {
            setTimerEnd((prevState) => ({ ...prevState, questionTimeEnds: true }));
            return;
        }
        time !== "" && setQuestionTiming(time);
    };

    const saveProgressForFileUpload = (files: File[]) => {
        setUploadPercent(0);
        setSocketBooleanStates((prevState) => ({ ...prevState, isFileUploadLoading: true }));
        setSocketBooleanStates((prevState) => ({ ...prevState, isFileUploadFailed: false }));
        const uploadData = new FormData();
        uploadData.append("teamName", info.state.teamName);
        uploadData.append("participantCode", info.state.code);
        uploadData.append("quizId", info.state.quizId);
        uploadData.append(
            "timeTakenInQuestion",
            getRemainingTime(
                currentQuestionInfo && currentQuestionInfo.timeLimit ? currentQuestionInfo.timeLimit : "00:00:00",
                questionTiming
            )
        );
        uploadData.append("eventId", info.state.eventId);
        uploadData.append("participantName", info.state.participantName);
        uploadData.append("questionId", currentQuestionInfo?.id || "");
        for (const i in files) {
            uploadData.append(`participantFiles[${i}]`, files[i]);
        }
        const options = {
            onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                const percent = Math.floor((loaded * 100) / total);
                setUploadPercent(percent);
            }
        };
        Client.getInstance()
            .createData(ApiConstants.uploadFileApiUrl(info.state.eventLink, info.state.code), uploadData, options)
            .then(() => {
                setSocketBooleanStates((prevState) => ({ ...prevState, isFileUploadLoading: false }));
            })
            .catch(() => {
                setUploadPercent(0);
                setSocketBooleanStates((prevState) => ({ ...prevState, isFileUploadFailed: true }));
                setSocketBooleanStates((prevState) => ({ ...prevState, isFileUploadLoading: false }));
                toast.success(
                    "File upload fail. Please upload it again",
                    successToastConfiguration(quizConfig.primaryColor)
                );
            });
    };

    const saveProgress = ({
        timeout = false,
        type,
        isSkipped = false,
        submittedAnswer
    }: {
        timeout?: boolean;
        type?: string;
        isSkipped?: boolean;
        submittedAnswer?: string;
    }) => {
        const data = {
            teamName: info.state.teamName,
            participantCode: info.state.code,
            participantName: info.state.participantName,
            quizId: info.state.quizId,
            timeTakenInQuestion: getRemainingTime(
                currentQuestionInfo && currentQuestionInfo.timeLimit ? currentQuestionInfo.timeLimit : "00:00:00",
                questionTiming
            ),
            timeTakenInQuiz: getRemainingTime(quizInfoData.initialEventTimer, quizUserSessionInfoData.eventTimer || ""),
            submittedAnswer: isSkipped ? "" : submittedAnswer || answerText,
            eventId: info.state.eventId,
            questionId: currentQuestionInfo?.id,
            branchId: quizContext?.branchId ? quizContext.branchId : undefined,
            userAttemptType: timeout
                ? type === "question"
                    ? "timeout"
                    : "quiz-timeout"
                : isSkipped
                ? "skip"
                : undefined
        };

        Client.getInstance()
            .createData(ApiConstants.socketQuestionSubmit(info.state.eventLink, info.state.code), data)
            .then(() => {
                setSocketBooleanStates((prevState) => ({ ...prevState, answerGiven: true }));
            });
    };

    const playerReachedPosition = (isTrigger: boolean) => {
        const newObj = {
            participantName: info.state.participantName,
            teamName: info.state.teamName
        };
        setIsQuestionTriggered(isTrigger);
        socket.current.emit("locationReached", newObj);
    };

    const getQuestionView = () => {
        if (currentQuestionInfo) {
            return (
                <WildgoatGameQuesionView
                    quizConfig={quizConfig}
                    setIsReachedPosition={playerReachedPosition}
                    isPositionReached={isQuestionTriggered}
                    totalPointsText={quizInfoData.totalPointsText}
                    key={refreshInfo}
                    isForceCorrect={!!currentQuestionInfo.forceCorrect}
                    videoLocation={currentQuestionInfo.videoQuestionFileLocation}
                    youtubeVideoLink={currentQuestionInfo.youtubeVideoLink}
                    correctAnswerText={currentQuestionInfo.correctAnswerText}
                    wrongAnswerPoints={
                        currentQuestionInfo.wrongPoints
                            ? currentQuestionInfo.wrongPoints
                            : parseInt(quizInfoData.wrongAnswerPoints)
                    }
                    triggers={triggerQuestion}
                    wrongAnswerText={currentQuestionInfo.wrongAnswerText}
                    saveProgress={({ isSkipped, submittedAnswer }: { isSkipped?: boolean; submittedAnswer?: string }) =>
                        saveProgress({
                            timeout: false,
                            isSkipped,
                            type: "question",
                            submittedAnswer
                        })
                    }
                    backgroundColor={quizConfig.backgroundColor}
                    mcqOptions={currentQuestionInfo.mcqOptions}
                    questionTextone={currentQuestionInfo.questionText}
                    questionTexttwo={currentQuestionInfo.bottomQuestionText}
                    questionTime={questionTiming}
                    timeoutText={currentQuestionInfo.timeOutText}
                    setQuestionTiming={getQuestionTiming}
                    teamPoints={quizUserSessionInfoData.totalMarks}
                    correctAnswerPoints={
                        currentQuestionInfo.correctPoints
                            ? currentQuestionInfo.correctPoints
                            : parseInt(quizInfoData.correctAnswerPoints)
                    }
                    mcqColor={quizConfig.buttonColor}
                    participantAnswer={answerText}
                    updateParticipantAnswer={(answer: string) => {
                        setSelectedAnswerText(answer);
                    }}
                    answerResult={answerResultType}
                    type={currentQuestionInfo.type}
                    questionCoverImage={currentQuestionInfo.fileLocation}
                    hideTimer={socketBooleanStates.hideTimer}
                    loading={socketBooleanStates.loading}
                    questionTitle={currentQuestionInfo.title}
                    uploadFile={saveProgressForFileUpload}
                    uploadedPercentage={uploadPercent}
                    isFileUploading={socketBooleanStates.isFileUploadLoading}
                    isFileUploadFailed={socketBooleanStates.isFileUploadFailed}
                    isQuestionNonScoring={currentQuestionInfo.isNonScoringQuestion}
                    isSkippingAllowed={currentQuestionInfo.allowSkipping}
                    primaryColor={quizConfig.primaryColor}
                    secondaryColor={quizConfig.secondaryColor}
                    tertiaryColor={quizConfig.tertiaryColor}
                    teamName={info.state.teamName}
                    userName={info.state.participantName}
                />
            );
        }
    };
    return (
        <WildGoatsContainer bgcolor={quizConfig.primaryColor} bgcolor_offset={getOffsetColor(quizConfig.primaryColor)}>
            <ToastContainer />
            <Header
                quizTitle={quizInfoData.quizTitle}
                companyLogo={quizInfoData.companyLogo}
                quizLogo={quizInfoData.eventLogo}
                secondaryColor={quizConfig.primaryColor}
            />
            {socketBooleanStates.endQuiz ? (
                <WildGoatSummaryView
                    gameBackgroundColor={lightPrimaryColor200}
                    info={quizInfoData.summaryPageInfo}
                    eventLink={info.state.eventLink}
                    buttonColor={quizConfig.buttonColor}
                    showSurveyForm={quizInfoData.showSurveyForm}
                    eventId={info.state.eventId}
                    partcipantName={info.state.participantName}
                    participantCode={info.state.code}
                />
            ) : (
                getQuestionView()
            )}
        </WildGoatsContainer>
    );
};

export default WildgoatGameView;

import { AccordionDetails, AccordionSummary, Box, List } from "@mui/material";
import { FC, useState } from "react";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useHistory } from "react-router";
import { whiteColor } from "../../../../constants/Styles";
import { AccordianMenuText, MenuItemButton, MenuText, StyledAccordian } from "./Style";
import { SideMenuType } from "./Type";

type AccordianMenuProps = {
    menuInfo: SideMenuType;
    activeKey: string;
    setActiveKey: (key: string) => void;
};
const AccordianMenu: FC<AccordianMenuProps> = ({ menuInfo, activeKey, setActiveKey }) => {
    const history = useHistory();
    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <Box sx={{ marginTop: "10px", padding: "8px 16px 0px 16px" }}>
            <Box
                sx={{ cursor: "pointer" }}
                onClick={() => {
                    setIsExpanded((prev) => !prev);
                }}
                display={"flex"}
                justifyContent={"space-between"}>
                <AccordianMenuText>
                    {menuInfo.icon}
                    {menuInfo.title}
                </AccordianMenuText>
                {isExpanded ? <MdOutlineExpandLess /> : <MdOutlineExpandMore />}
            </Box>
            {isExpanded && (
                <Box ml={"18px"}>
                    <List key={activeKey} disablePadding>
                        {menuInfo.subMenu &&
                            menuInfo.subMenu.length > 0 &&
                            menuInfo.subMenu
                                .filter((item) => item.toShow)
                                .map((menuOption, index) => (
                                    <MenuItemButton
                                        style={{
                                            marginTop: index === 0 ? "0px" : "10px"
                                        }}
                                        onClick={() => {
                                            menuOption.url && setActiveKey(menuOption.url);
                                            menuOption.url && history.push(menuOption.url);
                                        }}
                                        isactive={activeKey === menuOption.url ? 1 : 0}
                                        key={`${menuOption.id}${history.location.pathname}`}>
                                        <MenuText
                                            isactive={activeKey === menuOption.url ? 1 : 0}
                                            style={{ color: whiteColor }}
                                            primary={menuOption.title}
                                        />
                                    </MenuItemButton>
                                ))}
                    </List>
                </Box>
            )}
        </Box>
    );
    return (
        <StyledAccordian
            expanded={isExpanded}
            defaultExpanded={["/admin/event", "/admin/quiz", "/admin/questionbank", "/admin/referencelibrary"].includes(
                history.location.pathname
            )}
            onClick={() => setIsExpanded((prev) => !prev)}
            elevation={0}>
            <AccordionSummary
                expandIcon={<MdOutlineExpandLess />}
                style={{ margin: 0, padding: "0px 16px", width: "100%" }}>
                <AccordianMenuText>
                    {menuInfo.icon}
                    {menuInfo.title}
                </AccordianMenuText>
            </AccordionSummary>
            <AccordionDetails style={{ padding: "0px 16px", marginLeft: "18px" }}>
                <List key={activeKey} disablePadding>
                    {menuInfo.subMenu &&
                        menuInfo.subMenu.length > 0 &&
                        menuInfo.subMenu
                            .filter((item) => item.toShow)
                            .map((menuOption, index) => (
                                <MenuItemButton
                                    style={{
                                        marginTop: index === 0 ? "0px" : "10px"
                                    }}
                                    onClick={() => {
                                        menuOption.url && setActiveKey(menuOption.url);
                                        menuOption.url && history.push(menuOption.url);
                                    }}
                                    isactive={activeKey === menuOption.url ? 1 : 0}
                                    key={`${menuOption.id}${history.location.pathname}`}>
                                    <MenuText
                                        isactive={activeKey === menuOption.url ? 1 : 0}
                                        style={{ color: whiteColor }}
                                        primary={menuOption.title}
                                    />
                                </MenuItemButton>
                            ))}
                </List>
            </AccordionDetails>
        </StyledAccordian>
    );
};

export default AccordianMenu;

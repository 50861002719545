import { Grid } from "@mui/material";
import { useHistory } from "react-router";
import { Header1, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import ArrowLeft from "../../../../Images/icons/arrowLeft.svg";
import BreadcrumbWrapper from "../../../../CommonComponent/v2/BreadCrumbWrapper";
import { referenceBreadcrumbValues, editReferenceTabs } from "./Utils";
import { useEditReference } from "./Hooks";
import TabWrapper from "../../../../CommonComponent/v2/tab/Tab";
import { Formik } from "formik";
import Details from "../addNewReference/details";
import { validationSchema, editReferenceInitialValue } from "../addNewReference/Utils";
import Template from "../addNewReference/template";
import Items from "./items";
import Attachments from "./attachments";
import LoadingWrapper from "../../../../CommonComponent/v2/LoadingWrapper";
import LoadingButtonWrapper from "../../../../CommonComponent/v2/button/LoadingButtonWrapper";

const EditReference = () => {
    const history = useHistory();
    const { selectedTab, referenceDetails, setSelectedTab, saveData, isLoading, permission } = useEditReference();

    const renderTab = () => {
        if (selectedTab == "details") {
            return <Details />;
        }
        if (selectedTab == "items") {
            return <Items />;
        }
        if (selectedTab == "attachments") {
            return <Attachments />;
        }
        return <Template />;
    };

    return (
        <Grid px={"20px"}>
            <Grid container>
                <Grid item xs={12}>
                    <Header1>
                        <img
                            onClick={() => history.push("/admin/v2/referencelistmanager")}
                            src={ArrowLeft}
                            style={{ height: 18, marginRight: 10, cursor: "pointer" }}
                        />
                        Edit Reference List
                    </Header1>
                    <BreadcrumbWrapper links={referenceBreadcrumbValues} />
                </Grid>
                <Grid item xs={12} mt={"20px"}>
                    <TabWrapper
                        tabsOptions={editReferenceTabs}
                        selectedTab={selectedTab}
                        handleChange={setSelectedTab}
                    />
                </Grid>
                <LoadingWrapper isLoading={isLoading}>
                    <Formik
                        initialValues={editReferenceInitialValue(referenceDetails)}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            saveData(values, setSubmitting);
                        }}
                        enableReinitialize
                        validateOnMount
                    >
                        {({ handleSubmit, isSubmitting }) => (
                            <Grid item xs={12}>
                                {renderTab()}
                                <Grid container justifyContent={"center"} mt={"40px"} mb={"20px"}>
                                    {
                                        !!(permission && permission.includes("update")) && <LoadingButtonWrapper loading={isSubmitting}>
                                            <PrimaryButton onClick={() => handleSubmit()}>Save</PrimaryButton>
                                        </LoadingButtonWrapper>
                                    }
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </LoadingWrapper>
            </Grid>
        </Grid>
    );
};

export default EditReference;

import { difference, intersection } from "lodash";
import { ApiConstants } from "../../../../../constants/ApiConstant";
import { correctAnswerBackgroundColor, wrongAnswerBackgroundColor } from "../../../../../constants/Styles";
import {
    ALL_CORRECT,
    ATLEAST_FIVE_CORRECT,
    ATLEAST_FOUR_CORRECT,
    ATLEAST_ONE_CORRECT,
    ATLEAST_THREE_CORRECT,
    ATLEAST_TWO_CORRECT,
    CONNECTOR,
    CONNECTOR_SKIP,
    CORRECT_ANSWER_COUNT,
    EVENT_TIMEOUT,
    FREE_TEXT_CONTAINS,
    FREE_TEXT_DOES_NOT_CONTAINS,
    FREE_TEXT_EXACT_MATCH,
    MCQ,
    MCQAnswerOption,
    NON_SCORING_QUESTION,
    QUESTION_TIMEOUT,
    SKIP_QUESTION,
    WRONG_ANSWER_COUNT
} from "../../../../../data/question/types";
import { extractHtmlContent } from "../../../../../utils/JambarUtils";
import { getRemainingTime } from "../../../../../utils/TimeUtils";
import { Client } from "../../../../Base/Client";
import {
    AnswerType,
    ConnectorQuestionInfoTypes,
    CurrentQuestionInfoType,
    InfoLocationType,
    QuestionContextType,
    QuizConfigType,
    QuizInfoType,
    QuizUserSessionInfoType
} from "../type";
import { FieldValidation } from "../../../../../constants/FieldValidation";

export const PathQuizUserSessionInfoInitialValues: QuizUserSessionInfoType = {
    correctAnswerCount: 0,
    eventTimer: "",
    hintUsed: 0,
    hintsAlreadyUsed: "",
    progressId: -1,
    totalHintUsed: 0,
    totalMarks: 0,
    wrongAnswerCount: 0
};

export const PathQuizConfigInitialValues: QuizConfigType = {
    attachmentTitle: "",
    backgroundColor: "",
    backgroundImage: "",
    progressImage: "",
    progressTitle: "",
    tabTitle: "",
    buttonColor: "",
    correctAnswerBackgroundColor: "",
    wrongAnswerBackgroundColor: ""
};

export const ConnectorQuestionInitialValues: ConnectorQuestionInfoTypes = {
    connectorIsClosable: false,
    connectorQuizIsWorking: false,
    connectorTotalTime: "",
    playedConnectorOptionQuizIds: [],
    showConnectorTimer: false,
    timeTakenInConnector: ""
};

export const PathQuizInfoInitialValues: QuizInfoType = {
    title: "",
    correctAnswerPoints: "0",
    wrongAnswerPoints: "0",
    summaryPageInfo: "",
    eventLogo: "",
    quizAllowSkipping: false,
    initialEventTimer: "",
    nextButtonText: "Continue",
    answerResultImage: "",
    answerResultText: "",
    showProgressInPercentage: false,
    companyLogo: "",
    showSurveyForm: false,
    quizTitle: "",
    showCorrectAnswerCount: true,
    showHintCount: true,
    showMainTimer: true,
    showWrongAnswerCount: true,
    totalPointsText: ""
};

export const pathQuizInfoTransformData = (response: any): QuizInfoType => {
    const data = response.data.data;
    return {
        correctAnswerPoints: data.quiz.correctPoints || "0",
        totalPointsText: data.quiz?.totalPointsText || "",
        eventLogo: data.event.logo || "",
        summaryPageInfo: data.quiz.summaryPageText || "",
        title: data.quiz.title || "",
        wrongAnswerPoints: data.quiz.wrongPoints || "0",
        quizAllowSkipping: data.quiz.allowSkipping !== 0,
        initialEventTimer: data.quiz.timeLimit || "",
        nextButtonText:
            data.quiz.nextButtonText !== "null" && data.quiz.nextButtonText ? data.quiz.nextButtonText : "Continue",
        answerResultImage: data.quiz.answerResultImageFileLocation || "",
        answerResultText: data.quiz.answerResultText || "",
        showProgressInPercentage: !!data.quiz.showProgressInPercentage,
        companyLogo: data.event.companyLogo || "",
        showSurveyForm: data.event.showSurveyAtEnd || false,
        quizTitle: data.quiz.displayTitle || data.quiz.title,
        showCorrectAnswerCount: data.quiz.showCorrectAnswerCount,
        showWrongAnswerCount: data.quiz.showWrongAnswerCount,
        showMainTimer: data.quiz.showMainTimer,
        showHintCount: data.quiz.showHintCount
    };
};

export const pathQuizConfigTransformData = (response: any): QuizConfigType => {
    const data = response.data.data.quiz;
    return {
        attachmentTitle:
            data.quizConfig && data.quizConfig.attachmentTitle !== "null" && data?.quizConfig.attachmentTitle
                ? data.quizConfig.attachmentTitle
                : "",
        backgroundImage: data?.quizConfig?.backgroundImageFileLocation || "",
        progressImage: data?.quizConfig?.progressImageFileLocation || "",
        progressTitle: data?.quizConfig?.progressTitle || "",
        backgroundColor: data?.quizConfig?.baseColor || "",
        buttonColor: data?.quizConfig?.baseButtonColor || "",
        correctAnswerBackgroundColor: data?.quizConfig?.correctAnswerBackgroundColor || correctAnswerBackgroundColor,
        wrongAnswerBackgroundColor: data?.quizConfig?.wrongAnswerBackgroundColor || wrongAnswerBackgroundColor,
        tabTitle:
            data.quizConfig && data.quizConfig.browserTabTitle
                ? response.data.data.quiz.quizConfig.browserTabTitle
                : extractHtmlContent(response.data.data.quiz.title)
    };
};

export const currentQueTransformData = (data: any): CurrentQuestionInfoType => ({
    ...data,
    mcqOptions: JSON.parse(data.mcqOptions),
    questionConnector: data.type === CONNECTOR ? data.questionConnector : [],
    bottomQuestionText: data.questionText2
});

export const getNextQuestion = (eventLink: string, code: string) => {
    return Client.getInstance()
        .getData(ApiConstants.fetchNextQuestionApiUrl(eventLink, code), true)
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch(() => {
            return Promise.reject();
        });
};

export const reverseTransformData = (data: {
    info: InfoLocationType;
    answerType: AnswerType;
    questionContext: QuestionContextType;
    quizInfoData: QuizInfoType;
    currentQuestionInfo: CurrentQuestionInfoType;
    answerCount: number;
    totalScore: number;
    connectorQuizId?: number;
    timeout?: boolean;
    questionTiming: string;
    quizUserSessionInfoData: QuizUserSessionInfoType;
    connectorQuestionInfo: ConnectorQuestionInfoTypes;
}): any => {
    const {
        answerCount,
        answerType,
        currentQuestionInfo,
        info,
        questionContext,
        quizInfoData,
        totalScore,
        connectorQuizId,
        timeout,
        questionTiming,
        quizUserSessionInfoData,
        connectorQuestionInfo
    } = data;
    let quizProgressData: any = {
        teamName: info.participantName,
        quizId: questionContext && questionContext.quizId,
        questionId: questionContext && questionContext.questionId,
        timeTakenInQuiz: getRemainingTime(quizInfoData.initialEventTimer, quizUserSessionInfoData.eventTimer),
        timeTakenInQuestion: getRemainingTime(
            currentQuestionInfo && currentQuestionInfo.timeLimit ? currentQuestionInfo.timeLimit : "00:00:00",
            questionTiming
        ),
        hintsUsedCount: quizUserSessionInfoData.totalHintUsed,
        connectorQuizId: connectorQuizId,
        connectorQuestionId: questionContext && questionContext.connectorQuestionId,
        correctAnswersCount: quizUserSessionInfoData.correctAnswerCount,
        wrongAnswersCount: quizUserSessionInfoData.wrongAnswerCount
    };
    if (answerType.type === EVENT_TIMEOUT) {
        return {
            ...quizProgressData,
            userAttemptType: "quiz-timeout",
            quizScore: totalScore
        };
    }

    if (answerType.type === NON_SCORING_QUESTION) {
        quizProgressData = {
            ...quizProgressData,
            userAttemptType: "correct",
            quizScore: totalScore
        };
    }
    if (answerType.type === CORRECT_ANSWER_COUNT) {
        quizProgressData = {
            ...quizProgressData,
            correctAnswersCount: answerCount,
            userAttemptType: "correct",
            quizScore: totalScore
        };
    }
    if (answerType.type === WRONG_ANSWER_COUNT) {
        quizProgressData = {
            ...quizProgressData,
            wrongAnswersCount: answerCount,
            userAttemptType: "wrong",
            quizScore: totalScore
        };
    }
    if (answerType.type === SKIP_QUESTION) {
        return {
            ...quizProgressData,
            userAttemptType: "skip",
            quizScore: quizUserSessionInfoData.totalMarks + (currentQuestionInfo ? currentQuestionInfo?.skipPoints : 0)
        };
    }
    if (answerType.type === CONNECTOR_SKIP) {
        quizProgressData = {
            ...quizProgressData,
            userAttemptType: "skip",
            quizScore: totalScore,
            usedQuestionHintIds: quizUserSessionInfoData.hintsAlreadyUsed
        };
    }
    if (answerType.type === CONNECTOR) {
        quizProgressData = {
            ...quizProgressData,
            userAttemptType: "skip",
            quizScore: totalScore
        };
    }
    if (timeout) {
        quizProgressData = {
            ...quizProgressData,
            userAttemptType: "timeout",
            quizScore: totalScore
        };
    }
    if (answerType.type === QUESTION_TIMEOUT) {
        return {
            ...quizProgressData,
            wrongAnswersCount: answerCount,
            userAttemptType: "wrong",
            quizScore: totalScore
        };
    }

    if (answerType.quizType === CONNECTOR) {
        return {
            ...quizProgressData,
            timeTakenInConnector: getRemainingTime(
                connectorQuestionInfo.connectorTotalTime,
                connectorQuestionInfo.timeTakenInConnector
            )
        };
    }
    return {
        ...quizProgressData,
        userAttemptType: "correct",
        quizScore: totalScore
    };
};

export const saveCurrentQuestion = (data: {
    info: InfoLocationType;
    answerType: AnswerType;
    questionContext: QuestionContextType;
    quizInfoData: QuizInfoType;
    currentQuestionInfo: CurrentQuestionInfoType;
    answerCount: number;
    totalScore: number;
    connectorQuizId?: number;
    timeout?: boolean;
    questionTiming: string;
    quizUserSessionInfoData: QuizUserSessionInfoType;
    connectorQuestionInfo: ConnectorQuestionInfoTypes;
}) => {
    return Client.getInstance()
        .createData(ApiConstants.saveProgressApiUrl(data.info.eventLink, data.info.code), reverseTransformData(data))
        .then(() => {
            return Promise.resolve();
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const quizUserSessionInfoTransformData = (eventTime: string, response: any): QuizUserSessionInfoType => {
    const data = response.data.data.userQuizSessionInfo;
    return {
        correctAnswerCount: data?.correctAnswersCount || 0,
        eventTimer: data ? getRemainingTime(eventTime, data.timeTakenInQuiz) : "",
        wrongAnswerCount: data?.wrongAnswersCount || 0,
        hintUsed: data.usedQuestionHintIds === "" ? 0 : data.usedQuestionHintIds?.split(",").length || 0,
        hintsAlreadyUsed: data?.usedQuestionHintIds || "",
        progressId: data?.id || "",
        totalHintUsed: data?.hintsUsedCount || 0,
        totalMarks: data?.quizScore || 0
    };
};

export const saveProgressInterval = (
    quizUserSessionInfoData: QuizUserSessionInfoType,
    time: {
        timeTakenInQuiz: string;
        timeTakenInConnector: string;
    }
) => {
    return Client.getInstance()
        .updateData(ApiConstants.saveProgressAfterXSeconds(quizUserSessionInfoData.progressId), time)
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());
};

export const getTotalMarks = (
    answerResult: string,
    quizUserSessionInfoData: QuizUserSessionInfoType,
    currentQuestionInfo: CurrentQuestionInfoType,
    quizInfoData: QuizInfoType
) => {
    if (answerResult === "correct") {
        return (
            quizUserSessionInfoData.totalMarks +
            (currentQuestionInfo.correctPoints
                ? Number(currentQuestionInfo.correctPoints)
                : Number(quizInfoData.correctAnswerPoints))
        );
    }
    return (
        quizUserSessionInfoData.totalMarks +
        (currentQuestionInfo.wrongPoints
            ? Number(currentQuestionInfo.wrongPoints)
            : Number(quizInfoData.wrongAnswerPoints))
    );
};

export const saveProgressAfterXSeconds = (
    quizInfoData: QuizInfoType,
    connectorQuestionInfo: ConnectorQuestionInfoTypes,
    quizUserSessionInfoData: QuizUserSessionInfoType
): void => {
    const data = {
        timeTakenInQuiz: getRemainingTime(quizInfoData.initialEventTimer, quizUserSessionInfoData.eventTimer),
        timeTakenInConnector: getRemainingTime(
            connectorQuestionInfo.connectorTotalTime,
            connectorQuestionInfo.timeTakenInConnector
        )
    };
    if (quizUserSessionInfoData.progressId !== -1) {
        saveProgressInterval(quizUserSessionInfoData, data);
    }
};

export const getAnswerResult = (
    answerGivenByParticipant: string,
    currentQuestionInfo: CurrentQuestionInfoType
): boolean => {
    const correctAnswerOfQuestion =
        currentQuestionInfo.type === MCQ ? currentQuestionInfo.mcqAnswers : currentQuestionInfo.answer;
    const optionsId = currentQuestionInfo.mcqOptions
        ? currentQuestionInfo.mcqOptions.map((option: MCQAnswerOption) => option.id.toString())
        : [];
    let correctAnswerArray = correctAnswerOfQuestion.split(",");

    correctAnswerArray = correctAnswerArray.map((element: string) => element.trim().toLowerCase());
    const answersArray = answerGivenByParticipant.split(",").map((element: string) => element.trim().toLowerCase());

    if (currentQuestionInfo.answerType === ATLEAST_ONE_CORRECT) {
        const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
        const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);
        if (intersectWrongAnswerOptions.length === 0) {
            return true;
        }
        return false;
    }

    if (currentQuestionInfo.answerType === ATLEAST_TWO_CORRECT) {
        const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
        const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);

        if (intersectWrongAnswerOptions.length !== 0) {
            return false;
        }
        if (intersection(correctAnswerArray, answersArray).length >= 2) {
            return true;
        }
        return false;
    }
    if (currentQuestionInfo.answerType === ATLEAST_THREE_CORRECT) {
        const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
        const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);

        if (intersectWrongAnswerOptions.length !== 0) {
            return false;
        }
        if (intersection(correctAnswerArray, answersArray).length >= 3) {
            return true;
        }
        return false;
    }

    if (currentQuestionInfo.answerType === ATLEAST_FOUR_CORRECT) {
        const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
        const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);

        if (intersectWrongAnswerOptions.length !== 0) {
            return false;
        }
        if (intersection(correctAnswerArray, answersArray).length >= 4) {
            return true;
        }
        return false;
    }

    if (currentQuestionInfo.answerType === ATLEAST_FIVE_CORRECT) {
        const wrongAnswerOptions = difference(optionsId, correctAnswerArray);
        const intersectWrongAnswerOptions = intersection(answersArray, wrongAnswerOptions);

        if (intersectWrongAnswerOptions.length !== 0) {
            return false;
        }
        if (intersection(correctAnswerArray, answersArray).length >= 5) {
            return true;
        }
        return false;
    }

    if (currentQuestionInfo.answerType === FREE_TEXT_CONTAINS) {
        for (const answer of correctAnswerArray) {
            if (FieldValidation.freeTextContainsValidation(answer, answersArray[0])) {
                return true;
            }
        }
        return false;
    }

    if (currentQuestionInfo.answerType === FREE_TEXT_EXACT_MATCH || currentQuestionInfo.answerType === ALL_CORRECT) {
        if (answersArray.length !== correctAnswerArray.length) {
            return false;
        }
        const result = difference(answersArray, correctAnswerArray);
        return result.length === 0;
    }

    if (currentQuestionInfo.answerType === FREE_TEXT_DOES_NOT_CONTAINS) {
        for (const answer of correctAnswerArray) {
            if (FieldValidation.freeTextContainsValidation(answer, answersArray[0])) {
                return false;
            }
        }
        return true;
    }

    return false;
};

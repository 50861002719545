import { Grid } from "@mui/material";
import { FC, useState } from "react";
import { GameQuestionListItem } from "../../Type";
import { CaptionText } from "../../../../../../WebsiteGame/style";
import { CustomTextfield } from "../../../../../../CommonComponent/v2/fields/FormikTextInput";
import { SearchOutlined } from "@mui/icons-material";
import { SearchTab } from "../../../../../../CommonComponent/v2/searchheader/SearchHeader";
import { OptionType } from "../../../../../../CommonComponent/v2/fields/Type";
import { getFilteredQuestionList } from "./Util";
import PaginationQuestion from "./PaginationQuestion";

type AllQuestionListProps = {
    questionList: GameQuestionListItem[];
    setSelectedRows: (rows: GameQuestionListItem[]) => void;
    selectedRows: GameQuestionListItem[];
    gameType: string;
    tagOptions: OptionType[];
    gameId: string;
    openEditQuestionDrawer: (questionId: string) => void;
};

const AllQuestionList: FC<AllQuestionListProps> = ({
    questionList,
    selectedRows,
    setSelectedRows,
    tagOptions,
    openEditQuestionDrawer
}) => {
    const [searchText, setSearchText] = useState("");
    const [selectedTag, setSelectedTag] = useState("all");

    return (
        <Grid>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                <Grid item xs={7}>
                    <Grid container spacing={"10px"}>
                        <Grid item xs={6}>
                            <CustomTextfield
                                placeholder="Search a challenge"
                                name="search"
                                id="search"
                                value={searchText}
                                onChange={({ target }) => setSearchText(target.value)}
                                InputProps={{
                                    startAdornment: <SearchOutlined />
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SearchTab
                                filterOptions={{
                                    value: selectedTag,
                                    options: tagOptions,
                                    setValue: setSelectedTag
                                }}
                                label="Filter by tag"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={"30px"}>
                <PaginationQuestion
                    handleViewClick={(id) => {
                        openEditQuestionDrawer(id);
                    }}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    questionList={getFilteredQuestionList(questionList, selectedTag, searchText)}
                />
            </Grid>
        </Grid>
    );
};

export default AllQuestionList;

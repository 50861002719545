import { Grid } from "@mui/material";
import FormikTextInput from "../../../../../../CommonComponent/v2/fields/FormikTextInput";
import { useFormikContext } from "formik";
import ImageDrop from "../../../../../../CommonComponent/v2/fields/ImageDrop";
import { CaptionText } from "../../../../../../CommonComponent/v2/CommonStyle";
import { darkPrimaryColor } from "../../../../../../../constants/ColorConstant";
import { AddItemType } from "./Types";
import FormikTextEditor from "../../../../../../CommonComponent/v2/fields/FormikTextEditor";
import { removeReferenceDescriptionImage } from "./Utils";
import { useParams } from "react-router";

const AddItemFormUI = () => {
    const { values } = useFormikContext();
    const data = values as AddItemType;

    const { refItemId } = useParams<{
        refItemId: string;
    }>();

    const removeDescriptionImage = () => {
        refItemId && removeReferenceDescriptionImage(refItemId);
    };

    return (
        <Grid container mt={"20px"} spacing={"20px"}>
            <Grid item xs={7}>
                <FormikTextInput name={"title"} id={"title"} label={"Title"} fullWidth />
            </Grid>
            <Grid item xs={7}>
                <CaptionText mb={"10px"} fontWeight={700} color={darkPrimaryColor}>
                    Top description text
                </CaptionText>
                <FormikTextEditor name={"topDescription"} placeholder={"Enter description here..."} />
            </Grid>
            <Grid item xs={7} mt={"60px"}>
                <CaptionText fontWeight={700} color={darkPrimaryColor}>
                    Description image
                </CaptionText>
                <ImageDrop
                    imageSize={2}
                    variant={"secondary"}
                    selectedFile={data.descriptionImage}
                    logoInfo={"Attach description image (800px * 600px)"}
                    name={"descriptionImage"}
                    removeImage={() => removeDescriptionImage()}
                />
            </Grid>
            <Grid item xs={7} mb={"20px"}>
                <CaptionText mb={"10px"} fontWeight={700} color={darkPrimaryColor}>
                    Bottom description text
                </CaptionText>
                <FormikTextEditor name={"bottomDescription"} placeholder={"Enter description here..."} />
            </Grid>
        </Grid>
    );
};
export default AddItemFormUI;

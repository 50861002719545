import { useEffect, useState } from "react";
import { AddItemType } from "./Types";
import { useParams, useHistory } from "react-router";
import { addNewItem, getReferenceItemDetail } from "./Utils";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../../../utils/APIErrorMessages";

export const useItem = () => {
    const history = useHistory();
    const [itemDetails, setItemDetails] = useState<AddItemType | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const { id, refItemId } = useParams<{
        id: string;
        refItemId: string;
    }>();

    useEffect(() => {
        if (refItemId) {
            setIsLoading(true);
            getReferenceItemDetail(refItemId)
                .then((res) => setItemDetails(res))
                .catch((error) => toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration))
                .finally(() => setIsLoading(false));
        }
    }, []);

    const saveData = (values: AddItemType, setIsSubmitting: (value: boolean) => void) => {
        setIsSubmitting(true);
        addNewItem(id, values)
            .then(() => {
                toast.success("Item added", SiteConstants.successToastConfiguration);
                history.push(`/admin/v2/referencelistmanager/${id}/update`);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => setIsSubmitting(false));
    };
    return {
        itemDetails,
        setItemDetails,
        saveData,
        isLoading
    };
};

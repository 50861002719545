import { useEffect, useState } from "react";
import { useAdminContext } from "../../../Admin/context/AdminAuthContext";
import { useDebounce } from "../../../../commonhooks/Hooks";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { createPaymentIntent, updateCreatePaymentDetails, validatePromoCodeStatus } from "./util";
import { toast } from "react-toastify";

export const useStripeProvider = (credit: string, paymentComplete: () => void) => {
    const { userDetail, getCreditInfo } = useAdminContext();
    const [loading, setLoading] = useState(false);
    const [cardHolderName, setCardHolderName] = useState(null);
    const [promoCode, setPromoCode] = useState("");
    const [isChecking, setIsChecking] = useState<boolean>(false);
    const [availableType, setAvailableType] = useState<string>("notchecked");
    const [promoCodeValue, setPromoCodeValue] = useState<number | null>(null);
    const [country, setCountry] = useState("");

    const debouncePromoCode = useDebounce(promoCode, 300);

    useEffect(() => {
        debouncePromoCode !== "" && checkPromoCodeStatus(debouncePromoCode);
    }, [debouncePromoCode]);

    const stripe = useStripe();
    const elements = useElements();

    const checkPromoCodeStatus = (newCode: string) => {
        setIsChecking(true);
        setPromoCodeValue(null);
        validatePromoCodeStatus(newCode)
            .then((res) => {
                setPromoCodeValue(res);
                setAvailableType("available");
            })
            .catch(() => {
                setAvailableType("notavailaible");
            })
            .finally(() => setIsChecking(false));
    };

    const handleSubmit = async () => {
        setLoading(true);
        createPaymentIntent({
            userDetail: userDetail.email,
            credit: credit,
            promoCode: promoCode,
            shippingDetails: {
                address: {
                    city: "",
                    country: country,
                    line1: "",
                    postalCode: "",
                    state: ""
                },
                name: cardHolderName
            }
        })
            .then((res) => {
                fetchStripeCardDetails(res.data.data.clientSecret);
            })
            .catch(() => {
                toast.error("payment failed");
                setLoading(false);
            });
    };

    const fetchStripeCardDetails = async (clientSecret: string) => {
        if (elements == null) return;
        setLoading(true);
        const { error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    address: {
                        line1: "",
                        city: "",
                        country: country,
                        state: "",
                        postal_code: ""
                    },
                    name: cardHolderName,
                    email: userDetail.email
                }
            }
        });
        if (error) {
            toast.error(error.message || "Could not complete payment.");
            updateCreatePaymentDetails({
                clientSecret: clientSecret,
                status: "failed",
                userName: userDetail.email
            });
            setLoading(false);
        } else {
            updateCreatePaymentDetails({
                clientSecret: clientSecret,
                status: "success",
                userName: userDetail.email
            })
                .then(() => {
                    toast.success("Credit bought successfully");
                    getCreditInfo();
                })
                .catch((err) => {
                    toast.error(err?.message || "Something went wrong");
                })
                .finally(() => {
                    paymentComplete();
                    setLoading(false);
                });
        }
    };

    return {
        loading,
        fetchStripeCardDetails,
        handleSubmit,
        availableType,
        isChecking,
        promoCode,
        setPromoCode,
        setCardHolderName,
        cardHolderName,
        promoCodeValue,
        setCountry,
        country
    };
};

import { Image, Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { Client } from "../../../Base/Client";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { useFormik } from "formik";
import * as yup from "yup";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { toast, ToastContainer } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";

type ReferenceListAttachmentDetailsViewProps = {
    attachmentId: number;
    refreshAttachmentList: () => void;
    referenceListId: number;
    closeAddPanel: (id: number) => void;
};

type ReferenceListAttachment = {
    title: string;
    attachment: string;
    attachmentType: string;
};

const ReferenceListAttachmentDetailsView: React.FC<ReferenceListAttachmentDetailsViewProps> = ({
    attachmentId,
    refreshAttachmentList,
    referenceListId,
    closeAddPanel
}) => {
    const referenceListAttachmentInitialValues: ReferenceListAttachment = {
        attachment: "",
        attachmentType: "",
        title: ""
    };

    const attachmentValidation = yup.object().shape({
        attachment: yup.string().required("File is mandatory"),
        title: yup.string().required("Title is mandatory")
    });

    const formik = useFormik({
        initialValues: referenceListAttachmentInitialValues,
        onSubmit: () => onSaveAttachment(),
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: attachmentValidation
    });

    const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState("");
    const [attachmentFile, setAttachmentFile] = useState<File | null>();
    const [isContentLoading, setIsContentLoading] = useState(false);

    useEffect(() => {
        if (attachmentId !== -1) {
            setIsContentLoading(true);
            Client.getInstance()
                .getData(ApiConstants.getReferenceListAttachmentDetailsApiUrl(referenceListId, attachmentId), true)
                .then((response) => {
                    const fileType = response.data.data.fileName.split(".");
                    formik.setValues({
                        attachment: response.data.data.fileLocation,
                        attachmentType: fileType[fileType.length - 1].toLowerCase(),
                        title: response.data.data.title
                    });
                    setIsContentLoading(false);
                })
                .catch(() => {
                    setIsContentLoading(false);
                });
        }
    }, []);

    const reverseTransformData = (): any => {
        const attachmentData = new FormData();
        attachmentData.append("title", formik.values.title);
        attachmentData.append("referenceListAttachment", attachmentFile as Blob);
        return attachmentData;
    };

    const onSaveAttachment = (): void => {
        if (fileUploadErrorMessage) {
            formik.setSubmitting(false);
            return;
        }
        if (attachmentId !== -1) {
            Client.getInstance()
                .updateData(
                    ApiConstants.updateReferenceListAttachmentsApiUrl(referenceListId, attachmentId),
                    reverseTransformData()
                )
                .then(() => {
                    formik.setSubmitting(false);
                    toast.success("Attachment updated", SiteConstants.successToastConfiguration);
                    refreshAttachmentList();
                })
                .catch((error) => {
                    formik.setSubmitting(false);
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
            return;
        }
        Client.getInstance()
            .createData(ApiConstants.addReferenceListAttachmentApiUrl(referenceListId), reverseTransformData())
            .then((response) => {
                formik.setSubmitting(false);
                toast.success("Attachment added", SiteConstants.successToastConfiguration);
                refreshAttachmentList();
                closeAddPanel(response.data.data.id);
            })
            .catch((error) => {
                formik.setSubmitting(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (!event.target.files || (event.target.files && event.target.files.length === 0)) {
            return;
        }
        const type = event.target.files[0].type;
        formik.setFieldValue("attachmentType", type.split("/")[1]);
        if (!FieldValidation.imageTypeValidation(type) && !FieldValidation.pdfFileValidation(type)) {
            setFileUploadErrorMessage("Please select an image or pdf file");
            formik.setFieldValue("attachment", "");
            return;
        }
        if (FieldValidation.attachmentSizeValidation(event.target.files[0].size)) {
            setFileUploadErrorMessage("File size should be less than 5 MB");
            formik.setFieldValue("attachment", "");
            return;
        }
        formik.setFieldValue("attachment", URL.createObjectURL(event.target.files[0]));
        setFileUploadErrorMessage("");
        setAttachmentFile(event.target.files[0]);
    };

    const getFileView = (): JSX.Element => {
        return formik.values.attachmentType === SiteConstants.AttachmentTypePDF ? (
            <Stack.Item className={"quiz-attachment-container"}>
                <iframe width="100%" src={formik.values.attachment}></iframe>
            </Stack.Item>
        ) : (
            <Stack.Item>
                <Image className={"attachment-image"} src={formik.values.attachment} alt="Cover Image" />
            </Stack.Item>
        );
    };
    if (isContentLoading) {
        return <Spinner className={"loading-spinner"} size={SpinnerSize.large} label={"Loading..."} />;
    }
    return (
        <>
            <ToastContainer />
            <Stack tokens={ThemedMediumStackTokens}>
                <Stack tokens={ThemedMediumStackTokens} className={"section-background section-border"}>
                    <Stack.Item grow>
                        <TextField
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            label={"Title"}
                            errorMessage={formik.errors.title}
                            name={"title"}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Label>File</Label>
                        <Form>
                            <Form.Group>
                                <Form.File
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event)}
                                />
                                {fileUploadErrorMessage !== "" && (
                                    <span className={"error-message"}>{fileUploadErrorMessage}</span>
                                )}
                                {formik.errors.attachment && fileUploadErrorMessage === "" && (
                                    <span className={"error-message"}>{formik.errors.attachment}</span>
                                )}
                            </Form.Group>
                        </Form>
                        {formik.values.attachment && getFileView()}
                    </Stack.Item>
                </Stack>
                <Stack horizontalAlign={"center"}>
                    {formik.isSubmitting ? (
                        <Spinner size={SpinnerSize.large} />
                    ) : (
                        <PrimaryButton
                            iconProps={{ iconName: "Save" }}
                            text={"Save"}
                            onClick={() => formik.handleSubmit()}
                        />
                    )}
                </Stack>

                <Stack horizontal horizontalAlign={"center"} className={"margin-bottom-15"}>
                    {(!formik.isValid || fileUploadErrorMessage) && (
                        <span className={"error-message"}>Please fill in all the mandatory fields</span>
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default ReferenceListAttachmentDetailsView;

import JambarLogo from "../../Images/logo.svg";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import FormikTextInput from "../../CommonComponent/v2/fields/FormikTextInput";
import { PrimaryButton } from "../../CommonComponent/v2/CommonStyle";
import { StyledBackground } from "./Style";
import { useForgotPassword } from "./hook";
import { forgotPasswordInitialValue, forgotPasswordValidation } from "./util";
import { useHistory } from "react-router";
import { linkColor } from "../../../constants/ColorConstant";

const ForgotPassword = () => {
    const { forgotPasswordSubmit, loading } = useForgotPassword();
    const history = useHistory();
    return (
        <StyledBackground container alignItems={"center"} justifyContent={"center"}>
            <Grid p={"30px"} borderRadius={"5px"} minHeight={"50vh"} bgcolor={"#fff"} item xs={11} md={4}>
                <Grid container>
                    <Grid display={"flex"} justifyContent={"center"} alignItems={"center"} item xs={12}>
                        <img width={"300px"} src={JambarLogo} alt={"jambar"} loading="lazy" />
                    </Grid>
                    <Grid item xs={12} mt={"30px"}>
                        <Formik
                            initialValues={forgotPasswordInitialValue}
                            validationSchema={forgotPasswordValidation}
                            onSubmit={forgotPasswordSubmit}
                            enableReinitialize>
                            {({ handleSubmit }) => {
                                return (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <FormikTextInput id={"email"} name={"email"} label={"Email"} fullWidth />
                                        </Grid>

                                        <Grid item xs={12} mt={"20px"}>
                                            <PrimaryButton
                                                startIcon={loading && <CircularProgress size={"20px"} />}
                                                disabled={loading}
                                                onClick={() => handleSubmit()}
                                                fullWidth>
                                                Reset password
                                            </PrimaryButton>
                                        </Grid>
                                        <Grid item xs={12} mt={"20px"}>
                                            <Typography
                                                sx={{
                                                    cursor: "pointer",
                                                    ":hover": {
                                                        textDecoration: "underline"
                                                    }
                                                }}
                                                onClick={() => history.push("/admin/login")}
                                                color={linkColor}
                                                textAlign={"center"}
                                                variant="body2"
                                                mt={"10px"}>
                                                Go back to Login?
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
        </StyledBackground>
    );
};

export default ForgotPassword;

export const AnimalMediumQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "You don't know much about animals",
            imageSrc: "https://i.imgur.com/TpybpVU.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Not bad, you seem to like animals",
            imageSrc: "https://i.imgur.com/zY0KnYy.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Fantastic! You're a true animal lover",
            imageSrc: "https://i.imgur.com/VrqLKw3.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "What type of bird is this?",
            questionImageSrc: "https://i.imgur.com/Jvu4cUG.jpg",
            answerDescription:
                "You might have seen this bird in movies about tropical jungles. This is because toucans are native to the forests of Central and South America.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. peacock",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. toucan",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. cardinal",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Amazon macaw",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "Which animal is the heaviest on Earth?",
            questionImageSrc: "https://i.imgur.com/HkfnWud.jpg",
            answerDescription: "The giant sea creature is also the largest animal on Earth.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. elephant",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. hippopotamus",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. blue whale",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. great white shark",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "What gives flamingos their iconic colour?",
            questionImageSrc: "https://i.imgur.com/aKDftkF.jpg",
            answerDescription:
                "Young flamingos are actually grey in colour, and later develop their pink colour primarily from the algae and shrimp they eat.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. The sun",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Their food",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Their hormones",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Their water sources",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "How many eyes do the vast majority of spiders have?",
            questionImageSrc: "https://i.imgur.com/i1IT4DR.jpg",
            answerDescription:
                "Did you know that spiders cannot turn their heads because they don’t have necks? This is where having many eyes comes in handy.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 32",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 4",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 2",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 8",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "What type of bat feeds on blood?",
            questionImageSrc: "https://i.imgur.com/pEMkQ7g.jpg",
            answerDescription:
                "Vampire bats, named after the fictional blood-sucking creature, are the only type of bats that feed on blood.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. vampire bat",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. fruit bat",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. canyon bat",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. little brown bat",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "The animal that has the longest known gestation period is the ________.",
            questionImageSrc: "https://i.imgur.com/nf9xMPU.jpg",
            answerDescription: "On average elephants are pregnant for around 22 months before they give birth.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. wolf",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. elephant",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. horse",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. donkey",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "What type of animal is this?",
            questionImageSrc: "https://i.imgur.com/d0RuBlK.jpg",
            answerDescription: "Flying squirrels don’t actually fly, but glide from tree to tree.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Rocky",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. gliding rat",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. fruit bat",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. flying squirrel",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Which type of rodent is the largest on Earth? ",
            questionImageSrc: "https://i.imgur.com/W8aIvMi.jpg",
            answerDescription: "Capybaras are herbivores and are found in South America. ",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. chipmunk",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. beaver",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. capybara",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. lemur",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "What is the national bird of France?",
            questionImageSrc: "https://i.imgur.com/ELmqyMt.jpg",
            answerDescription:
                "The Gallic rooster can be seen on the uniforms of various French national sports teams.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. rooster",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. swan",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. eagle",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. albatross",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "Where will you most likely find arboreal animals?",
            questionImageSrc: "https://i.imgur.com/aJ2EgxN.jpg",
            answerDescription:
                "The word arboreal means “relating to trees”. So, monkeys and sloths are examples of arboreal animals.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. in the soil",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. in the sea",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. in trees",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. on the ground",
                    isCorrect: false
                }
            ]
        }
    ]
};

import React from "react";
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType } from "@fluentui/react";

type ConfirmationCloseProps = {
    isShown: boolean;
    warningText: string;
    onDismiss: () => void;
    onConfirm: () => void;
    dialogHeader: string;
};

const ConfirmationClose: React.FC<ConfirmationCloseProps> = ({
    isShown,
    warningText,
    onDismiss,
    onConfirm,
    dialogHeader
}) => {
    const dialogContentProps = {
        type: DialogType.normal,
        title: `${dialogHeader}`,
        closeButtonAriaLabel: "Close",
        subText: `${warningText}`
    };

    return (
        <Dialog hidden={!isShown} onDismiss={onDismiss} dialogContentProps={dialogContentProps}>
            <DialogFooter>
                <PrimaryButton id={"confirmDelete"} onClick={onConfirm} text="Confirm" />
                <DefaultButton id={"cancelDelete"} onClick={onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
};

export default ConfirmationClose;

import { PrimaryButton, Stack } from "@fluentui/react";
import React from "react";
import { ThemedMediumStackTokens } from "../../../constants/Styles";

const GamesView = () => {
    const clapSound = () => {
        const audio = new Audio("/assets/clap.mp3");
        audio.play();
    };

    const cheerSound = () => {
        const audio = new Audio("/assets/cheer.mp3");
        audio.play();
    };

    const wrongSound = () => {
        const audio = new Audio("/assets/wrong.mp3");
        audio.play();
    };

    return (
        <Stack horizontal horizontalAlign="center" tokens={ThemedMediumStackTokens} className={"margin-top-30"}>
            <PrimaryButton text={"Clap"} onClick={clapSound} />
            <PrimaryButton text={"Cheer"} onClick={cheerSound} />
            <PrimaryButton text={"Wrong"} onClick={wrongSound} />
        </Stack>
    );
};

export default GamesView;

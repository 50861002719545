import { ApiConstants } from "../../../../../../constants/ApiConstant";
import { Client } from "../../../../../Base/Client";
import { ReferenceAttachmentList } from "./Types";
import { JambarDateUtil } from "../../../../../../constants/JambarDateUtils";

const transformData = (data: any): ReferenceAttachmentList[] => {
    if (!data) return [];
    return data.map((event: any) => ({
        id: event.id,
        title: event.title,
        createdBy: event.createdByUser.firstName + " " + event.createdByUser.lastName,
        updatedBy: event.updatedByUser.firstName + " " + event.updatedByUser.lastName,
        createdDate: JambarDateUtil.formatDate(event.createdAt),
        lastUpdatedDate: JambarDateUtil.formatDate(event.updatedAt)
    }));
};
export const filterDataBySearchText = (searchText: string, rows: ReferenceAttachmentList[]) =>
    rows.filter(
        (row: ReferenceAttachmentList) =>
            (searchText !== "" && row.title.toLowerCase().includes(searchText.toLowerCase())) ||
            row.createdBy.toLowerCase().includes(searchText.toLowerCase()) ||
            row.createdBy.toLowerCase().includes(searchText.toLowerCase()) ||
            row.id.toString().includes(searchText.toLowerCase())
    );
export const getReferenceAttachmentData = (id: number, forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getReferenceListAttachmentsApiUrl(id), forceRefresh)
        .then((response: any) => Promise.resolve(transformData(response.data.data)))
        .catch((error: any) => Promise.reject(error));

export const onConfirmDelete = (id: number, attachmentId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteReferenceListAttachmentApiUrl(id, attachmentId))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

import { Grid } from "@mui/material";
import ConfirmationBox from "../../../CommonComponent/v2/ConfirmationBox";
import SearchHeader from "../../../CommonComponent/v2/searchheader/SearchHeader";
import { useHistory } from "react-router";
import { useRolesList } from "./Hooks";
import RoleList from "./RoleList";
import { filterDataBySearchText } from "./Utils";
const Roles = (): JSX.Element => {
    const {
        searchText,
        roleList,
        isLoading,
        selectedRoleDeleteId,
        setSearchText,
        onModalClose,
        onRoleDelete,
        setSelectedRoleDeleteId,
        setSelectedRoleRightsId
    } = useRolesList();

    const history = useHistory();
    return (
        <Grid container padding={"20px"}>
            <Grid item xs={12}>
                <SearchHeader
                    searchText={searchText}
                    setSearchText={setSearchText}
                    showAddButton
                    onButtonClick={() => history.push("/admin/v2/Roles")}
                    buttonLabel={"Add new role"}
                />
            </Grid>
            <Grid mt={"30px"} item xs={12}>
                <RoleList
                    roleList={filterDataBySearchText(searchText, roleList)}
                    setSelectedRoleDeleteId={setSelectedRoleDeleteId}
                    setSelectedRoleRightsId={setSelectedRoleRightsId}
                    isLoading={isLoading}
                />
            </Grid>
            {selectedRoleDeleteId && (
                <ConfirmationBox
                    title={`Delete "${selectedRoleDeleteId.name}" role`}
                    message={"Please confirm if you really want to delete this role"}
                    onConfirm={onRoleDelete}
                    onClose={onModalClose}
                />
            )}
        </Grid>
    );
};

export default Roles;

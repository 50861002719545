import { Grid } from "@mui/material";
import React, { FC } from "react";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import { CaptionText, Header2 } from "../../../../../CommonComponent/v2/CommonStyle";
import FormikToggle from "../../../../../CommonComponent/v2/fields/FormikToggle";

type MoreSettingsFormProps = {
    gameType: string;
};
const MoreSettingsForm: FC<MoreSettingsFormProps> = ({ gameType }) => {
    const renderSettingToogle = () => {
        if (gameType === SiteConstants.wildgoat) {
            return (
                <Grid item xs={4}>
                    <FormikToggle
                        id={"moreSettings.totalScoreVisibility"}
                        name="moreSettings.totalScoreVisibility"
                        label="Total score visibility"
                    />
                </Grid>
            );
        }

        return (
            <>
                <Grid item xs={4}>
                    <FormikToggle
                        id={"moreSettings.showProgressInPercentage"}
                        name="moreSettings.showProgressInPercentage"
                        label="Show progress in percentage"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormikToggle
                        id={"moreSettings.showMainTimer"}
                        name="moreSettings.showMainTimer"
                        label="Show main timer"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormikToggle
                        id={"moreSettings.showHintCount"}
                        name="moreSettings.showHintCount"
                        label="Show hint count"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormikToggle
                        id={"moreSettings.showCorrectAnswerCount"}
                        name="moreSettings.showCorrectAnswerCount"
                        label="Show correct answer count"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormikToggle
                        id={"moreSettings.showWrongAnswerCount"}
                        name="moreSettings.showWrongAnswerCount"
                        label="Show wrong answer count"
                    />
                </Grid>
            </>
        );
    };

    return (
        <Grid container mt={"60px"}>
            <Grid item xs={12}>
                <Header2>More settings</Header2>
                <CaptionText mt={"5px"}>
                    These are the options the player would get to answer the challenge (that has been specified in {"‘"}
                    challenge details{"’"}).
                </CaptionText>
            </Grid>
            <Grid item xs={12}>
                <Grid container xs={12} mt={"19px"} spacing={"15px"}>
                    <Grid item xs={4}>
                        <FormikToggle
                            id={"moreSettings.showIndividualScore"}
                            name="moreSettings.showIndividualScore"
                            label="Show individual score"
                        />
                    </Grid>
                    {renderSettingToogle()}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MoreSettingsForm;

import { Box, Dialog, Grid, IconButton } from "@mui/material";
import { FC } from "react";
import { Header1 } from "../../../../CommonComponent/v2/CommonStyle";
import TagsListView from "../../tag";
import { CloseOutlined } from "@mui/icons-material";

type TagModalProps = {
    handleClose: () => void;
    refreshTagList: () => void;
};
const TagModal: FC<TagModalProps> = ({ handleClose, refreshTagList }) => {
    return (
        <Dialog open maxWidth={"lg"} fullWidth onClose={handleClose}>
            <Grid p={"40px"}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box alignItems={"center"} display={"flex"} justifyContent={"space-between"}>
                            <Header1>Manage Tags</Header1>
                            <IconButton onClick={handleClose}>
                                <CloseOutlined />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TagsListView refreshTagList={refreshTagList} />
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default TagModal;

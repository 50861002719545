import { ApiConstants } from "../../../../../../constants/ApiConstant";
import { Client } from "../../../../../Base/Client";
import { SurveyType } from "./Type";

const transformData = (data: any) => {
    if (!data) {
        return [];
    }

    return data.map((item: SurveyType) => ({
        ...item,
        surveyDetails: JSON.parse(item.surveyDetails.toString())
    }));
};

export const getSurveyDetail = (eventId: string) =>
    Client.getInstance()
        .getData(ApiConstants.getEventSurveyDetailApiUrl(parseInt(eventId)), true)
        .then((response) => Promise.resolve(transformData(response.data.data)))
        .catch((error) => Promise.reject(error));

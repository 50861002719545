import { Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import React, { useEffect } from "react";
import { ThemedMediumStackTokens, ThemedSmall2StackTokens } from "../../../../constants/Styles";
import { useFormik } from "formik";
import DatePickerWrapper from "../../../CommonComponent/DatePickerWrapper";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { BulkCodeDetailViewProps, bulkCodeType, BulkEventCodeDetail } from "./utils/type";
import { eventCodeValidation, generateBulkCode } from "./utils/helperFunctions";
import { addBulkEventCode } from "./utils/bulkCodeApi";
import { useAdminContext } from "../../context/AdminAuthContext";

const BulkCodeDetailView: React.FC<BulkCodeDetailViewProps> = ({
    eventId,
    refreshCodeList,
    closePanel,
    eventCodes
}) => {
    const { userDetail } = useAdminContext();
    useEffect(() => {
        userDetail && formik.setFieldValue("generatedBy", `${userDetail.firstName} ${userDetail.lastName}`);
        return;
    }, []);

    const eventCodeInitialValue: BulkEventCodeDetail = {
        numberOfCode: 5,
        endDate: "",
        generatedBy: "",
        startDate: ""
    };

    const formik = useFormik({
        initialValues: eventCodeInitialValue,
        onSubmit: () => onSaveEventCode(),
        validationSchema: eventCodeValidation,
        validateOnMount: true,
        enableReinitialize: true
    });

    const onSaveEventCode = (): void => {
        const bulkCode: bulkCodeType[] = generateBulkCode(
            Number(formik.values.numberOfCode),
            formik.values.startDate,
            formik.values.endDate,
            eventCodes
        );

        addBulkEventCode(eventId, bulkCode)
            .then((response) => {
                toast.success(response, SiteConstants.successToastConfiguration);
                refreshCodeList();
                closePanel();
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                formik.setSubmitting(false);
            });
    };

    return (
        <>
            <Stack
                tokens={ThemedSmall2StackTokens}
                className="section-background section-border padding-10 overflowy-auto">
                <Stack horizontal grow>
                    <Label className={"width-25per padding-right-5"}>Number of codes : </Label>
                    <TextField
                        placeholder="Number of codes"
                        value={formik.values.numberOfCode.toString()}
                        onChange={formik.handleChange}
                        type="number"
                        name={"numberOfCode"}
                    />
                </Stack>
                <Stack horizontal grow>
                    <Label className={"width-20per"}>Generated by : </Label>
                    <Label>{formik.values.generatedBy}</Label>
                </Stack>
                <Stack.Item>
                    <Stack horizontal>
                        <Label className={"width-20per"}>Date Range : </Label>
                        <Stack horizontal tokens={ThemedMediumStackTokens}>
                            <Stack.Item className={"width-35per"}>
                                <DatePickerWrapper
                                    datePickerValue={
                                        formik.values.startDate ? new Date(formik.values.startDate) : undefined
                                    }
                                    onSelectDate={(date) =>
                                        formik.setFieldValue(
                                            "startDate",
                                            JambarDateUtil.dateFormat(date, "YYYY-MM-DD").toString()
                                        )
                                    }
                                    placeholder={"From"}
                                />

                                {formik.errors.endDate && (
                                    <span className={"error-message"}>{formik.errors.startDate}</span>
                                )}
                            </Stack.Item>

                            <Stack.Item className={"width-35per"}>
                                <DatePickerWrapper
                                    datePickerValue={
                                        formik.values.endDate ? new Date(formik.values.endDate) : undefined
                                    }
                                    onSelectDate={(date) =>
                                        formik.setFieldValue(
                                            "endDate",
                                            JambarDateUtil.dateFormat(date, "YYYY-MM-DD").toString()
                                        )
                                    }
                                    placeholder={"To"}
                                />

                                {formik.errors.endDate && (
                                    <span className={"error-message"}>{formik.errors.endDate}</span>
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack>
                </Stack.Item>
            </Stack>

            <Stack className="margin-10" horizontalAlign={"center"}>
                {formik.isSubmitting ? (
                    <Spinner size={SpinnerSize.large} />
                ) : (
                    <PrimaryButton
                        iconProps={{ iconName: "Save" }}
                        text={"Save"}
                        onClick={() => formik.handleSubmit()}
                    />
                )}
            </Stack>
        </>
    );
};

export default BulkCodeDetailView;

import { getRemainingTime } from "../../../../../utils/TimeUtils";
import {
    currentQuestionInfoType,
    hintType,
    QuizUserSessionInfoType
} from "../../RocketTemplateUtils/RocketTemplateTypes";

export const socketCurrentQuestionTransformData = (data: any): currentQuestionInfoType => {
    return {
        ...data,
        mcqOptions: JSON.parse(data.mcqOptions),
        bottomQuestionText: data.questionText2
    };
};

export const socketQuizUserSessionInfoTransformData = (eventTime: string, data: any): QuizUserSessionInfoType => {
    return {
        correctAnswerCount: data?.correctAnswersCount || 0,
        eventTimer: data ? getRemainingTime(eventTime, data.timeTakenInQuiz) : "",
        wrongAnswerCount: data?.wrongAnswersCount || 0,
        hintUsed: data.usedQuestionHintIds === "" ? 0 : data.usedQuestionHintIds?.split(",").length || 0,
        hintsAlreadyUsed: data?.usedQuestionHintIds || "",
        progressId: data?.id || "",
        totalHintUsed: data?.hintsUsedCount || 0,
        totalMarks: data?.quizScore || 0
    };
};

export const socketQuestionHintsTransformData = (data: any): hintType[] => {
    const transformedData: hintType[] = [];
    data.forEach((data: any) => {
        transformedData.push({
            description: data.description,
            questionId: data.id,
            scorePenalty: data.timePenalty ? `${data.timePenalty} secs` : data.scorePenalty
        });
    });
    return transformedData;
};

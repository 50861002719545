import { createContext, useContext, useEffect, useState } from "react";
import { LoginUtil } from "../../../utils/LoginUtils";
import { AdminUserType } from "./Type";
import { getCredit } from "./util";

interface AdminContextType {
    userDetail: AdminUserType | null;
    setUserDetail: (data: AdminUserType) => void;
    onLogout: () => void;
    userCredit: number;
    getCreditInfo: () => void;
}

export const AdminLoginDataContext = createContext<AdminContextType>({
    userDetail: null,
    setUserDetail: () => {
        return;
    },
    onLogout: () => {
        return;
    },
    getCreditInfo: () => {},
    userCredit: 0
});

export const AdminLoginDataProvider = ({ children }: any) => {
    const [userDetail, setUserDetail] = useState<AdminUserType | null>(null);
    const [userCredit, setUserCredit] = useState<number>(0);

    useEffect(() => {
        getCreditInfo();
    }, [userDetail]);

    const getCreditInfo = () => {
        userDetail?.identity &&
            getCredit(userDetail.identity.id.toString()).then((credits) => {
                setUserCredit(credits);
            });
    };

    const onLogout = () => {
        LoginUtil.removeUserSession();
        setUserDetail(null);
        window.location.href = "/admin/login";
    };

    const context = {
        setUserDetail: (data: AdminUserType) => setUserDetail(data),
        userDetail,
        onLogout,
        userCredit,
        getCreditInfo
    };

    return <AdminLoginDataContext.Provider value={context}>{children}</AdminLoginDataContext.Provider>;
};

export const useAdminContext = () => useContext(AdminLoginDataContext);

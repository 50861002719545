import { Grid } from "@mui/material";
import { FC } from "react";
import { CaptionText, CardContainer, IconContainer, SubText } from "../../../../CommonComponent/v2/CommonStyle";
import { FileIconContainer, FileImage, VideoFile } from "./Style";
import DownloadIcon from "../../../../Images/icons/download.svg";
import DeleteIcon from "../../../../Images/icons/delete.svg";
import { FileDataType } from "../Type";
import { JambarDateUtil } from "../../../../../constants/JambarDateUtils";
import { FieldValidation } from "../../../../../constants/FieldValidation";
import { downloadFileFromUrl } from "../../../../../constants/HelperFns";
import { convertBytesToMB } from "./Util";

type FileInfoCardProps = {
    fileInfo: FileDataType;
    handleDelete: () => void;
};

const FileInfoCard: FC<FileInfoCardProps> = ({ fileInfo, handleDelete }) => {
    const { fileName, participantName, participantCode, createdAt, teamName, fileLocation, fileSize } = fileInfo;
    return (
        <CardContainer elevation={0}>
            <Grid container spacing={"15px"}>
                <Grid item xs={5}>
                    {fileInfo.type && !FieldValidation.videoType.includes(fileInfo.type) ? (
                        <FileImage src={fileLocation} />
                    ) : (
                        <VideoFile controls>
                            <source src={fileLocation} />
                        </VideoFile>
                    )}
                </Grid>
                <Grid item xs={7}>
                    <CaptionText textOverflow={"ellipsis"} overflow={"hidden"} mt={"10px"} fontWeight={"bold"}>
                        Name: <SubText>{participantName}</SubText>
                    </CaptionText>
                    <CaptionText textOverflow={"ellipsis"} overflow={"hidden"} mt={"10px"} fontWeight={"bold"}>
                        Code: <SubText>{participantCode}</SubText>
                    </CaptionText>
                    <CaptionText textOverflow={"ellipsis"} overflow={"hidden"} mt={"10px"} fontWeight={"bold"}>
                        File Size: <SubText>{fileSize ? `${convertBytesToMB(fileSize).toFixed(2)} MB` : "-"}</SubText>
                    </CaptionText>
                    <CaptionText textOverflow={"ellipsis"} overflow={"hidden"} mt={"10px"} fontWeight={"bold"}>
                        Created At: <SubText>{JambarDateUtil.dateFormat(new Date(createdAt), "DD/MM/YYYY")}</SubText>
                    </CaptionText>
                    <CaptionText textOverflow={"ellipsis"} overflow={"hidden"} mt={"10px"} fontWeight={"bold"}>
                        Filename: <SubText>{fileName}</SubText>
                    </CaptionText>
                    <CaptionText textOverflow={"ellipsis"} overflow={"hidden"} mt={"10px"} fontWeight={"bold"}>
                        Challenge Name:{" "}
                        <SubText
                            dangerouslySetInnerHTML={{
                                __html: fileInfo.question.title
                            }}
                            className="cardLabel"
                        />
                    </CaptionText>
                    <CaptionText textOverflow={"ellipsis"} overflow={"hidden"} mt={"10px"} fontWeight={"bold"}>
                        Team: <SubText>{teamName}</SubText>
                    </CaptionText>
                    <FileIconContainer>
                        <IconContainer
                            onClick={() => downloadFileFromUrl(fileLocation)}
                            src={DownloadIcon}
                            alt="Download"
                        />
                        <IconContainer onClick={handleDelete} src={DeleteIcon} alt="Download" />
                    </FileIconContainer>
                </Grid>
            </Grid>
        </CardContainer>
    );
};

export default FileInfoCard;

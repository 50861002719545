import { Stack, Icon } from "@fluentui/react";
import { Grid } from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { ThemedMediumStackTokens, ThemedSmall2StackTokens } from "../../../constants/Styles";
import { QUIZ_TIMER_TYPE } from "../../../data/question/types";
import { extractHtmlContent } from "../../../utils/JambarUtils";
import { covertToMMSS } from "../../../utils/TimeUtils";
import Timer from "../../QuizView/Timer";
import { HeaderInfoType } from "./RocketTemplateUtils/RocketTemplateTypes";

type IQuizInfoNativeProps = {
    title: string;
    timing: string;
    getTiming: (time: string) => void;
    hintsUsed: number;
    correctAnswerCount: number;
    wrongAnswerCount: number;
    eventFinishes: boolean;
    totalTimeTakeninEvent: string;
    referenceItemText1: string;
    referenceItemText2: string;
    gameBackgroundColor: string;
    gameInfoBackgroundColor: string;
    quizType: string;
    showInfo: HeaderInfoType;
};

const QuizInfo: React.FC<IQuizInfoNativeProps> = ({
    title,
    timing,
    getTiming,
    hintsUsed,
    correctAnswerCount,
    wrongAnswerCount,
    eventFinishes,
    totalTimeTakeninEvent,
    referenceItemText1,
    referenceItemText2,
    gameBackgroundColor,
    gameInfoBackgroundColor,
    quizType,
    showInfo
}) => {
    const { showCorrectAnswerCount, showHintCount, showMainTimer, showWrongAnswerCount } = showInfo;
    const showReferenceText = useMemo(() => {
        return (
            <>
                {extractHtmlContent(referenceItemText1).length > 2 && (
                    <Grid item xs={6}>
                        <Stack
                            horizontalAlign="center"
                            verticalAlign="center"
                            className={"quiz-score-info info-ops-background-color"}>
                            <div
                                className={"quizQuestionview quill-editor-text-align"}
                                dangerouslySetInnerHTML={{
                                    __html: referenceItemText1
                                }}
                            />
                        </Stack>
                    </Grid>
                )}
                {extractHtmlContent(referenceItemText2).length > 2 && (
                    <Grid item xs={6}>
                        <Stack
                            horizontalAlign="center"
                            verticalAlign="center"
                            className={"quiz-score-info info-flight-background-color"}>
                            <div
                                className={"quizQuestionview quill-editor-text-align"}
                                dangerouslySetInnerHTML={{
                                    __html: referenceItemText2
                                }}
                            />
                        </Stack>
                    </Grid>
                )}
            </>
        );
    }, [referenceItemText1, referenceItemText2]);

    const renderTimer = () => {
        if (timing && totalTimeTakeninEvent === "") {
            return (
                <Stack
                    horizontal
                    tokens={ThemedSmall2StackTokens}
                    horizontalAlign="center"
                    verticalAlign="center"
                    className={"timing"}>
                    <Icon className={"show-on-small-screen"} iconName={"clock"} />
                    <Stack.Item>
                        <Timer
                            type={QUIZ_TIMER_TYPE}
                            timer={timing}
                            getTiming={getTiming}
                            quizType={quizType}
                            eventFinishes={eventFinishes}
                            key={eventFinishes.toString()}
                        />
                        <span className={"hide-on-small-screen"}>Remaining</span>
                    </Stack.Item>
                </Stack>
            );
        }
        return (
            <Stack horizontalAlign="center" verticalAlign="center" className={"timing"}>
                <span className={"font-size-20"}>{totalTimeTakeninEvent && covertToMMSS(totalTimeTakeninEvent)}</span>
            </Stack>
        );
    };

    const renderWrongAnswerCount = () => {
        return (
            <Stack
                horizontal
                tokens={ThemedSmall2StackTokens}
                horizontalAlign="center"
                verticalAlign="center"
                className={"quiz-score-info"}
                style={{ backgroundColor: gameInfoBackgroundColor }}>
                <Stack.Item>
                    <span className={"font-size-20"}>{wrongAnswerCount ? wrongAnswerCount : 0}</span>
                    <span className={"hide-on-small-screen"}>Wrong Answers</span>
                </Stack.Item>
                <span className={"show-on-small-screen"}>Wrong</span>
            </Stack>
        );
    };

    const renderHintCount = () => {
        return (
            <Stack
                horizontal
                tokens={ThemedSmall2StackTokens}
                horizontalAlign="center"
                verticalAlign="center"
                className={"quiz-score-info"}
                style={{ backgroundColor: gameInfoBackgroundColor }}>
                <Stack.Item>
                    <span className={"font-size-20"}>{hintsUsed ? hintsUsed : 0}</span>
                    <span className={"hide-on-small-screen"}>Hints used</span>
                </Stack.Item>
                <span className={"show-on-small-screen"}>Hints</span>
            </Stack>
        );
    };

    const renderCorrectAnswerCount = () => {
        return (
            <Stack
                horizontal
                tokens={ThemedSmall2StackTokens}
                horizontalAlign="center"
                verticalAlign="center"
                className={"quiz-score-info"}
                style={{ backgroundColor: gameInfoBackgroundColor }}>
                <Stack.Item>
                    <span className={"font-size-20"}>{correctAnswerCount ? correctAnswerCount : 0}</span>
                    <span className={"hide-on-small-screen"}>Correct answers</span>
                </Stack.Item>
                <span className={"show-on-small-screen"}>Correct</span>
            </Stack>
        );
    };

    return (
        <Stack
            className={"quiz-info"}
            tokens={ThemedMediumStackTokens}
            style={{ backgroundColor: gameBackgroundColor }}>
            <Stack
                style={{ backgroundColor: gameInfoBackgroundColor }}
                className={"quiz-title"}
                horizontalAlign="center"
                verticalAlign="center">
                <span
                    className={"margin-p-0 quill-editor-text-align"}
                    dangerouslySetInnerHTML={{
                        __html: title
                    }}
                />
            </Stack>
            <Grid container spacing={1}>
                {!!showMainTimer && (
                    <Grid item xs={6}>
                        {renderTimer()}
                    </Grid>
                )}
                {!!showWrongAnswerCount && (
                    <Grid item xs={6}>
                        {renderWrongAnswerCount()}
                    </Grid>
                )}
                {!!showHintCount && (
                    <Grid item xs={6}>
                        {renderHintCount()}
                    </Grid>
                )}
                {!!showCorrectAnswerCount && (
                    <Grid item xs={6}>
                        {renderCorrectAnswerCount()}
                    </Grid>
                )}
                {showReferenceText}
            </Grid>
        </Stack>
    );
};

export default QuizInfo;

import { DefaultButton, Stack, Spinner, SpinnerSize } from "@fluentui/react";
import { FC } from "react";
import { Form } from "react-bootstrap";
import { UPLOAD_VIDEO } from "../../../../data/question/types";
import { youtubeParser } from "../../../../utils/JambarUtils";
import TextFieldWrapper from "../../../CommonComponent/TextFieldWrapper";

type QuestionVideoSectionProps = {
    selectedVideoType: string;
    videoQuestion: string;
    handleVideoFileChange: (file: any) => void;
    videoUploadErrorMessage: string;
    isRemovingVideoQuestion: boolean;
    removeVideoQuestion: () => void;
    handleYoutubeVideoFileChange: (text: string) => void;
    youtubeVideoText: string;
};
const QuestionVideoSection: FC<QuestionVideoSectionProps> = ({
    selectedVideoType,
    videoQuestion,
    handleVideoFileChange,
    videoUploadErrorMessage,
    isRemovingVideoQuestion,
    removeVideoQuestion,
    handleYoutubeVideoFileChange,
    youtubeVideoText
}) => {
    if (selectedVideoType === UPLOAD_VIDEO) {
        return (
            <>
                <Stack className={"margin-top-10"} horizontal grow>
                    <Stack.Item grow>
                        <Form.Group>
                            <Form.File
                                value={videoQuestion === "" ? "" : undefined}
                                onChange={(event: any) => handleVideoFileChange(event)}
                            />
                            {videoUploadErrorMessage && (
                                <span className={"error-message"}>{videoUploadErrorMessage}</span>
                            )}
                        </Form.Group>
                    </Stack.Item>

                    <Stack horizontalAlign={"end"} verticalAlign={"start"}>
                        {isRemovingVideoQuestion ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : (
                            <DefaultButton
                                onClick={removeVideoQuestion}
                                className={"dashed-border-default-button"}
                                text={"Remove Video"}
                            />
                        )}
                    </Stack>
                </Stack>
                {videoQuestion && (
                    <video width="320" height="240" controls>
                        <source src={videoQuestion} />
                    </video>
                )}
            </>
        );
    }

    return (
        <>
            <Stack className={"margin-top-10"} grow>
                <Stack.Item grow>
                    <TextFieldWrapper
                        value={youtubeVideoText}
                        onTextFieldValueChange={(text: string) => handleYoutubeVideoFileChange(text)}
                    />
                </Stack.Item>
                <Stack.Item className={"margin-top-10"}>
                    {youtubeVideoText && (
                        <iframe
                            allowFullScreen
                            allow="accelerometer"
                            width="320"
                            height="240"
                            src={`https://www.youtube.com/embed/${youtubeParser(youtubeVideoText)}`}></iframe>
                    )}
                </Stack.Item>
            </Stack>
        </>
    );
};

export default QuestionVideoSection;

import Styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const UserRoleContainer = Styled(Grid)`
    background: #FCFCFC;
    box-shadow: 0px 0px 65px rgba(0, 0, 0, 0.012037), 0px 0px 38.0671px rgba(0, 0, 0, 0.0196296), 0px 0px 20.7037px rgba(0, 0, 0, 0.025), 0px 0px 10.5625px rgba(0, 0, 0, 0.0303704), 0px 0px 5.2963px rgba(0, 0, 0, 0.037963), 0px 0px 2.55787px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 16px 20px;
`;

export const UserRoleCard = Styled(Grid)`
    background: #E6EEf5;
    border-radius: 10px;
    padding: 16px 20px;
    display: flex;
    gap: 20px;
`;

import { Label } from "@fluentui/react";
import React from "react";
import { Image } from "react-bootstrap";
import doc from "../../Images/doc.svg";
import pdf from "../../Images/pdf.svg";
import gif from "../../Images/gif.svg";
import image from "../../Images/jpg.svg";
import mov from "../../Images/mov2.svg";
import html from "../../Images/html.svg";
import txt from "../../Images/txt.svg";
import log from "../../Images/log.svg";
import eml from "../../Images/eml.svg";
import { attachmentType } from "./RocketTemplateUtils/RocketTemplateTypes";
import { FieldValidation } from "../../../constants/FieldValidation";
import { ScrollViewContainer } from "./style";

//TODO - remove I.
type IQuizDocumentNativeProps = {
    attachmentType?: attachmentType[];
    selectedDocumentType: (attachmentUrl: string, fileType: string) => void;
    attachmentTitle?: string;
    gameBackgroundColor?: string;
};

const QuizDocument: React.FC<IQuizDocumentNativeProps> = ({
    attachmentType,
    selectedDocumentType,
    attachmentTitle,
    gameBackgroundColor
}) => {
    const getDocumentLogoTitle = (
        fileLocation: string,
        id: number,
        title: string,
        fileType: string,
        logoType: string
    ): JSX.Element => {
        return (
            <div className={"document-section-items"} key={id}>
                <Image src={logoType} key={id} onClick={() => selectedDocumentType(fileLocation, fileType)} />
                <span>{title}</span>
            </div>
        );
    };

    //TODO - move functions out of component
    const getDocumentImage = (fileType: string, fileLocation: string, id: number, title: string): JSX.Element => {
        if (FieldValidation.docTypes.includes(fileType)) {
            return getDocumentLogoTitle(fileLocation, id, title, fileType, doc);
        }
        if (FieldValidation.pdfType.includes(fileType)) {
            return getDocumentLogoTitle(fileLocation, id, title, fileType, pdf);
        }
        if (FieldValidation.imageTypes.includes(fileType)) {
            return getDocumentLogoTitle(fileLocation, id, title, fileType, image);
        }
        if (FieldValidation.gifType.includes(fileType)) {
            return getDocumentLogoTitle(fileLocation, id, title, fileType, gif);
        }
        if (FieldValidation.videoType.includes(fileType)) {
            return getDocumentLogoTitle(fileLocation, id, title, fileType, mov);
        }
        if (FieldValidation.htmlType.includes(fileType)) {
            return getDocumentLogoTitle(fileLocation, id, title, fileType, html);
        }
        if (FieldValidation.textType.includes(fileType)) {
            return getDocumentLogoTitle(fileLocation, id, title, fileType, txt);
        }
        if (FieldValidation.logType.includes(fileType)) {
            return getDocumentLogoTitle(fileLocation, id, title, fileType, log);
        }

        return getDocumentLogoTitle(fileLocation, id, title, fileType, eml);
    };

    return (
        <ScrollViewContainer className={"quiz-document-section"} style={{ backgroundColor: gameBackgroundColor }}>
            <Label className={"attachment-title margin-left-10"}>
                {attachmentTitle ? attachmentTitle : "Ship Manuals"}
            </Label>
            <div className={"document-section-container"}>
                {attachmentType &&
                    attachmentType.map((item: attachmentType) =>
                        getDocumentImage(item.fileType, item.fileLocation, item.id, item.fileTitle)
                    )}
            </div>
        </ScrollViewContainer>
    );
};

export default QuizDocument;

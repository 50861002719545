import { Stack } from "office-ui-fabric-react";
import React from "react";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import { CONNECTOR, MCQAnswerOption, QUESTION_TIMER_TYPE } from "../../../../data/question/types";
import Timer from "../../../QuizView/Timer";
import { QuestionConnector } from "../../RocketTemplate/RocketTemplateUtils/RocketTemplateTypes";
import MiniEventAnswerView from "./MiniEventAnswerView";

type MiniEventConnectorViewProps = {
    description: string;
    updateAnswerText: (answer: string) => void;
    options?: MCQAnswerOption[];
    type: string;
    goToNextQuestion: (answerSubmitted: boolean, userAttemptType: string, quizType?: string) => void;
    showContinueButton: boolean;
    questionTimeEnds: boolean;
    questionConnectors: QuestionConnector[];
    saveProgressForConnectorQuestion: (quizId: number) => void;
    completedConnector: string[];
    coverImage: string;
    bottomQuestionText: string;
    documentSelected: (coverImage: string) => void;
    nextButtonText?: string;
    timeLimit: string;
    getTiming: (time: string) => void;
    hideTimer: boolean;
    setHintDisable: (hintUseType: boolean) => void;
    gameBackgroundColor: string;
    optionsColor: string;
    hideConnectorTimer: boolean;
};

const MiniEventConnectorView: React.FC<MiniEventConnectorViewProps> = ({
    updateAnswerText,
    goToNextQuestion,
    showContinueButton,
    questionTimeEnds,
    questionConnectors,
    saveProgressForConnectorQuestion,
    completedConnector,
    description,
    bottomQuestionText,
    coverImage,
    documentSelected,
    nextButtonText,
    timeLimit,
    getTiming,
    hideTimer,
    setHintDisable,
    gameBackgroundColor,
    optionsColor,
    hideConnectorTimer
}) => {
    return (
        <Stack
            style={{ backgroundColor: gameBackgroundColor }}
            className={hideTimer ? "refrence-view" : " refrence-view padding-top-0"}
            tokens={ThemedMediumStackTokens}>
            {!hideTimer && (
                <Stack horizontalAlign={"end"}>
                    <div className={!hideConnectorTimer ? "display-none" : "question-Timing"}>
                        <Timer
                            type={QUESTION_TIMER_TYPE}
                            timer={timeLimit}
                            getTiming={getTiming}
                            key={"quizTimer"}
                            eventFinishes={false}
                        />
                    </div>
                </Stack>
            )}
            <div
                className={
                    "connector-question-text quizQuestionview quill-editor-text-align margin-p-0 image-width-100per"
                }
                dangerouslySetInnerHTML={{
                    __html: description
                }}
            />
            <Stack horizontalAlign={"center"}>
                <img
                    src={coverImage}
                    className={"max-width-400 cursor"}
                    alt={""}
                    onClick={() => coverImage && documentSelected(coverImage)}
                />
            </Stack>
            {bottomQuestionText && (
                <div
                    className={
                        "connector-question-text quizQuestionview quill-editor-text-align margin-p-0 image-width-100per"
                    }
                    dangerouslySetInnerHTML={{
                        __html: bottomQuestionText
                    }}
                />
            )}
            <MiniEventAnswerView
                updateAnswerText={(answer: string) => {
                    updateAnswerText(answer);
                }}
                type={CONNECTOR}
                goToNextQuestion={goToNextQuestion}
                showSkipButton={showContinueButton}
                questionTimeEnds={questionTimeEnds}
                questionConnectors={questionConnectors}
                saveProgressForConnectorQuestion={saveProgressForConnectorQuestion}
                completedConnector={completedConnector}
                nextButtonText={nextButtonText}
                setHintDisable={setHintDisable}
                optionColor={optionsColor}
            />
        </Stack>
    );
};

export default MiniEventConnectorView;

import React, { useState } from "react";
import {
    CircleButtonBig,
    InfoText,
    WildGoatsContainer,
    QuestionBox,
    MainText,
    WildGoatsCenterContainer,
    FullWidthImage,
    ScrollBox,
    CircleButtonSmall,
    QuestionDetailBox,
    CustomInputBox
} from "../CommonStyledComponents/CommonStyle";

import { linkColor, whiteColor } from "../../../../constants/Styles";
import { getOffsetColor } from "../Util/Util";
import { Icon } from "@fluentui/react";
import teamImage from "../../../Images/Wildgoat/teamImage.png";
import fullScreenIcon from "../../../Images/Wildgoat/fullScreenIcon.svg";
import stopWatchIcon from "../../../Images/Wildgoat/stopWatch.svg";
import {
    IconContainer,
    HeadPointsContainer,
    StarPointsContainer,
    FullScreenIconImage,
    GreyCircle,
    FlexBox,
    StopWatchContainer,
    PointsText,
    StickyContainer
} from "../QuestionTypeView/Style";
import { InputGroup } from "react-bootstrap";

const TeamNameQuestion = () => {
    const [backgroundColor] = useState<string>(linkColor);
    const [teamName, setTeamName] = useState<string>("");

    return (
        <WildGoatsContainer bgcolor={backgroundColor} bgcolor_offset={getOffsetColor(backgroundColor)}>
            <WildGoatsCenterContainer>
                <QuestionBox backgroundColor={""} style={{ marginBottom: 18 }}>
                    <ScrollBox>
                        <StickyContainer>
                            <div style={{ position: "relative" }}>
                                <FullWidthImage src={teamImage} alt="teamImage" />
                                <HeadPointsContainer>
                                    <PointsText>832 team points</PointsText>
                                </HeadPointsContainer>
                                <StarPointsContainer>
                                    <Icon iconName={"FavoriteStarFill"} style={{ fontSize: 15 }} />
                                    <span className="margin-left-10">25 points</span>
                                </StarPointsContainer>
                                <IconContainer bottom_pos="10%">
                                    <GreyCircle>
                                        <FullScreenIconImage src={fullScreenIcon} alt="fullscreenIcon" />
                                    </GreyCircle>
                                </IconContainer>
                            </div>
                        </StickyContainer>
                        <QuestionDetailBox>
                            <FlexBox>
                                <MainText>Team name</MainText>
                                <div>
                                    <StopWatchContainer className="margin-left-10">
                                        <img src={stopWatchIcon} alt="stopWatchIcon" />
                                        <div className="margin-left-10">
                                            <MainText>1:58</MainText>
                                        </div>
                                    </StopWatchContainer>
                                </div>
                            </FlexBox>
                            <InfoText style={{ marginBottom: 15 }}>
                                Come up with a team name. The name has to represent your team spirit!
                            </InfoText>
                            <InputGroup size="sm" className={"margin-top-15"}>
                                <CustomInputBox
                                    placeholder={"Type here..."}
                                    value={teamName}
                                    onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                                        setTeamName(target.value)
                                    }
                                />
                            </InputGroup>
                            <CircleButtonBig>
                                <Icon iconName={"ChevronRight"} style={{ fontSize: 20 }} />
                            </CircleButtonBig>
                            <CircleButtonSmall>
                                <Icon iconName={"globalnavbutton"} style={{ fontSize: 20, color: whiteColor }} />
                            </CircleButtonSmall>
                        </QuestionDetailBox>
                    </ScrollBox>
                </QuestionBox>
            </WildGoatsCenterContainer>
        </WildGoatsContainer>
    );
};

export default TeamNameQuestion;

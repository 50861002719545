import { Checkbox, Dialog, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { FC, useState } from "react";
import { CancelButton, Header1, PrimaryButton } from "../../../CommonComponent/v2/CommonStyle";
import { leaderboardFields } from "./Util";

type ManageColumnModalProps = {
    handleClose: () => void;
    includeColumns: string[];
    setIncludeColumns: (includeColumns: string[]) => void;
    saveColumns: (columns: string[]) => void;
};
const ManageColumnModal: FC<ManageColumnModalProps> = ({ handleClose, includeColumns, saveColumns }) => {
    const [columns, setColumns] = useState<string[]>(includeColumns);

    return (
        <Dialog open maxWidth={"md"} fullWidth onClose={handleClose}>
            <Grid p={"40px"}>
                <Header1 textAlign={"center"}>Manage columns</Header1>
                <Grid mt={"40px"} container>
                    {leaderboardFields.map((item) => (
                        <Grid item xs={4} key={item.id}>
                            <FormGroup>
                                <FormControlLabel
                                    sx={{
                                        "& .MuiTypography-root": {
                                            fontSize: "18px"
                                        }
                                    }}
                                    control={
                                        <Checkbox
                                            onChange={() => {
                                                !columns.includes(item.id)
                                                    ? setColumns([item.id, ...columns])
                                                    : setColumns(columns.filter((element) => element !== item.id));
                                            }}
                                            checked={!!columns.find((element) => element === item.id)}
                                        />
                                    }
                                    label={item.label}
                                />
                            </FormGroup>
                        </Grid>
                    ))}
                </Grid>
                <Grid container mt={"40px"} spacing={"30px"} justifyContent={"center"}>
                    <CancelButton fontsize="16px" onClick={handleClose}>
                        Cancel
                    </CancelButton>
                    <PrimaryButton
                        fontsize="16px"
                        onClick={() => saveColumns(columns)}
                        style={{ padding: "16px 24px", marginLeft: 10 }}>
                        Update columns
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ManageColumnModal;

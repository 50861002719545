import { Dialog, Grid } from "@mui/material";
import { FC } from "react";
import { Header1 } from "../../../CommonComponent/v2/CommonStyle";
import LeaderboardList from "./LeaderboardList";
import { LeaderBoardEventListItem } from "./Type";
import { useLeaderBoardDialog } from "./Hooks";

type LeaderboardDialogProps = {
    handleClose: () => void;
    selectedEvent: LeaderBoardEventListItem;
    selectedTab: string;
};
const LeaderboardDialog: FC<LeaderboardDialogProps> = ({ handleClose, selectedEvent, selectedTab }) => {
    const { eventLink } = useLeaderBoardDialog(selectedEvent.eventId.toString());
    return (
        <Dialog open={true} maxWidth={"lg"} fullWidth onClose={handleClose}>
            <Grid p={"40px"}>
                <Header1 textAlign={"center"}>{selectedEvent.eventName} Leaderboard</Header1>
            </Grid>
            <Grid p={"40px"}>
                <LeaderboardList
                    key={eventLink}
                    eventLink={eventLink}
                    hideCurrentQuestion
                    eventId={selectedEvent.eventId.toString()}
                    isEventLive={selectedTab === "active"}
                />
            </Grid>
        </Dialog>
    );
};

export default LeaderboardDialog;

import { Grid } from "@mui/material";
import { FC } from "react";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import LoadingWrapper from "../../../CommonComponent/v2/LoadingWrapper";
import { useLeaderboardListHook } from "./Hooks";
import ManageColumnModal from "./ManageColumnModal";
import SearchHeader from "../../../CommonComponent/v2/searchheader/SearchHeader";
import { getLeaderboardListFilteredItems } from "./Util";
import QuestionInfoTooltip from "./QuestionInfoTooltip";

type LeaderboardListProps = {
    eventId: string;
    isEventLive: boolean;
    eventLink?: string;
    hideCurrentQuestion?: boolean;
};
const LeaderboardList: FC<LeaderboardListProps> = ({ eventId, isEventLive, eventLink, hideCurrentQuestion }) => {
    const {
        setShowManageColumnDialog,
        showManageColumnDialog,
        leaderBoardlist,
        isContentLoading,
        filteredColumns,
        setFilteredColumns,
        includeColumns,
        setIncludeColumns,
        initializeLeaderBoardColumns,
        searchText,
        setSearchText,
        sortedLeaderBoardDetails,
        handleQuestionSelect,
        handleCloseQuestionModal,
        showQuestionDialog
    } = useLeaderboardListHook(eventId, isEventLive, hideCurrentQuestion);

    const saveColumns = (columns: string[]) => {
        setShowManageColumnDialog(false);
        setFilteredColumns(
            initializeLeaderBoardColumns(handleQuestionSelect).filter((element) => columns.includes(element.field))
        );
        setIncludeColumns(columns);
        localStorage.setItem("LeaderBoardListColumns", JSON.stringify(columns));
    };

    return (
        <Grid>
            <Grid mb={"20px"} container>
                <Grid item xs={12}>
                    <SearchHeader
                        searchText={searchText}
                        setSearchText={setSearchText}
                        searchPlaceholder={"Search leaderboard"}
                        showManageColumnButton={() => setShowManageColumnDialog(true)}
                    />
                </Grid>
            </Grid>
            <LoadingWrapper
                emptyTileLabel="No Data Found"
                isLoading={isContentLoading}
                loadingHeight="300px"
                isNoItem={leaderBoardlist.length === 0}>
                <Grid height={"calc(100vh - 390px)"}>
                    <DataGridWrapper
                        columns={filteredColumns}
                        rows={getLeaderboardListFilteredItems(sortedLeaderBoardDetails, searchText)}
                        loading={isContentLoading}
                    />
                </Grid>
            </LoadingWrapper>
            {showQuestionDialog && (
                <QuestionInfoTooltip
                    particpantCode={showQuestionDialog}
                    eventLink={eventLink || ""}
                    handleClose={handleCloseQuestionModal}
                />
            )}
            {showManageColumnDialog && (
                <ManageColumnModal
                    handleClose={() => setShowManageColumnDialog(false)}
                    includeColumns={includeColumns}
                    setIncludeColumns={(items) => {
                        setIncludeColumns(items);
                    }}
                    saveColumns={saveColumns}
                />
            )}
        </Grid>
    );
};

export default LeaderboardList;

import Styled from "@emotion/styled";
import { Box } from "@mui/material";

export const CodeTabContainer = Styled(Box)`
    & .MuiTab-root {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: #828282;
        text-transform: capitalize;
        border: 1px solid #C2C2C2;
        outline: none !important;
    }
    & .Mui-selected {
        background: #E6EEF5;
        color: #1F71B6;
    }

    & .MuiTabs-indicator {
        display: none;
    }
`;

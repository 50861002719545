import { HelperFunctions } from "../../../constants/HelperFns";
import { correctAnswerBackgroundColor, wrongAnswerBackgroundColor } from "../../../constants/Styles";
import { CONNECTOR } from "../../../data/question/types";
import { extractHtmlContent } from "../../../utils/JambarUtils";
import { getRemainingTime } from "../../../utils/TimeUtils";
import {
    attachmentType,
    currentQuestionInfoType,
    hintType,
    QuizConfigType,
    QuizInfoType,
    QuizUserSessionInfoType
} from "./RocketTemplateUtils/RocketTemplateTypes";

export const CurrentQuestionTransformData = (data: any): currentQuestionInfoType => ({
    ...data,
    mcqOptions: JSON.parse(data.mcqOptions),
    questionConnector: data.type === CONNECTOR ? data.questionConnector : [],
    bottomQuestionText: data.questionText2,
    referenceItemText1: data.referenceItemText1 || "",
    referenceItemText2: data.referenceItemText2 || ""
});

export const QuizInfoTransformData = (response: any): QuizInfoType => {
    const data = response.data.data;
    return {
        correctAnswerPoints: data.quiz.correctPoints || "0",
        totalPointsText: data.quiz?.totalPointsText || "",
        eventLogo: data.event.logo || "",
        summaryPageInfo: data.quiz.summaryPageText || "",
        title: data.quiz.title || "",
        wrongAnswerPoints: data.quiz.wrongPoints || "0",
        quizAllowSkipping: data.quiz.allowSkipping !== 0,
        initialEventTimer: data.quiz.timeLimit || "",
        nextButtonText:
            data.quiz.nextButtonText !== "null" && data.quiz.nextButtonText ? data.quiz.nextButtonText : "Continue",
        answerResultImage: data.quiz.answerResultImageFileLocation || "",
        answerResultText: data.quiz.answerResultText || "",
        showProgressInPercentage: !!data.quiz.showProgressInPercentage,
        companyLogo: data.event.companyLogo || "",
        showSurveyForm: data.event.showSurveyAtEnd || false,
        quizTitle: data.quiz.displayTitle || data.quiz.title,
        showCorrectAnswerCount: data.quiz.showCorrectAnswerCount,
        showWrongAnswerCount: data.quiz.showWrongAnswerCount,
        showMainTimer: data.quiz.showMainTimer,
        showHintCount: data.quiz.showHintCount
    };
};

export const QuizConfigTransformData = (response: any): QuizConfigType => {
    const data = response.data.data.quiz;
    return {
        attachmentTitle:
            data.quizConfig && data.quizConfig.attachmentTitle !== "null" && data?.quizConfig.attachmentTitle
                ? data.quizConfig.attachmentTitle
                : "",
        backgroundImage: data?.quizConfig?.backgroundImageFileLocation || "",
        progressImage: data?.quizConfig?.progressImageFileLocation || "",
        progressTitle: data?.quizConfig?.progressTitle || "",
        backgroundColor: data?.quizConfig?.baseColor || "",
        buttonColor: data?.quizConfig?.baseButtonColor || "",
        correctAnswerBackgroundColor: data?.quizConfig?.correctAnswerBackgroundColor || correctAnswerBackgroundColor,
        wrongAnswerBackgroundColor: data?.quizConfig?.wrongAnswerBackgroundColor || wrongAnswerBackgroundColor,
        tabTitle:
            data.quizConfig && data.quizConfig.browserTabTitle
                ? response.data.data.quiz.quizConfig.browserTabTitle
                : extractHtmlContent(response.data.data.quiz.title)
    };
};

export const QuizUserSessionInfoTransformData = (eventTime: string, response: any): QuizUserSessionInfoType => {
    const data = response.data.data.userQuizSessionInfo;
    return {
        correctAnswerCount: data?.correctAnswersCount || 0,
        eventTimer: data ? getRemainingTime(eventTime, data.timeTakenInQuiz) : "",
        wrongAnswerCount: data?.wrongAnswersCount || 0,
        hintUsed: data.usedQuestionHintIds === "" ? 0 : data.usedQuestionHintIds?.split(",").length || 0,
        hintsAlreadyUsed: data?.usedQuestionHintIds || "",
        progressId: data?.id || "",
        totalHintUsed: data?.hintsUsedCount || 0,
        totalMarks: data?.quizScore || 0
    };
};

export const QuizAttachmentTransformData = (response: any): attachmentType[] => {
    const transformedData: attachmentType[] = [];
    const data = response.data.data.quiz.quiz_attachments;
    data.forEach((data: any) => {
        transformedData.push({
            id: Math.random(),
            fileLocation: data.fileLocation,
            fileType: HelperFunctions.getFileType(data.fileName),
            fileTitle: data.title
        });
    });
    return transformedData;
};

export const QuestionHintsTransformData = (response: any): hintType[] => {
    const transformedData: hintType[] = [];
    response.data.data.nextQuestionInfo.questionHint.forEach((data: any) => {
        transformedData.push({
            description: data.description,
            questionId: data.id,
            scorePenalty: data.scorePenalty
        });
    });
    return transformedData;
};

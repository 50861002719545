import React, { useCallback } from "react";
import {
    ArrowForwardIconWrapper,
    FlexBox,
    FullScreenIconImage,
    FullScreenImage,
    FullScreenImageContainer,
    GreyCircle,
    HeadPointsContainer,
    IconContainer,
    OptionBox,
    PointsText,
    QuestionImage,
    QuestionImageContainer,
    StarPointsContainer,
    StickyContainer,
    StopWatchContainer,
    StyledReactPlayer
} from "../../../WildGoat/QuestionTypeView/Style";
import resizeIcon from "../../../../Images/Wildgoat/resize.svg";
import {
    CircleButtonBig,
    CircleButtonSmall,
    CircleSkipButton,
    CustomInputBox,
    InfoText,
    MainText,
    QuestionBox,
    QuestionDetailBox,
    ScrollBox
} from "../../../WildGoat/CommonStyledComponents/CommonStyle";
import { Icon } from "@fluentui/react";
import stopWatchIcon from "../../../../Images/Wildgoat/stopWatch.svg";
import Timer from "../../../../QuizView/Timer";
import { CONNECTOR, FILE_UPLOAD, MCQ, QUIZ_TIMER_TYPE } from "../../../../../data/question/types";
import { whiteColor } from "../../../../WebsiteGame/colorConstant";
import { Drawer } from "@mui/material";
import SideBar from "../../../WildGoat/SideBar/SideBar";
import SkipImage from "../../../../Images/Wildgoat/Skip.svg";
import { usePathGameQuestionSection } from "./hook";
import { McqLoading, SelectedButton, UnSelectedButton } from "../../../../../constants/SiteConstant";
import {
    ConnectorQuestionInfoTypes,
    CurrentQuestionInfoType,
    QuestionConnector,
    QuizInfoType,
    QuizUserSessionInfoType
} from "../type";
import {
    pathQuizPrimaryColor,
    pathQuizSecondaryColor,
    pathQuizTertiaryColor
} from "../../../../../constants/ColorConstant";
import loadingGif from "../../../../Images/loading.gif";
import fullScreenIcon from "../../../../Images/Wildgoat/fullScreenIcon.svg";
import { youtubeParser } from "../../../../../utils/JambarUtils";
import PhotoQuestion from "../../../WildGoat/PhotoQuestion/PhotoQuestion";
import { InputGroup } from "react-bootstrap";
import PathGameDialog from "./PathGameDialog";

const dummyConnectior: QuestionConnector[] = [
    {
        id: 37541,
        quizId: 4631,
        isClosable: false,
        optionText: "Sports"
    },
    {
        id: 37551,
        quizId: 4641,
        isClosable: false,
        optionText: "Geography"
    },
    {
        id: 37561,
        quizId: 4651,
        isClosable: false,
        optionText: "History"
    }
];
type PathGameQuestionAnswerSectionProps = {
    currentQuestionInfo: CurrentQuestionInfoType;
    participantAnswer: string;
    isFileUploading: boolean;
    quizInfoData: QuizInfoType;
    questionTiming: string;
    setQuestionTiming: (time: string) => void;
    saveProgress: (isSkipped?: boolean) => void;
    quizUserSessionInfoData: QuizUserSessionInfoType;
    updateParticipantAnswer: (answer: string) => void;
    uploadFile: (file: File[]) => void;
    uploadedPercentage: number;
    isFileUploadFailed: boolean;
    connectorQuestionSubmit: (connectorOpt: QuestionConnector) => void;
    connectorQuestionInfo: ConnectorQuestionInfoTypes;
    getEventTiming: (time: string) => void;
    getQuestionTiming: (time: string) => void;
    getConnectorTiming: (time: string) => void;
    eventTiming: string;
    connectorTiming: string;
    eventFinishes: boolean;
};
const PathGameQuestionAnswerSection = ({
    currentQuestionInfo,
    participantAnswer,
    isFileUploading,
    quizInfoData,
    questionTiming,
    setQuestionTiming,
    saveProgress,
    quizUserSessionInfoData,
    updateParticipantAnswer,
    uploadFile,
    uploadedPercentage,
    isFileUploadFailed,
    connectorQuestionSubmit,
    connectorQuestionInfo,
    getConnectorTiming,
    getEventTiming,
    getQuestionTiming,
    eventTiming,
    connectorTiming,
    eventFinishes
}: PathGameQuestionAnswerSectionProps) => {
    const {
        files,
        fullScreenImage,
        isAnswerSubmitting,
        openDrawer,
        selectedOption,
        setDrawerContent,
        setFiles,
        setFullScreenImage,
        setIsAnswerSubmitting,
        setOpenDrawer,
        setSkipQuestion,
        setSubmitQuestion,
        skipQuestion,
        submitQuestion,
        answerSelected,
        updateSelectedQuestionConnector,
        selectedConnectorQuestion
    } = usePathGameQuestionSection(participantAnswer, updateParticipantAnswer);

    const getButtonType = useCallback(() => {
        if (isAnswerSubmitting) {
            return McqLoading;
        }
        if (currentQuestionInfo.type === FILE_UPLOAD) {
            if (files.length === 0 || isFileUploading) {
                return UnSelectedButton;
            }
            return SelectedButton;
        }
        if (participantAnswer === "") {
            return UnSelectedButton;
        }
        return SelectedButton;
    }, [isAnswerSubmitting, currentQuestionInfo.type, participantAnswer, files.length, isFileUploading]);

    const renderFullScreenImage = () => {
        if (currentQuestionInfo.videoQuestionFileLocation || !currentQuestionInfo.fileLocation) {
            return <></>;
        }
        if (currentQuestionInfo.fileLocation) {
            return (
                <IconContainer bottom_pos="10%" onClick={() => setFullScreenImage(true)}>
                    <GreyCircle>
                        <FullScreenIconImage src={fullScreenIcon} alt="fullscreenIcon" />
                    </GreyCircle>
                </IconContainer>
            );
        }
        return <></>;
    };

    const renderFile = () => {
        if (
            !currentQuestionInfo.fileLocation &&
            !currentQuestionInfo.videoQuestionFileLocation &&
            !currentQuestionInfo.youtubeVideoLink
        ) {
            return <></>;
        }
        if (currentQuestionInfo.youtubeVideoLink) {
            return (
                <StyledReactPlayer
                    url={`https://www.youtube.com/watch?v=${youtubeParser(currentQuestionInfo.youtubeVideoLink)}`}
                    width="100%"
                    height="100%"
                    muted
                    controls
                    playing
                />
            );
        }
        if (currentQuestionInfo.videoQuestionFileLocation) {
            return (
                <video muted width="100%" playsInline style={{ borderRadius: 5 }} autoPlay controls>
                    <source src={currentQuestionInfo.videoQuestionFileLocation} />
                </video>
            );
        }
        if (currentQuestionInfo.fileLocation) {
            return <QuestionImage src={currentQuestionInfo.fileLocation} alt="lockImage" />;
        }
    };

    const getQuestionFieldView = () => {
        if (currentQuestionInfo.type === FILE_UPLOAD) {
            return (
                <PhotoQuestion
                    files={files}
                    setFiles={setFiles}
                    uploadFiles={uploadFile}
                    primaryColor={pathQuizPrimaryColor}
                    uploadedPercentage={uploadedPercentage}
                    isFileUploadFailed={isFileUploadFailed}
                />
            );
        }
        if (currentQuestionInfo.type === "Conector123") {
            return currentQuestionInfo.questionConnector.map((option) => (
                <OptionBox
                    onClick={() => updateSelectedQuestionConnector(option)}
                    style={{
                        pointerEvents: connectorQuestionInfo.playedConnectorOptionQuizIds.includes(
                            option.quizId.toString()
                        )
                            ? "none"
                            : "all"
                    }}
                    background={
                        connectorQuestionInfo.playedConnectorOptionQuizIds.includes(option.quizId.toString())
                            ? "gray"
                            : selectedConnectorQuestion && selectedConnectorQuestion.id === option.id
                            ? pathQuizPrimaryColor
                            : pathQuizSecondaryColor
                    }
                    key={option.id}>
                    {option.optionText}
                </OptionBox>
            ));
        }
        if (currentQuestionInfo.type === CONNECTOR) {
            return dummyConnectior.map((option) => (
                <OptionBox
                    onClick={() => updateSelectedQuestionConnector(option)}
                    style={{
                        pointerEvents: connectorQuestionInfo.playedConnectorOptionQuizIds.includes(
                            option.quizId.toString()
                        )
                            ? "none"
                            : "all"
                    }}
                    background={
                        connectorQuestionInfo.playedConnectorOptionQuizIds.includes(option.quizId.toString())
                            ? "gray"
                            : selectedConnectorQuestion && selectedConnectorQuestion.id === option.id
                            ? pathQuizPrimaryColor
                            : pathQuizSecondaryColor
                    }
                    key={option.id}>
                    {option.optionText}
                </OptionBox>
            ));
        }
        if (currentQuestionInfo.type === MCQ) {
            return currentQuestionInfo.mcqOptions.map((option) => (
                <OptionBox
                    onClick={() => answerSelected(option)}
                    background={
                        selectedOption.includes(option.id.toString()) ? pathQuizPrimaryColor : pathQuizSecondaryColor
                    }
                    key={option.id}>
                    {option.text}
                </OptionBox>
            ));
        }

        return (
            <InputGroup size="sm" className={"margin-top-15"}>
                <CustomInputBox
                    placeholder={"Enter answer here"}
                    value={participantAnswer}
                    onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                        updateParticipantAnswer(target.value)
                    }
                />
            </InputGroup>
        );
    };

    const disabledSubmitButton = () => {
        if (currentQuestionInfo.type === FILE_UPLOAD) {
            return files.length === 0 || isFileUploading;
        }
        if (currentQuestionInfo.type === CONNECTOR) {
            if (
                connectorQuestionInfo.playedConnectorOptionQuizIds?.length ===
                currentQuestionInfo.questionConnector.length
            ) {
                return false;
            }

            return !selectedConnectorQuestion;
        }
        return participantAnswer === "";
    };

    return (
        <>
            {fullScreenImage ? (
                <FullScreenImageContainer>
                    <FullScreenImage src={currentQuestionInfo.fileLocation} alt="lockImage" />
                    <IconContainer bottom_pos="2%" onClick={() => setFullScreenImage(false)}>
                        <GreyCircle>
                            <FullScreenIconImage src={resizeIcon} alt="resizeIcon" />
                        </GreyCircle>
                    </IconContainer>
                </FullScreenImageContainer>
            ) : (
                <>
                    <div style={{ flex: 1 }}>
                        <QuestionBox style={{ marginTop: 11 }}>
                            <ScrollBox>
                                <StickyContainer
                                    onClick={() =>
                                        !currentQuestionInfo.videoQuestionFileLocation && setFullScreenImage(true)
                                    }
                                    iscoverimage={currentQuestionInfo.fileLocation ? 1 : 0}>
                                    <QuestionImageContainer
                                        hidebackground={!currentQuestionInfo.videoQuestionFileLocation}>
                                        {renderFile()}
                                        <HeadPointsContainer>
                                            <PointsText color={pathQuizSecondaryColor}>
                                                {quizInfoData.totalPointsText || "Total points:"}{" "}
                                                {quizUserSessionInfoData.totalMarks}
                                            </PointsText>
                                        </HeadPointsContainer>
                                        {!(
                                            currentQuestionInfo.fileLocation &&
                                            currentQuestionInfo.videoQuestionFileLocation
                                        ) && (
                                            <StarPointsContainer
                                                isimage={!currentQuestionInfo.videoQuestionFileLocation}>
                                                <Icon iconName={"FavoriteStarFill"} style={{ fontSize: 15 }} />
                                                <span className="margin-left-10">
                                                    {currentQuestionInfo.correctPoints
                                                        ? currentQuestionInfo.correctPoints
                                                        : parseInt(quizInfoData.correctAnswerPoints)}{" "}
                                                    points
                                                </span>
                                            </StarPointsContainer>
                                        )}
                                        {renderFullScreenImage()}
                                    </QuestionImageContainer>
                                </StickyContainer>
                                <QuestionDetailBox>
                                    <FlexBox>
                                        {quizInfoData.showMainTimer && false && (
                                            <div>
                                                <StopWatchContainer className="margin-left-10">
                                                    <img src={stopWatchIcon} alt="stopWatchIcon" />
                                                    <div className="margin-left-10">
                                                        <MainText color={pathQuizSecondaryColor}>
                                                            <Timer
                                                                type={QUIZ_TIMER_TYPE}
                                                                timer={eventTiming}
                                                                getTiming={getEventTiming}
                                                                key={"quizTimer"}
                                                                eventFinishes={eventFinishes}
                                                            />
                                                        </MainText>
                                                    </div>
                                                </StopWatchContainer>
                                            </div>
                                        )}
                                    </FlexBox>
                                    {currentQuestionInfo.questionText && (
                                        <InfoText className={"quill-editor-text-align"} style={{ margin: "15px 0px" }}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: currentQuestionInfo.questionText
                                                }}
                                            />
                                        </InfoText>
                                    )}
                                    {currentQuestionInfo.bottomQuestionText && (
                                        <InfoText className={"quill-editor-text-align"} style={{ marginBottom: 15 }}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: currentQuestionInfo.bottomQuestionText
                                                }}
                                            />
                                        </InfoText>
                                    )}
                                    <div style={{ paddingBottom: 50 }}>{getQuestionFieldView()}</div>

                                    <CircleButtonBig
                                        selectionType={getButtonType()}
                                        disabled={disabledSubmitButton()}
                                        secondaryColor={pathQuizSecondaryColor}
                                        tertiarycolor={pathQuizTertiaryColor}
                                        onClick={() => {
                                            if (
                                                connectorQuestionInfo?.playedConnectorOptionQuizIds?.length ===
                                                currentQuestionInfo?.questionConnector?.length
                                            ) {
                                                saveProgress();
                                                return;
                                            }
                                            setSubmitQuestion(true);
                                            setIsAnswerSubmitting(true);
                                        }}>
                                        {isFileUploading ? (
                                            <img src={loadingGif} style={{ height: 10 }} alt={"loading"} />
                                        ) : (
                                            <ArrowForwardIconWrapper
                                                tertiaryColor={pathQuizTertiaryColor}
                                                secondaryColor={pathQuizSecondaryColor}
                                                selectionType={getButtonType()}
                                                primaryColor={pathQuizPrimaryColor}
                                            />
                                        )}
                                    </CircleButtonBig>
                                    {false && (
                                        <CircleButtonSmall onClick={() => setOpenDrawer(true)}>
                                            <Icon
                                                iconName={"globalnavbutton"}
                                                style={{ fontSize: 20, color: whiteColor }}
                                            />
                                        </CircleButtonSmall>
                                    )}
                                    <Drawer
                                        anchor={"left"}
                                        PaperProps={{
                                            style: {
                                                width: "80%",
                                                background: pathQuizPrimaryColor
                                            }
                                        }}
                                        open={openDrawer}
                                        onClose={() => setOpenDrawer(false)}>
                                        <SideBar
                                            menuSelect={(value) => {
                                                setDrawerContent(value);
                                                setOpenDrawer(false);
                                            }}
                                            userName={""}
                                            teamName={""}
                                            primaryColor={pathQuizPrimaryColor}
                                        />
                                    </Drawer>
                                    {currentQuestionInfo.allowSkipping && (
                                        <CircleSkipButton
                                            secondaryColor={pathQuizSecondaryColor}
                                            tertiarycolor={pathQuizTertiaryColor}
                                            onClick={() => setSkipQuestion(true)}>
                                            Skip
                                        </CircleSkipButton>
                                    )}
                                </QuestionDetailBox>
                            </ScrollBox>
                        </QuestionBox>
                    </div>
                    <PathGameDialog
                        submitButtonText="Submit"
                        cancelButtonText="Cancel"
                        modalTitle={selectedConnectorQuestion?.optionText}
                        modalSubText={
                            currentQuestionInfo.type === CONNECTOR
                                ? "Are you sure you want to continue with this Path?"
                                : "Are you sure you want to submit this answer?"
                        }
                        show={submitQuestion}
                        backgroundColor={pathQuizPrimaryColor}
                        onHide={() => {
                            setSubmitQuestion(false);
                            setIsAnswerSubmitting(false);
                        }}
                        onSubmit={() => {
                            if (selectedConnectorQuestion && currentQuestionInfo.type === CONNECTOR) {
                                // connectorQuestionSubmit(selectedConnectorQuestion);
                            } else {
                                saveProgress();
                            }
                            setSubmitQuestion(false);
                        }}
                    />
                    <PathGameDialog
                        submitButtonText="Yes"
                        cancelButtonText="Cancel"
                        modalTitle="Skip Question?"
                        modalSubText="Are you sure you want to skip this question?"
                        show={skipQuestion}
                        onHide={() => setSkipQuestion(false)}
                        onSubmit={() => {
                            setSkipQuestion(false);
                            saveProgress(true);
                        }}
                        modalImageSrc={SkipImage}
                    />
                </>
            )}
        </>
    );
};

export default PathGameQuestionAnswerSection;

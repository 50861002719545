import { Grid } from "@mui/material";
import { FC } from "react";
import { FlowBoxValueType, TriggersType } from "./Type";
import { Header2, SubText, TextButton } from "../../../CommonComponent/v2/CommonStyle";
import { extractHtmlContent } from "../../../../utils/JambarUtils";

type FlowChartBoxProps = {
    boxValue: FlowBoxValueType;
    onEditClick: () => void;
    onCreateTrigger: (id: string) => void;
    onEditTrigger: (branchId: string, triggerId: string, triggerInfo: TriggersType) => void;
};

const FlowChartBox: FC<FlowChartBoxProps> = ({ boxValue, onEditClick, onCreateTrigger, onEditTrigger }) => {
    const { branchInfo, triggers, id } = boxValue;
    return (
        <Grid width={500}>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                <Grid item>
                    <Header2>{branchInfo?.branchName || ""}</Header2>
                </Grid>
                <Grid item>
                    <TextButton onClick={onEditClick}>Edit branch</TextButton>
                </Grid>
            </Grid>
            {branchInfo && (
                <Grid container>
                    <Grid item>
                        <SubText style={{ color: "#000" }}>{branchInfo?.gameTitle || ""}</SubText>
                    </Grid>
                    <Grid container pb={"10px"} borderRadius={"10px"} bgcolor={"#efefef"}>
                        <Grid px={"10px"} py={"10px"} textAlign={"left"} borderBottom={"1px #efefef98 solid"} item xs={12}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <SubText style={{ fontWeight: 700 }}>#</SubText>
                                </Grid>
                                <Grid item xs={11}>
                                    <SubText style={{ fontWeight: 700 }}>Question name</SubText>
                                </Grid>
                            </Grid>
                        </Grid>
                        {branchInfo.questionList.slice(0, 5).map((item, index) => (
                            <Grid textAlign={"left"} px={"10px"} py={"5px"} item xs={12} key={item.id}>
                                <Grid container>
                                    <Grid item xs={1}>
                                        <SubText>{index + 1}</SubText>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <SubText>{extractHtmlContent(item.questionTitle)}</SubText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            )}
            <Grid container mt={"10px"}>
                <Grid item xs={12} textAlign={"left"}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item>
                            <Header2>Triggers</Header2>
                        </Grid>
                        <Grid item>
                            <TextButton onClick={() => onCreateTrigger(id)}>Create trigger</TextButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container pb={"10px"} borderRadius={"10px"} bgcolor={"#efefef"}>
                    <Grid px={"10px"} py={"10px"} textAlign={"left"} borderBottom={"1px #efefef98 solid"} item xs={12}>
                        <Grid container>
                            <Grid item xs={1}>
                                <SubText style={{ fontWeight: 700 }}>#</SubText>
                            </Grid>
                            <Grid item xs={7}>
                                <SubText style={{ fontWeight: 700 }}>Trigger name</SubText>
                            </Grid>
                            <Grid item xs={3}>
                                <SubText style={{ fontWeight: 700 }}>Trigger type</SubText>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    </Grid>
                    {triggers.map((item, index) => (
                        <Grid textAlign={"left"} px={"10px"} py={"5px"} item xs={12} key={item.triggerId}>
                            <Grid container alignItems={"center"}>
                                <Grid item xs={1}>
                                    <SubText>{index + 1}</SubText>
                                </Grid>
                                <Grid item xs={7}>
                                    <SubText>{item.triggerName}</SubText>
                                </Grid>
                                <Grid item xs={3}>
                                    <SubText>{item.triggerType}</SubText>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextButton onClick={() => onEditTrigger(id, item.triggerId, item)}>Edit</TextButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FlowChartBox;

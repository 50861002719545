import { Client } from "../../../../../Base/Client";
import { ApiConstants } from "../../../../../../constants/ApiConstant";

export const checkLinkStatusAction = (linkName: string) =>
    Client.getInstance()
        .getData(ApiConstants.checkReferenceLinkAvailability(linkName), true)
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());

export const removeReferenceCoverImage = (referenceId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.removeReferenceListCoverImage(referenceId))
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());

export const removeReferenceDecriptionImage = (referenceId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.removeReferenceListDescrptionImage(referenceId))
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());

import { toast } from "react-toastify";
import { ApiConstants } from "../../../../../constants/ApiConstant";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { Client } from "../../../../Base/Client";
import { FileDataType } from "../Type";

export const getEventFiles = (eventId: number, forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getEventParticipantFileApiUrl(eventId), forceRefresh)
        .then((response) => Promise.resolve(response.data.data))
        .catch((error) => Promise.reject(error));

export const sortFilesList = (fileList: FileDataType[]) =>
    fileList.sort((a: FileDataType, b: FileDataType) =>
        a.teamName.toLowerCase() > b.teamName.toLowerCase()
            ? 1
            : a.teamName === b.teamName
            ? a.createdAt > b.createdAt
                ? 1
                : -1
            : -1
    );

export const getFilesFilteredItems = (list: FileDataType[], searchText: string): FileDataType[] => {
    if (!list) {
        return [];
    }
    return list.filter(
        (file: FileDataType) =>
            searchText === "" ||
            file.teamName.toLowerCase().includes(searchText.toLowerCase()) ||
            file.question.title.toLowerCase().includes(searchText.toLowerCase())
    );
};

export const deleteEventFiles = (fileId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteEventFileUpload(fileId))
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());

export const downloadEventFile = (eventId: string) => {
    return Client.getInstance()
        .getData(ApiConstants.getDownloadFileApiUrl(eventId), true)
        .then(() => {
            toast.success(
                "Preparing download, please check status in download section",
                SiteConstants.successToastConfiguration
            );
            return Promise.resolve();
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const convertBytesToMB = (bytes: number) => {
    return bytes / (1024 * 1024);
};

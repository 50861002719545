import GeoQuestion1 from "../../Component/WebsiteGame/image/geographyQuiz/longestRiverWorld.png";
import GeoQuestion2 from "../../Component/WebsiteGame/image/geographyQuiz/indonesia.png";
import GeoQuestion3 from "../../Component/WebsiteGame/image/geographyQuiz/mariannaTrench.png";
import GeoQuestion4 from "../../Component/WebsiteGame/image/geographyQuiz/volcano.png";
import GeoQuestion5 from "../../Component/WebsiteGame/image/geographyQuiz/denmarkStrait.png";
import GeoQuestion6 from "../../Component/WebsiteGame/image/geographyQuiz/longestRiverAsia.png";
import GeoQuestion7 from "../../Component/WebsiteGame/image/geographyQuiz/archipelago.png";
import GeoQuestion8 from "../../Component/WebsiteGame/image/geographyQuiz/desert.png";
import GeoQuestion9 from "../../Component/WebsiteGame/image/geographyQuiz/southSudan.png";
import GeoQuestion10 from "../../Component/WebsiteGame/image/geographyQuiz/northernLights.png";

export const GeographyEndings = [
    {
        scoreMin: 0,
        scoreMax: 2,
        text: "Poor",
        imageSrc: "https://i.imgur.com/tccvGSl.jpg"
    },
    {
        scoreMin: 3,
        scoreMax: 7,
        text: "Average",
        imageSrc: "https://i.imgur.com/cqcYs9e.jpg"
    },
    {
        scoreMin: 8,
        scoreMax: 10,
        text: "Excellent",
        imageSrc: "https://i.imgur.com/XnYtpDE.jpg"
    }
];
export const GeographyQuestions = [
    {
        questionId: 1,
        questionTitle: "Which is the longest river in the world?",
        questionImageSrc: GeoQuestion1,
        answerDescription:
            "The enchanting River Nile in Africa is the longest river in the world. With the pyramids sitting at the backdrop in Egypt, it takes a beautiful form here. It is 6,853 km long.",
        wrongAnswerText: "Incorrect",
        correctAnswerText: "Correct",
        options: [
            { id: 1, text: "A. Yangtze River", isCorrect: false },
            { id: 2, text: "B. Amazon River", isCorrect: false },
            { id: 3, text: "C. Mississippi River", isCorrect: false },
            { id: 4, text: "D. Nile River", isCorrect: true }
        ]
    },
    {
        questionId: 2,
        questionTitle: "How many islands is Indonesia made up of?",
        questionImageSrc: GeoQuestion2,
        answerDescription: "There are total number of 17,508 islands in Indonesia of which about 6000 are inhabited.",
        wrongAnswerText: "Incorrect",
        correctAnswerText: "Correct",
        options: [
            { id: 1, text: "A. 5304", isCorrect: false },
            { id: 2, text: "B. 2000", isCorrect: false },
            { id: 3, text: "C. 17508", isCorrect: true },
            { id: 4, text: "D. 6000", isCorrect: false }
        ]
    },
    {
        questionId: 3,
        questionTitle: "True or False: The Mariana Trench is deeper than the height of the Mount Everest",
        questionImageSrc: GeoQuestion3,
        wrongAnswerText: "Incorrect",
        correctAnswerText: "Correct",
        answerDescription:
            "By comparison, Mount Everest stands at 29,026 feet (8,848 m) above sea level, meaning the deepest part of the Mariana Trench is 7,044 feet (2,147 m) deeper than Everest is tall.",
        options: [
            { id: 1, text: "A. True", isCorrect: true },
            { id: 2, text: "B. False", isCorrect: false }
        ]
    },
    {
        questionId: 4,
        questionTitle: "Which is the largest active volcano in the world?",
        questionImageSrc: GeoQuestion4,
        answerDescription:
            "Rising gradually to more than 4 km (2.5 mi) above sea level, Hawaii's Mauna Loa is the largest active volcano on our planet.",
        wrongAnswerText: "Incorrect",
        correctAnswerText: "Correct",
        options: [
            { id: 1, text: "A. Tamu Massif", isCorrect: false },
            { id: 2, text: "B. Mauna Loa", isCorrect: true },
            { id: 3, text: "C. Mount Etna", isCorrect: false },
            { id: 4, text: "D. Sakurajima", isCorrect: false }
        ]
    },
    {
        questionId: 5,
        questionTitle: "Which is the largest undersea waterfall that separates the countries Iceland and Greenland?",
        questionImageSrc: GeoQuestion5,
        answerDescription:
            "The world’s largest waterfall is in the ocean beneath the Denmark Strait, which separates Iceland and Greenland.",
        wrongAnswerText: "Incorrect",
        correctAnswerText: "Correct",
        options: [
            { id: 1, text: "A. Guaira Falls", isCorrect: false },
            { id: 2, text: "B. Victoria Falls", isCorrect: false },
            { id: 3, text: "C. Denmark Strait Cataract", isCorrect: true },
            { id: 4, text: "D. Niagara Falls", isCorrect: false }
        ]
    },
    {
        questionId: 6,
        questionTitle: "Which is the longest river in Asia?",
        questionImageSrc: GeoQuestion6,
        answerDescription:
            "The Yangtze or Yangzi is the longest river in Asia. It rises at Jari Hill in the Tanggula Mountains and flows 6,300 km in a generally easterly direction to the East China Sea.",
        wrongAnswerText: "Incorrect",
        correctAnswerText: "Correct",
        options: [
            { id: 1, text: "A. Amazon", isCorrect: false },
            { id: 2, text: "B. Argun", isCorrect: false },
            { id: 3, text: "C. Han River", isCorrect: false },
            { id: 4, text: "D. Yangtze", isCorrect: true }
        ]
    },
    {
        questionId: 7,
        questionTitle: "What is an Archipelago?",
        questionImageSrc: GeoQuestion7,
        answerDescription:
            "An archipelago, sometimes called an island group or island chain, is a chain, cluster or collection of islands, or sometimes a sea containing a small number of scattered islands.",
        wrongAnswerText: "Incorrect",
        correctAnswerText: "Correct",
        options: [
            { id: 1, text: "A. A mountain chain", isCorrect: false },
            { id: 2, text: "B. An island chain", isCorrect: true },
            { id: 3, text: "C. A sea chain", isCorrect: false },
            { id: 4, text: "D. A type of plant", isCorrect: false }
        ]
    },
    {
        questionId: 8,
        questionTitle: "Which is the largest desert on earth?",
        questionImageSrc: GeoQuestion8,
        answerDescription:
            "The largest desert on earth is the Antarctic desert, covering the continent of Antarctica with a size of around 5.5 million square miles.",
        wrongAnswerText: "Incorrect",
        correctAnswerText: "Correct",
        options: [
            { id: 1, text: "A. Sahara desert", isCorrect: false },
            { id: 2, text: "B. Thar desert", isCorrect: false },
            { id: 3, text: "C. Antarctic desert", isCorrect: true },
            { id: 4, text: "D. Mojave desert", isCorrect: false }
        ]
    },
    {
        questionId: 9,
        questionTitle: "Which is the newest country in the world?",
        questionImageSrc: GeoQuestion9,
        answerDescription:
            "The newest internationally recognized country in the world is the African country of South Sudan, which declared independence on July 9, 2011.",
        wrongAnswerText: "Incorrect",
        correctAnswerText: "Correct",
        options: [
            { id: 1, text: "A. South Sudan", isCorrect: true },
            { id: 2, text: "B. Kosovo", isCorrect: false },
            { id: 3, text: "C. East Timor", isCorrect: false },
            { id: 4, text: "D. Yemen", isCorrect: false }
        ]
    },
    {
        questionId: 10,
        questionTitle: "Which is the atmospheric layer in which northern lights appear?",
        questionImageSrc: GeoQuestion10,
        answerDescription:
            "The thermosphere is where the aurora (Southern and Northern Lights) occur. At high latitudes, charged particles from space clash with atoms and molecules in the thermosphere, causing them to be excited into higher energy levels.",
        wrongAnswerText: "Incorrect",
        correctAnswerText: "Correct",
        options: [
            { id: 1, text: "A. Troposphere", isCorrect: false },
            { id: 2, text: "B. Thermosphere", isCorrect: true },
            { id: 3, text: "C. Stratosphere", isCorrect: false },
            { id: 4, text: "D. Mesosphere", isCorrect: false }
        ]
    }
];

import { ApiConstants } from "../../../../constants/ApiConstant";
import { capitalizeFirstLetter } from "../../../../constants/HelperFns";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { Client } from "../../../Base/Client";

export const assignRightToResource = (roleId: number, resourceId: number, rightId: number) =>
    Client.getInstance()
        .createData(ApiConstants.assignRightUserRoleApiUrl(roleId, resourceId, rightId), {
            roleId: roleId,
            resourceId: resourceId,
            rightId: rightId
        })
        .then((res) => Promise.resolve(res))
        .catch((err) => Promise.reject(err));

export const revokeRightToResource = async (roleId: number, resourceId: number, rightId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.revokeRightUserRoleApiUrl(roleId, resourceId, rightId))
        .then((res) => Promise.resolve(res))
        .catch((err) => Promise.reject(err));

const transformData = (resourceData: any, rightsData: any) => ({
    resources: resourceData.map((item: any, index: number) => ({
        id: item.id,
        name: item.name,
        sno: index + 1,
        rights: rightsData
            .filter(({ resourceId }: any) => resourceId === item.id)
            .map((ele: any) => ({
                id: ele.rightId,
                roleId: ele.roleId,
                resourceId: ele.resourceId,
                name: ele.right.name
            }))
    }))
});

export const transformResourceColumnList = (data: any) => {
    if (!data) {
        return [];
    }
    return data.map(({ name }: any) => ({
        key: name,
        name: capitalizeFirstLetter(name),
        fieldName: name,
        minWidth: SiteConstants.listColumn55,
        maxWidth: SiteConstants.listColumn55
    }));
};

const transformResource = (data: any) => {
    if (!data) {
        return [];
    }
    return data.map(({ name, id }: any) => ({
        id: id,
        name: name,
    }));
};

export const getResourceList = async () =>
    Client.getInstance()
        .getData(ApiConstants.fetchAllRight())
        .then((res) => Promise.resolve(transformResource(res.data.data)))
        .catch((err) => Promise.reject(err));

export const getAllRights = async (roleId: number) =>
    Client.getInstance()
        .getData(ApiConstants.fetchAllResourcesApiUrl())
        .then((res1) => {
            return Client.getInstance()
                .getData(ApiConstants.fetchAllRightUserRoleApiUrl(roleId), true)
                .then((res2: any) => {
                    const resourceData = res1.data.data;
                    const rightData = res2.data.data;
                    return Promise.resolve(transformData(resourceData, rightData));
                })
                .catch((err) => Promise.reject(err));
        })
        .catch(() =>
            Promise.resolve({
                resources: []
            })
        );

import { Dialog, Grid } from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import { FC } from "react";
import { CaptionText, Header1 } from "../../../../../../CommonComponent/v2/CommonStyle";
import { darkColor } from "../../../../../../WebsiteGame/colorConstant";
import { codeInitialValue, codeValidation } from "../addcode/Util";
import SingleCodeForm from "../singlecode";
import { EventCodeListType } from "../Type";
import { useEditCode } from "./Hooks";

type EditCodeProps = {
    handleClose: () => void;
    codeInfo: EventCodeListType;
    eventCodeList: string[];
    refreshList: () => void;
};
const EditCode: FC<EditCodeProps> = ({ handleClose, codeInfo, eventCodeList, refreshList }) => {
    const { editCode, loading } = useEditCode();
    return (
        <Dialog open maxWidth={"lg"} fullWidth onClose={handleClose}>
            <Grid p={"40px"} container justifyContent={"center"}>
                <Grid item xs={8}>
                    <Header1 textAlign={"center"}>Edit code</Header1>
                    <Grid item textAlign={"center"} mt={"40px"}>
                        <CaptionText color={darkColor} fontWeight={700}>
                            Generated by
                            <span style={{ fontWeight: 400, marginLeft: 30 }}>{codeInfo.generatedBy}</span>
                        </CaptionText>
                    </Grid>
                    <Grid>
                        <Formik
                            initialValues={codeInitialValue(codeInfo)}
                            validationSchema={codeValidation}
                            onSubmit={(values) =>
                                editCode(
                                    {
                                        notes: values.notes,
                                        status: codeInfo.status,
                                        startDate: moment(values.startDate).format("YYYY-MM-DD"),
                                        endDate: moment(values.endDate).format("YYYY-MM-DD"),
                                        code: codeInfo.code,
                                        id: codeInfo.id,
                                        usedDate: codeInfo.usedDate,
                                        generatedBy: codeInfo.generatedBy,
                                        isPaid: codeInfo.isPaid
                                    },
                                    refreshList
                                )
                            }>
                            {() => (
                                <SingleCodeForm
                                    loading={loading}
                                    eventIdsList={eventCodeList}
                                    closeModal={handleClose}
                                />
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default EditCode;

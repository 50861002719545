import moment from "moment";
import { date, object } from "yup";
import { ApiConstants } from "../../../../../../../constants/ApiConstant";
import { Client } from "../../../../../../Base/Client";
import { getEventCode } from "../../../../../EventManager/EventDetails/Util";
import { BulkCodeType, BulkTransformCodeType } from "../generatebulkcode/Util";
import { CodeType, EventCodeListType } from "../Type";

export const codeInitialValue = (value?: EventCodeListType) => ({
    endDate: value?.endDate || moment().add(7, "days"),
    participantCode: value?.code || "",
    generatedBy: value?.generatedBy || "",
    notes: value?.notes || "",
    startDate: value?.startDate || moment(),
    status: value?.status || ""
});

export const codeValidation = object().shape(
    {
        startDate: date().typeError("Please select a valid date").required("From date is mandatory"),
        endDate: date()
            .when(
                "startDate",
                (startDate: Date, schema: any) =>
                    startDate && schema.min(startDate, "To date should be greater than From date")
            )
            .typeError("Please select a valid date")
            .required("To date is mandatory")
    },
    [["startDate", "endDate"]]
);

export const reverseTransformData = (values: CodeType): any => ({
    endDate: values.endDate,
    participantCode: values.participantCode,
    generatedBy: values.generatedBy,
    notes: values.notes,
    startDate: values.startDate
});

export const addEventCode = (eventId: string, values: CodeType) =>
    Client.getInstance()
        .createData(ApiConstants.addEventCodeApiUrl(parseInt(eventId)), reverseTransformData(values))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const addBulkEventCode = (eventId: string, bulkCode: BulkTransformCodeType[]) =>
    Client.getInstance()
        .createData(ApiConstants.addBulkEventCodeApiUrl(parseInt(eventId)), { codes: bulkCode })
        .then(() => Promise.resolve("Bulk code added"))
        .catch((error) => Promise.reject(error));

export const generateBulkCode = (values: BulkCodeType, excludeEventCodes: string[]): BulkTransformCodeType[] => {
    const bulkCode = [];
    for (let i = 0; i < values.numberOfCode; i++) {
        const code = {
            startDate: moment(values.startDate).format("YYYY-MM-DD"),
            endDate: moment(values.endDate).format("YYYY-MM-DD"),
            notes: values.notes,
            generatedBy: values.generatedBy,
            participantCode: getEventCode(excludeEventCodes)
        };
        bulkCode.push(code);
    }
    return bulkCode;
};

import { Grid } from "@mui/material";
import { useHistory, useParams } from "react-router";
import { Header1, PrimaryButton } from "../../../../../../CommonComponent/v2/CommonStyle";
import ArrowLeft from "../../../../../../Images/icons/arrowLeft.svg";
import BreadcrumbWrapper from "../../../../../../CommonComponent/v2/BreadCrumbWrapper";
import { Formik } from "formik";
import { ItemBreadcrumbValues, addItemInitialValues, validationSchema } from "./Utils";
import AddItemFormUI from "./AddItemFormUI";
import { useItem } from "./Hooks";
import LoadingWrapper from "../../../../../../CommonComponent/v2/LoadingWrapper";
import LoadingButtonWrapper from "../../../../../../CommonComponent/v2/button/LoadingButtonWrapper";

const UpdateReferenceItem = () => {
    const history = useHistory();
    const { id, refItemId } = useParams<{
        id: string;
        refItemId: string;
    }>();

    const { saveData, isLoading, itemDetails } = useItem();
    return (
        <Grid px={"20px"}>
            <Grid container>
                <Grid item xs={12}>
                    <Header1>
                        <img
                            onClick={() => history.push(`/admin/v2/referencelistmanager/${id}/update`)}
                            src={ArrowLeft}
                            style={{ height: 18, marginRight: 10, cursor: "pointer" }}
                        />
                        {refItemId ? "Edit reference item" : "Add new item"}
                    </Header1>
                    <BreadcrumbWrapper links={ItemBreadcrumbValues(id, refItemId)} />
                </Grid>
                <LoadingWrapper isLoading={isLoading}>
                    <Formik
                        initialValues={addItemInitialValues(itemDetails)}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            saveData(values, setSubmitting);
                        }}
                        enableReinitialize
                        validateOnMount
                    >
                        {({ handleSubmit, isSubmitting }) => (
                            <Grid item xs={12}>
                                <AddItemFormUI />
                                <Grid container justifyContent={"center"} mt={"60px"} mb={"20px"}>
                                    <LoadingButtonWrapper loading={isSubmitting}>
                                        <PrimaryButton onClick={() => handleSubmit()}>Save</PrimaryButton>
                                    </LoadingButtonWrapper>
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </LoadingWrapper>
            </Grid>
        </Grid>
    );
};
export default UpdateReferenceItem;

import React from "react";
import { useMemo } from "react";
import { Button } from "react-bootstrap";
import { hintType } from "../RocketTemplateUtils/RocketTemplateTypes";
import { ScrollViewContainer } from "../style";

interface SocketHintProps {
    hintCount: number;
    hints: hintType[];
    hintDisable: boolean;
    gameBackgroundColor: string;
    questionTimeEnds: boolean;
    showHint: () => void;
    isProgressInPercentage: boolean;
}

const SocketHintsView: React.FC<SocketHintProps> = ({
    gameBackgroundColor,
    hintCount,
    hintDisable,
    hints,
    questionTimeEnds,
    showHint,
    isProgressInPercentage
}) => {
    const getType = (scorepenality: string, isProgressInPercentage: boolean) => {
        if (scorepenality && scorepenality.toString().includes("sec")) {
            return scorepenality;
        }
        if (isProgressInPercentage) {
            return `${scorepenality}%`;
        }
    }
        
        

    return (
        <ScrollViewContainer className={"quiz-answer-history-view"} style={{ backgroundColor: gameBackgroundColor }}>
            <Button
                className={"hintButtonText submit-button-option cursor width-100per"}
                disabled={hintCount === hints.length || hintDisable || questionTimeEnds}
                onClick={showHint}>
                <span>
                    Hint{" "}
                    {hintCount != hints.length
                        ? getType(hints[hintCount].scorePenalty, isProgressInPercentage)
                        : getType(hints[hints.length - 1].scorePenalty, isProgressInPercentage)}
                </span>
            </Button>
            {hints.map((item, index) =>
                index < hintCount ? (
                    <div
                        className={"margin-p-0 hints-section quill-editor-text-align"}
                        key={`${item.questionId}`}
                        dangerouslySetInnerHTML={{
                            __html: hints[index].description
                        }}
                    />
                ) : (
                    ""
                )
            )}
        </ScrollViewContainer>
    );
};

export default SocketHintsView;

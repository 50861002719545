import React from "react";
import {
    IColumn,
    ShimmeredDetailsList,
    Selection,
    IColumnReorderOptions,
    IDragDropEvents,
    IDragDropContext,
    SelectionMode,
    IDetailsListProps,
    DetailsRow,
    Sticky,
    StickyPositionType
} from "@fluentui/react";
import { isEqual } from "lodash";

export type ISortingDetailsDnDListProps<T> = {
    columns: IColumn[];
    items: T[];
    onRenderItemColumn: (item: T, index: number | undefined, column: IColumn | undefined) => JSX.Element;
    isLoading: boolean;
    setKey?: string;
    isSortingCaseSensitive?: boolean;
    getTransformedItems?: (item: T[]) => void;
    showBlueBackgroundGrid?: boolean;
};

type ISortingDetailsListState<T> = {
    items: T[];
    columns: IColumn[];
};

export class SortingDetailsListDnD<T> extends React.Component<
    ISortingDetailsDnDListProps<T>,
    ISortingDetailsListState<T>
> {
    private _selection: Selection;
    private _dragDropEvents: IDragDropEvents;
    private _draggedIndex: number;
    private _draggedItem: T[] | undefined;

    public constructor(props: ISortingDetailsDnDListProps<T>) {
        super(props);
        this._selection = new Selection();
        this._dragDropEvents = this._getDragDropEvents();
        this._draggedIndex = -1;
        this.state = {
            items: this.props.items,
            columns: this._assignOnColumnClick(this.props.columns)
        };
    }

    public componentWillUpdate(nextProps: Readonly<ISortingDetailsDnDListProps<T>>) {
        if (!isEqual(nextProps.items, this.state.items)) {
            this.setState({
                items: nextProps.items
            });
        }
        if (!isEqual(nextProps.columns, this.state.columns)) {
            this.setState({
                columns: this._assignOnColumnClick(nextProps.columns)
            });
        }
    }

    private _getDragDropEvents(): IDragDropEvents {
        return {
            //eslint-disable-next-line
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
                return true;
            },
            //eslint-disable-next-line
            canDrag: (item?: any) => {
                return true;
            },
            //eslint-disable-next-line
            onDragEnter: (item?: any, event?: DragEvent) => {
                return "";
            },
            //eslint-disable-next-line
            onDragLeave: (item?: any, event?: DragEvent) => {
                return;
            },
            //eslint-disable-next-line
            onDrop: (item?: any, event?: DragEvent) => {
                if (this._draggedItem) {
                    this._insertBeforeItem(item);
                }
            },
            //eslint-disable-next-line
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                this._draggedItem = item;
                this._draggedIndex = itemIndex || 0;
            },
            //eslint-disable-next-line
            onDragEnd: (item?: any, event?: DragEvent) => {
                this._draggedItem = undefined;
                this._draggedIndex = -1;
            }
        };
    }

    private _insertBeforeItem(item: any): void {
        const draggedItems = this._selection.isIndexSelected(this._draggedIndex)
            ? (this._selection.getSelection() as any[])
            : [this._draggedItem];

        const insertIndex = this.state.items.indexOf(item);
        const items = this.state.items.filter((itm) => draggedItems.indexOf(itm) === -1);

        items.splice(insertIndex, 0, ...draggedItems);

        this.setState({ items });
        this.props.getTransformedItems && this.props.getTransformedItems(this.state.items);
    }

    public render(): JSX.Element {
        return (
            <ShimmeredDetailsList
                selectionMode={SelectionMode.none}
                columns={this.state.columns}
                items={this.state.items}
                onRenderItemColumn={this.props.onRenderItemColumn}
                enableShimmer={this.props.isLoading}
                setKey={this.props.setKey}
                key={this.props.setKey}
                dragDropEvents={this._dragDropEvents}
                onShouldVirtualize={() => false}
                columnReorderOptions={this._getColumnReorderOptions()}
                onRenderRow={this.props.showBlueBackgroundGrid ? this._onRenderRow : undefined}
                onRenderDetailsHeader={(headerProps, defaultRender) => {
                    return (
                        <Sticky
                            stickyPosition={StickyPositionType.Header}
                            isScrollSynced={true}
                            stickyBackgroundColor="transparent">
                            {defaultRender && defaultRender(headerProps)}
                        </Sticky>
                    );
                }}
            />
        );
    }

    private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
        return props ? <DetailsRow {...props} className={"grid-blue-background"} /> : null;
    };

    private _handleColumnReorder = (draggedIndex: number, targetIndex: number) => {
        const draggedItems = this.state.columns[draggedIndex];
        const newColumns: IColumn[] = [...this.state.columns];
        newColumns.splice(draggedIndex, 1);
        newColumns.splice(targetIndex, 0, draggedItems);
        this.setState({ columns: newColumns });
    };

    private _getColumnReorderOptions(): IColumnReorderOptions {
        return {
            handleColumnReorder: this._handleColumnReorder
        };
    }

    // eslint-disable-next-line
    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { items, columns } = this.state;

        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = this._copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems
        });
    };

    private _copyAndSort = (items: T[], columnKey: string | undefined, isSortedDescending?: boolean): T[] => {
        const key = columnKey as keyof T;
        return items
            .slice(0)
            .sort((a: T, b: T) =>
                (
                    isSortedDescending
                        ? this._getLowercaseIfPossible(a[key]) < this._getLowercaseIfPossible(b[key])
                        : this._getLowercaseIfPossible(a[key]) > this._getLowercaseIfPossible(b[key])
                )
                    ? 1
                    : -1
            );
    };

    private _getLowercaseIfPossible(value: any): any {
        if (this.props.isSortingCaseSensitive && typeof value === "string") {
            return (value as string).toLowerCase();
        }

        return value;
    }

    private _assignOnColumnClick(columns: IColumn[]): IColumn[] {
        const copiedColumns = columns.slice();
        copiedColumns.forEach((copiedColumn) => {
            copiedColumn.onColumnClick = this._onColumnClick;
        });

        return copiedColumns;
    }
}

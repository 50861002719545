import { Grid } from "@mui/material";
import React from "react";
import { PrimaryButton } from "../../../CommonComponent/Style";
import { FeedbackContainer, EventTitle, FeedbackTextField } from "./Style";

const DashboardFeedback = () => (
    <FeedbackContainer height={"100%"}>
        <EventTitle>Feedback</EventTitle>
        <FeedbackTextField
            style={{ marginTop: 20, background: "white", border: 0 }}
            rows={10}
            multiline
            fullWidth
            variant="outlined"
        />
        <Grid container marginTop={"30px"} justifyContent={"flex-end"}>
            <PrimaryButton variant={"contained"}>Submit</PrimaryButton>
        </Grid>
    </FeedbackContainer>
);

export default DashboardFeedback;

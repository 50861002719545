import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import { darkPrimaryColor } from "../../../../../constants/ColorConstant";
import { QuestionTypeDropdownOptions } from "../../../../../constants/DropdownOption";
import { SiteConstants, VIDEO_QUESTION_TYPE } from "../../../../../constants/SiteConstant";
import { CONNECTOR, FILE_UPLOAD, FREE_TEXT, MCQ } from "../../../../../data/question/types";
import { CaptionText, Header2 } from "../../../../CommonComponent/v2/CommonStyle";
import FormikRadioGroup from "../../../../CommonComponent/v2/fields/FormikRadioGroup";
import FormikTextEditor from "../../../../CommonComponent/v2/fields/FormikTextEditor";
import ConnectorQuestion from "./answerdetails/ConnectorQuestion";
import FreeTextQuestion from "./answerdetails/FreeTextQuestion";
import McqQuestion from "./answerdetails/McqQuestion";
import QuestionTrigger from "./answerdetails/QuestionTrigger";
import UploadQuestion from "./answerdetails/UploadQuestion";
import { QuestionDetailType } from "./Type";
import { QUESTION_TRIGGER_FLAG } from "../../../../../utils/JambarUtils";
import { QR_CODE } from "../questionInfo/Type";
import QRCodeView from "./answerdetails/QRCodeView";

type AnswerDetailsFormProps = {
    gameId: string;
};
const AnswerDetailsForm: FC<AnswerDetailsFormProps> = ({ gameId }) => {
    const { values } = useFormikContext();
    const data = values as QuestionDetailType;

    const renderAnswerTypeView = () => {
        if (data.answerDetails.answerType === MCQ) {
            return <McqQuestion />;
        }
        if (data.answerDetails.answerType === FREE_TEXT) {
            return <FreeTextQuestion />;
        }
        if (data.answerDetails.answerType === CONNECTOR) {
            return <ConnectorQuestion />;
        }
        if (data.answerDetails.answerType === FILE_UPLOAD) {
            return <UploadQuestion />;
        }
        if (data.answerDetails.answerType === QR_CODE) {
            return <QRCodeView />;
        }
        return <></>;
    };

    const getOptions = () => {
        if (gameId === "fullgame" && data.questionDetails.questionType === VIDEO_QUESTION_TYPE) {
            return "fullgameVideo";
        }
        return gameId;
    };

    return (
        <Grid container mt={"90px"}>
            <Grid item xs={12}>
                <Header2>Answer details</Header2>
            </Grid>
            {data.questionIdentification.gameType !== SiteConstants.wildgoat && (
                <Grid item xs={12}>
                    <CaptionText mb={"10px"} color={darkPrimaryColor} fontWeight={700}>
                        Answer header
                    </CaptionText>
                    <FormikTextEditor
                        basicControl
                        name={"answerDetails.answerHeader"}
                        placeholder={"Enter answer header text here..."}
                    />
                </Grid>
            )}
            <Grid item mt={"20px"} xs={12}>
                <CaptionText mb={"10px"} fontWeight={700} color={darkPrimaryColor}>
                    Answer type
                </CaptionText>
                <FormikRadioGroup
                    row
                    labelfontweight={400}
                    radioOptions={QuestionTypeDropdownOptions[getOptions()]}
                    name={"answerDetails.answerType"}
                />
            </Grid>
            <Grid item xs={12}>
                {renderAnswerTypeView()}
            </Grid>
            {QUESTION_TRIGGER_FLAG && data.questionIdentification.gameType === SiteConstants.wildgoat && (
                <Grid item xs={12}>
                    <QuestionTrigger />
                </Grid>
            )}
        </Grid>
    );
};

export default AnswerDetailsForm;

import React from "react";

import { IRenderFunction, ITextFieldProps, TextField } from "@fluentui/react";

type ITextFieldWrapperProps = {
    onTextFieldValueChange: (value: string, event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    value: string;
    disabled?: boolean;
    placeholder?: string;
    label?: string;
    errorMessage?: string;
    width?: number;
    type?: string;
    onKeydown?: (keyCode: string) => void;
    description?: string;
    onRenderDescription?: IRenderFunction<ITextFieldProps>;
    multiline?: boolean;
    autoAdjustHeight?: boolean;
    id?: string;
    name?: string;
    rows?: number;
} & ITextFieldProps;

const TextFieldWrapper: React.FC<ITextFieldWrapperProps> = ({
    onTextFieldValueChange,
    value,
    disabled,
    placeholder,
    label,
    errorMessage,
    width,
    type,
    onKeydown,
    description,
    onRenderDescription,
    multiline,
    autoAdjustHeight,
    id,
    name,
    rows,
    ...rest
}) => {
    const onTextFieldInputChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void => {
        if (newValue === undefined) {
            return;
        }

        onTextFieldValueChange(newValue, event);
    };

    const onkeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        onKeydown && onKeydown(event.key);
    };

    return (
        <TextField
            onChange={onTextFieldInputChange}
            value={value}
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            errorMessage={errorMessage}
            type={type}
            styles={{ root: { width: width } }}
            onKeyDown={onkeyDown}
            description={description}
            onRenderDescription={onRenderDescription}
            multiline={multiline}
            autoAdjustHeight={autoAdjustHeight}
            id={id}
            name={name}
            rows={rows}
            {...rest}
        />
    );
};

export default TextFieldWrapper;

import Styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const GameTypeContainer = Styled(Grid)`
    img {
        height: 30px;
        width: 23px;
    }
`;

type ColorIconProps = {
    backgroundcolor: string;
};
export const ColorIcon = Styled.div<ColorIconProps>`
    background-color: ${(props) => props.backgroundcolor};
    border-radius: 50%;
    height: 18px;
    width: 18px;
`;

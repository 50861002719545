import { Client } from "../../Base/Client";
import { ApiConstants } from "../../../constants/ApiConstant";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import  {EventDetails, TeamRecord} from "./Types";

type PromiseDataType = {
    eventDetail : EventDetails;
    teamRecords: TeamRecord[];
}

export const fetchLeaderBoard = (link:string):Promise<PromiseDataType> => {

    return new Promise<any>((resolve, reject) => {
        Client.getInstance()
        .getData(ApiConstants.getPublicLeaderboardDetails(link), true)
        .then((response) => {
            const data: any = response.data.data;
            const eventDetails = {
                eventId: data.context.eventId,
                eventName: data.context.eventName,
                status: data.context.status,
                logo: data.context.logo
            };
            const teamRecords = data.teamRecords.map((item: TeamRecord) => ({
                correctAnswersCount: item.correctAnswersCount,
                hintsUsedCount: item.hintsUsedCount,
                participantCode: item.participantCode,
                score: item.score,
                startTime: item.startTime,
                status: item.status,
                teamName: item.teamName,
                timeTakenInQuiz: item.timeTakenInQuiz,
                wrongAnswersCount: item.wrongAnswersCount,
                timeLeftInQuiz: item.timeLeftInQuiz,
                timeLeftInConnector: item.timeLeftInConnector,
            }));
            resolve({eventDetails,teamRecords});
        }).catch((Error) => {
            reject(getErrorMessage(Error));
        });
    });
};
import { ApiConstants } from "../../../../../../constants/ApiConstant";
import { Client } from "../../../../../Base/Client";
import { EventCodeListType } from "./Type";

const transformData = (data: any): EventCodeListType[] => {
    if (!data) {
        return [];
    }

    return data.map(
        (item: any) =>
            ({
                code: item.participantCode,
                status: new Date(item.endDate) < new Date() ? "expired" : item.status,
                startDate: item.startDate,
                endDate: item.endDate,
                generatedBy: item.createdByUser
                    ? `${item.createdByUser.firstName} ${item.createdByUser.lastName}`
                    : "-",
                notes: item.notes || "-",
                usedDate: item.usedDate,
                id: item.id,
                isPaid: item.isPaid
            } as EventCodeListType)
    );
};

export const getCodeList = (eventId: string) =>
    Client.getInstance()
        .getData(ApiConstants.getEventCodesApiUrl(parseInt(eventId)), true)
        .then((response) => Promise.resolve(transformData(response.data.data)))
        .catch((error) => Promise.reject(error));

export const StatusInfoOptions: Record<
    string,
    {
        label: string;
        color: string;
    }
> = {
    active: {
        label: "Active",
        color: "#65B44A"
    },
    started: {
        label: "Started",
        color: "#F2C94C"
    },
    finished: {
        label: "Finished",
        color: "#1F71B6"
    },
    expired: {
        label: "Out of Date",
        color: "#B12A2A"
    }
};

export const filterCodeBySearchText = (rows: EventCodeListType[], searchText: string): any =>
    rows.filter(
        (row: EventCodeListType) =>
            searchText === "" ||
            row.code.toLowerCase().includes(searchText.toLowerCase()) ||
            row.status.toLowerCase().includes(searchText.toLowerCase())
    );

export const codeDelete = (eventId: string, selectedEventCodeId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteEventCodeApiUrl(parseInt(eventId), selectedEventCodeId))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

import React from "react";
import { Button } from "react-bootstrap";
import { hintType } from "./RocketTemplateUtils/RocketTemplateTypes";
import { ScrollViewContainer } from "./style";

type QuizHintsProps = {
    hintCount: number;
    hints: hintType[];
    totalMarks: number;
    getTotalMarks: (marks: number) => void;
    questionTimeEnds: boolean;
    increaseHintCount: (previousHintCount: number, totalMarks: number) => void;
    hintDisable: boolean;
    gameBackgroundColor: string;
    isProgressInPercentage: boolean;
};

const QuizHintsView: React.FC<QuizHintsProps> = ({
    getTotalMarks,
    hintCount,
    hints,
    totalMarks,
    questionTimeEnds,
    increaseHintCount,
    hintDisable,
    gameBackgroundColor,
    isProgressInPercentage
}) => {
    const hintTaken = (hintCount: number): void => {
        getTotalMarks(totalMarks + parseInt(hints[hintCount].scorePenalty));
        increaseHintCount(hintCount, totalMarks + parseInt(hints[hintCount].scorePenalty));
    };

    return (
        <ScrollViewContainer className={"quiz-answer-view"} style={{ backgroundColor: gameBackgroundColor }}>
            <Button
                className={"submit-button-option cursor width-100per"}
                disabled={hintCount === hints.length || hintDisable || questionTimeEnds}
                onClick={() => hintTaken(hintCount)}>
                <span>
                    Hint{" "}
                    {hintCount != hints.length ? hints[hintCount].scorePenalty : hints[hints.length - 1].scorePenalty}
                    {isProgressInPercentage && "%"}
                </span>
            </Button>
            {hints.map((item, index) =>
                index < hintCount ? (
                    <div
                        className={"margin-p-0 hints-section quill-editor-text-align"}
                        key={`${item.questionId}${Math.random()}`}
                        dangerouslySetInnerHTML={{
                            __html: hints[index].description
                        }}
                    />
                ) : (
                    ""
                )
            )}
        </ScrollViewContainer>
    );
};

export default QuizHintsView;

import { Avatar } from "@mui/material";
import { FC } from "react";
import { darkColor } from "../../../WebsiteGame/colorConstant";
import { CaptionText } from "../CommonStyle";
import { LogoNameWrapper } from "./Style";

type LogoNameColumnProps = {
    name: string;
    logo: string;
    onClick?: () => void;
};

const LogoNameColumn: FC<LogoNameColumnProps> = ({ name, logo, onClick }) => (
    <LogoNameWrapper onClick={() => onClick && onClick()}>
        <Avatar style={{ border: "1px grey solid" }} src={logo} />
        <CaptionText color={darkColor} textOverflow={"ellipsis"} overflow={"hidden"}>
            {name}
        </CaptionText>
    </LogoNameWrapper>
);

export default LogoNameColumn;

import { Grid } from "@mui/material";
import FormikTextInput from "../../../../../../CommonComponent/v2/fields/FormikTextInput";
import { useFormikContext } from "formik";
import ImageDrop from "../../../../../../CommonComponent/v2/fields/ImageDrop";
import { CaptionText } from "../../../../../../CommonComponent/v2/CommonStyle";
import { darkPrimaryColor } from "../../../../../../../constants/ColorConstant";
import { AttachmentType } from "./AttachmentTypes";
import FormikRadioGroup from "../../../../../../CommonComponent/v2/fields/FormikRadioGroup";
import { AttachmentOptions } from "../../../../../../../constants/DropdownOption";
import FileDrop from "../../../../../../CommonComponent/v2/fields/FileDrop";
import { VALID_PDF } from "../../../../../../../constants/SiteConstant";

const EditAttachmentForm = () => {
    const { values, setFieldValue } = useFormikContext();
    const data = values as AttachmentType;
    return (
        <Grid container mt={"30px"} spacing={"20px"}>
            <Grid item xs={7}>
                <FormikTextInput name={"title"} id={"title"} label={"Title"} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <FormikRadioGroup
                    row
                    labelfontweight={400}
                    radioOptions={AttachmentOptions}
                    name={"type"}
                    onChange={() => {
                        setFieldValue("file", null);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <CaptionText fontWeight={700} color={darkPrimaryColor}>
                    Attachment file
                </CaptionText>
                {data.type === "image" ? (
                    <ImageDrop
                        imageSize={2}
                        variant={"secondary"}
                        selectedFile={data.imageFile}
                        logoInfo={"Attach Attachment image (800px * 600px)"}
                        name={"imageFile"}
                    />
                ) : (
                    <FileDrop
                        fileSize={5}
                        errorMessage={"Please upload pdf only"}
                        logoInfo={"Attach Attachment pdf"}
                        name={"attachmentFile"}
                        selectedFile={data.attachmentFile}
                        validFilesList={VALID_PDF}
                    />
                )}
            </Grid>
        </Grid>
    );
};
export default EditAttachmentForm;

import { Dialog, Grid } from "@mui/material";
import { Formik } from "formik";
import { FC } from "react";
import { darkPrimaryColor } from "../../../../../../constants/ColorConstant";
import { HintPenaltyTypeOptions } from "../../../../../../constants/DropdownOption";
import LoadingButtonWrapper from "../../../../../CommonComponent/v2/button/LoadingButtonWrapper";
import { CancelButton, CaptionText, Header1, PrimaryButton } from "../../../../../CommonComponent/v2/CommonStyle";
import FormikRadioGroup from "../../../../../CommonComponent/v2/fields/FormikRadioGroup";
import FormikTextEditor from "../../../../../CommonComponent/v2/fields/FormikTextEditor";
import FormikTextInput from "../../../../../CommonComponent/v2/fields/FormikTextInput";
import CloseIcon from "../../../../../Images/icons/close.svg";
import { useAddHintHook } from "./Hooks";
import { HintListItemType } from "./Type";
import { AddHintInitialValue, AddHintValidationSchema } from "./Util";

type AddHintModalProps = {
    handleClose: () => void;
    handleRefresh: () => void;
    hintDetail?: HintListItemType;
};

const AddHintModal: FC<AddHintModalProps> = ({ handleClose, handleRefresh, hintDetail }) => {
    const { saveHint } = useAddHintHook(handleRefresh, handleClose, hintDetail?.id);

    return (
        <Dialog maxWidth={"md"} fullWidth open onClose={handleClose}>
            <Grid p={"30px"}>
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Header1>{hintDetail ? "Update hint" : "Add hint"}</Header1>
                    </Grid>
                    <Grid item>
                        <img onClick={handleClose} alt={"close"} src={CloseIcon} style={{ height: 20 }} />
                    </Grid>
                </Grid>
                <Formik
                    initialValues={AddHintInitialValue(hintDetail)}
                    validationSchema={AddHintValidationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        saveHint(values, setSubmitting);
                        return;
                    }}
                >
                    {({ values, handleSubmit, isSubmitting }) => (
                        <Grid container>
                            <Grid item mt={"20px"} xs={12}>
                                <CaptionText mb={"10px"} fontWeight={700} color={darkPrimaryColor}>
                                    Hint penalty type
                                </CaptionText>
                                <FormikRadioGroup
                                    row
                                    labelfontweight={400}
                                    radioOptions={HintPenaltyTypeOptions}
                                    name={"penaltyType"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {values.penaltyType === "score_penalty" ? (
                                    <Grid item xs={3}>
                                        <FormikTextInput
                                            name={"scorePenalty"}
                                            id={"scorePenalty"}
                                            label={"Score penalty"}
                                            fullWidth
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={3}>
                                        <FormikTextInput
                                            name={"timePenalty"}
                                            id={"timePenalty"}
                                            fullWidth
                                            label={"Time penalty"}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={8} mt={"20px"} mb={"90px"}>
                                <CaptionText mb={"10px"} color={darkPrimaryColor} fontWeight={700}>
                                    Hint text
                                </CaptionText>
                                <FormikTextEditor
                                    basicControl
                                    name={"hintText"}
                                    placeholder={"Enter hint text here..."}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={"center"} spacing={"20px"} justifyContent={"center"}>
                                    <Grid item>
                                        <CancelButton onClick={handleClose}>Cancel</CancelButton>
                                    </Grid>
                                    <Grid item>
                                        <LoadingButtonWrapper loading={isSubmitting}>
                                            <PrimaryButton
                                                onClick={() => handleSubmit()}
                                                style={{ padding: "16px 18px" }}
                                            >
                                                Save hint
                                            </PrimaryButton>
                                        </LoadingButtonWrapper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Grid>
        </Dialog>
    );
};

export default AddHintModal;

import React, { useEffect, useState } from "react";

type QuestionBatchDeleteProps = {
    index: number | undefined;
    setBatchDelete: (value: number[]) => void;
    itemId: number;
    batchDelete: number[];
    batchDeleteAll: boolean;
};

const QuestionBatchDelete: React.FC<QuestionBatchDeleteProps> = ({
    index,
    itemId,
    setBatchDelete,
    batchDelete,
    batchDeleteAll
}) => {
    const [deleteUnchecked, setDeleteUnchecked] = useState<boolean>(true);

    useEffect(() => {
        if (batchDelete.find((id) => id === itemId) && batchDeleteAll) {
            setDeleteUnchecked(false);
            return;
        }
        setDeleteUnchecked(true);
    }, [batchDeleteAll]);

    const handleBatchDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteUnchecked(!deleteUnchecked);
        if (deleteUnchecked) {
            setBatchDelete([...batchDelete, Number(e.target.value)]);
            return;
        }
        const filteredBatch = batchDelete.filter((id: number) => id !== Number(e.target.value));
        setBatchDelete(filteredBatch);
    };

    return (
        <div style={{ display: "flex", gap: 5 }}>
            <div style={{ marginTop: 3 }}>
                <input type="checkbox" checked={!deleteUnchecked} value={itemId} onChange={handleBatchDelete} />
            </div>
            <div>{index?.toString() && index + 1}</div>
        </div>
    );
};

export default QuestionBatchDelete;

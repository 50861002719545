import { Grid } from "@mui/material";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { CancelButton, Header1, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import LoadingWrapper from "../../../../CommonComponent/v2/LoadingWrapper";
import ArrowLeft from "../../../../Images/icons/arrowLeft.svg";
import DecideGame from "../addeventdetail/DecideGame";
import LaunchEvent from "../addeventdetail/LaunchEvent";
import ProvideDetails from "../addeventdetail/ProvideDetails";
import { eventDetailInitialValues, eventValidationSchema } from "../addeventdetail/Util";
import { useEditEventDetailHook } from "./Hooks";

const UpdateEventDetail = () => {
    const { eventDetails, id, isLoading, history, saveData } = useEditEventDetailHook();
    const searchParams = new URLSearchParams(window.location.search);
    return (
        <Grid px={"20px"}>
            <Grid container>
                <Grid item xs={12}>
                    <Header1>
                        <img
                            onClick={() =>
                                history.push(
                                    `/admin/event/${id}/info${
                                        searchParams.get("searchtext")
                                            ? `?searchtext=${searchParams.get("searchtext")} `
                                            : ""
                                    }`
                                )
                            }
                            src={ArrowLeft}
                            alt={"back"}
                            style={{ height: 18, marginRight: 10, cursor: "pointer" }}
                        />
                        Edit Event
                    </Header1>
                </Grid>
                <Grid item xs={12}>
                    <LoadingWrapper isLoading={isLoading}>
                        <Formik
                            initialValues={eventDetailInitialValues({
                                eventDetails
                            })}
                            validationSchema={eventValidationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                saveData(values, setSubmitting);
                            }}
                            enableReinitialize
                            validateOnMount>
                            {({ handleSubmit, errors }) => (
                                <Grid container>
                                    <Grid item xs={7}>
                                        <DecideGame isUpdate />
                                        <ProvideDetails isUpdate />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LaunchEvent isUpdate prevLinkName={eventDetails?.linkName || ""} />
                                    </Grid>
                                    <Grid item xs={10} my={"50px"}>
                                        <Grid container justifyContent={"center"}>
                                            <Grid item>
                                                <div style={{ display: "flex", gap: "20px" }}>
                                                    <CancelButton
                                                        onClick={() => history.push(`/admin/event/${id}/info`)}>
                                                        Back
                                                    </CancelButton>
                                                    <PrimaryButton
                                                        onClick={() => handleSubmit()}
                                                        disabled={!isEmpty(errors)}>
                                                        Update
                                                    </PrimaryButton>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Formik>
                    </LoadingWrapper>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UpdateEventDetail;

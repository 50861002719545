/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
    CodeInputLogo,
    ContentBox,
    CustomInputBox,
    InfoText,
    LoginCircleButtonBig,
    WildGoatsCenterContainer,
    WildGoatsContainer
} from "../../../WildGoat/CommonStyledComponents/CommonStyle";
import Header from "../../../WildGoat/Header/Header";
import { MainImageContainer, WelcomeText } from "../../../WildGoat/WelcomeScreens/Style";
import { InputGroup } from "react-bootstrap";
import { Icon } from "@fluentui/react";
import { EventPathDetailsType, QuizPathDetailsType } from "../type";
import { getOffsetColor } from "../../../WildGoat/Util/Util";
import {
    pathQuizPrimaryColor,
    pathQuizSecondaryColor,
    pathQuizTertiaryColor
} from "../../../../../constants/ColorConstant";
import loadingGif from "../../../../Images/loading.gif";
import { usePathChallengeCodeInput } from "./hook";
import welcomeImage from "../../../../Images/Wildgoat/Welcome.svg";

type PathChallengeCodeInputProps = {
    eventInfo: EventPathDetailsType;
    quizInfo: QuizPathDetailsType;
    eventLink: string;
};
const PathChallengeCodeInput = ({ eventInfo, quizInfo, eventLink }: PathChallengeCodeInputProps) => {
    const { code, loading, loginFailed, name, setCode, setLoginFailed, setName, moveToTeamNameLink } =
        usePathChallengeCodeInput(eventInfo, quizInfo, eventLink);
    return (
        <WildGoatsContainer
            bgcolor={quizInfo.quizConfig?.wildgoatPrimaryColor || pathQuizPrimaryColor}
            bgcolor_offset={getOffsetColor(quizInfo.quizConfig?.wildgoatPrimaryColor || pathQuizPrimaryColor)}>
            <Header
                quizTitle={quizInfo.displayTitle}
                companyLogo={eventInfo.companyLogo}
                quizLogo={eventInfo.logo}
                secondaryColor={quizInfo.quizConfig?.wildgoatSecondaryColor || pathQuizSecondaryColor}
            />
            <WildGoatsCenterContainer style={{ marginTop: 11 }}>
                <ContentBox>
                    <WildGoatsCenterContainer>
                        <MainImageContainer>
                            <CodeInputLogo src={eventInfo?.logo || welcomeImage} alt="welcome" />
                        </MainImageContainer>
                    </WildGoatsCenterContainer>
                    <WelcomeText>Welcome</WelcomeText>
                    <InfoText>Please input the code to start the game</InfoText>
                    <InputGroup onFocus={() => setLoginFailed(false)} size="sm" className={"margin-top-15"}>
                        <CustomInputBox
                            placeholder={"Type code here..."}
                            value={code}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setCode(target.value)}
                        />
                    </InputGroup>
                    <InfoText style={{ marginTop: 20 }}>Enter your name to get started.</InfoText>
                    <InputGroup size="sm" className={"margin-top-15"}>
                        <CustomInputBox
                            placeholder={"Enter your name here..."}
                            value={name}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setName(target.value)}
                        />
                    </InputGroup>
                    {loginFailed && <span className={"error-message"}>Please enter a valid Code</span>}
                    <LoginCircleButtonBig
                        secondaryColor={quizInfo.quizConfig?.wildgoatSecondaryColor || pathQuizSecondaryColor}
                        tertiarycolor={quizInfo.quizConfig?.wildgoatTertiaryColor || pathQuizTertiaryColor}
                        disabled={code === "" || name === "" || loading}
                        onClick={moveToTeamNameLink}>
                        {loading ? (
                            <img src={loadingGif} style={{ height: 10 }} alt={"loading"} />
                        ) : (
                            <Icon iconName={"ChevronRight"} style={{ fontSize: 20 }} />
                        )}
                    </LoginCircleButtonBig>
                </ContentBox>
            </WildGoatsCenterContainer>
        </WildGoatsContainer>
    );
};

export default PathChallengeCodeInput;

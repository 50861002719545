import Styled from "@emotion/styled";

export const LogoContainer = Styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const LogoImage = Styled.img`
    width: 65px;
    object-fit: contain;
`;

export const UrlDivider = Styled.hr`
    width: 5%;
    border: 1px solid #C2C2C2;
    margin-top: 30px;
`;

import { Label, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { useState } from "react";
import { Client } from "../../Base/Client";
import TextFieldWrapper from "../../CommonComponent/TextFieldWrapper";
import { ApiConstants } from "../../../constants/ApiConstant";
import { ThemedMediumStackTokens } from "../../../constants/Styles";
import { LoginUtil } from "../../../utils/LoginUtils";
import { useAdminContext } from "../context/AdminAuthContext";
import { transformLoginData } from "./util";
import { Typography } from "@mui/material";
import { linkColor } from "../../../constants/ColorConstant";
import { useHistory } from "react-router";
import logo from "../../Images/logo.svg";
import "./LoginComponent.scss";
import { Button } from "react-bootstrap";

type ILoginComponentProps = {
    onLogin: () => void;
};

const Login: React.FC<ILoginComponentProps> = ({ onLogin }) => {
    const history = useHistory();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setErrorMessage] = useState(false);
    const { setUserDetail } = useAdminContext();
    const authenticateUser = (): void => {
        setErrorMessage(false);
        setLoading(true);
        Client.getInstance()
            .createData(ApiConstants.authenticateUserApiUrl(), { email: userName, password: password })
            .then((response: any) => {
                Client.getInstance().setAuthenticationClient(response.headers.authorization);
                LoginUtil.setUserSession(response.headers.authorization, response.data);
                setUserDetail(transformLoginData(response.data.data));
                onLogin();
            })
            .catch(() => {
                setErrorMessage(true);
                setLoading(false);
                setPassword("");
            });
    };

    const onKeyboardLogin = (keyCode: string): void => {
        if (keyCode === "Enter") {
            authenticateUser();
        }
    };

    return (
        <div className={"login-main-container"}>
            <Stack horizontalAlign={"center"} verticalAlign={"center"} className={"height-100per"}>
                <Stack className={"event-parent"} tokens={ThemedMediumStackTokens}>
                    <Stack horizontalAlign={"center"} className={"event-logo"}>
                        <img src={logo} />
                    </Stack>
                    <Stack className="event-child" tokens={ThemedMediumStackTokens}>
                        <Stack tokens={ThemedMediumStackTokens}>
                            <TextFieldWrapper
                                onTextFieldValueChange={(text: string) => setUserName(text)}
                                value={userName}
                                label={"Email"}
                                id={"userName"}
                                onKeydown={(keyCode: string) => onKeyboardLogin(keyCode)}
                            />
                            <TextFieldWrapper
                                type={"password"}
                                onTextFieldValueChange={(text: string) => setPassword(text)}
                                value={password}
                                label={"Password"}
                                id={"password"}
                                canRevealPassword
                                onKeydown={(keyCode: string) => onKeyboardLogin(keyCode)}
                            />
                            <Typography
                                sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                        textDecoration: "underline"
                                    }
                                }}
                                onClick={() => history.push("/forgotpassword")}
                                color={linkColor}
                                variant="body2"
                                mt={"10px"}>
                                Forgot Password?
                            </Typography>
                            {error && (
                                <Stack horizontal horizontalAlign={"center"}>
                                    <Label className={"error-message loginErrorMessageTc"}>
                                        Please enter correct username and password
                                    </Label>
                                </Stack>
                            )}

                            <Stack horizontal horizontalAlign={"center"}>
                                <Button disabled={loading} className={"login-button"} onClick={authenticateUser}>
                                    {loading ? <Spinner size={SpinnerSize.large} /> : "Login"}
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </div>
    );
};

export default Login;

import { TeamRecord } from "./Types";

export  const sortLeaderboard = (teamRecords:TeamRecord[]) => {
    
    return (
            teamRecords
            .filter((item) => !!item.teamName)
            .sort((a: TeamRecord, b: TeamRecord) =>
                a.score < b.score
                    ? 1
                    : a.score === b.score
                    ? a.timeTakenInQuiz > b.timeTakenInQuiz
                        ? 1
                        : -1
                    : -1
                    )
            );
};
import { DefaultButton, IconButton, Image, Label, Spinner, SpinnerSize, Stack } from "office-ui-fabric-react";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { ThemedMediumStackTokens, ThemedSmall2StackTokens } from "../../../../constants/Styles";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { Client } from "../../../Base/Client";
import TextFieldWrapper from "../../../CommonComponent/TextFieldWrapper";
import { DotContainer } from "../../Quiz/QuizDetails/Style";

type ReferenceListTemplateProps = {
    referenceId: number;
    centerHeading: string;
    centerHeadingChange: (centerHeading: string) => void;
    attachmentTitle: string;
    attachmentTitleChange: (attachmentTitle: string) => void;
    backgroundImage: string;
    backgroundImageChange: (backgroundImage: string) => void;
    progressImage: string;
    progressImageChange: (progressImage: string) => void;
    setBackgroundImageFile: (file: File | undefined) => void;
    setProgressImageFile: (file: File | undefined) => void;
    baseColor: string;
    setBaseColorChange: (baseColor: string) => void;
    setShowColorPickerPanel: (type: string) => void;
    buttonColor: string;
    setBaseButtonColor: (buttonColor: string) => void;
    setColorPicker: (color: string) => void;
};

const ReferenceListTemplate: React.FC<ReferenceListTemplateProps> = ({
    attachmentTitle,
    attachmentTitleChange,
    backgroundImage,
    backgroundImageChange,
    progressImage,
    progressImageChange,
    centerHeading,
    centerHeadingChange,
    setBackgroundImageFile,
    setProgressImageFile,
    referenceId,
    baseColor,
    setBaseColorChange,
    setShowColorPickerPanel,
    buttonColor,
    setBaseButtonColor,
    setColorPicker
}) => {
    const [backgroundImageError, setBackgroundImageError] = useState("");
    const [progressImageError, setProgressImageError] = useState("");
    const [isRemovingBackgroundImage, setIsRemovingBackgroundImage] = useState(false);
    const [isRemovingProgressImage, setIsRemovingProgressImage] = useState(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, image: string): void => {
        if (!event.target.files || (event.target.files && event.target.files.length === 0)) {
            return;
        }

        if (!FieldValidation.imageTypeValidation(event.target.files[0].type)) {
            if (image === SiteConstants.PROGRESS_IMAGE) {
                progressImageChange("");
                setProgressImageError("Please select an image file");
                setProgressImageFile(undefined);
                return;
            }
            backgroundImageChange("");
            setBackgroundImageError("Please select an image file");
            setBackgroundImageFile(undefined);
            return;
        }
        if (FieldValidation.imageSizeValidation(event.target.files[0].size)) {
            if (image === SiteConstants.PROGRESS_IMAGE) {
                progressImageChange("");
                setProgressImageError("Image size should be less than 2 MB");
                setProgressImageFile(undefined);
                return;
            }
            backgroundImageChange("");
            setBackgroundImageError("Image size should be less than 2 MB");
            setBackgroundImageFile(undefined);
            return;
        }
        if (image === SiteConstants.PROGRESS_IMAGE) {
            setProgressImageError("");
            progressImageChange(URL.createObjectURL(event.target.files[0]));
            setProgressImageFile(event.target.files[0]);
            return;
        }
        backgroundImageChange(URL.createObjectURL(event.target.files[0]));
        setBackgroundImageError("");
        setBackgroundImageFile(event.target.files[0]);
        return;
    };

    const removeImage = (image: string, imageUrl: string) => {
        if (referenceId !== -1) {
            image === SiteConstants.BACKGROUND_IMAGE
                ? setIsRemovingBackgroundImage(true)
                : setIsRemovingProgressImage(true);
            Client.getInstance()
                .deleteData(imageUrl)
                .then(() => {
                    if (image === SiteConstants.BACKGROUND_IMAGE) {
                        setIsRemovingBackgroundImage(false);
                        backgroundImageChange("");
                        setBackgroundImageFile(undefined);
                        toast.success("Background image removed", SiteConstants.successToastConfiguration);
                        return;
                    }
                    setIsRemovingProgressImage(false);
                    progressImageChange("");
                    setProgressImageFile(undefined);
                    toast.success("Progress image removed", SiteConstants.successToastConfiguration);
                })
                .catch((error) => {
                    if (image === SiteConstants.BACKGROUND_IMAGE) {
                        setIsRemovingBackgroundImage(false);
                        backgroundImageChange("");
                        setBackgroundImageFile(undefined);
                        toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                        return;
                    }
                    setIsRemovingProgressImage(false);
                    setIsRemovingProgressImage(false);
                    progressImageChange("");
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
            return;
        }

        if (image === SiteConstants.COVER_IMAGE) {
            progressImageChange("");
            setProgressImageFile(undefined);
            return;
        }
        backgroundImageChange("");
        setBackgroundImageFile(undefined);
        return;
    };

    return (
        <Stack tokens={ThemedMediumStackTokens}>
            <Stack tokens={ThemedMediumStackTokens} className={"section-background section-border"}>
                <TextFieldWrapper
                    label={"Center heading"}
                    value={centerHeading}
                    onTextFieldValueChange={(text) => centerHeadingChange(text)}
                    name={"centerHeading"}
                />
                <TextFieldWrapper
                    label={"Attachment title"}
                    value={attachmentTitle}
                    onTextFieldValueChange={(text) => attachmentTitleChange(text)}
                    name={"attachmentTitle"}
                />

                <Stack.Item>
                    <Label>Background image</Label>
                    <Stack horizontal>
                        <Stack.Item grow>
                            <Form.Group>
                                <Form.File
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleFileChange(event, SiteConstants.BACKGROUND_IMAGE)
                                    }
                                />
                                {backgroundImageError && (
                                    <span className={"error-message"}>{backgroundImageError}</span>
                                )}
                            </Form.Group>
                        </Stack.Item>
                        <Stack horizontalAlign={"end"} verticalAlign={"start"}>
                            {isRemovingBackgroundImage ? (
                                <Spinner size={SpinnerSize.large} />
                            ) : (
                                <DefaultButton
                                    onClick={() =>
                                        removeImage(
                                            SiteConstants.BACKGROUND_IMAGE,
                                            ApiConstants.removeReferenceBackgroundImage(referenceId)
                                        )
                                    }
                                    disabled={backgroundImage === ""}
                                    className={"dashed-border-default-button"}
                                    text={"Remove Background Image"}
                                />
                            )}
                        </Stack>
                    </Stack>
                    {backgroundImage && !backgroundImageError && (
                        <Image src={backgroundImage} alt="Background Image" className="question-image" />
                    )}
                </Stack.Item>
                <Stack.Item>
                    <Label>Progress image</Label>
                    <Stack horizontal>
                        <Stack.Item grow>
                            <Form.Group>
                                <Form.File
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleFileChange(event, SiteConstants.PROGRESS_IMAGE)
                                    }
                                />
                                {progressImageError && <span className={"error-message"}>{progressImageError}</span>}
                            </Form.Group>
                        </Stack.Item>
                        <Stack horizontalAlign={"end"} verticalAlign={"start"}>
                            {isRemovingProgressImage ? (
                                <Spinner size={SpinnerSize.large} />
                            ) : (
                                <DefaultButton
                                    onClick={() =>
                                        removeImage(
                                            SiteConstants.PROGRESS_IMAGE,
                                            ApiConstants.removeReferenceProgressImage(referenceId)
                                        )
                                    }
                                    disabled={progressImage === ""}
                                    className={"dashed-border-default-button"}
                                    text={"Remove Progress Image"}
                                />
                            )}
                        </Stack>
                    </Stack>
                    {progressImage && !progressImageError && (
                        <Image src={progressImage} alt="Progress Image" className="question-image" />
                    )}
                </Stack.Item>
                <Stack horizontal tokens={ThemedSmall2StackTokens}>
                    <TextFieldWrapper
                        label={"Background color"}
                        value={baseColor}
                        onTextFieldValueChange={(text) => setBaseColorChange(text)}
                        name={"attachmentTitle"}
                    />
                    <Stack.Item align={"end"}>
                        <div style={{ display: "flex" }}>
                            {baseColor && (
                                <DotContainer iconProps={{ iconName: "LocationDot" }} size={74} color={baseColor} />
                            )}
                            <IconButton
                                iconProps={{ iconName: "Color" }}
                                onClick={() => {
                                    setShowColorPickerPanel("backgroundColor");
                                    setColorPicker(baseColor);
                                }}
                            />
                        </div>
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={ThemedSmall2StackTokens}>
                    <TextFieldWrapper
                        label={"Button color"}
                        value={buttonColor}
                        onTextFieldValueChange={(text) => setBaseButtonColor(text)}
                        name={"attachmentTitle"}
                    />
                    <Stack.Item align={"end"}>
                        <div style={{ display: "flex" }}>
                            {buttonColor && (
                                <DotContainer iconProps={{ iconName: "LocationDot" }} size={74} color={buttonColor} />
                            )}
                            <IconButton
                                iconProps={{ iconName: "Color" }}
                                onClick={() => {
                                    setShowColorPickerPanel("buttonColor");
                                    setColorPicker(buttonColor);
                                }}
                            />
                        </div>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default ReferenceListTemplate;

import { useField } from "formik";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useDebounce } from "../../../../commonhooks/Hooks";
import { CaptionText } from "../CommonStyle";
import { EditorContainer, EditorWrapper, StyledTextEditorContainer } from "./Style";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ReactQuill = require("react-quill");

type FormikTextEditorProps = {
    basicControl?: boolean;
    showFontSizeControl?: boolean;
    isSimple?: boolean;
    id?: string;
    name: string;
    placeholder?: string;
};
const FormikTextEditor: React.FC<FormikTextEditorProps> = ({
    basicControl,
    showFontSizeControl,
    isSimple,
    id,
    name,
    placeholder
}) => {
    const [_, { error, touched, value }, { setValue, setTouched }] = useField(name);
    const [text, setText] = useState(value);

    const debounceValue = useDebounce(text, 100);

    const getValue = (text: string) => {
        if (text.substr(text.length - 11) === "<p><br></p>") {
            return (text = text.substr(0, text.length - 11) + "<p>&#8205;</p>");
        }
        return text;
    };

    useEffect(() => {
        if (debounceValue === "<p>‍</p>") {
            setValue("");
        } else {
            setValue(debounceValue);
        }
    }, [debounceValue]);

    return (
        <StyledTextEditorContainer iserror={!!touched && !!error}>
            <EditorWrapper>
                <EditorContainer>
                    <ReactQuill
                        id={id}
                        placeholder={placeholder}
                        onBlur={() => setTouched(true)}
                        modules={
                            isSimple
                                ? {
                                      toolbar: []
                                  }
                                : basicControl
                                ? {
                                      toolbar: [
                                          [
                                              showFontSizeControl && { header: [1, 2, false] },
                                              "bold",
                                              "italic",
                                              "underline"
                                          ],
                                          [{ align: [] }],
                                          [{ color: [] }]
                                      ]
                                  }
                                : {
                                      toolbar: [
                                          [{ header: [1, 2, false] }],
                                          ["bold", "italic", "underline"],
                                          [{ list: "ordered" }, { list: "bullet" }],
                                          [{ align: [] }],
                                          ["image"],
                                          ["video"],
                                          [{ color: [] }]
                                      ]
                                  }
                        }
                        formats={
                            basicControl
                                ? ["header", "bold", "italic", "underline", "color", "align"]
                                : [
                                      "header",
                                      "bold",
                                      "italic",
                                      "underline",
                                      "list",
                                      "bullet",
                                      "align",
                                      "indent",
                                      "image",
                                      "video",
                                      "color"
                                  ]
                        }
                        onChange={(text: string) => setText(text)}
                        value={text && getValue(text)}
                    />
                    {!!touched && !!error && (
                        <CaptionText mt={"50px"} color={"red"}>
                            {error}
                        </CaptionText>
                    )}
                </EditorContainer>
            </EditorWrapper>
        </StyledTextEditorContainer>
    );
};
export default FormikTextEditor;

import { Drawer, Grid } from '@mui/material';
import { Formik } from 'formik';
import React, { FC } from 'react';
import { Node } from 'react-flow-renderer';
import { TriggerOption } from '../../../../constants/DropdownOption';
import { CancelButton, Header1, PrimaryButton } from '../../../CommonComponent/v2/CommonStyle';
import FormikSelect from '../../../CommonComponent/v2/fields/FormikSelect';
import FormikTextInput from '../../../CommonComponent/v2/fields/FormikTextInput';
import { OptionType } from '../../../CommonComponent/v2/fields/Type';
import { TriggersType } from './Type';

type AddNewTriggerProps = {
    handleClose: () => void;
    addTrigger: (triggerInfo: TriggersType) => void;
    branches: Node[];
    triggerInfo?: TriggersType;
    deleteTrigger: () => void;
    triggerBranchId: string;
}

const AddNewTrigger: FC<AddNewTriggerProps> = ({
    handleClose,
    addTrigger,
    branches,
    triggerInfo,
    deleteTrigger,
    triggerBranchId
}) => {

    const options: OptionType[] = branches.map((item: any) => ({
        value: item.id,
        label: item.branchInfo.branchName
    }));

    return (
        <Drawer
            anchor={"right"}
            open
            PaperProps={{
                style: {
                    width: 800
                }
            }}
            onClose={handleClose}
        >
            <Grid container p={"30px"}>
                <Header1>{triggerInfo ? "Update Trigger Detail" : "Add Trigger Detail"}</Header1>
                <Formik
                    initialValues={{
                        triggerId: triggerInfo?.triggerId || Math.random().toString(),
                        triggerName: triggerInfo?.triggerName || "",
                        triggerType: triggerInfo?.triggerType || "",
                        keyword: triggerInfo?.keyword || "",
                        targetBranch: triggerInfo?.targetBranch || ""
                    }}
                    onSubmit={(values) => {
                        addTrigger(values);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Grid container spacing={"20px"} mt={"40px"}>
                            <Grid item xs={12}>
                                <FormikTextInput
                                    name={"triggerName"}
                                    id={"triggerName"}
                                    label={"Trigger Name"}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikSelect
                                    name={"triggerType"}
                                    id={"triggerType"}
                                    label={"Trigger type"}
                                    options={TriggerOption}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextInput
                                    name={"keyword"}
                                    id={"keyword"}
                                    label={"Keyword"}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikSelect
                                    name={"targetBranch"}
                                    id={"targetBranch"}
                                    label={"Target Branch"}
                                    options={options.filter((item) => item.value !== triggerBranchId)}
                                />
                            </Grid>
                            <Grid item xs={12} mt={"30px"}>
                                <Grid container alignItems={"center"} spacing={"20px"} justifyContent={"center"}>
                                    {
                                        triggerInfo && <Grid item>
                                            <CancelButton
                                                onClick={() => {
                                                    deleteTrigger();
                                                }}>
                                                Delete Trigger
                                            </CancelButton>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <PrimaryButton
                                            onClick={() => {
                                                handleSubmit();
                                            }}>
                                            Save
                                        </PrimaryButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Grid>
        </Drawer>
    );
};

export default AddNewTrigger;

import Styled from "@emotion/styled";
import { Box, Tab } from "@mui/material";

export const TabContainer = Styled(Box)`
    background: #F2F2F2 !important;
    border-radius: 10px !important;
    padding-top: 10px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
`;

export const StyledTab = Styled(Tab)`
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    text-align: center !important;
    color: #2F80ED !important;
    &.MuiButtonBase-root {
        outline: none !important;
        padding: 12px 16px; 
    }
`;

import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import { getPermissions } from "../../../../../../utils/PermissionUtils";
import { getErrorMessage } from "../../../../../../utils/APIErrorMessages";
import { useAdminContext } from "../../../../context/AdminAuthContext";
import { cloneSelectedEvent, closeEventStatus } from "./Util";

export const useEventDetailHook = (refreshList: () => void) => {
    const [closeEvent, setCloseEvent] = useState(false);
    const [cloneEvent, setCloneEvent] = useState(false);
    const { id } = useParams<{
        id: string;
    }>();
    const history = useHistory();
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "event");

    const toggleCloseEvent = () => {
        setCloseEvent((prev) => !prev);
    };

    const toggleCloneEvent = () => {
        setCloneEvent((prev) => !prev);
    };

    const onConfirmEventClone = () => {
        cloneSelectedEvent(id)
            .then((eventId) => {
                history.push(`/admin/event/${eventId}/info`);
                toast.success("Event cloned", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => setCloneEvent(false));
    };

    const onCloseEvent = () => {
        closeEventStatus(id)
            .then(() => {
                toast.success("Event closed", SiteConstants.successToastConfiguration);
                refreshList();
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setCloseEvent(false);
            });
    };

    return {
        toggleCloseEvent,
        closeEvent,
        onCloseEvent,
        toggleCloneEvent,
        cloneEvent,
        onConfirmEventClone,
        permission
    };
};

export const useEventLinkHook = () => {
    const [qrCodeLink, setQrCodeLink] = useState("");
    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const togglePreview = () => {
        setShowPreviewModal((prev) => !prev);
    };

    return {
        qrCodeLink,
        setQrCodeLink,
        showPreviewModal,
        togglePreview
    };
};

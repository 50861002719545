import Styled from "@emotion/styled";
import colorIcon from "../../../../Images/icons/colorIcon.svg";

type ColorProp = {
    color: string;
};
export const ColorInput = Styled.input<ColorProp>`
background-color: ${(props) => props.color};
  height: 20px;
  padding: 0;
  width: 20px;
  border: 0px;
  background: url(${colorIcon});
  background-size: cover;
  background-repeat: no-repeat;
  &::-webkit-color-swatch {
    display: none;
  }
  &::-moz-color-swatch {
    display: none;
  }  
`;

import { ApiConstants } from "../../../../../../../constants/ApiConstant";
import { Client } from "../../../../../../Base/Client";
import { reverseTransformData } from "../addcode/Util";
import { EventCodeListType } from "../Type";

export const editCodeUtil = (values: EventCodeListType, eventId: string) =>
    Client.getInstance()
        .updateData(ApiConstants.updateEventCodeApiUrl(parseInt(eventId), values.id), {
            ...reverseTransformData({
                endDate: values.endDate,
                generatedBy: values.generatedBy,
                notes: values.notes,
                participantCode: values.code,
                startDate: values.startDate,
                status: values.status
            }),
            status: values.status
        })
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

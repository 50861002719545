import { Spinner, SpinnerSize } from "@fluentui/react";
import { Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MAP_KEY } from "../../../../constants/SiteConstant";
import { CustomModal } from "../WildgoatDialog/Style";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { GoogleMap, useLoadScript, Marker } = require("@react-google-maps/api");

type ParticipantLocationMapProps = {
    handleClose: () => void;
};
const ParticipantLocationMap: FC<ParticipantLocationMapProps> = ({ handleClose }) => {
    const containerStyle = {
        width: "100%",
        height: "80vh"
    };

    const [location, setLocation] = useState({ latitude: 0, longitude: 0 });
    const [fetchingYourLocation, setFetchingYourLocation] = useState(false);

    useEffect(() => {
        setFetchingYourLocation(true);
        window.navigator.geolocation.watchPosition((location: any) => {
            setLocation({
                latitude: location.coords.latitude,
                longitude: location.coords.longitude
            });
            setFetchingYourLocation(false);
        });
    }, []);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: MAP_KEY
    });

    const renderView = () => {
        if (loadError) {
            return (
                <Grid height={"80vh"} container justifyContent="center" alignItems="center">
                    <Typography variant="h5">Error loading maps</Typography>
                </Grid>
            );
        }

        if (!isLoaded) {
            return (
                <Grid height={"80vh"} container justifyContent="center" alignItems="center">
                    <Spinner label="Loading Maps. Please wait" size={SpinnerSize.large} />
                </Grid>
            );
        }
        if (fetchingYourLocation) {
            return (
                <Grid height={"80vh"} container justifyContent="center" alignItems="center">
                    <Spinner label="Fetching your location. Please wait" size={SpinnerSize.large} />
                </Grid>
            );
        }
        return (
            <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={14}
                center={{ lat: location.latitude, lng: location.longitude }}>
                <Marker position={{ lat: location.latitude, lng: location.longitude }} />
            </GoogleMap>
        );
    };

    return (
        <CustomModal show={true} size="lg" centered>
            <Modal.Header closeButton onClick={handleClose}></Modal.Header>
            <Modal.Body>{renderView()}</Modal.Body>
        </CustomModal>
    );
};

export default ParticipantLocationMap;

import { FC, ReactNode } from "react";
import {
    CloneIcon,
    ColumnAction,
    ColumnHeader,
    ColumnsText,
    DeleteIcon,
    DownloadIcon,
    EditIcon,
    RocketIcon,
    ViewIcon,
    ChangePermissionIcon,
    RestoreIcon,
    RemoveIcon
} from "./Style";
import { ActionType } from "./Type";
import UrlImage from "../../../Images/icons/rocket.svg";
import CopyImage from "../../../Images/icons/copy.svg";
import { TypographyProps } from "@mui/material";

type StyledColumnProps = {
    label: string;
    color?: string;
    fontWeight?: string | number;
} & TypographyProps;
export const StyledColumn: FC<StyledColumnProps> = ({ label, color, fontWeight, ...rest }) => (
    <ColumnsText color={color} fontWeight={fontWeight} {...rest}>
        {label}
    </ColumnsText>
);
export const StyledText: FC<StyledColumnProps> = ({ label }) => <ColumnsText>{label}</ColumnsText>;
export const StyledHeader: FC<StyledColumnProps> = ({ label }) => <ColumnHeader>{label}</ColumnHeader>;

type StyledActionColumnProps = {
    isEditAvailaible?: ActionType;
    isDeleteAvailaible?: ActionType;
    isCloneAvailaible?: ActionType;
    isViewAvailaible?: ActionType;
    isDownloadAvailaible?: ActionType;
    isUrlLinkAvailaible?: ActionType;
    isChangePermissionAvailaible?: ActionType;
    isCopyAvailaible?: ActionType;
    isRestoreAvailiable?: ActionType;
    additionalIcons?: ReactNode;
    isRemoveAvailaible?: ActionType;
};
export const StyledActionColumn: FC<StyledActionColumnProps> = ({
    isCloneAvailaible,
    isEditAvailaible,
    isDeleteAvailaible,
    isViewAvailaible,
    isDownloadAvailaible,
    isUrlLinkAvailaible,
    isChangePermissionAvailaible,
    isCopyAvailaible,
    isRestoreAvailiable,
    additionalIcons,
    isRemoveAvailaible
}) => (
    <ColumnAction>
        {isUrlLinkAvailaible?.isAvailable && <RocketIcon src={UrlImage} onClick={isUrlLinkAvailaible.onClick} />}
        {isViewAvailaible?.isAvailable && <ViewIcon onClick={isViewAvailaible.onClick} />}
        {isCloneAvailaible?.isAvailable && <CloneIcon onClick={isCloneAvailaible.onClick} />}
        {isCopyAvailaible?.isAvailable && <RocketIcon src={CopyImage} onClick={isCopyAvailaible.onClick} />}
        {isEditAvailaible?.isAvailable && <EditIcon onClick={isEditAvailaible.onClick} />}
        {isDeleteAvailaible?.isAvailable && <DeleteIcon onClick={isDeleteAvailaible.onClick} />}
        {isRemoveAvailaible?.isAvailable && <RemoveIcon onClick={isRemoveAvailaible.onClick} />}
        {isDownloadAvailaible?.isAvailable && <DownloadIcon onClick={isDownloadAvailaible.onClick} />}
        {isChangePermissionAvailaible?.isAvailable && (
            <ChangePermissionIcon onClick={isChangePermissionAvailaible.onClick} />
        )}
        {isRestoreAvailiable?.isAvailable && <RestoreIcon onClick={isRestoreAvailiable.onClick} />}
        {additionalIcons}
    </ColumnAction>
);

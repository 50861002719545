import { Grid } from "@mui/material";
import SearchHeader from "../../../../../CommonComponent/v2/searchheader/SearchHeader";
import { useItem } from "./Hooks";
import { useHistory, useParams } from "react-router";
import ReferenceItem from "./ReferenceItem";
import { filterDataBySearchText } from "./Utils";
import ConfirmationBox from "../../../../../CommonComponent/v2/ConfirmationBox";
const Items = () => {
    const history = useHistory();
    const { id } = useParams<{
        id: string;
    }>();
    const {
        searchText,
        itemlist,
        isLoading,
        selectedItemDeleteId,
        setSearchText,
        onItemDelete,
        onItemDeleteClose,
        setSelectedItemDeleteId
    } = useItem();
    return (
        <Grid container mt={"30px"} spacing={"20px"}>
            <Grid item xs={12}>
                <SearchHeader
                    searchText={searchText}
                    setSearchText={setSearchText}
                    showAddButton
                    onButtonClick={() => history.push(`/admin/v2/referencelistmanager/${id}/refitem/addItem`)}
                    buttonLabel={"Add new "}
                />
            </Grid>
            <Grid mt={"30px"} item xs={12}>
                <ReferenceItem
                    referenceItems={filterDataBySearchText(searchText, itemlist)}
                    setItemDeleteId={setSelectedItemDeleteId}
                    isLoading={isLoading}
                />
            </Grid>
            {selectedItemDeleteId && (
                <ConfirmationBox
                    title={`Delete "${selectedItemDeleteId.name}" item`}
                    message={"Please confirm if you really want to delete this item"}
                    onConfirm={onItemDelete}
                    onClose={onItemDeleteClose}
                />
            )}
        </Grid>
    );
};
export default Items;

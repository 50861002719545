import { ApiConstants } from "../../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { Client } from "../../../Base/Client";
import { IdentityListtype } from "./Type";

const tranformData = (data: any): IdentityListtype[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        id: item.id,
        createdAt: JambarDateUtil.formatDate(item.createdAt),
        name: item.name,
        updatedAt: JambarDateUtil.formatDate(item.updatedAt),
        createdBy: item.createdBy
    }));
};

export const getAllIdentitiesList = (forceRefresh = false): any =>
    Client.getInstance()
        .getData(ApiConstants.getAllIdentitiesList(), forceRefresh)
        .then((res) => Promise.resolve(tranformData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const addIdentity = (name: string): any =>
    Client.getInstance()
        .createData(ApiConstants.addIdentitiyApiUrl(), {
            name: name
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const updateIdentity = (name: string, id: number): any =>
    Client.getInstance()
        .updateData(ApiConstants.updateIdentitiyApiUrl(id), {
            name: name
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const deleteIdentity = (id: number): any =>
    Client.getInstance()
        .deleteData(ApiConstants.updateIdentitiyApiUrl(id))
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

import { Stack } from "@fluentui/react";
import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import SurveyForm from "../../RocketTemplate/Feedback/SurveyForm";

type MiniEventSummaryProps = {
    info: string;
    gameBackgroundColor?: string;
    eventLink?: string;
    buttonColor: string;
    showSurveyForm: boolean;
    eventId: string;
    participantCode: string;
    partcipantName: string;
};

const MiniEventSummary: React.FC<MiniEventSummaryProps> = ({
    info,
    gameBackgroundColor,
    eventLink,
    buttonColor,
    showSurveyForm,
    eventId,
    partcipantName,
    participantCode
}) => {
    const [showFeedBackForm, setShowFeedBackForm] = useState(false);
    const history = useHistory();

    return (
        <>
            <div
                style={{ height: showFeedBackForm ? undefined : "55%" }}
                className={"middle-section-connector-question"}>
                <Stack
                    style={{ backgroundColor: gameBackgroundColor }}
                    className={showFeedBackForm ? "feedback-view" : "question-view"}
                    tokens={ThemedMediumStackTokens}>
                    {showFeedBackForm ? (
                        <SurveyForm
                            eventLink={eventLink}
                            ratingOptionColor={buttonColor}
                            eventId={eventId}
                            participantName={partcipantName}
                            participantCode={participantCode}
                        />
                    ) : (
                        <>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: info
                                }}
                                className={"quill-editor-text-align margin-p-0 quizQuestionview"}
                            />
                        </>
                    )}
                </Stack>
            </div>
            {!showFeedBackForm && (
                <>
                    <Stack horizontal horizontalAlign={"center"} tokens={ThemedMediumStackTokens}>
                        {showSurveyForm && (
                            <Button className={"answer-submit"} onClick={() => setShowFeedBackForm(true)}>
                                Submit survey
                            </Button>
                        )}
                        {!showSurveyForm && (
                            <Button
                                className={"answer-submit"}
                                onClick={() =>
                                    history.push("/thankyou", {
                                        eventClosed: false
                                    })
                                }>
                                Finish
                            </Button>
                        )}
                    </Stack>
                </>
            )}
        </>
    );
};

export default MiniEventSummary;

/* eslint-disable no-console */
import { IColumn } from "@fluentui/react";
import { useFormikContext } from "formik";
import { FC } from "react";
import FixedHeaderSortingDetailsList from "../../../../../../CommonComponent/FixedHeaderSortingDetilsList";
import { GameQuestionListItem } from "../../Type";
import { useSelectedQuestionHook } from "./Hooks";
import { SelectedListContainer } from "./Style";

type AllQuestionListProps = {
    questionList: GameQuestionListItem[];
    setSelectedQuestion?: (selectedQuestion: GameQuestionListItem[]) => void;
    gameId?: string;
    hideAction?: boolean;
    openEditQuestionDrawer?: (questionId: string) => void;
};

const SelectedQuestionList: FC<AllQuestionListProps> = ({
    questionList,
    setSelectedQuestion,
    hideAction,
    openEditQuestionDrawer
}) => {
    const { selectedQuestionColumns, renderItemColumn } = useSelectedQuestionHook();
    const { setFieldValue } = useFormikContext();

    return (
        <SelectedListContainer height={questionList.length <= 6 ? "350px" : `${questionList.length * 40 + 80}px`}>
            <FixedHeaderSortingDetailsList
                label={""}
                searchFieldWithoutLabel={true}
                columns={selectedQuestionColumns}
                isLoading={false}
                hideSearchSection
                items={questionList}
                onRenderItemColumn={(item: any, index: number | undefined, column: IColumn | undefined) =>
                    renderItemColumn(
                        item,
                        index,
                        column,
                        (id) => {
                            openEditQuestionDrawer && openEditQuestionDrawer(id.toString());
                        },
                        (id: number) => {
                            setSelectedQuestion && setSelectedQuestion(questionList.filter((item) => item.id !== id));
                        },
                        hideAction
                    )
                }
                getTransformedItems={(item: GameQuestionListItem[]) => setFieldValue("gameQuestionList", item)}
                isListDraggable={true}
            />
        </SelectedListContainer>
    );
};

export default SelectedQuestionList;

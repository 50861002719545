import { Grid, IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import { darkPrimaryColor } from "../../../../../../constants/ColorConstant";
import { McqAnswerTypeOptions } from "../../../../../../constants/DropdownOption";
import {
    CaptionText,
    ErrorTextfieldButton,
    SuccessTextfieldButton,
    TextButton
} from "../../../../../CommonComponent/v2/CommonStyle";
import FormikSelect from "../../../../../CommonComponent/v2/fields/FormikSelect";
import { QuestionDetailType } from "../Type";
import DeleteIcon from "../../../../../Images/icons/delete.svg";
import FormikTextInput from "../../../../../CommonComponent/v2/fields/FormikTextInput";
import { whiteColor } from "../../../../../../constants/Styles";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { MCQAnswerOption } from "../../Type";
import { useEffect } from "react";

const McqQuestion = () => {
    const { values, setFieldValue } = useFormikContext();
    const data = values as QuestionDetailType;

    useEffect(() => {
        if (data.answerDetails.mcq.mcqAnswerOptions && data.answerDetails.mcq.mcqAnswerOptions.length === 1) {
            setFieldValue("answerDetails.mcq.mcqAnswerOptions", [
                {
                    id: data.answerDetails.mcq.mcqAnswerOptions[0].id,
                    text: data.answerDetails.mcq.mcqAnswerOptions[0].text,
                    isCorrect: true
                }
            ]);
        }
    }, [data.answerDetails.mcq.mcqAnswerOptions.length]);

    const handleOptionClick = (selectedOption: MCQAnswerOption) => {
        const copy = data.answerDetails.mcq.mcqAnswerOptions;
        const index = copy.findIndex((x) => x.id === selectedOption.id);
        copy[index].isCorrect = !copy[index].isCorrect;
        setFieldValue("answerDetails.mcq.mcqAnswerOptions", copy);
    };

    return (
        <Grid container id={"mcqview"}>
            <Grid item xs={12} mt={"10px"}>
                <CaptionText mb={"10px"} fontWeight={700} color={darkPrimaryColor}>
                    Correct answer frequency
                </CaptionText>
                <FormikSelect
                    fullWidth
                    options={McqAnswerTypeOptions}
                    id={"answerDetails.mcq.correctAnswerFrequency"}
                    label={"Answer type"}
                    name={"answerDetails.mcq.correctAnswerFrequency"}
                />
            </Grid>
            <Grid item xs={12} mt={"30px"}>
                <CaptionText fontWeight={700} color={darkPrimaryColor}>
                    Answer options
                </CaptionText>
                {data.answerDetails.mcq.mcqAnswerOptions.map((option, index) => (
                    <Grid container mt={"10px"} spacing={"10px"} alignItems={"flex-start"} key={index}>
                        <Grid item xs={3}>
                            {option.isCorrect ? (
                                <SuccessTextfieldButton
                                    fullWidth
                                    fontWeight={700}
                                    height={56}
                                    background={whiteColor}
                                    onClick={() => handleOptionClick(option)}
                                    variant={"outlined"}>
                                    <AiOutlineCheck
                                        style={{
                                            color: "#65B44A",
                                            fontSize: 18,
                                            marginRight: 10
                                        }}
                                    />
                                    Correct
                                </SuccessTextfieldButton>
                            ) : (
                                <ErrorTextfieldButton
                                    fullWidth
                                    fontWeight={700}
                                    height={56}
                                    onClick={() => handleOptionClick(option)}
                                    background={whiteColor}
                                    variant={"outlined"}>
                                    <AiOutlineClose style={{ color: "#EB5757", fontSize: 18, marginRight: 10 }} /> Wrong
                                </ErrorTextfieldButton>
                            )}
                        </Grid>
                        <Grid item xs={8}>
                            <FormikTextInput
                                fullWidth
                                name={`answerDetails.mcq.mcqAnswerOptions[${index}].text`}
                                id={`answerDetails.mcq.mcqAnswerOptions[${index}].text`}
                                label={`Answer option ${index + 1}`}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            height={"68px"}>
                            {index !== 0 && (
                                <IconButton
                                    onClick={() => {
                                        setFieldValue(
                                            "answerDetails.mcq.mcqAnswerOptions",
                                            data.answerDetails.mcq.mcqAnswerOptions.filter(
                                                (item) => item.id !== option.id
                                            )
                                        );
                                    }}>
                                    <img src={DeleteIcon} style={{ height: 18, cursor: "pointer" }} />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            <Grid container mt={"20px"} justifyContent={"center"}>
                <TextButton
                    onClick={() =>
                        setFieldValue("answerDetails.mcq.mcqAnswerOptions", [
                            ...data.answerDetails.mcq.mcqAnswerOptions,
                            {
                                id: data.answerDetails.mcq.mcqAnswerOptions.length + 1,
                                text: "",
                                isCorrect: false
                            }
                        ])
                    }>
                    + Add new answer option
                </TextButton>
            </Grid>
        </Grid>
    );
};

export default McqQuestion;

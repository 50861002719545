import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SiteConstants, superAdmin } from "../../../../../constants/SiteConstant";
import { getPermissions } from "../../../../../utils/PermissionUtils";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { OptionType } from "../../../../CommonComponent/v2/fields/Type";
import { TabType } from "../../../../CommonComponent/v2/tab/Type";
import { useAdminContext } from "../../../context/AdminAuthContext";
import { dropDownType } from "../../../EventManager/EventDetails/utils/type";
import { getRoles } from "../../../Roles/Action";
import { RoleType } from "../../../Roles/Type";
import { UserDetailType } from "../Type";
import { getAllIdentitiesList } from "../Util";
import { getSpecificUserdetail, getUserRolesList, tranformRoleDropdown, transformIdentityDropdown } from "./Util";
import { useUserInfo } from "../../../../../commonhooks/Hooks";

export const useUserDetailHook = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setValues: (values: unknown, shouldValidate?: boolean | undefined) => void,
    userId: number
) => {
    const [identityDropdown, setIdentityDropdown] = useState<OptionType[]>([]);
    const { userDetail } = useAdminContext();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllIdentitiesList().then((res: any) => {
            if (userDetail?.identity.name !== "jambar") {
                setFieldValue(
                    "identityId",
                    res.find((item: { name: string }) => item.name === userDetail?.identity.name).id
                );
            }
            setIdentityDropdown(
                userDetail.identity.name === "jambar"
                    ? transformIdentityDropdown(res)
                    : transformIdentityDropdown(res).filter((item) => item.label === userDetail.identity.name)
            );
        });
        if (userId && userId > -1) {
            setLoading(true);
            getSpecificUserdetail(userId, true)
                .then((res: UserDetailType) => {
                    setValues({
                        firstName: res.firstName,
                        lastName: res.lastName,
                        email: res.email,
                        identityId: res.identityId
                    });
                })
                .catch((err: any) => toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration))
                .finally(() => setLoading(false));
        }
    }, []);

    return {
        identityDropdown,
        loading
    };
};

export const useUserDrawerHook = (userId: number) => {
    const [selectedTab, setSelectedTab] = useState("userDetails");
    const { isSuperAdmin, userDetail } = useUserInfo();

    const tabs: TabType[] = [
        {
            label: "Details",
            value: "userDetails",
            toShow: userId !== -1
        },
        {
            label: "Roles",
            value: "roles",
            toShow: isSuperAdmin && userId !== -1
        },
        {
            label: "Change Password",
            value: "changePassword",
            toShow: userId !== -1
        }
    ];
    return {
        selectedTab,
        tabs,
        setSelectedTab,
        email: userDetail?.email,
        IS_SUPER_ADMIN: isSuperAdmin
    };
};

export const useChangePasswordHook = (
    userId: number,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
) => {
    const [loading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        userId &&
            getSpecificUserdetail(userId)
                .then((res: UserDetailType) => {
                    setFieldValue("email", res.email);
                })
                .catch((err: any) => toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration))
                .finally(() => setIsLoading(false));
    }, []);

    return {
        loading
    };
};

export const useUserRoleHook = (userId?: number) => {
    const [userRolesDropdown, setUserRolesDropdown] = useState<dropDownType[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<dropDownType[]>([]);
    const { userDetail } = useAdminContext();
    const [userInfo, setUserInfo] = useState<{
        firstName: string;
        lastName: string;
        email: string;
    }>();
    const permissionRoles: string[] | null = getPermissions(userDetail, "role");

    useEffect(() => {
        userId &&
            getUserRolesList(userId)
                .then((res) => setSelectedRoles(tranformRoleDropdown(res)))
                .catch((err) => toast.error(err, SiteConstants.deleteToastConfiguration));
        permissionRoles?.includes("fetch") &&
            getRoles(false)
                .then((res: RoleType[]) => {
                    setUserRolesDropdown(tranformRoleDropdown(res));
                })
                .catch((err: any) => {
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                });

        userId &&
            getSpecificUserdetail(userId)
                .then((res: UserDetailType) => {
                    setUserInfo({
                        firstName: res.firstName,
                        lastName: res.lastName,
                        email: res.email
                    });
                })
                .catch((err: any) => toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration));
    }, []);

    const handleOptionChange = (value: dropDownType) => {
        if (selectedRoles.find((item) => item.value === value.value)) {
            setSelectedRoles(selectedRoles.filter((item) => item.value !== value.value));
            return;
        }
        setSelectedRoles([...selectedRoles, value]);
    };
    return {
        userRolesDropdown,
        selectedRoles,
        setSelectedRoles,
        handleOptionChange,
        permissionRoles,
        userInfo
    };
};

import { useEffect, useState } from "react";
import { OptionType } from "../../../../../CommonComponent/v2/fields/Type";
import { getQuizList } from "../Util";

export const useConnectorQuestionHook = () => {
    const [quizOptions, setQuizOptions] = useState<OptionType[]>([]);

    useEffect(() => {
        fetchQuizOptions();
    }, []);

    const fetchQuizOptions = () => {
        getQuizList().then((res) => setQuizOptions(res));
    };

    return {
        quizOptions
    };
};

import React from "react";
import { Modal } from "react-bootstrap";
import { WildGoatsCenterContainer } from "../CommonStyledComponents/CommonStyle";
import { FlexBox } from "../QuestionTypeView/Style";
import { CustomModal, SubText, HeadText, ModalContainer, LighButton, GreyButton } from "./Style";
import EllipseImage from "../../../Images/Wildgoat/Ellipse.svg";

type WildGoatDialogProps = {
    show: boolean;
    onHide: () => void;
    onSubmit: () => void;
    modalTitle: string;
    modalSubText: string;
    modalImageSrc?: string;
    cancelButtonText: string;
    submitButtonText: string;
    hideCancelButton?: boolean;
    backgroundColor?: string;
    primaryColor?: string;
    secondaryColor?: string;
};

const WildGoatDialog: React.FC<WildGoatDialogProps> = ({
    show,
    onHide,
    onSubmit,
    modalTitle,
    modalSubText,
    cancelButtonText,
    submitButtonText,
    modalImageSrc,
    hideCancelButton,
    backgroundColor,
    primaryColor,
    secondaryColor
}) => {
    return (
        <CustomModal show={show} onHide={onHide} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <ModalContainer>
                    {modalImageSrc && (
                        <WildGoatsCenterContainer style={{ marginBottom: 20 }}>
                            <img src={modalImageSrc} alt="modalImage" />
                            <img src={EllipseImage} alt="EllipseImage" />
                        </WildGoatsCenterContainer>
                    )}
                    <HeadText fontcolor={primaryColor}>{modalTitle}</HeadText>
                    <SubText fontcolor={backgroundColor}>{modalSubText}</SubText>
                    <FlexBox>
                        {!hideCancelButton && (
                            <LighButton className="margin-right-5" onClick={onHide}>
                                {cancelButtonText}
                            </LighButton>
                        )}
                        <GreyButton fontcolor={primaryColor} backgroundcolor={secondaryColor} onClick={onSubmit}>
                            {submitButtonText}
                        </GreyButton>
                    </FlexBox>
                </ModalContainer>
            </Modal.Body>
        </CustomModal>
    );
};

export default WildGoatDialog;

import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../../constants/SiteConstant";
import { addNewAttachment, getAttachmentData, updateAttachmentData, UpdateAttachmentType } from "./Util";

export const useUpdateAttachmentHook = (attachmentId: string) => {
    const { gameId } = useParams<{
        gameId: string;
    }>();
    const [isLoading, setIsLoading] = useState(false);

    const [attachmentDetails, setAttachmentDetails] = useState<UpdateAttachmentType>({
        attachmentTitle: "",
        attachmentFile: null
    });

    useEffect(() => {
        if (attachmentId !== "-1") {
            setIsLoading(true);
            fetchData();
        }
    }, []);

    const fetchData = () => {
        getAttachmentData(gameId, attachmentId)
            .then((res) => {
                setAttachmentDetails(res);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateAttachment = (
        values: UpdateAttachmentType,
        setSubmitting: (isSubmitting: boolean) => void,
        refreshData: () => void
    ) => {
        if (attachmentId !== "-1") {
            gameId &&
                updateAttachmentData(gameId, attachmentId, values)
                    .then(() => {
                        toast.success("Attachment updated successfully", SiteConstants.successToastConfiguration);
                        refreshData();
                    })
                    .catch((err) => {
                        toast.success(err, SiteConstants.deleteToastConfiguration);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            return;
        }
        addNewAttachment(gameId, values)
            .then(() => {
                toast.success("Attachment added successfully", SiteConstants.successToastConfiguration);
                refreshData();
            })
            .catch((err) => {
                toast.success(err, SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return {
        updateAttachment,
        attachmentDetails,
        isLoading
    };
};

import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { addEdge, applyEdgeChanges, applyNodeChanges, Edge, Node } from "react-flow-renderer";
import { useParams } from "react-router";
import { StyledActionColumn, StyledColumn, StyledHeader } from "../../../CommonComponent/v2/datagrid/DataGridStyle";
import { generateKey } from "../../../Poc/flowchart/Util";
import FlowChartBox from "./FlowChartBox";
import { BranchInfoType, BranchQuestionListType, BranchQuizType, FlowBoxValueType, TriggersType } from "./Type";
import { addMultiBranchTrigger, addQuizBranch, createMultiBranchDiagram, deleteMultiBranchTrigger, getMultiBranchDiagram, getQuestionListFromAllQuiz, getQuizListData, getQuizQuestionList, removeBranch, updateMultiBranchDiagram, updateMultiBranchTrigger, updateQuizBranch } from "./Util";
import { parse, stringify } from 'flatted';
import { toast } from "react-toastify";
import { QUESTION_TAB, QUIZ_TAB, SiteConstants } from "../../../../constants/SiteConstant";

export const useAddNewBranchHook = (quizType: string) => {
    const [isLoading, setIsLoading] = useState<{
        quizList: boolean,
        questionList: boolean,
    }>({
        questionList: false,
        quizList: false
    });
    const [quizList, setQuizList] = useState<BranchQuizType[]>([]);
    const [quizListLoading, setQuizListLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState<string>(QUIZ_TAB);
    const [selectedQuizId, setSelectedQuizId] = useState<string[]>([]);
    const [selectedQuestionList, setSelectedQuestionList] = useState<BranchQuestionListType[]>([]);

    useEffect(() => {
        fetchQuizList();
    }, []);

    useEffect(() => {
        if (selectedTab === QUESTION_TAB) {
            fetchQuestionList({
                setQuestion: setSelectedQuestionList
            });
        }
    }, [selectedTab]);

    const fetchQuizList = () => {
        setIsLoading((prev) => ({
            ...prev,
            quizList: true
        }));
        getQuizListData(quizType)
            .then((res) => {
                setQuizList(res);
            })
            .finally(() => {
                setIsLoading((prev) => ({
                    ...prev,
                    quizList: false
                }));
            });
    };

    const fetchQuestionList = (
        {
            setQuestion,
            handleClose,
        }: {
            setQuestion: (questionList: BranchQuestionListType[]) => void,
            handleClose?: () => void,
        }
    ) => {
        setIsLoading((prev) => ({
            ...prev,
            questionList: true
        }));
        selectedQuizId &&
            getQuestionListFromAllQuiz(selectedQuizId)
                .then((res: any) => {
                    setQuestion(res);
                    handleClose && handleClose();
                })
                .finally(() => {
                    setIsLoading((prev) => ({
                        ...prev,
                        questionList: false
                    }));
                });
    };

    const handleRemoveQuestion = (questionId: number) => {
        setSelectedQuestionList((prev) => prev.filter((item) => item.id !== questionId).map((item, index) => ({
            ...item,
            id: index + 1
        })));
    };

    const importQuestion = (
        setQuestionList: (questionList: BranchQuestionListType[]) => void,
        handleClose: () => void,
    ) => {
        if (selectedQuestionList.length === 0) {
            setQuizListLoading(true);
            fetchQuestionList({
                setQuestion: setQuestionList,
                handleClose: handleClose
            });
            return;
        }
        setQuestionList(selectedQuestionList);
        handleClose();
    };

    return {
        isLoading,
        quizList,
        fetchQuestionList,
        quizListLoading,
        setSelectedTab,
        selectedTab,
        setSelectedQuizId,
        selectedQuizId,
        selectedQuestionList,
        handleRemoveQuestion,
        importQuestion
    };
};

export const useBranchQuizListHook = () => {
    const quizListColumns = (): GridColDef[] => [
        {
            field: "index",
            headerName: "",
            width: 20,
            renderHeader: () => <StyledHeader label="#" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.index} />
        },
        {
            field: "title",
            headerName: "Title",
            flex: 1,
            renderHeader: () => <StyledHeader label="Quiz title" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.title} />
        }
    ];

    return {
        quizListColumns
    };
};

export const useBranchQuestionListHook = () => {

    const questionListColumns = (handleQuestionRemove: (id: number) => void,): GridColDef[] => [
        {
            field: "index",
            headerName: "",
            width: 20,
            renderHeader: () => <StyledHeader label="#" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.id} />
        },
        {
            field: "questionTitle",
            headerName: "Title",
            flex: 1,
            renderHeader: () => <StyledHeader label="Quiz title" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.questionTitle} />
        },
        {
            field: "id",
            headerName: "id",
            width: 150,
            renderHeader: () => <StyledHeader label="Action" />,
            renderCell: (params: GridCellParams) => (
                <StyledActionColumn
                    isDeleteAvailaible={{
                        isAvailable: true,
                        onClick: () => handleQuestionRemove(params.row.id)
                    }}
                />
            )
        }
    ];

    return {
        questionListColumns
    };
};

export const useMultibranchQuestion = (gameId?: string) => {
    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);
    const [loading, setLoading] = useState(false);
    const [showDeleteBranchModal, setShowDeleteBranchModal] = useState<string | null>(null);

    const { quizid } = useParams<{
        quizid: string;
    }>();

    const quizId = gameId || quizid;
    const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), [setNodes]);
    const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), [setEdges]);
    const [selectedBoxToEdit, setSelectedBoxToEdit] = useState<FlowBoxValueType | undefined>();
    const [addNewBranchModal, setAddNewBranchModal] = useState(false);
    const [triggerBranchId, setTriggerBranchId] = useState<string>("");
    const [editTrigger, setEditTrigger] = useState<{
        branchId: string;
        triggerId: string;
        triggerInfo: TriggersType;
    }>();
    const [quizType, setQuizType] = useState<string>("");
    const [refreshChart, setRefreshChart] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isBranchAlreadySaved, setIsBranchAlreadySaved] = useState(false);

    useEffect(() => {
        fetchMultiBranch();
    }, []);

    useEffect(() => {
        refreshChart && saveChart();
    }, [refreshChart]);

    const saveChart = () => {
        setTimeout(() => {
            saveChartinJson();
        }, 1000);
    };

    const fetchMultiBranch = () => {
        getMultiBranchDiagram(quizId).then((res) => {
            const parseLayout = parse(res.data.uiLayout);
            getQuizQuestionList(quizId).then((res) => {
                parseLayout.node[0].branchInfo.questionList = res.quizList;
                parseLayout.node.forEach((item: any) => {
                    addNodes(item.branchInfo, item);
                });
                setIsBranchAlreadySaved(true);
                setEdges(parseLayout.edges);
            });
        }).catch(() => {
            fetchQuizDetail();
            setIsBranchAlreadySaved(false);
        });
    };

    const onConnect = useCallback(
        (connection) => {
            setEdges((eds) =>
                addEdge(
                    {
                        ...connection,
                        markerStart: {
                            type: "arrow",
                            color: "grey"
                        },
                        markerEnd: {
                            type: "arrow",
                            color: "grey"
                        },
                        type: "step"
                    },
                    eds
                )
            );
        },
        [setEdges]
    );

    const addNodes = (
        branchInfo: BranchInfoType,
        newNodeInfo?: any,
    ) => {
        const id = newNodeInfo?.id || generateKey();
        const position = newNodeInfo?.position || { x: 250, y: 200 * nodes.length };
        const boxValue = {
            branchInfo: {
                ...branchInfo
            },
            triggers: newNodeInfo?.triggers || [],
            id: id,
            position: position
        };
        setNodes((prev) => [
            ...prev,
            {
                ...boxValue,
                data: {
                    label: (
                        <FlowChartBox
                            onCreateTrigger={(branchId) => setTriggerBranchId(branchId)}
                            onEditClick={() => setSelectedBoxToEdit(boxValue)}
                            boxValue={boxValue}
                            onEditTrigger={(branchId: string, triggerId: string, triggerInfo: TriggersType) =>
                                setEditTrigger({
                                    branchId,
                                    triggerId,
                                    triggerInfo
                                })
                            }
                        />
                    )
                }
            }
        ]);
    };

    const addInitialBox = (branchInfo: BranchInfoType) => {
        const node = [{
            id: generateKey(),
            branchInfo: {
                ...branchInfo
            },
            triggers: [],
            position: { x: 250, y: 200 * nodes.length }
        }];
        const str = stringify({
            node: node,
            edges: edges
        });
        createMultiBranchDiagram(quizId, str, branchInfo.questionList.map((item) => item.questionId).join(","))
            .then((mainBranchId) => {
                setIsBranchAlreadySaved(true);
                toast.success('Layout Initial saved successfully', SiteConstants.successToastConfiguration);
                addNodes(branchInfo, {
                    ...node,
                    id: `${mainBranchId}`
                });
            });
    };

    const addBranch = (branchInfo: BranchInfoType) => {
        setLoading(true);
        addQuizBranch(branchInfo, quizId).then((branchId: number) => {
            addNodes(branchInfo, {
                id: `${branchId}`
            });
            setTimeout(() => {
                setRefreshChart(Math.random().toString());
                setAddNewBranchModal(false);
            }, 200);
        }).finally(() => setLoading(false));
    };

    const fetchQuizDetail = () => {
        if (quizId) {
            setIsLoading(true);
            getQuizQuestionList(quizId)
                .then((res) => {
                    setQuizType(res.quizType);
                    addInitialBox({
                        branchName: res.quizName,
                        gameTitle: res.quizName,
                        interruptable: false,
                        questionList: res.quizList,
                        resumable: false
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const updateBranch = (boxInfo: FlowBoxValueType) => {
        if (boxInfo.branchInfo) {
            updateQuizBranch(boxInfo.branchInfo, quizId, boxInfo.id).then(() => {
                const nodeCopy: any = nodes;
                const index = nodeCopy.findIndex((item: any) => item.id === boxInfo.id);

                nodeCopy[index].data.label = (
                    <FlowChartBox
                        onCreateTrigger={(branchId) => setTriggerBranchId(branchId)}
                        onEditClick={() => setSelectedBoxToEdit(boxInfo)}
                        boxValue={boxInfo}
                        onEditTrigger={(branchId: string, triggerId: string, triggerInfo: TriggersType) =>
                            setEditTrigger({
                                branchId,
                                triggerId,
                                triggerInfo
                            })
                        }
                    />
                );
                nodeCopy[index] = {
                    ...nodes[index],
                    ...boxInfo
                };
                setNodes(nodeCopy);
                toast.success('Branch updated successfully', SiteConstants.successToastConfiguration);
                setTimeout(() => {
                    setRefreshChart(Math.random().toString());
                    setSelectedBoxToEdit(undefined);
                }, 200);
            });
        }


    };

    const deleteBranch = (branchId: string) => {
        removeBranch(quizId, branchId).then(() => {
            setNodes((prev) => prev.filter((item) => item.id !== branchId));
            toast.success('Branch deleted successfully', SiteConstants.successToastConfiguration);
            setShowDeleteBranchModal(null);
            setTimeout(() => {
                setRefreshChart(Math.random().toString());
                setSelectedBoxToEdit(undefined);
            }, 200);
        });
    };

    const deleteTrigger = async () => {
        const copyNode: any = nodes;
        const branchIndex = copyNode.findIndex((item: FlowBoxValueType) => item.id === editTrigger?.branchId);
        setEdges((prev) =>
            prev.filter(
                (item: any) =>
                    item.id !==
                    `reactflow__edge-${editTrigger?.branchId || triggerBranchId}-${editTrigger?.triggerInfo.targetBranch
                    }`
            )
        );
        if (editTrigger?.triggerId) {
            deleteMultiBranchTrigger(editTrigger.triggerId, quizId, editTrigger.branchId).then(() => {
                copyNode[branchIndex].triggers = copyNode[branchIndex].triggers.filter(
                    (item: TriggersType) => item.triggerId !== editTrigger?.triggerId
                );
                copyNode[branchIndex].data.label = (
                    <FlowChartBox
                        onCreateTrigger={(branchId) => setTriggerBranchId(branchId)}
                        onEditClick={() => setSelectedBoxToEdit(copyNode[branchIndex])}
                        boxValue={copyNode[branchIndex]}
                        onEditTrigger={(branchId: string, triggerId: string, triggerInfo: TriggersType) =>
                            setEditTrigger({
                                branchId,
                                triggerId,
                                triggerInfo
                            })
                        }
                    />
                );
                setNodes(copyNode);
                setTimeout(() => {
                    setRefreshChart(Math.random().toString());
                    setEditTrigger(undefined);
                }, 200);
            });
        }
    };

    const addTrigger = async (triggerInfo: TriggersType) => {
        const nodeCopy: any = nodes;

        triggerInfo.targetBranch &&
            onConnect({
                source: editTrigger?.branchId || triggerBranchId,
                sourceHandle: null,
                targetHandle: null,
                target: triggerInfo.targetBranch
            });

        const index = editTrigger
            ? nodeCopy.findIndex((item: any) => item.id === editTrigger.branchId)
            : nodeCopy.findIndex((item: any) => item.id === triggerBranchId);
        if (editTrigger) {
            await updateMultiBranchTrigger(triggerInfo, quizId, editTrigger.branchId);
            const triggerIndex = nodeCopy[index].triggers.findIndex(
                (item: TriggersType) => item.triggerId === editTrigger.triggerId
            );
            nodeCopy[index].triggers[triggerIndex] = triggerInfo;
        } else {
            const res = await addMultiBranchTrigger(triggerInfo, quizId, triggerBranchId);
            nodeCopy[index].triggers = [...nodeCopy[index].triggers, {
                triggerId: res.data.data.id,
                triggerName: res.data.data.name,
                triggerType: res.data.data.type,
                keyword: res.data.data.keywordText,
                targetBranch: res.data.data.targetBranchId,
            }];
        }

        nodeCopy[index].data.label = (
            <FlowChartBox
                onCreateTrigger={(branchId) => setTriggerBranchId(branchId)}
                onEditClick={() => setSelectedBoxToEdit(nodeCopy[index])}
                boxValue={nodeCopy[index]}
                onEditTrigger={(branchId: string, triggerId: string, triggerInfo: TriggersType) =>
                    setEditTrigger({
                        branchId,
                        triggerId,
                        triggerInfo
                    })
                }
            />
        );
        setNodes(nodeCopy);
        setTimeout(() => {
            setRefreshChart(Math.random().toString());
            setTriggerBranchId("");
            setEditTrigger(undefined);
        }, 200);
    };

    const saveChartinJson = () => {
        const node = nodes.map((item: any) => {
            const { data, ...rest } = item;
            return rest;
        });
        const str = stringify({
            node: node,
            edges: edges
        });
        updateMultiBranchDiagram(quizId, str).then(() => {
            toast.success('Layout updated successfully', SiteConstants.successToastConfiguration);
        });
    };

    const confirmDelete = () => {
        showDeleteBranchModal && deleteBranch(showDeleteBranchModal);
    };

    return {
        edges,
        onNodesChange,
        onEdgesChange,
        selectedBoxToEdit,
        setAddNewBranchModal,
        addNewBranchModal,
        refreshChart,
        isLoading,
        onConnect,
        addBranch,
        updateBranch,
        deleteBranch,
        deleteTrigger,
        addTrigger,
        setSelectedBoxToEdit,
        triggerBranchId,
        editTrigger,
        nodes,
        setTriggerBranchId,
        setEditTrigger,
        saveChartinJson,
        quizType,
        loading,
        isBranchAlreadySaved,
        showDeleteBranchModal,
        setShowDeleteBranchModal,
        confirmDelete
    };
};

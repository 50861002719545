import { ApiConstants } from "../../../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../../../constants/JambarDateUtils";
import { Client } from "../../../../Base/Client";
import { BreadCrumbType } from "../../../../CommonComponent/v2/Type";
import { EventInfoType } from "./Type";

export const eventDetailsBreadcrumbValues: BreadCrumbType[] = [
    {
        label: "Event Manager",
        url: "/admin/event"
    },
    {
        label: "Add new event"
    }
];

const transformReferenceList = (data: any) => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        description: item.description,
        id: item.id,
        name: item.name,
        referenceLink: item.referenceLink
    }));
};

const transformData = (data: any): EventInfoType => {
    return {
        startDate: JambarDateUtil.dateFormat(data.eventStartDate, "YYYY-MM-DD"),
        endDate: JambarDateUtil.dateFormat(data.eventEndDate, "YYYY-MM-DD"),
        eventLink: data.eventLink ? data.eventLink : "",
        eventName: data.name,
        noOfTeams: data.teamCount,
        gameId: data.quizId,
        isPublic: data.isPublic,
        isClosed: data.isClosed,
        isMini: data.type === "mini",
        generatedLink: data.eventLink
            ? `${window.location.origin}/${data.type === "mini" ? "minievent" : "event"}/${data.eventLink}`
            : "",
        leaderBoardLink: data.leaderboardLink || "",
        isDemo: data.isDemo,
        clientLogo: data.fileLocation ? `${data.fileLocation}?key=${new Date().getTime()}` : "",
        companyLogo: data.companyLogoFileLocation ? `${data.companyLogoFileLocation}?key=${new Date().getTime()}` : "",
        showSurvey: data.showSurveyAtEnd,
        gameType: data.quiz.type,
        linkName: data.eventLink,
        referenceLinkFirst: data.referenceList.length !== 0 ? data.referenceList[0].referenceLink : "",
        referenceLinkSecond: data.referenceList.length === 2 ? data.referenceList[1].referenceLink : "",
        participantFilesLink: data.participantFilesLink || "",
        gameName: data.quiz.title,
        createdUserDetail: {
            firstName: data.createdByUser.firstName,
            id: data.createdByUser.id,
            lastName: data.createdByUser.lastName
        },
        updatedUserDetail: {
            firstName: data.updatedByUser.firstName,
            id: data.updatedByUser.id,
            lastName: data.updatedByUser.lastName
        },
        referenceList: transformReferenceList(data.referenceList)
    };
};

export const getEventData = (eventId: string) =>
    Client.getInstance()
        .getData(ApiConstants.getEventDetailApiUrl(eventId), true)
        .then((response) => Promise.resolve(transformData(response.data.data)))
        .catch((error) => Promise.reject(error));

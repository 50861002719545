import React from "react";
import { ColorPicker, IColor, IColorPickerProps } from "@fluentui/react";

type ColorPickerWrapperProps = Omit<IColorPickerProps, "onChange"> & {
    onChange: (color: IColor) => void;
};

const ColorPickerWrapper: React.FC<ColorPickerWrapperProps> = (props) => {
    //eslint-disable-next-line
    const onColorPickerValueChange = (ev: React.SyntheticEvent<HTMLElement, Event>, color: IColor) => {
        props.onChange(color);
    };

    return (
        <ColorPicker
            styles={{ root: { minWidth: 352, maxWidth: 400 }, table: { color: "black" } }}
            {...props}
            onChange={onColorPickerValueChange}
        />
    );
};

export default ColorPickerWrapper;

import { Grid } from "@mui/material";
import { useAttachmentHooks } from "./Hooks";
import { FC } from "react";
import { ReferenceAttachmentList, ItemsDeleteType } from "./Types";
import { useHistory, useParams } from "react-router";
import DataGridWrapper from "../../../../../CommonComponent/v2/datagrid/DataGrid";

type AttachmentListProps = {
    referenceAttachments: ReferenceAttachmentList[];
    isLoading: boolean;
    setItemDeleteId: (id: ItemsDeleteType) => void;
};

const ReferenceAttachment: FC<AttachmentListProps> = ({
    referenceAttachments,
    isLoading,
    setItemDeleteId
}) => {
    const { attachmentColumns } = useAttachmentHooks();
    const history = useHistory();
    const { id } = useParams<{
        id: string;
    }>();
    return (
        <Grid height={"calc(100vh - 230px)"}>
            <DataGridWrapper
                columns={attachmentColumns(setItemDeleteId, (attachmentId) => {
                    history.push(`/admin/v2/referencelistmanager/${id}/update/${attachmentId}/editAttachment`);
                })}
                rows={referenceAttachments as any}
                loading={isLoading}
            />
        </Grid>
    );
};

export default ReferenceAttachment;

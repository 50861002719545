import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { darkPrimaryColor } from "../../../../../../constants/ColorConstant";
import { CaptionText } from "../../../../../CommonComponent/v2/CommonStyle";
import FormikTextInput from "../../../../../CommonComponent/v2/fields/FormikTextInput";
import ImageDrop from "../../../../../CommonComponent/v2/fields/ImageDrop";
import { AddReferenceType } from "../Type";
import { ColorInput } from "../Style";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { removeReferenceBackgroundImage, removeReferenceProgressImage } from "./Util";
import { SiteConstants } from "../../../../../../constants/SiteConstant";

const Template = () => {
    const { values, setFieldValue } = useFormikContext();
    const data = values as AddReferenceType;
    const { id } = useParams<{
        id: string;
    }>();

    const handleRemoveImage = (type: string): void => {
        if (!id) {
            return;
        }
        if (type === "background") {
            removeReferenceBackgroundImage(parseInt(id)).then(() => {
                toast.success("Cover image removed", SiteConstants.successToastConfiguration);
            });
            return;
        }
        removeReferenceProgressImage(parseInt(id)).then(() => {
            toast.success("Cover image removed", SiteConstants.successToastConfiguration);
        });
    };

    return (
        <Grid container mt={"30px"} spacing={"20px"}>
            <Grid item xs={12}>
                <FormikTextInput name={"centreHeading"} id={"centreHeading"} label={"Center Heading"} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <FormikTextInput name={"attachmentTitle"} id={"attachmentTitle"} label={"Attachment Title"} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <CaptionText fontWeight={700} color={darkPrimaryColor}>
                    Background image
                </CaptionText>
                <ImageDrop
                    imageSize={2}
                    removeImage={() => handleRemoveImage("background")}
                    variant={"secondary"}
                    selectedFile={data.backgroundImage}
                    logoInfo={"Attach background image (800px * 600px)"}
                    name={"backgroundImage"}
                />
            </Grid>
            <Grid item xs={12}>
                <CaptionText fontWeight={700} color={darkPrimaryColor}>
                    Progress image
                </CaptionText>
                <ImageDrop
                    imageSize={2}
                    variant={"secondary"}
                    removeImage={() => handleRemoveImage("progress")}
                    selectedFile={data.progressImage}
                    logoInfo={"Attach progress image (800px * 600px)"}
                    name={"progressImage"}
                />
            </Grid>
            <Grid item xs={7} mt={"40px"}>
                <Grid container>
                    <Grid item xs={6}>
                        <FormikTextInput
                            id={"backgroundColor"}
                            name={"backgroundColor"}
                            label={"Background color"}
                            fullWidth
                            value={data.backgroundColor}
                        />
                    </Grid>
                    <Grid item xs={6} mt={"15px"}>
                        <ColorInput
                            color={data.backgroundColor}
                            type="color"
                            id="head"
                            name="backgroundColor"
                            value={data.backgroundColor}
                            onChange={({ target }) => setFieldValue("backgroundColor", target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={7} mt={"40px"}>
                <Grid container>
                    <Grid item xs={6}>
                        <FormikTextInput
                            id={"buttonColor"}
                            name={"buttonColor"}
                            label={"Button color"}
                            fullWidth
                            value={data.buttonColor}
                        />
                    </Grid>
                    <Grid item xs={6} mt={"15px"}>
                        <ColorInput
                            color={data.buttonColor}
                            type="color"
                            id="head"
                            name="buttonColor"
                            value={data.buttonColor}
                            onChange={({ target }) => setFieldValue("buttonColor", target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default Template;

import { Breadcrumbs } from "@mui/material";
import { BreadCrumbType } from "./Type";
import { FC } from "react";
import { CaptionText } from "./CommonStyle";
import { useHistory } from "react-router";

type BreadcrumbProp = {
    links: BreadCrumbType[];
};
const BreadcrumbWrapper: FC<BreadcrumbProp> = ({ links }) => {
    const history = useHistory();

    return (
        <Breadcrumbs separator="/" aria-label="breadcrumb">
            {links.map(({ label, url }) => (
                <CaptionText
                    color={url ? "#828282" : "#1F71B6"}
                    fontWeight={!url ? 700 : 500}
                    style={{ cursor: "pointer" }}
                    key={label}
                    onClick={() => url && history.push(url)}>
                    {label}
                </CaptionText>
            ))}
        </Breadcrumbs>
    );
};

export default BreadcrumbWrapper;

import { Icon, Stack } from "@fluentui/react";
import React, { useMemo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { FieldValidation } from "../../../../../constants/FieldValidation";
import { MaxUploadFileSize, SiteConstants } from "../../../../../constants/SiteConstant";
import { ThemedMediumStackTokens } from "../../../../../constants/Styles";
import UploadProgressBar from "../UploadProgressBar";
import FileDropZone from "./FileDropZone";
import loadingGif from "../../../../Images/loading.gif";

type FileUploadAnswerViewProps = {
    gameBackgroundColor: string;
    answerHeader: string;
    fileUploadedByParticipant: (files: File[]) => void;
    isSubmitDisable: boolean;
    uploadedPercentage?: number;
    fileUploadLoading: boolean;
    submitAnswer: () => void;
    isFileUploadFailed: boolean;
};

const FileUploadAnswerView: React.FC<FileUploadAnswerViewProps> = ({
    answerHeader,
    gameBackgroundColor,
    fileUploadedByParticipant,
    isSubmitDisable,
    uploadedPercentage,
    fileUploadLoading,
    submitAnswer,
    isFileUploadFailed
}) => {
    const [files, setFiles] = useState<File[]>([]);
    const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
    const addFile = (file: File) => {
        const fileSize = file.size / 1024 / 1024;
        if (!FieldValidation.videoTypeValidation(file.name) && !FieldValidation.imageValidationByExtension(file.name)) {
            toast.success("File must be a video or image", SiteConstants.deleteToastConfiguration);
            return;
        }
        if (FieldValidation.videoTypeValidation(file.name) && fileSize > MaxUploadFileSize) {
            toast.success("Video must be less then 100mb", SiteConstants.deleteToastConfiguration);
            return;
        }
        if (FieldValidation.imageValidationByExtension(file.name) && fileSize > MaxUploadFileSize) {
            toast.success("Image must be less then 100mb", SiteConstants.deleteToastConfiguration);
            return;
        }
        fileUploadedByParticipant && fileUploadedByParticipant([file]);
        setFiles((prev) => [...prev, file]);
    };

    const showFilesUploaded = useMemo(() => {
        return files.map((item: any) => (
            <div className={"fileContainer"} key={item.id}>
                <span className={"fileText"}>{item.name}</span>
                <Icon
                    iconName="ChromeClose"
                    className={"fileText"}
                    onClick={() => setFiles(files.filter((file: any) => file.id !== item.id))}
                    style={{ marginLeft: 10 }}
                />
                <br />
            </div>
        ));
    }, [files]);

    return (
        <Stack
            className={"quiz-answer-view"}
            tokens={ThemedMediumStackTokens}
            style={{ backgroundColor: gameBackgroundColor }}>
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        answerHeader && !FieldValidation.quillTextEditorValidation(answerHeader)
                            ? answerHeader
                            : "Answer"
                }}
                className={
                    answerHeader && !FieldValidation.quillTextEditorValidation(answerHeader)
                        ? "margin-p-0 answer-title quill-editor-text-align"
                        : "component-title margin-p-0"
                }
            />

            <FileDropZone setSelectedFile={(file: File) => addFile(file)} />
            {uploadedPercentage && uploadedPercentage > 0 && files.length > 0 && (
                <UploadProgressBar progress={uploadedPercentage} />
            )}
            <Row>
                <Col md={files.length && isFileUploadFailed ? 7 : 9}>
                    {files.length > 0 && (
                        <>
                            <span style={{ fontSize: 16 }}>File Added</span>
                            <br />
                            {showFilesUploaded}
                        </>
                    )}
                </Col>
                <Col xs={files.length && isFileUploadFailed ? 12 : 6} md={files.length && isFileUploadFailed ? 5 : 3}>
                    <div style={{ display: "flex", gap: 10 }}>
                        {files.length !== 0 && isFileUploadFailed && (
                            <Button
                                className={
                                    files.length === 0
                                        ? "pointer-event-none submit-button-option"
                                        : "submit-button-option cursor"
                                }
                                onClick={() => {
                                    fileUploadedByParticipant && fileUploadedByParticipant(files);
                                }}>
                                Retry
                            </Button>
                        )}
                        <Button
                            disabled={
                                !files.length ||
                                fileUploadLoading ||
                                isFileUploadFailed ||
                                isSubmitDisable ||
                                isAnswerSubmitted
                            }
                            className={
                                files.length === 0
                                    ? "pointer-event-none submit-button-option"
                                    : "submit-button-option cursor"
                            }
                            onClick={() => {
                                submitAnswer();
                                setIsAnswerSubmitted(true);
                            }}>
                            {fileUploadLoading ? (
                                <img src={loadingGif} style={{ height: 10 }} alt={"loading"} />
                            ) : (
                                "Submit"
                            )}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Stack>
    );
};

export default FileUploadAnswerView;

import { VALID_IMAGE } from "../../../../constants/SiteConstant";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig } from "../../../../utils/JambarUtils";
import { array, lazy, object, string } from "yup";
import { UserDetailsType, TabType } from "./Type";
import avatar1 from "../../../Images/icons/avatar1.svg";
import avatar2 from "../../../Images/icons/avatar2.svg";
import avatar3 from "../../../Images/icons/avatar3.svg";
import avatar4 from "../../../Images/icons/avatar4.svg";
import avatar5 from "../../../Images/icons/avatar5.svg";
import avatar6 from "../../../Images/icons/avatar6.svg";
import avatar7 from "../../../Images/icons/avatar7.svg";
import avatar8 from "../../../Images/icons/avatar8.svg";
import avatar9 from "../../../Images/icons/avatar9.svg";
import avatar10 from "../../../Images/icons/avatar10.svg";
import avatar11 from "../../../Images/icons/avatar11.svg";
import avatar12 from "../../../Images/icons/avatar12.svg";
import avatar13 from "../../../Images/icons/avatar13.svg";
import avatar14 from "../../../Images/icons/avatar14.svg";
import avatar15 from "../../../Images/icons/avatar15.svg";
import avatar17 from "../../../Images/icons/avatar17.svg";


export const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9, avatar10, avatar11, avatar12, avatar13, avatar14, avatar15, avatar17,];
export const tabsOptions: TabType = [
    {
        label: "Profile",
        value: "profile",
        toShow: true
    },
    {
        label: "Settings",
        value: "settings",
        toShow: true
    }
];
export const userDetailInitialValues = (): UserDetailsType => ({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
    profileImage: null
});
export const validationSchema = object().shape(
    {
        firstName: string()
            .required('Required'),
        lastName: string()
            .required('Required'),
        password: string()
            .required('Required'),
        newPassword: string(),
        confirmNemPassword: string(),
        email: string().email('Invalid email').required('Required'),
        profileImage: lazy((val) =>
            Array.isArray(val)
                ? array()
                    .min(1, "Please select an Image")
                    .test(
                        "is-big-file",
                        "Image must be less then 2MB",
                        (value) => !value || checkIfFilesAreTooBig(value, 8)
                    )
                    .test(
                        "is-correct-file",
                        "Please upload image only",
                        (value) => !value || checkIfFilesAreCorrectType(value, VALID_IMAGE)
                    )
                : string()
                    .nullable()
        ),
    });
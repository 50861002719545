import { FC } from "react";
import { CompanyLogo, LogoContainer, TitleBox, WildGoatsHeader } from "./Style";

interface HeaderProps {
    quizLogo: string;
    companyLogo: string;
    quizTitle: string;
    secondaryColor: string;
}

const Header: FC<HeaderProps> = ({ companyLogo, quizLogo, quizTitle, secondaryColor }) => {
    return (
        <WildGoatsHeader>
            <LogoContainer>
                <CompanyLogo width={"100%"} src={`${quizLogo}?key=${new Date().getTime()}`} alt="" />
            </LogoContainer>
            <TitleBox
                textcolor={secondaryColor}
                dangerouslySetInnerHTML={{
                    __html: quizTitle
                }}
            />
            <LogoContainer>
                <CompanyLogo width={"100%"} src={`${companyLogo}?key=${new Date().getTime()}`} alt="" />
            </LogoContainer>
        </WildGoatsHeader>
    );
};

export default Header;

import { PrimaryButton } from "@fluentui/react";
import { Stack } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { FieldValidation } from "../../constants/FieldValidation";
import { JambarDateUtil } from "../../constants/JambarDateUtils";
import { addDefaultSrc } from "../../utils/JambarUtils";
import { FileDataType } from "../Admin/EventManager/EventDetails/utils/type";
import { deletePublicUploadFiles, downloadPublicUploadFiles, fetchPublicFiles } from "./Util";
import {
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Container,
    CircularProgress,
    TextField,
    Typography
} from "@mui/material";
import { backgroundColor } from "../../constants/Styles";
import { sortFilesList } from "../Admin/EventManager/EventDetails/utils/helperFunctions";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { SiteConstants } from "../../constants/SiteConstant";
import { getFileName } from "../../constants/HelperFns";
import DeleteConfirmationDialog from "../CommonComponent/DeleteConfirmationDialog";
import { convertBytesToMB } from "../Admin/v2/filemanager/filedetail/Util";

const PublicFilesView = () => {
    const [files, setFiles] = useState<FileDataType[]>([]);
    const [loading, setLoading] = useState(false);
    const searchParam = new URLSearchParams(window.location.search);
    const [selectedFile, setSelectedFile] = useState<{
        fileName: string;
        id: number;
    } | null>(null);
    const [filterFiles, setFilteredFiles] = useState<FileDataType[]>([]);

    useEffect(() => {
        setLoading(true);
        searchParam.get("participantFilesLink") &&
            fetchPublicFiles(searchParam.get("participantFilesLink") || "")
                .then((res: any) => {
                    setFiles(sortFilesList(res));
                    setFilteredFiles(sortFilesList(res));
                })
                .catch(() => setFiles([]))
                .finally(() => setLoading(false));
    }, []);

    const handleDelete = () => {
        selectedFile &&
            deletePublicUploadFiles(selectedFile.id, searchParam.get("participantFilesLink") || "")
                .then(() => {
                    toast.success("File deleted", SiteConstants.successToastConfiguration);
                    setFiles(files.filter((file: FileDataType) => file.id !== selectedFile.id));
                    setSelectedFile(null);
                })
                .catch(() =>
                    toast.success("Something went wrong. Please try again", SiteConstants.deleteToastConfiguration)
                );
    };

    const handleTextChange = (text: string) => {
        if (text === "") {
            setFilteredFiles(files);
            return;
        }
        setFilteredFiles(
            files.filter(
                (file: FileDataType) =>
                    file.teamName.toLowerCase().includes(text.toLowerCase()) ||
                    file.question.title.toLowerCase().includes(text.toLowerCase())
            )
        );
    };

    if (!searchParam.get("participantFilesLink")) {
        return (
            <div style={{ background: backgroundColor, height: "100vh" }}>
                <Container>
                    <Stack verticalAlign="center" style={{ height: "200px" }} horizontalAlign="center">
                        <Stack.Item align="center">
                            <p className="font-size-20">Please check the url</p>
                        </Stack.Item>
                    </Stack>
                </Container>
            </div>
        );
    }
    if (loading) {
        return (
            <div style={{ background: backgroundColor, height: "100vh" }}>
                <Container>
                    <Stack verticalAlign="center" style={{ height: "200px" }} horizontalAlign="center">
                        <Stack.Item align="center">
                            <CircularProgress />
                        </Stack.Item>
                    </Stack>
                </Container>
            </div>
        );
    }
    if (files.length !== 0) {
        return (
            <div style={{ background: backgroundColor, minHeight: "100vh" }}>
                <Container>
                    <div>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <TextField
                                variant={"outlined"}
                                label={"Search"}
                                size={"small"}
                                className="margin-top-30"
                                onChange={(e) => handleTextChange(e.target.value)}
                            />
                            <PrimaryButton
                                text={"Download Files"}
                                onClick={() => downloadPublicUploadFiles(searchParam.get("participantFilesLink") || "")}
                                className="margin-top-30"
                            />
                        </Grid>
                        <Grid className="margin-top-10" container alignItems={"stretch"} spacing={4}>
                            {filterFiles.length > 0 ? (
                                filterFiles.map((item) => (
                                    <Grid item xs={6} md={3} sm={4} key={item.id}>
                                        <Card>
                                            <CardActionArea>
                                                {item.type && !FieldValidation.videoType.includes(item.type) ? (
                                                    <img
                                                        src={item.fileLocation}
                                                        width={"100%"}
                                                        height={150}
                                                        onError={addDefaultSrc}
                                                        className="black-border"
                                                    />
                                                ) : (
                                                    <video width="100%" height={145} controls>
                                                        <source src={item.fileLocation} />
                                                    </video>
                                                )}
                                                <CardContent>
                                                    <div className="file-card">
                                                        <div>
                                                            <p className="cardSubText">Name:</p>
                                                            <span className="cardLabel"> {item.participantName}</span>
                                                        </div>
                                                        <div>
                                                            <p className="cardSubText">Code:</p>
                                                            <span className="cardLabel">{item.participantCode}</span>
                                                        </div>
                                                        <div>
                                                            <p className="cardSubText">Question:</p>
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item.question.title
                                                                }}
                                                                className="cardLabel"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="cardSubText">Created At:</p>
                                                            <span className="cardLabel">
                                                                {JambarDateUtil.dateFormat(
                                                                    new Date(item.createdAt),
                                                                    "DD/MM/YYYY"
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <p className="cardSubText">File Name:</p>
                                                            <span className="cardLabel">
                                                                {getFileName(item.fileName)}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <p className="cardSubText">File Size:</p>
                                                            <span className="cardLabel">
                                                                {item.fileSize
                                                                    ? `${convertBytesToMB(item.fileSize).toFixed(2)} MB`
                                                                    : "-"}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <p className="cardSubText">Team:</p>
                                                            <span className="cardLabel">{item.teamName}</span>
                                                        </div>
                                                        <Grid container>
                                                            <Button
                                                                onClick={() =>
                                                                    window.open(item.fileLocation, "Download")
                                                                }
                                                                size={"small"}
                                                                style={{
                                                                    textTransform: "none",
                                                                    fontWeight: "bold",
                                                                    paddingLeft: 0
                                                                }}
                                                                variant={"text"}
                                                                color={"primary"}>
                                                                Download
                                                            </Button>
                                                            <Button
                                                                onClick={() =>
                                                                    setSelectedFile({
                                                                        fileName: item.fileName,
                                                                        id: item.id
                                                                    })
                                                                }
                                                                size={"small"}
                                                                style={{
                                                                    textTransform: "none",
                                                                    fontWeight: "bold",
                                                                    paddingLeft: 0
                                                                }}
                                                                variant={"text"}
                                                                color={"secondary"}>
                                                                Delete
                                                            </Button>
                                                        </Grid>
                                                    </div>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                ))
                            ) : (
                                <Grid style={{ height: 500 }} container justifyContent={"center"}>
                                    <Typography className="font-size-20">No searched file found</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Container>
                {selectedFile && (
                    <DeleteConfirmationDialog
                        entryName={selectedFile.fileName}
                        onConfirm={handleDelete}
                        isShown={!!selectedFile}
                        onDismiss={() => setSelectedFile(null)}
                    />
                )}
            </div>
        );
    }

    return (
        <div style={{ background: backgroundColor, height: "100vh" }}>
            <Container>
                <Stack verticalAlign="center" style={{ height: "200px" }} horizontalAlign="center">
                    <Stack.Item align="center">
                        <p className="font-size-20">No Files</p>
                    </Stack.Item>
                </Stack>
            </Container>
        </div>
    );
};

export default PublicFilesView;

import { Pivot, PivotItem } from "office-ui-fabric-react";
import React from "react";
import { SiteConstants } from "../../../constants/SiteConstant";
import QuizListView from "./QuizListView";
import "./QuizView.scss";

const QuizView: React.FC = () => {
    return (
        <Pivot className={"quizViewPivot"}>
            <PivotItem className={"height-100per"} headerText={"Virtual Unlinked"} itemKey={"space-game-quiz"}>
                <QuizListView quizType={SiteConstants.spaceGameQuiz} />
            </PivotItem>
            <PivotItem className={"height-100per"} headerText={"Virtual Linked"} itemKey={"simple-socket-quiz"}>
                <QuizListView quizType={SiteConstants.socketQuiz} />
            </PivotItem>
            <PivotItem className={"height-100per"} headerText={"Wildgoat"} itemKey={"wild-goat-quiz"}>
                <QuizListView quizType={SiteConstants.wildgoat} />
            </PivotItem>
            <PivotItem className={"height-100per"} headerText={"Connector Quiz"} itemKey={"connector-quiz"}>
                <QuizListView quizType={SiteConstants.spaceGameQuiz} isConnector />
            </PivotItem>
        </Pivot>
    );
};

export default QuizView;

/* eslint-disable react-hooks/exhaustive-deps */
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../../utils/APIErrorMessages";
import { CaptionText, StyledTag } from "../../../../../CommonComponent/v2/CommonStyle";
import {
    StyledActionColumn,
    StyledColumn,
    StyledHeader
} from "../../../../../CommonComponent/v2/datagrid/DataGridStyle";
import { EventCodeDeleteType, EventCodeListType } from "./Type";
import { codeDelete, getCodeList, StatusInfoOptions } from "./Util";
import { useAdminContext } from "../../../../context/AdminAuthContext";
import { CiCreditCard1 } from "react-icons/ci";

export const useEventCodeHook = () => {
    const [eventCodeList, setEventCodeList] = useState<EventCodeListType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [addNewCodeModal, setAddNewCodeModal] = useState(false);
    const [codeDeleteModal, setCodeDeleteModal] = useState<EventCodeDeleteType | null>(null);
    const [codeEdit, setCodeEdit] = useState<EventCodeListType | null>(null);
    const { getCreditInfo } = useAdminContext();
    const { id } = useParams<{
        id: string;
    }>();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setIsLoading(true);
        getCodeList(id)
            .then((res) => {
                setEventCodeList(res);
            })
            .catch((err) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                setEventCodeList([]);
            })
            .finally(() => setIsLoading(false));
    };

    const onConfirmDelete = (): void => {
        codeDeleteModal &&
            codeDelete(id, codeDeleteModal?.id)
                .then(() => {
                    toast.success("Code removed", SiteConstants.deleteToastConfiguration);
                    fetchData();
                    getCreditInfo();
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => setCodeDeleteModal(null));
    };

    const eventCodeListColumns = (): GridColDef[] => [
        {
            field: "code",
            flex: 1,
            renderHeader: () => <StyledHeader label="Code" />,
            renderCell: (params: GridCellParams<EventCodeListType, EventCodeListType>) => (
                <StyledTag style={{ width: 150 }}>
                    <CaptionText fontWeight={500} color={"#1F71B6"}>
                        <CiCreditCard1 size={"30px"} visibility={params.row.isPaid ? "inherit" : "hidden"} />{" "}
                        {params.row.code}
                    </CaptionText>
                </StyledTag>
            )
        },
        {
            field: "status",
            flex: 1,
            renderHeader: () => <StyledHeader label="Status" />,
            renderCell: (params: GridCellParams) => (
                <CaptionText fontWeight={500} color={StatusInfoOptions[params.row.status].color}>
                    {StatusInfoOptions[params.row.status].label}
                </CaptionText>
            )
        },
        {
            field: "startDate",
            flex: 1,
            renderHeader: () => <StyledHeader label="Validity" />,
            renderCell: (params: GridCellParams) => (
                <StyledColumn
                    label={`${moment(params.row.startDate).format("DD/MM/YYYY")} - ${moment(params.row.endDate).format(
                        "DD/MM/YYYY"
                    )}`}
                />
            )
        },
        {
            field: "generatedBy",
            flex: 1,
            renderHeader: () => <StyledHeader label="Generated by" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.generatedBy} />
        },
        {
            field: "notes",
            flex: 1,
            renderHeader: () => <StyledHeader label="Notes" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.notes} />
        },
        {
            field: "Action",
            headerName: "Action",
            width: 150,
            renderHeader: () => <StyledHeader label="Action" />,
            renderCell: (params: GridCellParams<EventCodeListType, EventCodeListType>) => (
                <StyledActionColumn
                    isRemoveAvailaible={{
                        isAvailable: params.row.isPaid
                            ? !["expired", "started", "finished"].includes(params.row.status)
                            : true,
                        onClick: () =>
                            setCodeDeleteModal({
                                id: params.row.id,
                                code: params.row.code
                            })
                    }}
                    isEditAvailaible={{
                        isAvailable: ["expired", "active"].includes(params.row.status),
                        onClick: () => {
                            setCodeEdit({
                                id: params.row.id,
                                code: params.row.code,
                                startDate: params.row.startDate,
                                endDate: params.row.endDate,
                                generatedBy: params.row.generatedBy,
                                notes: params.row.notes,
                                status: params.row.status,
                                usedDate: params.row.usedDate,
                                isPaid: params.row.isPaid
                            });
                        }
                    }}
                    isCopyAvailaible={{
                        isAvailable: true,
                        onClick: () => {
                            window.navigator.clipboard.writeText(params.row.code);
                        }
                    }}
                />
            )
        }
    ];

    const toggleAddNewCodeModal = () => {
        setAddNewCodeModal(!addNewCodeModal);
    };

    return {
        eventCodeList,
        isLoading,
        eventCodeListColumns,
        searchText,
        setSearchText,
        addNewCodeModal,
        toggleAddNewCodeModal,
        fetchData,
        codeDeleteModal,
        onConfirmDelete,
        setCodeDeleteModal,
        codeEdit,
        setCodeEdit
    };
};

import { Button, TextField, Grid, Tab } from "@mui/material";
import { useCallback, useState } from "react";
import ReactFlow, {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    Controls,
    Edge,
    Node
} from "react-flow-renderer";
import { TabsButton } from "../../CommonComponent/Style";
import FlowChartBox from "./FlowChartBox";
import FlowChartEditModal from "./FlowChartEditModal";
import { FlowChartContainer } from "./Style";
import { FlowBoxValueType } from "./Type";
import { generateKey, transformData } from "./Util";

const FlowChart = () => {
    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);
    const [nodesJson, setNodeJson] = useState("[]");
    const [edgesJson, setEdgeJson] = useState("[]");
    const [nodeInput, setNodeInput] = useState("");
    const [edgeInput, setEdgeInput] = useState("");
    const [selectedTab, setSelectedTab] = useState("jsonvalue");

    const [selectedBoxToEdit, setSelectedBoxToEdit] = useState<FlowBoxValueType | null>(null);
    const onNodesChange = useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), [setNodes]);
    const [textInJson, setTextInJson] = useState("");

    const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), [setEdges]);

    const renderChart = () => {
        const transformNodes = transformData(JSON.parse(nodeInput)).map((item: FlowBoxValueType) => ({
            ...item,
            data: {
                label: <FlowChartBox onEditClick={() => setSelectedBoxToEdit(item)} boxValue={item} />
            }
        }));
        setNodes(transformNodes as Node[]);
        setEdges(JSON.parse(edgeInput || "[]"));
        setNodeJson(JSON.stringify(transformNodes, null, 4));
        setEdgeJson(JSON.stringify(edgeInput || "[]", null, 4));
    };

    const onConnect = useCallback(
        (connection) => {
            setEdges((eds) =>
                addEdge(
                    {
                        ...connection,
                        markerStart: {
                            type: "arrow",
                            color: "grey"
                        },
                        markerEnd: {
                            type: "arrow",
                            color: "grey"
                        },
                        type: "step"
                    },
                    eds
                )
            );
        },
        [setEdges]
    );

    const addBox = () => {
        const id = generateKey();
        const position = { x: 250, y: 100 * nodes.length };
        const boxValue = {
            id: id,
            description: "description",
            title: "title",
            value: "value",
            position: position
        };

        setNodes((prev) => [
            ...prev,
            {
                ...boxValue,
                data: {
                    label: <FlowChartBox onEditClick={() => setSelectedBoxToEdit(boxValue)} boxValue={boxValue} />
                }
            }
        ]);
    };

    const updateValue = (value: FlowBoxValueType) => {
        const boxValue = {
            ...value,
            position: nodes.find((item) => item.id === value.id)?.position || { x: 250, y: 100 * nodes.length },
            data: {
                label: <FlowChartBox onEditClick={() => setSelectedBoxToEdit(value)} boxValue={value} />
            }
        };
        setNodes(nodes.map((item) => (item.id === value.id ? { ...item, ...boxValue } : item)));
        setSelectedBoxToEdit(null);
    };

    const saveChartinJson = () => {
        setNodeJson(JSON.stringify(nodes, null, 4));
        setEdgeJson(JSON.stringify(edges, null, 4));
        setTextInJson(
            JSON.stringify(
                {
                    nodes: nodes,
                    edges: edges
                },
                null,
                4
            )
        );
    };

    const renderView = () => {
        if (selectedTab === "jsonvalue") {
            return (
                <Grid item xs={12} marginTop={"20px"}>
                    <Grid border={"2px grey dotted"} padding={"20px"} maxHeight={300} overflow={"scroll"}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h6>Node JSON</h6>
                            <Button variant={"outlined"} onClick={() => navigator.clipboard.writeText(nodesJson)}>
                                Copy
                            </Button>
                        </div>
                        <pre>{nodesJson}</pre>
                    </Grid>
                    <Grid
                        marginTop={"30px"}
                        border={"2px grey dotted"}
                        padding={"20px"}
                        maxHeight={300}
                        overflow={"scroll"}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h6>Edge JSON</h6>
                            <Button variant={"outlined"} onClick={() => navigator.clipboard.writeText(edgesJson)}>
                                Copy
                            </Button>
                        </div>
                        <pre>{edgesJson}</pre>
                    </Grid>
                    <Grid
                        marginTop={"30px"}
                        border={"2px grey dotted"}
                        padding={"20px"}
                        maxHeight={300}
                        overflow={"scroll"}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <h6>Node - Edge JSON</h6>
                            <Button variant={"outlined"} onClick={() => navigator.clipboard.writeText(textInJson)}>
                                Copy
                            </Button>
                        </div>
                        <pre>{textInJson}</pre>
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid padding={"20px"}>
                <Grid item xs={12}>
                    <TextField
                        value={nodeInput}
                        onChange={(e) => setNodeInput(e.target.value)}
                        label="Nodes"
                        multiline
                        fullWidth
                        rows={5}
                    />
                </Grid>
                <Grid item xs={12} marginTop={"20px"}>
                    <TextField
                        value={edgeInput}
                        fullWidth
                        onChange={(e) => setEdgeInput(e.target.value)}
                        label="Edges"
                        multiline
                        rows={5}
                    />
                </Grid>
                <Grid item xs={12} marginTop={"20px"} display={"flex"} justifyContent={"center"}>
                    <Button variant={"contained"} onClick={renderChart}>
                        Render Chart
                    </Button>
                </Grid>
            </Grid>
        );
    };

    return (
        <div>
            <Grid container spacing={3}>
                <FlowChartContainer item xs={8}>
                    <ReactFlow
                        style={{ width: "100%", maxHeight: "100vh" }}
                        nodes={nodes}
                        edges={edges}
                        fitView
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}>
                        {/* <MiniMap /> */}
                        <Controls />
                        <Background />
                    </ReactFlow>
                </FlowChartContainer>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} marginTop={"20px"} display={"flex"} justifyContent={"center"}>
                            <Button variant={"contained"} onClick={addBox}>
                                Add boxes
                            </Button>
                        </Grid>
                        <Grid item xs={12} marginTop={"20px"} display={"flex"} justifyContent={"center"}>
                            <Button variant={"contained"} onClick={saveChartinJson}>
                                Save chart
                            </Button>
                        </Grid>
                        <Grid item xs={12} marginTop={"20px"}>
                            <TabsButton
                                centered
                                value={selectedTab}
                                onChange={(_, newValue: string) => setSelectedTab(newValue)}>
                                <Tab label={"JSON Format"} value={"jsonvalue"} />
                                <Tab label={"Render chart"} value={"flowchart"} />
                            </TabsButton>
                            {renderView()}
                        </Grid>
                        {selectedBoxToEdit && (
                            <FlowChartEditModal
                                saveValue={updateValue}
                                boxValue={selectedBoxToEdit}
                                handleClose={() => {
                                    setSelectedBoxToEdit(null);
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default FlowChart;

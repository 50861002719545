import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { EventInfoType } from "./Type";
import { getEventData } from "./Util";
import { useAdminContext } from "../../../context/AdminAuthContext";

export const useEventInfoHook = () => {
    const [selectedTab, setSelectedTab] = useState("detail");
    const [eventDetails, setEventDetails] = useState<EventInfoType | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const { userDetail } = useAdminContext();
    const { id } = useParams<{
        id: string;
    }>();
    const info = useLocation<{
        tab: string;
    }>();

    useEffect(() => {
        id && fetchData();
        info?.state?.tab && setSelectedTab(info.state.tab);
    }, [id]);

    const fetchData = () => {
        setIsLoading(true);
        getEventData(id)
            .then((res) => {
                setEventDetails(res);
            })
            .catch((err) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const eventDetailsTabs = useMemo(
        () => [
            {
                label: "Details",
                value: "detail",
                toShow: true
            },
            {
                label: "Code",
                value: "code",
                toShow: true
            },
            {
                label: "Media",
                value: "files",
                toShow: true
            },
            {
                label: "Survey details",
                value: "survey",
                toShow: userDetail.identity.name === "jambar"
            },
            {
                label: "Participant emails",
                value: "emails",
                toShow: userDetail.identity.name === "jambar"
            },
            {
                label: "Live leaderboards",
                value: "leaderboards",
                toShow: true
            },
            {
                label: "Download",
                value: "download",
                toShow: true
            }
        ],
        []
    );

    return {
        eventDetailsTabs,
        selectedTab,
        setSelectedTab,
        isLoading,
        eventDetails,
        eventId: id,
        fetchData
    };
};

import { TabsProps, Tabs } from "@mui/material";
import { FC } from "react";
import { StyledTab, TabContainer } from "./Style";
import { TabType } from "./Type";

type TabWrapperProps = {
    tabsOptions: TabType[];
    selectedTab: string;
    handleChange: (value: string) => void;
} & TabsProps;
const TabWrapper: FC<TabWrapperProps> = ({ tabsOptions, handleChange, selectedTab, ...rest }) => (
    <TabContainer sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedTab} onChange={(_, newValue) => handleChange(newValue)} {...rest}>
            {tabsOptions
                .filter(({ toShow }) => toShow)
                .map(({ label, value }) => (
                    <StyledTab key={label} label={label} value={value} />
                ))}
        </Tabs>
    </TabContainer>
);

export default TabWrapper;

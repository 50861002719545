import {
    DefaultButton,
    IColor,
    IColumn,
    IconButton,
    Image,
    Label,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField
} from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { ADVANCE_TEXT_EDITOR_CLASS } from "../../../../data/question/types";
import { Client } from "../../../Base/Client";
import DeleteConfirmationDialog from "../../../CommonComponent/DeleteConfirmationDialog";
import TextEditor from "../../../CommonComponent/TextEditor";
import TextFieldWrapper from "../../../CommonComponent/TextFieldWrapper";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { SiteConstants } from "../../../../constants/SiteConstant";
import {
    dashedBorderStyle,
    defaultButtonBackgroundColor,
    successButtonBackgroundColor,
    ThemedMediumStackTokens,
    ThemedSmall2StackTokens
} from "../../../../constants/Styles";
import { ReferenceItemList } from "./ReferenceDetailDataType";
import "./ReferenceDetailView.scss";
import ReferenceItemsDetailsView from "./ReferenceItemsDetailsView";
import ReferenceListAttachmentListView from "./ReferenceListAttchmentListView";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { useFormik } from "formik";
import * as yup from "yup";
import FixedHeaderSortingDetailsList from "../../../CommonComponent/FixedHeaderSortingDetilsList";
import ReferenceListTemplate from "./ReferenceListTemplate";
import ColorPickerWrapper from "../../../CommonComponent/ColorPickerWrapper";
import { convertAlphaToHexadecimal } from "../../../../utils/JambarUtils";

type ReferenceDetailViewProps = {
    referenceId: number;
    refreshReferenceList: () => void;
    closeAddPanel: (id: number) => void;
};

type ReferenceList = {
    title: string;
    coverImage: string;
    descriptionImage: string;
    description: string;
    referenceLink: string;
    generatedLink: string;
    centerHeading: string;
    attachmentTitle: string;
    backgroundImage: string;
    progressImage: string;
    baseColor: string;
    baseButtonColor: string;
};

const ReferenceDetailView: React.FC<ReferenceDetailViewProps> = ({
    referenceId,
    refreshReferenceList,
    closeAddPanel
}) => {
    const referenceListInitialValues: ReferenceList = {
        coverImage: "",
        description: "",
        descriptionImage: "",
        generatedLink: "",
        referenceLink: "",
        title: "",
        attachmentTitle: "",
        backgroundImage: "",
        progressImage: "",
        centerHeading: "",
        baseColor: "",
        baseButtonColor: ""
    };

    const referenceListValidation = yup.object().shape(
        {
            title: yup.string().required("Title is mandatory"),
            description: yup.mixed().when("descriptionImage", {
                is: "",
                then: yup
                    .mixed()
                    .test("validateDescription", "Description is mandatory", (value) =>
                        FieldValidation.quillTextEditorValidation(value)
                    )
            }),
            descriptionImage: yup.string().when("description", (description: string) => {
                if (!description || (description && FieldValidation.quillTextEditorValidation(description))) {
                    return yup.string().required("Description is mandatory");
                }
                return yup.string();
            }),
            generatedLink: yup
                .mixed()
                .test("validateLink", "Please enter valid event link", (value) => FieldValidation.urlValidation(value))
        },
        [["description", "descriptionImage"]]
    );

    const formik = useFormik({
        initialValues: referenceListInitialValues,
        onSubmit: () => onSaveReference(),
        validationSchema: referenceListValidation,
        enableReinitialize: true,
        validateOnMount: true
    });

    const [searchText, setSearchText] = useState("");
    const [showAddEditPanel, setAddEditPanelDisplay] = useState(false);
    const [referenceItemList, setReferenceItemList] = useState<ReferenceItemList[]>([]);
    const [selectedReferenceItemId, setSelectedReferenceItemId] = useState(-1);
    const [showDeleteConfirmationDialog, setDeleteConfirmationDialogDisplay] = useState(false);
    const [coverImageUploadErrorMessage, setCoverImageUploadErrorMessage] = useState("");
    const [coverImageFile, setCoverImageFile] = useState<File | undefined>();
    const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);
    const [isLinkAvailable, setIsLinkAvailable] = useState(false);
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [descriptionImageFile, setDescriptionImageFile] = useState<File | undefined>();
    const [descriptionImageUploadErrorMessage, setDescriptionImageUploadErrorMessage] = useState("");
    const [isRemovingCoverImage, setIsRemovingCoverImage] = useState(false);
    const [isRemovingDescriptionImage, setIsRemovingDescriptionImage] = useState(false);
    const [backgroundImageFile, setBackgroundImageFile] = useState<File | undefined>();
    const [progressImageFile, setProgressImageFile] = useState<File | undefined>();
    const [showColorPickerPanel, setShowColorPickerPanel] = useState({
        type: "",
        showPanel: false
    });
    const [colorPickerValue, setColorPickerValue] = useState("");

    useEffect(() => {
        referenceId === -1 && setIsContentLoading(false);
        referenceId !== -1 && fetchReferenceListDetails();
    }, []);

    const fetchReferenceListDetails = (): void => {
        Client.getInstance()
            .getData(ApiConstants.getReferenceDetailsApiUrl(referenceId), true)
            .then((response: any) => {
                const data = response.data.data;
                formik.setValues({
                    coverImage: data.fileLocation,
                    description: data.description,
                    descriptionImage: data.descriptionImageFileLocation ? data.descriptionImageFileLocation : "",
                    generatedLink: data.referenceLink
                        ? `${window.location.origin}/reference/${data.referenceLink}`
                        : "",
                    referenceLink: data.referenceLink ? data.referenceLink : "",
                    title: data.name,
                    attachmentTitle: data.attachmentTitle,
                    backgroundImage: data.backgroundImageFileLocation || "",
                    progressImage: data.progressImageFileLocation || "",
                    centerHeading: data.centerHeading || "",
                    baseColor: data.backgroundColor || "",
                    baseButtonColor: data.baseButtonColor || ""
                });
                const referenceItems: ReferenceItemList[] = data.reference_list_items.map((element: any) => {
                    return {
                        id: element.reference_item.id,
                        title: element.reference_item.name
                    };
                });
                setReferenceItemList(referenceItems);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.successToastConfiguration);
            })
            .finally(() => {
                setIsContentLoading(false);
            });
    };

    const reverseTransformData = (): any => {
        const referenceListData = new FormData();
        referenceListData.append("name", formik.values.title);
        referenceListData.append("centerHeading", formik.values.centerHeading);
        referenceListData.append("attachmentTitle", formik.values.attachmentTitle);
        referenceListData.append("backgroundColor", formik.values.baseColor);
        referenceListData.append("baseButtonColor", formik.values.baseButtonColor);
        referenceListData.append("backgroundImage", backgroundImageFile as Blob);
        referenceListData.append("progressImage", progressImageFile as Blob);
        referenceListData.append("description", formik.values.description);
        referenceListData.append("referenceListImage", coverImageFile as Blob);
        referenceListData.append("descriptionImage", descriptionImageFile as Blob);

        formik.values.referenceLink && referenceListData.append("referenceLink", formik.values.referenceLink);
        if (referenceId !== -1) {
            referenceListData.append("referenceItems", referenceItemList.map((element) => element.id).join(","));
        }
        return referenceListData;
    };

    const onSaveReference = (): void => {
        if (coverImageUploadErrorMessage || descriptionImageUploadErrorMessage) {
            formik.setSubmitting(false);
            return;
        }
        if (referenceId !== -1) {
            Client.getInstance()
                .updateData(ApiConstants.updateReferenceApiUrl(referenceId), reverseTransformData())
                .then(() => {
                    formik.setSubmitting(false);
                    refreshReferenceList();
                    toast.success("Reference updated", SiteConstants.successToastConfiguration);
                })
                .catch((error) => {
                    formik.setSubmitting(false);
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
            return;
        }

        Client.getInstance()
            .createData(ApiConstants.addReferenceApiUrl(), reverseTransformData())
            .then((response) => {
                formik.setSubmitting(false);
                closeAddPanel(response.data.data.id);
                toast.success("Reference added", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                formik.setSubmitting(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const onOpenEditPanel = (id: number): void => {
        setSelectedReferenceItemId(id);
        setAddEditPanelDisplay(true);
    };

    const onCloseAddPanel = (id: number): void => {
        setSelectedReferenceItemId(id);
        fetchReferenceListDetails();
    };

    const onDeleteReferenceItem = (id: number) => {
        setSelectedReferenceItemId(id);
        setDeleteConfirmationDialogDisplay(true);
    };

    const onConfirmDelete = () => {
        Client.getInstance()
            .deleteData(ApiConstants.deleteReferenceItemApiUrl(selectedReferenceItemId))
            .then(() => {
                setDeleteConfirmationDialogDisplay(false);
                fetchReferenceListDetails();
                setSelectedReferenceItemId(-1);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const onCancelDelete = () => {
        setDeleteConfirmationDialogDisplay(false);
        setSelectedReferenceItemId(-1);
    };

    const referenceItemColumns: IColumn[] = [
        {
            key: "title",
            name: "Title",
            fieldName: "title",
            minWidth: SiteConstants.listColumnLarge
        },
        {
            key: "edit",
            name: "",
            fieldName: "edit",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "delete",
            name: "",
            fieldName: "delete",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        }
    ];

    const renderItemColumn = (
        item: ReferenceItemList,
        index: number | undefined,
        column: IColumn | undefined
    ): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "edit":
                return (
                    <IconButton
                        iconProps={{ iconName: "Edit" }}
                        title="Edit"
                        onClick={() => item.id && onOpenEditPanel(item.id)}
                    />
                );

            case "delete":
                return (
                    <IconButton
                        iconProps={{ iconName: "delete" }}
                        title="Delete"
                        onClick={() => item.id && onDeleteReferenceItem(item.id)}
                    />
                );
            default: {
                const fieldContent = item[column.fieldName as keyof ReferenceItemList] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    const getFilteredItems = (): any => {
        const filteredItems =
            searchText === ""
                ? referenceItemList
                : referenceItemList.filter((item: ReferenceItemList) => {
                      return includeInFilteredItems(searchText.toLowerCase(), item);
                  });
        return filteredItems;
    };

    const includeInFilteredItems = (searchText: string, item: ReferenceItemList): boolean => {
        if (item.title && item.title.toLowerCase().includes(searchText)) {
            return true;
        }
        return false;
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, image: string): void => {
        if (!event.target.files || (event.target.files && event.target.files.length === 0)) {
            return;
        }

        if (!FieldValidation.imageTypeValidation(event.target.files[0].type)) {
            if (image === SiteConstants.COVER_IMAGE) {
                setCoverImageUploadErrorMessage("Please select an image file");
                formik.setFieldValue("coverImage", "");
                setCoverImageFile(undefined);
                return;
            }
            setDescriptionImageUploadErrorMessage("Please select an image file");
            formik.setFieldValue("descriptionImage", "");
            setDescriptionImageFile(undefined);
            return;
        }
        if (FieldValidation.imageSizeValidation(event.target.files[0].size)) {
            if (image === SiteConstants.COVER_IMAGE) {
                setCoverImageUploadErrorMessage("Image size should be less than 2 MB");
                formik.setFieldValue("coverImage", "");
                setCoverImageFile(undefined);
                return;
            }
            setDescriptionImageUploadErrorMessage("Image size should be less than 2 MB");
            formik.setFieldValue("descriptionImage", "");
            setDescriptionImageFile(undefined);
            return;
        }
        if (image === SiteConstants.COVER_IMAGE) {
            setCoverImageUploadErrorMessage("");
            formik.setFieldValue("coverImage", URL.createObjectURL(event.target.files[0]));
            setCoverImageFile(event.target.files[0]);
            return;
        }
        setDescriptionImageUploadErrorMessage("");
        formik.setFieldValue("descriptionImage", URL.createObjectURL(event.target.files[0]));
        setDescriptionImageFile(event.target.files[0]);
        return;
    };

    const onCheckLinkAvailability = (): void => {
        setIsCheckingAvailability(true);
        Client.getInstance()
            .getData(ApiConstants.checkReferenceLinkAvailability(formik.values.referenceLink), true)
            .then((response) => {
                if (response.data.data.id === referenceId) {
                    setIsLinkAvailable(true);
                    setIsCheckingAvailability(false);
                    return;
                }
                setIsLinkAvailable(false);
                setIsCheckingAvailability(false);
                formik.setFieldError("generatedLink", "This link is not available");
            })
            .catch(() => {
                setIsLinkAvailable(true);
                setIsCheckingAvailability(false);
            });
    };

    const removeImage = (image: string, imageUrl: string) => {
        if (referenceId !== -1) {
            image === SiteConstants.DESCRIPTION_IMAGE
                ? setIsRemovingDescriptionImage(true)
                : setIsRemovingCoverImage(true);
            Client.getInstance()
                .deleteData(imageUrl)
                .then(() => {
                    if (image === SiteConstants.DESCRIPTION_IMAGE) {
                        setIsRemovingDescriptionImage(false);
                        formik.setFieldValue("descriptionImage", "");
                        setDescriptionImageFile(undefined);
                        toast.success("Description image removed", SiteConstants.successToastConfiguration);
                        return;
                    }
                    setIsRemovingCoverImage(false);
                    formik.setFieldValue("coverImage", "");
                    setCoverImageFile(undefined);
                    toast.success("Cover image removed", SiteConstants.successToastConfiguration);
                })
                .catch((error) => {
                    if (image === SiteConstants.DESCRIPTION_IMAGE) {
                        setIsRemovingDescriptionImage(false);
                        toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                        return;
                    }
                    setIsRemovingCoverImage(false);
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
            return;
        }

        if (image === SiteConstants.COVER_IMAGE) {
            formik.setFieldValue("coverImage", "");
            setCoverImageFile(undefined);
            return;
        }
        formik.setFieldValue("descriptionImage", "");
        setDescriptionImageFile(undefined);
        return;
    };

    const getReferenceDetailsSection = (): JSX.Element => {
        return (
            <Stack
                tokens={ThemedMediumStackTokens}
                className="section-background section-border padding-10 overflowy-auto">
                <Stack.Item>
                    <Label>Cover image</Label>
                    <Stack horizontal>
                        <Stack.Item grow>
                            <Form.Group>
                                <Form.File
                                    value={formik.values.coverImage === "" ? "" : undefined}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleFileChange(event, SiteConstants.COVER_IMAGE)
                                    }
                                />
                                {coverImageUploadErrorMessage && (
                                    <span className={"error-message"}>{coverImageUploadErrorMessage}</span>
                                )}
                            </Form.Group>
                        </Stack.Item>
                        <Stack horizontalAlign={"end"} verticalAlign={"start"}>
                            {isRemovingCoverImage ? (
                                <Spinner size={SpinnerSize.large} />
                            ) : (
                                <DefaultButton
                                    onClick={() =>
                                        removeImage(
                                            SiteConstants.COVER_IMAGE,
                                            ApiConstants.removeReferenceListCoverImage(referenceId)
                                        )
                                    }
                                    className={"dashed-border-default-button"}
                                    text={"Remove Cover Image"}
                                />
                            )}
                        </Stack>
                    </Stack>
                    {formik.values.coverImage && (
                        <Image src={formik.values.coverImage} alt="Cover Image" className={"reference-cover-image"} />
                    )}
                </Stack.Item>

                <Stack.Item grow>
                    <TextField
                        onChange={formik.handleChange}
                        value={formik.values.title}
                        label={"Title"}
                        name={"title"}
                        errorMessage={formik.errors.title}
                    />
                </Stack.Item>

                <Stack.Item>
                    <Label>Description image</Label>
                    <Stack horizontal>
                        <Stack.Item grow>
                            <Form.Group>
                                <Form.File
                                    value={formik.values.descriptionImage === "" ? "" : undefined}
                                    onChange={(event: any) => handleFileChange(event, SiteConstants.DESCRIPTION_IMAGE)}
                                />
                                {descriptionImageUploadErrorMessage && (
                                    <span className={"error-message"}>{descriptionImageUploadErrorMessage}</span>
                                )}
                            </Form.Group>
                        </Stack.Item>
                        <Stack horizontalAlign={"end"} verticalAlign={"start"}>
                            {isRemovingDescriptionImage ? (
                                <Spinner size={SpinnerSize.large} />
                            ) : (
                                <DefaultButton
                                    onClick={() =>
                                        removeImage(
                                            SiteConstants.DESCRIPTION_IMAGE,
                                            ApiConstants.removeReferenceListDescrptionImage(referenceId)
                                        )
                                    }
                                    className={"dashed-border-default-button"}
                                    text={"Remove Description Image"}
                                />
                            )}
                        </Stack>
                    </Stack>
                    {formik.values.descriptionImage && (
                        <Image
                            src={formik.values.descriptionImage}
                            alt="Description image"
                            className={"reference-cover-image"}
                        />
                    )}
                </Stack.Item>

                <Stack.Item grow>
                    <Stack>
                        <Stack.Item>
                            <Label>Description</Label>
                        </Stack.Item>
                        <Stack.Item>
                            <TextEditor
                                className={`${ADVANCE_TEXT_EDITOR_CLASS} description-text quill-blue-background`}
                                value={formik.values.description}
                                onChange={(text: string) => formik.setFieldValue("description", text)}
                            />
                        </Stack.Item>
                        {(formik.errors.descriptionImage || formik.errors.description) && (
                            <Stack.Item>
                                <span className={"error-message"}>Description is mandatory</span>
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
                <Stack horizontal tokens={ThemedSmall2StackTokens}>
                    <Stack.Item grow>
                        <TextFieldWrapper
                            onRenderDescription={() =>
                                !isLinkAvailable ||
                                (formik.values.referenceLink && formik.errors.generatedLink) ? null : (
                                    <Stack className={"success-message"}>
                                        <Text variant="small">{"Link is available"}</Text>
                                    </Stack>
                                )
                            }
                            onTextFieldValueChange={(text: string) => {
                                formik.setFieldValue("referenceLink", text);
                                formik.setFieldValue("generatedLink", `${window.location.origin}/reference/${text}`);
                                setIsLinkAvailable(false);
                            }}
                            value={formik.values.referenceLink}
                            label={"Reference list code"}
                            errorMessage={formik.errors.generatedLink}
                        />
                    </Stack.Item>
                    <Stack.Item
                        className={
                            (formik.values.referenceLink && formik.errors.generatedLink) || isLinkAvailable
                                ? "margin-top-8"
                                : ""
                        }
                        align={
                            (formik.values.referenceLink && formik.errors.generatedLink) || isLinkAvailable
                                ? "center"
                                : "end"
                        }>
                        {isCheckingAvailability ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : (
                            <DefaultButton
                                onClick={onCheckLinkAvailability}
                                text={"Check Availability"}
                                iconProps={{ iconName: isLinkAvailable ? "Accept" : "" }}
                                styles={{
                                    root: {
                                        background: isLinkAvailable
                                            ? successButtonBackgroundColor
                                            : defaultButtonBackgroundColor,
                                        borderStyle: dashedBorderStyle
                                    }
                                }}
                                disabled={
                                    formik.values.referenceLink === "" ||
                                    !FieldValidation.urlValidation(formik.values.generatedLink)
                                }
                            />
                        )}
                    </Stack.Item>
                </Stack>
            </Stack>
        );
    };

    const getReferenceListSection = (): JSX.Element => {
        return (
            <Stack className="section-background section-border padding-10 referenceItems-list">
                <FixedHeaderSortingDetailsList
                    onSearchTextChange={(text: string) => setSearchText(text)}
                    searchText={searchText}
                    onAddButtonClick={() => {
                        setAddEditPanelDisplay(true);
                        setSelectedReferenceItemId(-1);
                    }}
                    buttonLabel={"Add New"}
                    searchFieldWithoutLabel={true}
                    columns={referenceItemColumns}
                    isLoading={false}
                    items={getFilteredItems()}
                    onRenderItemColumn={renderItemColumn}
                />
                <DeleteConfirmationDialog
                    entryName={"reference item"}
                    isShown={showDeleteConfirmationDialog}
                    onConfirm={onConfirmDelete}
                    onDismiss={onCancelDelete}
                />
                <Panel
                    isLightDismiss
                    isOpen={showAddEditPanel}
                    onDismiss={() => {
                        setAddEditPanelDisplay(false);
                        setSelectedReferenceItemId(-1);
                    }}
                    headerText={selectedReferenceItemId === -1 ? "Add Reference Item" : "Edit Reference Item"}
                    type={PanelType.medium}>
                    <ReferenceItemsDetailsView
                        closeAddPanel={(referenceItemId: number) => onCloseAddPanel(referenceItemId)}
                        referenceItemId={selectedReferenceItemId}
                        referenceListId={referenceId}
                        refreshReferenceItemList={() => fetchReferenceListDetails()}
                    />
                </Panel>
            </Stack>
        );
    };

    if (isContentLoading) {
        return <Spinner className={"loading-spinner"} size={SpinnerSize.large} label={"Loading..."} />;
    }

    return (
        <>
            <ToastContainer />
            <Stack tokens={ThemedMediumStackTokens}>
                <Pivot>
                    <PivotItem headerText={"Details"} itemKey={"details"} className={"margin-top-10"}>
                        {getReferenceDetailsSection()}
                    </PivotItem>

                    {referenceId !== -1 && (
                        <PivotItem headerText={"Items"} itemKey={"items"} className={"margin-top-10"}>
                            {getReferenceListSection()}
                        </PivotItem>
                    )}
                    {referenceId !== -1 && (
                        <PivotItem headerText={"Attachments"} itemKey={"attachments"} className={"margin-top-10"}>
                            <ReferenceListAttachmentListView referenceListId={referenceId} />
                        </PivotItem>
                    )}
                    <PivotItem headerText={"Template"} itemKey={"template"} className={"margin-top-10"}>
                        <ReferenceListTemplate
                            centerHeading={formik.values.centerHeading}
                            centerHeadingChange={(text: string) => formik.setFieldValue("centerHeading", text)}
                            attachmentTitle={formik.values.attachmentTitle}
                            attachmentTitleChange={(text: string) => formik.setFieldValue("attachmentTitle", text)}
                            backgroundImage={formik.values.backgroundImage}
                            backgroundImageChange={(image: string) => formik.setFieldValue("backgroundImage", image)}
                            progressImage={formik.values.progressImage}
                            progressImageChange={(image: string) => formik.setFieldValue("progressImage", image)}
                            setBackgroundImageFile={(file) => setBackgroundImageFile(file)}
                            setProgressImageFile={(file) => setProgressImageFile(file)}
                            referenceId={referenceId}
                            baseColor={formik.values.baseColor}
                            setBaseColorChange={(color) => formik.setFieldValue("baseColor", color)}
                            setShowColorPickerPanel={(type) => setShowColorPickerPanel({ showPanel: true, type: type })}
                            buttonColor={formik.values.baseButtonColor}
                            setBaseButtonColor={(buttonColor) => formik.setFieldValue("baseButtonColor", buttonColor)}
                            setColorPicker={(color) => setColorPickerValue(color)}
                        />
                        {showColorPickerPanel.showPanel && (
                            <Panel
                                isLightDismiss
                                isOpen={true}
                                onDismiss={() =>
                                    setShowColorPickerPanel({
                                        showPanel: false,
                                        type: ""
                                    })
                                }
                                headerText={"Pick color"}
                                type={PanelType.custom}
                                customWidth={"450px"}>
                                <Stack
                                    tokens={ThemedMediumStackTokens}
                                    className={"section-background section-border padding-0 margin-top-10"}>
                                    <ColorPickerWrapper
                                        color={colorPickerValue}
                                        onChange={(colorObject: IColor) => {
                                            setColorPickerValue(
                                                `#${colorObject.hex}${convertAlphaToHexadecimal(colorObject.a || 0)}`
                                            );
                                        }}
                                    />
                                </Stack>
                                <Stack horizontalAlign={"center"} className={"margin-top-10"}>
                                    <PrimaryButton
                                        text={"Save"}
                                        onClick={() => {
                                            showColorPickerPanel.type === "backgroundColor"
                                                ? formik.setFieldValue("baseColor", colorPickerValue)
                                                : formik.setFieldValue("baseButtonColor", colorPickerValue);
                                            setShowColorPickerPanel({ type: "", showPanel: false });
                                        }}
                                    />
                                </Stack>
                            </Panel>
                        )}
                    </PivotItem>
                </Pivot>
                <>
                    <Stack horizontalAlign={"center"}>
                        {formik.isSubmitting ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : (
                            <PrimaryButton
                                iconProps={{ iconName: "Save" }}
                                text={"Save"}
                                onClick={() => formik.handleSubmit()}
                            />
                        )}
                    </Stack>
                    <Stack horizontal horizontalAlign={"center"} className={"margin-bottom-15"}>
                        {(!formik.isValid || descriptionImageUploadErrorMessage || coverImageUploadErrorMessage) && (
                            <span className={"error-message"}>Please fill in all the mandatory fields</span>
                        )}
                    </Stack>
                </>
            </Stack>
        </>
    );
};

export default ReferenceDetailView;

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getPermissions } from "../../../../../utils/PermissionUtils";
import { OptionType } from "../../../../CommonComponent/v2/fields/Type";
import { useAdminContext } from "../../../context/AdminAuthContext";
import { getTagDropdownData } from "../../questionlibrary/Utils";
import { GameDetailType } from "./Type";
import { getGameDetailData, saveQuiz, saveTemplate, updateQuizDetail, updateTemplate } from "./Util";

export const useGameLibraryHook = () => {
    const [gameDetails, setGameDetails] = useState<GameDetailType>();
    const [isLoading, setIsLoading] = useState(true);
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "question");
    const [tagOptions, setTagOptions] = useState<OptionType[]>([]);
    const history = useHistory();

    const { gameType, gameId } = useParams<{
        gameType: string;
        gameId: string;
    }>();

    useEffect(() => {
        fetchTagData();
        gameId && fetchGameData();
    }, []);

    const fetchGameData = () => {
        setIsLoading(true);
        gameId &&
            getGameDetailData(gameId).then((res) => {
                setGameDetails(res);
            });
    };

    const fetchTagData = () => {
        if (!(permission && permission.includes("fetch"))) {
            setTagOptions([]);
            return;
        }
        getTagDropdownData(true)
            .then((res) => {
                setTagOptions(res);
            })
            .catch(() => {
                setTagOptions([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateQuiz = (values: GameDetailType, setSubmitting: (isSubmitting: boolean) => void) => {
        if (gameId) {
            updateQuizDetail(gameId, values)
                .then(() => {
                    updateTemplate(values, parseInt(gameId))
                        .then(() => {
                            setSubmitting(false);
                            history.push("/admin/v2/gamelibrary");
                        })
                        .catch(() => {
                            setSubmitting(false);
                        });
                })
                .catch(() => {
                    setSubmitting(false);
                });
            return;
        }

        saveQuiz(values)
            .then((id) => {
                saveTemplate(values, id)
                    .then(() => {
                        setSubmitting(false);
                        history.push("/admin/v2/gamelibrary");
                    })
                    .catch(() => {
                        setSubmitting(false);
                    });
            })
            .catch(() => {
                setSubmitting(false);
            });
    };

    return {
        gameType,
        gameId,
        gameDetails,
        setGameDetails,
        isLoading,
        setIsLoading,
        tagOptions,
        updateQuiz
    };
};

/* eslint-disable react-hooks/exhaustive-deps */
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { getPermissions } from "../../../../utils/PermissionUtils";
import { StyledActionColumn, StyledColumn, StyledHeader } from "../../../CommonComponent/v2/datagrid/DataGridStyle";
import { GameInfoType, QuizListItemType, UsageListType } from "./Type";
import {
    changeVisibilityOfQuiz,
    fetchQuizUsage,
    getQuizListData,
    isGameUpdateAvailaible,
    quizClone,
    quizDelete
} from "./Util";
import { useUserInfo } from "../../../../commonhooks/Hooks";
import { Box, Tooltip } from "@mui/material";
import { getInitials } from "../../../../utils/JambarUtils";
import wildgoatIcon from "../../../Images/icons/wildgoatdisabled.svg";
import virtualLinkedIcon from "../../../Images/icons/virtuallinkeddisabled.svg";
import virtualUnLinked from "../../../Images/icons/virtualUnlinkedDisabled.svg";
import lock from "../../../Images/icons/lock.svg";
import globe from "../../../Images/icons/globe.svg";
import limited from "../../../Images/icons/limited.svg";
import { FILTER_OPTION_NAME, SelectedFilterType } from "../questionlibrary/Type";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { OptionType } from "../../../CommonComponent/v2/fields/Type";
import { getAllIdentitiesList } from "../questionlibrary/addquestion/Util";

export const useGameLibrary = () => {
    const {
        onlyOneGameAllowed,
        permissionQuizFullGame,
        getGameOption,
        permissionQuizPhysicalGame,
        permissionQuizSocketGame
    } = useUserInfo();
    const [searchText, setSearchText] = useState("");
    const [tabOption, setSelectedTabOption] = useState<string>("wildgoat");
    const [selectedStatusOption, setSelectedStatusOption] = useState("active");
    const [cloneId, setCloneId] = useState<GameInfoType | null>(null);
    const [deleteId, setDeleteId] = useState<GameInfoType | null>(null);
    const [isLoading, setIsLoading] = useState({
        listLoading: false,
        usageListLoading: false
    });
    const [quizList, setQuizList] = useState<QuizListItemType[]>([]);
    const [usageList, setUsageList] = useState<UsageListType[]>([]);
    const [openAddModal, setOpenAddModal] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();
    const { isSuperAdmin } = useUserInfo();
    const searchParams = new URLSearchParams(location.search);
    const [showBatchUpdate, setShowBatchUpdate] = useState(false);
    const paramsSearchText = searchParams.get("searchtext");
    const paramsGameType = searchParams.get("gameType");
    const [selectedRows, setSelectedRows] = useState<QuizListItemType[]>([]);
    const [identityDropdownOptions, setIdentityDropdownOptions] = useState<OptionType[]>([]);

    useEffect(() => {
        fetchData();
        history.push(
            `/admin/v2/gamelibrary?gameType=${tabOption}${paramsSearchText ? `&searchtext=${paramsSearchText}` : ""}`
        );
    }, [tabOption, selectedStatusOption]);

    const getSelectedTab = (gameType?: string) => {
        if (gameType === "null" || !gameType) {
            return SiteConstants.wildgoat;
        }
        return gameType;
    };

    useEffect(() => {
        fetchIdentityDropdowndownData();
        setSelectedTabOption(getSelectedTab(paramsGameType));
        paramsSearchText && setSearchText(paramsSearchText);
    }, []);

    const fetchIdentityDropdowndownData = () => {
        getAllIdentitiesList(true)
            .then((res) => setIdentityDropdownOptions(res))
            .catch(() => setIdentityDropdownOptions([]));
    };

    useEffect(() => {
        if (deleteId) {
            setUsageList([]);
            setIsLoading((prev) => ({
                ...prev,
                usageListLoading: true
            }));
            fetchQuizUsage(true, deleteId.id).then((res) => {
                setUsageList(res);
                setIsLoading((prev) => ({
                    ...prev,
                    usageListLoading: false
                }));
            });
        }
    }, [deleteId]);

    const fetchData = (): void => {
        setIsLoading((prev) => ({
            ...prev,
            listLoading: true
        }));
        if (
            !(
                permissionQuizFullGame?.includes("fetch") ||
                permissionQuizSocketGame?.includes("fetch") ||
                permissionQuizPhysicalGame?.includes("fetch")
            )
        ) {
            setQuizList([]);
            setIsLoading((prev) => ({
                ...prev,
                listLoading: false
            }));
            return;
        }
        getQuizListData(
            tabOption === SiteConstants.connectorQuestion ? SiteConstants.spaceGameQuiz : tabOption,
            selectedStatusOption,
            true
        )
            .then((res) => {
                if (tabOption === SiteConstants.connectorQuestion) {
                    setQuizList(res.filter((item) => item.isConnectorQuiz));
                    return;
                }
                setQuizList(() => {
                    setIsLoading((prev) => ({
                        ...prev,
                        listLoading: false
                    }));
                    return res.filter((item) => !item.isConnectorQuiz);
                });
            })
            .catch(() => {
                setQuizList([]);
                setIsLoading((prev) => ({
                    ...prev,
                    listLoading: false
                }));
            });
    };

    const handleGameClone = () => {
        cloneId?.id &&
            quizClone(cloneId.id)
                .then(() => {
                    fetchData();
                    setCloneId(null);
                })
                .catch(() => setCloneId(null));
    };

    const handleGameDelete = () => {
        deleteId &&
            quizDelete(deleteId.id).then(() => {
                setDeleteId(null);
                fetchData();
            });
    };

    const toggleAddModal = () => {
        if (onlyOneGameAllowed) {
            history.push(`/admin/gamelibrary/${getGameOption()}/add`);
            return;
        }
        setOpenAddModal(!openAddModal);
    };

    const confirmGameSet = (selectedFilter: SelectedFilterType, resolve: () => void) => {
        const ids = selectedRows.map((item) => item.id.toString());
        if (selectedFilter.selectedFilterName === FILTER_OPTION_NAME.CHANGE_VISIBILITY) {
            changeVisibilityOfQuiz(
                ids,
                selectedFilter.selectedFilterValue as string,
                selectedFilter.identityFilterOption
            )
                .then(() => {
                    toast.success("Game visbility updated successfully", SiteConstants.successToastConfiguration);
                    setSelectedRows([]);
                    fetchData();
                })
                .catch((error) => toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration))
                .finally(() => {
                    resolve();
                });
            return;
        }
    };

    return {
        searchText,
        setSearchText,
        setSelectedTabOption,
        tabOption,
        setSelectedStatusOption,
        selectedStatusOption,
        setCloneId,
        setDeleteId,
        cloneId,
        deleteId,
        isLoading,
        quizList,
        handleGameDelete,
        handleGameClone,
        usageList,
        openAddModal,
        toggleAddModal,
        permissionQuizFullGame,
        permissionQuizSocketGame,
        permissionQuizPhysicalGame,
        showBatchUpdate,
        setShowBatchUpdate,
        isSuperAdmin,
        selectedRows,
        setSelectedRows,
        confirmGameSet,
        identityDropdownOptions
    };
};

export const useGameListHook = (searchText: string) => {
    const { onlyOneGameAllowed, isSuperAdmin, userDetail } = useUserInfo();
    const permissionQuizFullGame: string[] | null = getPermissions(userDetail, "quiz_fullgame");
    const permissionQuizSocketGame: string[] | null = getPermissions(userDetail, "quiz_socket");
    const permissionQuizPhysicalGame: string[] | null = getPermissions(userDetail, "quiz_wildgoat");

    const history = useHistory();

    const renderGameTypeIcon = (type: string) => {
        if (onlyOneGameAllowed) {
            return <></>;
        }
        if (type === "fullgame")
            return (
                <Tooltip title={"Virtual Unlinked"}>
                    <img
                        style={{ marginRight: "5px", height: 20, width: 20 }}
                        src={virtualUnLinked}
                        alt={"virtualUnLinked"}
                    />
                </Tooltip>
            );
        if (type === "simplesocket")
            return (
                <Tooltip title={"Virtual linked"}>
                    <img
                        style={{ marginRight: "5px", height: 20, width: 20 }}
                        src={virtualLinkedIcon}
                        alt={"virtualLinkedIcon"}
                    />
                </Tooltip>
            );
        if (type === "wildgoat")
            return (
                <Tooltip title={"Wildgoat game"}>
                    <img
                        style={{ marginRight: "5px", height: 20, width: 20 }}
                        src={wildgoatIcon}
                        alt={"wildgoatIcon"}
                    />
                </Tooltip>
            );
        return "";
    };

    const selectVisibilityIcon = (type: string) => {
        if (type === "private")
            return (
                <Tooltip title={"Private"}>
                    <img style={{ marginRight: "3px", height: 20, width: 20 }} src={lock} alt={"lock"} />
                </Tooltip>
            );
        if (type === "global")
            return (
                <Tooltip title={"Global"}>
                    <img style={{ marginRight: "3px", height: 20, width: 20 }} src={globe} alt={"glob"} />
                </Tooltip>
            );
        return (
            <Tooltip title={"Limited"}>
                <img style={{ marginRight: "3px", height: 20, width: 20 }} src={limited} alt={"limited"} />
            </Tooltip>
        );
    };

    const gameListColumns = (
        tabOption: string,
        handleCopyLinkClick: (info: GameInfoType) => void,
        handleDeleteClick: (info: GameInfoType) => void
    ): GridColDef[] => [
        {
            field: "title",
            headerName: "Game Name",
            flex: 4,
            renderHeader: () => <StyledHeader label="Game Name" />,
            renderCell: (params: GridCellParams<QuizListItemType, QuizListItemType>) => (
                <Box alignItems={"center"} style={{ cursor: "pointer" }} display={"flex"}>
                    {renderGameTypeIcon(params.row.type)}
                    {selectVisibilityIcon(params.row.accessibilityScope)}
                    <StyledColumn
                        onClick={() => {
                            history.push(
                                `/admin/v2/gamelibrary/${params.row.id}?gameType=${tabOption}${
                                    searchText ? `&searchtext=${searchText}` : ""
                                }`
                            );
                        }}
                        label={params.row.title}
                    />
                </Box>
            )
        },
        {
            field: "createdBy",
            minWidth: 150,
            flex: 1,
            renderHeader: () => <StyledHeader label="Created By" />,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.createdBy}>
                    <Box style={{ display: "flex", gap: 3 }}>
                        <StyledColumn width={"30px"} label={`${getInitials(params?.row?.createdBy || "")}`} />
                        <StyledColumn label={`- ${params.row.createdDate}`} fontWeight={340} />
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "lastUpdatedBy",
            minWidth: 150,
            flex: 1,
            renderHeader: () => <StyledHeader label="Last Updated By" />,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.lastUpdatedBy}>
                    <Box style={{ display: "flex", gap: 3 }}>
                        <StyledColumn width={"30px"} label={`${getInitials(params?.row?.lastUpdatedBy || "")}`} />
                        <StyledColumn label={`- ${params.row.lastUpdatedDate}`} fontWeight={340} />
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "identityName",
            flex: 1,
            renderHeader: () => <StyledHeader label="Identity Name" />,
            renderCell: (params: GridCellParams<QuizListItemType, QuizListItemType>) => (
                <Tooltip title={params.row.identityName}>
                    <Box style={{ display: "flex", gap: 3 }}>
                        <StyledColumn width={"30px"} label={params.row.identityName} />
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "Action",
            headerName: "Action",
            flex: 1,
            renderHeader: () => <StyledHeader label="Action" />,
            renderCell: (params: GridCellParams<QuizListItemType, QuizListItemType>) => (
                <StyledActionColumn
                    isDeleteAvailaible={{
                        isAvailable: isGameUpdateAvailaible({
                            isSuperAdmin,
                            accessibilityScope: params.row.accessibilityScope,
                            permissionQuizFullGame,
                            permissionQuizPhysicalGame,
                            permissionQuizSocketGame
                        }),
                        onClick: () =>
                            handleDeleteClick({
                                id: params.row.id,
                                title: params.row.title
                            })
                    }}
                    isEditAvailaible={{
                        isAvailable: isGameUpdateAvailaible({
                            isSuperAdmin,
                            accessibilityScope: params.row.accessibilityScope,
                            permissionQuizFullGame,
                            permissionQuizPhysicalGame,
                            permissionQuizSocketGame
                        }),
                        onClick: () => {
                            history.push(
                                `/admin/v2/gamelibrary/${params.row.id}?gameType=${tabOption}${
                                    searchText ? `&searchtext=${searchText}` : ""
                                }`
                            );
                        }
                    }}
                    isCloneAvailaible={{
                        isAvailable: !!(
                            permissionQuizFullGame?.includes("clone") ||
                            permissionQuizSocketGame?.includes("clone") ||
                            permissionQuizPhysicalGame?.includes("clone")
                        ),
                        onClick: () => {
                            handleCopyLinkClick({
                                id: params.row.id,
                                title: params.row.title
                            });
                        }
                    }}
                />
            )
        }
    ];

    return {
        gameListColumns,
        isSuperAdmin
    };
};

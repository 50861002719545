import Styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const SelectedListContainer = Styled(Grid)`
    & .ms-DetailsRow-fields {
        background: #f2f2f2 !important;
    }

    & .ms-ScrollablePane--contentContainer{
        background: #f2f2f2 !important;
        border-radius: 10px !important;
    }

    & .ms-DetailsRow-cell {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #333333;
    }

    & .ms-DetailsHeader-cellName {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #828282;
    }

    & .ms-DetailsHeader {
        background: #f2f2f2;
        border-bottom: 2px #C2C2C2 solid;
        padding: 10px 0px 0px 0px;
        
    }
`;

export const TablePaginationContainer = Styled(Grid)`
  & .MuiTablePagination-displayedRows {
    margin-bottom: 0px;
  }  
`;

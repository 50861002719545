import { Grid } from "@mui/material";
import { FC } from "react";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { EventListItem } from "../../EventManager/EventListDataTypes";
import { useFileManagerListHook } from "./Hooks";
import { SelectedFileType } from "./Type";
import { getFileListFilteredItems } from "./Util";

type FileManagerListProps = {
    openFileDetailPanel: (eventInfo: SelectedFileType) => void;
    eventList: EventListItem[];
    isLoading: boolean;
    searchText: string;
};
const FileManagerList: FC<FileManagerListProps> = ({ eventList, openFileDetailPanel, searchText, isLoading }) => {
    const { fileManagerListColumns } = useFileManagerListHook();
    return (
        <Grid height={"calc(100vh - 303px)"}>
            <DataGridWrapper
                columns={fileManagerListColumns(openFileDetailPanel)}
                rows={getFileListFilteredItems(eventList, searchText)}
                loading={isLoading}
            />
        </Grid>
    );
};

export default FileManagerList;

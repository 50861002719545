import { DataGrid, DataGridProps, GridColDef, GridRowProps } from "@mui/x-data-grid";
import { FC } from "react";
import { StyledGrid } from "./Style";

type DataGridWrapperProps = {
    rows: GridRowProps[];
    columns: GridColDef[];
    loading?: boolean;
} & DataGridProps;

const DataGridWrapper: FC<DataGridWrapperProps> = ({ rows, columns, loading, ...rest }) => {
    return (
        <StyledGrid height={"100%"}>
            <DataGrid
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[]}
                disableExtendRowFullWidth
                disableSelectionOnClick
                disableColumnMenu
                headerHeight={42}
                localeText={{ noRowsLabel: "Nothing Yet!" }}
                loading={loading}
                {...rest}
            />
        </StyledGrid>
    );
};
export default DataGridWrapper;

import Axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { ApiConstants } from "../../constants/ApiConstant";
import { SiteConstants } from "../../constants/SiteConstant";
import { LoginUtil } from "../../utils/LoginUtils";

export class Client<T> {
    protected constructor() {
        this._data = {};
    }

    public static getInstance<U>() {
        if (!this._instance) {
            this._instance = new Client<U>();
        }

        return this._instance;
    }

    public handleError(errStatus: number) {
        if (errStatus === 401) {
            LoginUtil.removeUserSession();
            toast.error("User Session timed out", SiteConstants.deleteToastConfiguration);
            Client.getInstance().getData(ApiConstants.getLogoutApiUrl(), true);
            window.location.href = "/admin/login";
        }
    }
    public getData(url: string, forceRefresh = false): Promise<any> {
        if (forceRefresh || !this._data[url]) {
            return Axios.get(url)
                .then((result: any) => {
                    this._data[url] = Promise.resolve(result);
                    return this._data[url];
                })
                .catch((error: any) => {
                    this.handleError(error.response.status);
                    return Promise.reject(error);
                });
        }

        return this._data[url];
    }

    public downloadZip(url: string): Promise<any> {
        return Axios.get(url, {
            responseType: "arraybuffer",
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((result: any) => {
            this._data[url] = Promise.resolve(result);
            return this._data[url];
        });
    }

    public updateData(url: string, data: any): Promise<any> {
        return Axios.put(url, data).then((result: any) => {
            return result;
        });
    }

    public createData(url: string, data: any, config?: AxiosRequestConfig): Promise<any> {
        return Axios.post(url, data, config).then((result: any) => {
            return result;
        });
    }

    public deleteData(url: string): Promise<any> {
        return Axios.delete(url).then((result: any) => {
            return result;
        });
    }

    public setAuthenticationClient = (token: string): void => {
        Axios.interceptors.request.use(async (config): Promise<AxiosRequestConfig> => {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = token;
            return config;
        });
    };

    private static _instance: Client<any>;

    private _data: { [key: string]: Promise<T> };
}

import { Stack } from "@fluentui/react";
import { Grid } from "@mui/material";
import React from "react";
import { Button } from "react-bootstrap";
import { ThemedMediumStackTokens } from "../../../constants/Styles";

type IQuizCoverImageNativeProps = {
    totalMarks: number;
    progressImage: string;
    progressTitle: string;
    showProgressInPercentage: boolean;
    progressColor: string;
    correctAnswerBgColor: string;
    wrongAnswerBgColor: string;
    hideScore?: boolean;
};

const QuizCoverImage: React.FC<IQuizCoverImageNativeProps> = ({
    totalMarks,
    progressImage,
    progressTitle,
    showProgressInPercentage,
    progressColor,
    correctAnswerBgColor,
    wrongAnswerBgColor,
    hideScore = false
}) => {
    return (
        <Stack
            className={"quiz-cover-image"}
            style={{
                background: `linear-gradient(to bottom, ${wrongAnswerBgColor} ${100 - totalMarks
                    }%, ${correctAnswerBgColor} ${100 - totalMarks - 1}%)`
            }}
            tokens={ThemedMediumStackTokens}>
            <Grid height={"calc(100% - 95px)"} container justifyContent={"center"} alignItems={"center"}>
                <img style={{ objectFit: "contain", width: "100%" }} src={progressImage} />
            </Grid>

            {!hideScore && (
                <Button style={{ backgroundColor: progressColor }} className={"quiz-close-button"}>
                    <Stack>
                        <span className={"correct-percentage"}>
                            {totalMarks}
                            {showProgressInPercentage && "%"}
                        </span>
                        <span>{progressTitle}</span>
                    </Stack>
                </Button>
            )}
        </Stack>
    );
};

export default QuizCoverImage;

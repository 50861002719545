import Styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const StyledBackground = Styled(Grid)`
    min-height: 100vh;
    background: rgb(0,35,141);
    background: linear-gradient(150deg, rgba(0,35,141,1) 0%, rgba(0,22,95,1) 87%, rgba(0,9,66,1) 100%);
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
    background-size: cover;
`;

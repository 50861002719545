import { useState, useEffect } from "react";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { getPermissions } from "../../../../utils/PermissionUtils";
import { useAdminContext } from "../../context/AdminAuthContext";
import { RoleType, RoleDeleteType, InfoType } from "./Type";
import { confirmDeleteRole, getRoleList } from "./Utils";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { StyledActionColumn, StyledHeader, StyledColumn } from "../../../CommonComponent/v2/datagrid/DataGridStyle";

export const useRolesList = () => {
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "referenceList");
    const [roleList, setRoleList] = useState<RoleType[]>([]);
    const [selectedRoleDeleteId, setSelectedRoleDeleteId] = useState<RoleDeleteType | null>(null);
    const [selectedRoleRightsId, setSelectedRoleRightsId] = useState<RoleDeleteType | null>(null);

    useEffect(() => {
        fetchRoleList();
    }, []);

    const fetchRoleList = (): void => {
        setIsLoading(true);
        if (!(permission && permission.includes("fetch"))) {
            setRoleList([]);
            setIsLoading(false);
            return;
        }
        getRoleList(true)
            .then((res: any) => {
                setRoleList(res);
            })
            .catch(() => {
                setRoleList([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onRoleDelete = () => {
        selectedRoleDeleteId &&
            confirmDeleteRole(selectedRoleDeleteId.id)
                .then(() => {
                    setSelectedRoleDeleteId(null);
                    toast.success("Successfully deleted", SiteConstants.successToastConfiguration);
                    fetchRoleList();
                })
                .catch((error) => {
                    setSelectedRoleDeleteId(null);
                    toast.error(error, SiteConstants.deleteToastConfiguration);
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    const onModalClose = () => {
        setSelectedRoleDeleteId(null);
    };

    return {
        searchText,
        roleList,
        isLoading,
        selectedRoleDeleteId,
        selectedRoleRightsId,
        setSearchText,
        onModalClose,
        onRoleDelete,
        setSelectedRoleDeleteId,
        setSelectedRoleRightsId
    };
};

export const useRoleListHook = () => {
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "roleList");
    const roleListColumns = (
        handleRightsClick: (info: InfoType) => void,
        handleDeleteClick: (info: RoleDeleteType) => void,
        handleEditClick: (id: number) => void
    ): GridColDef[] => [
        {
            field: "sno",
            headerName: "S.No",
            width: 120,
            renderHeader: () => <StyledHeader label="S.No" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.sno} />
        },
        {
            field: "role",
            width: 120,
            flex: 1,
            renderHeader: () => <StyledHeader label="Role" />,
            renderCell: (params: GridCellParams) => (
                <div>
                    <StyledColumn label={params.row.name} />
                </div>
            )
        },
        {
            field: "Action",
            headerName: "Action",
            width: 150,
            renderHeader: () => <StyledHeader label="Action" />,

            renderCell: (params: GridCellParams) => (
                <StyledActionColumn
                    isDeleteAvailaible={{
                        isAvailable: !!(permission && permission.includes("delete")),
                        onClick: () =>
                            handleDeleteClick({
                                id: params.row.id,
                                name: params.row.name
                            })
                    }}
                    isEditAvailaible={{
                        isAvailable: !!(permission && permission.includes("update")),
                        onClick: () => {
                            handleEditClick(params.row.id);
                        }
                    }}
                    isChangePermissionAvailaible={{
                        isAvailable: !!(permission && permission.includes("change-permission")),
                        onClick: () => {
                            handleRightsClick({
                                id: params.row.id,
                                name: params.row.name
                            });
                        }
                    }}
                />
            )
        }
    ];

    return {
        roleListColumns
    };
};

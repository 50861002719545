import Style from "@emotion/styled";
import { whiteColor, wildgoatWelcomeLabelColor } from "../../../../constants/Styles";

export const WelcomeText = Style.span`
    font-size: 16px;
    color: ${wildgoatWelcomeLabelColor};
    font-weight: bold;
    margin-top: 20px;
`;

export const MainImageContainer = Style.div`
position: relative;
display: flex;
justify-content: center;
`;

export const ImageTextContainer = Style.div`
position: absolute;
top: 45%;
left: 45%;
color:${whiteColor};
`;

import { usePaymentList } from "./hook";
import { Grid } from "@mui/material";
import LoadingWrapper from "../../../CommonComponent/v2/LoadingWrapper";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { paymentListColumns } from "./ListColumn";
import SearchHeader from "../../../CommonComponent/v2/searchheader/SearchHeader";
import { filterPaymentBySearchText } from "./util";

const PaymentList = () => {
    const { loading, paymentList, searchText, setSearchText, downloadReceipt } = usePaymentList();
    return (
        <Grid container p={"20px"}>
            <Grid item xs={12}>
                <SearchHeader searchText={searchText} setSearchText={setSearchText} searchPlaceholder={"Search"} />
            </Grid>
            <Grid item xs={12} height={"calc(100vh - 120px)"}>
                <LoadingWrapper
                    isLoading={loading}
                    isNoItem={paymentList.length === 0}
                    emptyTileLabel={`No Payment Done`}>
                    <DataGridWrapper
                        columns={paymentListColumns(downloadReceipt)}
                        rows={filterPaymentBySearchText(paymentList, searchText)}
                        loading={loading}
                    />
                </LoadingWrapper>
            </Grid>
        </Grid>
    );
};

export default PaymentList;

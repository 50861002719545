/* eslint-disable jsx-a11y/alt-text */
import { Box, Grid, IconButton } from "@mui/material";
import { FC } from "react";
import { useHistory } from "react-router";
import { Header1 } from "../../../../CommonComponent/v2/CommonStyle";
import LoadingWrapper from "../../../../CommonComponent/v2/LoadingWrapper";
import ArrowLeft from "../../../../Images/icons/arrowLeft.svg";
import { EditGameType } from "../../gamelibrary/updategame/updategameform/Type";
import { useQuestionDetailHook } from "./Hooks";
import QuestionAddUpdateForm from "./QuestionAddUpdateForm";
import { CloseOutlined } from "@mui/icons-material";

type AddQuestionProps = {
    selectedQuestionId?: EditGameType;
    handleClose?: () => void;
    refreshList?: () => void;
};
const AddQuestion: FC<AddQuestionProps> = ({ selectedQuestionId, handleClose, refreshList }) => {
    const history = useHistory();
    const { gameId, questionDetails, isLoading, questionId } = useQuestionDetailHook(selectedQuestionId?.questionId);
    const searchParams = new URLSearchParams(window.location.search);
    const pageNo = searchParams.get("pageNo") || 0;

    return (
        <Grid px={"20px"}>
            <Grid container>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Header1>
                            {!handleClose && (
                                <img
                                    onClick={() => history.push(`/admin/v2/questionlibrary?pageNo=${pageNo}`)}
                                    src={ArrowLeft}
                                    style={{ height: 18, marginRight: 10, cursor: "pointer" }}
                                />
                            )}

                            {questionId || selectedQuestionId ? "Edit challenge" : "Add new challenge"}
                        </Header1>
                        {handleClose && (
                            <IconButton onClick={handleClose}>
                                <CloseOutlined />
                            </IconButton>
                        )}
                    </Box>
                </Grid>
                <Grid container minHeight={"100vh"}>
                    <LoadingWrapper isLoading={isLoading}>
                        <QuestionAddUpdateForm
                            questionId={selectedQuestionId?.questionId || questionId}
                            gameId={gameId || selectedQuestionId?.gameId || ""}
                            questionDetails={questionDetails}
                            refreshList={refreshList}
                            pageNo={0}
                            handleClose={handleClose}
                        />
                    </LoadingWrapper>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddQuestion;

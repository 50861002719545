import { DatePicker } from "office-ui-fabric-react";
import React from "react";
import { JambarDateUtil } from "../../constants/JambarDateUtils";

type DatePickerWrapperProps = {
    label?: string;
    datePickerValue?: Date;
    disabled?: boolean;
    onSelectDate?: (date: Date) => void;
    placeholder?: string;
    className?: string;
};

const DatePickerWrapper: React.FC<DatePickerWrapperProps> = ({
    datePickerValue,
    disabled,
    onSelectDate,
    label,
    placeholder,
    className
}) => {
    const onDateSelected = (date: Date | null | undefined): void => {
        if (!date) {
            onSelectDate && onSelectDate(new Date());
            return;
        }
        onSelectDate && onSelectDate(date);
    };

    return (
        <DatePicker
            label={label}
            value={datePickerValue}
            disabled={disabled}
            formatDate={() => JambarDateUtil.dateFormat(new Date(datePickerValue || ""), "DD/MM/YYYY")}
            onSelectDate={(date) => onDateSelected(date)}
            placeholder={placeholder}
            className={className}
        />
    );
};

export default DatePickerWrapper;

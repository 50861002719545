import { ApiConstants } from "../../../../constants/ApiConstant";
import { Client } from "../../../Base/Client";
import { RoleType } from "./Type";

const transaformData = (data: any): RoleType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any, index: number) => ({
        sno: index + 1,
        id: item.id,
        name: item.name
    }));
};

export const getRoleList = (forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getRolesListApiUrl(), forceRefresh)
        .then((res: any) => Promise.resolve(transaformData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const addRole = (name: string) =>
    Client.getInstance()
        .createData(ApiConstants.addRolesApiUrl(), {
            name: name
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const updateRole = (name: string, id: number) =>
    Client.getInstance()
        .updateData(ApiConstants.updateRoleApiUrl(id), {
            name: name
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const confirmDeleteRole = (roleId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.updateRoleApiUrl(roleId))
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const filterDataBySearchText = (searchText: string, rows: RoleType[]): RoleType[] =>
    rows.filter(
        (row: RoleType) =>
            (searchText !== "" && row.name.toLowerCase().includes(searchText.toLowerCase())) ||
            row.sno.toString().toLowerCase().includes(searchText.toLowerCase())
    );

import { ApiConstants } from "../../../../../../constants/ApiConstant";
import { Client } from "../../../../../Base/Client";
import { ReferenceItemList } from "./Types";

const transformData = (data: any): ReferenceItemList[] => {
    if (!data) return [];
    return data.reference_list_items.map((event: any) => ({
        id: event.reference_item.id,
        title: event.reference_item.name,
        topDescription: event.reference_item.description,
        bottomDescription: event.reference_item.description2,
        descriptionImage: event.reference_item.descriptionImageFileLocation
    }));
};

export const filterDataBySearchText = (searchText: string, rows: ReferenceItemList[]) => {
    if (searchText === "") {
        return rows;
    }
    return rows.filter((row: ReferenceItemList) => row.title.toLowerCase().includes(searchText.toLowerCase()));
};
export const getReferenceItemsData = (id: number, forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getReferenceDetailsApiUrl(id), forceRefresh)
        .then((response: any) => Promise.resolve(transformData(response.data.data)))
        .catch((error: any) => Promise.reject(error));

export const onConfirmDelete = (id: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteReferenceItemApiUrl(id))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

import { ApiConstants } from "../../../../../constants/ApiConstant";
import { Client } from "../../../../Base/Client";
import { EventPathDetailsType, QuizConfigPathDetailType, QuizPathDetailsType } from "../type";

const transformQuizConfigType = (apiRes: any): QuizConfigPathDetailType => {
    return {
        id: apiRes.id,
        quizId: apiRes.quizId,
        progressTitle: apiRes.progressTitle,
        attachmentTitle: apiRes.attachmentTitle,
        baseColor: apiRes.baseColor,
        baseButtonColor: apiRes.baseButtonColor,
        wrongAnswerBackgroundColor: apiRes.wrongAnswerBackgroundColor,
        correctAnswerBackgroundColor: apiRes.correctAnswerBackgroundColor,
        browserTabTitle: apiRes.browserTabTitle,
        wildgoatPrimaryColor: apiRes.wildgoatPrimaryColor,
        wildgoatSecondaryColor: apiRes.wildgoatSecondaryColor,
        wildgoatTertiaryColor: apiRes.wildgoatTertiaryColor,
        backgroundImageFileName: apiRes.backgroundImageFileName,
        backgroundImageFilePath: apiRes.backgroundImageFilePath,
        backgroundImageFileLocation: apiRes.backgroundImageFileLocation,
        progressImageFileName: apiRes.progressImageFileName,
        progressImageFilePath: apiRes.progressImageFilePath,
        progressImageFileLocation: apiRes.progressImageFileLocation,
        totalScoreVisibility: apiRes.totalScoreVisibility,
        correctAnswerHeading: apiRes.correctAnswerHeading,
        wrongAnswerHeading: apiRes.wrongAnswerHeading,
        timeoutAnswerHeading: apiRes.timeoutAnswerHeading,
        correctAnswerImageLocation: apiRes.correctAnswerImageLocation,
        wrongAnswerImageLocation: apiRes.wrongAnswerImageLocation,
        timeoutAnswerImageLocation: apiRes.timeoutAnswerImageLocation,
        createdBy: apiRes.createdBy,
        updatedBy: apiRes.updatedBy,
        createdAt: apiRes.createdAt,
        updatedAt: apiRes.updatedAt
    };
};
const transformQuizdata = (apiRes: any): QuizPathDetailsType => {
    return {
        id: apiRes.id,
        type: apiRes.type,
        title: apiRes.title,
        displayTitle: apiRes.displayTitle,
        description: apiRes.description,
        summaryPageText: apiRes.summaryPageText,
        showIndividualScore: apiRes.showIndividualScore,
        allowSkipping: apiRes.allowSkipping,
        wrongPoints: apiRes.wrongPoints,
        skipPoints: apiRes.skipPoints,
        correctPoints: apiRes.correctPoints,
        timeLimit: apiRes.timeLimit,
        nextButtonText: apiRes.nextButtonText,
        initialScore: apiRes.initialScore,
        showProgressInPercentage: apiRes.showProgressInPercentage,
        answerResultText: apiRes.answerResultText,
        totalPointsText: apiRes.totalPointsText,
        answerResultImageFilePath: apiRes.answerResultImageFilePath,
        answerResultImageFileLocation: apiRes.answerResultImageFileLocation,
        answerResultImageFileName: apiRes.answerResultImageFileName,
        showMainTimer: apiRes.showMainTimer,
        showHintCount: apiRes.showHintCount,
        showCorrectAnswerCount: apiRes.showCorrectAnswerCount,
        showWrongAnswerCount: apiRes.showWrongAnswerCount,
        isConnectorQuiz: apiRes.isConnectorQuiz,
        accessibilityScope: apiRes.accessibilityScope,
        createdBy: apiRes.createdBy,
        updatedBy: apiRes.updatedBy,
        isDeleted: apiRes.isDeleted,
        createdAt: apiRes.createdAt,
        updatedAt: apiRes.updatedAt,
        quiz_attachments: apiRes.quiz_attachments,
        quizConfig: transformQuizConfigType(apiRes.quizConfig),
        quizMultiBranch: apiRes.quizMultiBranch,
        isMultiBranchQuiz: apiRes.isMultiBranchQuiz
    };
};

const transformEventData = (apiRes: any): EventPathDetailsType => {
    return {
        companyLogo: apiRes.companyLogo,
        id: apiRes.id,
        isDemo: apiRes.isDemo,
        logo: apiRes.logo,
        name: apiRes.name,
        quizType: apiRes.quizType,
        showSurveyAtEnd: apiRes.showSurveyAtEnd,
        type: apiRes.type
    };
};
export const fetchEventDetail = (eventLink: string) => {
    return Client.getInstance()
        .getData(ApiConstants.getEventDataApiUrl(eventLink))
        .then((res: any) => {
            return Promise.resolve({
                event: transformEventData(res.data.data.event),
                quiz: transformQuizdata(res.data.data.quiz)
            });
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const validateEventCode = (event: string, code: string) => {
    return Client.getInstance()
        .getData(ApiConstants.validateParticipantCode(event, code))
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

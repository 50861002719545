export const FREE_TEXT = "free_text";
export const MCQ = "mcq";
export const FREE_TEXT_CONTAINS = "contains";
export const FREE_TEXT_DOES_NOT_CONTAINS = "does_not_contain";
export const FREE_TEXT_EXACT_MATCH = "exact_match";
export const BASIC_TEXT_EDITOR_CLASS = "basic-text-editor";
export const ADVANCE_TEXT_EDITOR_CLASS = "advance-text-editor";
export const FREE_TEXT_CONNECTORS = "connector";
export const FILE_UPLOAD = "file_upload";
export const CORRECT_ANSWER_COUNT = "CORRECT_ANSWER_COUNT";
export const WRONG_ANSWER_COUNT = "WRONG_ANSWER_COUNT";
export const SKIP_QUESTION = "SKIP_QUESTION";
export const NON_SCORING_QUESTION = "NON_SCORING_QUESTION";
export const QUESTION_TIMER_TYPE = "questionTimer";
export const QUIZ_TIMER_TYPE = "quizTimer";
export const CONNECTOR = "connector";
export const ATLEAST_ONE_CORRECT = "atleast_one_correct";
export const ALL_CORRECT = "all_correct";
export const ATLEAST_TWO_CORRECT = "atleast_2_correct";
export const ATLEAST_THREE_CORRECT = "atleast_3_correct";
export const ATLEAST_FOUR_CORRECT = "atleast_4_correct";
export const ATLEAST_FIVE_CORRECT = "atleast_5_correct";
export const CONNECTOR_SKIP = "connector_skip";
export const HINT = "hint";
export const QUESTION_TIMEOUT = "questionTimeOut";
export const EVENT_TIMEOUT = "eventTimeOut";
export const TIME_PENALTY = "timePenalty";
export const SCORE_PENALTY = "scorePenalty";
export const UPLOAD_VIDEO = "uploadVideo";
export const YOUTUBE_LINK = "youtubeLink";

export type MCQAnswerOption = {
    id: number;
    text: string;
};

export type timePenalty = {
    id: number;
    value: string;
};

export type ConnectorOptions = {
    id: string;
    optionText: string;
    quizId: string;
    isClosable: boolean;
};

export type questionInfo = {
    itemId: number;
    index: number; //TODO - change to order
    imagePath: string;
    questionType: string;
    questionText: string;
    correctAnswer: string;
    mcqAnswerOptions?: MCQAnswerOption[];
    freeTextCorrectAnswerType?: string;
    tags: QuestionTags[];
    timeLimit: string;
    wrongPoints: number;
    wrongAnswerText?: string;
    skipPoints: number;
    maxAttempts: number;
    correctPoints: number;
    correctAnswerText?: string;
    specifyAnswerText: boolean;
    skippingAllowed: boolean;
    correctOptions: number[];
    questionTitle: string;
    isVideoQuestion?: boolean;
};

export type Question = {
    itemId: number;
    index: number;
    accessibilityScope: string;
    title: string;
    tags: QuestionTags[];
    questionInfo: questionInfo;
    createdBy: string;
    createdDate: string;
    lastUpdatedBy: string;
    lastUpdatedDate: string;
};

export type usageListType = {
    id: number;
    name: string;
};

export type QuestionTags = {
    id: number;
    value: string;
};

export type time = {
    hr: number;
    min: number;
    sec: number;
};

export type tagsType = {
    id: number;
    title: string;
};

export type bulkQuestionUsageListType = {
    questionId: number;
    name: string;
    quiz: usageListType[];
};

import { useEffect, useState } from "react";
import { useAdminContext } from "../Component/Admin/context/AdminAuthContext";
import { getPermissions } from "../utils/PermissionUtils";
import { SiteConstants } from "../constants/SiteConstant";

export const useDebounce = (value: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
};

export const useUserInfo = () => {
    const { userDetail } = useAdminContext();
    const permissionQuizFullGame: string[] | null = getPermissions(userDetail, "quiz_fullgame");
    const permissionQuizSocketGame: string[] | null = getPermissions(userDetail, "quiz_socket");
    const permissionQuizPhysicalGame: string[] | null = getPermissions(userDetail, "quiz_wildgoat");
    const permissionTag: string[] | null = getPermissions(userDetail, "tag");

    const addPermissionCount =
        (permissionQuizFullGame?.includes("add") ? 1 : 0) +
        (permissionQuizSocketGame?.includes("add") ? 1 : 0) +
        (permissionQuizPhysicalGame?.includes("add") ? 1 : 0);

    const getGameOption = () => {
        if (permissionQuizFullGame?.includes("add")) {
            return SiteConstants.spaceGameQuiz;
        }
        if (permissionQuizPhysicalGame?.includes("add")) {
            return SiteConstants.wildgoat;
        }
        if (permissionQuizSocketGame?.includes("add")) {
            return SiteConstants.socketQuiz;
        }
        return "";
    };
    return {
        isSuperAdmin: userDetail.identity.name === "jambar",
        onlyOneGameAllowed: addPermissionCount <= 1,
        userDetail,
        permissionQuizFullGame,
        permissionQuizSocketGame,
        permissionQuizPhysicalGame,
        getGameOption,
        permissionTag
    };
};

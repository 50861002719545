/* eslint-disable react-hooks/exhaustive-deps */
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { getPermissions } from "../../../../utils/PermissionUtils";
import { StyledActionColumn, StyledColumn, StyledHeader } from "../../../CommonComponent/v2/datagrid/DataGridStyle";
import { USER_JAMBAR } from "../../../WebsiteGame/constants";
import { useAdminContext } from "../../context/AdminAuthContext";
import { IdentityListtype, SelectIdentityType, SelectUserDeleteType, UserListType } from "./Type";
import { superAdmin } from "../../../../constants/SiteConstant";
import { getIdentifySubscriptionStatusColor, updateIdentityCredit } from "./Util";
import { CiCreditCard1 } from "react-icons/ci";
import { toast } from "react-toastify";
import { useUserInfo } from "../../../../commonhooks/Hooks";
import { Box, Tooltip } from "@mui/material";
import { getInitials } from "../../../../utils/JambarUtils";

export const useUserHook = () => {
    const [loading, setLoading] = useState(false);
    const [allUsers, setAllUsers] = useState<UserListType[]>([]);
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "user");
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [selectDeleteId, setSelectDeleteId] = useState<SelectUserDeleteType | null>(null);
    const { isSuperAdmin } = useUserInfo();
    const UserListColumns = (
        handleDelete: (userInfo: SelectUserDeleteType) => void,
        handleEdit: (id: string) => void
    ): GridColDef[] =>
        useMemo(
            () => [
                {
                    field: "email",
                    headerName: "Email",
                    flex: 1,
                    renderHeader: () => <StyledHeader label="Email" />,
                    renderCell: (params: GridCellParams) => <StyledColumn label={params.row.email} />
                },
                {
                    field: "firstName",
                    headerName: "Name",
                    flex: 1,
                    renderHeader: () => <StyledHeader label="Name" />,
                    renderCell: (params: GridCellParams) => (
                        <StyledColumn label={`${params.row.firstName} ${params.row.lastName}`} />
                    )
                },
                {
                    field: "identityName",
                    headerName: "Identity",
                    flex: 1,
                    renderHeader: () => <StyledHeader label="Identity" />,
                    renderCell: (params: GridCellParams) => <StyledColumn label={params.row.identityName} />
                },
                {
                    field: "identityCredit",
                    headerName: "Credit",
                    flex: 1,
                    renderHeader: () => <StyledHeader label="Credit" />,
                    renderCell: (params: GridCellParams) => <StyledColumn label={params.row.identityCredit} />
                },
                {
                    field: "subscriptionStatus",
                    headerName: "Status",
                    flex: 1,
                    renderHeader: () => <StyledHeader label="Status" />,
                    renderCell: (params: GridCellParams) => (
                        <StyledColumn
                            color={getIdentifySubscriptionStatusColor(params.row.subscriptionStatus)}
                            label={params.row.subscriptionStatus}
                        />
                    )
                },
                {
                    field: "Action",
                    headerName: "Action",
                    renderHeader: () => <StyledHeader label="Action" />,
                    renderCell: (params: GridCellParams) => (
                        <StyledActionColumn
                            isDeleteAvailaible={{
                                isAvailable: !!(permission && permission.includes("delete")),
                                onClick: () =>
                                    handleDelete({
                                        id: params.row.id,
                                        identityName: params.row.identityName,
                                        email: params.row.email
                                    })
                            }}
                            isEditAvailaible={{
                                isAvailable: !!(permission && permission.includes("update")),
                                onClick: () => handleEdit(params.row.id)
                            }}
                        />
                    )
                }
            ],
            []
        );

    return {
        selectedUserId,
        setSelectedUserId,
        selectDeleteId,
        setSelectDeleteId,
        setLoading,
        loading,
        allUsers,
        setAllUsers,
        permission,
        UserListColumns,
        isSuperAdmin
    };
};

export const useIdentityHook = () => {
    const [loading, setLoading] = useState(false);
    const [allIdentity, setAllIdentity] = useState<IdentityListtype[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<SelectIdentityType | null>(null);
    const [selectedDeleteUserId, setSelectedDeleteUserId] = useState<number | null>(null);
    const [updateUserForCredit, setUpdateUserForCredit] = useState<IdentityListtype | null>(null);

    const IdentityListColumns = (
        handleDelete: (id: string) => void,
        handleEdit: (userDetail: SelectIdentityType) => void
    ): GridColDef[] => [
        {
            field: "name",
            headerName: "name",
            flex: 1,
            renderHeader: () => <StyledHeader label="Name" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.name} />
        },
        {
            field: "createdBy",
            headerName: "Created By",
            flex: 1,
            renderHeader: () => <StyledHeader label="Created" />,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.createdBy}>
                    <Box style={{ display: "flex", gap: 3 }}>
                        <StyledColumn width={"30px"} label={`${getInitials(params?.row?.createdBy || "")}`} />
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "updatedAt",
            headerName: "Updated",
            flex: 1,
            renderHeader: () => <StyledHeader label="Updated" />,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.updatedBy}>
                    <Box style={{ display: "flex", gap: 3 }}>
                        <StyledColumn width={"30px"} label={`${getInitials(params?.row?.updatedBy || "")}`} />
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "credits",
            headerName: "Credits",
            flex: 1,
            renderHeader: () => <StyledHeader label="Credits" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.credits} />
        },
        {
            field: "subscriptionStatus",
            headerName: "Status",
            flex: 1,
            renderHeader: () => <StyledHeader label="Status" />,
            renderCell: (params: GridCellParams) => (
                <StyledColumn
                    color={getIdentifySubscriptionStatusColor(params.row.subscriptionStatus)}
                    label={params.row.subscriptionStatus}
                />
            )
        },
        {
            field: "Action",
            headerName: "Action",
            renderHeader: () => <StyledHeader label="Action" />,
            renderCell: (params: GridCellParams) => (
                <StyledActionColumn
                    isDeleteAvailaible={{
                        isAvailable: true,
                        onClick: () => handleDelete(params.row.id)
                    }}
                    isEditAvailaible={{
                        isAvailable: true,
                        onClick: () => handleEdit({ id: parseInt(params.row.id), name: params.row.name })
                    }}
                    additionalIcons={
                        <CiCreditCard1
                            style={{ cursor: "pointer" }}
                            size={"20px"}
                            onClick={() => setUpdateUserForCredit(params.row)}
                        />
                    }
                />
            )
        }
    ];

    return {
        loading,
        allIdentity,
        setSelectedUserId,
        selectedUserId,
        setSelectedDeleteUserId,
        selectedDeleteUserId,
        IdentityListColumns,
        setLoading,
        setAllIdentity,
        updateUserForCredit,
        setUpdateUserForCredit
    };
};

export const useUserIdentityHook = () => {
    const [selectedTab, setSelectedTab] = useState("user");
    const [selectedEntriesPage, setSelectedEntriesPage] = useState("10");
    const [searchText, setSearchText] = useState("");
    const [selectedId, setSelectedId] = useState<number | SelectIdentityType | null>(null);
    const [selectDeleteId, setSelectDeleteId] = useState<SelectUserDeleteType | number | null>(null);
    const [refreshKey, setRefreshKey] = useState(Math.random().toString());
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "user");

    const IS_SUPER_ADMIN = userDetail?.currentRoles.find((item) => item.name === superAdmin);

    const userTabs = useMemo(
        () => [
            {
                label: IS_SUPER_ADMIN ? "Clients" : "Users",
                value: "user",
                toShow: true
            },
            {
                label: "Identities",
                value: "identities",
                toShow: !!(userDetail && userDetail.identity.name === USER_JAMBAR)
            }
        ],
        []
    );
    return {
        selectedTab,
        setSelectedTab,
        userTabs,
        selectedEntriesPage,
        setSelectedEntriesPage,
        searchText,
        setSearchText,
        selectedId,
        setSelectedId,
        setSelectDeleteId,
        selectDeleteId,
        setRefreshKey,
        refreshKey,
        permission,
        IS_SUPER_ADMIN
    };
};

export const useUpdateCredit = (identity: IdentityListtype, refreshData: () => void) => {
    const [loading, setLoading] = useState(false);

    const onUpdateCredit = (value: { credit: number }) => {
        setLoading(true);
        updateIdentityCredit(identity, value.credit)
            .then(() => {
                refreshData();
                toast.success("Credit Updated Successfully");
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "Something went wrong");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        onUpdateCredit,
        loading
    };
};

import { Grid } from "@mui/material";
import { optionArray } from "../../../../constants/SiteConstant";
import ConfirmationBox from "../../../CommonComponent/v2/ConfirmationBox";
import SearchHeader from "../../../CommonComponent/v2/searchheader/SearchHeader";
import TabWrapper from "../../../CommonComponent/v2/tab/Tab";
import { gameOptions } from "../eventmanager/addeventdetail/Util";
import GameTypeModal from "../questionlibrary/addquestion/GameTypeModal";
import GameDeleteDialog from "./GameDeleteDialog";
import GameList from "./GameList";
import { useGameLibrary } from "./Hooks";
import { filterDataBySearchText, QuizTabOption } from "./Util";
import { HeaderTextUi } from "../questionlibrary/HeaderTextUI";
import BatchQuestionUpdateSection from "../questionlibrary/updatequestion/BatchQuestionUpdateSection";

const GameLibrary = () => {
    const {
        searchText,
        setSearchText,
        setSelectedTabOption,
        tabOption,
        selectedStatusOption,
        setSelectedStatusOption,
        setDeleteId,
        setCloneId,
        isLoading,
        quizList,
        deleteId,
        cloneId,
        handleGameClone,
        handleGameDelete,
        usageList,
        openAddModal,
        toggleAddModal,
        permissionQuizFullGame,
        permissionQuizPhysicalGame,
        permissionQuizSocketGame,
        showBatchUpdate,
        setShowBatchUpdate,
        isSuperAdmin,
        selectedRows,
        confirmGameSet,
        setSelectedRows,
        identityDropdownOptions
    } = useGameLibrary();

    return (
        <Grid container padding={"20px"}>
            <Grid item xs={12}>
                <SearchHeader
                    searchText={searchText}
                    setSearchText={setSearchText}
                    showAddButton
                    onButtonClick={toggleAddModal}
                    buttonLabel={"Add new game"}
                    resetFilter={[
                        isSuperAdmin && (
                            <Grid maxWidth={"200px"}>
                                <HeaderTextUi
                                    label={showBatchUpdate ? "Hide Batch Changes" : "Show Batch Changes"}
                                    setValue={() => {
                                        setShowBatchUpdate((prev) => !prev);
                                    }}
                                />{" "}
                            </Grid>
                        )
                    ]}
                    filterOptions={[
                        {
                            value: selectedStatusOption,
                            options: optionArray,
                            setValue: setSelectedStatusOption,
                            label: "Game Status"
                        }
                    ]}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container columnSpacing={"20px"} mt={"20px"}>
                    <Grid item flex={1}>
                        <TabWrapper
                            tabsOptions={QuizTabOption({
                                permissions: {
                                    permissionQuizFullGame,
                                    permissionQuizPhysicalGame,
                                    permissionQuizSocketGame
                                }
                            })}
                            selectedTab={tabOption}
                            handleChange={setSelectedTabOption}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={"30px"} item xs={12}>
                <GameList
                    setCloneId={setCloneId}
                    setDeleteId={setDeleteId}
                    tabOption={tabOption}
                    searchText={searchText}
                    quizList={filterDataBySearchText(searchText, quizList)}
                    isLoading={isLoading.listLoading}
                    setSelectedRows={setSelectedRows}
                    hideCheckbox={!showBatchUpdate}
                    selectedRows={selectedRows}
                />
            </Grid>
            {deleteId && (
                <GameDeleteDialog
                    usageList={usageList}
                    title={`Delete "${deleteId.title}" game`}
                    message={
                        usageList.length === 0
                            ? [
                                  `
                        Are you sure you want to delete ${deleteId.title} event
                        `
                              ]
                            : [
                                  `
                        This ${deleteId.title} is being used in following events 
                        ${
                            usageList.length === 0
                                ? `do you want to continue to delete the ${deleteId.title}`
                                : `please update those event before deleting the ${deleteId.title}`
                        }
                    `
                              ]
                    }
                    onConfirm={handleGameDelete}
                    onClose={() => setDeleteId(null)}
                    isLoading={isLoading.usageListLoading}
                />
            )}

            {cloneId && (
                <ConfirmationBox
                    title={`Duplicate "${cloneId.title}" game?`}
                    message={"Please confirm if you really want to duplicate this game."}
                    onConfirm={handleGameClone}
                    onClose={() => setCloneId(null)}
                    type={"clone"}
                />
            )}
            {openAddModal && (
                <GameTypeModal
                    heading="Type of game to create"
                    subHeading="Please select the game type that you want to create."
                    handleClose={toggleAddModal}
                    type={"gamelibrary"}
                    gameOptions={gameOptions(
                        permissionQuizFullGame?.includes("add"),
                        permissionQuizSocketGame?.includes("add"),
                        permissionQuizPhysicalGame?.includes("add")
                    )}
                />
            )}
            {showBatchUpdate && (
                <Grid item xs={12}>
                    <BatchQuestionUpdateSection
                        tagOptions={[]}
                        identityDropdownOptions={identityDropdownOptions}
                        hideTags
                        confirmGameSet={confirmGameSet}
                        applyDisable={selectedRows.length === 0}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default GameLibrary;

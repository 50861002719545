import { Grid } from "@mui/material";
import { FC } from "react";
import { CancelButton, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import DataGridWrapper from "../../../../CommonComponent/v2/datagrid/DataGrid";
import { SelectUserDeleteType, transferOwnerType } from "../Type";
import { getUserListFilteredItems } from "../Util";
import { useTransferModalHooks } from "./Hooks";

type TransferOwnerDataGridProps = {
    searchText: string;
    selectedUserInfo: SelectUserDeleteType;
    closeModal: () => void;
    saveTransferOwner: (transferId: number) => void;
};
const TransferOwnerDataGrid: FC<TransferOwnerDataGridProps> = ({
    searchText,
    selectedUserInfo,
    closeModal,
    saveTransferOwner
}) => {
    const { userList, UserTransferOwnerListColumns, setUserList, transferId, setTransferId } = useTransferModalHooks(
        selectedUserInfo
    );

    const handleOwnerChange = (value: transferOwnerType) => {
        const newUserList = userList.map((item) => (item.id === value.id ? value : { ...item, isSelected: false }));
        const findUserIsSelected = newUserList.find((item) => item.isSelected);
        if (findUserIsSelected) {
            value.isSelected && setTransferId(parseInt(findUserIsSelected.id));
        } else {
            setTransferId(-1);
        }
        setUserList(userList.map((item) => (item.id === value.id ? value : { ...item, isSelected: false })));
    };

    return (
        <Grid mt={"50px"}>
            <Grid height={"400px"} overflow={"auto"}>
                <DataGridWrapper
                    columns={UserTransferOwnerListColumns(handleOwnerChange)}
                    rows={getUserListFilteredItems(userList, searchText)}
                />
            </Grid>
            <Grid container mt={"10px"} alignItems={"center"} spacing={"30px"} justifyContent={"center"}>
                <Grid item>
                    <CancelButton onClick={closeModal} variant={"text"}>
                        Cancel transfer
                    </CancelButton>
                </Grid>
                <Grid item>
                    <PrimaryButton
                        disabled={transferId === -1}
                        onClick={() => saveTransferOwner(transferId)}
                        variant={"contained"}>
                        Confirm transfer
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TransferOwnerDataGrid;

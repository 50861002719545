import { Grid } from "@mui/material";
import { CaptionText } from "../../../../../CommonComponent/v2/CommonStyle";

const UploadQuestion = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <CaptionText>
                    The game players will be required to upload certain files to complete the question requirements in
                    the game,
                </CaptionText>
            </Grid>
        </Grid>
    );
};

export default UploadQuestion;

import { Grid } from "@mui/material";
import { FC } from "react";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { useTagList } from "./Hooks";
import { TagListItem } from "./Type";

type TagListProps = {
    tagList: TagListItem[];
    loading: boolean;
    setSelectedEditTag: (tagInfo: TagListItem) => void;
    setSelectedDeleteTag: (tagInfo: TagListItem) => void;
};
const TagList: FC<TagListProps> = ({ tagList, loading, setSelectedEditTag, setSelectedDeleteTag }) => {
    const { tagListColumns } = useTagList();
    return (
        <Grid height={"calc(100vh - 303px)"}>
            <DataGridWrapper
                columns={tagListColumns(setSelectedDeleteTag, setSelectedEditTag)}
                rows={tagList as any}
                loading={loading}
            />
        </Grid>
    );
};

export default TagList;

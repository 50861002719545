import { Grid } from "@mui/material";
import { useItemsHooks } from "./Hooks";
import { FC } from "react";
import { ReferenceItemList, ItemsDeleteType } from "./Types";
import { useHistory, useParams } from "react-router";
import DataGridWrapper from "../../../../../CommonComponent/v2/datagrid/DataGrid";

type ItemListProps = {
    referenceItems: ReferenceItemList[];
    isLoading: boolean;
    setItemDeleteId: (id: ItemsDeleteType) => void;
};

const ReferenceItem: FC<ItemListProps> = ({ referenceItems, isLoading, setItemDeleteId }) => {
    const { itemColumns } = useItemsHooks();

    const history = useHistory();
    const { id } = useParams<{
        id: string;
    }>();

    return (
        <Grid height={"calc(100vh - 230px)"}>
            <DataGridWrapper
                columns={itemColumns(setItemDeleteId, (refItemId) => {
                    history.push(`/admin/v2/referencelistmanager/${id}/refitem/${refItemId}/updateitem`);
                })}
                rows={referenceItems as any}
                loading={isLoading}
            />
        </Grid>
    );
};

export default ReferenceItem;

import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { CaptionText } from "../../../../../CommonComponent/v2/CommonStyle";
import {
    StyledActionColumn,
    StyledColumn,
    StyledHeader
} from "../../../../../CommonComponent/v2/datagrid/DataGridStyle";
import { DownloadFileType } from "./Type";
import { DownloadStatusInfoOptions, getAllDownloadEventList, getDownloadEventList } from "./Util";

export const useDownloadFile = (eventId?: string) => {
    const [downloadFileList, setDownloadFileList] = useState<DownloadFileType[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchFile();
    }, []);

    const fetchFile = () => {
        setIsLoading(true);
        if (eventId) {
            getDownloadEventList(eventId)
                .then((res) => {
                    setDownloadFileList(res);
                })
                .catch(() => {
                    setDownloadFileList([]);
                })
                .finally(() => {
                    setIsLoading(false);
                });

            return;
        }

        getAllDownloadEventList()
            .then((res) => {
                setDownloadFileList(res);
            })
            .catch(() => {
                setDownloadFileList([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const eventFileColumns = (): GridColDef[] =>
        [
                {
                    field: "index",
                    flex: 1,
                    maxWidth: 50,
                    renderHeader: () => <StyledHeader label="#" />,
                    renderCell: (params: GridCellParams) => (
                        <CaptionText fontWeight={500}>{params.row.index}</CaptionText>
                    )
                },
                {
                    field: "status",
                    flex: 1,
                    maxWidth: 200,
                    renderHeader: () => <StyledHeader label="Status" />,
                    renderCell: (params: GridCellParams) => (
                        <CaptionText fontWeight={500} color={DownloadStatusInfoOptions[params.row.status].color}>
                            {DownloadStatusInfoOptions[params.row.status].label}
                        </CaptionText>
                    )
                },
                {
                    field: "uploadPercentage",
                    flex: 1,
                    renderHeader: () => <StyledHeader label="Upload Percentage" />,
                    renderCell: (params: GridCellParams) => (
                        <StyledColumn label={params.row.uploadPercentage ? `${params.row.uploadPercentage}%`: '-'} />
                    )
                },
                {
                    field: "requestedDateTime",
                    flex: 1,
                    renderHeader: () => <StyledHeader label="Requested date" />,
                    renderCell: (params: GridCellParams) => (
                        <StyledColumn label={`${moment(params.row.requestedDateTime).format("DD/MM/YYYY hh:mm a")}`} />
                    )
                },
                {
                    field: "uploadUrl",
                    headerName: "Action",
                    width: 150,
                    sortable: false,
                    renderHeader: () => <StyledHeader label="Action" />,
                    renderCell: (params: GridCellParams) => (
                        <StyledActionColumn
                            isDownloadAvailaible={{
                                isAvailable: params.row.status === "completed",
                                onClick: () => {
                                    window.open(params.row.uploadUrl, "_blank");
                                }
                            }}
                        />
                    )
                }
            ];

    return {
        downloadFileList,
        isLoading,
        eventFileColumns,
        fetchFile
    };
};

import { Grid } from "@mui/material";
import { FC } from "react";
import { CaptionText, IconContainer } from "../../../../../CommonComponent/v2/CommonStyle";
import { EventInfoType } from "../Type";
import EditIcon from "../../../../../Images/icons/edit.svg";
import { useHistory } from "react-router";

type ReferenceGameProps = {
    eventDetails: EventInfoType;
    id: string;
    showEdit?: boolean;
};

const ReferenceGame: FC<ReferenceGameProps> = ({ eventDetails, id, showEdit = true }) => {
    const { referenceList } = eventDetails;
    const history = useHistory();

    return (
        <Grid>
            <CaptionText color={"#333333"} fontWeight={700}>
                Reference games
                {
                    showEdit && <IconContainer
                        onClick={() => history.push(`/admin/event/${id}/update`)}
                        src={EditIcon}
                        style={{ marginLeft: 10 }}
                    />
                }
            </CaptionText>
            <hr style={{ margin: "20px 0px" }} />
            <CaptionText color={"#333333"} fontWeight={700}>
                First Reference game
            </CaptionText>
            <CaptionText mt={"10px"}>{referenceList?.[0]?.name || "No reference game"}</CaptionText>
            <hr style={{ margin: "20px 0px" }} />
            <CaptionText color={"#333333"} fontWeight={700}>
                Second Reference game
            </CaptionText>
            <CaptionText mt={"10px"}>{referenceList?.[1]?.name || "No reference game"}</CaptionText>
        </Grid>
    );
};

export default ReferenceGame;

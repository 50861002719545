import { Box, Grid } from "@mui/material";
import QrcodeGenerator from "../../../../../CommonComponent/QrcodeGenerator";
import { PrimaryButton } from "../../../../../CommonComponent/v2/CommonStyle";
import { QuestionDetailType } from "../Type";
import { useFormikContext } from "formik";

const QRCodeView = () => {
    const { values } = useFormikContext();
    const data = values as QuestionDetailType;

    const downloadImage = () => {
        const img = document.getElementById("qr-code") as HTMLImageElement | null;
        if (img) {
            const a = document.createElement("a");
            a.href = img.src;
            a.download = img.alt || "downloaded_image";
            document.body.appendChild(a);
            a.target = "_blank";
            a.click();
            document.body.removeChild(a);
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <QrcodeGenerator height={300} width={300} link={data.answerDetails.qrCodeValue} />
                <Box width={300} display={"flex"} justifyContent={"center"}>
                    <PrimaryButton onClick={downloadImage}>Download</PrimaryButton>
                </Box>
            </Grid>
        </Grid>
    );
};

export default QRCodeView;

import { Avatar, Divider, Grid } from "@mui/material";
import playerAvatar from "../../../Images/Wildgoat/Avatars/Avatar1.svg";
import { menuListOption } from "./Menu";
import { AiOutlinePoweroff } from "react-icons/ai";
import { AvatarIconContainer, HeaderTitle, MenuContainer, MenuTitle } from "./Style";
import { FC } from "react";
import { getOffsetColor } from "../Util/Util";
import { whiteColor } from "../../../../constants/Styles";
import { useLocation } from "react-router";

type SideBarProps = {
    primaryColor: string;
    userName: string;
    teamName: string;
    menuSelect: (menu: string) => void;
};

const SideBar: FC<SideBarProps> = ({ primaryColor, userName, teamName, menuSelect }) => {
    const info: any = useLocation();

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container alignItems={"center"} gap={"10px"} padding={"20px"}>
                    <Grid item>
                        <Avatar
                            src={playerAvatar}
                            sx={{ width: 75, height: 75, border: "2px white solid", borderRadius: "50%" }}
                        />
                    </Grid>
                    <Grid item>
                        <HeaderTitle>{userName}</HeaderTitle>
                        <HeaderTitle style={{ opacity: 0.6 }} variant={"h6"}>
                            {teamName}
                        </HeaderTitle>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container padding={"40px 20px"}>
                    {menuListOption.map((item) => (
                        <Grid item xs={12} mb={"20px"}>
                            <MenuContainer onClick={() => menuSelect(item.id)} key={item.id}>
                                <AvatarIconContainer bgColor={getOffsetColor(primaryColor)}>
                                    {item.icon}
                                </AvatarIconContainer>
                                <div>
                                    <MenuTitle>{item.label}</MenuTitle>
                                </div>
                            </MenuContainer>
                        </Grid>
                    ))}
                </Grid>
                <Divider />
                <Grid container padding={"40px 20px"}>
                    <MenuContainer>
                        <AvatarIconContainer bgColor={getOffsetColor(primaryColor)}>
                            <AiOutlinePoweroff style={{ fontSize: 20, color: whiteColor }} />
                        </AvatarIconContainer>
                        <div>
                            <MenuTitle onClick={() => (window.location.href = `/event/${info.state.eventLink}`)}>
                                Logout
                            </MenuTitle>
                        </div>
                    </MenuContainer>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SideBar;

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { FileDataType } from "../Type";
import { getEventFiles, sortFilesList } from "./Util";
import { DeleteFileType } from "./Type";

export const useFileDetailHook = (eventId: number) => {
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState<FileDataType[]>([]);
    const [selectedFileToDelete, setSelectedFileToDelete] = useState<DeleteFileType | null>(null);

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = (forceRefresh = false) => {
        setIsLoading(true);
        getEventFiles(eventId, forceRefresh)
            .then((data) => {
                const newFileData = data.map((item: FileDataType) => ({
                    ...item,
                    type: item.fileLocation.split(".").pop() || "",
                    fileName: item.fileLocation.split("/").pop() || ""
                }));
                setFileList(sortFilesList(newFileData));
            })
            .catch((error) => toast.error(error, SiteConstants.deleteToastConfiguration))
            .finally(() => setIsLoading(false));
    };

    return {
        searchText,
        setSearchText,
        fileList,
        isLoading,
        setFileList,
        selectedFileToDelete,
        setSelectedFileToDelete,
        fetchFiles
    };
};

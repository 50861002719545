import { Stack } from "@fluentui/react";
import { useFormik } from "formik";
import React, { FC } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { onlineOptions, ratingOptions } from "../../../../constants/DropdownOption";
import { defaultButtonBackgroundColor, whiteColor } from "../../../../constants/Styles";
import { Client } from "../../../Base/Client";
import SelectRating from "../../../CommonComponent/SelectRating";
import TextFieldWrapper from "../../../CommonComponent/TextFieldWrapper";
import "./FeedbackFormStyle.scss";
import { feedbackInitialValues, feedbackSchema } from "./util";

type SurveyFormProps = {
    eventLink?: string;
    ratingOptionColor: string;
    eventId: string;
    participantCode: string;
    participantName: string;
};

const SurveyForm: FC<SurveyFormProps> = ({ ratingOptionColor, eventId, participantName, participantCode }) => {
    const history = useHistory();

    const onSubmit = () => {
        const surveyData = {
            companyName: formik.values.companyName,
            playedBefore: formik.values.playedBefore,
            enjoyPoints: formik.values.enjoyPoints,
            teamConnectPoints: formik.values.teamConnectPoints,
            overallExpereincePoints: formik.values.overallExpereincePoints,
            likes: formik.values.likes,
            mistakes: formik.values.mistakes,
            improvement: formik.values.improvement
        };

        const data = {
            eventId: eventId,
            participantCode: participantCode,
            participantName: participantName,
            surveyDetails: JSON.stringify(surveyData)
        };

        Client.getInstance()
            .createData(ApiConstants.addSurveyDetails(), data)
            .then(() =>
                history.push("/thankyou", {
                    eventClosed: false
                })
            );
    };

    const formik = useFormik({
        initialValues: feedbackInitialValues,
        onSubmit,
        validationSchema: feedbackSchema,
        validateOnMount: true
    });

    return (
        <div>
            <span className={"field-title"}>
                <sup className={"required"}>*</sup>What's your company name
            </span>
            <TextFieldWrapper
                name={"companyName"}
                value={formik.values.companyName}
                onTextFieldValueChange={(text: string) => formik.setFieldValue("companyName", text)}
                errorMessage={formik.errors.companyName}
            />
            <span className={"field-title"}>
                <sup className={"required"}>*</sup>Have you played and online co-operative puzzle game like this before
            </span>
            {onlineOptions.map((option) => (
                <Form.Check
                    key={option.key}
                    type="radio"
                    label={option.text}
                    name="playedBefore"
                    value={option.text}
                    onChange={formik.handleChange}
                    checked={option.text === formik.values.playedBefore}
                    style={{ color: whiteColor }}
                />
            ))}

            <span className={"field-title"}>
                <sup className={"required"}>*</sup>How much do you enjoy this game
            </span>
            <SelectRating
                ratingOptions={ratingOptions}
                selectedRating={formik.values.enjoyPoints}
                setSelectedRating={(rate: number) => formik.setFieldValue("enjoyPoints", rate)}
                ratingColor={ratingOptionColor || defaultButtonBackgroundColor}
            />
            <span className={"field-title"}>
                <sup className={"required"}>*</sup>Did the game help you feel more connected with your team mates?
            </span>
            <SelectRating
                ratingOptions={ratingOptions}
                selectedRating={formik.values.teamConnectPoints}
                setSelectedRating={(rate: number) => formik.setFieldValue("teamConnectPoints", rate)}
                ratingColor={ratingOptionColor || defaultButtonBackgroundColor}
            />
            <span className={"field-title"}>
                <sup className={"required"}>*</sup>How professional was the overall experience?
            </span>
            <SelectRating
                ratingOptions={ratingOptions}
                selectedRating={formik.values.overallExpereincePoints}
                setSelectedRating={(rate: number) => formik.setFieldValue("overallExpereincePoints", rate)}
                ratingColor={ratingOptionColor || defaultButtonBackgroundColor}
            />
            <span className={"field-title"}>
                <sup className={"required"}>*</sup>What did you like about this game?
            </span>
            <TextFieldWrapper
                name={"likes"}
                multiline
                rows={4}
                value={formik.values.likes}
                onTextFieldValueChange={(text: string) => formik.setFieldValue("likes", text)}
                errorMessage={formik.errors.likes}
            />
            <span className={"field-title"}>
                Did you notice any genuine mistakes in the game? If so, can you describe it as best you can.
            </span>
            <TextFieldWrapper
                name={"mistakes"}
                multiline
                rows={4}
                value={formik.values.mistakes}
                onTextFieldValueChange={(text: string) => formik.setFieldValue("mistakes", text)}
            />
            <span className={"field-title"}>
                <sup className={"required"}>*</sup>What could be improved about this game?
            </span>
            <TextFieldWrapper
                name={"improvement"}
                multiline
                rows={4}
                value={formik.values.improvement}
                onTextFieldValueChange={(text: string) => formik.setFieldValue("improvement", text)}
                errorMessage={formik.errors.improvement}
            />
            <Stack horizontalAlign={"center"} className={"margin-top-15"}>
                <Button className={"answer-submit"} onClick={() => formik.handleSubmit()}>
                    Submit
                </Button>
            </Stack>
        </div>
    );
};

export default SurveyForm;

import { Stack } from "@fluentui/react";
import { Button } from "react-bootstrap";
import Styled from "@emotion/styled";

export const ReferenceCloseContainer = Styled(Stack)`
    padding: 0 12.5px;
    margin-bottom: 30px;
`;
export const ReferenceCloseButton = Styled(Button)`
        padding: 10;
        height: 95px;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        box-shadow: 0px 3px 6px #00000029;
        border: border solid #000;
        border-radius: 10px;
        background: transparent linear-gradient(180deg, #6a54c6 0%, #c32290 100%) 0% 0% no-repeat padding-box;
        opacity: 1;
        span {
            font-size: 19px;
        }
`;

import React from "react";

import { Toggle } from "@fluentui/react";

type ToggleFieldWrapperProps = {
    onChange: (checked: boolean, event: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
    label?: string;
    checked?: boolean;
    inlineLabel?: boolean;
    id?: string;
    removeMargin?: boolean;
};

const ToggleFieldWrapper: React.FC<ToggleFieldWrapperProps> = ({
    onChange,
    disabled,
    checked,
    label,
    inlineLabel,
    id,
    removeMargin
}) => {
    const onToggle = (event: React.MouseEvent<HTMLElement>, checked?: boolean): void => {
        onChange(checked as boolean, event);
    };
    return (
        <Toggle
            id={id}
            onChange={onToggle}
            disabled={disabled}
            checked={checked}
            label={label}
            inlineLabel={inlineLabel}
            styles={{root:{marginBottom: removeMargin ? 0 : 8}}}
        />
    );
};

export default ToggleFieldWrapper;

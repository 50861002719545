import { toast } from "react-toastify";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { Client } from "../../../Base/Client";
import { PaymentListType } from "./type";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";

const transformData = (data: any): PaymentListType[] => {
    if (!data) {
        return [];
    }
    return data.map(
        (item) =>
            ({
                amount: item.amount,
                createdAt: item.createdAt,
                currency: item.currency,
                id: item.id,
                status: item.status,
                userName: item.userName
            } as PaymentListType)
    );
};
export const getPaymentList = async (userId: number, isSuperAdmin: boolean) => {
    return Client.getInstance()
        .getData(ApiConstants.fetchPaymentsApiUrl(isSuperAdmin ? "" : userId.toString()), true)
        .then((res) => {
            console.log(res);
            return Promise.resolve(transformData(res.data.data));
        })
        .catch((err) => {
            toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const filterPaymentBySearchText = (rows: PaymentListType[], searchText: string): any =>
    rows.filter(
        (row: PaymentListType) =>
            searchText === "" ||
            row.userName.toLowerCase().includes(searchText.toLowerCase()) ||
            row.amount.toLowerCase().includes(searchText.toLowerCase()) ||
            row.status.toLowerCase().includes(searchText.toLowerCase()) ||
            JambarDateUtil.dateFormat(new Date(row.createdAt), "DD/MM/YYYY")
                .toLowerCase()
                .includes(searchText.toLowerCase())
    );

export const getPaymentReceipt = (receiptId: string) => {
    return Client.getInstance()
        .getData(ApiConstants.downloadPaymentReceiptApiUrl(receiptId))
        .then(() => {
            return Promise.resolve();
        })
        .catch(() => Promise.reject());
};

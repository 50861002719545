import { WildgoatConfigType } from "./WildgoatType";

export const WildgoatConfigInitialValues: WildgoatConfigType = {
    attachmentTitle: "",
    backgroundColor: "",
    backgroundImage: "",
    progressImage: "",
    progressTitle: "",
    tabTitle: "",
    buttonColor: "",
    correctAnswerBackgroundColor: "",
    wrongAnswerBackgroundColor: "",
    primaryColor: "",
    secondaryColor: "",
    tertiaryColor: "",
    wildgoatCorrectAnswerHeading: "",
    totalScoreVisibility: false,
    wildgoatCorrectAnswerImage: "",
    wildgoatTimeoutAnswerImage: "",
    wildgoatTimeoutHeading: "",
    wildgoatWrongAnswerHeading: "",
    wildgoatWrongAnswerImage: ""
};

import { usePublicGameView } from "./hook";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import logo from "../../Images/logo.svg";
import { Formik } from "formik";
import { formValidationSchema } from "./util";
import PublicGameForm from "./component/PublicGameForm";
import { red } from "@mui/material/colors";

const PublicGameView = () => {
    const { eventDetails, error, submitFormLoading, handlePublicEventFormSubmit } = usePublicGameView();

    if (error) {
        return (
            <Grid
                className="login-main-container"
                minHeight={"100vh"}
                alignItems={"center"}
                justifyContent={"center"}
                px={["10px", "10px", "0px"]}
                container>
                <Grid item xs={12} md={6} py={"80px"}>
                    <Grid borderRadius={"10px"} container bgcolor={"#fff"} justifyContent={"center"}>
                        <Grid item xs={12}>
                            <Box
                                borderRadius={"10px 10px 0px 0px"}
                                bgcolor={"#f2f4f7"}
                                display={"flex"}
                                justifyContent={"center"}>
                                <img src={logo} height={"150px"} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} mt={"30px"} pb={"40px"}>
                            <Typography textAlign={"center"} color={red[800]} fontSize={"16px"}>
                                This event is not live right now
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    if (!eventDetails) {
        return (
            <Grid
                className="login-main-container"
                minHeight={"100vh"}
                alignItems={"center"}
                justifyContent={"center"}
                px={["10px", "10px", "0px"]}
                container>
                <Grid item xs={12} md={6} py={"80px"}>
                    <Grid borderRadius={"10px"} container bgcolor={"#fff"} justifyContent={"center"}>
                        <Grid item xs={12}>
                            <Box
                                borderRadius={"10px 10px 0px 0px"}
                                bgcolor={"#f2f4f7"}
                                display={"flex"}
                                justifyContent={"center"}>
                                <img src={logo} height={"150px"} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} mt={"30px"} pb={"40px"}>
                            <Typography alignItems={"center"} textAlign={"center"} fontSize={"16px"}>
                                <CircularProgress size={"16px"} /> Fetching game details...
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid
            className="login-main-container"
            minHeight={"100vh"}
            alignItems={"center"}
            justifyContent={"center"}
            px={["10px", "10px", "0px"]}
            container>
            <Grid item xs={12} md={6} py={"80px"}>
                <Grid borderRadius={"10px"} container bgcolor={"#fff"} justifyContent={"center"}>
                    <Grid item xs={12}>
                        <Box
                            borderRadius={"10px 10px 0px 0px"}
                            bgcolor={"#f2f4f7"}
                            display={"flex"}
                            justifyContent={"center"}>
                            <img src={logo} height={"150px"} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} px={"20px"} py={"10px"}>
                        <Typography fontSize={"16px"}>Event Name: {eventDetails.quizTitle}</Typography>
                        <Typography sx={{ textDecoration: "underline" }} mt={"20px"} variant="h6" fontWeight={"bold"}>
                            How to play?
                        </Typography>
                        <Typography variant="body1" fontWeight={"500"}>
                            1. Enter the number of players
                        </Typography>
                        <Typography variant="body1" fontWeight={"500"}>
                            2. Enter the email ids of all the player.
                        </Typography>
                        <Typography variant="body1" fontWeight={"500"}>
                            3. You will get email with the link of the game.
                        </Typography>
                        <Typography variant="body1" fontWeight={"500"}>
                            4. Click on the link and Enjoy!!
                        </Typography>
                    </Grid>
                    <Grid item xs={12} mt={"20px"} p={"20px"}>
                        <Formik
                            initialValues={{
                                noOfPlayer: 1,
                                emailIds: [""]
                            }}
                            onSubmit={handlePublicEventFormSubmit}
                            validationSchema={formValidationSchema}>
                            {() => <PublicGameForm submitFormLoading={submitFormLoading} />}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PublicGameView;

import React, { FC, useEffect, useState } from "react";
import { QrCodeImage } from "../../constants/SiteConstant";
import { lightPrimaryColor200 } from "../../constants/ColorConstant";

type QrcodeGeneratorProps = {
    link: string;
    height: number;
    width: number;
};
const QrcodeGenerator: FC<QrcodeGeneratorProps> = ({ link, height, width }) => {
    const [qrCode, setQrCode] = useState("");

    useEffect(() => {
        setQrCode(QrCodeImage(link, height, width));
    }, [link]);

    return (
        <div className="output-box">
            <img
                id={"qr-code"}
                src={qrCode}
                style={{ borderRadius: 10, border: `30px ${lightPrimaryColor200} solid` }}
                alt="qrcode"
            />
        </div>
    );
};

export default QrcodeGenerator;

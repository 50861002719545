import { LinearProgress } from "@mui/material";
import Styled from "@emotion/styled";
import { videoBackground } from "../../../../constants/ColorConstant";

export const VideoQuestionContainer = Styled.video`
    background: ${videoBackground};
    border-radius: 10px;
`;

type StyledLinearProgressProps = {
    linecolor?: string;
};
export const StyledLinearProgress = Styled(LinearProgress)`
    & .MuiLinearProgress-bar {
        background-color: ${(props: StyledLinearProgressProps) => props.linecolor || "#00bcd4"};
    }
`;

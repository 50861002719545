import { FormControl } from "react-bootstrap";
import Style from "@emotion/styled";
import { greenColor, inputBackgroundColor, purpleColor, scoreBoxColor, whiteColor } from "../../../../constants/Styles";
import { wildgoatSecondaryColor } from "../../../../constants/ColorConstant";

type WildGoatsContainerProps = {
    bgcolor: string;
    bgcolor_offset: string;
};

export const WildGoatsContainer = Style.div<WildGoatsContainerProps>`
width: 100%;
height: ${window.innerHeight}px;
display: flex;
flex-direction: column;
background: repeating-linear-gradient(
  -55deg,
  ${(props) => props.bgcolor},
  ${(props) => props.bgcolor} 90px,
  ${(props) => props.bgcolor_offset} 5px,
  ${(props) => props.bgcolor_offset} 150px
);
padding: 10px;
padding-bottom: 20px;
@media (max-width: 320px) {
    height: 100%;
  }
`;

export const WildGoatsContainerScroll = Style.div`
width: 100%;
border-radius: 35px;
margin-top: 10px;
`;

export const ContentBox = Style.div`
background: ${whiteColor};
width: 100%;
max-height: 100%;
border-radius: 35px;
padding: 0px 20px;
position: relative;
padding-bottom: 30%;
max-width: 500px;
@media (max-width: 280px) {
    padding: 0 10px;
    padding-bottom: 30%;
  }
`;

type ContentBoxProps = {
    backgroundcolor?: string;
};

export const FeedbackContentBox = Style(ContentBox)`
background: ${(props: ContentBoxProps) => (props.backgroundcolor ? props.backgroundcolor : whiteColor)};
max-height: 90% !important;
overflow: auto;
`;

interface QuestionBoxProps {
    backgroundColor?: string;
}

export const QuestionBox = Style.div`
background: ${(props: QuestionBoxProps) => props.backgroundColor || whiteColor};
width: 100%;
min-height: calc(${window.innerHeight}px - 118px);
border-radius: 35px;
position: relative;
margin-bottom: 10px;
max-width: 500px;
marginTop: 11px;
`;

export const QuestionDetailBox = Style.div`
padding: 0px 20px;
padding-bottom: 20px;
@media (max-width: 280px) {
  padding: 0 10px;
  padding-bottom: 20px;
}
`;

export const WildGoatsCenterContainer = Style.div`
display: grid;
place-items: center;
`;

export const WildGoatsCorrectCenterContainer = Style.div`
  display: grid;
  place-items: center;
  height: ${window.innerHeight}px;
  overflow: auto;
  border-radius: 30px;
`;

export const MainImage = Style.img`
height: 250px;
max-width: 100%;
margin: 25px 0;
`;

export const CodeInputLogo = Style.img`
width: 50%;
margin: 25px 0;
border-radius: 50%
`;

export const FullWidthImage = Style.img`
width: 100%;
`;

type MainTextProps = {
    color?: string;
};
export const MainText = Style.p<MainTextProps>`
margin:0;
padding:0;
color : ${(props) => props.color || greenColor};
font-weight: bold;
text-align: center;
span {
  font-size: 16px !important;
}
`;

type InfoTextProps = {
    color?: string;
};

export const InfoText = Style.p<InfoTextProps>`
margin:0;
padding:0;
font-size: 16px;
color : ${(props) => props.color || purpleColor};
span {
  p {
    margin: 0px;
  }
}
`;

type CircleButtonBigProps = {
    selectionType?: "selected" | "unselected" | "loading";
    tertiarycolor?: string;
    secondaryColor?: string;
};

export const CircleButtonBig = Style.button`
height: 80px;
width: 80px;
background-color: ${(props: CircleButtonBigProps) => `${props.selectionType === "selected" && props.secondaryColor}`};
background-color: ${(props: CircleButtonBigProps) => `${props.selectionType === "unselected" && "#dee2e6"}`};
background-color: ${(props: CircleButtonBigProps) => `${props.selectionType === "loading" && props.tertiarycolor}`};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;
border: 0;
display: flex;
justify-content: center;
align-items: center;
position: absolute;
bottom: -20px;
right: 5px;
`;

export const LoginCircleButtonBig = Style(CircleButtonBig)`
background-color: ${(props: CircleButtonBigProps) => props.secondaryColor};
`;

type CircleSkipButtonProps = {
    fontcolor?: string;
};
export const CircleSkipButton = Style(CircleButtonBig)<CircleSkipButtonProps>`
  bottom: -20px;
  right: 100px;
  height: 70px;
  width: 70px;
  color: ${(props) => props.fontcolor || "grey"} !important;
  box-shadow: 0px 3px 6px #00000029;
  background-color: ${(props: CircleButtonBigProps) => props.secondaryColor};
`;

export const CircleButtonSmall = Style.div`
height: 50px;
width: 50px;
background-color: ${greenColor};
border-radius: 50%;
display: flex;
box-shadow: 0px 3px 6px #00000029;
justify-content: center;
align-items: center;
position: absolute;
bottom: -20px;
left: 0px;
`;

type CustomInputBoxProps = {
    fontcolor?: string;
};
export const CustomInputBox = Style(FormControl)<CustomInputBoxProps>`
background: ${scoreBoxColor};
padding: 20px 40px 20px 15px !important;
border-radius: 30px !important;
color: ${(props) => props.fontcolor || wildgoatSecondaryColor} !important;
`;

export const ScrollBox = Style.div`
height: calc(${window.innerHeight}px - 160px);
overflow: auto;
`;

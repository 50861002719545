import { Grid } from "@mui/material";
import { FC } from "react";
import { StyledAvatarImage } from "./Style";
import { avatars } from "./Util";

type AvatarPropsType = {
    setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
}
const Avatars: FC<AvatarPropsType> = ({ setFieldValue }) => {

    const avatarList = avatars.map((avatar, index) => {
        return (
            <Grid item key={index}>
                <StyledAvatarImage src={avatar} alt={"avatar" + index} onClick={() => { setFieldValue('profileImage', avatar); }} />
            </Grid>
        );
    });

    return (
        <Grid container spacing={"10px"}>
            {avatarList}
        </Grid>
    );
};

export default Avatars;
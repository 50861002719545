import React from "react";
import SadImage from "../../../Images/Wildgoat/wrongAnswer.svg";
import {
    WildGoatsCenterContainer,
    ContentBox,
    MainImage,
    WildGoatsContainerScroll,
    WildGoatsCorrectCenterContainer
} from "../CommonStyledComponents/CommonStyle";
import { ScoreText, DescriptionText, SmallText } from "./Style";
import { FC } from "react";

interface WrongAnswerProps {
    wrongAnswerPoints: number;
    wrongAnswerText: string;
    wrongAnswerHeading: string;
    wrongAnswerImage: string;
    isForceCorrect: boolean;
}

const WrongAnswer: FC<WrongAnswerProps> = ({
    wrongAnswerPoints,
    wrongAnswerText,
    wrongAnswerHeading,
    wrongAnswerImage,
    isForceCorrect
}) => {
    return (
        <WildGoatsCorrectCenterContainer>
            <WildGoatsContainerScroll>
                <WildGoatsCenterContainer>
                    <ContentBox style={{ margin: 0, overflow: "auto" }}>
                        <WildGoatsCenterContainer>
                            <MainImage src={wrongAnswerImage || SadImage} alt="wrongAnswer" />
                        </WildGoatsCenterContainer>
                        <SmallText style={{ fontSize: 24 }}>
                            {wrongAnswerHeading || "Sorry - that's incorrect."}
                        </SmallText>
                        {wrongAnswerPoints ? (
                            <ScoreText style={{ fontSize: 20 }}>{wrongAnswerPoints} points.</ScoreText>
                        ) : null}
                        <DescriptionText>
                            {wrongAnswerText ? (
                                <span
                                    className={"quill-editor-text-align"}
                                    dangerouslySetInnerHTML={{
                                        __html: wrongAnswerText
                                    }}
                                />
                            ) : (
                                `${wrongAnswerPoints} points have been deducted from your score.`
                            )}
                        </DescriptionText>
                        {isForceCorrect && (
                            <DescriptionText style={{ marginTop: 10 }}>
                                Please enter correct answer to go to next question
                            </DescriptionText>
                        )}
                    </ContentBox>
                </WildGoatsCenterContainer>
            </WildGoatsContainerScroll>
        </WildGoatsCorrectCenterContainer>
    );
};

export default WrongAnswer;

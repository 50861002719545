import React, { useEffect, useState } from "react";
import "./Timer.scss";
import { QUESTION_TIMER_TYPE, time } from "../../data/question/types";
import { SocketGame } from "../../constants/SiteConstant";

type ITimerNativeProps = {
    timer: string;
    onTimeOut?: () => void;
    getTiming?: (time: string) => void;
    quizTiming?: string;
    type: string;
    eventFinishes: boolean;
    quizType?: string;
};

const Timer: React.FC<ITimerNativeProps> = ({ timer, onTimeOut, getTiming, type, eventFinishes, quizType }) => {
    const [time, setTime] = useState<time>({ hr: 0, min: 0, sec: 0 });
    let interval: any = null;
    useEffect(() => {
        !eventFinishes && startTimer();
        return () => clearInterval(interval);
    }, []);

    const startTimer = () => {
        const countDownTime = Date.now() + getTimeInSec(timer);
        interval = setInterval(() => {
            const now: any = new Date();
            const distance = countDownTime - now;
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const sec = Math.floor((distance % (1000 * 60)) / 1000);
            if (distance < 0) {
                setTime({
                    hr: 0,
                    min: 0,
                    sec: 0
                });
                onTimeOut && onTimeOut();
            } else {
                setTime({
                    hr: hours,
                    min: min,
                    sec: sec
                });
                getTiming && getTiming(`${hours}:${min}:${sec}`);
            }
        }, 100);
    };

    const getTimeInSec = (time: string): number => {
        const hh_mm_ss = time.split(":");
        let seconds = 0;
        seconds = parseInt(hh_mm_ss[0]) * 60 * 60 + parseInt(hh_mm_ss[1]) * 60 + parseInt(hh_mm_ss[2]);
        return seconds * 1000;
    };

    if (quizType === SocketGame && time.hr === 0 && time.min === 0 && time.sec === 0) {
        return (
            <span className={"font-size-20"} style={{ color: "transparent" }}>
                00:00
            </span>
        );
    }

    if (type === QUESTION_TIMER_TYPE) {
        return (
            <span className={"font-size-20"}>
                {time.sec
                    ? `${time.min.toString().padStart(2, "0")}:${time.sec.toString().padStart(2, "0")}`
                    : `${time.min.toString().padStart(2, "0")}:00`}
            </span>
        );
    }

    return (
        <span className={"font-size-20"}>
            {time.sec
                ? `${(time.min + time.hr * 60).toString().padStart(2, "0")}:${time.sec.toString().padStart(2, "0")}`
                : `${(time.min + time.hr * 60).toString().padStart(2, "0")}:00`}
        </span>
    );
};

export default Timer;

import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { StyledActionColumn, StyledColumn, StyledHeader } from "../../../CommonComponent/v2/datagrid/DataGridStyle";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { STATUS_INFO } from "./type";
import { errorLightColor, successColor } from "../../../../constants/ColorConstant";
import { darkColor } from "../../../WebsiteGame/colorConstant";

const statusColor = {
    success: successColor,
    initiated: "#ff8e00",
    failed: errorLightColor
};
export const paymentListColumns = (downloadReceipt: (receiptId: string) => void): GridColDef[] => [
    {
        field: "userName",
        headerName: "User name",
        flex: 1,
        renderHeader: () => <StyledHeader label="User name" />,
        renderCell: (params: GridCellParams) => <StyledColumn label={params.row.userName} />
    },
    {
        field: "amount",
        headerName: "Amount",
        flex: 1,
        renderHeader: () => <StyledHeader label="Amount" />,
        renderCell: (params: GridCellParams) => <StyledColumn label={`${params.row.amount} ${params.row.currency}`} />
    },
    {
        field: "noOfCredit",
        headerName: "Credits",
        flex: 1,
        renderHeader: () => <StyledHeader label="Credits" />,
        renderCell: (params: GridCellParams) => <StyledColumn label={`${params.row.amount / 10}`} />
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderHeader: () => <StyledHeader label="Status" />,
        renderCell: (params: GridCellParams) => (
            <StyledColumn color={statusColor[params.row.status] || darkColor} label={STATUS_INFO[params.row.status]} />
        )
    },
    {
        field: "createdAt",
        width: 120,
        renderHeader: () => <StyledHeader label="Starting On" />,
        renderCell: (params: GridCellParams) => (
            <StyledColumn
                fontWeight={"500"}
                color={"#1F71B6"}
                label={JambarDateUtil.dateFormat(new Date(params.row.createdAt), "DD/MM/YYYY")}
            />
        )
    },
    {
        field: "Action",
        headerName: "Download",
        width: 150,
        renderHeader: () => <StyledHeader label="Download" />,
        renderCell: (params: GridCellParams) => (
            <StyledActionColumn
                isDownloadAvailaible={{
                    isAvailable: false,
                    onClick: () => downloadReceipt(params.row.id)
                }}
            />
        )
    }
];

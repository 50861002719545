import { useEffect, useState } from "react";
import { createpPublicEvent, fetchEventData } from "./util";
import { PathQuizType } from "../pathchallenge/component/type";
import { PublicGameFormType, PublicGameType } from "./type";
import { toast } from "react-toastify";

export const usePublicGameView = () => {
    const [eventDetails, setEventDetails] = useState<PublicGameType | null>(null);
    const [submitFormLoading, setSubmitFormLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        fetchEventId();
    }, []);

    const fetchEventId = (): string => {
        const url = window.location.href;
        const urlSplit = url.split("/");
        fetchEvent(urlSplit[urlSplit.length - 1]);
        // setEvent(urlSplit[urlSplit.length - 1]);
        return urlSplit[urlSplit.length - 1];
    };

    const fetchEvent = (eventLink: string): void => {
        fetchEventData(eventLink)
            .then((res) => {
                setEventDetails(res);
            })
            .catch(() => {
                setError(true);
            });
    };

    const handlePublicEventFormSubmit = (data: PublicGameFormType) => {
        setSubmitFormLoading(true);
        createpPublicEvent(data, eventDetails.id)
            .then(() => {
                toast.success("Email sent successfully. Please check your mail.");
            })
            .catch(() => {
                toast.error("Something went wrong");
            })
            .finally(() => {
                setSubmitFormLoading(false);
            });
    };
    return {
        eventDetails,
        handlePublicEventFormSubmit,
        submitFormLoading,
        error
    };
};

import { Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import { Label } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "./PublicLeaderBoard.scss";
import { LeaderBoardClass, LeaderBoardBadge } from "./LeaderBoard";
import { sortLeaderboard } from "./PublicLeaderBoardUtils";
import { fetchLeaderBoard } from "./LeaderBoardApi";
import { EventDetails, LeaderboardColumns, TeamRecord } from "./Types";
import SettingsIcon from "../../Images/control.png";
import SettingsModal from "./SettingsModal";
import { LeaderBoardPollingInterval } from "../../../constants/SiteConstant";

const PublicLeaderboardView: React.FC = () => {
    const { link } = useParams<{ link: string }>();

    const [eventDetails, setEventDetails] = useState<EventDetails>();
    const [teamRecords, setTeamRecords] = useState<TeamRecord[]>([]);
    const [leaderboardErrorMessage, setLeaderboardErrorMessage] = useState("");
    const [loading, setLoading] = useState<boolean>(true);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [showColumns, setShowColumns] = useState<LeaderboardColumns>({
        position: true,
        name: true,
        score: true,
        hints: true,
        timeTaken: true,
        timeLeft: true,
        timeLeftInConnector: true
    });

    let interval: NodeJS.Timeout;

    const getLiveLeaderBoardData = () => {
        fetchLeaderBoard(link)
            .then((data: any) => {
                const { eventDetails, teamRecords } = data;
                setEventDetails(eventDetails);
                setTeamRecords(teamRecords);
                setLoading(false);
            })
            .catch((Error: string) => {
                setLeaderboardErrorMessage(Error);
                setLoading(false);
            });
    };

    useEffect(() => {
        if (eventDetails?.status && eventDetails?.status !== "active") {
            setLeaderboardErrorMessage("This event has been closed.");
            return;
        }
        getLiveLeaderBoardData();
        interval = setInterval(() => getLiveLeaderBoardData(), LeaderBoardPollingInterval);
        return () => clearInterval(interval);
    }, [eventDetails?.status]);

    if (loading)
        return (
            <div className="loader">
                <Spinner size={SpinnerSize.large} />
            </div>
        );

    if (leaderboardErrorMessage) {
        return <Label className={"error-message text-align-center font-size-16"}>{leaderboardErrorMessage}</Label>;
    }

    return (
        <>
            <div className="leaderBoardContainer">
                <Stack className="stackColor" horizontal horizontalAlign="space-between">
                    <Stack.Item align="center">
                        <img
                            onClick={() => setModalShow(true)}
                            className="settings"
                            src={SettingsIcon}
                            width={50}
                            alt="settings"
                        />
                    </Stack.Item>
                    <Stack.Item align="center">
                        <h1> Leaderboard - {eventDetails?.eventName} </h1>
                    </Stack.Item>
                    <Stack.Item align="center">
                        {eventDetails?.logo !== "" ? (
                            <img src={eventDetails?.logo} width={150} className="eventLogo" alt="event logo" />
                        ) : (
                            " "
                        )}
                    </Stack.Item>
                </Stack>
                <table>
                    <thead>
                        <tr>
                            <th hidden={!showColumns.position}>
                                <Text variant="xxLarge"> Position </Text>
                            </th>
                            <th hidden={!showColumns.name}>
                                <Text variant="xxLarge"> Name </Text>
                            </th>
                            <th hidden={!showColumns.score}>
                                <Text variant="xxLarge"> Score </Text>
                            </th>
                            <th hidden={!showColumns.hints}>
                                <Text variant="xxLarge"> Hints </Text>
                            </th>
                            <th hidden={!showColumns.timeTaken}>
                                <Text variant="xxLarge"> Time Taken </Text>
                            </th>
                            <th hidden={!showColumns.timeLeft}>
                                <Text variant="xxLarge"> Time Left </Text>
                            </th>
                            <th hidden={!showColumns.timeLeftInConnector}>
                                <Text variant="xxLarge"> Time Left In Connector </Text>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortLeaderboard(teamRecords).map((item: TeamRecord, index: number) => (
                            <tr className={LeaderBoardClass(index + 1)} key={item.participantCode}>
                                {LeaderBoardBadge(index + 1, showColumns.position)}
                                <td hidden={!showColumns.name}>{item.teamName && item.teamName}</td>
                                <td hidden={!showColumns.score}>{item.score && item.score}</td>
                                <td hidden={!showColumns.hints}>{item.hintsUsedCount && item.hintsUsedCount}</td>
                                <td hidden={!showColumns.timeTaken}>{item.timeTakenInQuiz && item.timeTakenInQuiz}</td>
                                <td hidden={!showColumns.timeLeft}>{item.timeLeftInQuiz && item.timeLeftInQuiz}</td>
                                <td hidden={!showColumns.timeLeftInConnector}>
                                    {item.timeLeftInConnector ? item.timeLeftInConnector : "N/A"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <SettingsModal
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    setShowColumns={setShowColumns}
                    showColumns={showColumns}
                />
            </div>
        </>
    );
};

export default PublicLeaderboardView;

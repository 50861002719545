import { Formik } from "formik";
import { FC } from "react";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import UserChangePassword from "./UserChangePassword";
import { ChangePasswordValidationSchema, getChangeUserPassword } from "./Util";

type ChangePasswordProps = {
    selectedUserId: number;
};

const ChangePassword: FC<ChangePasswordProps> = ({ selectedUserId }) => {
    const changePassword = (value: { email: string; password: string }) => {
        getChangeUserPassword(value.email, value.password)
            .then(() => {
                toast.success("Password changed", SiteConstants.successToastConfiguration);
            })
            .catch((err) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            });
    };

    return (
        <div>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    confirmPassword: ""
                }}
                validationSchema={ChangePasswordValidationSchema}
                onSubmit={changePassword}
                validateOnMount={false}
                enableReinitialize>
                {() => {
                    return <UserChangePassword userId={selectedUserId} />;
                }}
            </Formik>
        </div>
    );
};

export default ChangePassword;

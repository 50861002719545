import { Drawer, Grid } from "@mui/material";
import { FC } from "react";
import AddQuestion from "../../../questionlibrary/addquestion";
import { EditGameType } from "./Type";

type GameEditDrawerProps = {
    handleClose: () => void;
    selectedQuestion: EditGameType;
    refreshList: () => void;
};

const GameEditDrawer: FC<GameEditDrawerProps> = ({ handleClose, selectedQuestion, refreshList }) => {
    return (
        <Drawer
            anchor={"right"}
            PaperProps={{
                style: {
                    width: "70%",
                    paddingTop: 30,
                    paddingLeft: 10
                }
            }}
            open
            onClose={handleClose}>
            <AddQuestion selectedQuestionId={selectedQuestion} handleClose={handleClose} refreshList={refreshList} />
        </Drawer>
    );
};

export default GameEditDrawer;

import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { darkPrimaryColor } from "../../../../../../constants/ColorConstant";
import { FieldValidation } from "../../../../../../constants/FieldValidation";
import { CaptionText, Header2 } from "../../../../../CommonComponent/v2/CommonStyle";
import FormikTextEditor from "../../../../../CommonComponent/v2/fields/FormikTextEditor";
import FormikTextInput from "../../../../../CommonComponent/v2/fields/FormikTextInput";
import FormikToggle from "../../../../../CommonComponent/v2/fields/FormikToggle";
import { GameDetailType } from "../Type";

const GameDetailsForm = () => {
    const { values } = useFormikContext();
    const data = values as GameDetailType;

    return (
        <Grid container mt={"20px"}>
            <Grid item xs={12}>
                <Header2>Game details</Header2>
                <CaptionText mt={"5px"}>This is how the player would interact with the game.</CaptionText>
            </Grid>
            <Grid item xs={12}>
                <Grid container mt={"19px"} spacing={"15px"}>
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"gameDetails.title"}
                            id={"gameDetails.title"}
                            label={"Title(in game)"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CaptionText my={"10px"} color={darkPrimaryColor} fontWeight={700}>
                            Game time limit
                        </CaptionText>
                        <Grid container spacing={"10px"}>
                            <Grid item xs={3}>
                                <FormikTextInput
                                    name="gameDetails.timeLimit.minutes"
                                    id="gameDetails.timeLimit.minutes"
                                    label="Minutes"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormikTextInput
                                    name="gameDetails.timeLimit.seconds"
                                    id="gameDetails.timeLimit.seconds"
                                    label="Seconds"
                                />
                            </Grid>
                        </Grid>
                        <CaptionText my={"10px"} color={"red"} fontWeight={700}>
                            {(parseInt(data.gameDetails.timeLimit.minutes) === 0 &&
                                parseInt(data.gameDetails.timeLimit.seconds) === 0) ||
                            (isNaN(parseInt(data.gameDetails.timeLimit.minutes)) &&
                                isNaN(parseInt(data.gameDetails.timeLimit.seconds)))
                                ? "Time limit is mandatory"
                                : !FieldValidation.quizTimeLimitValidation(
                                      parseInt(data.gameDetails.timeLimit.minutes),
                                      parseInt(data.gameDetails.timeLimit.seconds)
                                  )
                                ? "Please enter valid time limit"
                                : null}
                        </CaptionText>
                    </Grid>
                    {data.gameIdentification.gameType === "fullgame" && (
                        <Grid item xs={12}>
                            <FormikToggle
                                id={"gameDetails.isConnectorQuiz"}
                                name="gameDetails.isConnectorQuiz"
                                label="Connector game"
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <CaptionText my={"10px"} color={darkPrimaryColor} fontWeight={700}>
                    Game summary
                </CaptionText>
                <FormikTextEditor
                    name={"gameDetails.gameSummary"}
                    id={"gameDetails.gameSummary"}
                    placeholder="Enter game summary here..."
                />
            </Grid>
        </Grid>
    );
};

export default GameDetailsForm;

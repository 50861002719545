import firstMedal from "../../Images/firstMedal.png";
import secondMedal from "../../Images/secondMedal.png";
import thirdMedal from "../../Images/thirdMedal.png";

export const LeaderBoardClass = (pos:number) => {
  return (pos < 9 ? `pos${pos}`: "pos9");
};

const badgeMap:any = {
  1: {
  suffix: 'st',
  imageSrc: firstMedal,
  class: 'badgePos',
  },
  2: {
  suffix: 'nd',
  imageSrc: secondMedal,
  class: 'badgePos',
  },
  3: {
      suffix: 'rd',
      imageSrc: thirdMedal,
      class: 'badgePos',
  },
  4: {
      suffix: 'th',
  }
};

export const LeaderBoardBadge = (pos:number,posCol:boolean) => {

      return ( 
        pos < 4 ? 
         (<td hidden={!posCol} className={badgeMap[pos].class}>
              {pos}
              <sup>{badgeMap[pos].suffix}</sup>
              <img src={badgeMap[pos].imageSrc} alt={"badge-image"+pos} width="50px" />
          </td> )
        : 
          (<td hidden={!posCol} >
              {pos}
              <sup>{badgeMap[4].suffix}</sup>
          </td>)
        );
};

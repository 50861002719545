import { wildgoatPrimaryColor, wildgoatSecondaryColor } from "../../../../../constants/ColorConstant";
import CorrectAnswer from "../../../WildGoat/CorrectAnswer/CorrectAnswer";
import Loader from "../../../WildGoat/Loader";
import QuestionTimeout from "../../../WildGoat/QuestionTimeout/QuestionTimeout";
import SkipAnswerView from "../../../WildGoat/SkipAnswer/SkipAnswerView";
import WrongAnswer from "../../../WildGoat/WrongAnswer/WrongAnswer";
import {
    ConnectorQuestionInfoTypes,
    CurrentQuestionInfoType,
    QuestionConnector,
    QuizInfoType,
    QuizUserSessionInfoType
} from "../type";
import PathGameQuestionAnswerSection from "./PathGameQuestionAnswerSection";

type PathGameQuestionViewProps = {
    currentQuestionInfo: CurrentQuestionInfoType;
    participantAnswer: string;
    isFileUploading?: boolean;
    quizInfoData: QuizInfoType;
    questionTiming: string;
    setQuestionTiming: (time: string) => void;
    saveProgress: (isSkipped?: boolean) => void;
    quizUserSessionInfoData: QuizUserSessionInfoType;
    updateParticipantAnswer: (answer: string) => void;
    uploadFile?: (file: File[]) => void;
    uploadedPercentage?: number;
    isFileUploadFailed?: boolean;
    loading: boolean;
    answerResult: string;
    connectorQuestionSubmit: (connectorOpt: QuestionConnector) => void;
    connectorQuestionInfo: ConnectorQuestionInfoTypes;
    getEventTiming: (time: string) => void;
    getQuestionTiming: (time: string) => void;
    getConnectorTiming: (time: string) => void;
    endQuiz: boolean;
};
const PathGameQuestionView = ({
    currentQuestionInfo,
    participantAnswer,
    isFileUploading,
    quizInfoData,
    questionTiming,
    setQuestionTiming,
    saveProgress,
    quizUserSessionInfoData,
    updateParticipantAnswer,
    uploadFile,
    uploadedPercentage,
    loading,
    isFileUploadFailed,
    answerResult,
    connectorQuestionSubmit,
    connectorQuestionInfo,
    getConnectorTiming,
    getEventTiming,
    getQuestionTiming,
    endQuiz
}: PathGameQuestionViewProps) => {
    if (answerResult === "skip") {
        return <SkipAnswerView />;
    }
    if (answerResult === "timeout") {
        return (
            <QuestionTimeout
                timeoutAnswerHeading={"Skip"}
                timeoutAnswerImage={""}
                timeoutText={currentQuestionInfo.timeOutText}
            />
        );
    }
    if (answerResult === "correct") {
        return (
            <CorrectAnswer
                secondaryColor={wildgoatPrimaryColor}
                correctAnswerPoints={
                    currentQuestionInfo.isNonScoringQuestion
                        ? 0
                        : currentQuestionInfo.correctPoints
                        ? currentQuestionInfo.correctPoints
                        : parseInt(quizInfoData.correctAnswerPoints)
                }
                correctAnswerText={currentQuestionInfo.correctAnswerText}
                correctAnswerHeading={"Correct"}
                correctAnswerImage={""}
            />
        );
    }
    if (answerResult === "wrong") {
        return (
            <WrongAnswer
                wrongAnswerPoints={
                    currentQuestionInfo.isNonScoringQuestion
                        ? 0
                        : currentQuestionInfo.wrongPoints
                        ? currentQuestionInfo.wrongPoints
                        : parseInt(quizInfoData.wrongAnswerPoints)
                }
                wrongAnswerText={currentQuestionInfo.wrongAnswerText}
                wrongAnswerHeading={"Wrong"}
                wrongAnswerImage={""}
                isForceCorrect={!!currentQuestionInfo.forceCorrect}
            />
        );
    }
    if (loading) {
        return <Loader />;
    }

    return (
        <PathGameQuestionAnswerSection
            currentQuestionInfo={currentQuestionInfo}
            isFileUploadFailed={isFileUploadFailed}
            isFileUploading={isFileUploading}
            participantAnswer={participantAnswer}
            questionTiming={questionTiming}
            quizInfoData={quizInfoData}
            quizUserSessionInfoData={quizUserSessionInfoData}
            saveProgress={saveProgress}
            setQuestionTiming={setQuestionTiming}
            updateParticipantAnswer={updateParticipantAnswer}
            uploadFile={uploadFile}
            uploadedPercentage={uploadedPercentage}
            connectorQuestionSubmit={connectorQuestionSubmit}
            connectorQuestionInfo={connectorQuestionInfo}
            getEventTiming={getEventTiming}
            getConnectorTiming={getConnectorTiming}
            getQuestionTiming={getQuestionTiming}
            connectorTiming={connectorQuestionInfo.timeTakenInConnector}
            eventFinishes={endQuiz}
            eventTiming={quizUserSessionInfoData.eventTimer}
        />
    );
};

export default PathGameQuestionView;

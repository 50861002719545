import { IColumn, Stack, Panel, PanelType, IconButton, Label, StackItem, PrimaryButton } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { EventCodesList } from "./EventDetailsDataTypes";
import EventCodeDetailView from "./EventCodeDetailView";
import BulkCodeDetailView from "./BulkEventCodeDetailView";
import DeleteConfirmationDialog from "../../../CommonComponent/DeleteConfirmationDialog";
import { Client } from "../../../Base/Client";
import { toast } from "react-toastify";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { EventCodeDetail } from "./EventCodeDetailView";
import { HelperFunctions } from "../../../../constants/HelperFns";
import FixedHeaderSortingDetailsList from "../../../CommonComponent/FixedHeaderSortingDetilsList";
import { donwloadEventCodes } from "./utils/downloadFilesApi";
import { isDateLess } from "../../../../utils/JambarUtils";

type EventCodesViewProps = {
    eventName: string;
    eventId: number;
    isEventClosed: boolean;
};

const excludeColumns = ["edit", "delete"];

const EventCodesView: React.FC<EventCodesViewProps> = ({ eventName, eventId, isEventClosed }) => {
    const [searchText, setSearchText] = useState("");
    const [showAddEditCodePanel, setShowAddEditCodePanel] = useState(false);
    const [showBulkCodePanel, setShowBulkCodePanel] = useState(false);
    const [eventCodeList, setEventCodeList] = useState<EventCodesList[]>([]);
    const [selectedEventCodeId, setSelectedEventCodeId] = useState(-1);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [isRefreshLoading, setIsRefreshLoading] = useState(false);

    useEffect(() => {
        fetchEventCodesList();
    }, []);

    const fetchEventCodesList = (forceRefresh = false, refreshLoading = false): void => {
        refreshLoading && setIsRefreshLoading(true);
        Client.getInstance()
            .getData(ApiConstants.getEventCodesApiUrl(eventId), forceRefresh)
            .then((response) => {
                const codeList: EventCodesList[] = response.data.data.map((item: any) => ({
                    code: item.participantCode,
                    status: HelperFunctions.capitalizeFirstLetter(item.status),
                    startDate: item.startDate,
                    endDate: item.endDate,
                    generatedBy: item.createdByUser
                        ? `${item.createdByUser.firstName} ${item.createdByUser.lastName}`
                        : "",
                    notes: item.notes,
                    usedDate: item.usedDate,
                    id: item.id
                }));
                setEventCodeList(codeList);
                setIsRefreshLoading(false);
            });
    };

    const onConfirmDelete = (): void => {
        Client.getInstance()
            .deleteData(ApiConstants.deleteEventCodeApiUrl(eventId, selectedEventCodeId))
            .then(() => {
                toast.success("Code deleted", SiteConstants.deleteToastConfiguration);
                setSelectedEventCodeId(-1);
                setShowDeleteConfirmationDialog(false);
                fetchEventCodesList(true);
            })
            .catch((error) => {
                setSelectedEventCodeId(-1);
                setShowDeleteConfirmationDialog(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const eventCodeColumns: IColumn[] = [
        {
            key: "code",
            name: "Code",
            fieldName: "code",
            minWidth: SiteConstants.listColumnXSmall,
            maxWidth: SiteConstants.listColumnSmall
        },
        {
            key: "copy",
            name: "",
            fieldName: "copy",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "status",
            name: "Status",
            fieldName: "status",
            minWidth: SiteConstants.listColumnXSmall,
            maxWidth: SiteConstants.listColumnSmall
        },
        {
            key: "dateRange",
            name: "Date Range",
            fieldName: "dateRange",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        },
        {
            key: "generatedBy",
            name: "Generated By",
            fieldName: "generatedBy",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        },
        {
            key: "notes",
            name: "Notes",
            fieldName: "notes",
            minWidth: SiteConstants.listColumnMedium
        },
        {
            key: "edit",
            name: "",
            fieldName: "edit",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "delete",
            name: "",
            fieldName: "delete",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        }
    ];

    const getFilteredItems = (): any => {
        const filteredItems =
            searchText === ""
                ? eventCodeList
                : eventCodeList.filter((item: EventCodesList) => {
                      return includeInFilteredItems(searchText.toLowerCase(), item);
                  });
        return filteredItems;
    };

    const includeInFilteredItems = (searchText: string, item: EventCodesList): boolean => {
        if (item.code && item.code.toLowerCase().includes(searchText)) {
            return true;
        }
        if (item.endDate && item.endDate.includes(searchText)) {
            return true;
        }
        if (item.startDate && item.startDate.includes(searchText)) {
            return true;
        }
        if (item.generatedBy && item.generatedBy.toLowerCase().includes(searchText)) {
            return true;
        }
        if (item.notes && item.notes.toLowerCase().includes(searchText)) {
            return true;
        }
        if (item.status && item.status.toLowerCase().includes(searchText)) {
            return true;
        }

        return false;
    };

    const renderStatus = (status: string, endDate: string, usedDate: string) => {
        if (status.toLowerCase() === "started") {
            if (isDateLess(new Date(), new Date(endDate))) {
                return (
                    <span>
                        {status} -
                        <br />
                        {JambarDateUtil.dateFormat(new Date(usedDate), "DD/MM/YYYY")}
                    </span>
                );
            }
            return <span>Expired</span>;
        }

        return <span>{status}</span>;
    };

    const renderEventCodesColumn = (
        item: EventCodesList,
        index: number | undefined,
        column: IColumn | undefined
    ): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "code":
                return <span className={"event-codes width-100per"}>{item.code}</span>;
            case "status":
                return renderStatus(item.status, item.endDate, item.usedDate);
            case "dateRange":
                return (
                    <span>
                        <i>{JambarDateUtil.dateFormat(new Date(item.startDate), "DD/MM/YYYY")}</i>
                        <br />
                        to <i>{JambarDateUtil.dateFormat(new Date(item.endDate), "DD/MM/YYYY")}</i>
                    </span>
                );

            case "copy":
                return (
                    <IconButton
                        iconProps={{ iconName: "copy" }}
                        title={"Copy code"}
                        onClick={() => navigator.clipboard.writeText(item.code)}
                    />
                );
            case "delete":
                return (
                    <IconButton
                        iconProps={{ iconName: "delete" }}
                        title="Delete"
                        onClick={() => {
                            setSelectedEventCodeId(item.id);
                            setShowDeleteConfirmationDialog(true);
                        }}
                    />
                );
            case "edit":
                return (
                    <IconButton
                        iconProps={{ iconName: "Edit" }}
                        title="Edit"
                        onClick={() => {
                            setSelectedEventCodeId(item.id);
                            setShowAddEditCodePanel(true);
                        }}
                    />
                );
            default: {
                const fieldContent = item[column.fieldName as keyof EventCodesList] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    const getCodeDetails = (): EventCodeDetail => {
        const details = eventCodeList.find((item) => item.id === selectedEventCodeId);
        if (details && selectedEventCodeId !== -1) {
            return {
                endDate: details?.endDate || "",
                generatedBy: details?.generatedBy || "",
                notes: details?.notes || "",
                participantCode: details?.code || "",
                startDate: details?.startDate || "",
                status: details?.status || ""
            };
        }
        const date = new Date();
        return {
            endDate: JambarDateUtil.dateFormat(new Date(date.setDate(date.getDate() + 7)), "YYYY-MM-DD").toString(),
            generatedBy: "",
            notes: "",
            participantCode: "",
            startDate: JambarDateUtil.dateFormat(new Date(), "YYYY-MM-DD").toString(),
            status: ""
        };
    };

    const AddBulkCodeButton = () => {
        return (
            <StackItem grow={10}>
                <PrimaryButton
                    text={"Generate Bulk Codes"}
                    onClick={() => setShowBulkCodePanel(true)}
                    disabled={isEventClosed}
                />
            </StackItem>
        );
    };

    const AddRefreshCodeButton = () => {
        return (
            <StackItem grow={150}>
                <PrimaryButton
                    text={isRefreshLoading ? "Loading.." : "Refresh"}
                    onClick={() => fetchEventCodesList(true, true)}
                    disabled={isRefreshLoading}
                />
            </StackItem>
        );
    };

    const DownloadCodeButton = () => {
        return (
            <StackItem grow={10}>
                <PrimaryButton text={"Download"} onClick={() => donwloadEventCodes(eventId)} />
            </StackItem>
        );
    };

    return (
        <>
            <Stack className={"section-background section-border overflowy-auto event-codes-list-container"}>
                {isEventClosed && <Label className={"event-closed-message"}>This event is now closed</Label>}
                <FixedHeaderSortingDetailsList
                    onSearchTextChange={(text: string) => setSearchText(text)}
                    searchText={searchText}
                    onAddButtonClick={() => setShowAddEditCodePanel(true)}
                    buttonLabel={"Add Code"}
                    addBulkCodeButton={AddBulkCodeButton()}
                    addRefreshButton={AddRefreshCodeButton()}
                    searchFieldWithoutLabel={true}
                    columns={
                        isEventClosed
                            ? eventCodeColumns.filter((item) => !excludeColumns.includes(item.key))
                            : eventCodeColumns
                    }
                    isLoading={false}
                    items={getFilteredItems()}
                    onRenderItemColumn={renderEventCodesColumn}
                    label={eventName}
                    hideAddNewButton={isEventClosed}
                    searchFieldWidth={isEventClosed ? "30%" : undefined}
                    downloadZip={DownloadCodeButton()}
                />
            </Stack>
            <DeleteConfirmationDialog
                entryName={"code"}
                isShown={showDeleteConfirmationDialog}
                onConfirm={onConfirmDelete}
                onDismiss={() => {
                    setSelectedEventCodeId(-1);
                    setShowDeleteConfirmationDialog(false);
                }}
            />
            <Panel
                isLightDismiss
                isOpen={showAddEditCodePanel}
                onDismiss={() => {
                    setShowAddEditCodePanel(false);
                    setSelectedEventCodeId(-1);
                }}
                headerText={selectedEventCodeId === -1 ? "Add Code" : "Edit Code"}
                type={PanelType.medium}>
                <EventCodeDetailView
                    refreshCodeList={() => fetchEventCodesList(true)}
                    closeAddPanel={(id) => setSelectedEventCodeId(id)}
                    codeDetails={getCodeDetails()}
                    eventId={eventId}
                    eventIdsList={eventCodeList.map((item) => item.code)}
                    codeId={selectedEventCodeId}
                />
            </Panel>
            <Panel
                isLightDismiss
                isOpen={showBulkCodePanel}
                onDismiss={() => {
                    setShowBulkCodePanel(false);
                }}
                headerText={"Generate Bulk Codes"}
                type={PanelType.medium}>
                <BulkCodeDetailView
                    refreshCodeList={() => fetchEventCodesList(true)}
                    eventId={eventId}
                    eventCodes={eventCodeList.map((item) => item.code)}
                    closePanel={() => {
                        setShowBulkCodePanel(false);
                    }}
                />
            </Panel>
        </>
    );
};

export default EventCodesView;

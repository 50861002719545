import { toast } from "react-toastify";
import { ApiConstants } from "../../../../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../../../../constants/JambarDateUtils";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../../utils/APIErrorMessages";
import { Client } from "../../../../../Base/Client";
import { AttachmentListType } from "./Type";

const transformData = (data: any): AttachmentListType[] => {
    if (!data) {
        return [];
    }

    return data.map((attachment: any, index: number) => ({
        id: attachment.id,
        title: attachment.title,
        createdBy: `${attachment.createdByUser.firstName} ${attachment.createdByUser.lastName}`,
        createdDate: JambarDateUtil.formatDate(attachment.createdAt),
        lastUpdatedBy: `${attachment.updatedByUser.firstName} ${attachment.updatedByUser.lastName}`,
        lastUpdatedDate: JambarDateUtil.formatDate(attachment.updatedAt),
        index: index + 1
    }));
};

export const getGameAttachment = (gameId: string, forceRefresh = false) => {
    return Client.getInstance()
        .getData(ApiConstants.getAllQuizAttachmentsApiUrl(parseInt(gameId)), forceRefresh)
        .then((response) => {
            return Promise.resolve(transformData(response.data.data));
        })
        .catch(() => {
            return Promise.reject();
        });
};

export const deleteAttachment = (gameId: string, attachmentId: string) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteQuizAttachmentApiUrl(parseInt(gameId), parseInt(attachmentId)))
        .then(() => Promise.resolve())
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });

import Styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";

export const LobbyTitle = Styled(Typography)`
    text-align: center;
    font: normal normal bold 20px/27px Nunito;
    letter-spacing: 0px;
    color: #1C1C1C;
    opacity: 1;
`;

export const LobbyDescription = Styled(Typography)`
    text-align: left;
    font: normal normal normal 16px/22px Nunito;
    letter-spacing: 0px;
    color: #1C1C1C;
    opacity: 1;
`;

export const LobbyButton = Styled(Button)`
    background: #13204A 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    text-align: left;
    height: 38px;
    font: normal normal normal 16px/18px Nunito;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    width: 120px;
    text-transform: capitalize;
`;

import { useMemo } from "react";
import BrowserCheckView from "./BrowserCheck/BrowserCheckView";
import ConfigsCheck from "./ConfigsCheck/ConfigsCheck";
import { initialSetupInfoWizard } from "./data";

export const useShowWizard = (moveToNextStep: () => void, activeStep: number) =>
    useMemo(() => {
        if (activeStep === 1) {
            return <BrowserCheckView />;
        }
        if (activeStep === 2) {
            return (
                <ConfigsCheck
                    body={initialSetupInfoWizard.wifiConnection.body}
                    activeStep={activeStep}
                    title={initialSetupInfoWizard.wifiConnection.title}
                    iconName={initialSetupInfoWizard.wifiConnection.iconName}
                    moveToNextScreen={moveToNextStep}
                />
            );
        }

        if (activeStep === 3) {
            return (
                <ConfigsCheck
                    body={initialSetupInfoWizard.closeApplications.body}
                    activeStep={activeStep}
                    title={initialSetupInfoWizard.closeApplications.title}
                    iconName={initialSetupInfoWizard.closeApplications.iconName}
                    moveToNextScreen={moveToNextStep}
                />
            );
        }

        if (activeStep === 4) {
            return (
                <ConfigsCheck
                    body={initialSetupInfoWizard.closeCommunication.body}
                    activeStep={activeStep}
                    title={initialSetupInfoWizard.closeCommunication.title}
                    iconName={initialSetupInfoWizard.closeCommunication.iconName}
                    moveToNextScreen={moveToNextStep}
                />
            );
        }
    }, [activeStep]);

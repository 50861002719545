import { GameQuestionListItem } from "../../Type";

export const getFilteredQuestionList = (
    questionList: GameQuestionListItem[],
    filterTags: string,
    searchText: string
): any => {
    if (searchText === "" && filterTags === "all") {
        return questionList;
    }
    const filteredData =
        filterTags === "all"
            ? questionList
            : questionList.filter((item) => item.tags.find((item) => item.value === filterTags));
    return filterDataBySearchText(searchText, filteredData);
};

export const filterDataBySearchText = (searchText: string, rows: GameQuestionListItem[]) => {
    if (searchText === "") {
        return rows;
    }

    return rows.filter(
        (row: GameQuestionListItem) =>
            row.questionTitle.toLowerCase().includes(searchText.toLowerCase()) ||
            row.createdBy.toLowerCase().includes(searchText.toLowerCase())
    );
};

import { Grid, Typography, Button } from "@mui/material";
import Styled from "@emotion/styled";
import {
    backgroundContainerColor,
    captionTextColor,
    correctAnswerColor,
    hoverPrimaryButtonColor,
    primaryTextColor,
    quizResultTextColor,
    secondaryBackgroundColor,
    secondaryTextColor,
    wrongAnswerColor
} from "./colorConstant";

type OptionButtonProps = {
    selectiontype: "correct" | "wrong" | "neutral";
};

export const OptionButton = Styled.div<OptionButtonProps>`
    background: ${backgroundContainerColor} 0% 0% no-repeat padding-box;
    &:hover{
        background: ${secondaryBackgroundColor} 0% 0% no-repeat padding-box;
        font-weight: bold;
    }
    ${(props) =>
        props.selectiontype === "correct" &&
        `
        background: ${correctAnswerColor};
        border: 1px solid ${correctAnswerColor};
        &:hover{
        background: ${correctAnswerColor} 0% 0% no-repeat padding-box;
        font-weight: bold;
    }
    `}
    ${(props) =>
        props.selectiontype === "wrong" &&
        `
        border: 3px solid ${wrongAnswerColor};
    `}
    border-radius: 5px;
    cursor: pointer;
    margin-top:10px;
    padding: 10px;
    padding-left: 20px;
    
`;

export const QuestionContainer = Styled(Grid)`
    border: 1px solid #D7D7D747;
    border-radius: 10px;
    background: #D7D7D747;
    padding: 30px;
`;

export const ImageContainer = Styled.img`
    opacity: 1;
    border-radius: 5px;
`;

export const PrimaryText = Styled(Typography)`
    color: ${primaryTextColor};
    font-size: 16px;
    font-weight: 600;
`;

export const SecondaryText = Styled(Typography)`
    color: ${secondaryTextColor};
    font-size: 16px;
    font-weight: bold;
`;

export const CaptionText = Styled(Typography)`
    color: ${captionTextColor};
    font-size: 14px;
    line-height: 1.75;
`;

export const StartQuizButton = Styled(Button)`
    background-color: ${correctAnswerColor};
    text-transform: none;
    color: #ffff;
    font-weight: bold;
    padding: 8px 30px;
    &:hover {
        background-color: ${hoverPrimaryButtonColor};
    }
`;

type AnswerDescriptionProps = {
    selectiontype: "correct" | "wrong";
};
export const AnswerDescription = Styled(Grid) <AnswerDescriptionProps>`
    margin-top: 10px;
    background-color: ${(props) =>
        props.selectiontype === "correct" ? `${correctAnswerColor}` : `${wrongAnswerColor}`};
    padding: 10px;
    padding-left: 20px;
    border-radius: 5px;
`;

export const AnswerDescriptionText = Styled(Typography)`
    color: #ffff;
    font-size: 14px;
`;

export const AnswerDescriptionTitleText = Styled(Typography)`
    color: #ffff;
    font-size: 20px;
`;

export const QuizEndContainer = Styled(Grid)`
    min-height: 100vh;
`;

export const QuizResultContainer = Styled(Grid)`
    background-color: #ffff;
    padding: 60px;
    border-radius: 5px;
`;

export const QuizResultTitleText = Styled(Typography)`
    color: ${quizResultTextColor};
    font-weight: bold !important;
    font-size: 20px;
`;

export const GameEndButton = Styled(Typography)`
    text-transform: none !important;
    font-weight: bold !important;
    color: #000 !important;
    width: 100%;
    text-align: center;
`;

import { Grid } from "@mui/material";
import { FC } from "react";
import { useHistory } from "react-router";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { useEventListHook } from "./Hooks";
import { EventDeleteType, EventListItem } from "./Type";

type EventListProps = {
    eventList: EventListItem[];
    isLoading: boolean;
    setSelectedCloneItem: (eventItem: EventListItem) => void;
    setSelectedEventDeleteId: (id: EventDeleteType) => void;
    searchedText: string;
    setSelectedEventForUpdateLiveStatus: (eventItem: EventListItem) => void;
    selectedTab: string;
};
const EventList: FC<EventListProps> = ({
    eventList,
    isLoading,
    setSelectedCloneItem,
    setSelectedEventDeleteId,
    searchedText,
    setSelectedEventForUpdateLiveStatus,
    selectedTab
}) => {
    const { eventListColumns } = useEventListHook();
    const history = useHistory();

    const handleEdit = (id: number) => {
        history.push(`/admin/event/${id}/update${searchedText !== "" ? `?searchtext=${searchedText}` : ""}`);
    };

    const handleInfo = (id: number) => {
        history.push(`/admin/event/${id}/info${searchedText !== "" ? `?searchtext=${searchedText}` : ""}`);
    };

    return (
        <Grid height={"calc(100vh - 303px)"}>
            <DataGridWrapper
                columns={eventListColumns(
                    setSelectedCloneItem,
                    setSelectedEventDeleteId,
                    handleEdit,
                    handleInfo,
                    setSelectedEventForUpdateLiveStatus
                )}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            liveEvent: ["upcoming", "systemPublic"].includes(selectedTab)
                        }
                    }
                }}
                key={selectedTab}
                rows={eventList as any}
                loading={isLoading}
            />
        </Grid>
    );
};

export default EventList;

import { Dialog, Grid } from "@mui/material";
import { FC } from "react";
import { CaptionText, Header1, PrimaryButton, PrimaryOutlinedButton, Wrapper } from "./CommonStyle";
import CloseIcon from "../../Images/icons/close.svg";
import { deleteColor, linkColor } from "../../../constants/ColorConstant";
import LoadingButtonWrapper from "./button/LoadingButtonWrapper";

type ConfirmationBoxProps = {
    title: string;
    message: string;
    onConfirm: () => void;
    onClose: () => void;
    isLoading?: boolean;
    type?: "delete" | "clone" | "update";
};
const ConfirmationBox: FC<ConfirmationBoxProps> = ({
    title,
    message,
    onConfirm,
    onClose,
    type = "delete",
    isLoading
}) => {
    return (
        <Dialog
            PaperProps={{
                style: {
                    padding: "40px"
                }
            }}
            maxWidth={"sm"}
            fullWidth
            onClose={onClose}
            open>
            <Wrapper>
                <Grid container justifyContent={"flex-end"}>
                    <img onClick={onClose} src={CloseIcon} style={{ height: 20 }} />
                </Grid>
                <Header1 fontsize="24px" textAlign={"center"}>
                    {title}
                </Header1>
                <CaptionText textAlign={"center"}>{message || "Are you sure you want to delete"}</CaptionText>
                <Grid container justifyContent={"center"} spacing={"30px"}>
                    <Grid item>
                        <LoadingButtonWrapper loading={!!isLoading}>
                            <PrimaryButton
                                fontsize="16px"
                                background={type === "delete" ? deleteColor : linkColor}
                                onClick={onConfirm}
                                variant={"contained"}>
                                Confirm
                            </PrimaryButton>
                        </LoadingButtonWrapper>
                    </Grid>
                    <Grid item>
                        <PrimaryOutlinedButton fontsize="16px" onClick={onClose} variant={"text"}>
                            Cancel
                        </PrimaryOutlinedButton>
                    </Grid>
                </Grid>
            </Wrapper>
        </Dialog>
    );
};

export default ConfirmationBox;

import { Drawer, Grid } from "@mui/material";
import { Formik } from "formik";
import { FC } from "react";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { Header1 } from "../../../../CommonComponent/v2/CommonStyle";
import TabWrapper from "../../../../CommonComponent/v2/tab/Tab";
import { UserDetailType } from "../Type";
import ChangePassword from "./ChangePassword";
import { useUserDrawerHook } from "./Hooks";
import UserDetailForm from "./UserDetailForm";
import UserRole from "./UserRole";
import { addUser, updateUser, userDetailFormInitialValues, UserDetailValidationSchema } from "./Util";

type UserDetailDrawerProps = {
    closeDrawer: () => void;
    selectedUserId: number;
    openModalForEdit?: (userId: number) => void;
    refreshUserList: () => void;
};
const UserDetailDrawer: FC<UserDetailDrawerProps> = ({
    closeDrawer,
    selectedUserId,
    openModalForEdit,
    refreshUserList
}) => {
    const { selectedTab, setSelectedTab, tabs, IS_SUPER_ADMIN } = useUserDrawerHook(selectedUserId);
    const saveIdentity = (values: UserDetailType) => {
        if (selectedUserId === -1) {
            addUser(values)
                .then((id: number) => {
                    toast.success("User added", SiteConstants.successToastConfiguration);
                    openModalForEdit && openModalForEdit(id);
                    refreshUserList();
                })
                .catch((err: any) => {
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                });
            return;
        }
        selectedUserId &&
            updateUser(values, selectedUserId)
                .then(() => {
                    toast.success("User Updated", SiteConstants.successToastConfiguration);
                    refreshUserList();
                    closeDrawer();
                })
                .catch((err: any) => {
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                });
    };

    const renderForm = () => {
        if (selectedTab === "userDetails") {
            return (
                <Formik
                    initialValues={userDetailFormInitialValues}
                    validationSchema={UserDetailValidationSchema}
                    onSubmit={saveIdentity}
                    enableReinitialize
                    validateOnMount={false}>
                    {() => {
                        return <UserDetailForm selectedUserId={selectedUserId} />;
                    }}
                </Formik>
            );
        }
        if (selectedTab === "roles") {
            return <UserRole userId={selectedUserId} />;
        }
        if (selectedTab === "changePassword") {
            return <ChangePassword selectedUserId={selectedUserId} />;
        }
    };
    return (
        <Drawer
            anchor={"right"}
            open
            PaperProps={{
                style: {
                    width: "50%"
                }
            }}
            onClose={closeDrawer}>
            <Grid p={"20px"}>
                <Header1>
                    {selectedUserId !== -1
                        ? `Edit ${IS_SUPER_ADMIN ? "Client" : "User"}`
                        : `Add new ${IS_SUPER_ADMIN ? "Client" : "User"}`}
                </Header1>
                {selectedUserId !== -1 && (
                    <Grid container spacing={"20px"} mt={"20px"}>
                        <Grid item xs={12}>
                            <TabWrapper tabsOptions={tabs} selectedTab={selectedTab} handleChange={setSelectedTab} />
                        </Grid>
                    </Grid>
                )}
                {renderForm()}
            </Grid>
        </Drawer>
    );
};

export default UserDetailDrawer;

import Styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const HeaderTitle = Styled(Typography)`
    font-size: 16px;
    color: #ffff;
    font-weight: bold;
    line-height: 20px;
`;

export const MenuTitle = Styled(HeaderTitle)`
    font-weight: normal;
`;

type AvatarIconContainerProps = {
    bgColor: string;
};

export const AvatarIconContainer = Styled.div<AvatarIconContainerProps>`
    height: 40px;
    width: 40px;
    padding: 10px;
    border-radius: 50%;
    background: ${(props) => props.bgColor};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MenuContainer = Styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
`;

/* eslint-disable jsx-a11y/iframe-has-title */
import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { VideoTypeOptions } from "../../../../../../constants/DropdownOption";
import { UPLOAD_VIDEO_TYPE } from "../../../../../../constants/SiteConstant";
import { youtubeParser } from "../../../../../../utils/JambarUtils";
import FormikRadioGroup from "../../../../../CommonComponent/v2/fields/FormikRadioGroup";
import FormikTextInput from "../../../../../CommonComponent/v2/fields/FormikTextInput";
import VideoDrop from "../../../../../CommonComponent/v2/fields/VideoDrop";
import { QuestionDetailType } from "../Type";
import { CaptionText } from "../../../../../CommonComponent/v2/CommonStyle";
import { darkPrimaryColor } from "../../../../../../constants/ColorConstant";

const VideoQuestion = () => {
    const { values } = useFormikContext();
    const data = values as QuestionDetailType;

    const renderVideoType = () => {
        if (data.questionDetails.videoQuestionType === UPLOAD_VIDEO_TYPE) {
            return (
                <VideoDrop
                    name="questionDetails.uploadVideo"
                    selectedFile={data.questionDetails.uploadVideo}
                    videoSize={40}
                    logoInfo={"Attach video for the challenge We recommend 720p - 1080p Max Size 40MB"}
                />
            );
        }
        return (
            <Grid container>
                <Grid item xs={12}>
                    <FormikTextInput
                        name="questionDetails.youtubeVideoLink"
                        label="Youtube video link"
                        id="questionDetails.youtubeVideoLink"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} mt={"10px"}>
                    {data.questionDetails.youtubeVideoLink && (
                        <iframe
                            allowFullScreen
                            allow="accelerometer"
                            width="302"
                            frameBorder={0}
                            style={{ borderRadius: 10 }}
                            height="170"
                            src={`https://www.youtube.com/embed/${youtubeParser(
                                data.questionDetails.youtubeVideoLink
                            )}`}
                        />
                    )}
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container mt={"30px"}>
            <Grid item xs={12}>
                <CaptionText fontWeight={700} color={darkPrimaryColor}>
                    Cover video
                </CaptionText>
            </Grid>
            <Grid item xs={12}>
                <FormikRadioGroup row name="questionDetails.videoQuestionType" radioOptions={VideoTypeOptions} />
            </Grid>

            <Grid item xs={12}>
                {renderVideoType()}
            </Grid>
        </Grid>
    );
};

export default VideoQuestion;

export const getOffsetColor = (hex: string) => {
    const tempHex = hex.replace("#", "");
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);
    return `rgba(${r},${g},${b},0.80)`;
};

export const getWebCamStatus = (setStatus: (value: boolean) => void) => {
    navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(() => {
            setStatus(true);
        })
        .catch(() => {
            setStatus(false);
        });
};

export const getMediaDevicesStatus = (setStatus: (value: boolean) => void) => {
    navigator.mediaDevices.enumerateDevices().then((devices: MediaDeviceInfo[]) => {
        setStatus(true);
        return devices.filter((item) => item.kind === "videoinput").length > 1;
    });
};

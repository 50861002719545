import { array, date, lazy, object, string } from "yup";
import { EventType, GameOptionType } from "./Type";
import VirtualUnlinked from "../../../../Images/icons/virtualunlinked.svg";
import Virtuallinked from "../../../../Images/icons/virtuallinked.svg";
import WildgoatIcon from "../../../../Images/icons/wildgoat.svg";
import { Client } from "../../../../Base/Client";
import { ApiConstants } from "../../../../../constants/ApiConstant";
import {
    checkIfFilesAreCorrectType,
    checkIfFilesAreTooBig,
    extractHtmlContent
} from "../../../../../utils/JambarUtils";
import { StepperType } from "../../../../CommonComponent/v2/stepper/Type";
import ActiveVirtualLinkedIcon from "../../../../Images/icons/virtualLinkedActive.svg";
import WildgoatInActiveIcon from "../../../../Images/icons/wildgoatActive.svg";
import VirtualUnlinkedActive from "../../../../Images/icons/virtualUnlinkedActive.svg";
import { SiteConstants, VALID_IMAGE } from "../../../../../constants/SiteConstant";
import moment from "moment";
import { BreadCrumbType } from "../../../../CommonComponent/v2/Type";
import WildgoatDisabledIcon from "../../../../Images/icons/wildgoatdisabled.svg";
import VirtualLinkedDisabledIcon from "../../../../Images/icons/virtuallinkeddisabled.svg";
import VirtualUnLinkedDisabledIcon from "../../../../Images/icons/virtualUnlinkedDisabled.svg";

const getInitialGameTypeAccordingToPermission = (
    permissionQuizFullGame,
    permissionQuizSocketGame,
    permissionQuizPhysicalGame
) => {
    return (
        gameOptions(
            permissionQuizFullGame?.includes("fetch"),
            permissionQuizSocketGame?.includes("fetch"),
            permissionQuizPhysicalGame?.includes("fetch")
        ).filter((item) => item.toShow)?.[0]?.value || "fullgame"
    );
};

export const eventDetailInitialValues = ({
    eventDetails,
    permissions
}: {
    permissions?: {
        permissionQuizFullGame: string[];
        permissionQuizSocketGame: string[];
        permissionQuizPhysicalGame: string[];
    };
    eventDetails?: EventType;
}): EventType => {
    return {
        isPublic: !!eventDetails?.isPublic,
        gameType:
            eventDetails?.gameType ||
            getInitialGameTypeAccordingToPermission(
                permissions?.permissionQuizFullGame,
                permissions?.permissionQuizSocketGame,
                permissions?.permissionQuizPhysicalGame
            ),
        eventLink: eventDetails?.eventLink || "",
        startDate: eventDetails?.startDate
            ? moment(eventDetails.startDate).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        endDate: eventDetails?.endDate
            ? moment(eventDetails.endDate).format("YYYY-MM-DD")
            : moment().add(14, "days").format("YYYY-MM-DD"),
        linkName: eventDetails?.linkName || "",
        eventName: eventDetails?.eventName || "",
        noOfTeams: eventDetails?.noOfTeams || "",
        gameId: eventDetails?.gameId || "",
        isClosed: eventDetails?.isClosed || false,
        generatedLink: eventDetails?.generatedLink || "",
        leaderBoardLink: eventDetails?.leaderBoardLink || "",
        isDemo: eventDetails?.isDemo || false,
        isMini: eventDetails?.isMini || false,
        referenceLinkFirst: eventDetails?.referenceLinkFirst || "",
        referenceLinkSecond: eventDetails?.referenceLinkSecond || "",
        showSurvey: eventDetails?.showSurvey || false,
        participantFilesLink: eventDetails?.participantFilesLink || "",
        clientLogo: eventDetails?.clientLogo || [],
        companyLogo: eventDetails?.companyLogo || []
    };
};

export const eventValidationSchema = object().shape(
    {
        startDate: date().typeError("Please select a valid date").required("From date is mandatory"),
        endDate: date()
            .when(
                "startDate",
                (startDate: Date, schema: any) =>
                    startDate && schema.min(startDate, "To date should be greater than From date")
            )
            .typeError("Please select a valid date")
            .required("To date is mandatory"),
        eventName: string()
            .required("Event name is required")
            .matches(/^[^/]*$/, 'This field should not contain a "/" character'),
        linkName: string().required("Event link is required"),
        gameId: string().required("Game is mandatory"),
        clientLogo: lazy((val) =>
            Array.isArray(val)
                ? array()
                      .min(1, "Please select logo")
                      .test(
                          "is-big-file",
                          "Image must be less then 2MB",
                          (value) => !value || checkIfFilesAreTooBig(value, 8)
                      )
                      .test(
                          "is-correct-file",
                          "Please upload image only",
                          (value) => !value || checkIfFilesAreCorrectType(value, VALID_IMAGE)
                      )
                : string()
        ),
        companyLogo: lazy((val) =>
            Array.isArray(val)
                ? array()
                      .max(1, "Please select logo")
                      .test(
                          "is-big-file",
                          "Image must be less then 2MB",
                          (value) => !value || checkIfFilesAreTooBig(value, 8)
                      )
                      .test(
                          "is-correct-file",
                          "Please upload image only",
                          (value) => !value || checkIfFilesAreCorrectType(value, VALID_IMAGE)
                      )
                : string()
        )
    },
    [["startDate", "endDate"]]
);

export const gameOptions = (
    showVirtualUnliked = true,
    showSocketQuiz = true,
    showPhysical = true
): GameOptionType[] => [
    {
        id: 1,
        title: "Virtual Unlinked",
        value: SiteConstants.spaceGameQuiz,
        icon: <img alt={"game"} src={VirtualUnlinkedActive} style={{ height: 60 }} />,
        inActiveicon: <img alt={"game"} src={VirtualUnlinked} style={{ height: 60 }} />,
        isDisabledIcon: <img alt={"game"} src={VirtualUnLinkedDisabledIcon} style={{ height: 60 }} />,
        toShow: showVirtualUnliked
    },
    {
        id: 2,
        title: "Virtual Linked",
        value: SiteConstants.socketQuiz,
        isDisabledIcon: <img alt={"game"} src={VirtualLinkedDisabledIcon} style={{ height: 60 }} />,
        icon: <img alt={"game"} src={Virtuallinked} style={{ height: 60 }} />,
        inActiveicon: <img alt={"game"} src={ActiveVirtualLinkedIcon} style={{ height: 60 }} />,
        toShow: showSocketQuiz
    },
    {
        id: 3,
        title: "Physical",
        value: SiteConstants.wildgoat,
        isDisabledIcon: <img alt={"game"} src={WildgoatDisabledIcon} style={{ height: 60 }} />,
        icon: <img alt={"game"} src={WildgoatIcon} style={{ height: 60 }} />,
        inActiveicon: <img alt={"game"} src={WildgoatInActiveIcon} style={{ height: 60 }} />,
        toShow: showPhysical
    }
];

const transformGameOptions = (gameOptions: any) => {
    if (!gameOptions) {
        return [];
    }
    return gameOptions.map((game: any) => ({
        value: game.id,
        type: game.type,
        label: extractHtmlContent(game.title)
    }));
};

export const getGameList = () =>
    Client.getInstance()
        .getData(ApiConstants.getAllQuizzesApiUrl("active"), true)
        .then((response) => Promise.resolve(transformGameOptions(response.data.data)));

export const getGameLabel: Record<string, string> = {
    fullgame: "Virtual Unlinked",
    simplesocket: "Virtual Linked",
    wildgoat: "Physical"
};

export const addEventSteps: StepperType[] = [
    {
        label: "Event Design",
        description: "Design your event"
    },
    {
        label: "Event Type",
        description: "Type of event"
    },
    {
        label: "Event URL",
        description: "Generate links to launch & more"
    }
];

export const editEventBreadCrumbValues = (id: string): BreadCrumbType[] => [
    {
        label: "Event Manager",
        url: "/admin/event"
    },
    {
        label: "Events",
        url: `/admin/event/${id}/info`
    },
    {
        label: "Edit event"
    }
];

const tranformReferenceOptions = (data: any) => {
    if (!data) {
        return [];
    }
    return data.map((element: any) => ({
        value: element.id,
        label: element.name,
        type: element.referenceLink
    }));
};

export const getReferenceList = () =>
    Client.getInstance()
        .getData(ApiConstants.getReferenceListApiUrl(), false)
        .then((res) => Promise.resolve(tranformReferenceOptions(res.data.data)))
        .catch((err) => Promise.reject(err));

export const getFile = (file: File[] | string | null) => {
    if (!file) {
        return null;
    }
    if (typeof file === "string") {
        return file;
    }
    return file[0];
};

export const getReferenceListIds = (referenceOne: string, referenceTwo: string) => {
    const data = [];
    referenceOne && data.push(referenceOne);
    referenceTwo && data.push(referenceTwo);
    return data.join(";");
};

export const reverseTransformData = (values: EventType) => {
    const eventData = new FormData();
    values.clientLogo && values.clientLogo.length !== 0 && eventData.append("eventImage", values.clientLogo[0] as Blob);
    values.companyLogo &&
        values.companyLogo.length !== 0 &&
        eventData.append("companyLogo", values.companyLogo[0] as Blob);
    const data: any = {
        quizId: values.gameId,
        teamCount: values.noOfTeams,
        eventStartDate: moment(values.startDate).format("YYYY-MM-DD"),
        eventEndDate: moment(values.endDate).format("YYYY-MM-DD"),
        name: values.eventName,
        isDemo: values.isDemo,
        isPublic: values.isPublic ? true : false,
        type: values.isMini ? "mini" : "regular",
        showSurveyAtEnd: values.showSurvey,
        leaderboardLink: values.linkName.split(" ").join("_"),
        participantFilesLink: values.linkName.split(" ").join("_"),
        eventLink: values.linkName.split(" ").join("_"),
        referenceList: getReferenceListIds(values.referenceLinkFirst, values.referenceLinkSecond)
    };

    for (const key in data) {
        eventData.append(key, data[key]);
    }

    return eventData;
};

export const getAddNewEvent = (values: EventType) =>
    Client.getInstance()
        .createData(ApiConstants.addEventApiUrl(), reverseTransformData(values))
        .then((res) =>
            Promise.resolve({
                id: res.data.data.id,
                eventLink: res.data.data.eventLink,
                type: res.data.data.isPublic ? "publicEvent" : res.data.data.type
            })
        )
        .catch(() => Promise.reject());

export const checkLinkStatusAction = async (linkName: string) => {
    await Promise.all([
        Client.getInstance().getData(ApiConstants.checkLinkAvailability(linkName), true),
        Client.getInstance().getData(ApiConstants.checkLeaderboardLinkAvailability(linkName), true),
        Client.getInstance().getData(ApiConstants.checkParticipantFileLinkAvailability(linkName), true)
    ])
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());
};

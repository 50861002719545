import Styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const StyledTextField = Styled(TextField)`
    & .MuiOutlinedInput-root{
        background: #FCFCFC;
        border-radius: 10px;
    }
    fieldset {
        legend {
            width: unset !important;
        }
    }
`;

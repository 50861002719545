import { Stack } from "@fluentui/react";
import React from "react";
import { ThemedMediumStackTokens, ThemedSmall2StackTokens } from "../../../../constants/Styles";
import logo from "../../../Images/logo.svg";

const ReferenceItemErrorPage = () => {
    return (
        <div className={"event-main-container"}>
            <Stack horizontalAlign={"center"} verticalAlign={"center"} className={"height-100per"}>
                <Stack className={"event-parent"} tokens={ThemedMediumStackTokens}>
                    <Stack horizontalAlign={"center"} className={"event-logo"}>
                        <img src={logo} />
                    </Stack>
                    <Stack tokens={ThemedMediumStackTokens} className={"event-child"}>
                        <Stack tokens={ThemedSmall2StackTokens}>
                            <span className={"font-size-20 font-weight-bold"}>Hello,</span>
                            <span className={"font-size-20"}>Welcome to Jambar's Virtual Team Building Event</span>
                            <span className={"text-align-center font-size-16 error-message-font-color"}>
                                Could not find a reference list, please check the link again.
                            </span>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </div>
    );
};

export default ReferenceItemErrorPage;

export type MCQAnswerOption = {
    id: number;
    text: string;
    isCorrect: boolean;
};
export type QuestionTags = {
    id?: number;
    key?: number;
    value: string;
    label?: string;
    type?: string;
};
export type questionInfo = {
    itemId: number;
    imagePath: string;
    questionType: string;
    questionText: string;
    correctAnswer: string;
    mcqAnswerOptions?: MCQAnswerOption[];
    freeTextCorrectAnswerType?: string;
    tags: QuestionTags[];
    timeLimit: string;
    wrongPoints: number;
    wrongAnswerText?: string;
    skipPoints: number;
    maxAttempts: number;
    correctPoints: number;
    correctAnswerText?: string;
    specifyAnswerText: boolean;
    skippingAllowed: boolean;
    correctOptions: number[];
    questionTitle: string;
    isVideoQuestion?: boolean;
};
export type Question = {
    id: number;
    sr: number;
    accessibilityScope: string;
    title: string;
    gameType: string;
    tags: QuestionTags[];
    questionInfo: questionInfo;
    createdBy: string;
    createdDate: string;
    lastUpdatedBy: string;
    lastUpdatedDate: string;
    identityName: string;
};
export type Dropdown = {
    id: number;
    key: number;
    value: string;
};
export type QuestionDeleteType = {
    title: string;
    id: number;
};
export type QuestionCloneType = {
    id: number;
    title: string;
};
export type Info = {
    id: number;
    title: string;
};

export enum FILTER_OPTION_NAME {
    CHANGE_VISIBILITY = "changeVisibility",
    ADD_TAGS = "addTags",
    REMOVE_TAGS = "removeTags"
}
export type SelectedFilterType = {
    selectedFilterName: string;
    selectedFilterValue: string | string[];
    identityFilterOption?: string[];
};

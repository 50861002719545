import { Grid } from "@mui/material";
import { FC } from "react";
import { CaptionText, IconContainer } from "../../../../../CommonComponent/v2/CommonStyle";
import CopyIcon from "../../../../../Images/icons/copy.svg";
import QrIcon from "../../../../../Images/icons/qr.svg";
import DisableRocket from "../../../../../Images/icons/rocket.svg";
import EditIcon from "../../../../../Images/icons/edit.svg";
import QrCodeModal from "../../../../../CommonComponent/QrCodeModal";
import { useEventLinkHook } from "./Hooks";
import { EventInfoType } from "../Type";
import EyeIcon from "../../../../../Images/icons/eye.svg";
import { getEventUrl } from "../../util";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import PreviewAllLink from "../../addeventdetail/PreviewAllLink";

type Props = {
    eventInfo: EventInfoType;
    showEdit?: boolean;
};
const EventLink: FC<Props> = ({ eventInfo, showEdit = true }) => {
    const { qrCodeLink, setQrCodeLink, showPreviewModal, togglePreview } = useEventLinkHook();
    const {
        eventLink,
        isMini,
        leaderBoardLink,
        participantFilesLink,
        referenceLinkFirst,
        referenceLinkSecond,
        gameType,
        linkName,
        isPublic
    } = eventInfo;

    const renderActionButton = (url: string) => {
        return (
            <Grid container mt={"5px"} justifyContent={"center"} spacing={"20px"}>
                <Grid item>
                    <IconContainer
                        onClick={() => {
                            window.navigator.clipboard.writeText(url);
                        }}
                        src={CopyIcon}
                        style={{ height: 18 }}
                    />
                </Grid>
                <Grid item>
                    <IconContainer onClick={() => setQrCodeLink(url)} src={QrIcon} style={{ height: 18 }} />
                </Grid>
                <Grid item>
                    <IconContainer
                        onClick={() => window.open(url, "_blank")}
                        src={DisableRocket}
                        style={{ height: 18 }}
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid>
            <CaptionText color={"#333333"} fontWeight={700}>
                Event Url
                {showEdit && <IconContainer src={EditIcon} style={{ marginLeft: 10 }} />}
            </CaptionText>
            <CaptionText>
                You can find the links to launch the event, view participants’ files, leaderboards, and launch reference
                games. Links can be accessed from the events manager too.
            </CaptionText>
            <Grid container mt={"30px"} alignItems={"center"} spacing={"30px"}>
                <Grid item xs={4}>
                    <CaptionText textAlign={"center"}>Event link</CaptionText>
                    {renderActionButton(getEventUrl(isMini ? "mini" : isPublic ? "isPublic" : "full", eventLink))}
                </Grid>
                <Grid item xs={4}>
                    <CaptionText textAlign={"center"}>Leaderboard</CaptionText>
                    {renderActionButton(`${window.location.origin}/leaderboard/${leaderBoardLink}`)}
                </Grid>
                <Grid item xs={4}>
                    <CaptionText textAlign={"center"}>Participant files</CaptionText>
                    {renderActionButton(
                        `${window.location.origin}/participant/files?participantFilesLink=${
                            participantFilesLink || linkName
                        }`
                    )}
                </Grid>
                {![SiteConstants.wildgoat, SiteConstants.socketQuiz].includes(gameType) && (
                    <>
                        <Grid item xs={4}>
                            <CaptionText textAlign={"center"}>1st reference game</CaptionText>
                            {referenceLinkFirst ? (
                                renderActionButton(
                                    `${window.location.origin}/reflist/${eventLink}/${referenceLinkFirst}`
                                )
                            ) : (
                                <CaptionText textAlign={"center"}>Link not found</CaptionText>
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            <CaptionText textAlign={"center"}>2nd reference game</CaptionText>
                            {referenceLinkSecond ? (
                                renderActionButton(
                                    `${window.location.origin}/reflist/${eventLink}/${referenceLinkSecond}`
                                )
                            ) : (
                                <CaptionText textAlign={"center"}>Link not found</CaptionText>
                            )}
                        </Grid>
                    </>
                )}
                <Grid item xs={4}>
                    <CaptionText
                        style={{ cursor: "pointer" }}
                        onClick={togglePreview}
                        display={"flex"}
                        justifyContent={"center"}
                        textAlign={"center"}>
                        <IconContainer src={EyeIcon} style={{ height: 18 }} />
                        <span style={{ color: "#1F71B6", marginLeft: 10, cursor: "pointer" }}>Preview all links</span>
                    </CaptionText>
                </Grid>
            </Grid>
            {qrCodeLink && <QrCodeModal url={qrCodeLink} handleClose={() => setQrCodeLink("")} />}
            {showPreviewModal && (
                <PreviewAllLink
                    handleClose={togglePreview}
                    urlLink={{
                        eventUrl: getEventUrl(isMini ? "mini" : isPublic ? "isPublic" : "full", eventLink),
                        participantFilesUrl: `${window.location.origin}/participant/files?participantFilesLink=${participantFilesLink}`,
                        leaderboardUrl: `${window.location.origin}/leaderboard/${leaderBoardLink}`,
                        firstReferenceUrl: referenceLinkFirst
                            ? `${window.location.origin}/reflist/${eventLink}/${referenceLinkFirst}`
                            : "",
                        secondRefrenceUrl: referenceLinkSecond
                            ? `${window.location.origin}/reflist/${eventLink}/${referenceLinkSecond}`
                            : ""
                    }}
                    showReferenceLinks={![SiteConstants.wildgoat, SiteConstants.socketQuiz].includes(gameType)}
                />
            )}
        </Grid>
    );
};

export default EventLink;

import { Box, Button, Typography } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";

type ErrorToastProps = {
    handleBuyClick: () => void;
    handleCloseClick: () => void;
    errorText: string;
};
const ErrorToast = ({ handleBuyClick, handleCloseClick, errorText }: ErrorToastProps) => {
    return (
        <Box>
            <Typography>{errorText}</Typography>
            <Box display={"flex"} mt={"10px"} gap={"5px"}>
                <Button
                    sx={{
                        textTransform: "none",
                        bgcolor: "#fff",
                        ":hover": {
                            bgcolor: "#fff"
                        }
                    }}
                    fullWidth
                    onClick={handleBuyClick}
                    variant="text"
                    endIcon={<MdKeyboardArrowRight />}>
                    Buy more credits
                </Button>
                <Button
                    variant="text"
                    onClick={handleCloseClick}
                    sx={{
                        color: "#fff"
                    }}>
                    Close
                </Button>
            </Box>
        </Box>
    );
};

export default ErrorToast;

import React, { useState } from "react";
import { Stack } from "office-ui-fabric-react";
import { Icon } from "@fluentui/react";
import { VideoQuestionContainer } from "./style";

type SocketQuestionTextProps = {
    videoLocation: string;
    questionText: string;
    coverImage: string;
    bottomQuestionText: string;
    showDocumentSelected: (coverImage: string) => void;
    setIsVideoPlayed: () => void;
};

const SocketQuestionText: React.FC<SocketQuestionTextProps> = ({
    videoLocation,
    coverImage,
    questionText,
    bottomQuestionText,
    showDocumentSelected,
    setIsVideoPlayed
}) => {
    const [coverImageLoading, setCoverImageLoading] = useState(false);

    if (videoLocation) {
        return (
            <VideoQuestionContainer autoPlay muted onEnded={() => setIsVideoPlayed()} height={"100%"} width={"100%"} controls>
                <source src={videoLocation} type="video/mp4" />
            </VideoQuestionContainer>
        );
    }

    return (
        <>
            {questionText && (
                <div
                    className={"quizQuestionview quill-editor-text-align"}
                    dangerouslySetInnerHTML={{
                        __html: questionText
                    }}
                />
            )}
            {coverImage && (
                <Stack horizontalAlign={"center"}>
                    {coverImageLoading ? null : <div className={"coverImageLoader"} />}
                    <img
                        src={coverImage}
                        className={"question-cover-image cursor"}
                        alt={"coverimage"}
                        onClick={() => coverImage && showDocumentSelected(coverImage)}
                        onLoad={() => setCoverImageLoading(true)}
                    />
                    <Icon
                        iconName="ZoomIn"
                        className={"zoom-image"}
                        onClick={() => coverImage && showDocumentSelected(coverImage)}
                    />
                </Stack>
            )}

            <div
                className={"quizQuestionview quill-editor-text-align"}
                dangerouslySetInnerHTML={{
                    __html: bottomQuestionText || ""
                }}
            />
        </>
    );
};

export default SocketQuestionText;

import { Grid } from "@mui/material";
import React, { FC, useState } from "react";
import {
    OptionButton,
    QuestionContainer,
    ImageContainer,
    PrimaryText,
    SecondaryText,
    AnswerDescriptionText,
    AnswerDescriptionTitleText,
    AnswerDescription
} from "./style";
import { OptionType, QuestionType } from "./type";

type WebsiteGameQuestionProps = {
    questionDetails: QuestionType;
    questionNumber: number;
    setCorrectAnswerCount: () => void;
    totalQuestionCount: number;
};
const WebsiteGameQuestion: FC<WebsiteGameQuestionProps> = ({
    questionDetails,
    questionNumber,
    setCorrectAnswerCount,
    totalQuestionCount
}) => {
    const [selectedOption, setSelectedOption] = useState<OptionType | null>();

    return (
        <QuestionContainer>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={1}>
                    <SecondaryText variant={"h6"}>
                        {questionNumber + 1}/{totalQuestionCount}
                    </SecondaryText>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <PrimaryText style={{ marginBottom: 10 }}>{questionDetails.questionTitle}</PrimaryText>
                    <ImageContainer src={questionDetails.questionImageSrc} width={"100%"} />
                    {questionDetails.options.map((option) => (
                        <OptionButton
                            style={{ pointerEvents: selectedOption ? "none" : "visible" }}
                            selectiontype={
                                !selectedOption || selectedOption.id !== option.id
                                    ? selectedOption && option.isCorrect
                                        ? "correct"
                                        : "neutral"
                                    : selectedOption?.isCorrect
                                    ? "correct"
                                    : "wrong"
                            }
                            key={option.id}
                            onClick={() => {
                                option.isCorrect && setCorrectAnswerCount();
                                setSelectedOption(option);
                            }}>
                            {option.text}
                        </OptionButton>
                    ))}
                    {selectedOption && (
                        <AnswerDescription selectiontype={selectedOption.isCorrect ? "correct" : "wrong"}>
                            <AnswerDescriptionTitleText>
                                {selectedOption.isCorrect
                                    ? questionDetails.correctAnswerText
                                    : questionDetails.wrongAnswerText}{" "}
                            </AnswerDescriptionTitleText>
                            <AnswerDescriptionText>{questionDetails.answerDescription}</AnswerDescriptionText>
                        </AnswerDescription>
                    )}
                </Grid>
                <Grid item xs={12} sm={1} />
            </Grid>
        </QuestionContainer>
    );
};

export default WebsiteGameQuestion;

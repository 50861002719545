import { Grid } from "@mui/material";
import PathChallengeCodeInput from "./PathChallengeCodeInput";
import { usePathChallenge } from "./hook";
import LoadingWrapper from "../../../../CommonComponent/v2/LoadingWrapper";

const PathChallengeView = () => {
    const { eventDetails, eventLink, loading } = usePathChallenge();
    return (
        <Grid container>
            <LoadingWrapper isLoading={loading}>
                <Grid item xs={12}>
                    {eventDetails && (
                        <PathChallengeCodeInput
                            eventLink={eventLink}
                            eventInfo={eventDetails.event}
                            quizInfo={eventDetails.quiz}
                        />
                    )}
                </Grid>
            </LoadingWrapper>
        </Grid>
    );
};

export default PathChallengeView;

import { Label, Stack } from "@fluentui/react";
import React, { FC, useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { FieldValidation } from "../../../constants/FieldValidation";
import { ThemedSmall2StackTokens } from "../../../constants/Styles";
import CropImageModal from "./CropImageModal";
import { getCroppedImg, readFile } from "./Util";

type CropImageWrapperProps = {
    label: string;
    setErrorMessage: (message: string) => void;
    errorMessage: string;
    imgUrl: string;
    isMandatory?: boolean;
    setNewCropedFile: (file: File, croppedImage: string) => void;
};

const CropImageWrapper: FC<CropImageWrapperProps> = ({
    label,
    setNewCropedFile,
    errorMessage,
    setErrorMessage,
    imgUrl,
    isMandatory
}) => {
    const [imgsrc, setImgSrc] = useState<string>("");

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMessage("");
        if (!event.target.files || (event.target.files && event.target.files.length === 0)) {
            return;
        }
        if (!FieldValidation.imageTypeValidation(event.target.files[0].type)) {
            setImgSrc("");
            setErrorMessage("Please select an image file");
            return;
        }
        if (FieldValidation.imageSizeValidation(event.target.files[0].size)) {
            setImgSrc("");
            setErrorMessage("Image size should be less than 2 MB");
            return;
        }

        const file = event.target.files[0];
        readFile(file).then((res) => {
            setImgSrc(res);
        });
    };

    const showCroppedImage = useCallback(
        async (croppedAreaPixels) => {
            getCroppedImg(imgsrc, croppedAreaPixels)
                .then((croppedImage) => {
                    croppedImage && setNewCropedFile(croppedImage.file, croppedImage.imgSrc);
                    setImgSrc("");
                })
                .catch(() => {
                    setErrorMessage("Something went wrong please try again");
                });
        },
        [imgsrc]
    );

    return (
        <>
            <Stack tokens={ThemedSmall2StackTokens}>
                <Stack.Item>
                    <Label>{label}</Label>
                </Stack.Item>
                <Stack.Item>
                    <Form>
                        <Form.Group>
                            <Form.File
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event)}
                            />
                            {imgUrl === "" && isMandatory && errorMessage === "" && (
                                <span className={"error-message"}>Logo is mandatory</span>
                            )}
                            {errorMessage && <span className={"error-message"}>{errorMessage}</span>}
                        </Form.Group>
                    </Form>
                </Stack.Item>
                {imgsrc && (
                    <CropImageModal storeImage={showCroppedImage} imgSrc={imgsrc} handleClose={() => setImgSrc("")} />
                )}
            </Stack>
        </>
    );
};

export default CropImageWrapper;

import React from "react";
import { FREE_TEXT, MCQ, MCQAnswerOption } from "../../../data/question/types";
import QuizAnswerView from "./QuizAnswerView";

type QuestionAnswerType = {
    updateAnswerText: (answer: string) => void;
    options?: MCQAnswerOption[];
    type: string;
    goToNextQuestion: (answerSubmitted: boolean, userAttemptType: string, quizType?: string) => void;
    showContinueButton: boolean;
    questionTimeEnds: boolean;
    completedConnector: string[];
    answerHeader?: string;
    connectorQuizIsWorking?: boolean;
    setHintDisable: (hintUseType: boolean) => void;
    gameBackgroundColor: string;
    optionColor: string;
};

const RocketTemplateQuestionAnswerTypeCheck: React.FC<QuestionAnswerType> = ({
    updateAnswerText,
    options,
    type,
    goToNextQuestion,
    showContinueButton,
    questionTimeEnds,
    answerHeader,
    connectorQuizIsWorking,
    gameBackgroundColor,
    setHintDisable,
    optionColor
}) => {
    if (type === MCQ) {
        return (
            <QuizAnswerView
                options={options}
                updateAnswerText={(answer: string) => {
                    updateAnswerText(answer);
                }}
                type={MCQ}
                goToNextQuestion={goToNextQuestion}
                showSkipButton={showContinueButton}
                questionTimeEnds={questionTimeEnds}
                answerHeader={answerHeader}
                connectorQuizIsWorking={connectorQuizIsWorking}
                setHintDisable={setHintDisable}
                gameBackgroundColor={gameBackgroundColor}
                optionColor={optionColor}
            />
        );
    }
    if (type === FREE_TEXT) {
        return (
            <QuizAnswerView
                updateAnswerText={(answer: string) => {
                    updateAnswerText(answer);
                }}
                type={FREE_TEXT}
                goToNextQuestion={goToNextQuestion}
                showSkipButton={showContinueButton}
                questionTimeEnds={questionTimeEnds}
                answerHeader={answerHeader}
                connectorQuizIsWorking={connectorQuizIsWorking}
                setHintDisable={setHintDisable}
                gameBackgroundColor={gameBackgroundColor}
            />
        );
    }
    return <span>Wrong Answer type</span>;
};

export default RocketTemplateQuestionAnswerTypeCheck;

import { Grid } from "@mui/material";
import { FC } from "react";
import { useHistory, useParams } from "react-router";
import { whiteColor } from "../../../../../../constants/Styles";
import { CaptionText, TextButton } from "../../../../../CommonComponent/v2/CommonStyle";
import ConfirmationBox from "../../../../../CommonComponent/v2/ConfirmationBox";
import LoadingWrapper from "../../../../../CommonComponent/v2/LoadingWrapper";
import { GameButton } from "../../addeventdetail/Style";
import { gameOptions } from "../../addeventdetail/Util";
import { EventInfoType } from "../Type";
import EventHeader from "./EventHeader";
import EventInfo from "./EventInfo";
import { useEventDetailHook } from "./Hooks";

type EventDetailProps = {
    eventDetails?: EventInfoType;
    isLoading: boolean;
    refreshDetails: () => void;
};
const EventDetail: FC<EventDetailProps> = ({ eventDetails, isLoading, refreshDetails }) => {
    const {
        cloneEvent,
        onConfirmEventClone,
        toggleCloneEvent,
        closeEvent,
        toggleCloseEvent,
        onCloseEvent,
        permission
    } = useEventDetailHook(refreshDetails);
    const history = useHistory();
    const { id } = useParams<{
        id: string;
    }>();

    const selectedGameType = gameOptions().find((item) => item.value === (eventDetails?.gameType || ""));

    return (
        <Grid pb={"52px"}>
            <EventHeader
                isEventClosed={!eventDetails?.isClosed}
                closeEvent={toggleCloseEvent}
                cloneEvent={toggleCloneEvent}
                updateEvent={() => history.push(`/admin/event/${id}/update`)}
                showUpdateEvent={!!(permission && permission.includes("update"))}
                showClone={!!(permission && permission.includes("clone"))}
                showCloseEvent={!!(permission && permission.includes("delete"))}
            />
            <hr style={{ border: "1px solid #C2C2C2", margin: 13 }} />
            <LoadingWrapper isLoading={isLoading}>
                <Grid>
                    <Grid container mt={"20px"} spacing={"22px"}>
                        <Grid item>
                            <GameButton xs={12} textAlign={"center"} item isactive={true}>
                                {selectedGameType?.inActiveicon}
                                <CaptionText mt={"5px"} color={whiteColor} textAlign={"center"}>
                                    {selectedGameType?.title}
                                </CaptionText>
                            </GameButton>
                            <Grid mt={"30px"}>
                                <Grid container spacing={"20px"}>
                                    <Grid item xs={12}>
                                        <CaptionText color={"#333333"} fontWeight={700}>
                                            Created by
                                        </CaptionText>
                                        <CaptionText>
                                            {eventDetails?.createdUserDetail.firstName}{" "}
                                            {eventDetails?.createdUserDetail.lastName}
                                        </CaptionText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CaptionText color={"#333333"} fontWeight={700}>
                                            Updated by
                                        </CaptionText>
                                        <CaptionText>
                                            {eventDetails?.updatedUserDetail.firstName}{" "}
                                            {eventDetails?.updatedUserDetail.lastName}
                                        </CaptionText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            {eventDetails && <EventInfo showEdit={false} eventDetails={eventDetails} />}
                            <Grid item xs={10}>
                                <Grid container mt={"68px"} justifyContent={"center"}>
                                    <Grid item>
                                        <div style={{ display: "flex", gap: "20px" }}>
                                            {!!(permission && permission.includes("update")) && (
                                                <>
                                                    {!eventDetails?.isClosed && (
                                                        <TextButton
                                                            fontsize="16px"
                                                            onClick={() => history.push(`/admin/event/${id}/update`)}>
                                                            Edit all details
                                                        </TextButton>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </LoadingWrapper>
            {closeEvent && (
                <ConfirmationBox
                    title={`Do you want to close the event - ${eventDetails?.eventName}`}
                    message={`This will close the event and will not be able to edit the event details.`}
                    onConfirm={onCloseEvent}
                    onClose={toggleCloseEvent}
                />
            )}
            {cloneEvent && (
                <ConfirmationBox
                    title={`Duplicate ${eventDetails?.eventName} event?`}
                    message={"Please confirm if you really want to duplicate this event."}
                    onConfirm={onConfirmEventClone}
                    onClose={toggleCloneEvent}
                />
            )}
        </Grid>
    );
};

export default EventDetail;

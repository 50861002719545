import { ApiConstants } from "../../../../../constants/ApiConstant";
import { Client } from "../../../../Base/Client";
import { FileDataType } from "./type";

export const getEventFiles = (eventId: number): Promise<FileDataType[]> => {
    return new Promise<any>((resolve, reject) => {
        Client.getInstance()
            .getData(ApiConstants.getEventParticipantFileApiUrl(eventId))
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const deleteEventFiles = (fileId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteEventFileUpload(fileId))
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());

import { Dialog, Grid, Tab, Tabs } from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import { FC } from "react";
import { CaptionText, Header1 } from "../../../../../../CommonComponent/v2/CommonStyle";
import { darkColor } from "../../../../../../WebsiteGame/colorConstant";
import GenerateBulkCode from "../generatebulkcode";
import { BulkCodeInitialValue, bulkEventCodeValidation } from "../generatebulkcode/Util";
import SingleCodeForm from "../singlecode";
import { useAddNewCodeHook } from "./Hooks";
import { CodeTabContainer } from "./Style";
import { codeInitialValue, codeValidation } from "./Util";

type AddNewCodeProps = {
    handleClose: () => void;
    eventCodeList: string[];
    refreshList: () => void;
};
const AddNewCode: FC<AddNewCodeProps> = ({ handleClose, eventCodeList, refreshList }) => {
    const { codeTabs, selectedTab, setSelectedTab, addNewCode, addBulkCode, userName, loading } = useAddNewCodeHook();

    const renderView = () => {
        if (selectedTab === "single") {
            return (
                <Formik
                    initialValues={codeInitialValue()}
                    validationSchema={codeValidation}
                    onSubmit={(values) => {
                        addNewCode(
                            {
                                ...values,
                                endDate: moment(values.endDate).format("YYYY-MM-DD"),
                                startDate: moment(values.startDate).format("YYYY-MM-DD")
                            },
                            refreshList
                        );
                        return;
                    }}>
                    {() => <SingleCodeForm loading={loading} eventIdsList={eventCodeList} closeModal={handleClose} />}
                </Formik>
            );
        }

        return (
            <Formik
                initialValues={BulkCodeInitialValue}
                validationSchema={bulkEventCodeValidation}
                onSubmit={(values) => {
                    addBulkCode(values, refreshList, eventCodeList);
                }}>
                {() => <GenerateBulkCode loading={loading} handleClose={handleClose} />}
            </Formik>
        );
    };
    return (
        <Dialog open maxWidth={"lg"} fullWidth onClose={handleClose}>
            <Grid p={"40px"} container justifyContent={"center"}>
                <Grid item xs={8}>
                    <Header1 textAlign={"center"}>Add new code</Header1>
                    <CaptionText mt={"10px"} textAlign={"center"}>
                        Please choose whether you want to generate single code or in bulk. These codes will be used by
                        the event players to start playing the game.
                    </CaptionText>
                    <CodeTabContainer mt={"20px"}>
                        <Tabs centered value={selectedTab} onChange={(_, value: string) => setSelectedTab(value)}>
                            {codeTabs.map((item, index) => (
                                <Tab
                                    sx={{
                                        borderRadius: index === 0 ? "10px 0px 0px 10px" : "0px 10px 10px 0px"
                                    }}
                                    key={item.value}
                                    value={item.value}
                                    label={item.label}
                                />
                            ))}
                        </Tabs>
                    </CodeTabContainer>
                    <Grid item textAlign={"center"} mt={"40px"}>
                        <CaptionText color={darkColor} fontWeight={700}>
                            Generated by
                            <span style={{ fontWeight: 400, marginLeft: 30 }}>{userName}</span>
                        </CaptionText>
                    </Grid>
                    <Grid>{renderView()}</Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default AddNewCode;

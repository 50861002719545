import {
    Dropdown,
    IColor,
    IColumn,
    IconButton,
    IDropdownOption,
    Image,
    Label,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField
} from "@fluentui/react";
import React, { useState, useEffect } from "react";
import QuestionBankListView from "../../QuestionBank/QuestionBankListView";
import { Client } from "../../../Base/Client";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { toast, ToastContainer } from "react-toastify";
import { SiteConstants, WildGoatGame } from "../../../../constants/SiteConstant";
import { ADVANCE_TEXT_EDITOR_CLASS, BASIC_TEXT_EDITOR_CLASS, QuestionTags } from "../../../../data/question/types";
import { ThemedMediumStackTokens, ThemedSmall2StackTokens } from "../../../../constants/Styles";
import TextFieldWrapper from "../../../CommonComponent/TextFieldWrapper";
import ToggleFieldWrapper from "../../../CommonComponent/ToggleFieldWrapper";
import "./QuizDetailView.scss";
import TextEditor from "../../../CommonComponent/TextEditor";
import { QuizQuestionListItem } from "../QuizListDataTypes";
import QuizAttachmentListView from "./QuizAttachmentListView";
import * as yup from "yup";
import { useFormik } from "formik";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { Form } from "react-bootstrap";
import FixedHeaderSortingDetailsList from "../../../CommonComponent/FixedHeaderSortingDetilsList";
import { convertAlphaToHexadecimal, extractHtmlContent } from "../../../../utils/JambarUtils";
import ColorPickerWrapper from "../../../CommonComponent/ColorPickerWrapper";
import DropdownWrapper from "../../../CommonComponent/DropdownWrapper";
import { quizTypeDropdownOptions, visibiltyTypeOptions } from "../../../../constants/DropdownOption";
import QuestionDetailView from "../../QuestionBank/QuestionDetailPanel/QuestionDetailView";
import { DotContainer } from "./Style";
import { getAllIdentitiesList } from "../../Users/Identites/Action";
import { IdentityListtype } from "../../Users/Identites/Type";
import { useAdminContext } from "../../context/AdminAuthContext";
import { dangerColorLight } from "../../../../constants/ColorConstant";

type IQuestionDetailNativeProps = {
    refreshQuizList: (quizType: string) => void;
    closeAddPanel: (id: number, quizType: string) => void;
    quizId: number;
    accessibilityScope?: string;
};

type QuizDetailsType = {
    title: string;
    description: string;
    showIndividualScore: boolean;
    skippingAllowed: boolean;
    correctPoints: string;
    wrongPoints: string;
    skipPoints: string;
    minutes: number;
    seconds: number;
    nextButtonText: string;
    summary: string;
    initialProgress: string;
    questionList: QuizQuestionListItem[];
    quizType: string;
    answerResultImage: string;
    answerResultText: string;
    showPercentageProgress: boolean;
    displayTitle: string;
    showMainTimer: boolean;
    showHintCount: boolean;
    showCorrectAnswerCount: boolean;
    showWrongAnswerCount: boolean;
    isConnectorQuiz: boolean;
    accessibilityScope: string;
    accessibleIdentityIds: string[];
    totalPointsText: string;
};

type QuizTemplateDetails = {
    progressTitle: string;
    attachmentTitle: string;
    backgroundImage: string;
    progressImage: string;
    id: number;
    baseColor: string;
    browserTabTitle: string;
    buttonColor: string;
    wrongAnswerBackgroundColor: string;
    correctAnswerBackgroundColor: string;
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    wildgoatCorrectAnswerHeading: string;
    wildgoatCorrectAnswerImage: string;
    wildgoatWrongAnswerHeading: string;
    wildgoatWrongAnswerImage: string;
    wildgoatTimeoutHeading: string;
    wildgoatTimeoutAnswerImage: string;
    totalScoreVisibility: boolean;
};

const QuizDetailView: React.FC<IQuestionDetailNativeProps> = ({
    refreshQuizList,
    quizId,
    closeAddPanel,
    accessibilityScope
}) => {
    const [searchText, setSearchText] = useState("");
    const [showQuestionBank, setQuestionBankDisplay] = useState(false);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [activePivot, setActivePivot] = useState<string | undefined>();
    const [backgroundImageFile, setBackgroundImageFile] = useState<File | undefined>();
    const [backgroundImageError, setBackgroundImageError] = useState("");
    const [progressImageFile, setProgressImageFile] = useState<File | undefined>();
    const [progressImageError, setProgressImageError] = useState("");
    const [isQuizTemplateAdded, SetIsQuizTemplateAdded] = useState(false);
    const [colorPickerValue, setColorPickerValue] = useState("");
    const [showColorPickerPanel, setShowColorPickerPanel] = useState({
        type: "",
        showPanel: false
    });
    const [answerResultImageFile, setAnswerResultImageFile] = useState<File | undefined>();
    const [answerResultImageError, setAnswerResultImageError] = useState("");
    const [selectedQuestionId, setSelectedQuestionId] = useState(-1);
    const [showAddEditQuestionPanel, setAddEditQuestionPanelDisplay] = useState(false);
    const [identityOptions, setIdentityOption] = useState<IDropdownOption[]>([]);
    const { userDetail } = useAdminContext();
    const [correctAnswerImageFile, setCorrectAnswerImageFile] = useState<File | undefined>();
    const [correctAnswerImageError, setCorrectAnswerImageError] = useState("");
    const [wrongAnswerImageFile, setWrongAnswerImageFile] = useState<File | undefined>();
    const [wrongAnswerImageError, setWrongAnswerImageError] = useState("");
    const [timeoutImageFile, setTimeoutImageFile] = useState<File | undefined>();
    const [timoutImageError, setTimeoutImageError] = useState("");

    const quizInitialValues: QuizDetailsType = {
        correctPoints: "",
        description: "",
        minutes: 60,
        nextButtonText: "",
        seconds: 0,
        showIndividualScore: false,
        skippingAllowed: false,
        summary: "",
        title: "",
        wrongPoints: "",
        initialProgress: "",
        skipPoints: "",
        questionList: [],
        quizType: SiteConstants.spaceGameQuiz,
        answerResultImage: "",
        answerResultText: "",
        showPercentageProgress: true,
        displayTitle: "",
        showCorrectAnswerCount: true,
        showWrongAnswerCount: true,
        showHintCount: true,
        showMainTimer: true,
        isConnectorQuiz: false,
        accessibilityScope: "private",
        accessibleIdentityIds: [],
        totalPointsText: ""
    };

    const quizDetailsValidation = yup.object().shape(
        {
            skippingAllowed: yup.boolean(),
            correctPoints: yup
                .mixed()
                .test("validateCorrectPoint", "Please enter a valid number", (value) => {
                    return value ? FieldValidation.floatValidation(value.toString()) : true;
                })
                .required("Correct point is mandatory"),
            wrongPoints: yup
                .mixed()
                .test("validateWrongPoint", "Please enter a valid number", (value) => {
                    return value ? FieldValidation.floatValidation(value.toString()) : true;
                })
                .required("Wrong point is mandatory"),
            title: yup.string().required("Title is mandatory"),
            skipPoints: yup.mixed().when("skippingAllowed", {
                is: true,
                then: yup
                    .mixed()
                    .test("validateSkipPoint", "Please enter a valid number", (value) => {
                        return value ? FieldValidation.floatValidation(value.toString()) : true;
                    })
                    .required("Skip points is mandatory"),
                otherwise: yup.mixed().test("validateSkipPoint", "Please enter a valid number", (value) => {
                    return value ? FieldValidation.floatValidation(value.toString()) : true;
                })
            }),
            initialProgress: yup.mixed().test("validateInitialProgress", "Please enter a valid number", (value) => {
                return value ? FieldValidation.floatValidation(value.toString()) : true;
            })
        },
        [["skippingAllowed", "skipPoints"]]
    );

    const formik = useFormik({
        initialValues: quizInitialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: quizDetailsValidation,
        onSubmit: () => saveQuiz()
    });

    const quizTemplateDetailsInitialValue: QuizTemplateDetails = {
        progressTitle: "",
        attachmentTitle: "",
        backgroundImage: "",
        progressImage: "",
        id: -1,
        baseColor: "",
        browserTabTitle: "",
        buttonColor: "",
        correctAnswerBackgroundColor: "",
        wrongAnswerBackgroundColor: "",
        primaryColor: "",
        secondaryColor: "",
        tertiaryColor: "",
        wildgoatCorrectAnswerHeading: "",
        wildgoatWrongAnswerHeading: "",
        wildgoatTimeoutHeading: "",
        wildgoatCorrectAnswerImage: "",
        wildgoatTimeoutAnswerImage: "",
        wildgoatWrongAnswerImage: "",
        totalScoreVisibility: false
    };

    const quizTemplateDetailsValidation = yup.object().shape({
        progressTitle: yup.string(),
        attachmentTitle: yup.string(),
        backgroundImage: yup.string(),
        progressImage: yup.string(),
        browserTabTitle: yup.string()
    });

    const quizTemplateForm = useFormik({
        initialValues: quizTemplateDetailsInitialValue,
        validationSchema: quizTemplateDetailsValidation,
        onSubmit: () => saveQuizTemplateConfig(),
        validateOnMount: true,
        enableReinitialize: true
    });

    const reverseTransformTemplateData = (): FormData => {
        const quizTemplateData = new FormData();
        const templateData: any = {
            attachmentTitle: quizTemplateForm.values.attachmentTitle,
            progressTitle: quizTemplateForm.values.progressTitle,
            backgroundImage: backgroundImageFile as Blob,
            progressImage: progressImageFile as Blob,
            baseColor: quizTemplateForm.values.baseColor,
            baseButtonColor: quizTemplateForm.values.buttonColor,
            browserTabTitle: quizTemplateForm.values.browserTabTitle,
            wrongAnswerBackgroundColor: quizTemplateForm.values.wrongAnswerBackgroundColor,
            correctAnswerBackgroundColor: quizTemplateForm.values.correctAnswerBackgroundColor,
            wildgoatPrimaryColor: quizTemplateForm.values.primaryColor,
            wildgoatSecondaryColor: quizTemplateForm.values.secondaryColor,
            wildgoatTertiaryColor: quizTemplateForm.values.tertiaryColor,
            correctAnswerHeading: quizTemplateForm.values.wildgoatCorrectAnswerHeading,
            wrongAnswerHeading: quizTemplateForm.values.wildgoatWrongAnswerHeading,
            timeoutAnswerHeading: quizTemplateForm.values.wildgoatTimeoutHeading,
            totalScoreVisibility: quizTemplateForm.values.totalScoreVisibility,
            correctAnswerImage: correctAnswerImageFile as Blob,
            wrongAnswerImage: wrongAnswerImageFile as Blob,
            timeoutAnswerImage: timeoutImageFile as Blob
        };
        for (const key in templateData) {
            quizTemplateData.append(key, templateData[key]);
        }
        return quizTemplateData;
    };

    const saveQuizTemplateConfig = (): void => {
        if (backgroundImageError || progressImageError) {
            quizTemplateForm.setSubmitting(false);
            return;
        }
        if (quizTemplateForm.values.id !== -1 || isQuizTemplateAdded) {
            Client.getInstance()
                .updateData(ApiConstants.addQuizTemplateDetailApiUrl(quizId), reverseTransformTemplateData())
                .then(() => {
                    toast.success("Quiz template updated", SiteConstants.successToastConfiguration);
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => {
                    quizTemplateForm.setSubmitting(false);
                });
            return;
        }
        Client.getInstance()
            .createData(ApiConstants.addQuizTemplateDetailApiUrl(quizId), reverseTransformTemplateData())
            .then(() => {
                toast.success("Quiz template added", SiteConstants.successToastConfiguration);
                SetIsQuizTemplateAdded(true);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                quizTemplateForm.setSubmitting(false);
            });
    };

    useEffect(() => {
        if (quizId !== -1) {
            setShowLoadingSpinner(true);
            Client.getInstance()
                .getData(ApiConstants.getQuizDetailApiUrl(quizId), true)
                .then((response: any) => {
                    transformData(response.data.data);
                    setShowLoadingSpinner(false);
                })
                .catch(() => {
                    setShowLoadingSpinner(false);
                });
        }
        getAllIdentitiesList(true)
            .then((res: IdentityListtype[]) => {
                setIdentityOption(
                    res.map(({ id, name }) => ({
                        id: id,
                        key: id,
                        text: name
                    }))
                );
            })
            .catch(() => {
                setIdentityOption([]);
            });
    }, []);

    const transformData = (data: any): void => {
        const questions: QuizQuestionListItem[] = data.quiz_questions.map((quizQuestion: any, index: number) => ({
            id: quizQuestion.question.id,
            questionTitle: quizQuestion.question.title,
            tags: quizQuestion.question.questionTags.map((tag: any) => ({ id: tag.tag.value, value: tag.tag.value })),
            index: index + 1
        }));
        formik.setFieldValue("questionList", questions);
        const quizTimeLimit = data.timeLimit.split(":");

        formik.setValues({
            correctPoints: data.correctPoints,
            wrongPoints: data.wrongPoints,
            description: data.description,
            minutes: parseInt(quizTimeLimit[1]) + parseInt(quizTimeLimit[0]) * 60,
            nextButtonText: data.nextButtonText,
            seconds: quizTimeLimit[2],
            showIndividualScore: data.showIndividualScore,
            skippingAllowed: data.allowSkipping,
            summary: data.summaryPageText,
            title: data.title,
            initialProgress: data.initialScore !== null ? data.initialScore : "",
            skipPoints: data.skipPoints !== null ? data.skipPoints : "",
            questionList: fetchQuestionList(data.quiz_questions),
            quizType: data.type,
            answerResultImage: data?.answerResultImageFileLocation || "",
            answerResultText: data?.answerResultText || "",
            showPercentageProgress: data?.showProgressInPercentage || false,
            displayTitle: data.displayTitle || "",
            showCorrectAnswerCount: data.showCorrectAnswerCount,
            showHintCount: data.showHintCount,
            showMainTimer: data.showMainTimer,
            showWrongAnswerCount: data.showWrongAnswerCount,
            isConnectorQuiz: data.isConnectorQuiz || false,
            accessibilityScope: data.accessibilityScope || "private",
            accessibleIdentityIds: data?.accessibleIdentityIds || [],
            totalPointsText: data?.totalPointsText || ""
        });

        data.quizConfig &&
            quizTemplateForm.setValues({
                attachmentTitle: data.quizConfig.attachmentTitle || "",
                backgroundImage: data.quizConfig.backgroundImageFileLocation || "",
                progressImage: data.quizConfig.progressImageFileLocation || "",
                progressTitle: data.quizConfig.progressTitle || "",
                id: data.quizConfig.id,
                baseColor: data.quizConfig.baseColor || "",
                browserTabTitle: data.quizConfig.browserTabTitle || "",
                buttonColor: data.quizConfig.baseButtonColor || "",
                wrongAnswerBackgroundColor: data.quizConfig.wrongAnswerBackgroundColor || "",
                correctAnswerBackgroundColor: data.quizConfig.correctAnswerBackgroundColor || "",
                primaryColor: data.quizConfig?.wildgoatPrimaryColor || "",
                secondaryColor: data.quizConfig?.wildgoatSecondaryColor || "",
                tertiaryColor: data.quizConfig?.wildgoatTertiaryColor || "",
                wildgoatCorrectAnswerHeading: data.quizConfig?.correctAnswerHeading || "",
                wildgoatCorrectAnswerImage: data.quizConfig?.correctAnswerImageLocation || "",
                wildgoatTimeoutAnswerImage: data.quizConfig?.timeoutAnswerImageLocation || "",
                wildgoatTimeoutHeading: data.quizConfig?.timeoutAnswerHeading || "",
                wildgoatWrongAnswerHeading: data.quizConfig?.wrongAnswerHeading || "",
                wildgoatWrongAnswerImage: data.quizConfig?.wrongAnswerImageLocation || "",
                totalScoreVisibility: data.quizConfig?.totalScoreVisibility || false
            });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, image: string): void => {
        if (!event.target.files || (event.target.files && event.target.files.length === 0)) {
            return;
        }

        if (!FieldValidation.imageTypeValidation(event.target.files[0].type)) {
            if (image === SiteConstants.PROGRESS_IMAGE) {
                quizTemplateForm.setFieldValue("progressImage", "");
                setProgressImageError("Please select an image file");
                setProgressImageFile(undefined);
                return;
            }
            if (image === "correctAnswerImageFile") {
                quizTemplateForm.setFieldValue("wildgoatCorrectAnswerImage", "");
                setCorrectAnswerImageError("Please select an image file");
                setCorrectAnswerImageFile(undefined);
                return;
            }
            if (image === "wrongAnswerImageFile") {
                quizTemplateForm.setFieldValue("wildgoatWrongAnswerImage", "");
                setWrongAnswerImageError("Please select an image file");
                setWrongAnswerImageFile(undefined);
                return;
            }
            if (image === "timeoutImageFile") {
                quizTemplateForm.setFieldValue("wildgoatTimeoutAnswerImage", "");
                setTimeoutImageError("Please select an image file");
                setTimeoutImageFile(undefined);
                return;
            }
            if (image === SiteConstants.AnswerResultImage) {
                formik.setFieldValue("answerResultImage", "");
                setAnswerResultImageError("Please select an image file");
                setAnswerResultImageFile(undefined);
                return;
            }
            quizTemplateForm.setFieldValue("backgroundImage", "");
            setBackgroundImageError("Please select an image file");
            setBackgroundImageFile(undefined);
            return;
        }
        if (FieldValidation.imageSizeValidation(event.target.files[0].size)) {
            if (image === SiteConstants.PROGRESS_IMAGE) {
                quizTemplateForm.setFieldValue("progressImage", "");
                setProgressImageError("Image size should be less than 2 MB");
                setProgressImageFile(undefined);
                return;
            }
            if (image === SiteConstants.AnswerResultImage) {
                formik.setFieldValue("answerResultImage", "");
                setAnswerResultImageError("Image size should be less than 2 MB");
                setAnswerResultImageFile(undefined);
                return;
            }
            quizTemplateForm.setFieldValue("backgroundImage", "");
            setBackgroundImageError("Image size should be less than 2 MB");
            setBackgroundImageFile(undefined);
            return;
        }
        if (image === SiteConstants.PROGRESS_IMAGE) {
            setProgressImageError("");
            quizTemplateForm.setFieldValue("progressImage", URL.createObjectURL(event.target.files[0]));
            setProgressImageFile(event.target.files[0]);
            return;
        }
        if (image === "correctAnswerImageFile") {
            setCorrectAnswerImageError("");
            quizTemplateForm.setFieldValue("wildgoatCorrectAnswerImage", URL.createObjectURL(event.target.files[0]));
            setCorrectAnswerImageFile(event.target.files[0]);
            return;
        }
        if (image === "wrongAnswerImageFile") {
            setWrongAnswerImageError("");
            quizTemplateForm.setFieldValue("wildgoatWrongAnswerImage", URL.createObjectURL(event.target.files[0]));
            setWrongAnswerImageFile(event.target.files[0]);
            return;
        }
        if (image === "timeoutImageFile") {
            setTimeoutImageError("");
            quizTemplateForm.setFieldValue("wildgoatTimeoutAnswerImage", URL.createObjectURL(event.target.files[0]));
            setTimeoutImageFile(event.target.files[0]);
            return;
        }
        if (image === SiteConstants.AnswerResultImage) {
            formik.setFieldValue("answerResultImage", URL.createObjectURL(event.target.files[0]));
            setAnswerResultImageError("");
            setAnswerResultImageFile(event.target.files[0]);
            return;
        }
        quizTemplateForm.setFieldValue("backgroundImage", URL.createObjectURL(event.target.files[0]));
        setBackgroundImageError("");
        setBackgroundImageFile(event.target.files[0]);
        return;
    };

    const onIdentityChange = (_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            const selectedIdentity = item.selected
                ? [...formik.values.accessibleIdentityIds, item.key as string]
                : formik.values.accessibleIdentityIds.filter((key: any) => key !== item.key);

            formik.setFieldValue("accessibleIdentityIds", selectedIdentity);
        }
    };

    const renderVisibilty = () => {
        return (
            <Stack className={"margin-right-48"}>
                <Stack.Item>
                    <DropdownWrapper
                        label={"Visibilty type"}
                        selectedKey={formik.values.accessibilityScope}
                        onChangeDropdownOption={(value) => {
                            formik.setFieldValue("accessibilityScope", value);
                            formik.setFieldValue("accessibleIdentityIds", []);
                        }}
                        options={visibiltyTypeOptions}
                        width={200}
                    />
                </Stack.Item>
                {formik.values.accessibilityScope === "limited" && (
                    <Stack horizontal tokens={ThemedSmall2StackTokens}>
                        <Stack.Item>
                            <Dropdown
                                label={"All Identities"}
                                selectedKeys={formik.values.accessibleIdentityIds}
                                options={identityOptions}
                                onChange={onIdentityChange}
                                multiSelect
                                styles={{ root: { width: 200 } }}
                            />
                        </Stack.Item>
                        <PrimaryButton
                            className={"margin-top-30"}
                            text={formik.values.accessibleIdentityIds.length > 0 ? "Clear all" : "Select All"}
                            onClick={() => {
                                formik.values.accessibleIdentityIds.length > 0
                                    ? formik.setFieldValue("accessibleIdentityIds", [])
                                    : formik.setFieldValue(
                                          "accessibleIdentityIds",
                                          identityOptions.map((item: any) => item.id)
                                      );
                            }}
                        />
                    </Stack>
                )}
            </Stack>
        );
    };

    const renderWildgoatThemeColor = () => {
        return (
            <Stack tokens={ThemedMediumStackTokens}>
                <ToggleFieldWrapper
                    label={"Total Score visibility"}
                    checked={quizTemplateForm.values.totalScoreVisibility}
                    onChange={(value: boolean) => quizTemplateForm.setFieldValue("totalScoreVisibility", value)}
                    inlineLabel={true}
                />
                <TextField
                    label={"Wildgoat Correct Answer Heading"}
                    value={quizTemplateForm.values.wildgoatCorrectAnswerHeading}
                    onChange={quizTemplateForm.handleChange}
                    name={"wildgoatCorrectAnswerHeading"}
                />
                <Stack.Item className="width-300">
                    <Label>Wildgoat Correct Answer image</Label>
                    <Form.Group>
                        <Form.File
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleFileChange(event, "correctAnswerImageFile")
                            }
                        />
                        {correctAnswerImageError && <span className={"error-message"}>{correctAnswerImageError}</span>}
                    </Form.Group>
                    {quizTemplateForm.values.wildgoatCorrectAnswerImage && !correctAnswerImageError && (
                        <Image
                            src={quizTemplateForm.values.wildgoatCorrectAnswerImage}
                            alt="Background Image"
                            className="question-image"
                        />
                    )}
                </Stack.Item>
                <TextField
                    label={"Wildgoat Wrong Answer Heading"}
                    value={quizTemplateForm.values.wildgoatWrongAnswerHeading}
                    onChange={quizTemplateForm.handleChange}
                    name={"wildgoatWrongAnswerHeading"}
                />
                <Stack.Item className="width-300">
                    <Label>Wildgoat Wrong Answer image</Label>
                    <Form.Group>
                        <Form.File
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleFileChange(event, "wrongAnswerImageFile")
                            }
                        />
                        {wrongAnswerImageError && <span className={"error-message"}>{wrongAnswerImageError}</span>}
                    </Form.Group>
                    {quizTemplateForm.values.wildgoatWrongAnswerImage && !wrongAnswerImageError && (
                        <Image
                            src={quizTemplateForm.values.wildgoatWrongAnswerImage}
                            alt="Background Image"
                            className="question-image"
                        />
                    )}
                </Stack.Item>
                <TextField
                    label={"Wildgoat Timeout Answer Heading"}
                    value={quizTemplateForm.values.wildgoatTimeoutHeading}
                    onChange={quizTemplateForm.handleChange}
                    name={"wildgoatTimeoutHeading"}
                />
                <Stack.Item className="width-300">
                    <Label>Wildgoat Timeout Answer image</Label>
                    <Form.Group>
                        <Form.File
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleFileChange(event, "timeoutImageFile")
                            }
                        />
                        {timoutImageError && <span className={"error-message"}>{timoutImageError}</span>}
                    </Form.Group>
                    {quizTemplateForm.values.wildgoatTimeoutAnswerImage && !timoutImageError && (
                        <Image
                            src={quizTemplateForm.values.wildgoatTimeoutAnswerImage}
                            alt="Background Image"
                            className="question-image"
                        />
                    )}
                </Stack.Item>
                <Stack horizontal tokens={ThemedSmall2StackTokens}>
                    <TextField
                        label={"Wildgoat primary colour"}
                        value={quizTemplateForm.values.primaryColor}
                        onChange={quizTemplateForm.handleChange}
                        name={"primaryColor"}
                    />
                    <Stack.Item className="margin-right-48" align={"end"}>
                        <div style={{ display: "flex" }}>
                            {quizTemplateForm.values.primaryColor && (
                                <DotContainer
                                    iconProps={{ iconName: "LocationDot" }}
                                    size={74}
                                    color={quizTemplateForm.values.primaryColor}
                                />
                            )}

                            <IconButton
                                iconProps={{ iconName: "Color" }}
                                onClick={() => {
                                    setShowColorPickerPanel({ type: "primaryColor", showPanel: true });
                                    setColorPickerValue(quizTemplateForm.values.baseColor);
                                }}
                            />
                        </div>
                    </Stack.Item>

                    <TextField
                        label={"Wildgoat secondary colour"}
                        value={quizTemplateForm.values.secondaryColor}
                        onChange={quizTemplateForm.handleChange}
                        name={"secondaryColor"}
                    />
                    <Stack.Item align={"end"} className="margin-right-48">
                        <div style={{ display: "flex" }}>
                            {quizTemplateForm.values.secondaryColor && (
                                <DotContainer
                                    iconProps={{ iconName: "LocationDot" }}
                                    size={74}
                                    color={quizTemplateForm.values.secondaryColor}
                                />
                            )}
                            <IconButton
                                iconProps={{ iconName: "Color" }}
                                onClick={() => {
                                    setShowColorPickerPanel({ type: "secondaryColor", showPanel: true });
                                    setColorPickerValue(quizTemplateForm.values.secondaryColor);
                                }}
                            />
                        </div>
                    </Stack.Item>

                    <TextField
                        label={"Wildgoat tertiary color"}
                        value={quizTemplateForm.values.tertiaryColor}
                        onChange={quizTemplateForm.handleChange}
                        name={"tertiaryColor"}
                    />
                    <Stack.Item align={"end"}>
                        <div style={{ display: "flex" }}>
                            {quizTemplateForm.values.tertiaryColor && (
                                <DotContainer
                                    iconProps={{ iconName: "LocationDot" }}
                                    size={74}
                                    color={quizTemplateForm.values.tertiaryColor}
                                />
                            )}
                            <IconButton
                                iconProps={{ iconName: "Color" }}
                                onClick={() => {
                                    setShowColorPickerPanel({ type: "tertiaryColor", showPanel: true });
                                    setColorPickerValue(quizTemplateForm.values.tertiaryColor);
                                }}
                            />
                        </div>
                    </Stack.Item>
                </Stack>
            </Stack>
        );
    };

    const getQuizTemplateSection = (): JSX.Element => (
        <Stack tokens={ThemedMediumStackTokens}>
            <Stack
                style={{
                    backgroundColor:
                        quizId !== -1 && accessibilityScope && ["limited", "global"].includes(accessibilityScope)
                            ? dangerColorLight
                            : ""
                }}
                tokens={ThemedMediumStackTokens}
                className={"section-background section-border"}>
                <TextField
                    label={"Progress title"}
                    value={quizTemplateForm.values.progressTitle}
                    onChange={quizTemplateForm.handleChange}
                    name={"progressTitle"}
                />
                <TextField
                    label={"Attachment title"}
                    value={quizTemplateForm.values.attachmentTitle}
                    onChange={quizTemplateForm.handleChange}
                    name={"attachmentTitle"}
                />
                <TextField
                    label={"Browser tab title"}
                    onChange={quizTemplateForm.handleChange}
                    value={quizTemplateForm.values.browserTabTitle}
                    name={"browserTabTitle"}
                />
                <Stack.Item className="width-200">
                    <Label>Background image</Label>
                    <Form.Group>
                        <Form.File
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleFileChange(event, SiteConstants.BACKGROUND_IMAGE)
                            }
                        />
                        {backgroundImageError && <span className={"error-message"}>{backgroundImageError}</span>}
                    </Form.Group>
                    {quizTemplateForm.values.backgroundImage && !backgroundImageError && (
                        <Image
                            src={quizTemplateForm.values.backgroundImage}
                            alt="Background Image"
                            className="question-image"
                        />
                    )}
                </Stack.Item>

                <Stack.Item className="width-200">
                    <Label>Progress image</Label>
                    <Form.Group>
                        <Form.File
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleFileChange(event, SiteConstants.PROGRESS_IMAGE)
                            }
                        />
                        {progressImageError && <span className={"error-message"}>{progressImageError}</span>}
                    </Form.Group>
                    {quizTemplateForm.values.progressImage && !progressImageError && (
                        <Image
                            src={quizTemplateForm.values.progressImage}
                            alt="Progress Image"
                            className="question-image"
                        />
                    )}
                </Stack.Item>
                {formik.values.quizType === WildGoatGame && renderWildgoatThemeColor()}
                <Stack horizontal tokens={ThemedSmall2StackTokens}>
                    <TextField
                        label={"Background color"}
                        value={quizTemplateForm.values.baseColor}
                        onChange={quizTemplateForm.handleChange}
                        name={"baseColor"}
                    />
                    <Stack.Item className="margin-right-48" align={"end"}>
                        <div style={{ display: "flex" }}>
                            {quizTemplateForm.values.baseColor && (
                                <DotContainer
                                    iconProps={{ iconName: "LocationDot" }}
                                    size={74}
                                    color={quizTemplateForm.values.baseColor}
                                />
                            )}

                            <IconButton
                                iconProps={{ iconName: "Color" }}
                                onClick={() => {
                                    setShowColorPickerPanel({ type: "baseColor", showPanel: true });
                                    setColorPickerValue(quizTemplateForm.values.baseColor);
                                }}
                            />
                        </div>
                    </Stack.Item>

                    <TextField
                        label={"Button color"}
                        value={quizTemplateForm.values.buttonColor}
                        onChange={quizTemplateForm.handleChange}
                        name={"buttonColor"}
                    />
                    <Stack.Item align={"end"}>
                        <div style={{ display: "flex" }}>
                            {quizTemplateForm.values.buttonColor && (
                                <DotContainer
                                    iconProps={{ iconName: "LocationDot" }}
                                    size={74}
                                    color={quizTemplateForm.values.buttonColor}
                                />
                            )}
                            <IconButton
                                iconProps={{ iconName: "Color" }}
                                onClick={() => {
                                    setShowColorPickerPanel({ type: "buttonColor", showPanel: true });
                                    setColorPickerValue(quizTemplateForm.values.buttonColor);
                                }}
                            />
                        </div>
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={ThemedSmall2StackTokens}>
                    <TextField
                        label={"Correct answer background color"}
                        value={quizTemplateForm.values.correctAnswerBackgroundColor}
                        onChange={quizTemplateForm.handleChange}
                        name={"correctAnswerBackgroundColor"}
                    />
                    <Stack.Item className="margin-right-48" align={"end"}>
                        <div style={{ display: "flex" }}>
                            {quizTemplateForm.values.correctAnswerBackgroundColor && (
                                <DotContainer
                                    iconProps={{ iconName: "LocationDot" }}
                                    size={74}
                                    color={quizTemplateForm.values.correctAnswerBackgroundColor}
                                />
                            )}
                            <IconButton
                                iconProps={{ iconName: "Color" }}
                                onClick={() => {
                                    setShowColorPickerPanel({ type: "correctAnswerBackgroundColor", showPanel: true });
                                    setColorPickerValue(quizTemplateForm.values.correctAnswerBackgroundColor);
                                }}
                            />
                        </div>
                    </Stack.Item>

                    <TextField
                        label={"Wrong answer background color"}
                        value={quizTemplateForm.values.wrongAnswerBackgroundColor}
                        onChange={quizTemplateForm.handleChange}
                        name={"wrongAnswerBackgroundColor"}
                    />
                    <Stack.Item align={"end"}>
                        <div style={{ display: "flex" }}>
                            {quizTemplateForm.values.wrongAnswerBackgroundColor && (
                                <DotContainer
                                    iconProps={{ iconName: "LocationDot" }}
                                    size={74}
                                    color={quizTemplateForm.values.wrongAnswerBackgroundColor}
                                />
                            )}
                            <IconButton
                                iconProps={{ iconName: "Color" }}
                                onClick={() => {
                                    setShowColorPickerPanel({ type: "wrongAnswerBackgroundColor", showPanel: true });
                                    setColorPickerValue(quizTemplateForm.values.wrongAnswerBackgroundColor);
                                }}
                            />
                        </div>
                    </Stack.Item>
                </Stack>
            </Stack>
            <Stack horizontal horizontalAlign={"center"}>
                {quizTemplateForm.isSubmitting ? <Spinner size={SpinnerSize.large} /> : renderQuizTemplateSaveButton()}
            </Stack>

            <Stack horizontal horizontalAlign={"center"} className={"margin-bottom-15"}>
                {!quizTemplateForm.isValid || progressImageError || backgroundImageError ? (
                    <span className={"error-message"}>Please fill in all the fields correctly</span>
                ) : null}
            </Stack>
            {showColorPickerPanel.showPanel && (
                <Panel
                    isLightDismiss
                    isOpen={true}
                    onDismiss={() => setShowColorPickerPanel({ type: "", showPanel: false })}
                    headerText={"Pick color"}
                    type={PanelType.custom}
                    customWidth={"450px"}>
                    <Stack
                        tokens={ThemedMediumStackTokens}
                        className={"section-background section-border padding-0 margin-top-10"}>
                        <ColorPickerWrapper
                            color={colorPickerValue}
                            onChange={(colorObject: IColor) =>
                                setColorPickerValue(
                                    `#${colorObject.hex}${convertAlphaToHexadecimal(colorObject.a || 0)}`
                                )
                            }
                        />
                    </Stack>
                    <Stack horizontalAlign={"center"} className={"margin-top-10"}>
                        <PrimaryButton
                            text={"Save"}
                            onClick={() => {
                                quizTemplateForm.setFieldValue(showColorPickerPanel.type, colorPickerValue);
                                setShowColorPickerPanel({ type: "", showPanel: false });
                            }}
                        />
                    </Stack>
                </Panel>
            )}
        </Stack>
    );

    const fetchQuestionList = (data: any): QuizQuestionListItem[] => {
        return data.map((quizQuestion: any, index: number) => ({
            id: quizQuestion.question.id,
            questionTitle: quizQuestion.question.title,
            tags: quizQuestion.question.questionTags.map((tag: any) => ({ id: tag.tag.id, value: tag.tag.value })),
            index: index + 1
        }));
    };

    const getTime = (): string => {
        if (formik.values.minutes === 59 && formik.values.seconds === 60) {
            return `01:00:00`;
        }
        if (formik.values.minutes >= 60 && formik.values.seconds === 60) {
            return `${Math.floor(formik.values.minutes / 60)}:${(formik.values.minutes % 60) + 1}:00`;
        }
        if (formik.values.minutes < 60 && formik.values.seconds === 60) {
            return `00:${formik.values.minutes + 1}:00`;
        }
        return `${formik.values.minutes ? Math.floor(formik.values.minutes / 60) : "00"}:${
            formik.values.minutes ? formik.values.minutes % 60 : "00"
        }:${formik.values.seconds ? formik.values.seconds : "00"}`;
    };

    const reverseTransformData = (): any => {
        const quizSettingsFormData = new FormData();
        const settingsData: any = {
            title: formik.values.title,
            allowSkipping: formik.values.skippingAllowed ? 1 : 0,
            showMainTimer: formik.values.showMainTimer ? 1 : 0,
            showHintCount: formik.values.showHintCount ? 1 : 0,
            showCorrectAnswerCount: formik.values.showCorrectAnswerCount ? 1 : 0,
            showWrongAnswerCount: formik.values.showWrongAnswerCount ? 1 : 0,
            questions: formik.values.questionList.map((element) => element.id).join(","),
            correctPoints: formik.values.correctPoints,
            wrongPoints: formik.values.wrongPoints,
            skipPoints: formik.values.skippingAllowed ? formik.values.skipPoints : null,
            showIndividualScore: formik.values.showIndividualScore ? 1 : 0,
            description: formik.values.description,
            summaryPageText: formik.values.summary,
            timeLimit: getTime(),
            initialScore: formik.values.initialProgress === "" ? null : formik.values.initialProgress,
            nextButtonText: formik.values.nextButtonText,
            type: formik.values.quizType,
            showProgressInPercentage: formik.values.showPercentageProgress,
            answerResultImage: answerResultImageFile as Blob,
            answerResultText: formik.values.answerResultText,
            displayTitle: formik.values.displayTitle,
            isConnectorQuiz: formik.values.isConnectorQuiz,
            accessibilityScope: formik.values.accessibilityScope,
            accessibleIdentityIds: formik.values.accessibleIdentityIds.join(";"),
            totalPointsText: formik.values.totalPointsText
        };

        for (const key in settingsData) {
            quizSettingsFormData.append(key, settingsData[key]);
        }

        return quizSettingsFormData;
    };

    const saveQuiz = (): void => {
        if (quizId !== -1) {
            Client.getInstance()
                .updateData(ApiConstants.updateQuizDetailApiUrl(quizId), reverseTransformData())
                .then(() => {
                    refreshQuizList(formik.values.quizType);
                    toast.success("Quiz updated", SiteConstants.successToastConfiguration);
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => {
                    formik.setSubmitting(false);
                });
            return;
        }

        Client.getInstance()
            .createData(ApiConstants.addQuizApiUrl(), reverseTransformData())
            .then((response) => {
                formik.setSubmitting(false);
                closeAddPanel(response.data.data.id, formik.values.quizType);
                toast.success("Quiz added", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                formik.setSubmitting(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                formik.setSubmitting(false);
            });
    };

    const quizQuestionColumns: IColumn[] = [
        {
            key: "serialNumber",
            name: "Id",
            fieldName: "serialNumber",
            minWidth: SiteConstants.listColumnSerialNumber,
            maxWidth: SiteConstants.listColumnSerialNumber,
            isResizable: true
        },
        {
            key: "title",
            name: "Title",
            fieldName: "title",
            minWidth: SiteConstants.listColumnMedium
        },
        {
            key: "edit",
            name: "",
            fieldName: "edit",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "delete",
            name: "",
            fieldName: "delete",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        }
    ];

    const getTagsSpan = (tags: QuestionTags[]): JSX.Element[] => {
        let tagsElement = tags.map((tag) => (
            <span key={tag.id} className={"quiz-question-tag-container"}>
                {tag.value}
            </span>
        ));
        if (tagsElement.length > 10) {
            tagsElement = tagsElement.slice(0, 10);
            tagsElement.push(<span> + {tags.length - 10} More</span>);
            return tagsElement;
        }
        return tagsElement;
    };

    const deleteQuestion = (id: number): void => {
        const filteredQuestions = formik.values.questionList.filter(
            (question: QuizQuestionListItem) => question.id !== id
        );
        filteredQuestions.map((question, index) => (question["index"] = index + 1));
        formik.setFieldValue("questionList", filteredQuestions);
    };

    const renderItemColumn = (
        item: QuizQuestionListItem,
        index: number | undefined,
        column: IColumn | undefined
    ): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "edit":
                return (
                    <IconButton
                        iconProps={{ iconName: "Edit" }}
                        title="Edit"
                        onClick={() => {
                            item.id && setSelectedQuestionId(item.id);
                            item.id && setAddEditQuestionPanelDisplay(true);
                        }}
                    />
                );
            case "delete":
                return (
                    <IconButton
                        iconProps={{ iconName: "delete" }}
                        title="Delete"
                        onClick={() => item.id && deleteQuestion(item.id)}
                        className={"deleteQuizQuestionIconTc"}
                    />
                );
            case "title":
                return (
                    <>
                        <span className={"margin-bottom-10 display-block"}>
                            {extractHtmlContent(item.questionTitle)}
                        </span>
                        <span>{item.tags && getTagsSpan(item.tags)}</span>
                    </>
                );
            case "serialNumber":
                return <span>{item.index}</span>;

            default: {
                const fieldContent = item[column.fieldName as keyof QuizQuestionListItem] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    const addQuestions = (item: QuizQuestionListItem): void => {
        formik.setFieldValue("questionList", [
            ...formik.values.questionList,
            { ...item, index: formik.values.questionList.length + 1 }
        ]);
    };

    const getFilteredItems = (): any => {
        const filteredItems =
            searchText === ""
                ? formik.values.questionList
                : formik.values.questionList.filter((item: QuizQuestionListItem) => {
                      return includeInFilteredItems(searchText.toLowerCase(), item);
                  });
        return filteredItems;
    };

    const includeInFilteredItems = (searchText: string, item: QuizQuestionListItem): boolean => {
        if (item.questionTitle && item.questionTitle.toLowerCase().includes(searchText)) {
            return true;
        }
        if (
            item.tags &&
            item.tags
                .map((tag) => tag.value)
                .toString()
                .toLowerCase()
                .includes(searchText)
        ) {
            return true;
        }
        return false;
    };

    const refreshQuizListDetails = (questionId: number, quizTitle: string, tag: IDropdownOption[]) => {
        const copyQuestionList = formik.values.questionList;
        const question = copyQuestionList.find((question) => question.id === questionId);
        const index = formik.values.questionList.findIndex((item) => item.id === questionId);
        copyQuestionList[index] = {
            ...question,
            questionTitle: quizTitle,
            tags: tag.map((item) => ({
                id: item.key as number,
                value: item.text
            }))
        } as any;
        formik.setFieldValue("questionList", copyQuestionList);
    };

    const renderSaveButton = () => {
        if (quizId === -1 || userDetail?.identity.name === "jambar" || accessibilityScope === "private") {
            return (
                <PrimaryButton iconProps={{ iconName: "Save" }} text={"Save"} onClick={() => formik.handleSubmit()} />
            );
        }
        return <></>;
    };

    const renderQuizTemplateSaveButton = () => {
        if (quizId === -1 || userDetail?.identity.name === "jambar" || accessibilityScope === "private") {
            return (
                <PrimaryButton
                    iconProps={{ iconName: "Save" }}
                    text={"Save"}
                    onClick={() => quizTemplateForm.handleSubmit()}
                />
            );
        }
        return <></>;
    };

    const getQuizSettingsSection = (): JSX.Element => {
        return (
            <Stack
                style={{
                    backgroundColor:
                        quizId !== -1 && accessibilityScope && ["limited", "global"].includes(accessibilityScope)
                            ? dangerColorLight
                            : ""
                }}
                tokens={ThemedMediumStackTokens}
                className="section-background section-border overflowy-auto">
                <Stack.Item grow>
                    <Label>Game Title</Label>
                    <TextEditor
                        className={`${BASIC_TEXT_EDITOR_CLASS} quill-blue-background quizTitleTc`}
                        value={formik.values.title}
                        onChange={(text) => formik.setFieldValue("title", text)}
                        basicControl={true}
                    />
                    {FieldValidation.quillTextEditorValidation(formik.values.title) && (
                        <span className={"error-message"}>Title is mandatory</span>
                    )}
                </Stack.Item>
                <Stack.Item grow>
                    <Label>Game Display Title</Label>
                    <TextEditor
                        className={`${BASIC_TEXT_EDITOR_CLASS} quill-blue-background quizTitleTc`}
                        value={formik.values.displayTitle}
                        onChange={(text) => formik.setFieldValue("displayTitle", text)}
                        basicControl={true}
                    />
                    {FieldValidation.quillTextEditorValidation(formik.values.displayTitle) && (
                        <span className={"error-message"}>Title is mandatory</span>
                    )}
                </Stack.Item>
                <Stack.Item grow>
                    <Label>Description</Label>
                    <TextEditor
                        className={`${BASIC_TEXT_EDITOR_CLASS} quill-blue-background quizDescriptionTc`}
                        value={formik.values.description}
                        onChange={(text: string) => formik.setFieldValue("description", text)}
                        basicControl={true}
                    />
                </Stack.Item>
                {formik.values.quizType === SiteConstants.wildgoat && (
                    <TextField
                        label={"Total Points text"}
                        onChange={formik.handleChange}
                        value={formik.values.totalPointsText}
                        name={"totalPointsText"}
                    />
                )}
                <TextField
                    label={"Answer result text"}
                    onChange={formik.handleChange}
                    value={formik.values.answerResultText}
                    name={"answerResultText"}
                    description={"{score} for quiz score and {questionscore} for question score"}
                />
                <Stack.Item>
                    <Label>Answer result image</Label>
                    <Form.Group>
                        <Form.File
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                handleFileChange(event, SiteConstants.AnswerResultImage)
                            }
                        />
                        {answerResultImageError && <span className={"error-message"}>{answerResultImageError}</span>}
                    </Form.Group>
                    {formik.values.answerResultImage && !answerResultImageError && (
                        <Image src={formik.values.answerResultImage} alt="Progress Image" className="question-image" />
                    )}
                </Stack.Item>

                <ToggleFieldWrapper
                    label={"Show progress in percentage"}
                    checked={formik.values.showPercentageProgress}
                    onChange={(value: boolean) => formik.setFieldValue("showPercentageProgress", value)}
                    inlineLabel={true}
                />
                <ToggleFieldWrapper
                    label={"Show individual score"}
                    checked={formik.values.showIndividualScore}
                    onChange={(value: boolean) => formik.setFieldValue("showIndividualScore", value)}
                    inlineLabel={true}
                    id={"showIndividualScore"}
                />
                <ToggleFieldWrapper
                    label={"Skipping allowed"}
                    checked={formik.values.skippingAllowed}
                    onChange={(value: boolean) => formik.setFieldValue("skippingAllowed", value)}
                    inlineLabel={true}
                    id={"skippingAllowed"}
                />
                <ToggleFieldWrapper
                    label={"Show Main Timer"}
                    checked={formik.values.showMainTimer}
                    onChange={(value: boolean) => formik.setFieldValue("showMainTimer", value)}
                    inlineLabel={true}
                    id={"showMainTimer"}
                />
                <ToggleFieldWrapper
                    label={"Show Hint Count"}
                    checked={formik.values.showHintCount}
                    onChange={(value: boolean) => formik.setFieldValue("showHintCount", value)}
                    inlineLabel={true}
                    id={"showHintCount"}
                />
                <ToggleFieldWrapper
                    label={"Show Correct Answer Count"}
                    checked={formik.values.showCorrectAnswerCount}
                    onChange={(value: boolean) => formik.setFieldValue("showCorrectAnswerCount", value)}
                    inlineLabel={true}
                    id={"showCorrectAnswerCount"}
                />
                <ToggleFieldWrapper
                    label={"Show Wrong Answer Count"}
                    checked={formik.values.showWrongAnswerCount}
                    onChange={(value: boolean) => formik.setFieldValue("showWrongAnswerCount", value)}
                    inlineLabel={true}
                    id={"showWrongAnswerCount"}
                />
                {![SiteConstants.wildgoat, SiteConstants.socketQuiz].includes(formik.values.quizType) && (
                    <ToggleFieldWrapper
                        label={"Is Connector Game"}
                        checked={formik.values.isConnectorQuiz}
                        onChange={(value: boolean) => formik.setFieldValue("isConnectorQuiz", value)}
                        inlineLabel={true}
                        id={"isConnectorQuiz"}
                    />
                )}
                {userDetail?.identity.name === "jambar" && renderVisibilty()}
                <Stack horizontal tokens={ThemedMediumStackTokens}>
                    <TextFieldWrapper
                        label={"Correct points"}
                        errorMessage={formik.errors.correctPoints}
                        onTextFieldValueChange={(text: string) => formik.setFieldValue("correctPoints", text)}
                        value={formik.values.correctPoints}
                        id={"correctPoints"}
                    />

                    <TextFieldWrapper
                        label={"Wrong points"}
                        errorMessage={formik.errors.wrongPoints}
                        onTextFieldValueChange={(text: string) => formik.setFieldValue("wrongPoints", text)}
                        value={formik.values.wrongPoints}
                        id={"wrongPoints"}
                    />

                    {formik.values.skippingAllowed && (
                        <TextFieldWrapper
                            label={"Skip points"}
                            errorMessage={formik.errors.skipPoints}
                            onTextFieldValueChange={(text: string) => formik.setFieldValue("skipPoints", text)}
                            value={formik.values.skipPoints}
                            id={"skipPoints"}
                        />
                    )}

                    <TextFieldWrapper
                        label={"Initial progress"}
                        errorMessage={formik.errors.initialProgress}
                        onTextFieldValueChange={(text: string) => formik.setFieldValue("initialProgress", text)}
                        value={formik.values.initialProgress}
                        id={"initialProgress"}
                    />
                </Stack>

                <Stack horizontal tokens={ThemedMediumStackTokens}>
                    <Stack.Item className={"width-168 quizTimeLimit"}>
                        <Label>Quiz time limit</Label>
                        <Stack horizontal tokens={ThemedSmall2StackTokens}>
                            <TextFieldWrapper
                                onRenderDescription={() => <Text variant={"small"}>Minute</Text>}
                                onTextFieldValueChange={(text: string) =>
                                    formik.setFieldValue("minutes", parseInt(text))
                                }
                                value={formik.values.minutes.toString()}
                                placeholder={"00"}
                                type={"number"}
                                id={"minutes"}
                            />
                            <TextFieldWrapper
                                onRenderDescription={() => <Text variant={"small"}>Second</Text>}
                                onTextFieldValueChange={(text: string) =>
                                    formik.setFieldValue("seconds", parseInt(text))
                                }
                                value={formik.values.seconds.toString()}
                                placeholder={"00"}
                                type={"number"}
                                id={"seconds"}
                            />
                        </Stack>
                        <Stack.Item className={"margin-bottom-15"}>
                            {(formik.values.minutes === 0 && formik.values.seconds === 0) ||
                            (isNaN(formik.values.minutes) && isNaN(formik.values.seconds)) ? (
                                <span className={"error-message"}>Time limit is mandatory</span>
                            ) : !FieldValidation.quizTimeLimitValidation(
                                  formik.values.minutes,
                                  formik.values.seconds
                              ) ? (
                                <span className={"error-message"}>Please enter valid time limit</span>
                            ) : null}
                        </Stack.Item>
                    </Stack.Item>
                    <TextFieldWrapper
                        label={"Next button text"}
                        onTextFieldValueChange={(text: string) => formik.setFieldValue("nextButtonText", text)}
                        value={formik.values.nextButtonText}
                        id={"nextButtonText"}
                    />

                    <DropdownWrapper
                        options={quizTypeDropdownOptions}
                        selectedKey={formik.values.quizType}
                        onChangeDropdownOption={(quizType: string) => formik.setFieldValue("quizType", quizType)}
                        label={"Quiz type"}
                        className={"min-width-168"}
                        disabled={quizId !== -1}
                    />
                </Stack>
            </Stack>
        );
    };

    const updateQuestionList = (updatedQuestion: QuizQuestionListItem): void => {
        const updateValues = formik.values.questionList.map((question, index) => {
            if (question.id === updatedQuestion.id) {
                return {
                    ...updatedQuestion,
                    index: index + 1
                };
            }
            return question;
        });

        formik.setFieldValue("questionList", updateValues);
    };

    const getQuizQuestionSelectSection = (): JSX.Element => {
        return (
            <Stack
                style={{
                    backgroundColor:
                        quizId !== -1 && accessibilityScope && ["limited", "global"].includes(accessibilityScope)
                            ? dangerColorLight
                            : ""
                }}
                horizontal
                tokens={ThemedMediumStackTokens}>
                <Stack.Item
                    className={
                        showQuestionBank
                            ? "left-side-container section-background section-border"
                            : "quiz-question-container section-background section-border"
                    }>
                    <FixedHeaderSortingDetailsList
                        label={"Questions in this quiz"}
                        onSearchTextChange={(text: string) => setSearchText(text)}
                        searchText={searchText}
                        onAddButtonClick={() => setQuestionBankDisplay(true)}
                        buttonLabel={"Add Question"}
                        searchFieldWithoutLabel={true}
                        addNewButtonClassName={"addQuestionToQuizTc"}
                        columns={quizQuestionColumns}
                        isLoading={false}
                        items={getFilteredItems()}
                        onRenderItemColumn={renderItemColumn}
                        getTransformedItems={(item: QuizQuestionListItem[]) =>
                            formik.setFieldValue("questionList", item)
                        }
                        isListDraggable={true}
                    />
                </Stack.Item>
                {showQuestionBank && (
                    <Stack className={"questionBankTc right-side-container section-background section-border"}>
                        <Stack horizontal horizontalAlign={"stretch"}>
                            <Stack.Item grow>
                                <Label>All questions</Label>
                            </Stack.Item>
                            <IconButton
                                iconProps={{ iconName: "chromeclose" }}
                                title="close"
                                onClick={() => setQuestionBankDisplay(false)}
                            />
                        </Stack>
                        <Stack.Item className={"quiz-question-List height-100per"}>
                            <QuestionBankListView
                                inSearchMode={true}
                                onSelectQuestion={(item: QuizQuestionListItem) => addQuestions(item)}
                                quizQuestionList={formik.values.questionList}
                                getUpdatedQuizQuestion={(question: QuizQuestionListItem) =>
                                    updateQuestionList(question)
                                }
                                isSocketQuiz={
                                    formik.values.quizType === SiteConstants.socketQuiz ||
                                    formik.values.quizType === SiteConstants.wildgoat
                                }
                            />
                        </Stack.Item>
                    </Stack>
                )}
                <Panel
                    isLightDismiss
                    isOpen={showAddEditQuestionPanel}
                    onDismiss={() => {
                        setAddEditQuestionPanelDisplay(false);
                        setSelectedQuestionId(-1);
                    }}
                    headerText={selectedQuestionId === -1 ? "Add Question" : "Edit Question"}
                    type={PanelType.large}>
                    <QuestionDetailView
                        questionId={selectedQuestionId}
                        closeAddQuestionPanel={() => {
                            return;
                        }}
                        fetchQuestionList={() => {
                            return;
                        }}
                        refreshQuestionDetails={refreshQuizListDetails}
                    />
                </Panel>
            </Stack>
        );
    };

    const getQuizSummarySection = (): JSX.Element => (
        <Stack
            style={{
                backgroundColor:
                    quizId !== -1 && accessibilityScope && ["limited", "global"].includes(accessibilityScope)
                        ? dangerColorLight
                        : ""
            }}
            className={"section-background section-border max-height-75vh overflowy-auto"}>
            <Label>Summary</Label>
            <Stack.Item grow>
                <TextEditor
                    className={`${ADVANCE_TEXT_EDITOR_CLASS} quiz-summary quill-blue-background quizSummrayTc`}
                    value={formik.values.summary}
                    onChange={(text: string) => formik.setFieldValue("summary", text)}
                />
            </Stack.Item>
        </Stack>
    );

    return (
        <>
            <ToastContainer />
            {showLoadingSpinner ? (
                <Spinner className={"loading-spinner"} size={SpinnerSize.large} label={"Loading..."} />
            ) : (
                <Stack tokens={ThemedMediumStackTokens}>
                    <Pivot
                        className={"quizPivotTc"}
                        onLinkClick={(pivot?: PivotItem) => setActivePivot(pivot?.props.itemKey)}>
                        <PivotItem
                            headerText={"Game Settings"}
                            itemKey={SiteConstants.QuizSettingsPivot}
                            className={"margin-top-10"}>
                            {getQuizSettingsSection()}
                        </PivotItem>

                        <PivotItem
                            headerText={"Select Question"}
                            itemKey={SiteConstants.QuizSelectQuestionPivot}
                            className={"margin-top-10"}>
                            {getQuizQuestionSelectSection()}
                        </PivotItem>
                        {quizId !== -1 && (
                            <PivotItem
                                headerText={"Attachments"}
                                itemKey={SiteConstants.QuizAttachmentPivot}
                                className={"margin-top-10"}>
                                <Stack
                                    style={{
                                        backgroundColor:
                                            quizId !== -1 &&
                                            accessibilityScope &&
                                            ["limited", "global"].includes(accessibilityScope)
                                                ? dangerColorLight
                                                : ""
                                    }}
                                    className={"section-background section-border height-70vh overflowy-auto"}>
                                    <QuizAttachmentListView quizId={quizId} />
                                </Stack>
                            </PivotItem>
                        )}
                        {quizId !== -1 && (
                            <PivotItem
                                headerText={"Game Template"}
                                itemKey={SiteConstants.QuiztemplatePivot}
                                className={"margin-top-10"}>
                                {getQuizTemplateSection()}
                            </PivotItem>
                        )}
                        <PivotItem
                            headerText={"Summary"}
                            itemKey={SiteConstants.QuizSummaryPivot}
                            className={"margin-top-10"}>
                            {getQuizSummarySection()}
                        </PivotItem>
                    </Pivot>
                    {activePivot !== SiteConstants.QuiztemplatePivot && (
                        <>
                            <Stack horizontal horizontalAlign={"center"}>
                                {formik.isSubmitting ? <Spinner size={SpinnerSize.large} /> : renderSaveButton()}
                            </Stack>

                            <Stack horizontal horizontalAlign={"center"} className={"margin-bottom-15"}>
                                {!formik.isValid && (
                                    <span className={"error-message submitErrorMessageTc"}>
                                        Please fill in all the mandatory fields
                                    </span>
                                )}
                            </Stack>
                        </>
                    )}
                </Stack>
            )}
        </>
    );
};

export default QuizDetailView;

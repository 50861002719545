import { Dialog, DialogContent, Grid, IconButton } from "@mui/material";
import { FC, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useHistory } from "react-router";
import { whiteColor } from "../../../../../constants/Styles";
import { CancelButton, CaptionText, Header1, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import { GameButton } from "../../eventmanager/addeventdetail/Style";
import { GameOptionType } from "../../eventmanager/addeventdetail/Type";

type GameTypeModalProps = {
    handleClose: () => void;
    heading: string;
    subHeading: string;
    type: string;
    gameOptions: GameOptionType[];
};
const GameTypeModal: FC<GameTypeModalProps> = ({ handleClose, heading, subHeading, type, gameOptions }) => {
    const [selectedGame, setSelectedGame] = useState("");
    const history = useHistory();

    const renderImage = (
        gameType: string,
        currentGameType: string,
        activeIcon?: JSX.Element,
        inActiveIcon?: JSX.Element
    ) => {
        if (gameType === currentGameType) {
            return <>{inActiveIcon}</>;
        }
        return <>{activeIcon}</>;
    };

    const handleSubmit = () => {
        history.push(`/admin/${type}/${selectedGame}/add`);
    };

    return (
        <Dialog
            PaperProps={{
                style: {
                    borderRadius: 10
                }
            }}
            open
            maxWidth={"md"}
            fullWidth
            onClose={handleClose}>
            <Grid p={"20px"} container justifyContent={"flex-end"}>
                <IconButton onClick={handleClose}>
                    <AiOutlineClose />
                </IconButton>
            </Grid>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Header1 fontsize="28px" textAlign={"center"}>
                            {heading}
                        </Header1>
                        <CaptionText textAlign={"center"}>{subHeading}</CaptionText>
                    </Grid>
                </Grid>
                <Grid mt={"30px"} key={selectedGame} justifyContent={"center"} container gap={"20px"}>
                    {gameOptions
                        .filter((item) => item.toShow)
                        .map((item) => (
                            <GameButton
                                textAlign={"center"}
                                item
                                variant={"primary"}
                                key={`${item.id}`}
                                isactive={selectedGame === item.value}
                                onClick={() => setSelectedGame(item.value)}>
                                {renderImage(selectedGame, item.value, item.icon, item.inActiveicon)}
                                <CaptionText
                                    mt={"5px"}
                                    color={selectedGame === item.value ? whiteColor : "#1F71B6"}
                                    textAlign={"center"}>
                                    {item.title}
                                </CaptionText>
                            </GameButton>
                        ))}
                </Grid>
                <Grid
                    mt={"50px"}
                    mb={"40px"}
                    alignItems={"center"}
                    spacing={"10px"}
                    container
                    justifyContent={"center"}>
                    <Grid item xs={2}>
                        <CancelButton fontsize="16px" fullWidth onClick={handleClose}>
                            Cancel
                        </CancelButton>
                    </Grid>
                    <Grid item xs={2}>
                        <PrimaryButton fontsize="16px" disabled={selectedGame === ""} onClick={handleSubmit} fullWidth>
                            Next
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default GameTypeModal;

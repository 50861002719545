import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import MiniEventGameView from "./Game/MiniEventGameView";

const MiniEventRoutes = () => {
    return (
        <>
            <ToastContainer />
            <BrowserRouter>
                <Switch>
                    <Route exact path={"/mini/participant/quiz"}>
                        <MiniEventGameView />
                    </Route>
                </Switch>
            </BrowserRouter>
        </>
    );
};

export default MiniEventRoutes;

import { Stack } from "office-ui-fabric-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { io } from "socket.io-client";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { getRemainingTime } from "../../../../utils/TimeUtils";
import { Client } from "../../../Base/Client";
import QuizDocumentModal from "../QuizDocumentModal";
import {
    CurrentQuestionTransformData,
    QuizAttachmentTransformData,
    QuizConfigTransformData,
    QuizInfoTransformData,
    QuizUserSessionInfoTransformData
} from "../RocketTemplateTransformedData";
import {
    QuizConfigInitialValues,
    QuizInfoInitialValues,
    QuizUserSessionInfoInitialValues,
    SelectedDocumentInitialValues,
    TimerEndInitialValues
} from "../RocketTemplateUtils/RocketTemplateInitialValues";
import {
    attachmentType,
    currentQuestionInfoType,
    hintType,
    QuizConfigType,
    QuizInfoType,
    QuizUserSessionInfoType,
    SelectedDocumentType,
    TimersEndType
} from "../RocketTemplateUtils/RocketTemplateTypes";
import BackgroundImage from "../../../Images/background.png";
import "../RocketTemplateView.scss";
import { Col, Row } from "react-bootstrap";
import QuizInfo from "../QuizInfo";
import QuizTitleLogoView from "../QuizTitleLogoView";
import QuizDocument from "../QuizDocument";
import QuizSummaryTextView from "../QuizEndTextView";
import SocketQuestionView from "./SocketQuestionView";
import { CONNECTOR } from "../../../../data/question/types";
import {
    EventClosedMessage,
    NextQuestionTimeOut,
    SiteConstants,
    SocketGame
} from "../../../../constants/SiteConstant";
import SocketHintsView from "./SocketHintsView";
import SocketGameAnswerView from "./SocketGameAnswerView";
import { fetchQuizInfo } from "./api/api";
import {
    socketCurrentQuestionTransformData,
    socketQuestionHintsTransformData,
    socketQuizUserSessionInfoTransformData
} from "./util/SocketTransformData";
import { socketBooleanStatesInitialValues, socketBooleanStatesType } from "./util/SocketInitialValues";
import { toast, ToastContainer } from "react-toastify";
import FileUploadAnswerView from "./FileUpload/FileUploadAnswerView";
import NoInternetConnectionModal from "../NoInternetConnectionModal";
import { Spinner } from "@fluentui/react";
import SocketGameAnswerHistory from "./SocketGameAnswerHistory";
import { Grid } from "@mui/material";

const SocketGameView = () => {
    const info: any = useLocation();
    const history = useHistory();
    const [quizInfoData, setQuizInfoData] = useState<QuizInfoType>(QuizInfoInitialValues);
    const [quizUserSessionInfoData, setQuizUserSessionInfoData] = useState<QuizUserSessionInfoType>(
        QuizUserSessionInfoInitialValues
    );
    const [quizConfig, setQuizConfig] = useState<QuizConfigType>(QuizConfigInitialValues);
    const [selectedDocument, setSelectedDocument] = useState<SelectedDocumentType>(SelectedDocumentInitialValues);
    const [timerEnd, setTimerEnd] = useState<TimersEndType>(TimerEndInitialValues);
    const [answerText, setSelectedAnswerText] = useState("");
    const [hints, setHint] = useState<hintType[]>([]);
    const [attachment, setAttachment] = useState<attachmentType[]>([]);
    const [socketBooleanStates, setSocketBooleanStates] = useState<socketBooleanStatesType>(
        socketBooleanStatesInitialValues
    );
    const [isQuestionHasTime, setIsQuestionHasTime] = useState(false);
    const [currentQuestionInfo, setCurrentQuestionInfo] = useState<currentQuestionInfoType>();
    const [questionTiming, setQuestionTiming] = useState("00:00:00");
    const [totalTimeTakeninEvent, setTotalTimeTakenInEvent] = useState("");
    const [refreshInfo, setRefreshInfo] = useState("initialKey");
    const [answerResultType, setAnswerResultType] = useState("");
    const [internetLost, setInternetLost] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [uploadPercent, setUploadPercent] = useState(0);
    const questionInitialTime = useRef<string>("");
    const socket = useRef<any>();

    useEffect(() => {
        if (navigator.onLine) {
            return setInternetLost(false);
        }
        return setInternetLost(true);
    }, [navigator.onLine]);

    const startSocket = (eventTimeLimit: string) => {
        socket.current = io(ApiConstants.connectSocket(), {
            transports: ["websocket"]
        });
        socket.current.on("connect", () => {
            socket.current.emit("joinTeamEvent", {
                eventId: info.state.eventId,
                participantCode: info.state.code
            });
        });

        socket.current.on("fetchNextQuestionEvent", (data: any) => {
            fetchSocketNextQuestion(data, eventTimeLimit);
        });

        socket.current.on("answerSubmittedEvent", (data: any) => {
            if (data) {
                setSocketBooleanStates((prevState) => ({
                    ...prevState,
                    disableSubmit: true,
                    answerGiven: true,
                    isVideoPlayed: false
                }));
                setSelectedAnswerText(data.submittedAnswer);
                setAnswerResultType(data.result.userAttemptType);
                if (
                    data.result.questionMaxAttemptCount === data.result.questionAttemptCount ||
                    data.result.userAttemptType === "correct"
                ) {
                    localStorage.setItem("currentAnswerHistory", JSON.stringify([]));
                } else {
                    data.result.userAttemptType === "wrong" &&
                        localStorage.setItem(
                            "currentAnswerHistory",
                            JSON.stringify([
                                ...JSON.parse(localStorage.getItem("currentAnswerHistory") || "[]"),
                                {
                                    submittedAnswer: data.submittedAnswer,
                                    participantName: data.participantName
                                }
                            ])
                        );
                }

                setRefreshInfo(Math.random().toString());
                setQuizUserSessionInfoData((prevState) => ({
                    ...prevState,
                    totalHintUsed: data.result.hintsUsedCount,
                    totalMarks: data.result.quizScore,
                    eventTimer: data ? getRemainingTime(eventTimeLimit, data.result.timeTakenInQuiz) : "",
                    correctAnswerCount: data.result.correctAnswersCount,
                    wrongAnswerCount: data.result.wrongAnswersCount
                }));
                if (data.result.userAttemptType === "timeout") {
                    setTimerEnd((prevState) => ({ ...prevState, questionTimeEnds: true }));
                    toast.success(`Question time ends`, SiteConstants.successToastConfiguration);
                } else {
                    toast.success(`Answer given by ${data.participantName}`, SiteConstants.successToastConfiguration);
                }

                setTimeout(() => {
                    if (data.participantName === info.state.participantName) {
                        fetchNextQuestion(eventTimeLimit);
                    }
                }, NextQuestionTimeOut);
            }
        });

        socket.current.on("hintUsed", (data: any) => {
            setQuizUserSessionInfoData((prevState) => ({
                ...prevState,
                hintsAlreadyUsed: data.result.usedQuestionHintIds,
                totalHintUsed: data.result.hintsUsedCount,
                totalMarks: data.result.quizScore,
                hintUsed:
                    data.result.usedQuestionHintIds === ""
                        ? 0
                        : data.result.usedQuestionHintIds?.split(",").length || 0,
                eventTimer: getRemainingTime(eventTimeLimit, data.result.timeTakenInQuiz)
            }));
            setQuestionTiming(getRemainingTime(questionInitialTime.current, data.result.timeTakenInQuestion));
            setRefreshInfo(Math.random().toString());
            toast.success(`Hint taken by ${data.participantName}`, SiteConstants.successToastConfiguration);
        });
    };

    useEffect(() => {
        fetchQuizData();
    }, []);

    const checkEventEnded = (message: string) => {
        message === EventClosedMessage &&
            history.push("/thankyou", {
                eventClosed: true
            });
    };

    const fetchSocketNextQuestion = (data: any, eventTime: string, initialProgress = 0) => {
        setSelectedAnswerText("");
        setSocketBooleanStates((prevState) => ({
            ...prevState,
            disableSubmit: false,
            answerGiven: false,
            hintDisable: false,
            loading: false,
            isVideoPlayed: false
        }));
        setIsQuestionHasTime(false);
        setAnswerResultType("");
        setTimerEnd((prevState) => ({ ...prevState, questionTimeEnds: false }));
        setTimerEnd((prevState) => ({ ...prevState, connectorQuizEnds: false }));
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, hintUsed: 0, hintsAlreadyUsed: "" }));
        if (data.message) {
            setSocketBooleanStates((prevState) => ({ ...prevState, endQuiz: true }));
            if (data.userQuizSessionInfo.userAttemptType === "quiz-timeout") {
                setTotalTimeTakenInEvent("00:00:00");
                return;
            }
            setTotalTimeTakenInEvent(getRemainingTime(eventTime, data.userQuizSessionInfo.timeTakenInQuiz));
            return;
        }
        data.nextQuestionInfo && data.nextQuestionInfo.timeLimit === "00:00:00"
            ? setIsQuestionHasTime(false)
            : setIsQuestionHasTime(true);
        data.nextQuestionInfo && (questionInitialTime.current = data.nextQuestionInfo.timeLimit);
        if (data?.userQuizSessionInfo?.timeTakenInQuestion) {
            data.nextQuestionInfo &&
                setQuestionTiming(
                    getRemainingTime(data.nextQuestionInfo.timeLimit, data.userQuizSessionInfo.timeTakenInQuestion)
                );
        } else {
            data.nextQuestionInfo && setQuestionTiming(data.nextQuestionInfo.timeLimit);
        }
        setCurrentQuestionInfo(socketCurrentQuestionTransformData(data.nextQuestionInfo));
        currentQuestionTransformData(data.nextQuestionInfo);
        if (data.userQuizSessionInfo) {
            setQuizUserSessionInfoData(socketQuizUserSessionInfoTransformData(eventTime, data.userQuizSessionInfo));
        } else {
            setQuizUserSessionInfoData((prevState) => ({ ...prevState, eventTimer: eventTime }));
            setQuizUserSessionInfoData((prevState) => ({ ...prevState, totalMarks: initialProgress }));
        }
        setHint(socketQuestionHintsTransformData(data.nextQuestionInfo.questionHint));
        setRefreshInfo(FieldValidation.generateAlphaNumericCode());
    };

    useEffect(() => {
        if (timerEnd.eventEnds) {
            saveProgress(true, "quiz");
        }
    }, [timerEnd.eventEnds]);

    useEffect(() => {
        if (timerEnd.questionTimeEnds) {
            saveProgress(true, "question");
        }
    }, [timerEnd.questionTimeEnds]);

    const emitFetchNextQuestionEvent = (data: any) => {
        socket.current.emit("fetchNextQuestionEvent", {
            eventId: info.state.eventId,
            participantCode: info.state.code,
            nextQuestion: { ...data }
        });
    };

    useEffect(() => {
        if (quizConfig.tabTitle) {
            document.title = quizConfig.tabTitle;
        }
    }, [quizConfig.tabTitle]);

    const fetchQuizData = (): void => {
        fetchQuizInfo({
            eventLink: info.state.eventLink,
            code: info.state.code
        })
            .then((response) => {
                checkEventEnded(response.data.message);
                setQuizInfoData(QuizInfoTransformData(response));
                setQuizConfig(QuizConfigTransformData(response));
                setAttachment(QuizAttachmentTransformData(response));
                startSocket(response.data.data.quiz.timeLimit);
                fetchNextQuestion(
                    response.data.data.quiz.timeLimit,
                    response.data.data.quiz.initialScore ? response.data.data.quiz.initialScore : 0
                );
            })
            .catch((err) => {
                checkEventEnded(err.response.data.message);
            });
    };

    const fetchNextQuestion = (eventTime: string, initialProgress = 0): void => {
        setSelectedAnswerText("");
        setSocketBooleanStates((prevState) => ({
            ...prevState,
            disableSubmit: false,
            answerGiven: false,
            hintDisable: false,
            loading: true,
            isVideoPlayed: false
        }));
        setAnswerResultType("");
        setTimerEnd((prevState) => ({ ...prevState, questionTimeEnds: false }));
        setTimerEnd((prevState) => ({ ...prevState, connectorQuizEnds: false }));
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, hintUsed: 0, hintsAlreadyUsed: "" }));
        Client.getInstance()
            .getData(ApiConstants.fetchNextQuestionApiUrl(info.state.eventLink, info.state.code), true)
            .then((response: any) => {
                const data = response.data.data;
                if (!data.userQuizSessionInfo) {
                    localStorage.setItem("currentAnswerHistory", JSON.stringify([]));
                }
                if (data.message) {
                    checkEventEnded(data.message);
                    setSocketBooleanStates((prevState) => ({ ...prevState, endQuiz: true }));
                    emitFetchNextQuestionEvent(data);
                    if (data.userQuizSessionInfo.userAttemptType === "quiz-timeout") {
                        setTotalTimeTakenInEvent("00:00:00");
                        return;
                    }
                    setTotalTimeTakenInEvent(getRemainingTime(eventTime, data.userQuizSessionInfo.timeTakenInQuiz));
                    return;
                }
                data.nextQuestionInfo && data.nextQuestionInfo.timeLimit === "00:00:00"
                    ? setIsQuestionHasTime(false)
                    : setIsQuestionHasTime(true);
                data.nextQuestionInfo && (questionInitialTime.current = data.nextQuestionInfo.timeLimit);
                if (data?.userQuizSessionInfo?.timeTakenInQuestion) {
                    data.nextQuestionInfo &&
                        setQuestionTiming(
                            getRemainingTime(
                                data.nextQuestionInfo.timeLimit,
                                data.userQuizSessionInfo.timeTakenInQuestion
                            )
                        );
                } else {
                    data.nextQuestionInfo && setQuestionTiming(data.nextQuestionInfo.timeLimit);
                }
                if (data.userQuizSessionInfo) {
                    setQuizUserSessionInfoData(QuizUserSessionInfoTransformData(eventTime, response));
                } else {
                    setQuizUserSessionInfoData((prevState) => ({ ...prevState, eventTimer: eventTime }));
                    setQuizUserSessionInfoData((prevState) => ({ ...prevState, totalMarks: initialProgress }));
                }

                currentQuestionTransformData(data.nextQuestionInfo);
                setHint(socketQuestionHintsTransformData(data.nextQuestionInfo.questionHint));
                setRefreshInfo(FieldValidation.generateAlphaNumericCode());
                emitFetchNextQuestionEvent(data);
            })
            .catch((err) => {
                checkEventEnded(err.response.data.message);
                if (err.response.data.message === "Invalid participantCode") {
                    setSocketBooleanStates((prevState) => ({ ...prevState, endQuiz: true }));
                }
            })
            .finally(() => {
                setSocketBooleanStates((prevState) => ({ ...prevState, loading: false }));
                setInitialLoading(false);
            });
    };

    const currentQuestionTransformData = (data: any): void => {
        setSocketBooleanStates((prevState) => ({
            ...prevState,
            allowSkipping: true,
            hideTimer: data.timeLimit === "00:00:00"
        }));
        setCurrentQuestionInfo(CurrentQuestionTransformData(data));
    };

    const getEventTiming = (time: string): void => {
        if (time === "0:0:0" && !timerEnd.eventEnds) {
            setTimerEnd((prevState) => ({ ...prevState, eventEnds: true }));
            return;
        }
        time !== "" && setQuizUserSessionInfoData((prevState) => ({ ...prevState, eventTimer: time }));
    };

    const getQuestionTiming = (time: string): void => {
        if (time === "0:0:0" && !timerEnd.questionTimeEnds && isQuestionHasTime) {
            setTimerEnd((prevState) => ({ ...prevState, questionTimeEnds: true }));
            return;
        }
        time !== "" && setQuestionTiming(time);
    };

    const saveProgressForFileUpload = (files: File[]) => {
        setUploadPercent(0);
        setSocketBooleanStates((prevState) => ({ ...prevState, isFileUploadLoading: true }));
        setSocketBooleanStates((prevState) => ({ ...prevState, isFileUploadFailed: false }));
        const uploadData = new FormData();
        uploadData.append("teamName", info.state.teamName);
        uploadData.append("participantCode", info.state.code);
        uploadData.append("quizId", info.state.quizId);
        uploadData.append(
            "timeTakenInQuestion",
            getRemainingTime(
                currentQuestionInfo && currentQuestionInfo.timeLimit ? currentQuestionInfo.timeLimit : "00:00:00",
                questionTiming
            )
        );
        uploadData.append("eventId", info.state.eventId);
        uploadData.append("participantName", info.state.participantName);
        uploadData.append("questionId", currentQuestionInfo?.id || "");
        for (const i in files) {
            uploadData.append(`participantFiles[${i}]`, files[i]);
        }
        const options = {
            onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                const percent = Math.floor((loaded * 100) / total);
                setUploadPercent(percent);
            }
        };
        Client.getInstance()
            .createData(ApiConstants.uploadFileApiUrl(info.state.eventLink, info.state.code), uploadData, options)
            .then(() => {
                setSocketBooleanStates((prevState) => ({ ...prevState, isFileUploadLoading: false }));
            })
            .catch((err) => {
                setUploadPercent(0);
                setSocketBooleanStates((prevState) => ({ ...prevState, isFileUploadFailed: true }));
                setSocketBooleanStates((prevState) => ({ ...prevState, isFileUploadLoading: false }));
                err.response && checkEventEnded(err.response.data.message);
                toast.success("File upload fail. Please upload it again", SiteConstants.deleteToastConfiguration);
            });
    };

    const saveProgress = (timeout = false, type?: string) => {
        const data = {
            teamName: info.state.teamName,
            participantCode: info.state.code,
            participantName: info.state.participantName,
            quizId: info.state.quizId,
            timeTakenInQuestion: getRemainingTime(
                currentQuestionInfo && currentQuestionInfo.timeLimit ? currentQuestionInfo.timeLimit : "00:00:00",
                questionTiming
            ),
            timeTakenInQuiz: getRemainingTime(quizInfoData.initialEventTimer, quizUserSessionInfoData.eventTimer),
            submittedAnswer: answerText,
            eventId: info.state.eventId,
            questionId: currentQuestionInfo?.id,
            userAttemptType: timeout ? (type === "question" ? "timeout" : "quiz-timeout") : null
        };

        Client.getInstance()
            .createData(ApiConstants.socketQuestionSubmit(info.state.eventLink, info.state.code), data)
            .then(() => {
                setSocketBooleanStates((prevState) => ({ ...prevState, answerGiven: true }));
            })
            .catch((err) => {
                checkEventEnded(err.response.data.message || "");
            });
    };

    const hintSave = (): void => {
        if (quizUserSessionInfoData.hintUsed > hints.length - 1) {
            return;
        }
        saveProgressForHints(
            quizUserSessionInfoData.hintUsed === hints.length - 1
                ? hints[hints.length - 1].questionId.toString()
                : hints[quizUserSessionInfoData.hintUsed].questionId.toString()
        );
    };

    const saveProgressForHints = (hintId: string): void => {
        Client.getInstance()
            .createData(ApiConstants.socketHintUsed(info.state.eventLink, info.state.code), {
                eventId: info.state.eventId,
                teamName: info.state.teamName,
                participantCode: info.state.code,
                participantName: info.state.participantName,
                quizId: info.state.quizId,
                questionId: currentQuestionInfo?.id,
                timeTakenInQuestion: getRemainingTime(
                    currentQuestionInfo && currentQuestionInfo.timeLimit ? currentQuestionInfo.timeLimit : "00:00:00",
                    questionTiming
                ),
                timeTakenInQuiz: getRemainingTime(quizInfoData.initialEventTimer, quizUserSessionInfoData.eventTimer),
                hintId: hintId
            })
            .catch((err) => {
                checkEventEnded(err.response.data.message);
            });
    };

    const getQuestionView = (): JSX.Element => {
        if (currentQuestionInfo) {
            return (
                <SocketQuestionView
                    isProgressInPercentage={quizInfoData.showProgressInPercentage}
                    answerGiven={socketBooleanStates.answerGiven}
                    answerResult={answerResultType}
                    answerResultInfoImage={quizInfoData.answerResultImage}
                    answerResultInfoText={quizInfoData.answerResultText}
                    bottomQuestionText={currentQuestionInfo.bottomQuestionText}
                    correctAnswerPoints={
                        currentQuestionInfo.correctPoints
                            ? currentQuestionInfo.correctPoints.toString() :
                            quizInfoData.correctAnswerPoints
                    }
                    correctAnswerText={currentQuestionInfo.correctAnswerText}
                    gameBackgroundColor={quizConfig.backgroundColor}
                    coverImage={currentQuestionInfo.fileLocation}
                    isQuestionNonScoring={currentQuestionInfo.isNonScoringQuestion}
                    isQuestionTimeFinished={timerEnd.questionTimeEnds}
                    isTimerShow={socketBooleanStates.hideTimer}
                    questionText={currentQuestionInfo.questionText}
                    setQuestionTiming={getQuestionTiming}
                    showDocumentSelected={(coverImage) => {
                        setSelectedDocument((prevState) => ({
                            ...prevState,
                            documentSelected: coverImage,
                            selectedDocumentType: "jpg"
                        }));
                    }}
                    timeoutText={currentQuestionInfo.timeOutText}
                    timing={questionTiming}
                    totalScore={quizUserSessionInfoData.totalMarks.toString()}
                    wrongAnswerPoints={
                        currentQuestionInfo.wrongPoints
                            ? currentQuestionInfo.wrongPoints.toString()
                            : quizInfoData.wrongAnswerPoints
                    }
                    wrongAnswerText={currentQuestionInfo.wrongAnswerText}
                    key={refreshInfo}
                    videoLocation={currentQuestionInfo.videoQuestionFileLocation || ""}
                    setIsVideoPlayed={() => setSocketBooleanStates((prev) => ({ ...prev, isVideoPlayed: true }))}
                    correctAnswerBackgroundColor={quizConfig.correctAnswerBackgroundColor}
                    wrongAnswerBackgroundColor={quizConfig.wrongAnswerBackgroundColor}
                />
            );
        }
        return <span />;
    };
    const internetLostModal = useMemo(() => internetLost && <NoInternetConnectionModal />, [internetLost]);

    if (initialLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
                <Spinner className={"loading-component"} label="I am loading..." />
            </Grid>
        );
    }

    return (
        <>
            <ToastContainer />
            <Stack
                className={"main-container"}
                style={{
                    backgroundImage: `url('${quizConfig?.backgroundImage && quizConfig ? quizConfig?.backgroundImage : BackgroundImage
                        }')`
                }}>
                {selectedDocument.documentSelected !== "" && (
                    <div className={"document-cover-image-view position-absolute zIndex-9999 width-100per"}>
                        <div
                            className={
                                FieldValidation.imageTypes.includes(selectedDocument.selectedDocumentType)
                                    ? "document-cover-image-section"
                                    : "document-cover-image-section width-100per"
                            }>
                            <QuizDocumentModal
                                document={selectedDocument.documentSelected}
                                documentType={selectedDocument.selectedDocumentType}
                                documentClose={() => {
                                    setSelectedDocument((prevState) => ({
                                        ...prevState,
                                        documentSelected: "",
                                        selectedDocumentType: ""
                                    }));
                                }}
                                key={selectedDocument.documentSelected}
                            />
                        </div>
                    </div>
                )}
                <Stack
                    horizontal
                    className={
                        selectedDocument.documentSelected === "" || selectedDocument.selectedDocumentType === ""
                            ? "quiz-container"
                            : "quiz-container opacity-point3 pointer-event-none"
                    }>
                    <Row className={"height-100per width-100per margin-0"}>
                        <Col sm={3} className={"margin-0 padding-0 height-100per left-section-column"}>
                            <div className={"left-section-logo height-100per"}>
                                <QuizTitleLogoView
                                    eventLogo={quizInfoData.eventLogo}
                                    companyLogo={quizInfoData.companyLogo}
                                />
                            </div>
                            <div className={"left-section-info height-100per"}>
                                <QuizInfo
                                    gameInfoBackgroundColor={quizConfig.buttonColor}
                                    title={quizInfoData.quizTitle}
                                    quizType={SocketGame}
                                    correctAnswerCount={quizUserSessionInfoData.correctAnswerCount}
                                    wrongAnswerCount={quizUserSessionInfoData.wrongAnswerCount}
                                    timing={quizUserSessionInfoData.eventTimer}
                                    getTiming={getEventTiming}
                                    hintsUsed={quizUserSessionInfoData.totalHintUsed}
                                    eventFinishes={socketBooleanStates.endQuiz}
                                    totalTimeTakeninEvent={totalTimeTakeninEvent}
                                    referenceItemText1={
                                        currentQuestionInfo ? currentQuestionInfo.referenceItemText1 : ""
                                    }
                                    referenceItemText2={
                                        currentQuestionInfo ? currentQuestionInfo.referenceItemText2 : ""
                                    }
                                    key={refreshInfo}
                                    gameBackgroundColor={quizConfig.backgroundColor}
                                    showInfo={{
                                        showCorrectAnswerCount: quizInfoData.showCorrectAnswerCount,
                                        showWrongAnswerCount: quizInfoData.showWrongAnswerCount,
                                        showHintCount: quizInfoData.showHintCount,
                                        showMainTimer: quizInfoData.showMainTimer
                                    }}
                                />
                            </div>
                            <div className={"left-section-document height-100per"}>
                                <QuizDocument
                                    selectedDocumentType={(documentUrl: string, fileType: string) => {
                                        setSelectedDocument((prevState) => ({
                                            ...prevState,
                                            documentSelected: documentUrl,
                                            selectedDocumentType: fileType
                                        }));
                                    }}
                                    attachmentType={attachment}
                                    attachmentTitle={
                                        quizConfig && quizConfig?.attachmentTitle
                                            ? quizConfig?.attachmentTitle
                                            : "Ship Manuals"
                                    }
                                    gameBackgroundColor={quizConfig.backgroundColor}
                                />
                            </div>
                        </Col>
                        <Col sm={9} className={"margin-0 padding-0 height-100per"}>
                            {socketBooleanStates.endQuiz ? (
                                <QuizSummaryTextView
                                    gameBackgroundColor={quizConfig.backgroundColor}
                                    info={quizInfoData.summaryPageInfo}
                                    eventLink={info.state.eventLink}
                                    buttonColor={quizConfig.buttonColor}
                                    showSurveyForm={quizInfoData.showSurveyForm}
                                    eventId={info.state.eventId}
                                    partcipantName={info.state.participantName}
                                    participantCode={info.state.code}
                                />
                            ) : !socketBooleanStates.loading ? (
                                <div
                                    className={
                                        selectedDocument.documentSelected === "" ||
                                            selectedDocument.selectedDocumentType === ""
                                            ? "middle-section-question height-100per"
                                            : "middle-section-question height-100per opacity-point3"
                                    }>
                                    {getQuestionView()}
                                </div>
                            ) : (
                                <div
                                    className={
                                        selectedDocument.documentSelected === "" ||
                                            selectedDocument.selectedDocumentType === ""
                                            ? "middle-section-question height-100per"
                                            : "middle-section-question height-100per opacity-point3"
                                    }
                                />
                            )}

                            {!socketBooleanStates.endQuiz &&
                                currentQuestionInfo &&
                                currentQuestionInfo.type !== CONNECTOR && (
                                    <div
                                        className={
                                            selectedDocument.documentSelected === ""
                                                ? "middle-section-answer height-100per"
                                                : "middle-section-answer height-100per opacity-point3"
                                        }>
                                        <Row className={"height-100per"}>
                                            <Col
                                                className={"height-100per"}
                                                sm={
                                                    hints && hints.length > 0 && !socketBooleanStates.endQuiz
                                                        ? currentQuestionInfo.maxAttempts
                                                            ? 6
                                                            : 9
                                                        : currentQuestionInfo.maxAttempts
                                                            ? 9
                                                            : 12
                                                }>
                                                <Stack.Item grow={1} styles={SiteConstants.height100per}>
                                                    {currentQuestionInfo &&
                                                        (currentQuestionInfo.type === "file_upload" ? (
                                                            <FileUploadAnswerView
                                                                answerHeader={currentQuestionInfo.answerHeader}
                                                                gameBackgroundColor={quizConfig.backgroundColor}
                                                                fileUploadedByParticipant={saveProgressForFileUpload}
                                                                key={currentQuestionInfo.id}
                                                                isSubmitDisable={socketBooleanStates.disableSubmit}
                                                                uploadedPercentage={uploadPercent}
                                                                fileUploadLoading={
                                                                    socketBooleanStates.isFileUploadLoading
                                                                }
                                                                submitAnswer={saveProgress}
                                                                isFileUploadFailed={
                                                                    socketBooleanStates.isFileUploadFailed
                                                                }
                                                            />
                                                        ) : (
                                                            <SocketGameAnswerView
                                                                answerHeader={currentQuestionInfo.answerHeader}
                                                                gameBackgroundColor={quizConfig.backgroundColor}
                                                                isQuestionTimeEnds={timerEnd.questionTimeEnds}
                                                                saveAnswer={saveProgress}
                                                                setHintDisable={(hintViewType: boolean) => {
                                                                    setSocketBooleanStates((prevState) => ({
                                                                        ...prevState,
                                                                        hintDisable: hintViewType
                                                                    }));
                                                                }}
                                                                showSkipButton={
                                                                    socketBooleanStates.allowSkipping &&
                                                                    quizInfoData.quizAllowSkipping
                                                                }
                                                                updateParticipantAnswer={(answer: string) => {
                                                                    setSelectedAnswerText(answer);
                                                                }}
                                                                participantAnswer={answerText}
                                                                key={refreshInfo}
                                                                mcqOptions={currentQuestionInfo?.mcqOptions}
                                                                isSubmitDisable={socketBooleanStates.disableSubmit}
                                                                optionColor={quizConfig.buttonColor}
                                                                isVideoPlayed={socketBooleanStates.isVideoPlayed}
                                                                isVideoQuestion={
                                                                    !!currentQuestionInfo.videoQuestionFileLocation
                                                                }
                                                            />
                                                        ))}
                                                </Stack.Item>
                                            </Col>

                                            {hints && hints.length > 0 && !socketBooleanStates.endQuiz && (
                                                <Col sm={3} className={"socket-hint"}>
                                                    <div className={"height-100per"}>
                                                        <SocketHintsView
                                                            hintCount={quizUserSessionInfoData.hintUsed}
                                                            hints={hints}
                                                            hintDisable={socketBooleanStates.hintDisable}
                                                            gameBackgroundColor={quizConfig.backgroundColor}
                                                            showHint={hintSave}
                                                            questionTimeEnds={timerEnd.questionTimeEnds}
                                                            isProgressInPercentage={
                                                                quizInfoData.showProgressInPercentage
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                            )}
                                            {currentQuestionInfo.maxAttempts && (
                                                <Col sm={3} className={"socket-game-history"}>
                                                    <SocketGameAnswerHistory
                                                        gameBackgroundColor={quizConfig.backgroundColor}
                                                        mcqOptions={currentQuestionInfo.mcqOptions || []}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                )}
                        </Col>
                    </Row>
                </Stack>
            </Stack>
            {internetLostModal}
        </>
    );
};

export default SocketGameView;

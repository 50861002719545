/* eslint-disable jsx-a11y/alt-text */
import { Grid } from "@mui/material";
import { useHistory } from "react-router";
import { CaptionText, Header1 } from "../../../../CommonComponent/v2/CommonStyle";
import TabWrapper from "../../../../CommonComponent/v2/tab/Tab";
import ArrowLeft from "../../../../Images/icons/arrowLeft.svg";
import FileDetailDrawer from "../../filemanager/filedetail";
import LeaderboardList from "../../leaderboard/LeaderboardList";
import DownloadFile from "./downloadfile";
import EventCode from "./eventcodes";
import EventDetail from "./eventdetail";
import { useEventInfoHook } from "./Hooks";
import ParticpantEmail from "./participantemail";
import SurveyDetail from "./surveydetail";

const EventInfo = () => {
    const history = useHistory();
    const { eventDetailsTabs, eventDetails, fetchData, selectedTab, setSelectedTab, isLoading, eventId } =
        useEventInfoHook();
    const searchParams = new URLSearchParams(window.location.search);
    const renderView = () => {
        if (selectedTab === "detail") {
            return <EventDetail refreshDetails={fetchData} isLoading={isLoading} eventDetails={eventDetails} />;
        }
        if (selectedTab === "code") {
            return <EventCode />;
        }
        if (eventDetails && selectedTab === "files") {
            return (
                <FileDetailDrawer
                    eventInfo={{
                        eventId: parseInt(eventId),
                        eventName: eventDetails.eventName || ""
                    }}
                />
            );
        }
        if (eventDetails && selectedTab === "download") {
            return <DownloadFile eventId={eventId} />;
        }
        if (selectedTab === "survey") {
            return <SurveyDetail />;
        }
        if (selectedTab === "emails") {
            return <ParticpantEmail />;
        }
        if (selectedTab === "leaderboards") {
            return (
                <LeaderboardList
                    eventLink={eventDetails?.eventLink}
                    eventId={eventId}
                    isEventLive={!eventDetails?.isClosed}
                />
            );
        }
        return null;
    };

    return (
        <Grid px={"20px"}>
            <Grid container>
                <Grid item xs={12}>
                    <Header1>
                        <img
                            onClick={() =>
                                history.push(
                                    `/admin/event${
                                        searchParams.get("searchtext")
                                            ? `?searchtext=${searchParams.get("searchtext")} `
                                            : ""
                                    }`
                                )
                            }
                            src={ArrowLeft}
                            style={{ height: 18, marginRight: 10, cursor: "pointer" }}
                        />
                        Event Details
                    </Header1>
                </Grid>
                <Grid item xs={8} mt={"20px"}>
                    <CaptionText>You can review the event details here.</CaptionText>
                </Grid>
            </Grid>
            <Grid container spacing={"20px"} mt={"20px"}>
                <Grid item xs={12}>
                    <TabWrapper
                        tabsOptions={eventDetailsTabs}
                        selectedTab={selectedTab}
                        handleChange={setSelectedTab}
                    />
                </Grid>
            </Grid>
            <Grid mt={"20px"}>{renderView()}</Grid>
        </Grid>
    );
};

export default EventInfo;

import { Route } from "react-router-dom";
import PathChallengeView from "./component/loginflow/PathChallengeView";
import PathChallengeTeamName from "./component/loginflow/PathChallengeTeamName";
import PathChallengeQuizView from "./component/pathchallengequiz/PathChallengeQuizView";

const PathChallengeRoute = () => {
    return (
        <>
            <Route exact path="/event/pathchallenge/:pathId">
                <PathChallengeView />
            </Route>
            <Route exact path="/event/pathchallenge/:pathId/teamname">
                <PathChallengeTeamName />
            </Route>
            <Route exact path="/event/pathchallenge/:pathId/quiz">
                <PathChallengeQuizView />
            </Route>
        </>
    );
};

export default PathChallengeRoute;

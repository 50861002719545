export interface socketBooleanStatesType {
    endQuiz: boolean;
    allowSkipping: boolean;
    hideTimer: boolean;
    hintDisable: boolean;
    answerGiven: boolean;
    disableSubmit: boolean;
    loading: boolean;
    isVideoPlayed: boolean;
    isFileUploadLoading: boolean;
    isFileUploadFailed: boolean;
}

export const socketBooleanStatesInitialValues: socketBooleanStatesType = {
    endQuiz: false,
    allowSkipping: false,
    hideTimer: false,
    hintDisable: false,
    answerGiven: false,
    disableSubmit: false,
    loading: false,
    isVideoPlayed: false,
    isFileUploadLoading: false,
    isFileUploadFailed: false
};

import { Grid } from "@mui/material";
import { FC } from "react";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { useBranchQuizListHook } from "./Hooks";
import { BranchQuizType } from "./Type";

type BranchQuizListProps = {
    quizList: BranchQuizType[];
    selectedQuizId: string[];
    setSelectedQuizId: (quizId: string[]) => void;
};
const BranchQuizList: FC<BranchQuizListProps> = ({ quizList, selectedQuizId, setSelectedQuizId }) => {
    const { quizListColumns } = useBranchQuizListHook();
    return (
        <Grid height={"400px"} mt={"30px"}>
            <DataGridWrapper
                checkboxSelection
                selectionModel={selectedQuizId}
                onSelectionModelChange={(selection) => {
                    setSelectedQuizId(selection as string[]);
                }}
                disableSelectionOnClick
                columns={quizListColumns()}
                rows={quizList as any}
            />
        </Grid>
    );
};

export default BranchQuizList;

import React from "react";
import { WildGoatsCenterContainer } from "../CommonStyledComponents/CommonStyle";
import { ModalContainer, HeadText, SubText, CustomModal } from "../WildgoatDialog/Style";
import SkipImage from "../../../Images/Wildgoat/Skip.svg";
import EllipseImage from "../../../Images/Wildgoat/Ellipse.svg";
import { whiteColor } from "../../../../constants/Styles";
import { Modal } from "react-bootstrap";

const SkipAnswerView = () => {
    return (
        <CustomModal show={true} size="sm" centered>
            <Modal.Body>
                <ModalContainer style={{ background: whiteColor }}>
                    <WildGoatsCenterContainer style={{ marginBottom: 20 }}>
                        <img src={SkipImage} alt="modalImage" />
                        <img src={EllipseImage} alt="EllipseImage" />
                    </WildGoatsCenterContainer>
                    <HeadText>Skipped.</HeadText>
                    <SubText>You have successfully skipped this question.</SubText>
                </ModalContainer>
            </Modal.Body>
        </CustomModal>
    );
};

export default SkipAnswerView;

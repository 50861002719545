import { IDropdownOption, PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Client } from "../Base/Client";
import TextFieldWrapper from "../CommonComponent/TextFieldWrapper";
import { ApiConstants } from "../../constants/ApiConstant";
import { ThemedMediumStackTokens } from "../../constants/Styles";
import "./QuizSelectionView.scss";
import DropdownWrapper from "../CommonComponent/DropdownWrapper";

const QuizSelectionView = () => {
    const history = useHistory();
    const [teamName, setTeamName] = useState("");
    const [referenceListDropdown, setReferenceListDropdown] = useState<IDropdownOption[]>([]);
    const [referenceId, setReferenceId] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        Client.getInstance()
            .getData(ApiConstants.getReferenceListApiUrl(), true)
            .then((response) => {
                const referenceListData: IDropdownOption[] = response.data.data.map((referenceList: any) => ({
                    id: referenceList.id,
                    key: referenceList.id,
                    text: referenceList.name
                }));
                setReferenceListDropdown(referenceListData);
                setLoading(false);
            });
    }, []);

    const navigateToReferenceList = (): void => {
        if (referenceId === "") {
            return;
        }
        history.push("/participant/flighttemplate", { referenceListId: referenceId });
    };

    if (!loading) {
        return (
            <Stack horizontalAlign={"center"} className={"quizSelectionView-background"}>
                <Stack verticalAlign={"start"} className={"quizSelectionView-component-container"}>
                    <div className={"logo"}></div>
                    <Stack tokens={ThemedMediumStackTokens}>
                        <TextFieldWrapper
                            label={"Team name"}
                            onTextFieldValueChange={(text: string) => setTeamName(text)}
                            value={teamName}
                        />
                        <DropdownWrapper
                            label={"Select reference list"}
                            options={referenceListDropdown}
                            onChangeDropdownOption={(value: string) => setReferenceId(value)}
                            errorMessage={referenceId === "" ? "Please select a reference item" : ""}
                        />
                        <Stack horizontal horizontalAlign={"center"}>
                            <PrimaryButton text={"Submit to open Reference"} onClick={navigateToReferenceList} />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        );
    } else {
        return <Spinner className={"loading-component"} size={SpinnerSize.large} label={"I am loading..."} />;
    }
};
export default QuizSelectionView;

import { Spinner, SpinnerSize } from "@fluentui/react";
import { Grid } from "@mui/material";
import React, { FC } from "react";

type LoadingWrapperProps = {
    isLoading: boolean;
    children: React.ReactNode;
};

const LoadingWrapper: FC<LoadingWrapperProps> = ({ isLoading, children }) => {
    if (isLoading) {
        return (
            <Grid container height={"100%"} alignItems={"center"} justifyContent={"center"}>
                <Spinner size={SpinnerSize.large} />
            </Grid>
        );
    }
    return <>{children}</>;
};

export default LoadingWrapper;

import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { WEBSITE_GAME_URL } from "./constants";
import { quizzes } from "./dummyQuestion";
import { CaptionText, StartQuizButton } from "./style";
import { QuizType } from "./type";
import { cacheImages } from "./util";
import WebsiteGameEnd from "./WebsiteGameEnd";
import WebsiteGameQuestion from "./WebsiteGameQuestion";

const WebsiteGameView = () => {
    const [correctAnswerCount, setCorrectAnswerCount] = useState(0);
    const [questionCount, setQuestionCount] = useState(0);
    const [quizEnds, setQuizEnds] = useState(false);
    const [quiz, setQuiz] = useState<QuizType | undefined>(undefined);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const gameName = searchParams.get("game");
        const selectedQuiz = quizzes.find((quiz) => quiz.quizId === gameName);

        if (selectedQuiz) {
            cacheImages([...selectedQuiz.questions.map((question) => question.questionImageSrc), ...selectedQuiz.endings.map((ending) => ending.imageSrc)]);
            setQuiz(selectedQuiz);
        } else {
            setQuiz(quizzes[0]);
        }
    }, []);

    if (!quiz) {
        return <></>;
    }
    if (quizEnds) {
        return (
            <WebsiteGameEnd
                restartQuiz={() => window.open(WEBSITE_GAME_URL, "_self")}
                endingInfo={quiz.endings.find(
                    (item) => item.scoreMax >= correctAnswerCount && item.scoreMin <= correctAnswerCount
                )}
                correctAnswerCount={correctAnswerCount}
                totalQuestion={quiz.questions.length}
            />
        );
    }

    return (
        <Grid style={{ marginBottom: 50 }} container direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={12} md={5}>
                <Typography style={{ marginTop: 50 }} variant={"h4"} align="center">
                    {quiz.quizTitle}
                </Typography>
                <CaptionText style={{ marginTop: 50 }} align="center">
                    {quiz.quizDescription}
                </CaptionText>
                <div style={{ marginTop: 40 }}>
                    <WebsiteGameQuestion
                        key={questionCount}
                        questionNumber={questionCount}
                        totalQuestionCount={quiz.questions.length}
                        questionDetails={quiz.questions[questionCount]}
                        setCorrectAnswerCount={() => setCorrectAnswerCount((prev) => prev + 1)}
                    />
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
                    <StartQuizButton
                        onClick={() => {
                            if (quiz.questions.length === questionCount + 1) {
                                setQuizEnds(true);
                                return;
                            }
                            setQuestionCount((prev) => prev + 1);
                        }}>
                        Next Question
                    </StartQuizButton>
                </div>
            </Grid>
        </Grid>
    );
};

export default WebsiteGameView;

import { Stack } from "@fluentui/react";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import { ReferenceItemDetails } from "./ReferenceItemDataTypes";
import RefrenceItemDescriptionModal from "./RefrenceItemDescriptionModal";
import { Icon } from "@fluentui/react/lib/Icon";

type IParticipantRefernceViewNativeProps = {
    title: string;
    description: string;
    refrenceItems: ReferenceItemDetails[];
    showReferenceInfo: boolean;
    getReferenceInfo: () => void;
    descriptionImage: string;
    selectedDescriptionImage: (image: string) => void;
    gameBackgroundColor: string;
    buttonColor: string;
};

const ParticipantRefernceView: React.FC<IParticipantRefernceViewNativeProps> = ({
    title,
    description,
    refrenceItems,
    showReferenceInfo,
    getReferenceInfo,
    descriptionImage,
    selectedDescriptionImage,
    gameBackgroundColor,
    buttonColor
}) => {
    const [selectedReferenceIndex, setSelectedReferenceIndex] = useState(-1);

    const showDialog = (index: number) => {
        getReferenceInfo();
        setSelectedReferenceIndex(index);
    };

    if (!showReferenceInfo) {
        return (
            <Stack
                className={"refrence-view"}
                tokens={ThemedMediumStackTokens}
                style={{ backgroundColor: gameBackgroundColor }}>
                <h1>{title}</h1>
                {descriptionImage && (
                    <Stack horizontalAlign={"center"}>
                        <img
                            onClick={() => selectedDescriptionImage(descriptionImage)}
                            src={descriptionImage}
                            alt={"Description image"}
                            className={"max-width-400 cursor"}
                        />
                        <Icon
                            iconName="ZoomIn"
                            className={"zoom-image"}
                            onClick={() => selectedDescriptionImage(descriptionImage)}
                        />
                    </Stack>
                )}
                <div
                    className={
                        "refrence-info quill-editor-text-align margin-p-0 question-view-text image-width-100per quizQuestionview"
                    }
                    dangerouslySetInnerHTML={{
                        __html: description
                    }}
                />
                <Row>
                    {refrenceItems.map((item: ReferenceItemDetails, index: number) => (
                        <Col md={4} key={item.id}>
                            <Button
                                style={{ backgroundColor: buttonColor }}
                                className={"refrence-options cursor"}
                                onClick={() => showDialog(index)}>
                                {item.title}
                            </Button>
                        </Col>
                    ))}
                </Row>
            </Stack>
        );
    }

    return (
        <Stack
            className={"refrence-view"}
            style={{ backgroundColor: gameBackgroundColor }}
            tokens={ThemedMediumStackTokens}>
            <RefrenceItemDescriptionModal
                description={refrenceItems[selectedReferenceIndex].description}
                title={refrenceItems[selectedReferenceIndex].title}
                descriptionImage={refrenceItems[selectedReferenceIndex].descriptionImage}
                bottomDescriptionText={refrenceItems[selectedReferenceIndex].bottomDescriptionText}
                showDescriptionImageModal={() =>
                    selectedDescriptionImage(refrenceItems[selectedReferenceIndex].descriptionImage)
                }
                gameBackgroundColor={gameBackgroundColor}
            />
        </Stack>
    );
};

export default ParticipantRefernceView;

import React from "react";
import {
    DetailsRow,
    DirectionalHint,
    IColumn,
    IDetailsColumnRenderTooltipProps,
    IDetailsHeaderProps,
    IDetailsListProps,
    IRenderFunction,
    Label,
    SelectionMode,
    ShimmeredDetailsList,
    Sticky,
    StickyPositionType,
    TooltipHost
} from "@fluentui/react";
import { isEqual } from "lodash";

export type ISortingDetailsListProps<T> = {
    columns: IColumn[];
    items: T[];
    onRenderItemColumn: (item: T, index: number | undefined, column: IColumn | undefined) => JSX.Element;
    isLoading: boolean;
    setKey?: string;
    isSortingCaseSensitive?: boolean;
    showBlueBackgroundGrid?: boolean;
    selectionMode?: SelectionMode;
};

type ISortingDetailsListState<T> = {
    items: T[];
    columns: IColumn[];
};

export class SortingDetailsList<T> extends React.Component<ISortingDetailsListProps<T>, ISortingDetailsListState<T>> {
    public constructor(props: ISortingDetailsListProps<T>) {
        super(props);
        this.state = {
            items: this.props.items,
            columns: this._assignOnColumnClick(this.props.columns)
        };
    }

    public componentWillUpdate(nextProps: Readonly<ISortingDetailsListProps<T>>) {
        if (!isEqual(nextProps.items, this.state.items)) {
            this.setState({
                items: nextProps.items
            });
        }
        if (!isEqual(nextProps.columns, this.state.columns)) {
            this.setState({
                columns: this._assignOnColumnClick(nextProps.columns)
            });
        }
    }

    private renderFixedDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (headerProps, defaultRender) => {
        if (!headerProps || this.props.items.length === 0) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = (
            tooltipHostProps: any
        ) => {
            return (
                <TooltipHost directionalHint={DirectionalHint.topLeftEdge} content={tooltipHostProps.column.name}>
                    <Label
                        onClick={() => this._onColumnClick({}, tooltipHostProps.column)}
                        style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                        {tooltipHostProps.column.name}
                        {tooltipHostProps.column.isSorted ? (
                            tooltipHostProps.column.isSortedDescending ? (
                                <span style={{ marginLeft: 5 }}>&#x2191;</span>
                            ) : (
                                <span style={{ marginLeft: 5 }}>&#x2193;</span>
                            )
                        ) : (
                            ""
                        )}
                    </Label>
                </TooltipHost>
            );
        };
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced stickyBackgroundColor="transparent">
                {/*eslint-disable-next-line @typescript-eslint/no-non-null-assertion*/}
                {defaultRender!({
                    onRenderColumnHeaderTooltip,
                    ...headerProps
                })}
            </Sticky>
        );
    };

    public render(): JSX.Element {
        return (
            <ShimmeredDetailsList
                columns={this.state.columns}
                items={this.state.items || []}
                onRenderItemColumn={this.props.onRenderItemColumn}
                enableShimmer={this.props.isLoading}
                setKey={this.props.setKey}
                key={this.props.setKey}
                selectionMode={SelectionMode.none}
                onShouldVirtualize={() => false}
                onRenderRow={this.props.showBlueBackgroundGrid ? this._onRenderRow : undefined}
                onRenderDetailsHeader={this.renderFixedDetailsHeader}
                onColumnHeaderClick={this._onColumnClick}
            />
        );
    }

    private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
        return props ? <DetailsRow {...props} className={"grid-blue-background width-100per"} /> : null;
    };
    //eslint-disable-next-line
    private _onColumnClick = (_: any, column: any): void => {
        const { items, columns } = this.state;

        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = this._copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);

        this.setState({
            columns: newColumns,
            items: newItems
        });
    };

    private _copyAndSort = (items: T[], columnKey: string | undefined, isSortedDescending?: boolean): T[] => {
        const key = columnKey as keyof T;
        if (isSortedDescending) {
            return items.sort((a: T, b: T) => (this._getLowerCase(a[key]) < this._getLowerCase(b[key]) ? 1 : -1));
        }
        return items.sort((a: T, b: T) => (this._getLowerCase(a[key]) > this._getLowerCase(b[key]) ? 1 : -1));
    };

    private _getLowerCase(value: any): any {
        if (typeof value === "string") {
            return (value as string).toLowerCase();
        }
        return value;
    }

    private _assignOnColumnClick(columns: IColumn[]): IColumn[] {
        const copiedColumns = columns.slice();
        copiedColumns.forEach((copiedColumn) => {
            copiedColumn.onColumnClick = this._onColumnClick;
        });

        return copiedColumns;
    }
}

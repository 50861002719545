import { Box, Grid } from "@mui/material";
import { Scanner } from "@yudiel/react-qr-scanner";

type QRReaderViewProps = {
    submitAnswer: (qrCodeValue: string) => void;
};
const QRReaderView = ({ submitAnswer }: QRReaderViewProps) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box height={300} width={300}>
                    <Scanner
                        constraints={{
                            facingMode: "environment",
                            height: 300,
                            width: 300
                        }}
                        onScan={(result) => {
                            submitAnswer(result[0].rawValue);
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default QRReaderView;

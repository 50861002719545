import { object, string } from "yup";
import { Client } from "../../Base/Client";
import { ApiConstants } from "../../../constants/ApiConstant";
import { VerifyUserType } from "./type";

export const VerifyUserValidation = object().shape({
    verifyCode: string().min(6, "Enter 6 digits code").required("Enter Code"),
    email: string().email("Please enter correct email").required("Please enter email")
});

export const verifyUserInitialValue = (email?: string, verifyCode?: string) => ({
    email: email || "",
    verifyCode: verifyCode || ""
});

export const verifyUserEmail = (userData: VerifyUserType) => {
    return Client.getInstance()
        .createData(ApiConstants.verifyUserApiUrl(), {
            email: userData.email,
            verificationCode: userData.verifyCode
        })
        .then((res) => {
            return Promise.resolve({
                firstName: res.data.data.firstName,
                lastName: res.data.data.lasName,
                userId: res.data.data.userId
            });
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

import { Slide, toast } from "react-toastify";

export const STRIPE_PUBLISHABLE_KEY_OLD =
    "pk_test_51IdBOYSBw58eIWzWvNxUatyP7Ojj9H5eacYhKVFYwa8Mv2VrQN3cUwHg8pUBLdKmdvNnshai2uM8ZmADtbmZZBgz00e5eIsies";
export const STRIPE_PUBLISHABLE_KEY =
    "pk_live_51My8UiL0l3Tt8gQ6F99xtBiNbsmf5mfz0ecpDPeJzzBDojbKWYVcbNx1CjLkCYxJvdRL6Zjxsq3WqnE0FWf5Jppa002H979M2c";

export const MAP_KEY = "AIzaSyAJRh0e7vi82VU6lDebiV9xuAQqTv-Eegk";
export const WHAT3WORD_KEY = "1BNAIFRB";
export const NextQuestionTimeOut = 2500;
export const SaveProgressInterval = 10000;
export const LeaderBoardPollingInterval = 10000;
export const SocketGame = "simplesocket";
export const WildGoatGame = "wildgoat";
export const RocketGame = "rocket";
export const EventClosedMessage = "Event already closed";
export const MaxUploadFileSize = 100;
export const mobileBreakdownPoint = 576;
export const McqLoading = "loading";
export const UnSelectedButton = "unselected";
export const SelectedButton = "selected";
export const drawerWidth = 300;
export const superAdmin = "Super admin";
export const CODE_LENGTH = 6;
export const SCORE_PENALTY = "scorepenalty";
export const TIMEOUT_PENALTY = "timeoutpenalty";
export const QUESTION_TAB = "question";
export const QUIZ_TAB = "question";
export const QUESTION_PER_PAGE = 100;
export const BASIC_PLAN_AMOUNT = 10;

export class SiteConstants {
    public static successToastConfiguration = {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        draggable: true,
        transition: Slide,
        pauseOnFocusLoss: false,
        style: {
            backgroundColor: "rgba(0, 120, 212, 0.8)"
        }
    };
    public static deleteToastConfiguration = {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: false,
        draggable: true,
        transition: Slide,
        pauseOnFocusLoss: false,
        style: {
            backgroundColor: "rgba(218, 59, 1, 0.7)"
        }
    };

    public static correctOptionIconColor = {
        root: {
            color: "green"
        }
    };

    public static wrongOptionIconColor = {
        root: {
            color: "red"
        }
    };

    public static closeIconColor = {
        root: {
            color: "black"
        }
    };

    public static height100per = {
        root: {
            height: "100%"
        }
    };

    public static getMaskedTime(hours: string, minutes: string, seconds: string): string {
        return `${hours === "" ? "0" : hours}:${minutes === "" ? "0" : minutes}:${seconds === "" ? "0" : seconds}`;
    }

    public static EXACT_MATCH = "exact_match";

    public static listColumnSerialNumber = 30;
    public static listColumnIcon = 40;
    public static listColumn300 = 300;
    public static listColumn400 = 400;
    public static listColumn150 = 150;
    public static listColumn120 = 120;
    public static listColumn170 = 170;
    public static listColumn55 = 55;
    public static listColumnXSmall = 80;
    public static listColumnSmall = 100;
    public static listColumnMedium = 200;
    public static listColumnLarge = 500;
    public static listColumnXLarge = 700;
    public static listColumnXXLarge = 1000;

    public static ATLEAST_ONE_CORRECT = "atleast_one_correct";

    public static COVER_IMAGE = "coverImage";
    public static DESCRIPTION_IMAGE = "descriptionImage";
    public static AttachmentTypePDF = "pdf";
    public static PROGRESS_IMAGE = "progressImage";
    public static BACKGROUND_IMAGE = "backgroundImage";
    public static AnswerResultImage = "answerResultImage";

    public static gridFontColor = "white";

    public static QuizAttachmentPivot = "quizAttachmentPivot";
    public static QuizSettingsPivot = "quizSettingsPivot";
    public static QuizSelectQuestionPivot = "selectQuestionPivot";
    public static QuiztemplatePivot = "quiztemplatePivot";
    public static QuizSummaryPivot = "quizSummaryPivot";

    public static closedEventPivot = "closedEventPivot";
    public static activeEventPivot = "activeEventPivot";
    public static changePasswordEventPivot = "changePasswordEventPivot";

    public static connectorQuestion = "connector";
    public static spaceGameQuiz = "fullgame";
    public static socketQuiz = "simplesocket";
    public static wildgoat = "wildgoat";
}
export const IMAGE_QUESTION_TYPE = "image";
export const VIDEO_QUESTION_TYPE = "video";

export const UPLOAD_VIDEO_TYPE = "upload";
export const YOUTUBE_VIDEO_TYPE = "youtube";

export const optionArray = [
    {
        label: "All",
        value: "all"
    },
    {
        label: "Active",
        value: "active"
    },
    {
        label: "Deleted",
        value: "deleted"
    }
];

export const successToastConfiguration = (backgroundColor?: string) => ({
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    draggable: true,
    transition: Slide,
    pauseOnFocusLoss: false,
    style: {
        backgroundColor: backgroundColor || "rgba(0, 120, 212, 0.8)",
        height: 80
    }
});

export const QrCodeImage = (link: string, height: number, width: number) =>
    `http://api.qrserver.com/v1/create-qr-code/?data=${link}&size=${height}x${width}&bgcolor=black`;

export const VALID_IMAGE = ["image/jpg", "image/png", "image/gif", "image/jfif", "image/jpeg", "image/svg"];
export const VALID_VIDEO = [
    "video/mp4",
    "video/webm",
    "video/ogg",
    "video/mov",
    "video/avi",
    "video/flv",
    "video/wmv",
    "video/m4v",
    "video/mpeg4",
    "video/h264",
    "video/h265",
    "video/mkv",
    "video/hevc",
    "video/quicktime"
];
export const VALID_PDF = ["application/pdf"];

export const HIDE_SIDEBAR_URL = ["/admin/paymentlist", "/admin/subscription", "/admin/password"];

import { Dialog, Grid } from "@mui/material";
import { FC } from "react";
import { CaptionText, Header1, IconContainer } from "../../../../CommonComponent/v2/CommonStyle";
import { UrlDivider } from "../Style";
import { EventUrls } from "../Type";
import CopyIcon from "../../../../Images/icons/copy.svg";
import QrIcon from "../../../../Images/icons/qr.svg";
import DisableRocket from "../../../../Images/icons/rocketDisable.svg";
import QrCodeModal from "../../../../CommonComponent/QrCodeModal";
import { usePreviewAllLinksHook } from "./Hooks";
import CloseIcon from "../../../../Images/icons/close.svg";

type Props = {
    handleClose: () => void;
    urlLink: EventUrls;
    showReferenceLinks: boolean;
};
const PreviewAllLink: FC<Props> = ({ handleClose, urlLink, showReferenceLinks }) => {
    const { qrCodeLink, setQrCodeLink } = usePreviewAllLinksHook();
    const renderButton = (url: string) => {
        return (
            <Grid container mt={"10px"} justifyContent={"center"} gap={"15px"}>
                <Grid item>
                    <img
                        onClick={() => window.navigator.clipboard.writeText(url)}
                        src={CopyIcon}
                        style={{ height: 18, cursor: "pointer" }}
                    />
                </Grid>
                <Grid item>
                    <img onClick={() => setQrCodeLink(url)} src={QrIcon} style={{ height: 18, cursor: "pointer" }} />
                </Grid>
                <Grid item>
                    <img src={DisableRocket} onClick={() => window.open(url, "_blank")} style={{ height: 18, cursor: "pointer" }} />
                </Grid>
            </Grid>
        );
    };

    return (
        <Dialog maxWidth={"lg"} fullWidth open onClose={handleClose}>
            <Grid p={"60px"}>
                <Grid container justifyContent={"flex-end"}>
                    <IconContainer src={CloseIcon} onClick={handleClose} />
                </Grid>
                <Header1 style={{ cursor: "pointer" }} textAlign={"center"}>
                    Preview of all links
                </Header1>
                <CaptionText textAlign={"center"}>
                    You can find all your links here that can be accessed from the events manager too.
                </CaptionText>
                <Grid mt={"50px"} container spacing={"30px"}>
                    <Grid item xs={6}>
                        <CaptionText textAlign={"center"} fontWeight={700} color={"#333333"}>
                            Event Link
                        </CaptionText>
                        <CaptionText mt={"10px"} textAlign={"center"} color={"#333333"}>
                            {urlLink.eventUrl || "Link not found"}
                        </CaptionText>
                        {urlLink.eventUrl && renderButton(urlLink.eventUrl)}
                        {urlLink.eventUrl && <UrlDivider />}
                    </Grid>
                    {showReferenceLinks && (
                        <Grid item xs={6}>
                            <CaptionText textAlign={"center"} fontWeight={700} color={"#333333"}>
                                1st reference game
                            </CaptionText>
                            <CaptionText mt={"10px"} textAlign={"center"} color={"#333333"}>
                                {urlLink.firstReferenceUrl || "Link not found"}
                            </CaptionText>
                            {urlLink.firstReferenceUrl && renderButton(urlLink.firstReferenceUrl)}
                            {urlLink.firstReferenceUrl && <UrlDivider />}
                        </Grid>
                    )}

                    <Grid item xs={6}>
                        <CaptionText textAlign={"center"} fontWeight={700} color={"#333333"}>
                            Leaderboard
                        </CaptionText>
                        <CaptionText mt={"10px"} textAlign={"center"} color={"#333333"}>
                            {urlLink.leaderboardUrl || "Link not found"}
                        </CaptionText>
                        {urlLink.leaderboardUrl && renderButton(urlLink.leaderboardUrl)}
                        {urlLink.leaderboardUrl && <UrlDivider />}
                    </Grid>
                    {showReferenceLinks && (
                        <Grid item xs={6}>
                            <CaptionText textAlign={"center"} fontWeight={700} color={"#333333"}>
                                2nd reference game
                            </CaptionText>
                            <CaptionText mt={"10px"} textAlign={"center"} color={"#333333"}>
                                {urlLink.secondRefrenceUrl || "Link not found"}
                            </CaptionText>
                            {urlLink.secondRefrenceUrl && renderButton(urlLink.secondRefrenceUrl)}
                            {urlLink.secondRefrenceUrl && <UrlDivider />}
                        </Grid>
                    )}

                    <Grid item xs={6}>
                        <CaptionText textAlign={"center"} fontWeight={700} color={"#333333"}>
                            Participant files
                        </CaptionText>
                        <CaptionText mt={"10px"} textAlign={"center"} color={"#333333"}>
                            {urlLink.participantFilesUrl || "Link not found"}
                        </CaptionText>
                        {urlLink.participantFilesUrl && renderButton(urlLink.participantFilesUrl)}
                        {urlLink.participantFilesUrl && <UrlDivider />}
                    </Grid>
                </Grid>
            </Grid>
            {qrCodeLink && <QrCodeModal url={qrCodeLink} handleClose={() => setQrCodeLink("")} />}
        </Dialog>
    );
};

export default PreviewAllLink;

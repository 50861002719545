import {
    IDropdownOption,
    PrimaryButton,
    SpinnerSize,
    Stack,
    Spinner,
    Label,
    Image,
    DefaultButton,
    IconButton,
    Text,
    TextField,
    Pivot,
    PivotItem
} from "@fluentui/react";
import React, { useState, useEffect, useCallback } from "react";
import { Client } from "../../../Base/Client";
import DropdownWrapper from "../../../CommonComponent/DropdownWrapper";
import { ApiConstants } from "../../../../constants/ApiConstant";
import {
    ThemedMediumStackTokens,
    ThemedSmall2StackTokens,
    dashedBorderStyle,
    successButtonBackgroundColor,
    defaultButtonBackgroundColor
} from "../../../../constants/Styles";
import { FieldValidation } from "../../../../constants/FieldValidation";
import TextFieldWrapper from "../../../CommonComponent/TextFieldWrapper";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { toast, ToastContainer } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { useFormik } from "formik";
import * as yup from "yup";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { HIDE_MINI_EVENT_FLAG, extractHtmlContent } from "../../../../utils/JambarUtils";
import EventCodesView from "./EventCodesListView";
import DatePickerWrapper from "../../../CommonComponent/DatePickerWrapper";
import ConfirmationDialog from "../../../CommonComponent/ConfirmationDialog";
import ToggleFieldWrapper from "../../../CommonComponent/ToggleFieldWrapper";
import "./EventDetailsView.scss";
import EventFilesView from "./EventFilesView";
import Select from "react-select";
import { getReferenceCode, getReferenceId, selectStyle } from "./utils/helperFunctions";
import { dropDownType, referenceListType } from "./utils/type";
import { debounce } from "lodash";
import EventSurveyView from "./EventSurveyView";
import ParticipantEmailList from "./ParticipantEmailList";
import QrCodeModal from "../../../CommonComponent/QrCodeModal";
import LeaderBoardDetailsView from "../../Leaderboard/LeaderBoardDetails/LeaderBoardDetailsView";
import CropImageWrapper from "../../../CommonComponent/CropImage/CropImageWrapper";

type EventDetailsViewProps = {
    eventId: number;
    closeAddPanel: (id: number) => void;
    refreshEventsList: () => void;
};

type EventDetails = {
    eventName: string;
    startDate: string;
    endDate: string;
    noOfTeams: number | undefined;
    eventLink: string;
    quizId: string;
    generatedLink: string;
    leaderBoardLink: string;
    isDemo: boolean;
    selectedReferenceFirst: dropDownType | null;
    selectedReferenceSecond: dropDownType | null;
    referenceLinkFirst: string;
    referenceLinkSecond: string;
    showSurvey: boolean;
    participantFilesLink: string;
    isMini: boolean;
};

const getLinkAvailableSuccessMessage = (): JSX.Element => (
    <Stack className={"success-message"}>
        <Text variant="small">{"Link is available"}</Text>
    </Stack>
);

const EventDetailsView: React.FC<EventDetailsViewProps> = ({ eventId, closeAddPanel, refreshEventsList }) => {
    const [quizList, setQuizList] = useState<IDropdownOption[]>([]);
    const [logo, setLogo] = useState("");
    const [logoFile, setLogoFile] = useState<File | undefined>();
    const [companyLogo, setCompanyLogo] = useState("");
    const [companyLogoFile, setCompanyLogoFile] = useState<File | undefined>();
    const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);
    const [isLinkAvailable, setIsLinkAvailable] = useState(false);
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [imageUploadErrorMessage, setImageUploadErrorMessage] = useState("");
    const [companyImageUploadErrorMessage, setCompanyImageUploadErrorMessage] = useState("");
    const [showCloseConfirmationDialog, setShowCloseConfirmationDialog] = useState(false);
    const [isEventClosed, setIsEventClosed] = useState(false);
    const [isCheckingLeaderboardLinkAvailability, setIsCheckingLeaderboardLinkAvailability] = useState(false);
    const [isCheckingParticipantFileLinkAvailability, setIsCheckingParticipantFileLinkAvailability] = useState({
        isLoading: false,
        isAvailable: false
    });
    const [isLeaderBoardLinkAvaliable, setIsLeaderBoardLinkAvailable] = useState(false);
    const [currentLeaderBoardLink, setCurrentLeaderBoardLink] = useState("");
    const [currentParticipantFileLink, setCurrentParticipantFileLink] = useState("");
    const [currentEventLink, setCurrentEventLink] = useState("");
    const [referenceLists, setReferenceLists] = useState<referenceListType[]>([]);
    const [referenceListsOptions, setReferenceListsOptions] = useState<dropDownType[]>([]);
    const [debouncedEventLink, setDebouncedEventLink] = useState<string>("");
    const [debouncedLeaderboardLink, setDebouncedLeaderboardLink] = useState<string>("");
    const [selectedQuizType, setSelectedQuizType] = useState<string>("");
    const quiz_status = "active";
    const [showQrCodeModal, setShowQrCodeModal] = useState<string>("");

    const eventInitialValues: EventDetails = {
        startDate: JambarDateUtil.dateFormat(new Date(), "YYYY-MM-DD"),
        endDate: JambarDateUtil.returnNDateFromToday(14, "YYYY-MM-DD"),
        eventLink: "",
        eventName: "",
        noOfTeams: undefined,
        quizId: "",
        generatedLink: "",
        leaderBoardLink: "",
        isDemo: false,
        isMini: false,
        selectedReferenceFirst: null,
        selectedReferenceSecond: null,
        referenceLinkFirst: "",
        referenceLinkSecond: "",
        showSurvey: false,
        participantFilesLink: ""
    };

    const eventDetailsValidation = yup.object().shape(
        {
            startDate: yup.date().typeError("Please select a valid date").required("From date is mandatory"),
            endDate: yup
                .date()
                .typeError("Please select a valid date")
                .when(
                    "startDate",
                    (startDate: Date, schema: any) =>
                        startDate && schema.min(startDate, "To date should be greater than From date")
                )
                .required("To date is mandatory"),
            eventName: yup.string().required("Event name is required"),
            eventLink: yup.string().required("Event link is required"),
            noOfTeams: yup
                .mixed()
                .test("validateNoOfTeams", "Please enter a valid number", (value) => {
                    return value ? FieldValidation.integerValidation(value.toString()) : true;
                })
                .required("No of teams is mandatory"),
            quizId: yup.string().required("Game is mandatory"),
            generatedLink: yup
                .mixed()
                .test("validateLink", "Please enter valid event link", (value) => FieldValidation.urlValidation(value)),
            leaderBoardLink: yup
                .mixed()
                .test("validateLeaderboardLink", "Please enter valid leaderboard link", (value) =>
                    FieldValidation.urlValidation(`${window.location.origin}/leaderboard/${value}`)
                ),
            participantFilesLink: yup
                .mixed()
                .test("validateParticipantLink", "Please enter valid participant link", (value) =>
                    FieldValidation.urlValidation(
                        `${window.location.origin}/participant/files?participantFilesLink=${value}`
                    )
                )
        },
        [["startDate", "endDate"]]
    );

    const formik = useFormik({
        initialValues: eventInitialValues,
        onSubmit: () => onSaveEventDetails(),
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: eventDetailsValidation
    });

    useEffect(() => {
        Client.getInstance()
            .getData(ApiConstants.getAllQuizzesApiUrl(quiz_status), true)
            .then((response) => {
                const quizData: IDropdownOption[] = response.data.data.map((quiz: any) => ({
                    id: quiz.id,
                    key: quiz.id,
                    text: extractHtmlContent(quiz.title)
                }));
                setQuizList(quizData);
            });

        Client.getInstance()
            .getData(ApiConstants.getReferenceListApiUrl(), true)
            .then((response) => {
                setReferenceListsOptions(
                    response.data.data.map((element: any) => ({
                        value: element.id,
                        label: element.name
                    }))
                );

                setReferenceLists(
                    response.data.data.map((element: any) => ({
                        id: element.id,
                        name: element.name,
                        description: element.description,
                        referenceLink: element.referenceLink
                    }))
                );
            });

        if (eventId !== -1) {
            setIsContentLoading(true);
            Client.getInstance()
                .getData(ApiConstants.getEventDetailApiUrl(eventId.toString()), true)
                .then((response) => {
                    transformData(response.data.data);
                    setIsContentLoading(false);
                })
                .catch((error) => {
                    setIsContentLoading(false);
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
        }
    }, []);

    useEffect(() => {
        if (!formik.values.quizId) {
            return;
        }
        Client.getInstance()
            .getData(ApiConstants.getQuizDetailApiUrl(Number(formik.values.quizId)), true)
            .then((response: any) => {
                setSelectedQuizType(response.data.data.type);
                if (response.data.data.type !== SiteConstants.spaceGameQuiz) {
                    formik.setFieldValue("isDemo", false);
                }
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    }, [formik.values.quizId]);

    const transformData = (data: any): void => {
        formik.setValues({
            startDate: JambarDateUtil.dateFormat(data.eventStartDate, "YYYY-MM-DD"),
            endDate: JambarDateUtil.dateFormat(data.eventEndDate, "YYYY-MM-DD"),
            eventLink: data.eventLink ? data.eventLink : "",
            eventName: data.name,
            noOfTeams: data.teamCount,
            quizId: data.quizId,
            isMini: data.type === "mini",
            generatedLink: data.eventLink
                ? `${window.location.origin}/${data.type === "mini" ? "minievent" : "event"}/${data.eventLink}`
                : "",
            leaderBoardLink: data.leaderboardLink || "",
            isDemo: data.isDemo,
            showSurvey: data.showSurveyAtEnd,
            selectedReferenceFirst:
                data.referenceList.length !== 0
                    ? { value: data.referenceList[0].id, label: data.referenceList[0].name }
                    : null,
            selectedReferenceSecond:
                data.referenceList.length === 2
                    ? { value: data.referenceList[1].id, label: data.referenceList[1].name }
                    : null,
            referenceLinkFirst: data.referenceList.length !== 0 ? data.referenceList[0].referenceLink : "",
            referenceLinkSecond: data.referenceList.length === 2 ? data.referenceList[1].referenceLink : "",
            participantFilesLink: data.participantFilesLink || ""
        });
        setLogo(`${data.fileLocation}?key=${new Date().getTime()}`);
        setCompanyLogo(`${data.companyLogoFileLocation}?key=${new Date().getTime()}`);
        setIsEventClosed(data.isClosed);
        setDebouncedEventLink(data.eventLink ? data.eventLink : "");
        setCurrentLeaderBoardLink(data.leaderboardLink || "");
        setCurrentParticipantFileLink(data.participantFilesLink || "");
        setDebouncedLeaderboardLink(data.leaderboardLink || "");
        setCurrentEventLink(data.eventLink || "");
    };

    const onSaveEventDetails = (): void => {
        if (
            imageUploadErrorMessage ||
            logo === "" ||
            companyImageUploadErrorMessage ||
            formik.values.eventLink === ""
        ) {
            formik.setSubmitting(false);
            return;
        }
        if (eventId !== -1) {
            Client.getInstance()
                .updateData(ApiConstants.updateEventApiUrl(eventId.toString()), reverseTransformData())
                .then(() => {
                    formik.setSubmitting(false);
                    refreshEventsList();
                    toast.success("Event updated", SiteConstants.successToastConfiguration);
                })
                .catch((error) => {
                    formik.setSubmitting(false);
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                });
            return;
        }

        Client.getInstance()
            .createData(ApiConstants.addEventApiUrl(), reverseTransformData())
            .then((response) => {
                formik.setSubmitting(false);
                closeAddPanel(response.data.data.id);
                toast.success("Event added", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                formik.setSubmitting(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const reverseTransformData = (): any => {
        const eventData = new FormData();
        logoFile && eventData.append("eventImage", logoFile as Blob);
        companyLogoFile && eventData.append("companyLogo", companyLogoFile as Blob);
        const data: any = {
            quizId: formik.values.quizId,
            teamCount: formik.values.noOfTeams,
            eventStartDate: formik.values.startDate,
            eventEndDate: formik.values.endDate,
            name: formik.values.eventName,
            isDemo: formik.values.isDemo || formik.values.isMini,
            type: formik.values.isMini ? "mini" : "regular",
            showSurveyAtEnd: formik.values.showSurvey,
            referenceList: getReferenceId(
                referenceLists,
                formik.values.selectedReferenceFirst?.value ? formik.values.selectedReferenceFirst.value : 0,
                formik.values.selectedReferenceSecond?.value ? formik.values.selectedReferenceSecond.value : 0
            )
        };

        formik.values.leaderBoardLink && eventData.append("leaderboardLink", formik.values.leaderBoardLink);
        formik.values.participantFilesLink &&
            eventData.append("participantFilesLink", formik.values.participantFilesLink);

        if (formik.values.eventLink) {
            eventData.append("eventLink", formik.values.eventLink);
        }
        for (const key in data) {
            eventData.append(key, data[key]);
        }
        return eventData;
    };

    const selectImage = (): JSX.Element => {
        return (
            <span>
                <CropImageWrapper
                    label={"Client Logo"}
                    setNewCropedFile={(file: File, fileImgSrc: string) => {
                        setLogoFile(file);
                        setLogo(fileImgSrc);
                    }}
                    imgUrl={logo}
                    isMandatory
                    errorMessage={imageUploadErrorMessage}
                    setErrorMessage={setImageUploadErrorMessage}
                />
                {logo && (
                    <Stack.Item>
                        <Image src={logo} alt="Cover Image" className={"quiz-logo"} />
                    </Stack.Item>
                )}
            </span>
        );
    };
    const selectCompanyImage = (): JSX.Element => {
        return (
            <span>
                <CropImageWrapper
                    label={"Company Logo"}
                    setNewCropedFile={(file: File, fileImgSrc: string) => {
                        setCompanyLogoFile(file);
                        setCompanyLogo(fileImgSrc);
                    }}
                    imgUrl={companyLogo}
                    errorMessage={companyImageUploadErrorMessage}
                    setErrorMessage={setCompanyImageUploadErrorMessage}
                />
                {companyLogo && (
                    <Stack.Item>
                        <Image src={companyLogo} alt="Cover Image" className={"quiz-logo"} />
                    </Stack.Item>
                )}
            </span>
        );
    };

    const onCheckLinkAvailability = (): void => {
        if (currentEventLink === formik.values.eventLink && currentEventLink !== "") {
            setIsLinkAvailable(true);
            return;
        }
        setIsCheckingAvailability(true);
        Client.getInstance()
            .getData(ApiConstants.checkLinkAvailability(formik.values.eventLink), true)
            .then((response) => {
                if (response.data.success) {
                    setIsLinkAvailable(true);
                }
                setIsCheckingAvailability(false);
            })
            .catch((error) => {
                formik.setFieldError("generatedLink", getErrorMessage(error));
                setIsLinkAvailable(false);
                setIsCheckingAvailability(false);
            });
    };

    const onCloseEvent = (): void => {
        Client.getInstance()
            .updateData(ApiConstants.closeEventApiUrl(eventId), {})
            .then(() => {
                toast.success("Event closed", SiteConstants.successToastConfiguration);
                setIsEventClosed(true);
                refreshEventsList();
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setShowCloseConfirmationDialog(false);
            });
    };

    const onCheckLeaderBoardLinkAvailability = (): void => {
        if (currentLeaderBoardLink === formik.values.leaderBoardLink && currentLeaderBoardLink !== "") {
            setIsLeaderBoardLinkAvailable(true);
            return;
        }
        setIsCheckingLeaderboardLinkAvailability(true);
        Client.getInstance()
            .getData(ApiConstants.checkLeaderboardLinkAvailability(formik.values.leaderBoardLink), true)
            .then((response) => {
                response.data.success && setIsLeaderBoardLinkAvailable(true);
            })
            .catch((error) => {
                formik.setFieldError("leaderBoardLink", getErrorMessage(error));
            })
            .finally(() => {
                setIsCheckingLeaderboardLinkAvailability(false);
            });
    };

    const onCheckParticpinatFileLinkAvailability = (): void => {
        if (currentParticipantFileLink === formik.values.participantFilesLink && currentParticipantFileLink !== "") {
            setIsCheckingParticipantFileLinkAvailability({
                ...isCheckingParticipantFileLinkAvailability,
                isAvailable: true
            });
            return;
        }
        setIsCheckingParticipantFileLinkAvailability({
            ...isCheckingParticipantFileLinkAvailability,
            isLoading: true
        });

        Client.getInstance()
            .getData(ApiConstants.checkParticipantFileLinkAvailability(formik.values.participantFilesLink), true)
            .then((response) => {
                response.data.success &&
                    setIsCheckingParticipantFileLinkAvailability({
                        ...isCheckingParticipantFileLinkAvailability,
                        isAvailable: true
                    });
            })
            .catch((error) => {
                formik.setFieldError("participantFilesLink", getErrorMessage(error));
            })
            .finally(() => {
                setIsCheckingParticipantFileLinkAvailability({
                    ...isCheckingParticipantFileLinkAvailability,
                    isLoading: false
                });
            });
    };

    const toggleFields = (): JSX.Element => {
        return (
            <div className="display-flex">
                {HIDE_MINI_EVENT_FLAG && (
                    <div>
                        <ToggleFieldWrapper
                            label="Mini event"
                            checked={formik.values.isMini}
                            onChange={(value: boolean) => {
                                formik.setFieldValue("isMini", value);
                                formik.setFieldValue("isDemo", false);
                                formik.setFieldValue(
                                    "generatedLink",
                                    `${window.location.origin}/${value ? "minievent" : "event"}/${
                                        formik.values.eventLink
                                    }`
                                );
                            }}
                        />
                    </div>
                )}

                <div className="margin-left-40">
                    <ToggleFieldWrapper
                        disabled={selectedQuizType !== SiteConstants.spaceGameQuiz || formik.values.isMini}
                        label="Demo event"
                        checked={formik.values.isDemo}
                        onChange={(value: boolean) => formik.setFieldValue("isDemo", value)}
                    />
                </div>
                <div className="margin-left-40">
                    <ToggleFieldWrapper
                        label="Show survey"
                        checked={formik.values.showSurvey}
                        onChange={(value: boolean) => formik.setFieldValue("showSurvey", value)}
                    />
                </div>
            </div>
        );
    };

    const handleEventLinkChange = useCallback(
        debounce((text) => {
            formik.setFieldValue(
                "generatedLink",
                `${window.location.origin}/${formik.values.isMini ? "minievent" : "event"}/${text}`
            );
            setIsLinkAvailable(currentEventLink === text && currentEventLink !== "");
            setDebouncedEventLink(text);
        }, 500),
        [formik.values.isMini]
    );

    const handleLeaderboardLinkChance = useCallback(
        debounce((text) => {
            setIsLeaderBoardLinkAvailable(currentLeaderBoardLink === text && currentLeaderBoardLink !== "");
            setDebouncedLeaderboardLink(text);
        }, 500),
        []
    );

    const getEventDetailsSection = (): JSX.Element => (
        <Stack tokens={ThemedMediumStackTokens}>
            <Stack
                tokens={ThemedMediumStackTokens}
                className="section-background section-border padding-10 overflowy-auto">
                {eventId !== -1 && !isEventClosed && (
                    <Stack horizontalAlign={"end"}>
                        <PrimaryButton text={"Close This Event"} onClick={() => setShowCloseConfirmationDialog(true)} />
                    </Stack>
                )}
                {isEventClosed && <Label className={"event-closed-message"}>This event is now closed</Label>}
                <Stack horizontal>
                    {selectImage()}
                    {selectCompanyImage()}
                </Stack>
                {toggleFields()}
                <Stack tokens={ThemedMediumStackTokens}>
                    <TextField
                        value={formik.values.eventName}
                        label={"Name of the event"}
                        errorMessage={formik.errors.eventName}
                        onChange={formik.handleChange}
                        name={"eventName"}
                    />
                    <DropdownWrapper
                        options={quizList}
                        selectedKey={formik.values.quizId}
                        onChangeDropdownOption={(quizId: string) => formik.setFieldValue("quizId", quizId)}
                        label={"Choose your game"}
                        errorMessage={formik.errors.quizId}
                    />
                    <Stack.Item>
                        <Stack horizontal tokens={ThemedMediumStackTokens}>
                            <Label>Date range : </Label>
                            <Stack horizontal tokens={ThemedMediumStackTokens}>
                                <Stack.Item className={"width-35per"}>
                                    <DatePickerWrapper
                                        datePickerValue={
                                            formik.values.startDate ? new Date(formik.values.startDate) : undefined
                                        }
                                        onSelectDate={(date) =>
                                            formik.setFieldValue(
                                                "startDate",
                                                JambarDateUtil.dateFormat(date, "YYYY-MM-DD").toString()
                                            )
                                        }
                                        placeholder={"From"}
                                    />
                                    {formik.errors.startDate && (
                                        <span className={"error-message"}>{formik.errors.startDate}</span>
                                    )}
                                </Stack.Item>

                                <Stack.Item className={"width-35per"}>
                                    <DatePickerWrapper
                                        datePickerValue={
                                            formik.values.endDate ? new Date(formik.values.endDate) : undefined
                                        }
                                        onSelectDate={(date) =>
                                            formik.setFieldValue(
                                                "endDate",
                                                JambarDateUtil.dateFormat(date, "YYYY-MM-DD").toString()
                                            )
                                        }
                                        placeholder={"To"}
                                    />
                                    {formik.errors.endDate && (
                                        <span className={"error-message"}>{formik.errors.endDate}</span>
                                    )}
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </Stack.Item>

                    <TextField
                        onChange={formik.handleChange}
                        value={formik.values.noOfTeams?.toString()}
                        name={"noOfTeams"}
                        label={"No. of teams"}
                        errorMessage={formik.errors.noOfTeams}
                    />

                    <Stack horizontal horizontalAlign="center" tokens={ThemedSmall2StackTokens}>
                        <Stack.Item grow>
                            <TextFieldWrapper
                                onRenderDescription={() =>
                                    !isLinkAvailable || (formik.values.eventLink && formik.errors.generatedLink)
                                        ? null
                                        : getLinkAvailableSuccessMessage()
                                }
                                onTextFieldValueChange={(text: string) => {
                                    formik.setFieldValue("eventLink", text);
                                    formik.setFieldValue("leaderBoardLink", text);
                                    handleEventLinkChange(text);
                                    handleLeaderboardLinkChance(text);
                                }}
                                value={formik.values.eventLink}
                                label={"Event link"}
                                errorMessage={formik.errors.generatedLink}
                            />
                        </Stack.Item>
                        <Stack.Item
                            className={
                                (formik.values.eventLink && formik.errors.generatedLink) || isLinkAvailable
                                    ? "margin-top-8"
                                    : ""
                            }
                            align={
                                (formik.values.eventLink && formik.errors.generatedLink) || isLinkAvailable
                                    ? "center"
                                    : "end"
                            }>
                            {isCheckingAvailability ? (
                                <Spinner size={SpinnerSize.large} />
                            ) : (
                                <DefaultButton
                                    onClick={onCheckLinkAvailability}
                                    text={"Check Availability"}
                                    iconProps={{ iconName: isLinkAvailable ? "Accept" : "" }}
                                    styles={{
                                        root: {
                                            background: isLinkAvailable
                                                ? successButtonBackgroundColor
                                                : defaultButtonBackgroundColor,
                                            borderStyle: dashedBorderStyle
                                        }
                                    }}
                                    disabled={formik.values.eventLink === "" || !!formik.errors.generatedLink}
                                />
                            )}
                        </Stack.Item>
                    </Stack>
                    {formik.values.eventLink === "" && <span className={"error-message"}>Please enter event link</span>}
                </Stack>
                {formik.values.eventLink && (
                    <Stack horizontal wrap className={"margin-top-0"} tokens={ThemedSmall2StackTokens}>
                        <Stack.Item align={"center"}>
                            <Text>{formik.values.generatedLink}</Text>
                        </Stack.Item>
                        <IconButton
                            iconProps={{ iconName: "copy" }}
                            title={"Copy url"}
                            onClick={() => navigator.clipboard.writeText(formik.values.generatedLink)}
                            disabled={!!formik.errors.generatedLink}
                        />
                        <IconButton
                            iconProps={{ iconName: "QRCode" }}
                            title={"Open Qrcode"}
                            onClick={() => setShowQrCodeModal(formik.values.generatedLink)}
                            disabled={!!formik.errors.generatedLink}
                        />
                    </Stack>
                )}

                <Stack horizontal tokens={ThemedSmall2StackTokens}>
                    <Stack.Item grow>
                        <TextFieldWrapper
                            onRenderDescription={() =>
                                !isLeaderBoardLinkAvaliable ||
                                (formik.values.leaderBoardLink && formik.errors.leaderBoardLink)
                                    ? null
                                    : getLinkAvailableSuccessMessage()
                            }
                            onTextFieldValueChange={(text: string) => {
                                formik.setFieldValue("leaderBoardLink", text);
                                handleLeaderboardLinkChance(text);
                            }}
                            value={formik.values.leaderBoardLink}
                            label={"Leaderboard public link"}
                            errorMessage={formik.errors.leaderBoardLink}
                        />
                    </Stack.Item>
                    <Stack.Item
                        className={
                            (formik.values.leaderBoardLink && formik.errors.leaderBoardLink) ||
                            isLeaderBoardLinkAvaliable
                                ? "margin-top-8"
                                : ""
                        }
                        align={
                            (formik.values.leaderBoardLink && formik.errors.leaderBoardLink) ||
                            isLeaderBoardLinkAvaliable
                                ? "center"
                                : "end"
                        }>
                        {isCheckingLeaderboardLinkAvailability ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : (
                            <DefaultButton
                                onClick={onCheckLeaderBoardLinkAvailability}
                                text={"Check Availability"}
                                iconProps={{ iconName: isLeaderBoardLinkAvaliable ? "Accept" : "" }}
                                styles={{
                                    root: {
                                        background: isLeaderBoardLinkAvaliable
                                            ? successButtonBackgroundColor
                                            : defaultButtonBackgroundColor,
                                        borderStyle: dashedBorderStyle
                                    }
                                }}
                                disabled={formik.values.leaderBoardLink === "" || !!formik.errors.leaderBoardLink}
                            />
                        )}
                    </Stack.Item>
                </Stack>
                {debouncedLeaderboardLink && (
                    <Stack horizontal wrap className={"margin-top-0"} tokens={ThemedSmall2StackTokens}>
                        <Stack.Item align={"center"}>
                            <Text>{`${window.location.origin}/leaderboard/${debouncedLeaderboardLink}`}</Text>
                        </Stack.Item>
                        <IconButton
                            iconProps={{ iconName: "copy" }}
                            title={"Copy url"}
                            onClick={() =>
                                navigator.clipboard.writeText(
                                    `${window.location.origin}/leaderboard/${debouncedLeaderboardLink}`
                                )
                            }
                            disabled={!!formik.errors.leaderBoardLink}
                        />
                        <IconButton
                            iconProps={{ iconName: "QRCode" }}
                            title={"Open Qrcode"}
                            onClick={() =>
                                setShowQrCodeModal(`${window.location.origin}/leaderboard/${debouncedLeaderboardLink}`)
                            }
                            disabled={!!formik.errors.leaderBoardLink}
                        />
                    </Stack>
                )}
                <Stack horizontal tokens={ThemedSmall2StackTokens}>
                    <Stack.Item grow>
                        <TextFieldWrapper
                            onRenderDescription={() =>
                                !isCheckingParticipantFileLinkAvailability.isAvailable ||
                                (formik.values.participantFilesLink && formik.errors.participantFilesLink)
                                    ? null
                                    : getLinkAvailableSuccessMessage()
                            }
                            onTextFieldValueChange={(text: string) => {
                                formik.setFieldValue("participantFilesLink", text);
                            }}
                            value={formik.values.participantFilesLink}
                            label={"Participant file public link"}
                            errorMessage={formik.errors.participantFilesLink}
                        />
                    </Stack.Item>
                    <Stack.Item
                        className={
                            (formik.values.participantFilesLink && formik.errors.participantFilesLink) ||
                            isCheckingParticipantFileLinkAvailability.isAvailable
                                ? "margin-top-8"
                                : ""
                        }
                        align={
                            (formik.values.participantFilesLink && formik.errors.participantFilesLink) ||
                            isCheckingParticipantFileLinkAvailability.isAvailable
                                ? "center"
                                : "end"
                        }>
                        {isCheckingParticipantFileLinkAvailability.isLoading ? (
                            <Spinner size={SpinnerSize.large} />
                        ) : (
                            <DefaultButton
                                onClick={onCheckParticpinatFileLinkAvailability}
                                text={"Check Availability"}
                                iconProps={{
                                    iconName: isCheckingParticipantFileLinkAvailability.isAvailable ? "Accept" : ""
                                }}
                                styles={{
                                    root: {
                                        background: isCheckingParticipantFileLinkAvailability.isAvailable
                                            ? successButtonBackgroundColor
                                            : defaultButtonBackgroundColor,
                                        borderStyle: dashedBorderStyle
                                    }
                                }}
                                disabled={
                                    formik.values.participantFilesLink === "" || !!formik.errors.participantFilesLink
                                }
                            />
                        )}
                    </Stack.Item>
                </Stack>
                {formik.values.participantFilesLink && (
                    <Stack horizontal wrap className={"margin-top-0"} tokens={ThemedSmall2StackTokens}>
                        <Stack.Item align={"center"}>
                            <Text>{`${window.location.origin}/participant/files?participantFilesLink=${formik.values.participantFilesLink}`}</Text>
                        </Stack.Item>
                        <IconButton
                            iconProps={{ iconName: "copy" }}
                            title={"Copy url"}
                            onClick={() =>
                                navigator.clipboard.writeText(
                                    `${window.location.origin}/participant/files?participantFilesLink=${formik.values.participantFilesLink}`
                                )
                            }
                            disabled={!!formik.errors.leaderBoardLink}
                        />
                        <IconButton
                            iconProps={{ iconName: "QRCode" }}
                            title={"Open Qrcode"}
                            onClick={() =>
                                setShowQrCodeModal(
                                    `${window.location.origin}/participant/files?participantFilesLink=${formik.values.participantFilesLink}`
                                )
                            }
                            disabled={!!formik.errors.leaderBoardLink}
                        />
                    </Stack>
                )}
                <Stack>
                    <div>
                        <Label>Select first reference list</Label>
                        <Select
                            name="referenceList"
                            options={referenceListsOptions}
                            placeholder=""
                            value={formik.values.selectedReferenceFirst}
                            styles={selectStyle}
                            onChange={(value) => {
                                formik.setFieldValue("selectedReferenceFirst", value);
                                value?.value &&
                                    formik.setFieldValue(
                                        "referenceLinkFirst",
                                        getReferenceCode(referenceLists, value.value)
                                    );
                            }}
                        />
                    </div>
                </Stack>
                {formik.values.referenceLinkFirst && (
                    <Stack horizontal wrap className={"margin-top-0"} tokens={ThemedSmall2StackTokens}>
                        <Stack.Item align={"center"}>
                            <Text>{`${window.location.origin}/reflist/${debouncedEventLink}/${formik.values.referenceLinkFirst}`}</Text>
                        </Stack.Item>
                        <IconButton
                            iconProps={{ iconName: "copy" }}
                            title={"Copy url"}
                            onClick={() =>
                                navigator.clipboard.writeText(
                                    `${window.location.origin}/reflist/${debouncedEventLink}/${formik.values.referenceLinkFirst}`
                                )
                            }
                        />
                    </Stack>
                )}

                <Stack>
                    <div>
                        <Label>Select second reference list</Label>
                        <Select
                            name="referenceList"
                            options={referenceListsOptions}
                            placeholder=""
                            value={formik.values.selectedReferenceSecond}
                            styles={selectStyle}
                            onChange={(value) => {
                                formik.setFieldValue("selectedReferenceSecond", value);
                                value?.value &&
                                    formik.setFieldValue(
                                        "referenceLinkSecond",
                                        getReferenceCode(referenceLists, value.value)
                                    );
                            }}
                        />
                    </div>
                </Stack>
                {formik.values.referenceLinkSecond && (
                    <Stack horizontal wrap className={"margin-top-0"} tokens={ThemedSmall2StackTokens}>
                        <Stack.Item align={"center"}>
                            <Text>{`${window.location.origin}/reflist/${debouncedEventLink}/${formik.values.referenceLinkSecond}`}</Text>
                        </Stack.Item>
                        <IconButton
                            iconProps={{ iconName: "copy" }}
                            title={"Copy url"}
                            onClick={() =>
                                navigator.clipboard.writeText(
                                    `${window.location.origin}/reflist/${debouncedEventLink}/${formik.values.referenceLinkSecond}`
                                )
                            }
                        />
                    </Stack>
                )}
            </Stack>

            {!isEventClosed && (
                <Stack horizontalAlign={"center"}>
                    {formik.isSubmitting ? (
                        <Spinner size={SpinnerSize.large} />
                    ) : (
                        <PrimaryButton
                            iconProps={{ iconName: "Save" }}
                            text={"Save"}
                            onClick={() => formik.handleSubmit()}
                        />
                    )}
                </Stack>
            )}

            <Stack horizontal horizontalAlign={"center"} className={"margin-bottom-15"}>
                {(!formik.isValid || imageUploadErrorMessage || logo === "" || companyImageUploadErrorMessage) && (
                    <span className={"error-message"}>Please fill in all the mandatory fields</span>
                )}
            </Stack>
        </Stack>
    );

    if (isContentLoading) {
        return <Spinner className={"loading-spinner"} size={SpinnerSize.large} label={"Loading..."} />;
    }

    return (
        <>
            <ToastContainer />
            <Pivot className={"quizPivotTc"}>
                <PivotItem headerText={"Details"} className={"margin-top-10 padding-bottom-10"}>
                    {getEventDetailsSection()}
                    <ConfirmationDialog
                        entryName={"event"}
                        dialogHeader={"Confirm Close"}
                        isShown={showCloseConfirmationDialog}
                        onConfirm={onCloseEvent}
                        actionName={"close"}
                        onDismiss={() => setShowCloseConfirmationDialog(false)}
                    />
                </PivotItem>
                {eventId !== -1 && (
                    <PivotItem headerText={"Code"} className={"margin-top-10 padding-bottom-10"}>
                        <EventCodesView
                            isEventClosed={isEventClosed}
                            eventId={eventId}
                            eventName={formik.values.eventName}
                        />
                    </PivotItem>
                )}
                {eventId !== -1 && (
                    <PivotItem headerText={"Media"} className={"margin-top-10 padding-bottom-10"}>
                        <EventFilesView eventId={eventId} />
                    </PivotItem>
                )}
                {eventId !== -1 && (
                    <PivotItem headerText={"Survey Details"} className={"margin-top-10 padding-bottom-10"}>
                        <EventSurveyView eventId={eventId} />
                    </PivotItem>
                )}
                {eventId !== -1 && (
                    <PivotItem headerText={"Participant Emails"} className={"margin-top-10 padding-bottom-10"}>
                        <ParticipantEmailList eventId={eventId} />
                    </PivotItem>
                )}
                {eventId !== -1 && (
                    <PivotItem
                        style={{ height: "80vh" }}
                        headerText={"Live Leaderboards"}
                        className={"margin-top-10 padding-bottom-10"}>
                        <LeaderBoardDetailsView isEventLive={!isEventClosed} eventId={eventId} />
                    </PivotItem>
                )}
            </Pivot>
            {showQrCodeModal && <QrCodeModal handleClose={() => setShowQrCodeModal("")} url={showQrCodeModal} />}
        </>
    );
};

export default EventDetailsView;

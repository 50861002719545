import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { darkPrimaryColor } from "../../../../../../constants/ColorConstant";
import { CaptionText } from "../../../../../CommonComponent/v2/CommonStyle";
import ImageDrop from "../../../../../CommonComponent/v2/fields/ImageDrop";
import { QuestionDetailType } from "../Type";
import { FC } from "react";

type ImageQuestionProps = {
    removeCoverImage: () => void;
};
const ImageQuestion: FC<ImageQuestionProps> = ({ removeCoverImage }) => {
    const { values, setFieldValue } = useFormikContext();
    const data = values as QuestionDetailType;

    return (
        <Grid mt={"30px"}>
            <CaptionText fontWeight={700} color={darkPrimaryColor}>
                Cover image
            </CaptionText>
            <Grid>
                <ImageDrop
                    imageSize={2}
                    variant={"secondary"}
                    selectedFile={data.questionDetails.coverImage.file}
                    logoInfo={"Preferred image ratio: 16: 9, Prefereed resolution: 1920 x 1080 or 1280 x 720 , Max Size: 2MB"}
                    name={"questionDetails.coverImage.file"}
                    setFileInfo={(fileInfo) => {
                        setFieldValue("questionDetails.coverImage.fileName", fileInfo.fileName);
                        setFieldValue("questionDetails.coverImage.fileSize", fileInfo.fileSize);
                    }}
                    fileInfo={{
                        fileName: data.questionDetails.coverImage.fileName,
                        fileSize: data.questionDetails.coverImage.fileSize
                    }}
                    removeImage={removeCoverImage}
                />
            </Grid>
        </Grid>
    );
};

export default ImageQuestion;

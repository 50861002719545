import Styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import VerificationInput from "react-verification-input";

export const StyledBackground = Styled(Grid)`
    min-height: 100vh;
    background: rgb(0,35,141);
    background: linear-gradient(150deg, rgba(0,35,141,1) 0%, rgba(0,22,95,1) 87%, rgba(0,9,66,1) 100%);
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
    background-size: cover;
`;

type StyledVerifyInputProps = {
    iserror?: boolean;
};
export const StyledVerifyInput = Styled(Box)<StyledVerifyInputProps>`
 

& .container {
    padding: 0 !important;
       & .character {
        border: none;
    font-size: 20px;
    border-radius: 8px;
    color: #272729;
    background-color: #f6f5fa;
    box-shadow: 0 2px 0 #e4e2f5;
   
    cursor: pointer;

    ${(props) =>
        props.iserror &&
        `
        border: 1px #d32f2f solid;
        box-shadow: 0 2px 0 #d32f2f;
    `};
}
}
`;

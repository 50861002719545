import { Stack } from "@fluentui/react";
import React from "react";
import { FC } from "react";
import { selectedButtonColor, ThemedMediumStackTokens } from "../../constants/Styles";
import "./SelectRating.scss";

type SelectRatingProps = {
    ratingOptions: number[];
    selectedRating: number;
    setSelectedRating: (rate: number) => void;
    ratingColor: string;
};

const SelectRating: FC<SelectRatingProps> = ({
    ratingOptions,
    selectedRating,
    setSelectedRating,
    ratingColor
}) => {
    return (
        <Stack horizontal wrap tokens={ThemedMediumStackTokens}>
            {ratingOptions.map((item) => (
                <div
                    key={item}
                    onClick={() => setSelectedRating(item)}
                    className={"rating-option"}
                    style={{ backgroundColor: selectedRating === item ? selectedButtonColor : ratingColor }}>
                    {item}
                </div>
            ))}
        </Stack>
    );
};

export default SelectRating;

import { Container, Col } from "react-bootstrap";
import Style from "@emotion/styled";

export const ConfigContainer = Style(Container)`
    display: flex;
    justify-content: center;
`;

export const ConfigInfoTitleCol = Style(Col)`
    display: flex;
    align-items: center;
    margin-top: 30px;
`;

export const DotsStepper = Style(Col)`
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;
import { Grid } from "@mui/material";
import { FC } from "react";
import { darkPrimaryColor } from "../../../../../../constants/ColorConstant";
import { CaptionText, Header2 } from "../../../../../CommonComponent/v2/CommonStyle";
import { OptionType } from "../../../../../CommonComponent/v2/fields/Type";
import LoadingWrapper from "../../../../../CommonComponent/v2/LoadingWrapper";
import { GameTypeOption } from "../../../questionlibrary/addquestion/Util";
import GameEditDrawer from "./GameEditDrawer";
import AllQuestionList from "./gamequestion/AllQuestionList";
import SelectedQuestionList from "./gamequestion/SelectedQuestionList";
import { useGameIdentificationHooks } from "./Hooks";

type GameQuestionFormProps = {
    tagOptions: OptionType[];
    gameType: string;
    gameId: string;
};

const GameQuestionForm: FC<GameQuestionFormProps> = ({ tagOptions, gameType, gameId }) => {
    const {
        questionList,
        questionListLoading,
        selectedQuestion,
        setSelectedQuestion,
        selectedEditQuestion,
        setSelectedEditQuestion,
        fetchData,
        refreshListKey
    } = useGameIdentificationHooks();

    const getQuestionList = () => {
        return questionList;
    };

    return (
        <Grid container mt={"60px"}>
            <Grid item xs={12}>
                <Header2>Game challenges</Header2>
                <CaptionText mt={"5px"}>
                    You can select the challenges that will be added to the game for the players.
                </CaptionText>
            </Grid>
            <Grid item xs={12}>
                <LoadingWrapper isLoading={questionListLoading}>
                    <Grid container>
                        <Grid item mt={"20px"} xs={12}>
                            <CaptionText fontWeight={700} color={darkPrimaryColor}>
                                Select a challenge(only{" "}
                                {GameTypeOption.find((item) => item.value === gameType)?.label || ""} challenges are
                                here)
                            </CaptionText>
                        </Grid>
                        <Grid item xs={12} mt={"20px"}>
                            <AllQuestionList
                                tagOptions={tagOptions}
                                gameType={gameType}
                                openEditQuestionDrawer={(id) =>
                                    setSelectedEditQuestion({
                                        gameId: gameType,
                                        questionId: id
                                    })
                                }
                                questionList={getQuestionList()}
                                gameId={gameId}
                                selectedRows={selectedQuestion}
                                setSelectedRows={setSelectedQuestion}
                            />
                        </Grid>
                        <Grid item mt={"20px"} xs={12}>
                            <CaptionText fontWeight={700} color={darkPrimaryColor}>
                                Added challenges: {selectedQuestion.length}
                            </CaptionText>
                        </Grid>
                        <Grid item xs={12} mt={"20px"}>
                            <SelectedQuestionList
                                gameId={gameId}
                                openEditQuestionDrawer={(id) =>
                                    setSelectedEditQuestion({
                                        gameId: gameType,
                                        questionId: id
                                    })
                                }
                                key={refreshListKey}
                                questionList={selectedQuestion}
                                setSelectedQuestion={setSelectedQuestion}
                            />
                        </Grid>
                        {selectedEditQuestion && (
                            <GameEditDrawer
                                handleClose={() => setSelectedEditQuestion(null)}
                                selectedQuestion={selectedEditQuestion}
                                refreshList={() => fetchData(true)}
                            />
                        )}
                    </Grid>
                </LoadingWrapper>
            </Grid>
        </Grid>
    );
};

export default GameQuestionForm;

import { FieldValidation } from "../../../../constants/FieldValidation";
import { CODE_LENGTH } from "../../../../constants/SiteConstant";

export const getEventCode = (eventIds: string[]) => {
    // eslint-disable-next-line no-constant-condition
    while (1) {
        const code = FieldValidation.generateAlphaNumericCodeExcludingValues(CODE_LENGTH).toUpperCase();
        if (!eventIds.includes(code)) {
            return code;
        }
    }
    return "";
};

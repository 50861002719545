import Styled from "@emotion/styled";

export const SearchWrapper = Styled.div`
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: white;
    height: 46px;
    border: 2px black solid;
    & input {
        border-radius: 10px 0px 0px 10px;
        font-size: 16px;
        font-family: "Poppins";
        border: 0px !important;
        outline: 0px !important;
    }
`;
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import { FlowBoxValueType } from "./Type";

type FlowChartEditModalProps = {
    handleClose: () => void;
    boxValue: FlowBoxValueType;
    saveValue: (value: FlowBoxValueType) => void;
};
const FlowChartEditModal: FC<FlowChartEditModalProps> = ({ handleClose, boxValue, saveValue }) => {
    const [value, setValue] = useState(boxValue.value);
    const [description, setDescription] = useState(boxValue.description);
    const [title, setTitle] = useState(boxValue.title);

    return (
        <Dialog fullWidth maxWidth={"sm"} open onClose={handleClose}>
            <DialogTitle>Edit Details</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} marginTop={"10px"}>
                        <TextField fullWidth value={title} onChange={(e) => setTitle(e.target.value)} label="Title" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            label="Description"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={value} fullWidth onChange={(e) => setValue(e.target.value)} label="Value" />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button
                        onClick={() =>
                            saveValue({
                                title,
                                description,
                                value,
                                position: boxValue.position,
                                id: boxValue.id
                            })
                        }>
                        Save
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default FlowChartEditModal;

import { Icon, Stack } from "office-ui-fabric-react";
import React from "react";

type ScrollDownControlProps = {
    moveScrollBarDown: () => void;
    height: number;
};
const ScrollDownControl: React.FC<ScrollDownControlProps> = ({ moveScrollBarDown, height }) => {
    return (
        <Stack horizontalAlign={"center"} className={"scroll-icon"} style={{ top: height - 48 }}>
            <Icon iconName={"DrillDownSolid"} onClick={moveScrollBarDown} className={"cursor"} />
        </Stack>
    );
};

export default ScrollDownControl;

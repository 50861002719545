import {
    IconButton,
    Label,
    Stack,
    Dropdown,
    PrimaryButton,
    DefaultButton,
    IDropdownOption,
    Image,
    Pivot,
    PivotItem,
    Spinner,
    SpinnerSize,
    Modal,
    Text,
    TooltipHost,
    Icon
} from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import TextEditor from "../../../CommonComponent/TextEditor";
import "./QuestionDetail.scss";
import { Client } from "../../../Base/Client";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { SiteConstants, superAdmin } from "../../../../constants/SiteConstant";
import { toast, ToastContainer } from "react-toastify";
import {
    BASIC_TEXT_EDITOR_CLASS,
    ADVANCE_TEXT_EDITOR_CLASS,
    MCQAnswerOption,
    MCQ,
    FREE_TEXT,
    FREE_TEXT_CONNECTORS,
    ConnectorOptions,
    FILE_UPLOAD,
    TIME_PENALTY,
    SCORE_PENALTY,
    timePenalty,
    UPLOAD_VIDEO,
    YOUTUBE_LINK
} from "../../../../data/question/types";
import {
    freeTextAnswerTypeOptions,
    mcqAnswerTypeOptions,
    visibiltyTypeOptions
} from "../../../../constants/DropdownOption";
import TextFieldWrapper from "../../../CommonComponent/TextFieldWrapper";
import ToggleFieldWrapper from "../../../CommonComponent/ToggleFieldWrapper";
import {
    dashedBorderStyle,
    defaultButtonBackgroundColor,
    ThemedMediumStackTokens,
    ThemedSmall2StackTokens
} from "../../../../constants/Styles";
import DropdownWrapper from "../../../CommonComponent/DropdownWrapper";
import QuestionTagModal from "./QuestionTagPanel";
import { omit } from "lodash";
import QuestionHintsListView from "./QuestionHintsListView";
import { FileInfo, FreeTextAnswerFields } from "./QuestionDetailsDataTypes";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { extractHtmlContent } from "../../../../utils/JambarUtils";
import { getTimePenalty, removeTimePenalty, transformTimePenalty } from "./util";
import { useAdminContext } from "../../context/AdminAuthContext";
import { getPermissions } from "../../../../utils/PermissionUtils";
import { getAllIdentitiesList } from "../../Users/Identites/Action";
import { IdentityListtype } from "../../Users/Identites/Type";
import { dangerColorLight } from "../../../../constants/ColorConstant";
import QuestionVideoSection from "./QuestionVideoSection";

type IQuestionDetailNativeProps = {
    questionId: number;
    closeAddQuestionPanel: (questionId: number) => void;
    fetchQuestionList: (questionId: number) => void;
    accessibilityScope?: string;
    refreshQuestionDetails?: (questionId: number, questionTitle: string, tags: IDropdownOption[]) => void;
};

const QuestionDetailView: React.FC<IQuestionDetailNativeProps> = ({
    questionId,
    closeAddQuestionPanel,
    fetchQuestionList,
    accessibilityScope,
    refreshQuestionDetails
}) => {
    const [questionType, setQuestionType] = useState("mcq");
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [options, setOptions] = useState<MCQAnswerOption[]>([
        { id: Math.floor(Math.random() * Math.floor(9999)), text: "" }
    ]);
    const [coverImage, setCoverImage] = useState("");
    const [coverImageFile, setCoverImageFile] = useState<File | null>(null);
    const [videoQuestion, setVideoQuestion] = useState("");
    const [videoQuestionFile, setVideoQuestionFile] = useState<File | null>(null);
    const [freeTextAnswerType, setFreeTextAnswerType] = useState("");
    const [correctOption, setCorrectOption] = useState<number[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [accessibleIdentityIds, setAccessibleIdentityIds] = useState<string[]>([]);
    const [correctPoints, setCorrectPoints] = useState("");
    const [wrongPoints, setWrongPoints] = useState("");
    const [skipPoints, setSkipPoints] = useState("");
    const [correctAnswerText, setCorrectAnswerText] = useState("");
    const [wrongAnswerText, setWrongAnswerText] = useState("");
    const [maxAttempts, setMaxAttempts] = useState("");
    const [enableSpecifyAnswerText, setSpecifyAnswerText] = useState(false);
    const [imageUploadErrorMessage, setImageUploadErrorMessage] = useState("");
    const [videoUploadErrorMessage, setVideoUploadErrorMessage] = useState("");
    const [timeOutText, setTimeOutText] = useState("");
    const [skippingAllowed, setSkippingAllowed] = useState(false);
    const [timeOutAllowed, setTimeOutAllowed] = useState(false);
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    const [connectorOptions, setConnectorOptions] = useState<ConnectorOptions[]>([
        { id: FieldValidation.generateAlphaNumericCode(), optionText: "", quizId: "", isClosable: false }
    ]);
    const [quizList, setQuizList] = useState<IDropdownOption[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [showTagsModal, setShowTagsModal] = useState(false);
    const [questionTitle, setQuestionTitle] = useState("");
    const [tagsOption, setTagsOption] = useState<IDropdownOption[]>([]);
    const [identityOptions, setIdentityOption] = useState<IDropdownOption[]>([]);
    const [isNonScoring, setIsNonScoring] = useState(false);
    const [freeTextAnswerFields, setFreeTextAnswerFields] = useState<FreeTextAnswerFields[]>([
        {
            id: FieldValidation.generateAlphaNumericCode(),
            text: ""
        }
    ]);
    const [mcqAnswerType, setMcqAnsweType] = useState(SiteConstants.ATLEAST_ONE_CORRECT);
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [isRemovingCoverImage, setIsRemovingCoverImage] = useState(false);
    const [isRemovingVideoQuestion, setIsRemovingVideoQuestion] = useState(false);
    const [bottomQuestionText, setBottomQuestionText] = useState("");
    const [answerHeader, setAnswerHeader] = useState("");
    const [referenceItemText1, setReferenceItemText1] = useState("");
    const [referenceItemText2, setReferenceItemText2] = useState("");
    const [showConnectorTimer, setShowConnectorTimer] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [completionTriggerQuizScore, setCompletionTriggerQuizScore] = useState("");
    const [forceCorrect, setForceCorrect] = useState(false);

    const [penaltyType, setPenaltyType] = useState<string>("scorePenalty");
    const [timePenalty, setTimePenalty] = useState<timePenalty[]>([]);
    const [fileInfo, setFileInfo] = useState<FileInfo>({
        fileName: "",
        fileSize: 0
    });
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "tag");
    const [visibilty, setVisibilty] = useState("private");
    const [videoType, setVideoType] = useState(UPLOAD_VIDEO);
    const [youtubeVideoText, setYoutubeVideoText] = useState("");

    useEffect(() => {
        if (options.length === 1) {
            setCorrectOption((prevState) => [...prevState, options[0].id]);
        }
    }, [options.length]);

    useEffect(() => {
        if (penaltyType === TIME_PENALTY) {
            if (timePenalty.length > Number(maxAttempts)) {
                setTimePenalty([...removeTimePenalty(timePenalty, maxAttempts)]);
                return;
            }
            if (timePenalty.length === 0) {
                for (let x = 0; x < Number(maxAttempts); x++) {
                    addNewTimePenalty();
                }
                return;
            }
            const addAmount = Number(maxAttempts) - timePenalty.length;
            for (let x = 0; x < addAmount; x++) {
                addNewTimePenalty();
            }
        }
    }, [maxAttempts, penaltyType]);

    useEffect(() => {
        fetchTagsDropdownData();
        getAllIdentitiesList(true)
            .then((res: IdentityListtype[]) => {
                setIdentityOption(
                    res.map(({ id, name }) => ({
                        id: id,
                        key: id,
                        text: name
                    }))
                );
            })
            .catch(() => {
                setIdentityOption([]);
            });

        Client.getInstance()
            .getData(ApiConstants.getQuizListApiUrl(SiteConstants.spaceGameQuiz, "all"))
            .then((response: any) => {
                const quizDropdownData: IDropdownOption[] = response.data.data.map((quiz: any) => ({
                    id: quiz.id,
                    key: quiz.id,
                    text: extractHtmlContent(quiz.title)
                }));
                setQuizList(quizDropdownData);
            });

        if (questionId !== -1) {
            setShowLoadingSpinner(true);
            Client.getInstance()
                .getData(ApiConstants.getQuestionDetailsApiUrl(questionId), true)
                .then((response: any) => {
                    transformData(response.data.data);
                    setShowLoadingSpinner(false);
                })
                .catch(() => {
                    setShowLoadingSpinner(false);
                });
        }
    }, []);

    const renderVisibilty = () => {
        return (
            <Stack className={"margin-right-48"}>
                <Stack.Item>
                    <DropdownWrapper
                        label={"Visibilty type"}
                        selectedKey={visibilty}
                        onChangeDropdownOption={(value) => {
                            setVisibilty(value);
                            setAccessibleIdentityIds([]);
                        }}
                        options={visibiltyTypeOptions}
                        width={200}
                    />
                </Stack.Item>
                {visibilty === "limited" && (
                    <Stack horizontal tokens={ThemedSmall2StackTokens}>
                        <Stack.Item>
                            <Dropdown
                                label={"All Identities"}
                                selectedKeys={accessibleIdentityIds}
                                options={identityOptions}
                                onChange={onIdentityChange}
                                multiSelect
                                styles={{ root: { width: 200 } }}
                            />
                        </Stack.Item>
                        <PrimaryButton
                            className={"margin-top-30"}
                            text={accessibleIdentityIds.length > 0 ? "Clear all" : "Select All"}
                            onClick={() => {
                                accessibleIdentityIds.length > 0
                                    ? setAccessibleIdentityIds([])
                                    : setAccessibleIdentityIds(identityOptions.map((item: any) => item.id));
                            }}
                        />
                    </Stack>
                )}
            </Stack>
        );
    };

    const fetchTagsDropdownData = (forceRefresh = false): void => {
        if (!(permission && permission.includes("fetch"))) {
            setTagsOption([]);
            return;
        }
        Client.getInstance()
            .getData(ApiConstants.getTagsListApiUrl(), forceRefresh)
            .then((response: any) => {
                const tagsDropdownData: IDropdownOption[] = response.data.data.map((tag: any) => ({
                    Answerid: tag.id,
                    key: tag.id,
                    text: tag.value
                }));
                setTagsOption(tagsDropdownData);
            });
    };

    const transformData = (data: any): void => {
        const timeLimit = data.timeLimit.split(":");
        setQuestionType(data.type);
        setCoverImage(data.fileLocation);
        if (data.youtubeVideoLink) {
            setVideoType(YOUTUBE_LINK);
            setYoutubeVideoText(data.youtubeVideoLink);
        }

        if (data.videoQuestionFileLocation) setIsVideo(true);
        setVideoQuestion(data.videoQuestionFileLocation);
        setTags(data.questionTags.map((tag: any) => tag.tag.id));
        setQuestion(data.questionText);
        setCorrectAnswerText(data.correctAnswerText);
        setWrongAnswerText(data.wrongAnswerText);
        setMaxAttempts(data.maxAttempts);
        setVisibilty(data.accessibilityScope);
        setFileInfo({
            fileName: data.fileName,
            fileSize: data?.fileSize ? data.fileSize / 1024 : 0
        });
        setMinutes(timeLimit[1]);
        setSeconds(timeLimit[2]);
        setAccessibleIdentityIds(data?.accessibleIdentityIds || []);
        setWrongPoints(data.isNonScoringQuestion ? "" : data.wrongPoints);
        setCorrectPoints(data.isNonScoringQuestion ? "" : data.correctPoints);
        setSkipPoints(data.isNonScoringQuestion ? "" : data.skipPoints);
        setSpecifyAnswerText(data.specifyAnswerText);
        setTimeOutText(data.timeOutText);
        setSkippingAllowed(data.allowSkipping);
        setTimeOutAllowed(data.timeLimit !== "00:00:00");
        setQuestionTitle(data.title);
        setIsNonScoring(data.isNonScoringQuestion);
        setBottomQuestionText(data.questionText2 ? data.questionText2 : "");
        setAnswerHeader(data.answerHeader ? data.answerHeader : "");
        setReferenceItemText1(data.referenceItemText1 ? data.referenceItemText1 : "");
        setReferenceItemText2(data.referenceItemText2 ? data.referenceItemText2 : "");
        setShowConnectorTimer(data.showConnectorTimer);
        setCompletionTriggerQuizScore(data.thresholdQuizScore || "");
        setForceCorrect(data.forceCorrect || false);
        if (data.timePenaltyOnWrongAttempts) {
            setTimePenalty(getTimePenalty(data.timePenaltyOnWrongAttempts));
            setWrongPoints("");
            setPenaltyType(TIME_PENALTY);
        }
        if (data.type === FREE_TEXT_CONNECTORS) {
            const connectorsData: ConnectorOptions[] = data.questionConnector.map((connector: any) => ({
                id: connector.id,
                optionText: connector.optionText,
                quizId: connector.quizId,
                isClosable: connector.isClosable
            }));
            setConnectorOptions(connectorsData);
            return;
        }

        if (data.type === MCQ) {
            setCorrectOption(() => data.mcqAnswers.split(",").map((element: string) => parseFloat(element)));
            const mcqOptions = JSON.parse(data.mcqOptions);
            setOptions(() =>
                mcqOptions.map((element: MCQAnswerOption) => {
                    return {
                        text: element.text,
                        id: element.id
                    };
                })
            );
            setMcqAnsweType(data.answerType);
            return;
        }

        data.answerType === SiteConstants.EXACT_MATCH
            ? setAnswer(data.answer)
            : setFreeTextAnswerFields(
                  data.answer
                      .split(",")
                      .map((item: string) => ({ id: FieldValidation.generateAlphaNumericCode(), text: item }))
              );

        setFreeTextAnswerType(data.answerType ? data.answerType : "");
        setPenaltyType(data.timePenaltyOnWrongAttempts ? "timePenalty" : "scorePenalty");
    };

    const getTime = (): string => {
        if ((minutes === "60" && seconds === "60") || minutes === "60") {
            return `00:59:59`;
        }
        if (seconds === "60") {
            return `00:${minutes === "" ? "01" : parseInt(minutes) + 1}:00`;
        }
        return SiteConstants.getMaskedTime("00", minutes, seconds);
    };

    const addVideoQuestion = (id: number) => {
        const videoQuestionData = new FormData();
        videoQuestionData.append("questionVideo", videoQuestionFile as File);
        setLoading(true);
        Client.getInstance()
            .createData(ApiConstants.addQuestionVideoApiUrl(id), videoQuestionData)
            .then(() => {
                setLoading(false);
                fetchQuestionList(id);
                setVideoQuestionFile(null);
                setVideoUploadErrorMessage("");
                if (questionId !== -1) {
                    toast.success("Challenge updated", SiteConstants.successToastConfiguration);
                    return;
                }
                toast.success("Challenge added", SiteConstants.successToastConfiguration);
                closeAddQuestionPanel(id);
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response.data.message, SiteConstants.deleteToastConfiguration);
            });
        return;
    };

    const reverseTransformData = (type: string): any => {
        const questionData = new FormData();
        questionData.append("questionImage", coverImageFile as Blob);

        const data: any = {
            questionText: question,
            questionText2: bottomQuestionText,
            type: questionType,
            maxAttempts: maxAttempts,
            allowSkipping: skippingAllowed,
            timeOutText: timeOutAllowed ? timeOutText : "",
            tagIds: tags.join(","),
            accessibilityScope: visibilty,
            accessibleIdentityIds: accessibleIdentityIds.join(";"),
            timeLimit: timeOutAllowed ? getTime() : "",
            skipPoints: skipPoints === "" ? null : skipPoints,
            wrongPoints: wrongPoints,
            correctPoints: correctPoints === "" ? null : correctPoints,
            correctAnswerText: enableSpecifyAnswerText
                ? FieldValidation.quillTextEditorValidation(correctAnswerText)
                    ? " "
                    : correctAnswerText
                : "",
            wrongAnswerText: enableSpecifyAnswerText
                ? FieldValidation.quillTextEditorValidation(wrongAnswerText)
                    ? " "
                    : wrongAnswerText
                : "",
            specifyAnswerText: enableSpecifyAnswerText ? 1 : 0,
            title: questionTitle,
            isNonScoringQuestion: isNonScoring ? 1 : 0,
            answerHeader: answerHeader,
            referenceItemText1: referenceItemText1,
            referenceItemText2: referenceItemText2,
            forceCorrect: forceCorrect,
            youtubeVideoLink: youtubeVideoText,
            timePenaltyOnWrongAttempts: transformTimePenalty(timePenalty)
        };
        if (type === FREE_TEXT_CONNECTORS) {
            const transformedConnectors = connectorOptions.map((connector) => omit(connector, ["index"]));
            const connectorQuestionData = {
                ...data,
                connectorOptions: JSON.stringify(transformedConnectors),
                showConnectorTimer: showConnectorTimer ? 1 : 0,
                thresholdQuizScore: completionTriggerQuizScore
            };
            for (const key in connectorQuestionData) {
                questionData.append(key, connectorQuestionData[key]);
            }
            return questionData;
        }
        if (type === "mcq") {
            const mcqOptions = JSON.stringify(
                options.map((element) => {
                    return { text: element.text, id: element.id };
                })
            );
            const mcqData = {
                ...data,
                mcqOptions: mcqOptions,
                mcqAnswers: correctOption.join(","),
                answerType: mcqAnswerType
            };
            for (const key in mcqData) {
                questionData.append(key, mcqData[key]);
            }
            return questionData;
        }

        const textData = {
            ...data,
            answer:
                freeTextAnswerType === SiteConstants.EXACT_MATCH
                    ? answer
                    : freeTextAnswerFields.map((field) => field.text).join(","),
            answerType: freeTextAnswerType
        };

        for (const key in textData) {
            questionData.append(key, textData[key]);
        }
        return questionData;
    };

    const saveAll = () => {
        if (
            (minutes === "" && seconds === "" && timeOutAllowed) ||
            (timeOutAllowed &&
                !FieldValidation.timeLimitValidation(SiteConstants.getMaskedTime("00", minutes, seconds)))
        ) {
            setShowValidationErrorMessage(true);
            return;
        }

        if (
            questionType === FREE_TEXT_CONNECTORS &&
            (connectorOptions.some(
                (connector) =>
                    FieldValidation.quillTextEditorValidation(connector.optionText) || connector.quizId === ""
            ) ||
                (completionTriggerQuizScore !== "" && !FieldValidation.floatValidation(completionTriggerQuizScore)))
        ) {
            setShowValidationErrorMessage(true);
            return;
        }

        if (!isVideo) {
            if (
                (FieldValidation.quillTextEditorValidation(question) &&
                    FieldValidation.quillTextEditorValidation(bottomQuestionText)) ||
                tags.length === 0 ||
                FieldValidation.quillTextEditorValidation(questionTitle)
            ) {
                setShowValidationErrorMessage(true);
                return;
            }
        }

        if (questionType === MCQ) {
            if (correctOption.length === 0 || options.some((option: MCQAnswerOption) => option.text === "")) {
                setShowValidationErrorMessage(true);
                return;
            }
        }

        if (
            questionType === FREE_TEXT &&
            ((freeTextAnswerType === SiteConstants.EXACT_MATCH && answer === "") ||
                (freeTextAnswerType !== SiteConstants.EXACT_MATCH &&
                    freeTextAnswerFields.some((item) => item.text === "")) ||
                freeTextAnswerType === "")
        ) {
            setShowValidationErrorMessage(true);
            return;
        }
        setShowValidationErrorMessage(false);

        saveQuestion();
        return;
    };

    const saveQuestion = () => {
        if (questionId !== -1) {
            setLoading(true);
            Client.getInstance()
                .updateData(ApiConstants.updateQuestionDetailsApiUrl(questionId), reverseTransformData(questionType))
                .then(() => {
                    if (isVideo && videoQuestionFile && videoUploadErrorMessage === "") {
                        addVideoQuestion(questionId);
                        return;
                    }
                    setLoading(false);
                    fetchQuestionList(questionId);
                    refreshQuestionDetails &&
                        refreshQuestionDetails(
                            questionId,
                            questionTitle,
                            tagsOption.filter((item) => tags.includes(item.key as string))
                        );
                    toast.success("Challenge updated", SiteConstants.successToastConfiguration);
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error(error.response.data.message, SiteConstants.deleteToastConfiguration);
                });
            return;
        }
        setLoading(true);
        Client.getInstance()
            .createData(ApiConstants.addQuestionApiUrl(), reverseTransformData(questionType))
            .then((response) => {
                if (isVideo && videoQuestionFile && videoUploadErrorMessage === "") {
                    addVideoQuestion(response.data.data.id);
                    return;
                }
                setLoading(false);
                closeAddQuestionPanel(response.data.data.id);
                toast.success("Challenge added", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.response.data.message, SiteConstants.deleteToastConfiguration);
            });
        return;
    };

    const handleInputChange = (id: number, text: string) => {
        const list = [...options];
        list[id].text = text;
        setOptions(list);
    };

    const handleTimePenaltyChange = (id: number, value: string) => {
        setTimePenalty(timePenalty.map((item) => (item.id === id ? { ...item, value } : item)));
    };

    const addNewOption = (): void => {
        setOptions((prevState: MCQAnswerOption[]) => [
            ...prevState,
            { id: Math.floor(Math.random() * Math.floor(9999)), text: "" }
        ]);
    };

    const getTimePenaltyField = (item: timePenalty, index: number): JSX.Element => {
        return (
            <Stack key={item.id} horizontal tokens={ThemedMediumStackTokens}>
                <Stack.Item>
                    <Label>Time penalty {index + 1}:</Label>
                </Stack.Item>
                <Stack.Item>
                    <TextFieldWrapper
                        value={item.value}
                        type="number"
                        onTextFieldValueChange={(text: string) => handleTimePenaltyChange(item.id, text)}
                        errorMessage={timePenalty[index].value === "" ? "This field is mandatory" : ""}
                    />
                </Stack.Item>
            </Stack>
        );
    };

    const addNewTimePenalty = (): void => {
        setTimePenalty((prev: timePenalty[]) => [...prev, { id: prev.length + 1, value: "" }]);
    };

    const handleConnectorInputChange = (index: number, text: string): void => {
        const connectors = [...connectorOptions];
        connectors[index].optionText = text;
        setConnectorOptions(connectors);
    };

    const handleConnectorQuizNameInputChange = (index: number, text: string): void => {
        const connectors = [...connectorOptions];
        connectors[index].quizId = text;
        setConnectorOptions(connectors);
    };

    const handleConnectorClosableChange = (index: number, value: boolean): void => {
        const connectors = [...connectorOptions];
        connectors[index].isClosable = value;
        setConnectorOptions(connectors);
    };

    const addConnectorNewOption = (): void => {
        setConnectorOptions((prevState: ConnectorOptions[]) => [
            ...prevState,
            { id: FieldValidation.generateAlphaNumericCode(), optionText: "", quizId: "", isClosable: false }
        ]);
    };

    const deleteOption = (index: number): void => {
        setOptions(options.filter((item: MCQAnswerOption) => index !== item.id));
        if (correctOption.includes(index)) {
            setCorrectOption((prevState) => {
                return prevState.filter((element) => element !== index);
            });
        }
        setOptions((prevState) => {
            return prevState.filter((item: MCQAnswerOption) => index !== item.id);
        });
    };

    const deleteConnectorOption = (id: string): void => {
        setConnectorOptions(connectorOptions.filter((connector: ConnectorOptions) => connector.id !== id));
    };

    const onMarkCorrectOption = (id: number): void => {
        setCorrectOption((prevState) => {
            return [...prevState, id];
        });
    };

    const onUnmarkCorrectOption = (id: number): void => {
        setCorrectOption((prevState) => {
            const correctOptions = prevState.filter((element) => element !== id);
            return correctOptions;
        });
    };

    const handleAnswerFieldInputChange = (index: number, text: string) => {
        const answerFields = [...freeTextAnswerFields];
        answerFields[index].text = text;
        setFreeTextAnswerFields(answerFields);
    };

    const getTextFields = (field: FreeTextAnswerFields, index: number): JSX.Element => (
        <Stack key={field.id} horizontal tokens={ThemedMediumStackTokens}>
            <Stack.Item grow={1}>
                <TextFieldWrapper
                    value={field.text}
                    onTextFieldValueChange={(text: string) => handleAnswerFieldInputChange(index, text)}
                    errorMessage={freeTextAnswerFields[index].text === "" ? "This field is mandatory" : ""}
                />
            </Stack.Item>
            <Stack.Item className={index < 1 ? "visibility-hidden" : ""}>
                <IconButton
                    iconProps={{ iconName: "delete" }}
                    onClick={() =>
                        setFreeTextAnswerFields(
                            freeTextAnswerFields.filter((item: FreeTextAnswerFields) => item.id !== field.id)
                        )
                    }
                />
            </Stack.Item>
        </Stack>
    );

    const getFreeTextAnswerFields = (): JSX.Element => {
        return (
            <Stack tokens={ThemedMediumStackTokens}>
                {freeTextAnswerFields.map((field: FreeTextAnswerFields, index: number) => getTextFields(field, index))}
                <Stack horizontalAlign={"stretch"} className={"margin-right-48"}>
                    <DefaultButton
                        onClick={() =>
                            setFreeTextAnswerFields((answerFields: FreeTextAnswerFields[]) => [
                                ...answerFields,
                                { id: FieldValidation.generateAlphaNumericCode(), text: "" }
                            ])
                        }
                        text={"Add Answer"}
                        iconProps={{ iconName: "Add" }}
                        styles={{ root: { background: defaultButtonBackgroundColor, borderStyle: dashedBorderStyle } }}
                    />
                </Stack>
            </Stack>
        );
    };

    const textFieldAnswer = (): JSX.Element => {
        return (
            <Stack tokens={ThemedMediumStackTokens}>
                <Stack.Item>
                    <DropdownWrapper
                        label={"Answer type"}
                        selectedKey={freeTextAnswerType}
                        onChangeDropdownOption={(value) => setFreeTextAnswerType(value)}
                        options={freeTextAnswerTypeOptions}
                        width={200}
                        errorMessage={freeTextAnswerType === "" ? "Answer type is mandatory" : ""}
                    />
                </Stack.Item>

                {getAnswerHeaderField()}

                <Label>Answer</Label>
                {freeTextAnswerType !== SiteConstants.EXACT_MATCH ? (
                    getFreeTextAnswerFields()
                ) : (
                    <Stack tokens={ThemedSmall2StackTokens}>
                        <TextFieldWrapper
                            autoAdjustHeight={true}
                            multiline={true}
                            value={answer}
                            onTextFieldValueChange={(value: string) => setAnswer(value)}
                        />

                        {FieldValidation.quillTextEditorValidation(answer) ? (
                            <span className={"error-message"}>Answer is mandatory</span>
                        ) : null}
                    </Stack>
                )}
            </Stack>
        );
    };

    const getAnswerHeaderField = (): JSX.Element => (
        <Stack>
            <Label>Answer header</Label>
            <TextEditor
                className={`${ADVANCE_TEXT_EDITOR_CLASS} quill-blue-background`}
                value={answerHeader}
                onChange={(text: string) => setAnswerHeader(text)}
                basicControl={true}
                showFontSizeControl={true}
            />
        </Stack>
    );

    const mcqField = (): JSX.Element => {
        return (
            <Stack tokens={ThemedMediumStackTokens}>
                <DropdownWrapper
                    options={mcqAnswerTypeOptions}
                    onChangeDropdownOption={(answerType: string) => setMcqAnsweType(answerType)}
                    width={200}
                    label={"Answer type"}
                    selectedKey={mcqAnswerType}
                />
                {getAnswerHeaderField()}
                <Label>Answer options</Label>
                {correctOption.length === 0 ? (
                    <Stack className={"margin-bottom-5"}>
                        <span className={"error-message"}>Please mark correct option</span>
                    </Stack>
                ) : null}
                {options.map((item: MCQAnswerOption, index: number) => {
                    return (
                        <Stack key={item.id} horizontal tokens={ThemedMediumStackTokens}>
                            <Stack>
                                {correctOption.includes(item.id) ? (
                                    <IconButton
                                        iconProps={{ iconName: "Accept" }}
                                        title="Correct Option"
                                        onClick={() => onUnmarkCorrectOption(item.id)}
                                        styles={SiteConstants.correctOptionIconColor}
                                    />
                                ) : (
                                    <IconButton
                                        iconProps={{ iconName: "Clear" }}
                                        title="Wrong Option"
                                        onClick={() => onMarkCorrectOption(item.id)}
                                        styles={SiteConstants.wrongOptionIconColor}
                                    />
                                )}
                            </Stack>

                            <Stack.Item grow={1} key={`stackItem${item.id}`}>
                                <TextFieldWrapper
                                    key={`textEditor${item.id}`}
                                    value={item.text}
                                    onTextFieldValueChange={(text: string) => handleInputChange(index, text)}
                                    errorMessage={options[index].text === "" ? "This field is mandatory" : ""}
                                />
                            </Stack.Item>
                            <Stack key={`stack${item.id}`} className={index < 1 ? "visibility-hidden" : undefined}>
                                <IconButton
                                    key={`iconButton${item.id}`}
                                    iconProps={{ iconName: "delete" }}
                                    onClick={() => deleteOption(item.id)}
                                />
                            </Stack>
                        </Stack>
                    );
                })}
                <Stack horizontalAlign={"stretch"} className={"margin-right-48 margin-left-48"}>
                    <DefaultButton
                        onClick={addNewOption}
                        text={"Add Option"}
                        iconProps={{ iconName: "Add" }}
                        styles={{ root: { background: "#E5E5E5", borderStyle: "dashed" } }}
                    />
                </Stack>
            </Stack>
        );
    };

    const getConnectorFields = (connector: ConnectorOptions, index: number): JSX.Element => (
        <Stack
            className={"margin-top-0 margin-bottom-15"}
            key={connector.id}
            wrap
            horizontal
            tokens={ThemedMediumStackTokens}>
            <Stack.Item className={"width-35per"} grow>
                <TextEditor
                    className={"simple-text-editor"}
                    isSimple={true}
                    value={connector.optionText}
                    onChange={(text: string) => handleConnectorInputChange(index, text)}
                />
                {FieldValidation.quillTextEditorValidation(connectorOptions[index].optionText) && (
                    <span className={"error-message"}>This field is mandatory</span>
                )}
            </Stack.Item>
            <Stack.Item align={"start"}>
                <DropdownWrapper
                    width={300}
                    options={quizList}
                    onChangeDropdownOption={(text: string) => handleConnectorQuizNameInputChange(index, text)}
                    placeholder={"Select quiz"}
                    selectedKey={connector.quizId}
                    errorMessage={connectorOptions[index].quizId === "" ? "Please select quiz" : ""}
                    titleHeight={35}
                />
            </Stack.Item>
            <Stack.Item align={"start"}>
                <ToggleFieldWrapper
                    label="Closable"
                    checked={connector.isClosable}
                    onChange={(value: boolean) => handleConnectorClosableChange(index, value)}
                    inlineLabel={true}
                />
            </Stack.Item>
            <Stack.Item className={index < 1 ? "visibility-hidden" : undefined}>
                <IconButton iconProps={{ iconName: "delete" }} onClick={() => deleteConnectorOption(connector.id)} />
            </Stack.Item>
        </Stack>
    );

    const connectorField = (): JSX.Element => {
        return (
            <Stack tokens={ThemedMediumStackTokens}>
                <ToggleFieldWrapper
                    inlineLabel
                    label={"Show connector timer"}
                    checked={showConnectorTimer}
                    onChange={() => setShowConnectorTimer((prevState) => !prevState)}
                />

                <Stack horizontal tokens={ThemedMediumStackTokens}>
                    <TextFieldWrapper
                        onTextFieldValueChange={(text) => setCompletionTriggerQuizScore(text)}
                        value={completionTriggerQuizScore}
                        errorMessage={
                            completionTriggerQuizScore !== "" &&
                            !FieldValidation.floatValidation(completionTriggerQuizScore)
                                ? "Please enter valid number"
                                : ""
                        }
                        width={45}
                    />
                    <Label>Completion trigger(Quiz score)</Label>
                </Stack>

                <Label className={"margin-bottom-5"}>Connector options</Label>
                {connectorOptions.map((connector: ConnectorOptions, index: number) =>
                    getConnectorFields(connector, index)
                )}
                <Stack horizontalAlign={"stretch"} className={"margin-top-0"}>
                    <DefaultButton
                        onClick={addConnectorNewOption}
                        text={"Add Option"}
                        iconProps={{ iconName: "Add" }}
                        styles={{ root: { background: "#E5E5E5", borderStyle: "dashed" } }}
                    />
                </Stack>
            </Stack>
        );
    };

    const toggleSpecifyAnswerText = (value: boolean): void => {
        setSpecifyAnswerText(value);
        if (!enableSpecifyAnswerText) {
            setCorrectAnswerText("");
            setWrongAnswerText("");
        }
    };

    const getQuestionSettingsSection = (): JSX.Element => {
        return (
            <Stack
                style={{
                    backgroundColor:
                        questionId !== -1 && accessibilityScope && ["limited", "global"].includes(accessibilityScope)
                            ? dangerColorLight
                            : ""
                }}
                tokens={ThemedMediumStackTokens}
                className="section-background section-border">
                <ToggleFieldWrapper
                    label="Non scoring"
                    checked={isNonScoring}
                    onChange={(value: boolean) => {
                        setIsNonScoring(value);
                        setCorrectPoints("");
                        setWrongPoints("");
                        setSkipPoints("");
                    }}
                    inlineLabel={true}
                />
                <Stack horizontal grow tokens={ThemedMediumStackTokens}>
                    <ToggleFieldWrapper
                        label="Force correct"
                        checked={forceCorrect}
                        onChange={(value: boolean) => setForceCorrect(value)}
                        inlineLabel={true}
                    />
                    <Stack.Item align={"center"} className={"margin-bottom-5 hover-cursor-pointer"}>
                        <TooltipHost content="If this config is set to 'ON', participants will not be able to proceed until this answer this question correctly. Note that in space game, this config will be ignored">
                            <Icon iconName="info" />
                        </TooltipHost>
                    </Stack.Item>
                </Stack>

                <Stack tokens={ThemedSmall2StackTokens} className={"margin-right-48"}>
                    <ToggleFieldWrapper
                        label="Specify correct/wrong answer text"
                        checked={enableSpecifyAnswerText}
                        onChange={(value: boolean) => toggleSpecifyAnswerText(value)}
                        inlineLabel={true}
                        disabled={questionType === FREE_TEXT_CONNECTORS}
                    />

                    {enableSpecifyAnswerText ? (
                        <Stack>
                            <Stack.Item>
                                <Stack.Item>
                                    <Label>Correct answer text</Label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextEditor
                                        className={`${BASIC_TEXT_EDITOR_CLASS} quill-blue-background`}
                                        value={correctAnswerText}
                                        onChange={(text: string) => setCorrectAnswerText(text)}
                                        basicControl={true}
                                    />
                                </Stack.Item>
                            </Stack.Item>

                            <Stack.Item>
                                <Stack.Item>
                                    <Label>Wrong answer text</Label>
                                </Stack.Item>
                                <Stack.Item>
                                    <TextEditor
                                        className={`${BASIC_TEXT_EDITOR_CLASS} quill-blue-background`}
                                        value={wrongAnswerText}
                                        onChange={(text: string) => setWrongAnswerText(text)}
                                        basicControl={true}
                                    />
                                </Stack.Item>
                            </Stack.Item>
                        </Stack>
                    ) : null}
                </Stack>

                <ToggleFieldWrapper
                    label={"Skipping allowed"}
                    checked={skippingAllowed}
                    inlineLabel={true}
                    onChange={(value: boolean) => setSkippingAllowed(value)}
                />

                <ToggleFieldWrapper
                    label={"Specify time out"}
                    checked={timeOutAllowed}
                    inlineLabel={true}
                    onChange={(value: boolean) => setTimeOutAllowed(value)}
                />
                <Stack className={timeOutAllowed ? undefined : "display-none"}>
                    <Stack.Item>
                        <Label>Time out text</Label>
                    </Stack.Item>
                    <Stack.Item>
                        <TextEditor
                            value={timeOutText}
                            onChange={(text: string) => setTimeOutText(text)}
                            basicControl={true}
                            className={`${BASIC_TEXT_EDITOR_CLASS} quill-blue-background`}
                        />
                    </Stack.Item>
                </Stack>

                <Stack horizontal tokens={ThemedMediumStackTokens} className={"margin-bottom-15"}>
                    <Stack className={"width-150"}>
                        <TextFieldWrapper
                            disabled={questionType === FREE_TEXT_CONNECTORS}
                            label={"Max attempts"}
                            value={maxAttempts}
                            onTextFieldValueChange={(text: string) => setMaxAttempts(text)}
                            errorMessage={
                                maxAttempts !== "" && !FieldValidation.integerValidation(maxAttempts)
                                    ? "Please enter valid number"
                                    : ""
                            }
                        />
                    </Stack>
                    <Stack className={timeOutAllowed ? "width-150" : "display-none"}>
                        <Label>Question time limit</Label>
                        <Stack horizontal tokens={ThemedSmall2StackTokens}>
                            <TextFieldWrapper
                                onRenderDescription={() => <Text variant={"small"}>Minute</Text>}
                                onTextFieldValueChange={(text: string) => setMinutes(text)}
                                value={minutes}
                                placeholder={"00"}
                            />
                            <TextFieldWrapper
                                onRenderDescription={() => <Text variant={"small"}>Second</Text>}
                                onTextFieldValueChange={(text: string) => setSeconds(text)}
                                value={seconds}
                                placeholder={"00"}
                            />
                        </Stack>
                        <Stack.Item>
                            {minutes === "" && seconds === "" ? (
                                <span className={"error-message"}>Time limit is mandatory</span>
                            ) : FieldValidation.timeLimitValidation(
                                  SiteConstants.getMaskedTime("00", minutes, seconds)
                              ) ? null : (
                                <span className={"error-message"}>Please enter valid time limit</span>
                            )}
                        </Stack.Item>
                    </Stack>
                </Stack>
                <Stack.Item>
                    <Label>Wrong attempt penalty type</Label>
                </Stack.Item>
                <Stack horizontal tokens={ThemedMediumStackTokens}>
                    <Stack.Item>
                        <Form.Check
                            type="radio"
                            label="Score penalty"
                            name="penaltyType"
                            value={SCORE_PENALTY}
                            checked={penaltyType === SCORE_PENALTY}
                            onChange={() => {
                                setPenaltyType(SCORE_PENALTY);
                                setTimePenalty([]);
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Form.Check
                            className={"margin-left-20"}
                            type="radio"
                            label="Time penalty"
                            name="penaltyType"
                            value={TIME_PENALTY}
                            checked={penaltyType === TIME_PENALTY}
                            onChange={() => {
                                setPenaltyType(TIME_PENALTY);
                                setWrongPoints("");
                            }}
                        />
                    </Stack.Item>
                </Stack>
                {timePenalty.map((item, index) => getTimePenaltyField(item, index))}
                <Stack horizontal tokens={ThemedMediumStackTokens} className={"margin-right-48"}>
                    <Stack.Item>
                        <TextFieldWrapper
                            label={"Correct points"}
                            errorMessage={
                                correctPoints !== ""
                                    ? !FieldValidation.floatValidation(correctPoints)
                                        ? "Please enter valid number"
                                        : ""
                                    : ""
                            }
                            value={correctPoints}
                            onTextFieldValueChange={(text: string) => setCorrectPoints(text)}
                            disabled={isNonScoring || questionType === FREE_TEXT_CONNECTORS}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextFieldWrapper
                            label={"Wrong points"}
                            errorMessage={
                                wrongPoints !== ""
                                    ? !FieldValidation.floatValidation(wrongPoints)
                                        ? "Please enter valid number"
                                        : ""
                                    : ""
                            }
                            value={wrongPoints}
                            onTextFieldValueChange={(text: string) => setWrongPoints(text)}
                            disabled={
                                isNonScoring || questionType === FREE_TEXT_CONNECTORS || penaltyType === TIME_PENALTY
                            }
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextFieldWrapper
                            label={"Skip points"}
                            errorMessage={
                                skipPoints !== ""
                                    ? !FieldValidation.floatValidation(skipPoints)
                                        ? "Please enter valid number"
                                        : ""
                                    : ""
                            }
                            value={skipPoints}
                            onTextFieldValueChange={(text: string) => setSkipPoints(text)}
                            disabled={isNonScoring || questionType === FREE_TEXT_CONNECTORS}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        );
    };

    const handleFileChange = (event: any) => {
        if (event.target.files.length === 0) {
            setCoverImage("");
            setImageUploadErrorMessage("");
            setCoverImageFile(null);
            return;
        }

        const type = event.target.files[0].type;
        const size: number = event.target.files[0].size;
        if (!FieldValidation.imageTypeValidation(type)) {
            setCoverImage("");
            setImageUploadErrorMessage("Please select an image file");
            setCoverImageFile(null);
            return;
        }
        if (FieldValidation.imageSizeValidation(size)) {
            setCoverImage("");
            setImageUploadErrorMessage("Image size should be less than 2 MB");
            setCoverImageFile(null);
            return;
        }
        setCoverImage(URL.createObjectURL(event.target.files[0]));
        setFileInfo({
            fileName: event.target.files[0].name,
            fileSize: event.target.files[0].size / Math.pow(1024, 2)
        });
        setImageUploadErrorMessage("");
        setCoverImageFile(event.target.files[0]);
        return;
    };
    const handleVideoFileChange = (event: any) => {
        if (event.target.files.length === 0) {
            setVideoQuestion("");
            setVideoUploadErrorMessage("");
            setVideoQuestionFile(null);
            return;
        }

        const size: number = event.target.files[0].size;
        if (!FieldValidation.videoTypeValidation(event.target.files[0].name)) {
            setVideoQuestion("");
            setVideoUploadErrorMessage("Please select an video file");
            setVideoQuestionFile(null);
            return;
        }
        if (FieldValidation.videoSizeValidation(size, 40)) {
            setVideoQuestion("");
            setVideoUploadErrorMessage("Video size should be less than 40 MB");
            setVideoQuestionFile(null);
            return;
        }
        setVideoQuestion(URL.createObjectURL(event.target.files[0]));
        setVideoUploadErrorMessage("");
        setVideoQuestionFile(event.target.files[0]);
        return;
    };

    const onTagsChange = (
        // eslint-disable-next-line
        event: React.FormEvent<HTMLDivElement>,
        item?: IDropdownOption
    ): void => {
        if (item) {
            const selectedTag = item.selected
                ? [...tags, item.key as string]
                : tags.filter((key: any) => key !== item.key);

            setTags(selectedTag);
        }
    };

    const onIdentityChange = (
        // eslint-disable-next-line
        event: React.FormEvent<HTMLDivElement>,
        item?: IDropdownOption
    ): void => {
        if (item) {
            const selectedIdentity = item.selected
                ? [...accessibleIdentityIds, item.key as string]
                : accessibleIdentityIds.filter((key: any) => key !== item.key);

            setAccessibleIdentityIds(selectedIdentity);
        }
    };

    const removeCoverImage = (): void => {
        if (questionId === -1) {
            setCoverImage("");
            setCoverImageFile(null);
            return;
        }
        setIsRemovingCoverImage(true);
        Client.getInstance()
            .deleteData(ApiConstants.removeQuestionCoverImage(questionId))
            .then(() => {
                setIsRemovingCoverImage(false);
                setCoverImage("");
                setCoverImageFile(null);
                toast.success("Cover image removed", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                setIsRemovingCoverImage(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const removeVideoQuestion = (): void => {
        if (questionId !== -1) {
            setVideoQuestion("");
            setVideoQuestionFile(null);

            setIsRemovingVideoQuestion(true);
            Client.getInstance()
                .deleteData(ApiConstants.removeQuestionVideo(questionId))
                .then(() => {
                    setIsRemovingVideoQuestion(false);
                    setVideoQuestion("");
                    setVideoQuestionFile(null);
                    toast.success("Video Question removed", SiteConstants.successToastConfiguration);
                })
                .catch(() => {
                    setIsRemovingVideoQuestion(false);
                });
        }
    };

    const getQuestionSection = (): JSX.Element => {
        return (
            <Stack
                tokens={ThemedMediumStackTokens}
                style={{
                    backgroundColor:
                        questionId !== -1 && accessibilityScope && ["limited", "global"].includes(accessibilityScope)
                            ? dangerColorLight
                            : ""
                }}
                className="section-background section-border padding-10 overflowy-auto">
                <Stack className={"margin-right-48"} tokens={ThemedMediumStackTokens}>
                    <Stack.Item>
                        <Label>Question title</Label>
                        <TextEditor
                            className={`${BASIC_TEXT_EDITOR_CLASS} quill-blue-background`}
                            value={questionTitle}
                            onChange={(text: string) => setQuestionTitle(text)}
                            basicControl={true}
                        />
                        {FieldValidation.quillTextEditorValidation(questionTitle) ? (
                            <span className={"error-message"}>Question title is mandatory</span>
                        ) : null}
                    </Stack.Item>

                    <Stack horizontal tokens={ThemedSmall2StackTokens}>
                        <Stack.Item grow>
                            <Dropdown
                                label={"Tags"}
                                selectedKeys={tags}
                                options={tagsOption}
                                onChange={onTagsChange}
                                multiSelect
                                errorMessage={tags.length === 0 ? "Tags are mandatory" : ""}
                            />
                        </Stack.Item>
                        <IconButton
                            className={"margin-top-30"}
                            iconProps={{ iconName: "Settings" }}
                            onClick={() => setShowTagsModal(true)}
                        />
                    </Stack>

                    <Stack.Item align={"start"}>
                        <ToggleFieldWrapper
                            inlineLabel
                            label={"Is video question?"}
                            checked={isVideo}
                            onChange={() => {
                                setIsVideo((prevState) => !prevState);
                                if (questionType === "connector") {
                                    setQuestionType("mcq");
                                }
                            }}
                        />
                    </Stack.Item>

                    {isVideo && (
                        <Stack.Item>
                            <Label>Video Upload</Label>
                            <Stack horizontal tokens={ThemedMediumStackTokens}>
                                <Stack.Item>
                                    <Form.Check
                                        type="radio"
                                        label="Upload video"
                                        name="videoType"
                                        value={UPLOAD_VIDEO}
                                        checked={videoType === UPLOAD_VIDEO}
                                        onClick={() => setVideoType(UPLOAD_VIDEO)}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Form.Check
                                        className={"margin-left-20"}
                                        type="radio"
                                        label="Specify youtube link"
                                        name="videoType"
                                        value={YOUTUBE_LINK}
                                        checked={videoType === YOUTUBE_LINK}
                                        onClick={() => setVideoType(YOUTUBE_LINK)}
                                    />
                                </Stack.Item>
                            </Stack>
                            <QuestionVideoSection
                                handleVideoFileChange={handleVideoFileChange}
                                isRemovingVideoQuestion={isRemovingVideoQuestion}
                                selectedVideoType={videoType}
                                removeVideoQuestion={removeVideoQuestion}
                                videoQuestion={videoQuestion}
                                youtubeVideoText={youtubeVideoText}
                                handleYoutubeVideoFileChange={setYoutubeVideoText}
                                videoUploadErrorMessage={videoUploadErrorMessage}
                            />
                        </Stack.Item>
                    )}
                    <Stack.Item>
                        <Label>Top question text</Label>
                        <TextEditor
                            className={`${ADVANCE_TEXT_EDITOR_CLASS} quill-blue-background`}
                            value={question}
                            onChange={(text: string) => setQuestion(text)}
                        />
                        {!isVideo &&
                            FieldValidation.quillTextEditorValidation(bottomQuestionText) &&
                            FieldValidation.quillTextEditorValidation(question) && (
                                <span className={"error-message"}>Question text is mandatory</span>
                            )}
                    </Stack.Item>
                    {!isVideo && (
                        <Stack.Item>
                            <Label>Cover image</Label>
                            <Stack horizontal grow>
                                <Stack.Item grow>
                                    <Form.Group>
                                        <Form.File
                                            value={coverImage === "" ? "" : undefined}
                                            onChange={(event: any) => handleFileChange(event)}
                                        />
                                        {imageUploadErrorMessage && (
                                            <span className={"error-message"}>{imageUploadErrorMessage}</span>
                                        )}
                                    </Form.Group>
                                </Stack.Item>
                                <Stack horizontalAlign={"end"} verticalAlign={"start"}>
                                    {isRemovingCoverImage ? (
                                        <Spinner size={SpinnerSize.large} />
                                    ) : (
                                        <DefaultButton
                                            onClick={removeCoverImage}
                                            className={"dashed-border-default-button"}
                                            text={"Remove Cover Image"}
                                        />
                                    )}
                                </Stack>
                            </Stack>
                            {coverImage && (
                                <Stack horizontal>
                                    <Stack.Item>
                                        <Image src={coverImage} alt="Cover Image" className="question-image" />
                                    </Stack.Item>
                                    <Stack.Item className={"padding-25"}>
                                        <Label>Filename: {fileInfo.fileName}</Label>
                                        {fileInfo.fileSize ? (
                                            <Label>Filesize: {fileInfo.fileSize.toFixed(2)}MB</Label>
                                        ) : (
                                            ""
                                        )}
                                    </Stack.Item>
                                </Stack>
                            )}
                        </Stack.Item>
                    )}

                    <Stack.Item>
                        <Label>Bottom question text</Label>
                        <TextEditor
                            className={`${ADVANCE_TEXT_EDITOR_CLASS} quill-blue-background`}
                            value={bottomQuestionText}
                            onChange={(text: string) => setBottomQuestionText(text)}
                        />
                        {!isVideo &&
                            FieldValidation.quillTextEditorValidation(bottomQuestionText) &&
                            FieldValidation.quillTextEditorValidation(question) && (
                                <span className={"error-message"}>Question text is mandatory</span>
                            )}
                    </Stack.Item>

                    <Stack.Item>
                        <Label>Reference item text 1</Label>
                        <TextEditor
                            className={`${BASIC_TEXT_EDITOR_CLASS} quill-blue-background quill-minHeight-32px`}
                            value={referenceItemText1}
                            onChange={(text: string) => setReferenceItemText1(text)}
                            basicControl={true}
                        />
                    </Stack.Item>

                    <Stack.Item>
                        <Label>Reference item text 2</Label>
                        <TextEditor
                            className={`${BASIC_TEXT_EDITOR_CLASS} quill-blue-background quill-minHeight-32px`}
                            value={referenceItemText2}
                            onChange={(text: string) => setReferenceItemText2(text)}
                            basicControl={true}
                        />
                    </Stack.Item>
                </Stack>
                {!!userDetail?.currentRoles.find((item) => item.name === superAdmin) &&
                    userDetail?.identity.name === "jambar" &&
                    renderVisibilty()}
                <Stack className={"margin-right-48"}>
                    <Stack.Item>
                        <Label>Question type</Label>
                    </Stack.Item>
                    <Stack horizontal tokens={ThemedMediumStackTokens}>
                        <Stack.Item>
                            <Form.Check
                                type="radio"
                                label="Multiple choice"
                                name="questionType"
                                value={MCQ}
                                checked={questionType === MCQ}
                                onClick={() => setQuestionType(MCQ)}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Form.Check
                                className={"margin-left-20"}
                                type="radio"
                                label="Free text"
                                name="questionType"
                                value={FREE_TEXT}
                                checked={questionType === FREE_TEXT}
                                onClick={() => setQuestionType(FREE_TEXT)}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Form.Check
                                disabled={isVideo}
                                className={"margin-left-20"}
                                type="radio"
                                label="Connector"
                                name="questionType"
                                value={FREE_TEXT_CONNECTORS}
                                checked={questionType === FREE_TEXT_CONNECTORS}
                                onClick={() => {
                                    setQuestionType(FREE_TEXT_CONNECTORS);
                                    setSpecifyAnswerText(false);
                                    setCorrectPoints("");
                                    setWrongPoints("");
                                    setSkipPoints("");
                                    setMaxAttempts("");
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Form.Check
                                className={"margin-left-20"}
                                type="radio"
                                label="Upload file"
                                name="questionType"
                                value={FILE_UPLOAD}
                                checked={questionType === FILE_UPLOAD}
                                onClick={() => setQuestionType(FILE_UPLOAD)}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack className={"margin-bottom-15 margin-top-10"} tokens={ThemedMediumStackTokens}>
                        {getQuestionView(questionType)}
                    </Stack>
                    <Modal
                        isOpen={showTagsModal}
                        onDismiss={closeTagsModel}
                        className={"tags-modal"}
                        isBlocking={false}>
                        <QuestionTagModal
                            fetchQuestionList={() => fetchQuestionList(questionId)}
                            closeModal={closeTagsModel}
                        />
                    </Modal>
                </Stack>
            </Stack>
        );
    };

    const closeTagsModel = (): void => {
        setShowTagsModal(false);
        fetchTagsDropdownData();
    };

    const getQuestionView = (questionType: string) => {
        if (questionType === MCQ) {
            return mcqField();
        }
        if (questionType === FREE_TEXT) {
            return textFieldAnswer();
        }
        if (questionType === FREE_TEXT_CONNECTORS) {
            return connectorField();
        }
    };

    const renderSaveButton = () => {
        if (questionId === -1 || userDetail?.identity.name === "jambar" || accessibilityScope === "private") {
            return <PrimaryButton iconProps={{ iconName: "Save" }} text={"Save"} onClick={saveAll} />;
        }
        return <></>;
    };

    return (
        <>
            <ToastContainer />
            {showLoadingSpinner ? (
                <Spinner className={"loading-spinner"} size={SpinnerSize.large} label={"Loading..."} />
            ) : (
                <Stack tokens={ThemedMediumStackTokens}>
                    <Pivot>
                        <PivotItem headerText={"Question"} className={"margin-top-10"}>
                            {getQuestionSection()}
                        </PivotItem>
                        <PivotItem headerText={"Question Settings"} className={"margin-top-10"}>
                            {getQuestionSettingsSection()}
                        </PivotItem>
                        {questionId !== -1 && (
                            <PivotItem headerText={"Hints"} className={"margin-top-10"}>
                                <QuestionHintsListView questionId={questionId} />
                            </PivotItem>
                        )}
                    </Pivot>
                    <Stack horizontal horizontalAlign={"center"} tokens={ThemedMediumStackTokens}>
                        {isLoading ? <Spinner size={SpinnerSize.large} /> : renderSaveButton()}
                    </Stack>

                    <Stack horizontal horizontalAlign={"center"} className={"margin-bottom-15"}>
                        {showValidationErrorMessage && (
                            <span className={"error-message"}>Please fill in all the mandatory fields</span>
                        )}
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default QuestionDetailView;

export const setOptionButtonClass = (selectedOptions: string[], id: string) => {
    if (selectedOptions.length === 0) {
        return "refrence-options cursor";
    }

    if (selectedOptions.includes(id)) {
        return "refrence-options cursor option-select";
    }

    return "refrence-options cursor";
};

export const setColor = (
    answerResult: string,
    correctBackgroundColor: string,
    wrongBackgroundColor: string
): string => {
    return answerResult === "correct" ? correctBackgroundColor : wrongBackgroundColor;
};

import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { darkLightColor, darkPrimaryColor } from "../../../../../../constants/ColorConstant";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import { CaptionText, Header2 } from "../../../../../CommonComponent/v2/CommonStyle";
import FormikTextInput from "../../../../../CommonComponent/v2/fields/FormikTextInput";
import ImageDrop from "../../../../../CommonComponent/v2/fields/ImageDrop";
import { GameDetailType } from "../Type";

const GameTemplateForm = () => {
    const { values, setFieldValue } = useFormikContext();
    const data = values as GameDetailType;

    const renderColor = () => {
        if (data.gameIdentification.gameType === SiteConstants.wildgoat) {
            return (
                <>
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"gameTemplate.colors.primaryColor"}
                            id={"gameTemplate.colors.primaryColor"}
                            label={"Primary colour - background and text"}
                            fullWidth
                            setColor={(color) => setFieldValue("gameTemplate.colors.primaryColor", color)}
                            textfieldType="color"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"gameTemplate.colors.secondaryColor"}
                            id={"gameTemplate.colors.secondaryColor"}
                            label={"Secondary colour - selections and highlights"}
                            fullWidth
                            setColor={(color) => setFieldValue("gameTemplate.colors.secondaryColor", color)}
                            textfieldType="color"
                        />
                    </Grid>
                </>
            );
        }

        return (
            <>
                <Grid item xs={12}>
                    <FormikTextInput
                        name={"gameTemplate.colors.backgroundColor"}
                        id={"gameTemplate.colors.backgroundColor"}
                        label={"Background color"}
                        fullWidth
                        setColor={(color) => setFieldValue("gameTemplate.colors.backgroundColor", color)}
                        textfieldType="color"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormikTextInput
                        name={"gameTemplate.colors.buttonColor"}
                        id={"gameTemplate.colors.buttonColor"}
                        label={"Button color"}
                        fullWidth
                        setColor={(color) => setFieldValue("gameTemplate.colors.buttonColor", color)}
                        textfieldType="color"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormikTextInput
                        name={"gameTemplate.colors.correctAnswerBackgroundColor"}
                        id={"gameTemplate.colors.correctAnswerBackgroundColor"}
                        label={"Correct answer background color"}
                        fullWidth
                        setColor={(color) => setFieldValue("gameTemplate.colors.correctAnswerBackgroundColor", color)}
                        textfieldType="color"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormikTextInput
                        name={"gameTemplate.colors.wrongAnswerBackgroundColor"}
                        id={"gameTemplate.colors.wrongAnswerBackgroundColor"}
                        label={"Wrong answer background color"}
                        fullWidth
                        setColor={(color) => setFieldValue("gameTemplate.colors.wrongAnswerBackgroundColor", color)}
                        textfieldType="color"
                    />
                </Grid>
            </>
        );
    };

    const renderTemplateForm = () => {
        if (data.gameIdentification.gameType === SiteConstants.wildgoat) {
            return (
                <>
                    <Grid item xs={12}>
                        <CaptionText fontWeight={700} color={darkPrimaryColor}>
                            Answer screens
                        </CaptionText>
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"gameTemplate.answerScreen.correctAnswerHeading"}
                            id={"gameTemplate.answerScreen.correctAnswerHeading"}
                            label={"Correct answer heading"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid>
                            <ImageDrop
                                imageSize={2}
                                variant={"secondary"}
                                selectedFile={data.gameTemplate.answerScreen.correctAnswerBackgroundImage}
                                logoInfo={"Correct answer screen background(800px * 600px)"}
                                name={"gameTemplate.answerScreen.correctAnswerBackgroundImage"}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"gameTemplate.answerScreen.wrongAnswerHeading"}
                            id={"gameTemplate.answerScreen.wrongAnswerHeading"}
                            label={"Wrong answer heading"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid>
                            <ImageDrop
                                imageSize={2}
                                variant={"secondary"}
                                selectedFile={data.gameTemplate.answerScreen.wrongAnswerBackgroundImage}
                                logoInfo={"Wrong answer screen background(800px * 600px)"}
                                name={"gameTemplate.answerScreen.wrongAnswerBackgroundImage"}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"gameTemplate.answerScreen.timeoutAnswerAnswerHeading"}
                            id={"gameTemplate.answerScreen.timeoutAnswerAnswerHeading"}
                            label={"Timeout answer heading"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid>
                            <ImageDrop
                                imageSize={2}
                                variant={"secondary"}
                                selectedFile={data.gameTemplate.answerScreen.timeoutAnswerBackgroundImage}
                                logoInfo={"Timeout answer screen background(800px * 600px)"}
                                name={"gameTemplate.answerScreen.timeoutAnswerBackgroundImage"}
                            />
                        </Grid>
                    </Grid>
                </>
            );
        }

        return (
            <>
                <Grid item xs={12}>
                    <CaptionText fontWeight={700} color={darkPrimaryColor}>
                        Game screen background image
                    </CaptionText>
                    <Grid>
                        <ImageDrop
                            imageSize={2}
                            variant={"secondary"}
                            selectedFile={data.gameTemplate.gameScreenBackgroundImage}
                            logoInfo={"Attach image for the game screen background(800px * 600px)"}
                            name={"gameTemplate.gameScreenBackgroundImage"}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <CaptionText fontWeight={700} color={darkPrimaryColor}>
                        Progress image
                    </CaptionText>
                    <Grid>
                        <ImageDrop
                            imageSize={2}
                            variant={"secondary"}
                            selectedFile={data.gameTemplate.progressImage}
                            logoInfo={"Attach image for the progress(800px * 600px)"}
                            name={"gameTemplate.progressImage"}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={"30px"}>
                    <CaptionText fontWeight={700} color={darkPrimaryColor}>
                        Title
                    </CaptionText>
                </Grid>
                <Grid item xs={12}>
                    <FormikTextInput
                        name={"gameTemplate.title.progressTitle"}
                        id={"gameTemplate.title.progressTitle"}
                        label={"Progress title"}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormikTextInput
                        name={"gameTemplate.title.attachmentSectionTitle"}
                        id={"gameTemplate.title.attachmentSectionTitle"}
                        label={"Attachment section title"}
                        fullWidth
                    />
                    <CaptionText mt={"5px"} style={{ fontStyle: "italic" }} fontWeight={400} color={darkLightColor}>
                        You may add attachments later from the game details page under Attachments tab.
                    </CaptionText>
                </Grid>
            </>
        );
    };

    return (
        <Grid container mt={"60px"}>
            <Grid item xs={12}>
                <Header2>
                    Game template<span style={{ color: darkLightColor }}>(optional)</span>
                </Header2>
                <CaptionText mt={"5px"}>
                    Settings relevant to the template of the game screen within which the player will interact with the
                    game can be adjusted here.
                </CaptionText>
            </Grid>
            <Grid item xs={12}>
                <Grid container mt={"19px"} spacing={"15px"}>
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"gameTemplate.title.browserTabTitle"}
                            id={"gameTemplate.title.browserTabTitle"}
                            label={"Browser tab title"}
                            fullWidth
                        />
                    </Grid>
                    {renderTemplateForm()}
                    <Grid item mt={"30px"} xs={12}>
                        <CaptionText fontWeight={700} color={darkPrimaryColor}>
                            Colour
                        </CaptionText>
                    </Grid>
                    {renderColor()}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GameTemplateForm;

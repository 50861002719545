/* eslint-disable no-console */
import { intersection } from "lodash";
import { toast } from "react-toastify";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { extractHtmlContent } from "../../../../utils/JambarUtils";
import { convertTimeTo12HoursFormat, convertTimeTo24HoursFormat } from "../../../../utils/TimeUtils";
import { Client } from "../../../Base/Client";
import { FieldType, LeaderBoardDetailsType, LeaderBoardEventListItem, QuestionInfoTooltipType } from "./Type";

export const leaderboardFields: FieldType[] = [
    {
        id: "position",
        label: "Position"
    },
    {
        id: "teamName",
        label: "Team name"
    },
    {
        id: "participantCode",
        label: "Participant code"
    },
    {
        id: "status",
        label: "Status"
    },
    {
        id: "startTime",
        label: "Start time"
    },
    {
        id: "score",
        label: "Score"
    },
    {
        id: "questionAnsweredCount",
        label: "No. of questions answered"
    },
    {
        id: "correctAnswersCount",
        label: "No. of correct answers"
    },
    {
        id: "wrongAnswersCount",
        label: "No. of wrong answers"
    },
    {
        id: "hintsUsedCount",
        label: "No. of Hints used"
    },
    {
        id: "timeTakenInQuiz",
        label: "Time taken in quiz"
    },
    {
        id: "timeLeftInConnector",
        label: "Time left in connector"
    },
    {
        id: "timeLeftInQuiz",
        label: "Time left in quiz"
    },
    {
        id: "currentQuestion",
        label: "Current Question"
    }
];

const transformData = (data: any) => {
    if (!data) {
        return [];
    }

    return data.map((item: any, index: number) => ({
        correctAnswersCount: item.correctAnswersCount || 0,
        hintsUsedCount: item.hintsUsedCount || 0,
        participantCode: item.participantCode,
        score: item.score || 0,
        startTime: item.startTime ? convertTimeTo24HoursFormat(item.startTime) : "",
        status: item.status,
        teamName: item.teamName,
        timeTakenInQuiz: item.timeTakenInQuiz,
        timeLeftInConnector: item.timeLeftInConnector,
        timeLeftInQuiz: item.timeLeftInQuiz,
        wrongAnswersCount: item.wrongAnswersCount || 0,
        position: index + 1,
        id: index + 1,
        questionAnsweredCount: item.correctAnswersCount + item.wrongAnswersCount
    }));
};

export const fetchClosedEventLeaderBoardData = (eventId: string, forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getClosedEventLeaderBoardScores(parseInt(eventId)), forceRefresh)
        .then((response) => Promise.resolve(transformData(response.data.data)))
        .catch((error) => Promise.reject(error));

export const fetchLeaderBoardData = (eventId: string, forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getLiveLeaderBoardScores(parseInt(eventId)), forceRefresh)
        .then((response) => Promise.resolve(transformData(response.data.data)))
        .catch((error) => Promise.reject(error));

export const getStatusColor = (status: string) => {
    if (status === "Active") {
        return "#65B44A";
    }
    if (status === "Finished") {
        return "#1F71B6";
    }
    return "#828282";
};

export const getLeaderboardListFilteredItems = (list: LeaderBoardDetailsType[], searchText: string): any =>
    list.filter(
        (item: LeaderBoardDetailsType) =>
            searchText === "" ||
            (item.teamName && item.teamName.toString().toLowerCase().includes(searchText)) ||
            (item.participantCode && item.participantCode.toLowerCase().includes(searchText))
    );

export const leaderboardEventTabs = [
    {
        label: "Active",
        value: "active",
        toShow: true
    },
    {
        label: "Closed",
        value: "closed",
        toShow: true
    }
];

const eventTransformData = (item: any): LeaderBoardEventListItem => {
    return {
        eventId: item.eventId,
        quizId: item.quizId,
        quizName: extractHtmlContent(item.quizName),
        startDate: JambarDateUtil.dateFormat(item.startedOn, "DD/MM/YYYY"),
        eventName: item.eventName
    };
};

export const fetchLiveEvent = (forceRefresh = false) => {
    return Client.getInstance()
        .getData(ApiConstants.getLiveEventsLeaderBoardList(), forceRefresh)
        .then((response: any) => {
            return Promise.resolve(
                response.data.data.map((item: any, index: number) => ({ id: index, ...eventTransformData(item) }))
            );
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const fetchClosedEventsData = (forceRefresh = false) => {
    return Client.getInstance()
        .getData(ApiConstants.getClosedEventsLeaderBoardList(), forceRefresh)
        .then((response: any) => {
            return Promise.resolve(
                response.data.data.map((item: any, index: number) => ({ id: index, ...eventTransformData(item) }))
            );
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const filterEventList = (list: LeaderBoardEventListItem[], searchText: string): LeaderBoardEventListItem[] => {
    if (searchText === "") {
        return list;
    }
    const trimSearchTextArray = searchText.trim().toLowerCase().split(" ");
    return list.filter(
        (item) =>
            intersection(item.eventName.trim().toLowerCase().split(" "), trimSearchTextArray).length ===
            trimSearchTextArray.length
    );
};

const transformCurrentQuestionData = (data: any): QuestionInfoTooltipType | null => {
    if (!data) {
        return null;
    }

    return {
        gameType: data.gameType,
        questionText: data.questionText,
        questionText2: data.questionText2,
        title: data.title,
        type: data.type
    };
};

export const fetchCurrentQuestion = (eventLink: string, participantCode: string, forceRefresh = false) => {
    return Client.getInstance()
        .getData(ApiConstants.fetchNextQuestionApiUrl(eventLink, participantCode), forceRefresh)
        .then((response: any) => {
            return Promise.resolve(transformCurrentQuestionData(response.data.data.nextQuestionInfo));
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const getEventUrl = (eventId: string) => {
    return Client.getInstance()
        .getData(ApiConstants.getEventDetailApiUrl(eventId))
        .then((response: any) => Promise.resolve(response.data.data.eventLink))
        .catch((error: any) => Promise.reject(error));
};

import React from "react";
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType } from "@fluentui/react";

type ConfirmationDialogProps = {
    isShown: boolean;
    entryName: string;
    onDismiss: () => void;
    onConfirm: () => void;
    dialogHeader: string;
    actionName: string;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    isShown,
    entryName,
    onDismiss,
    onConfirm,
    actionName,
    dialogHeader
}) => {
    const dialogContentProps = {
        type: DialogType.normal,
        title: `${dialogHeader}`,
        closeButtonAriaLabel: "Close",
        subText: `Are you sure you want to ${actionName} this ${entryName} ?`
    };

    return (
        <Dialog hidden={!isShown} onDismiss={onDismiss} dialogContentProps={dialogContentProps}>
            <DialogFooter>
                <PrimaryButton id={"confirmDelete"} onClick={onConfirm} text="Confirm" />
                <DefaultButton id={"cancelDelete"} onClick={onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
};

export default ConfirmationDialog;

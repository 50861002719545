import { number, object, string } from "yup";
import { ApiConstants } from "../../../../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../../../../constants/JambarDateUtils";
import { extractHtmlContent } from "../../../../../../utils/JambarUtils";
import { Client } from "../../../../../Base/Client";
import { AddHintType, HintListItemType } from "./Type";

const tranformData = (data: any): HintListItemType[] => {
    if (!data) {
        return [];
    }
    return data.map((hint: any, index: number) => ({
        id: hint.id,
        hint: extractHtmlContent(hint.description),
        createdBy: `${hint.createdByUser.firstName} ${hint.createdByUser.lastName}`,
        createdDate: JambarDateUtil.formatDate(hint.createdAt),
        lastUpdatedBy: `${hint.updatedByUser.firstName} ${hint.updatedByUser.lastName}`,
        lastUpdatedDate: JambarDateUtil.formatDate(hint.updatedAt),
        scorePenalty: hint.scorePenalty,
        index: index + 1,
        timePenalty: hint.timePenalty
    }));
};

export const getHintList = (questionId: number, forceRefresh: boolean) =>
    Client.getInstance()
        .getData(ApiConstants.getAllHintsOfQuestion(questionId), forceRefresh)
        .then((response) => Promise.resolve(tranformData(response.data.data)))
        .catch((error) => Promise.reject(error));

export const deleteHint = (questionId: number, hintId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteQuestionHintApiUrl(questionId, hintId))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const AddHintInitialValue = (hintDetails?: HintListItemType): AddHintType => ({
    penaltyType: hintDetails ? (hintDetails.timePenalty ? "timeout_penalty" : "score_penalty") : "score_penalty",
    scorePenalty: hintDetails?.scorePenalty || "",
    timePenalty: hintDetails?.timePenalty || "",
    hintText: hintDetails?.hint || ""
});

export const AddHintValidationSchema = object().shape({
    scorePenalty: number().when("penaltyType", {
        is: (val: string) => val === "score_penalty",
        then: number().typeError("Please enter number").required("Score penalty is required"),
        otherwise: number().notRequired()
    }),
    timePenalty: number().when("penaltyType", {
        is: (val: string) => val === "time_penalty",
        then: number().typeError("Please enter number").required("Time penalty is required"),
        otherwise: number().notRequired()
    }),
    hintText: string().required("Hint text is required")
});

export const addNewHint = (questionId: number, value: AddHintType) =>
    Client.getInstance()
        .createData(ApiConstants.addQuestionHintApiUrl(questionId), {
            description: value.hintText,
            scorePenalty: value.penaltyType === "score_penalty" ? value.scorePenalty : "",
            timePenalty: value.penaltyType === "timeout_penalty" ? value.timePenalty : ""
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const updateHint = (questionId: number, hintId: number, value: AddHintType) =>
    Client.getInstance()
        .updateData(ApiConstants.updateQuestionHintApiUrl(questionId, hintId), {
            description: value.hintText,
            scorePenalty: value.penaltyType === "score_penalty" ? value.scorePenalty : "",
            timePenalty: value.penaltyType === "timeout_penalty" ? value.timePenalty : ""
        })
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

export class FieldValidation {
    public static floatValidation(number: string): boolean {
        if (/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/g.test(number)) {
            return true;
        }
        return false;
    }

    public static integerValidation(number: string): boolean {
        if (/^[0-9]+$/g.test(number)) {
            return true;
        }
        return false;
    }

    public static generateAlphaNumericCode(): string {
        return Math.random().toString(36).substr(2, 8).toUpperCase();
    }

    public static timeLimitValidation(time: string): boolean {
        const timeArray = time.split(":");
        const hours = parseInt(timeArray[0]);
        const minutes = parseInt(timeArray[1]);
        const seconds = parseInt(timeArray[2]);
        if (/[^0-9:]/g.test(time)) {
            return false;
        }
        if (hours > 24 || hours < 0) {
            return false;
        }
        if (minutes > 60 || minutes < 0) {
            return false;
        }
        if (seconds > 60 || seconds < 0) {
            return false;
        }
        if (hours === 0 && minutes === 0 && seconds === 0) {
            return false;
        }
        return true;
    }

    public static quillTextEditorValidation(text: string): boolean {
        if (text === "<p>\u200D</p>") {
            return true;
        }
        if (/<img([\w\W]+?)>|<iframe ([\w\W]+?)><\/iframe>/g.test(text)) {
            return false;
        }
        if (/<img([\w\W]+?)>/g.test(text)) {
            return false;
        }
        if (text.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
            return true;
        }
        return false;
    }

    public static imageTypeValidation(type: string): boolean {
        return [
            "image/gif",
            "image/jpeg",
            "image/png",
            "image/jpg",
            "image/GIF",
            "image/PNG",
            "image/JPG",
            "image/svg",
            "image/svg+xml",
            "image/tiff",
            "image/webp"
        ].includes(type);
    }

    public static imageValidationByExtension(filename: string): boolean {
        const fileType = filename.split(".").pop() || "";
        return ["gif", "jpeg", "png", "jpg", "GIF", "svg", "svg+xml", "tiff", "webp", "heic"].includes(
            fileType.toLowerCase()
        );
    }

    public static videoTypeValidation(filename: string): boolean {
        const fileType = filename.split(".").pop() || "";
        return [
            "mp4",
            "webm",
            "ogg",
            "mov",
            "avi",
            "flv",
            "wmv",
            "m4v",
            "mpeg4",
            "h264",
            "h265",
            "mkv",
            "hevc"
        ].includes(fileType.toLowerCase());
    }

    public static urlValidation(url: string): boolean {
        return RegExp(
            "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + //port
                "(\\?[;&amp;a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ).test(url);
    }

    public static quizTimeLimitValidation(minutes: number, seconds: number): boolean {
        if (minutes > 1439 || (minutes === 1439 && seconds === 60)) {
            return false;
        }
        if (seconds > 60 || seconds < 0 || minutes < 0) {
            return false;
        }
        return true;
    }

    public static pdfFileValidation(fileType: string): boolean {
        return fileType === "application/pdf";
    }

    public static attachmentSizeValidation(size: number): boolean {
        if (size / Math.pow(1024, 2) > 5) {
            return true;
        }
        return false;
    }

    public static imageSizeValidation(size: number): boolean {
        if (size / Math.pow(1024, 2) > 2) {
            return true;
        }
        return false;
    }

    public static videoSizeValidation(size: number, maxSize: number): boolean {
        if (size / Math.pow(1024, 2) > maxSize) {
            return true;
        }
        return false;
    }

    public static freeTextContainsValidation(answer: string, userAnswer: string): boolean {
        return RegExp(`\\b${answer}`, "g").test(userAnswer.toLowerCase());
    }

    public static freeTextDoesNotContainsValidation(answer: string, userAnswer: string): boolean {
        if (RegExp(`\\b${answer}\\b`, "g").test(userAnswer.toLowerCase())) {
            return true;
        }
        return false;
    }

    public static freeTextExactMatchValidation(answer: string, userAnswer: string): boolean {
        if (RegExp(`\\b${answer}\\b`, "g").test(userAnswer.toLowerCase().trim())) {
            return false;
        }
        return true;
    }

    public static generateAlphaNumericCodeExcludingValues(codelength: number): string {
        let text = "";
        const possible = "ABCDEFGHJKMNPQRSTUVWXYZ123456789";

        for (let i = 0; i < codelength; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    public static imageTypes = ["jpg", "png", "gif", "jfif", "jpeg", "svg"];
    public static docTypes = ["doc", "docx"];
    public static gifType = ["gif"];
    public static videoType = ["3gp", "mp4", "mov", "avi"];
    public static htmlType = ["html"];
    public static textType = ["txt"];
    public static logType = ["log"];
    public static pdfType = ["pdf"];
}

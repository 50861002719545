import { MenuItem, Select, SelectProps, FormControl, InputLabel } from "@mui/material";
import { FC } from "react";
import { OptionType } from "./Type";
type SelectWrapperProps = {
    options: OptionType[];
    value: string;
    handleChange: (value: string) => void;
} & SelectProps;
const SelectWrapper: FC<SelectWrapperProps> = ({ options, value, handleChange, ...rest }) => (
    <FormControl style={{ width: "100%", backgroundColor: "#FCFCFC" }}>
        <InputLabel style={{ color: "#828282" }}> {rest.label} </InputLabel>
        <Select value={value} onChange={(e) => handleChange(e.target.value as string)} {...rest}>
            {options.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                    {label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);
export default SelectWrapper;

import { RoleType } from "./Type";

export const getRoleListFilteredItems = (list: RoleType[], searchText: string): any =>
    list.filter((item: RoleType) => searchText === "" || includeInFilteredItems(searchText.toLowerCase(), item));

const includeInFilteredItems = (searchText: string, item: RoleType): boolean => {
    if (item.name.toLowerCase().includes(searchText)) {
        return true;
    }

    if (item.id.toString().includes(searchText)) {
        return true;
    }

    return false;
};

import { toast } from "react-toastify";
import { ApiConstants } from "../../../../../constants/ApiConstant";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { Client } from "../../../../Base/Client";

export const donwloadEventCodes = (eventId: number) => {
    return new Promise<any>((reject) => {
        toast.success("File downloading has started", SiteConstants.successToastConfiguration);
        Client.getInstance()
            .getData(ApiConstants.downloadEventCodesApiUrl(eventId), true)
            .then((response) => {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                link.setAttribute("download", `codes_${eventId}.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                toast.success("File has been downloaded", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const donwloadEventFiles = (eventId: number) => {
    return new Promise<any>((reject) => {
        toast.success("File downloading has started", SiteConstants.successToastConfiguration);
        Client.getInstance()
            .downloadZip(ApiConstants.getEventParticipantFileDownloadApiUrl(eventId))
            .then((res) => {
                const blob = new Blob([res.data], { type: "application/zip" });
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = downloadUrl;
                a.download = `eventFiles_${eventId}.zip`;
                document.body.appendChild(a);
                a.click();
                toast.success("File has been downloaded", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

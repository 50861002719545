import { toast } from "react-toastify";
import { ApiConstants } from "../../constants/ApiConstant";
import { SiteConstants } from "../../constants/SiteConstant";
import { FileDataType } from "../Admin/EventManager/EventDetails/utils/type";
import { Client } from "../Base/Client";

export const transformFileData = (data: any) => {
    const newFileData = data.map((item: FileDataType) => ({
        ...item,
        type: item.fileLocation.split(".").pop() || "",
        fileName: item.fileLocation.split("/").pop() || ""
    }));
    return newFileData || [];
};

export const fetchPublicFiles = (fileCode: string) =>
    Client.getInstance()
        .getData(ApiConstants.getFileUploadLink(fileCode))
        .then((res) => Promise.resolve(transformFileData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const downloadPublicUploadFiles = (eventId: string) => {
    return new Promise<any>((reject) => {
        toast.success("File downloading has started", SiteConstants.successToastConfiguration);
        Client.getInstance()
            .downloadZip(ApiConstants.getParticipantFileDownloadApiUrl(eventId))
            .then((res) => {
                const blob = new Blob([res.data], { type: "application/zip" });
                const downloadUrl = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = downloadUrl;
                a.download = `eventFiles_${eventId}.zip`;
                document.body.appendChild(a);
                a.click();
                toast.success("File has been downloaded", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                reject(error);
                toast.success("Something went wrong. Please try again", SiteConstants.deleteToastConfiguration);
            });
    });
};

export const deletePublicUploadFiles = (fileId: number, eventId: string) =>
    Client.getInstance()
        .deleteData(ApiConstants.deletePublicFileUploadLink(fileId, eventId))
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));

// import { Dialog } from "@fluentui/react";
import { FC } from "react";
import QrcodeGenerator from "./QrcodeGenerator";
import { Box, Dialog, Typography } from "@mui/material";
import { Header2 } from "./v2/CommonStyle";

type QrCodeModalProps = {
    handleClose: () => void;
    url: string;
};
const QrCodeModal: FC<QrCodeModalProps> = ({ handleClose, url }) => (
    <Dialog
        PaperProps={{
            sx: {
                borderRadius: "20px"
            }
        }}
        open
        onClose={handleClose}>
        <Box bgcolor={"#000"} p={"20px"}>
            <QrcodeGenerator height={300} width={300} link={url} />
            <Typography textAlign={"center"} mt={"20px"} fontSize={"30px"} color={"#fff"}>
                SCAN ME
            </Typography>
        </Box>
    </Dialog>
);

export default QrCodeModal;

import { Tabs, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Styled from "@emotion/styled";
import {
    darkPrimaryColor,
    lightPrimaryColor,
    lightPrimaryColor100,
    lightPrimaryColor200,
    primaryButtonColor,
    selectedButtonColor
} from "../../constants/ColorConstant";

export const ThankYouPageText = Styled.span`
    font-family: "Times New Roman";
    font-size: 20px;
    font-weight: 500;
    text-align: center;
`;

export const TabsButton = Styled(Tabs)`
    & .MuiTabs-flexContainer {
        background-color: ${lightPrimaryColor200};
    }
    & .Mui-selected {
        color: ${selectedButtonColor} !important;
        background-color: ${lightPrimaryColor} !important;
    }
    & .MuiTabs-indicator {
        background-color: none;
        height: 0px;
    }
    button:focus {
        outline: none;
    }
    button {
        padding: 22px;
        text-transform: capitalize;
        font: normal normal bold 20px/24px Raleway;
        letter-spacing: 0px;
        color: ${darkPrimaryColor};
        background-color: ${lightPrimaryColor100};
        min-width: 200px;
        opacity: 1;
    }
`;

export const DataGridWrapper = Styled(DataGrid)`
 & .MuiDataGrid-columnHeaders{
     max-height: 70px !important;
     height: 70px;
     background: ${lightPrimaryColor};
 }

 & .MuiDataGrid-cell:focus {
     outline: none !important;
 }
 & .MuiDataGrid-virtualScrollerContent {
     margin-top: 30px;
     background: ${lightPrimaryColor200};
 }
`;

export const DataHeaderText = Styled(Typography)`
    text-align: left;
    font: normal normal bold 20px/22px Nunito;
    letter-spacing: 0px;
    color: ${darkPrimaryColor};
`;

export const DataCaptionText = Styled(Typography)`
    font: normal normal normal 16px/22px Nunito;
    letter-spacing: 0px;
    color: ${darkPrimaryColor};
`;

export const PrimaryButton = Styled(Button)`
    background: ${primaryButtonColor} 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    text-transform: capitalize;
`;

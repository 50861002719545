import { FlowBoxValueType } from "./Type";

export const generateKey = () => {
    return `${new Date().getTime()}`;
};

export const transformData = (data: any): FlowBoxValueType[] => {
    if (!data) {
        return [];
    }
    return data.map((node: any) => ({
        id: node.id,
        description: node.description,
        title: node.title,
        value: node.value,
        position: node.position
    }));
};

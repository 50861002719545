import { IconButton, Label, PrimaryButton, Spinner, SpinnerSize, Stack, TextField } from "@fluentui/react";
import React, { useEffect, useMemo } from "react";
import { ThemedMediumStackTokens, ThemedSmall2StackTokens } from "../../../../constants/Styles";
import { useFormik } from "formik";
import * as yup from "yup";
import DatePickerWrapper from "../../../CommonComponent/DatePickerWrapper";
import { Client } from "../../../Base/Client";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { toast, ToastContainer } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { getEventCode } from "./Util";
import { useAdminContext } from "../../context/AdminAuthContext";

export type EventCodeDetailViewProps = {
    codeId: number;
    eventId: number;
    closeAddPanel: (id: number) => void;
    refreshCodeList: () => void;
    codeDetails: EventCodeDetail;
    eventIdsList: string[];
};

export type EventCodeDetail = {
    participantCode: string;
    status: string;
    generatedBy: string;
    startDate: string;
    endDate: string;
    notes: string;
};

const EventCodeDetailView: React.FC<EventCodeDetailViewProps> = ({
    codeId,
    eventId,
    closeAddPanel,
    refreshCodeList,
    codeDetails,
    eventIdsList
}) => {
    const { userDetail } = useAdminContext();
    const eventCodeInitialValue: EventCodeDetail = useMemo(
        () => ({
            endDate: codeDetails.endDate,
            participantCode: codeDetails.participantCode,
            generatedBy: codeDetails.generatedBy,
            notes: codeDetails.notes,
            startDate: codeDetails.startDate,
            status: codeDetails.status
        }),
        [codeDetails]
    );

    const eventCodeValidation = yup.object().shape(
        {
            startDate: yup.date().typeError("Please select a valid date").required("From date is mandatory"),
            endDate: yup
                .date()
                .when(
                    "startDate",
                    (startDate: Date, schema: any) =>
                        startDate && schema.min(startDate, "To date should be greater than From date")
                )
                .typeError("Please select a valid date")
                .required("To date is mandatory")
        },
        [["startDate", "endDate"]]
    );
    const formik = useFormik({
        initialValues: eventCodeInitialValue,
        onSubmit: () => onSaveEventCode(),
        validationSchema: eventCodeValidation,
        validateOnMount: true,
        enableReinitialize: true
    });

    useEffect(() => {
        if (codeId === -1) {
            formik.setFieldValue("participantCode", getEventCode(eventIdsList));
            userDetail &&
                formik.setFieldValue("generatedBy", `${userDetail.firstName} ${userDetail.lastName}`);
            return;
        }
    }, []);

    const reverseTransformData = (): any => ({
        endDate: JambarDateUtil.dateFormat(new Date(formik.values.endDate), "YYYY-MM-DD"),
        participantCode: formik.values.participantCode,
        generatedBy: formik.values.generatedBy,
        notes: formik.values.notes,
        startDate: JambarDateUtil.dateFormat(new Date(formik.values.startDate), "YYYY-MM-DD")
    });

    const onSaveEventCode = (): void => {
        if (codeId === -1) {
            Client.getInstance()
                .createData(ApiConstants.addEventCodeApiUrl(eventId), reverseTransformData())
                .then((response) => {
                    toast.success("Code added", SiteConstants.successToastConfiguration);
                    closeAddPanel(response.data.data.id);
                    refreshCodeList();
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => {
                    formik.setSubmitting(false);
                });
            return;
        }
        Client.getInstance()
            .updateData(ApiConstants.updateEventCodeApiUrl(eventId, codeId), {
                ...reverseTransformData(),
                status: formik.values.status
            })
            .then(() => {
                toast.success("Code updated", SiteConstants.successToastConfiguration);
                refreshCodeList();
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                formik.setSubmitting(false);
            });
    };

    return (
        <>
            <ToastContainer />
            <Stack tokens={ThemedMediumStackTokens}>
                <Stack
                    tokens={ThemedSmall2StackTokens}
                    className="section-background section-border padding-10 overflowy-auto">
                    <Stack horizontal grow>
                        <Label className={"width-20per"}>Code : </Label>
                        <Label className={"event-codes"}>{formik.values.participantCode}</Label>
                        <IconButton
                            className={"margin-left-right-10"}
                            iconProps={{ iconName: "copy" }}
                            title={"Copy code"}
                            onClick={() => navigator.clipboard.writeText(formik.values.participantCode)}
                        />
                    </Stack>

                    {codeId !== -1 && (
                        <Stack horizontal grow>
                            <Label className={"width-20per"}>Status : </Label>
                            <Label>{formik.values.status}</Label>
                        </Stack>
                    )}

                    <Stack horizontal grow>
                        <Label className={"width-20per"}>Generated by : </Label>
                        <Label>{formik.values.generatedBy}</Label>
                    </Stack>

                    <Stack.Item>
                        <Stack horizontal>
                            <Label className={"width-20per"}>Date Range : </Label>
                            <Stack horizontal tokens={ThemedMediumStackTokens}>
                                <Stack.Item className={"width-35per"}>
                                    <DatePickerWrapper
                                        datePickerValue={
                                            formik.values.startDate ? new Date(formik.values.startDate) : undefined
                                        }
                                        onSelectDate={(date) =>
                                            formik.setFieldValue(
                                                "startDate",
                                                JambarDateUtil.dateFormat(date, "YYYY-MM-DD").toString()
                                            )
                                        }
                                        placeholder={"From"}
                                    />

                                    {formik.errors.endDate && (
                                        <span className={"error-message"}>{formik.errors.startDate}</span>
                                    )}
                                </Stack.Item>

                                <Stack.Item className={"width-35per"}>
                                    <DatePickerWrapper
                                        datePickerValue={
                                            formik.values.endDate ? new Date(formik.values.endDate) : undefined
                                        }
                                        onSelectDate={(date) =>
                                            formik.setFieldValue(
                                                "endDate",
                                                JambarDateUtil.dateFormat(date, "YYYY-MM-DD").toString()
                                            )
                                        }
                                        placeholder={"To"}
                                    />

                                    {formik.errors.endDate && (
                                        <span className={"error-message"}>{formik.errors.endDate}</span>
                                    )}
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </Stack.Item>

                    <TextField
                        multiline
                        label={"Notes"}
                        autoAdjustHeight
                        value={formik.values.notes}
                        onChange={formik.handleChange}
                        name={"notes"}
                    />
                </Stack>

                <Stack horizontalAlign={"center"}>
                    {formik.isSubmitting ? (
                        <Spinner size={SpinnerSize.large} />
                    ) : (
                        <PrimaryButton
                            iconProps={{ iconName: "Save" }}
                            text={"Save"}
                            onClick={() => formik.handleSubmit()}
                        />
                    )}
                </Stack>
            </Stack>
        </>
    );
};

export default EventCodeDetailView;

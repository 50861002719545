import Styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const FlowChartContainer = Styled(Grid)`
& .react-flow__node-default{
    min-width: 150px !important;
    width: fit-content !important;
}
& .react-flow__node {
    border-radius: 5px !important;
    border: 1px grey solid;
}
`;

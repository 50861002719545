import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IProps, IStateless } from "../Base/Base";
import ThankYouPage from "../CommonComponent/ThankYouPage";
import PublicFilesView from "../PublicFiles/PublicFilesView";
import QuizSelectionView from "./QuizSelectionView";
import FlightCrewView from "./RocketTemplate/FlightCrewView/ReferenceItemView";
import RocketTemplateView from "./RocketTemplate/RocketTemplateView";

const ParticipantLogin = () => {
    return (
        <>
            <ToastContainer />
            <BrowserRouter>
                <Switch>
                    <Route exact path={"/participant/quizselection"}>
                        <QuizSelectionView />
                    </Route>
                    <Route exact path={"/participant/files"}>
                        <PublicFilesView />
                    </Route>
                    <Route exact path={"/participant/quiz"}>
                        <RocketTemplateView />
                    </Route>
                    {/* TODO: See if we can remove following route as it may not be in use */}
                    <Route exact path={"/participant/flighttemplate"}>
                        <FlightCrewView />
                    </Route>
                    <Route exact path="/thankyou">
                        <ThankYouPage />
                    </Route>
                </Switch>
            </BrowserRouter>
        </>
    );
};

export default ParticipantLogin;

import { ImMap } from "react-icons/im";
import { RiMessage3Line } from "react-icons/ri";
import { MdLeaderboard } from "react-icons/md";
import { AiOutlineTeam, AiFillLock } from "react-icons/ai";
import { FaQuestionCircle } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { whiteColor } from "../../../../constants/Styles";

export const menuListOption = [
    {
        id: "map",
        label: "Map",
        icon: <ImMap style={{ fontSize: 20, color: whiteColor }} />
    },
    {
        id: "inbox",
        label: "Inbox",
        icon: <RiMessage3Line style={{ fontSize: 20, color: whiteColor }} />
    },
    {
        id: "leaderboard",
        label: "Live team performance",
        icon: <MdLeaderboard style={{ fontSize: 20, color: whiteColor }} />
    },
    {
        id: "my_team",
        label: "My Team",
        icon: <AiOutlineTeam style={{ fontSize: 20, color: whiteColor }} />
    },
    {
        id: "faq",
        label: "FAQ's",
        icon: <FaQuestionCircle style={{ fontSize: 20, color: whiteColor }} />
    },
    {
        id: "setting",
        label: "Settings",
        icon: <FiSettings style={{ fontSize: 20, color: whiteColor }} />
    },
    {
        id: "code",
        label: "Code",
        icon: <AiFillLock style={{ fontSize: 20, color: whiteColor }} />
    }
];

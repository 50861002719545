import React, { useState } from "react";
import {
    CircleButtonBig,
    CustomInputBox,
    InfoText,
    WildGoatsContainer,
    ContentBox,
    MainText,
    WildGoatsCenterContainer
} from "../CommonStyledComponents/CommonStyle";
import { InputGroup } from "react-bootstrap";
import { Icon } from "@fluentui/react";
import { AvatarContainer, AvatarImage, ImageCircle } from "./Style";
import { avatarData } from "./avatarUtil";
import { linkColor } from "../../../../constants/Styles";
import { getOffsetColor } from "../Util/Util";
import { Client } from "../../../Base/Client";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { useHistory, useLocation } from "react-router";

const PickAvatar = () => {
    const [name, setName] = useState<string>("");
    const [backgroundColor] = useState<string>(linkColor);
    const info: any = useLocation();
    const history = useHistory();

    const checkTeamNameAlreadyPresent = () => {
        Client.getInstance()
            .getData(ApiConstants.getTeamNameApiUrl(info.state.eventLink, info.state.code), true)
            .then((res) => {
                if (!res.data.data.teamName) {
                    history.push("/wildgoat/teamname", {
                        code: info.state.code,
                        eventLink: info.state.eventLink,
                        eventId: info.state.eventId,
                        quizId: info.state.quizId,
                        participantName: name
                    });
                    return;
                }
                history.push("/wildgoat", {
                    code: info.state.code,
                    eventLink: info.state.eventLink,
                    eventId: info.state.eventId,
                    quizId: info.state.quizId,
                    teamName: res.data.data.teamName,
                    participantName: name
                });
            });
    };

    return (
        <WildGoatsContainer bgcolor={backgroundColor} bgcolor_offset={getOffsetColor(backgroundColor)}>
            <WildGoatsCenterContainer>
                <ContentBox>
                    <MainText style={{ paddingTop: 15, marginBottom: 10 }}>Pick your avatar </MainText>
                    <AvatarContainer>
                        {avatarData.map((avatar) => (
                            <ImageCircle key={avatar.name}>
                                <AvatarImage src={avatar.src} alt={avatar.name} />
                            </ImageCircle>
                        ))}
                    </AvatarContainer>
                    <MainText>Your name</MainText>
                    <InfoText>Please enter your name to get started.</InfoText>
                    <InputGroup size="sm" className={"margin-top-15"}>
                        <CustomInputBox
                            placeholder={"Enter your name here..."}
                            value={name}
                            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => setName(target.value)}
                        />
                    </InputGroup>
                    <CircleButtonBig onClick={checkTeamNameAlreadyPresent}>
                        <Icon iconName={"ChevronRight"} style={{ fontSize: 20 }} />
                    </CircleButtonBig>
                </ContentBox>
            </WildGoatsCenterContainer>
        </WildGoatsContainer>
    );
};

export default PickAvatar;

import React from "react";
import ListSearch, { IListSearchProps } from "./ListSearch";
import { SortingDetailsList, ISortingDetailsListProps } from "../Base/SortingDetailList";
import { TProps } from "../Base/Base";
import { Label, ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType } from "@fluentui/react";
import { SortingDetailsListDnD, ISortingDetailsDnDListProps } from "../Base/DragAndDropSortingDetailList";

type FixedHeaderNativeProps = {
    label?: string;
    isListDraggable?: boolean;
    hideSearchSection?: boolean;
};

type FixedHeaderSortingDetailsListProps<T> = IListSearchProps &
    ISortingDetailsListProps<T> &
    ISortingDetailsDnDListProps<T> &
    FixedHeaderNativeProps;

const FixedHeaderSortingDetailsList = <T extends TProps>(props: FixedHeaderSortingDetailsListProps<T>) => {
    return (
        <ScrollablePane
            className={"position-relative height-100per width-100per"}
            styles={{ contentContainer: { overflowX: "hidden" } }}
            scrollbarVisibility={ScrollbarVisibility.auto}>
            <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced={true}
                stickyBackgroundColor="transparent">
                {props.label && (
                    <Label className={"section-background padding-left-0 padding-top-0"}>{props.label}</Label>
                )}
                {!props.hideSearchSection && <ListSearch {...props} />}
            </Sticky>
                          
            {props.isListDraggable ? <SortingDetailsListDnD {...props} /> : <SortingDetailsList {...props} />}
        </ScrollablePane>
    );
};

export default FixedHeaderSortingDetailsList;

import axios from "axios";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { extractHtmlContent } from "../../../../utils/JambarUtils";
import { Client } from "../../../Base/Client";
import { GameQuestionListItem } from "../gamelibrary/updategame/Type";
import { BranchInfoType, BranchQuestionListType, BranchQuizType, TriggersType } from "./Type";

const transformData = (data: any): BranchQuestionListType[] => {
    if (!data) {
        return [];
    }
    return data.quiz_questions.map((quizQuestion: any) => ({
        questionTitle: quizQuestion.question.title,
        questionId: quizQuestion.question.id
    })) as BranchQuestionListType[];
};

export const getQuestionListFromAllQuiz = (quizId: string[]) => {
    const results: BranchQuestionListType[] = [];
    return quizId.map((id) => ApiConstants.getQuizDetailApiUrl(parseInt(id)))
        .reduce((promiseChain, currentUrl) => {
            return promiseChain.then(() => {
                return axios.get(currentUrl).then(response => {
                    results.push(...transformData(response.data.data));
                });
            });
        }, Promise.resolve())
        .then(() => results.map((item, index: number) => ({
            ...item,
            id: index + 1
        })));
};

export const getQuizQuestionList = (quizId: string) => {
    return Client.getInstance()
        .getData(ApiConstants.getQuizDetailApiUrl(parseInt(quizId)))
        .then((res) =>
            Promise.resolve({
                quizList: transformData(res.data.data),
                quizName: res.data.data.title,
                quizType: res.data.data.type
            })
        );
};

export const filterQuizList = (quiz: BranchQuizType[], searchText: string) => {
    if (searchText === "") {
        return quiz;
    }
    return quiz.filter((ques) => ques.title.toLowerCase().includes(searchText.toLowerCase()));
};

export const filterQuestionList = (questionList: GameQuestionListItem[], searchText: string) => {
    if (searchText === "") {
        return questionList;
    }
    return questionList.filter((ques) => ques.questionTitle.toLowerCase().includes(searchText.toLowerCase()));
};

const tranformQuizData = (data: any): BranchQuizType[] => {
    if (!data) {
        return [];
    }

    return data.map((item: any, index: number) => ({
        title: extractHtmlContent(item.title),
        id: item.id,
        index: index + 1
    }));
};

export const getQuizListData = (quizType: string) =>
    Client.getInstance()
        .getData(ApiConstants.getQuizListApiUrl(quizType, "active"))
        .then((response: any) => Promise.resolve(tranformQuizData(response.data.data)))
        .catch((error: any) => Promise.reject(error));

export const addQuizBranch = (branchInfo: BranchInfoType, quizId: string) =>
    Client.getInstance().createData(ApiConstants.addQuizBranchApiUrl(quizId), {
        name: branchInfo.branchName,
        isInterruptible: branchInfo.interruptable,
        isResumable: branchInfo.resumable,
        questions: branchInfo.questionList.map((item) => item.questionId).join(";")
    }).then((res) => {
        return Promise.resolve(res.data.data.id);
    })
        .catch(() => Promise.reject());

export const updateQuizBranch = (branchInfo: BranchInfoType, quizId: string, branchId: string) =>
    Client.getInstance().updateData(ApiConstants.updateBranchApiUrl(quizId, branchId), {
        name: branchInfo.branchName,
        isInterruptible: branchInfo.interruptable,
        isResumable: branchInfo.resumable,
        questions: branchInfo.questionList.map((item) => item.questionId).join(";")
    }).then(() => Promise.resolve())
        .catch(() => Promise.reject());

const tarnsformBranch = (data: any): BranchInfoType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        branchName: item.name,
        gameTitle: item.name,
        interruptable: item.isInterruptible,
        questionList: [],
        resumable: item.isResumable
    }));
};

export const getAllBranchesOfQuiz = (quizId: string) =>
    Client.getInstance().getData(ApiConstants.getQuizBranchApiUrl(quizId))
        .then((res) => Promise.resolve(tarnsformBranch(res.data.data)))
        .catch((err) => Promise.reject(err));

export const getMultiBranchDiagram = (quizId: string) =>
    Client.getInstance().getData(ApiConstants.quizMultiBranchApiUrl(quizId), true)
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err));

export const createMultiBranchDiagram = (quizId: string, flowchart: string, questionIds: string) =>
    Client.getInstance().createData(ApiConstants.quizMultiBranchApiUrl(quizId), {
        uiLayout: flowchart,
        questionIds: questionIds
    })
        .then((res) => Promise.resolve(res.data.data.mainBranchId))
        .catch((err) => Promise.reject(err));

export const updateMultiBranchDiagram = (quizId: string, flowchart: string) =>
    Client.getInstance().updateData(ApiConstants.quizMultiBranchApiUrl(quizId), {
        uiLayout: flowchart
    })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err));

const reverseTransformData = (data: TriggersType) => {
    return {
        name: data.triggerName,
        keywordText: data.keyword,
        type: data.triggerType,
        targetBranchId: Number(data.targetBranch),
    };
};

export const addMultiBranchTrigger = (data: TriggersType, quizId: string, branchId: string) =>
    Client.getInstance()
        .createData(ApiConstants.createTriggerApiUrl(quizId, branchId),
            reverseTransformData(data)).then((res) => Promise.resolve(res)
            );

export const updateMultiBranchTrigger = (data: TriggersType, quizId: string, branchId: string) =>
    Client.getInstance()
        .updateData(ApiConstants.updateTriggerApiUrl(quizId, branchId, data.triggerId),
            reverseTransformData(data))
        .then((res) => Promise.resolve(res));

export const deleteMultiBranchTrigger = (triggerId: string, quizId: string, branchId: string) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteTriggerApiUrl(quizId, branchId, triggerId))
        .then((res) => Promise.resolve(res));

export const removeBranch = (quizId: string, branchId: string) =>
    Client.getInstance()
        .deleteData(ApiConstants.deleteBranchApiUrl(quizId, branchId))
        .then((res) => Promise.resolve(res));

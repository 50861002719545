import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { getPermissions } from "../../../../utils/PermissionUtils";
import { ReferenceListViewItem, ReferenceDeleteType, ReferenceCloneType, Info } from "./Types";
import { useAdminContext } from "../../context/AdminAuthContext";
import { getReferenceListViewData, onConfirmDelete, onConfirmReferenceClone } from "./Utils";
import {
    StyledActionColumn,
    StyledHeader,
    StyledColumn,
    StyledText
} from "../../../CommonComponent/v2/datagrid/DataGridStyle";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { Box, Tooltip } from "@mui/material";
import { getInitials } from "../../../../utils/JambarUtils";

export const useReferenceListManager = () => {
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "referenceList");
    const [refrencelist, setRefrencelist] = useState<ReferenceListViewItem[]>([]);
    const [cloneReferenceId, setCloneReferenceId] = useState<ReferenceCloneType | null>(null);
    const [selectedReferenceDeleteId, setSelectedReferenceDeleteId] = useState<ReferenceDeleteType | null>(null);

    useEffect(() => {
        fetchReferenceListData();
    }, []);

    const fetchReferenceListData = (): void => {
        setIsLoading(true);
        if (!(permission && permission.includes("fetch"))) {
            setRefrencelist([]);
            setIsLoading(false);
            return;
        }
        getReferenceListViewData(true)
            .then((res: any) => {
                setRefrencelist(res);
            })
            .catch(() => {
                setRefrencelist([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onReferenceDelete = () => {
        selectedReferenceDeleteId &&
            onConfirmDelete(selectedReferenceDeleteId.id)
                .then(() => {
                    setSelectedReferenceDeleteId(null);
                    fetchReferenceListData();
                })
                .catch((error) => {
                    setSelectedReferenceDeleteId(null);
                    toast.error(error, SiteConstants.deleteToastConfiguration);
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    const onReferenceClone = () => {
        cloneReferenceId &&
            onConfirmReferenceClone(cloneReferenceId.id)
                .then(() => {
                    setCloneReferenceId(null);
                    fetchReferenceListData();
                })
                .catch((error) => {
                    setCloneReferenceId(null);
                    toast.error(error, SiteConstants.deleteToastConfiguration);
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };
    const onReferenceCloneClose = () => {
        setCloneReferenceId(null);
    };
    const onReferenceDeleteClose = () => {
        setSelectedReferenceDeleteId(null);
    };

    return {
        searchText,
        refrencelist,
        isLoading,
        cloneReferenceId,
        selectedReferenceDeleteId,
        onReferenceDeleteClose,
        onReferenceCloneClose,
        onReferenceClone,
        onReferenceDelete,
        setSearchText,
        setCloneReferenceId,
        fetchReferenceListData,
        setSelectedReferenceDeleteId,
        permission
    };
};

export const useRefrenceListHook = () => {
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "referenceList");
    const refrenceListColumns = (
        handleCopyLinkClick: (info: Info) => void,
        handleDeleteClick: (info: Info) => void,
        handleEditClick: (id: number) => void
    ): GridColDef[] => [
        {
            field: "title",
            headerName: "Title",
            flex: 2,
            renderHeader: () => <StyledHeader label="Title" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.title} />
        },
        {
            field: "createdBy",
            flex: 1,
            renderHeader: () => <StyledHeader label="Created By" />,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.createdBy}>
                    <Box style={{ display: "flex", gap: 3 }}>
                        <StyledColumn width={"30px"} label={`${getInitials(params?.row?.createdBy || "")}`} />
                        <StyledColumn label={`- ${params.row.createdDate}`} fontWeight={340} />
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "lastUpdatedBy",
            flex: 1,
            renderHeader: () => <StyledHeader label="Updated By" />,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.lastUpdatedBy}>
                    <Box style={{ display: "flex", gap: 3 }}>
                        <StyledColumn width={"30px"} label={`${getInitials(params?.row?.lastUpdatedBy || "")}`} />
                        <StyledColumn label={`- ${params.row.lastUpdatedDate}`} fontWeight={340} />
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "Action",
            headerName: "Action",
            width: 150,
            renderHeader: () => <StyledHeader label="Action" />,

            renderCell: (params: GridCellParams) => (
                <StyledActionColumn
                    isDeleteAvailaible={{
                        isAvailable: !!(permission && permission.includes("delete")),
                        onClick: () =>
                            handleDeleteClick({
                                id: params.row.id,
                                name: params.row.title
                            })
                    }}
                    isEditAvailaible={{
                        isAvailable: !!(permission && permission.includes("update")),
                        onClick: () => {
                            handleEditClick(params.row.id);
                        }
                    }}
                    isCloneAvailaible={{
                        isAvailable: !!(permission && permission.includes("clone")),
                        onClick: () => {
                            handleCopyLinkClick({
                                id: params.row.id,
                                name: params.row.title
                            });
                        }
                    }}
                />
            )
        }
    ];

    return {
        refrenceListColumns
    };
};

import { toast } from "react-toastify";
import { object, string } from "yup";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { extractHtmlContent } from "../../../../utils/JambarUtils";
import { Client } from "../../../Base/Client";
import { UsageListType } from "../gamelibrary/Type";
import { TagListItem } from "./Type";

export const UpdateTagValidationSchema = object().shape({
    tagName: string().required("Please specify a tag name")
});

const transformData = (res: any): TagListItem[] => {
    if (!res) {
        return [];
    }
    return res.data.data.map((item: any) => ({
        id: item.id,
        tagName: item.value
    }));
};
export const getTagsList = (forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getTagsListApiUrl(), forceRefresh)
        .then((response: any) => Promise.resolve(transformData(response)))
        .catch((error: any) => Promise.reject(error));

const transformTagsUsageList = (data: any): UsageListType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        id: item.id,
        name: extractHtmlContent(item.title)
    }));
};

export const fetchTagsUsage = (forceRefresh = true, id: number) => {
    return Client.getInstance()
        .getData(ApiConstants.getTagsUsageApiUrl(id), forceRefresh)
        .then((response) => {
            return Promise.resolve(transformTagsUsageList(response.data.data.question));
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const tagDelete = (id: number) => {
    return Client.getInstance()
        .deleteData(ApiConstants.deleteTagApiUrl(id))
        .then(() => {
            return Promise.resolve();
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const updateTagDetail = (value: TagListItem) =>
    Client.getInstance()
        .updateData(ApiConstants.updateTagApiUrl(value.id), { value: value.tagName })
        .then(() => {
            return Promise.resolve();
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });

export const addTag = (value: TagListItem) =>
    Client.getInstance()
        .createData(ApiConstants.addTagApiUrl(), { value: value.tagName })
        .then(() => {
            return Promise.resolve();
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });

export const filteredTagList = (tagList: TagListItem[], searchText: string) => {
    if (searchText === "") {
        return tagList;
    }
    return tagList.filter((item) => item.tagName.toLowerCase().includes(searchText.toLowerCase()));
};

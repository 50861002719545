import { FormHelperText } from "@mui/material";
import { useField } from "formik";
import { FC, useMemo } from "react";
import Select from "react-select";
import { newSelectStyle } from "../../../Admin/EventManager/EventDetails/utils/helperFunctions";
import { AutocompleteDropdownType } from "./Type";

type FormikSelectProps = {
    name: string;
    options: AutocompleteDropdownType[];
    placeholder?: string;
};

const FormikSelectAutocomplete: FC<FormikSelectProps> = ({ name, options, placeholder }) => {
    const [_, { value, error }, { setValue }] = useField(name);

    const selectedOption = useMemo(() => options.filter((option) => option.value === value), [value, options]);

    const dropdownOptions = useMemo(() => options, [options]);

    return (
        <>
            <Select
                options={dropdownOptions}
                placeholder={placeholder}
                isClearable
                styles={newSelectStyle(!!error) as any}
                value={selectedOption}
                onChange={(opt: any) => {
                    setValue(opt.value);
                }}
            />
            {!!error && <FormHelperText style={{ color: "#d32f2f" }}>{error}</FormHelperText>}
        </>
    );
};

export default FormikSelectAutocomplete;

import { useState } from "react";
import { AddReferenceType } from "./Type";
import { useHistory } from "react-router";
import { addReferenceTabs, addNewReference } from "./Utils";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";

export const useAddReference = () => {
    const history = useHistory();
    const [selectedTab, setSelectedTab] = useState("detail");
    const [referenceDetails, setReferenceDetails] = useState<AddReferenceType | undefined>();

    const saveData = (values: AddReferenceType, setIsSubmitting: (value: boolean) => void) => {
        setIsSubmitting(true);
        addNewReference(values)
            .then(() => {
                toast.success("Reference added", SiteConstants.successToastConfiguration);
                history.push(`/admin/v2/referencelistmanager`);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => setIsSubmitting(false));
    };
    return {
        addReferenceTabs,
        selectedTab,
        referenceDetails,
        setSelectedTab,
        setReferenceDetails,
        saveData
    };
};
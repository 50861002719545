import { Grid } from "@mui/material";
import { Formik } from "formik";
import { FC } from "react";
import { useHistory } from "react-router";
import { useAddQuestionHook } from "./Hooks";
import { QuestionDetailType } from "./Type";
import { QuestionIntialValue, QuestionFormValidationSchema } from "./Util";
import QuestionForm from "./QuestionForm";

type QuestionAddUpdateFormProps = {
    questionDetails?: QuestionDetailType;
    gameId: string;
    questionId?: string;
    refreshList?: () => void;
    pageNo: string | number;
    handleClose?: () => void;
};

const QuestionAddUpdateForm: FC<QuestionAddUpdateFormProps> = ({
    questionDetails,
    gameId,
    questionId,
    refreshList,
    pageNo,
    handleClose
}) => {
    const { handleQuestionSubmit, removeCoverImage, isLoading, usageList } = useAddQuestionHook(
        questionId,
        refreshList,
        handleClose
    );

    const history = useHistory();

    return (
        <Grid container>
            <Formik
                initialValues={QuestionIntialValue(gameId, questionDetails)}
                enableReinitialize
                validationSchema={QuestionFormValidationSchema(gameId)}
                onSubmit={(values, { setSubmitting }) => {
                    handleQuestionSubmit(values, setSubmitting);
                }}>
                {() => (
                    <QuestionForm
                        gameId={gameId}
                        handleCancel={() => {
                            handleClose && handleClose();
                            history.push(`/admin/v2/questionlibrary?pageNo=${pageNo}`);
                        }}
                        isLoading={isLoading}
                        questionId={questionId}
                        removeCoverImage={removeCoverImage}
                        usageList={usageList}
                    />
                )}
            </Formik>
        </Grid>
    );
};

export default QuestionAddUpdateForm;

export const SportsHardQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "Looks like you don't watch sports at all!",
            imageSrc: "https://i.imgur.com/Aksk6Ik.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Good try! Better luck next time.",
            imageSrc: "https://i.imgur.com/Vp4fJQ6.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Awesome! You're a champion",
            imageSrc: "https://i.imgur.com/OmyDrVM.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "What type of event is the Royal Ascot?",
            questionImageSrc: "https://i.imgur.com/9XnDldS.jpg",
            answerDescription:
                "As the name suggests, this event is attended by many members of the British royal family, including Queen Elizabeth II, who is a fan of horse racing.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. chess",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. skydiving",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. horse racing",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. football",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "What sport is this?",
            questionImageSrc: "https://i.imgur.com/HX5FvzE.jpg",
            answerDescription: "In this game, the players can’t use oxygen tanks; they have to hold their breath.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. air hockey",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. scuba-ball",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. underwater polo",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. underwater hockey",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "What is this sport called?",
            questionImageSrc: "https://i.imgur.com/YTHEmRh.jpg",
            answerDescription: "This sport is similar to kiteboarding, which is done on a body of water.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. snowkiting",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Quidditch",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. alpine skiing",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. paraboarding",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "What team does Son Heung-min currently play for?",
            questionImageSrc: "https://i.imgur.com/hIpFKNU.jpg",
            answerDescription:
                "Son began playing for Tottenham in 2015 and is expected to continue with this team until 2025 (at least).",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Barcelona",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. New York Red Bulls",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Toronto FC",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Tottenham Hotspur",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "How many times was the Summer Olympics held in Greece?",
            questionImageSrc: "https://i.imgur.com/P8Pde5N.jpg",
            answerDescription:
                "In 1896, Greece hosted the first modern Olympic Games and did so again 108 years later in 2004.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 4",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 2",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. 7",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 8",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "How many points is the centre of the bullseye worth?",
            questionImageSrc: "https://i.imgur.com/5GHldg5.jpg",
            answerDescription:
                "The red area (inner circle) is worth 50 points, while the green area (outer circle) is worth 25 points.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 50",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. 25",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 60",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 100",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "Who did Ali fight in the “Rumble in the Jungle”?",
            questionImageSrc: "https://i.imgur.com/qpzRjlk.jpg",
            answerDescription:
                "At the time of the fight, George Foreman was the undisputed heavyweight champion, and seven years younger than Muhammad Ali.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Joe Frazier",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. George Foreman",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Mike Tyson",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Sonny Liston",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Bob and Mike Bryan are Olympic gold medallists in ______.",
            questionImageSrc: "https://i.imgur.com/apvbM9R.jpg",
            answerDescription: "Mike has a total of 18 Grand Slam titles while Bob has 16.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. diving",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. wrestling",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. tennis",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. basketball",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "Yukigassen, a snowball fighting tournament, originated in which country?",
            questionImageSrc: "https://i.imgur.com/2gb5Szs.jpg",
            answerDescription: "Each team has seven players and can use up to 90 snowballs.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Argentina",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Russia",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. England",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Japan",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "In Australian rules football, how many players make up a team?",
            questionImageSrc: "https://i.imgur.com/hrwOJpQ.jpg",
            answerDescription:
                "Although there are 22 players on each team, only 18 can be on the field at any time during play.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 22",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. 7",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 11",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 30",
                    isCorrect: false
                }
            ]
        }
    ]
};

/* eslint-disable jsx-a11y/alt-text */
import { Grid } from "@mui/material";
import { useHistory } from "react-router";
import { Header1 } from "../../../../CommonComponent/v2/CommonStyle";
import LoadingWrapper from "../../../../CommonComponent/v2/LoadingWrapper";
import ArrowLeft from "../../../../Images/icons/arrowLeft.svg";
import GameForm from "./GameForm";
import { useGameLibraryHook } from "./Hooks";

const UpdateGame = () => {
    const history = useHistory();

    const { gameId, gameType, gameDetails, tagOptions, updateQuiz } = useGameLibraryHook();

    return (
        <Grid px={"20px"}>
            <Grid container>
                <Grid item xs={12}>
                    <Header1>
                        <img
                            onClick={() =>
                                gameId
                                    ? history.push(`/admin/v2/gamelibrary/${gameId}`, {
                                          gameType: gameType
                                      })
                                    : history.push("/admin/v2/gamelibrary")
                            }
                            src={ArrowLeft}
                            style={{ height: 18, marginRight: 10, cursor: "pointer" }}
                        />
                        {gameId ? "Edit game" : "Add new game"}
                    </Header1>
                </Grid>
                <Grid container>
                    <LoadingWrapper isLoading={gameId ? !gameDetails : false}>
                        <GameForm
                            gameDetails={gameDetails}
                            gameType={gameType}
                            tagOptions={tagOptions}
                            updateQuiz={updateQuiz}
                            gameId={gameId || ""}
                        />
                    </LoadingWrapper>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UpdateGame;

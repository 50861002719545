import Styled from "@emotion/styled";
import { Grid, TextField, Typography } from "@mui/material";

export const DashboardContainer = Styled(Grid)`
    background: transparent linear-gradient(180deg, #31A461 0%, #023047 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    padding: 30px 40px;
`;

export const EventTitle = Styled(Typography)`
    font: normal normal bold 20px/27px Nunito;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
`;

export const EventContainer = Styled(Grid)`
    background: #EFEFEF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    opacity: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const FeedbackContainer = Styled(Grid)`
    background: #EFEFEF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    opacity: 1;
    padding: 20px;
`;

export const EventList = Styled(Grid)`
    border: 1px dashed #00000029;
`;

export const FeedbackTextField = Styled(TextField)`
    fieldset {
        border: none;
    }
`;

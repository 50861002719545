import { DialogFooter } from "@fluentui/react";
import { Button, Dialog } from "@mui/material";
import { FC, useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { CropStyleContainer } from "./Style";

type CropImageModalProps = {
    handleClose: () => void;
    imgSrc: string;
    storeImage: (areaPixels: { width: number; height: number; x: number; y: number }) => void;
};
const CropImageModal: FC<CropImageModalProps> = ({ handleClose, imgSrc, storeImage }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
        width: number;
        height: number;
        x: number;
        y: number;
    } | null>(null);

    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    return (
        <Dialog style={{ zIndex: 1000000 }} fullWidth maxWidth={"md"} open onClose={handleClose}>
            <div style={{ padding: 40 }}>
                <CropStyleContainer>
                    <Cropper
                        image={imgSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        cropShape="round"
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </CropStyleContainer>
                <DialogFooter>
                    <Button variant={"contained"} onClick={() => croppedAreaPixels && storeImage(croppedAreaPixels)}>
                        Save
                    </Button>
                </DialogFooter>
            </div>
        </Dialog>
    );
};

export default CropImageModal;

import { FC } from "react";
import { BranchQuestionListType } from "./Type";
import { Grid, IconButton } from "@mui/material";
import { SubText } from "../../../CommonComponent/v2/CommonStyle";
import { extractHtmlContent } from "../../../../utils/JambarUtils";
import { DeleteIcon } from "../../../CommonComponent/v2/datagrid/Style";

type ImportQuestionListProps = {
    questionList: BranchQuestionListType[];
    handleQuestionRemove: (questionId: number) => void;
};

const ImportQuestionList: FC<ImportQuestionListProps> = ({
    questionList,
    handleQuestionRemove
}) => {

    return (
        <Grid container pb={"30px"} borderRadius={"10px"} bgcolor={"#efefef"}>
            <Grid p={"20px"} borderBottom={"1px #efefef98 solid"} item xs={12}>
                <Grid container>
                    <Grid item xs={1}>
                        <SubText style={{ fontWeight: 700 }}>#</SubText>
                    </Grid>
                    <Grid item xs={10}>
                        <SubText style={{ fontWeight: 700 }}>Challenge title</SubText>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
            </Grid>
            {questionList.map((item, index) => (
                <Grid px={"20px"} py={"5px"} item xs={12} key={item.id}>
                    <Grid container alignItems={"centers"}>
                        <Grid item xs={1}>
                            <SubText>{index + 1}</SubText>
                        </Grid>
                        <Grid item xs={10}>
                            <SubText>{extractHtmlContent(item.questionTitle)}</SubText>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={() => handleQuestionRemove(item.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

export default ImportQuestionList;

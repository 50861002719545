import { PrimaryButton, SpinnerSize, Stack, Spinner, Label, IconButton, Text } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import TextEditor from "../../../CommonComponent/TextEditor";
import { ADVANCE_TEXT_EDITOR_CLASS, SCORE_PENALTY, TIME_PENALTY } from "../../../../data/question/types";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { Client } from "../../../Base/Client";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { SiteConstants } from "../../../../constants/SiteConstant";
import TextFieldWrapper from "../../../CommonComponent/TextFieldWrapper";
import { Form } from "react-bootstrap";

type QuestionHintDetailsViewProps = {
    hintId: number;
    closeModalAndRefreshList: () => void;
    questionId: number;
    closeModal: () => void;
    hintTextProp: string;
    scorePenaltyProp: string;
    timePenaltyProp: string;
};

const QuestionHintDetailsView: React.FC<QuestionHintDetailsViewProps> = ({
    hintId,
    closeModal,
    questionId,
    closeModalAndRefreshList,
    hintTextProp,
    scorePenaltyProp,
    timePenaltyProp
}) => {
    const [hintText, setHintText] = useState("");
    const [penaltyType, setPenaltyType] = useState(SCORE_PENALTY);
    const [scorePenalty, setScorePenalty] = useState("");
    const [timePenalty, setTimePenalty] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showValidationErrorMessage, setShowValidationErrorMessage] = useState(false);

    useEffect(() => {
        if (hintId !== -1) {
            setHintText(hintTextProp);
            setScorePenalty(scorePenaltyProp);
            setTimePenalty(timePenaltyProp);
            setPenaltyType(scorePenaltyProp ? SCORE_PENALTY : TIME_PENALTY);
        }
    }, []);

    const onSaveHint = (): void => {
        if (penaltyType === SCORE_PENALTY && scorePenalty === "") {
            setShowValidationErrorMessage(true);
            return;
        }
        if (penaltyType === TIME_PENALTY && timePenalty === "") {
            setShowValidationErrorMessage(true);
            return;
        }
        if (FieldValidation.quillTextEditorValidation(hintText)) {
            setShowValidationErrorMessage(true);
            return;
        }
        setShowValidationErrorMessage(false);
        setIsLoading(true);
        if (hintId !== -1) {
            Client.getInstance()
                .updateData(ApiConstants.updateQuestionHintApiUrl(questionId, hintId), {
                    description: hintText,
                    scorePenalty: scorePenalty,
                    timePenalty: timePenalty
                })
                .then(() => {
                    setIsLoading(false);
                    closeModalAndRefreshList();
                })
                .catch(() => {
                    setIsLoading(false);
                });
            return;
        }
        Client.getInstance()
            .createData(ApiConstants.addQuestionHintApiUrl(questionId), {
                description: hintText,
                scorePenalty: scorePenalty,
                timePenalty: timePenalty
            })
            .then(() => {
                setIsLoading(false);
                closeModalAndRefreshList();
            })
            .catch(() => {
                setIsLoading(false);
            });
    };
    return (
        <Stack className={"padding-10"}>
            <Stack.Item className={"margin-bottom-10"}>
                <Stack horizontal>
                    <Stack.Item grow>
                        <Text variant={"xLarge"}>{hintId !== -1 ? "Edit Hint" : "Add Hint"}</Text>
                    </Stack.Item>
                    <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        styles={SiteConstants.closeIconColor}
                        onClick={closeModal}
                    />
                </Stack>
            </Stack.Item>
            <Stack tokens={ThemedMediumStackTokens}>
                <Stack.Item
                    className={"section-background section-border padding-10 overflowy-auto"}
                    tokens={ThemedMediumStackTokens}>
                    <Stack.Item>
                        <Label>Hint penalty type</Label>
                    </Stack.Item>
                    <Stack horizontal tokens={ThemedMediumStackTokens}>
                        <Stack.Item>
                            <Form.Check
                                type="radio"
                                label="Score penalty"
                                name="penaltyType"
                                value={SCORE_PENALTY}
                                checked={penaltyType === SCORE_PENALTY}
                                onChange={() => {
                                    setPenaltyType(SCORE_PENALTY);
                                    setTimePenalty("");
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Form.Check
                                className={"margin-left-20"}
                                type="radio"
                                label="Time penalty"
                                name="penaltyType"
                                value={TIME_PENALTY}
                                checked={penaltyType === TIME_PENALTY}
                                onChange={() => {
                                    setPenaltyType(TIME_PENALTY);
                                    setScorePenalty("");
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal>
                        <Stack.Item>
                            <TextFieldWrapper
                                value={scorePenalty}
                                disabled={penaltyType !== SCORE_PENALTY}
                                label={"Score penalty"}
                                errorMessage={
                                    penaltyType === SCORE_PENALTY
                                        ? scorePenalty === ""
                                            ? "Score penalty is mandatory"
                                            : FieldValidation.floatValidation(scorePenalty)
                                            ? ""
                                            : "Please enter valid number"
                                        : ""
                                }
                                onTextFieldValueChange={(score: string) => setScorePenalty(score)}
                            />
                        </Stack.Item>
                        <Stack.Item className={"margin-left-20"}>
                            <TextFieldWrapper
                                value={timePenalty}
                                disabled={penaltyType !== TIME_PENALTY}
                                label={"Time penalty"}
                                errorMessage={
                                    penaltyType === TIME_PENALTY
                                        ? timePenalty === ""
                                            ? "Time penalty is mandatory"
                                            : FieldValidation.floatValidation(timePenalty)
                                            ? ""
                                            : "Please enter valid number"
                                        : ""
                                }
                                onTextFieldValueChange={(value: string) => setTimePenalty(value)}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack.Item>
                        <Stack.Item>
                            <Label>Hint text</Label>
                        </Stack.Item>
                        <Stack.Item>
                            <TextEditor
                                className={`${ADVANCE_TEXT_EDITOR_CLASS} hint-text quill-blue-background`}
                                value={hintText}
                                onChange={(text: string) => setHintText(text)}
                                basicControl={true}
                                showFontSizeControl={true}
                            />
                        </Stack.Item>
                        {FieldValidation.quillTextEditorValidation(hintText) ? (
                            <Stack.Item>
                                <span className={"error-message"}>Hint text is mandatory</span>
                            </Stack.Item>
                        ) : null}
                    </Stack.Item>
                </Stack.Item>
                <Stack horizontalAlign={"center"}>
                    {isLoading ? (
                        <Spinner size={SpinnerSize.large} />
                    ) : (
                        <PrimaryButton iconProps={{ iconName: "Save" }} text={"Save"} onClick={onSaveHint} />
                    )}
                </Stack>
                <Stack horizontal horizontalAlign={"center"} className={"margin-bottom-15"}>
                    {showValidationErrorMessage && (
                        <span className={"error-message"}>Please fill in all the mandatory fields</span>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default QuestionHintDetailsView;

export const MovieHardQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "You don't know much about movies.",
            imageSrc: "https://i.imgur.com/xuYg8m9.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Pretty good, you have some knowledge of movies.",
            imageSrc: "https://i.imgur.com/tkkmFzG.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Awesome! You're a couch potato",
            imageSrc: "https://i.imgur.com/yzTWkdB.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "Which film is this? ",
            questionImageSrc: "https://i.imgur.com/2PeGw9a.jpg",
            answerDescription:
                "The character shown here is Freddy Krueger, one of the most iconic horror movie characters in film history.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. A Nightmare on Elm Street",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. The Gremlins",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Friday the 13th",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Halloween",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "In which movie did this actor have his first role?",
            questionImageSrc: "https://i.imgur.com/wx2kP0t.jpg",
            answerDescription:
                "This actor, Dwayne Johnson, played the character 'The Scorpion King 'in this film, then a year later reprised his role in the movie ‘The Scorpion King’.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. The Mummy Returns",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. The Crow",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Walking Tall",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. The Scorpion King",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "The costliest film ever made is part of which franchise?",
            questionImageSrc: "https://i.imgur.com/luAnGJ4.jpg",
            answerDescription:
                "The fourth film in this series, “On Stranger Tides” cost at least $410.6 million to make.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. The Avengers",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Fast & Furious",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Pirates of the Caribbean",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Toy Story",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "Name the highest-grossing film at the global box office.",
            questionImageSrc: "https://i.imgur.com/IEiH8yn.jpg",
            answerDescription:
                "For a short period, Avengers: Infinity War was the highest-grossing film at the global box office until Avatar reclaimed the top spot after it was re-released in China.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Avengers: Age of Ultron",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. The Sound of Music",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Star Wars",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Avatar",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Which movie is this?",
            questionImageSrc: "https://i.imgur.com/L2vZ0Bd.jpg",
            answerDescription:
                "The film is apparently named after the flavour of ice cream Riley’s daughter was eating at the carnival.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Tomb Raider",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Peppermint",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Charlie’s Angels",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Suicide Squad",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "Which of these is the highest-grossing animated film franchise?",
            questionImageSrc: "https://i.imgur.com/PPFxbvM.jpg",
            answerDescription:
                "Unless you’ve been living under a rock, you would have at some point come across those little yellow minions in the media. ",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Shrek",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Cars",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Despicable Me",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Madagascar",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "Which of these actors was not in Speed 2?",
            questionImageSrc: "https://i.imgur.com/ZlLpLOf.jpg",
            answerDescription:
                "Keanu starred in the first movie in this series, ‘Speed’, but turned down the offer to star in the subsequent film.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Keanu Reeves",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Sandra Bullock",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Willem Dafoe",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Jason Patric",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Johnny Depp’s first movie role was in what film?",
            questionImageSrc: "https://i.imgur.com/wGXrAUU.jpg",
            answerDescription: "Did you know that Johnny Depp was 21 years old when he played ‘Glen’ in this movie?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Charlie and the Chocolate Factory",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Pirates of the Caribbean",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. A Nightmare on Elm Street ",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Edward Scissorhands",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "Name Clint Eastwood’s character in Gran Torino.",
            questionImageSrc: "https://i.imgur.com/JlqBclI.jpg",
            answerDescription: "Did you know that Gran Torino is the 30th film Clint Eastwood has directed?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Blondie",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Walt",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Angel Eyes",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Matthew",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "Which movie’s post-credit scene shows Goose regurgitating the Cube?",
            questionImageSrc: "https://i.imgur.com/Ff6pG2Q.jpg",
            answerDescription:
                "In this film, Goose joins Captain Marvel and Nick Fury on a quest to find the lab of Goose’s deceased owner. ",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Spider-Man: No Way Home",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Black Panther",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Avengers: Infinity War",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Captain Marvel",
                    isCorrect: true
                }
            ]
        }
    ]
};

import { useField } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CancelButton, CaptionText } from "../CommonStyle";
import { FileUploadContainer, LogoContainer } from "./Style";
import AddIcon from "../../../Images/icons/addfile.svg";
import { dangerColor } from "../../../../constants/ColorConstant";
import { Grid } from "@mui/material";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig } from "../../../../utils/JambarUtils";

type FileDropProps = {
    selectedFile: File | null | string;
    fileSize: number;
    name: string;
    logoInfo: string;
    validFilesList: string[];
    errorMessage: string;
};
const FileDrop: FC<FileDropProps> = ({ selectedFile, fileSize, name, validFilesList, logoInfo, errorMessage }) => {
    // eslint-disable-next-line no-empty-pattern
    const [_, { error }, { setError, setValue }] = useField(name);

    const [attachmentSrc, setAttachmentSrc] = useState<string>("");

    useEffect(() => {
        if (typeof selectedFile === "string") {
            setAttachmentSrc(selectedFile);
            return;
        }
        selectedFile && setAttachmentSrc(URL.createObjectURL(selectedFile as File));
    }, [selectedFile]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles[0].id = Math.random();
            const file = acceptedFiles[0];
            if (setError && !checkIfFilesAreCorrectType(acceptedFiles, validFilesList)) {
                setError(errorMessage);
                return;
            }

            if (fileSize && !checkIfFilesAreTooBig(acceptedFiles, fileSize)) {
                setError(`Attachment must be less then ${fileSize} MB`);
                return;
            }

            setValue(file);
        },
        [fileSize, setError]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <Grid container spacing={"20px"}>
            {attachmentSrc ? (
                <Grid item xs={12}>
                    <Grid container justifyContent={"flex-end"}>
                        <CancelButton
                            onClick={() => {
                                setValue(null);
                                setAttachmentSrc("");
                            }}
                        >
                            Remove
                        </CancelButton>
                        <iframe height={"500px"} width="100%" src={attachmentSrc}></iframe>
                    </Grid>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    {isDragActive ? (
                        <LogoContainer variant={"secondary"} {...getRootProps({ className: "dropzone height-100per" })}>
                            <input {...getInputProps()} />
                            <FileUploadContainer style={{ padding: 20 }}>
                                <img src={AddIcon} style={{ height: 28 }} />
                                <CaptionText color={"#BDBDBD"} textAlign={"center"}>
                                    {logoInfo}
                                </CaptionText>
                            </FileUploadContainer>
                        </LogoContainer>
                    ) : (
                        <LogoContainer variant={"secondary"} {...getRootProps({ className: "dropzone height-100per" })}>
                            <input {...getInputProps()} />
                            <FileUploadContainer style={{ padding: 20 }}>
                                <img src={AddIcon} style={{ height: 28 }} />
                                <CaptionText color={"#BDBDBD"} textAlign={"center"}>
                                    {logoInfo}
                                </CaptionText>
                            </FileUploadContainer>
                        </LogoContainer>
                    )}
                    {error && <CaptionText color={dangerColor}>{error}</CaptionText>}
                </Grid>
            )}
        </Grid>
    );
};

export default FileDrop;

import { Grid, Switch, SwitchProps } from "@mui/material";
import { useField } from "formik";
import { FC } from "react";
import { darkPrimaryColor } from "../../../../constants/ColorConstant";
import { CaptionText } from "../CommonStyle";

type FormikToggleProps = {
    id: string;
    name: string;
    label: string;
    textfieldType?: "textField" | "password";
} & SwitchProps;

const FormikToggle: FC<FormikToggleProps> = ({ id, name, label, ...rest }) => {
    const [field, { value }] = useField(name);

    return (
        <Grid>
            <CaptionText color={darkPrimaryColor} fontWeight={700}>
                {label}
            </CaptionText>
            <Switch checked={value} id={id} {...field} {...rest} />
        </Grid>
    );
};

export default FormikToggle;

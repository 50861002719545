import { SearchOutlined } from "@mui/icons-material";
import { Dialog, Grid, InputAdornment } from "@mui/material";
import { FC, useState } from "react";
import { CaptionText, Header1 } from "../../../../CommonComponent/v2/CommonStyle";
import { StyledTextField } from "../../../../CommonComponent/v2/searchheader/Style";
import { SelectUserDeleteType } from "../Type";
import TransferOwnerDataGrid from "./TransferOwnerDataGrid";

type TransferOwnerShipProps = {
    handleClose: () => void;
    selectedUserInfo: SelectUserDeleteType;
    saveTransferOwnerShip: (transferId: number) => void;
};
const TransferOwnerShip: FC<TransferOwnerShipProps> = ({ handleClose, selectedUserInfo, saveTransferOwnerShip }) => {
    const [searchText, setSearchText] = useState("");

    return (
        <Dialog open maxWidth={"lg"} fullWidth onClose={handleClose}>
            <Grid padding={"60px"}>
                <Grid justifyContent={"center"} alignItems={"center"} container direction={"column"}>
                    <Grid item>
                        <Header1 textAlign={"center"}>Transfer ownership of</Header1>
                        <Header1 textAlign={"center"}>{selectedUserInfo.email}</Header1>
                    </Grid>
                    <Grid item width={"70%"} mt={"40px"}>
                        <CaptionText textAlign={"center"}>
                            Users cannot be deleted as their data is linked with the whole system rather we can just
                            transfer the ownership to another user. Please select a user to transfer the ownership to.
                        </CaptionText>
                    </Grid>
                </Grid>
                <Grid container mt={"40px"} justifyContent={"center"}>
                    <Grid item xs={8}>
                        <StyledTextField
                            InputLabelProps={{ shrink: true }}
                            placeholder="Search a user by name or email"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined />
                                    </InputAdornment>
                                )
                            }}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid mt={"50px"}>
                    <TransferOwnerDataGrid
                        closeModal={handleClose}
                        selectedUserInfo={selectedUserInfo}
                        searchText={searchText}
                        saveTransferOwner={saveTransferOwnerShip}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default TransferOwnerShip;

import { Grid } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { CaptionText } from './CommonStyle';
import { PasswordValidateType } from './Type';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { errorLightColor, sucessLightColor } from '../../../constants/ColorConstant';

type Props = {
    value: string;
}
const PasswordValidator: FC<Props> = ({
    value
}) => {
    const [passwordValidation, setPasswordValidation] = useState<PasswordValidateType>({
        uppercase: false,
        lowercase: false,
        number: false,
        length: false,
        specialCharacters: false,
    });

    useEffect(() => {
        handlePasswordCheck(value);
    }, [value]);

    const handlePasswordCheck = (text: string) => {
        if (text === "") {
            setPasswordValidation({
                uppercase: false,
                lowercase: false,
                number: false,
                length: false,
                specialCharacters: false,
            });
        }
        if (text.length > 8 && text.length < 64) {
            setPasswordValidation((prev) => ({
                ...prev,
                length: true
            }));
        }
        if (/[A-Z]/.test(text)) {
            setPasswordValidation((prev) => ({
                ...prev,
                uppercase: true
            }));
        }
        if (/[a-z]/.test(text)) {
            setPasswordValidation((prev) => ({
                ...prev,
                lowercase: true
            }));
        }
        if (/[0-9]/.test(text)) {
            setPasswordValidation((prev) => ({
                ...prev,
                number: true
            }));
        }
        if (/[!@#$%^&*]/.test(text)) {
            setPasswordValidation((prev) => ({
                ...prev,
                specialCharacters: true
            }));
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <CaptionText>
                    {
                        passwordValidation.length ? <CheckCircleIcon
                            style={{
                                color: sucessLightColor
                            }} /> : <CancelIcon style={{
                                color: errorLightColor
                            }} />
                    }{" "}
                    Length: 8 or more characters
                </CaptionText>
                <CaptionText>
                    {
                        passwordValidation.uppercase ? <CheckCircleIcon style={{
                            color: sucessLightColor
                        }} /> : <CancelIcon style={{
                            color: errorLightColor
                        }} />
                    }{" "}
                    Uppercase: At least one uppercase character
                </CaptionText>
                <CaptionText>
                    {
                        passwordValidation.lowercase ? <CheckCircleIcon style={{
                            color: sucessLightColor
                        }} /> : <CancelIcon style={{
                            color: errorLightColor
                        }} />
                    }{" "}
                    Lowercase: At least one lowercase character
                </CaptionText>
                <CaptionText>
                    {
                        passwordValidation.number ? <CheckCircleIcon style={{
                            color: sucessLightColor
                        }} /> : <CancelIcon style={{
                            color: errorLightColor
                        }} />
                    }{" "}
                    Number: At least one number
                </CaptionText>
                <CaptionText>
                    {
                        passwordValidation.specialCharacters ? <CheckCircleIcon style={{
                            color: sucessLightColor
                        }} /> : <CancelIcon style={{
                            color: errorLightColor
                        }} />
                    }{" "}
                    Special Character: At least one special character
                </CaptionText>
            </Grid>
        </Grid>
    );
};

export default PasswordValidator;

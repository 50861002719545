import { Box, Grid } from "@mui/material";
import { FC } from "react";
import DataGridWrapper from "../../../../../CommonComponent/v2/datagrid/DataGrid";
import LoadingWrapper from "../../../../../CommonComponent/v2/LoadingWrapper";
import { useDownloadFile } from "./Hooks";
import { IconButton } from "@mui/material";
import { Refresh } from "@mui/icons-material";

type DownloadFileProps = {
    eventId?: string;
};
const DownloadFile: FC<DownloadFileProps> = ({ eventId }) => {
    const { downloadFileList, isLoading, eventFileColumns, fetchFile } = useDownloadFile(eventId);

    return (
        <Grid container height={"calc(100vh - 300px)"}>
            <Grid item xs={12}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <IconButton onClick={fetchFile}>
                        <Refresh />
                    </IconButton>
                </Box>
                <LoadingWrapper
                    isLoading={isLoading}
                    loadingHeight="400px"
                    isNoItem={downloadFileList.length === 0}
                    emptyTileLabel={`No download found`}>
                    <DataGridWrapper columns={eventFileColumns()} rows={downloadFileList as any} loading={isLoading} />
                </LoadingWrapper>
            </Grid>
        </Grid>
    );
};

export default DownloadFile;

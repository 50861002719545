import { Button } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import Style from "@emotion/styled";
import { linkColor } from "../../../../constants/Styles";

export const BrowserCheckParent = Style(Row)`
    text-align: center;
    background: #ffff;
    box-shadow: 1px 1px 10px black;
    margin-top: 50px;
    padding-top: 30px;
    width: 80%;
    border-radius: 10px;
    padding-bottom: 30px;
`;

export const BrowserCheckColumn = Style(Col)`
    margin-top: 5px;
    display: flex;
    justify-content: center;
`;

export const BrowserCheckLogos = Style(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    &:hover{
        span{
            text-transform: none;
            text-decoration: underline;
            color: ${linkColor};   
        }
    }
`;

export const ContinueLink = Style(Button)`
    span {
        text-transform: none;
        text-decoration: underline;
        color: ${linkColor};
    }
`;

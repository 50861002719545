import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { darkPrimaryColor } from "../../../../../constants/ColorConstant";
import { QuestionTypeOptions } from "../../../../../constants/DropdownOption";
import { IMAGE_QUESTION_TYPE, SiteConstants } from "../../../../../constants/SiteConstant";
import { CaptionText, Header2 } from "../../../../CommonComponent/v2/CommonStyle";
import FormikSelect from "../../../../CommonComponent/v2/fields/FormikSelect";
import FormikTextEditor from "../../../../CommonComponent/v2/fields/FormikTextEditor";
import ImageQuestion from "./questiondetailquestion/ImageQuestion";
import VideoQuestion from "./questiondetailquestion/VideoQuestion";
import { QuestionDetailType } from "./Type";
import { FC } from "react";

type QuestionDetailsFormProps = {
    removeCoverImage: () => void;
};
const QuestionDetailsForm: FC<QuestionDetailsFormProps> = ({ removeCoverImage }) => {
    const { values } = useFormikContext();
    const data = values as QuestionDetailType;

    const renderQuestionType = () => {
        if (data.questionDetails.questionType === IMAGE_QUESTION_TYPE) {
            return <ImageQuestion removeCoverImage={removeCoverImage} />;
        }
        return <VideoQuestion />;
    };

    return (
        <Grid container mt={"20px"}>
            <Grid item xs={12}>
                <Header2>Challenge details</Header2>
                <CaptionText mt={"5px"}>
                    This is what the player would see on the screen as a challenge of the game.
                </CaptionText>
            </Grid>
            <Grid item xs={12} mt={"40px"}>
                <CaptionText mb={"10px"} color={darkPrimaryColor} fontWeight={700}>
                    Name (in game)
                </CaptionText>
                <FormikTextEditor name={"questionDetails.topQuestionText"} placeholder={"Enter Name here..."} />
            </Grid>
            <Grid item xs={12} mt={"60px"}>
                <FormikSelect
                    name={"questionDetails.questionType"}
                    id={"questionDetails.questionType"}
                    options={QuestionTypeOptions}
                    label={"Media type"}
                />
            </Grid>

            <Grid item xs={12}>
                {renderQuestionType()}
            </Grid>
            <Grid item xs={12}>
                <CaptionText mt={"90px"} color={darkPrimaryColor} fontWeight={700}>
                    Challenge text
                </CaptionText>
                <FormikTextEditor
                    name={"questionDetails.bottomQuestionText"}
                    placeholder={"Enter challenge text here..."}
                />
            </Grid>
            <Grid item xs={12} mt={"20px"}>
                <Grid container>
                    {data.questionIdentification.gameType !== SiteConstants.wildgoat && (
                        <>
                            <Grid item xs={12} mt={"90px"}>
                                <CaptionText mb={"10px"} color={darkPrimaryColor} fontWeight={700}>
                                    Reference item text 1
                                </CaptionText>
                                <FormikTextEditor
                                    basicControl
                                    name={"questionDetails.referenceItemText1"}
                                    placeholder={"Enter reference text here..."}
                                />
                            </Grid>
                            <Grid item xs={12} mt={"90px"}>
                                <CaptionText mb={"10px"} color={darkPrimaryColor} fontWeight={700}>
                                    Reference item text 2
                                </CaptionText>
                                <FormikTextEditor
                                    basicControl
                                    name={"questionDetails.referenceItemText2"}
                                    placeholder={"Enter reference text here..."}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default QuestionDetailsForm;

import { Modal } from "office-ui-fabric-react";
import React from "react";

const NoInternetConnectionModal: React.FC = () => {
    return (
        <Modal isOpen={true} className={"no-internet-modal"}>
            <div className={"no-internet"}>
                <h1>Internet connection lost</h1>
            </div>
        </Modal>
    );
};

export default NoInternetConnectionModal;

export const SquidGameQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "You don't know much about Squid Game.",
            imageSrc: "https://i.imgur.com/Yqi8W4p.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Good try! Better luck next time.",
            imageSrc: "https://i.imgur.com/UTsbbLJ.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Congrats! You won the game.",
            imageSrc: "https://i.imgur.com/az0OUFZ.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "Name the first game the players compete in.",
            questionImageSrc: "https://i.imgur.com/ADzdRUp.jpg",
            answerDescription: "This popular children’s game appears in the first episode of the series.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. tag",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Simon says",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. red light, green light",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. dodgeball",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "What number does Seong Gi-hun have?",
            questionImageSrc: "https://i.imgur.com/IDaVftx.jpg",
            answerDescription: "The main character, Seong Gi-hun is played by Lee Jung-jae.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 001",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 456",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. 232",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 450",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Which player has a brain tumour?",
            questionImageSrc: "https://i.imgur.com/AjY5l2A.jpg",
            answerDescription:
                "Player 001 is an old man who sometimes forgets simple things like his name, due to his illness.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 001",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. 067",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 199",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 112",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "What shape candy does this character get?",
            questionImageSrc: "https://i.imgur.com/gziqNNB.jpg",
            answerDescription:
                "Han Mi-nyeo, also called ‘Player 212’, carves out the star shape with the help of a lighter.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. triangle",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. circle",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. umbrella",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. star",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "In 2021, Squid Game became the most-watched series on ________.",
            questionImageSrc: "https://i.imgur.com/FGjNI2G.jpg",
            answerDescription: "Did you know that the creator of this successful TV show wrote the series in 2009?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. HBO Max",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Amazon Prime Video",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Netflix",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Hulu",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "What is Cho Sang-Woo’s number?",
            questionImageSrc: "https://i.imgur.com/BIkMu20.jpg",
            answerDescription:
                "This character enters the games with the hopes of winning the prize to pay off his huge debts.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 218",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. 067",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 230",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 037",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "What is this actress’ name?",
            questionImageSrc: "https://i.imgur.com/lo3oFUG.jpg",
            answerDescription: "Did you know HoYeon Jung is also a model?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. HoYeon Jung",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Gemma Chan",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Lucy Lui",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Constance Wu",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "When a player dies, what happens to the prize money?",
            questionImageSrc: "https://i.imgur.com/l2UBhSy.jpg",
            answerDescription:
                "The original prize money was set at ₩45.6 billion, which is a little over US$38.1 million.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. It increases by ₩1 million",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. It decreases by ₩100 million",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. It doubles",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. It increases by ₩100 million",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "In what year did this character win the competition?",
            questionImageSrc: "https://i.imgur.com/d5rTpdI.jpg",
            answerDescription: "This character is called the Front Man and is in charge of overseeing the competition.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 2005",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 2019",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 2021",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 2015",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "Name the detective that enters the game by disguising himself.",
            questionImageSrc: "https://i.imgur.com/UsgEOHP.jpg",
            answerDescription: "Jun-ho manages to enter the game by disguising himself as one of the workers.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Jun-ho",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Oh Il-nam",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Gi Hun",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Ali Abdul",
                    isCorrect: false
                }
            ]
        }
    ]
};

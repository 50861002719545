import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
    StyledActionColumn,
    StyledColumn,
    StyledHeader
} from "../../../../../CommonComponent/v2/datagrid/DataGridStyle";
import { AttachmentListType } from "./Type";
import { deleteAttachment, getGameAttachment } from "./Util";
import { getInitials } from "../../../../../../utils/JambarUtils";
import { Box, Tooltip } from "@mui/material";

export const useGameAttachmentHook = () => {
    const [attachmentList, setAttachmentList] = useState<AttachmentListType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDeleteItem, setSelectedDeletItem] = useState<{
        id: string;
        name: string;
    } | null>(null);
    const [selectedEditItem, setSelectedEditItem] = useState<string | null>(null);
    const { gameId } = useParams<{
        gameId: string;
    }>();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (forceRefresh = false) => {
        setIsLoading(true);
        gameId &&
            getGameAttachment(gameId, forceRefresh)
                .then((res) => {
                    setAttachmentList(res);
                    setSelectedEditItem(null);
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    const columns: GridColDef[] = [
        {
            field: "index",
            headerName: "#",
            width: 20,
            renderHeader: () => <StyledHeader label="#" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.index} />
        },
        {
            field: "title",
            headerName: "Attachment title",
            flex: 1,
            renderHeader: () => <StyledHeader label="Attachment title" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.title} />
        },
        {
            field: "createdBy",
            headerName: "Created By",
            flex: 1,
            renderHeader: () => <StyledHeader label="Created By" />,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.createdBy}>
                    <Box style={{ display: "flex", gap: 3 }}>
                        <StyledColumn width={"30px"} label={`${getInitials(params?.row?.createdBy || "")}`} />
                        <StyledColumn label={`- ${params.row.createdDate}`} fontWeight={340} />
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "lastUpdatedBy",
            headerName: "Updated By",
            flex: 1,
            renderHeader: () => <StyledHeader label="Updated By" />,
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.lastUpdatedBy}>
                    <Box style={{ display: "flex", gap: 3 }}>
                        <StyledColumn width={"30px"} label={`${getInitials(params?.row?.lastUpdatedBy || "")}`} />
                        <StyledColumn label={`- ${params.row.lastUpdatedDate}`} fontWeight={340} />
                    </Box>
                </Tooltip>
            )
        },
        {
            field: "Action",
            headerName: "Action",
            width: 150,
            renderHeader: () => <StyledHeader label="Action" />,
            renderCell: (params: GridCellParams) => (
                <StyledActionColumn
                    isDeleteAvailaible={{
                        isAvailable: true,
                        onClick: () =>
                            setSelectedDeletItem({
                                id: params.row.id,
                                name: params.row.title
                            })
                    }}
                    isEditAvailaible={{
                        isAvailable: true,
                        onClick: () => {
                            setSelectedEditItem(params.row.id);
                        }
                    }}
                />
            )
        }
    ];

    const confirmDelete = () => {
        selectedDeleteItem &&
            deleteAttachment(gameId, selectedDeleteItem.id).then(() => {
                fetchData(true);
                setSelectedDeletItem(null);
            });
    };

    return {
        columns,
        attachmentList,
        selectedEditItem,
        selectedDeleteItem,
        isLoading,
        setSelectedEditItem,
        fetchData,
        setSelectedDeletItem,
        confirmDelete
    };
};

import Styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StepperContainer = Styled(Box)`
    display: flex;
    justify-content: center;
    padding-top: 40px;
    & .Mui-completed {
        color: #65B44A !important;
    }

    & .MuiStepper-root {
        height: 100%;
    }
    & .MuiStepConnector-line {
        height: 100%;
    }
`;

export const GeographyMediumQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "Seems like you didn't pay attention in geography class.",
            imageSrc: "https://i.imgur.com/tccvGSl.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Looks like you've travelled a bit.",
            imageSrc: "https://i.imgur.com/cqcYs9e.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Great job! Marco Polo would be proud.",
            imageSrc: "https://i.imgur.com/XnYtpDE.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "Which of these countries is not in the Western Hemisphere?",
            questionImageSrc: "https://i.imgur.com/pVY1xvB.jpg",
            answerDescription:
                "If you can picture a vertical line separating the Earth in half, the Western hemisphere would be the half in which The Americas lie. Parts of Russia and the U.K would also lie within this hemisphere.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. The Canary Islands",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Jamaica",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Cuba",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Canada",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "In which country is this famous rock located?",
            questionImageSrc: "https://i.imgur.com/OE0CpCi.jpg",
            answerDescription:
                "Did you know that a large part of the Rock of Gibraltar is home to various types of animals, including monkeys and foxes?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Venezuela",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Egypt",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Gibraltar",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Scotland",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Which ocean separates Africa and Australia?",
            questionImageSrc: "https://i.imgur.com/VQD43Ex.jpg",
            answerDescription:
                "There isn’t an “African Ocean”, but there is an Indian Ocean which has had its name since the 1500’s.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Pacific",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Indian",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. African",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Atlantic",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "The Galapagos Islands are part of which of these countries?",
            questionImageSrc: "https://i.imgur.com/IpIiHvP.jpg",
            answerDescription:
                "Did you know that these islands were formed by lava from volcanoes situated on some of the islands?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Portugal",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Ecuador",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Brazil",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Chile",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Name the country this flag belongs to:",
            questionImageSrc: "https://i.imgur.com/FG3DuDj.jpg",
            answerDescription: "Did you know that Singapore is an island located off the tip of Malaysia?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Thailand",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Vietnam",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. The Netherlands",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Singapore",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "Which South American country is in red?",
            questionImageSrc: "https://i.imgur.com/F8C2dkW.jpg",
            answerDescription:
                "Here’s a fun fact: The vicuña, which is related to the llama, is Peru’s national animal.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Brazil",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Peru",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Bolivia",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Argentina",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "What Asian country is formerly known as “Burma”?",
            questionImageSrc: "https://i.imgur.com/7aIiqo5.jpg",
            answerDescription:
                "Did you know that Sylvester Stallone was almost shot by Burma’s military while filming Rambo near Burma?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Myanmar",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Cambodia",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. India",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. South Korea",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Is the Equator a line of latitude or longitude?",
            questionImageSrc: "https://i.imgur.com/wLkkK3i.jpg",
            answerDescription: "The Equator runs horizontally around the Earth, which makes it a line of latitude.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                { id: 1, text: "A. Longitude", isCorrect: false },
                { id: 2, text: "B. Latitude", isCorrect: true }
            ]
        },
        {
            questionId: 9,
            questionTitle: "Name the country this flag belongs to:",
            questionImageSrc: "https://i.imgur.com/XgVHHL9.jpg",
            answerDescription: "Did you know that the famous designer Yves Saint-Laurent was born in Algeria?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Turkey",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Iraq",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Kazakhstan",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Algeria",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "Which African country was called South West Africa until 1990?",
            questionImageSrc: "https://i.imgur.com/nHrPucu.jpg",
            answerDescription:
                "Did you know that there is a desert in Namibia? This sandy area, the Namib desert, is the oldest desert on the planet.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Namibia",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Kenya",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. South Africa",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Egypt",
                    isCorrect: false
                }
            ]
        }
    ]
};

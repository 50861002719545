import { Grid } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import { CaptionText, Header1, PrimaryOutlinedButton } from "../../../../CommonComponent/v2/CommonStyle";
import TabWrapper from "../../../../CommonComponent/v2/tab/Tab";
import ArrowLeft from "../../../../Images/icons/arrowLeft.svg";
import { useQuestionInfo } from "./Hooks";
import { QuestionDetailsTabs } from "./Utils";
import QuestionDetails from "./questionDetails";
import QuestionAnswer from "./answerDetails";
import QuestionSettings from "./questionSettings";
import LoadingWrapper from "../../../../CommonComponent/v2/LoadingWrapper";
import ConfirmationBox from "../../../../CommonComponent/v2/ConfirmationBox";
import QuestionHint from "./questionhints";
import { deleteColor, linkColor } from "../../../../../constants/ColorConstant";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { FC } from "react";

type QuestionInfoProps = {
    selectedQuestionId?: string;
};
const QuestionInfo: FC<QuestionInfoProps> = ({ selectedQuestionId }) => {
    const history = useHistory();
    const {
        selectedTab,
        questionDetails,
        setSelectedTab,
        toggleClone,
        isLoading,
        toggleDelete,
        onUseInGameClick,
        tagsOptions,
        questionHints,
        deleteQuestion,
        cloneQuestion,
        onConfirmQuestionDelete,
        onConfirmQuestionClone,
        questionId,
        permission,
        toggleRestore,
        restoreQuestion,
        onConfirmQuestionRestore,
        isSuperAdmin
    } = useQuestionInfo(Number(selectedQuestionId));
    const searchParams = new URLSearchParams(window.location.search);
    const pageNo = searchParams.get("pageNo") || 1;
    const { state } = useLocation<{
        gameId: string;
    }>();

    const renderView = () => {
        if (selectedTab === "details") {
            return <QuestionDetails tagsOption={tagsOptions} questionDetails={questionDetails} />;
        }
        if (selectedTab === "answer") {
            return <QuestionAnswer questionDetails={questionDetails} />;
        }
        if (selectedTab === "settings") {
            return <QuestionSettings questionHints={questionHints} questionDetails={questionDetails} />;
        }
        if (selectedTab === "hints") {
            return <QuestionHint />;
        }
        return <div></div>;
    };

    const showEditIcon = () => {
        if (!isSuperAdmin) {
            if (
                !["global", "limited"].includes(questionDetails?.visibility) &&
                permission &&
                permission.includes("update") &&
                !questionDetails?.isDeleted
            ) {
                return true;
            }
            return false;
        }
        if (isSuperAdmin && permission && permission.includes("update") && !questionDetails?.isDeleted) {
            return true;
        }
        return false;
    };

    const showDeleteIcon = () => {
        if (!isSuperAdmin) {
            if (
                !["global", "limited"].includes(questionDetails?.visibility) &&
                permission &&
                permission.includes("delete")
            ) {
                return true;
            }
            return false;
        }
        if (isSuperAdmin && permission && permission.includes("delete")) {
            return true;
        }
        return false;
    };
    return (
        <Grid px={"20px"}>
            <Grid container>
                <Grid item xs={12}>
                    <Header1>
                        <img
                            onClick={() =>
                                state?.gameId
                                    ? history.goBack()
                                    : history.push(`/admin/v2/questionlibrary?pageNo=${pageNo}`)
                            }
                            alt={"arrow-left"}
                            src={ArrowLeft}
                            style={{ height: 18, marginRight: 10, cursor: "pointer" }}
                        />
                        Challenge Details
                    </Header1>
                </Grid>
                <Grid item xs={8} mt={"20px"}>
                    <CaptionText>You can review the challenge details here.</CaptionText>
                </Grid>
            </Grid>
            <Grid container flexWrap={"nowrap"} alignItems={"center"} spacing={"20px"} mt={"20px"}>
                <Grid item flex={1}>
                    <TabWrapper
                        tabsOptions={QuestionDetailsTabs(
                            questionDetails?.gameType ? questionDetails?.gameType !== SiteConstants.wildgoat : false
                        )}
                        selectedTab={selectedTab}
                        handleChange={setSelectedTab}
                    />
                </Grid>
                <Grid item>
                    <Grid container flexWrap={"nowrap"} spacing={2}>
                        {!!(showDeleteIcon() && permission && permission.includes("delete")) && (
                            <Grid item>
                                <PrimaryOutlinedButton
                                    onClick={questionDetails?.isDeleted ? toggleRestore : toggleDelete}
                                    fullWidth
                                    fontsize="16px"
                                    style={{ padding: "16px 10px", minWidth: 100, width: "fit-content" }}
                                    textcolor={deleteColor}>
                                    {questionDetails?.isDeleted ? "Restore" : "deleted"}
                                </PrimaryOutlinedButton>
                            </Grid>
                        )}
                        {!!(permission && permission.includes("clone")) && !questionDetails?.isDeleted && (
                            <Grid item>
                                <PrimaryOutlinedButton
                                    fontsize="16px"
                                    fullWidth
                                    onClick={toggleClone}
                                    style={{ padding: "16px 10px", minWidth: 100, width: "fit-content" }}>
                                    Duplicate
                                </PrimaryOutlinedButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <LoadingWrapper isLoading={isLoading}>
                <>
                    <Grid container mt={"20px"}>
                        <Grid item xs={12}>
                            {questionDetails && renderView()}
                        </Grid>
                    </Grid>
                    <Grid pb={"50px"} justifyContent={"center"} container mt={"20px"}>
                        <Grid item xs={10}>
                            <Grid justifyContent={"center"} container spacing={2}>
                                {showEditIcon() && (
                                    <Grid item>
                                        <PrimaryOutlinedButton
                                            fontsize="16px"
                                            onClick={() => {
                                                history.push(
                                                    `/admin/questionlibrary/${questionDetails?.gameType}/update/${questionId}?pageNo=${pageNo}`
                                                );
                                            }}>
                                            Edit
                                        </PrimaryOutlinedButton>
                                    </Grid>
                                )}
                                <Grid item>
                                    <PrimaryOutlinedButton
                                        fontsize="16px"
                                        onClick={() => history.push(`/admin/v2/questionlibrary?pageNo=${pageNo}`)}>
                                        Back
                                    </PrimaryOutlinedButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </LoadingWrapper>
            {questionDetails && deleteQuestion && (
                <ConfirmationBox
                    title={`Delete "${questionDetails.QuestionTitle}" challenge`}
                    message={"Please confirm if you really want to delete this challenge"}
                    onConfirm={onConfirmQuestionDelete}
                    onClose={toggleDelete}
                />
            )}

            {questionDetails && cloneQuestion && (
                <ConfirmationBox
                    title={`Duplicate "${questionDetails.QuestionTitle}" challenge`}
                    message={"Please confirm if you really want to duplicate this challenge"}
                    onConfirm={onConfirmQuestionClone}
                    onClose={toggleClone}
                    type={"clone"}
                />
            )}
            {restoreQuestion && questionDetails && (
                <ConfirmationBox
                    title={`Restore "${questionDetails.QuestionTitle}" challenge`}
                    message={"Please confirm if you really want to restore this challenge"}
                    onConfirm={onConfirmQuestionRestore}
                    onClose={toggleRestore}
                />
            )}
        </Grid>
    );
};

export default QuestionInfo;

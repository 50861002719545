{
    /*eslint-disable*/
}
import { Box, CircularProgress, CircularProgressProps } from "@mui/material";
import { FC } from "react";
import { darkColor } from "../../WebsiteGame/colorConstant";
import { CaptionText, StyledCircularProgress } from "./CommonStyle";

type CircularProgressWrapperProps = {
    progressValue: number;
    height?: string;
    width?: string;
    progressColor?: string;
} & CircularProgressProps;

const CircularProgressWrapper: FC<CircularProgressWrapperProps> = ({
    value,
    progressValue,
    height,
    width,
    progressColor,
    ...rest
}) => {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <StyledCircularProgress height={height} width={width} progressColor={progressColor}>
                <CircularProgress value={progressValue} variant={"determinate"} {...rest} />
            </StyledCircularProgress>

            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                <CaptionText color={darkColor}>{progressValue}</CaptionText>
            </Box>
        </Box>
    );
};

export default CircularProgressWrapper;

import React, { FC } from "react";
import { Box, CircularProgress } from "@mui/material";

const Loader: FC = (): JSX.Element => (
    <Box style={{ display: "grid", placeItems: "center", height: "calc(100% - 50px)" }}>
        <CircularProgress />
    </Box>
);

export default Loader;

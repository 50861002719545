import { object, string } from "yup";

export const feedbackInitialValues = {
    companyName: "",
    playedBefore: "",
    enjoyPoints: 10,
    teamConnectPoints: 10,
    overallExpereincePoints: 10,
    likes: "",
    mistakes: "",
    improvement: ""
};

export const feedbackSchema = object().shape({
    companyName: string().required("Please enter your company name"),
    playedBefore: string().required("Please choose option"),
    enjoyPoints: string().required("Please choose option"),
    teamConnectPoints: string().required("Please choose option"),
    overallExpereincePoints: string().required("Please choose option"),
    likes: string().required("Please tell what you like"),
    improvement: string().required("Please tell us any improvement needed")
});

export const GEOGRAPHY_GAME = "geography";
export const RED_NOTICE = "rednotice";
export const MUSIC = "music";
export const MOVIE = "movie";
export const SPORTS = "sports";
export const ANIMAL = "animal";
export const GEOGRAPHY_MEDIUM = "geographymedium";
export const MUSIC_MEDIUM = "musicmedium";
export const ANIMAL_MEDIUM = "animalmedium";
export const SPORTS_MEDIUM = "sportsmedium";
export const GEOGRAPHY_JAMBAR = "geographyjambar";
export const GEOGRAPHY_HARD = "geographyhard";
export const MOVIE_HARD = "moviehard";
export const MUSIC_HARD = "musichard";
export const ANIMAL_HARD = "animalhard";
export const SPORTS_HARD = "sportshard";
export const MARVEL = "marvel";
export const SHERLOCK = "sherlock";
export const SQUIDGAME = "squidgame";
export const HARRYPOTTER = "harrypotter";
export const MONEYHEIST = "moneyheist";
export const MOVIEMEDIUM = "moviemedium";
export const GEOGRAPHY_JAMBAR_MEDIUM = "geographyjambarmedium";
export const WEBSITE_GAME_URL = "https://jambarteambuilding.com/trivia-games-online/";
export const USER_JAMBAR = "jambar";

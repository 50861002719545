export const MoneyHeistQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "You don't watch the show, do you?",
            imageSrc: "https://i.imgur.com/YaHrw7U.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Not bad, you have an average score.",
            imageSrc: "https://i.imgur.com/bki3RGY.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "You're a true 'Money Heist' fan!",
            imageSrc: "https://i.imgur.com/ihMR2LK.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "What is the Professor’s real name?",
            questionImageSrc: "https://i.imgur.com/W0qCXaM.jpg",
            answerDescription: "The Professor is also known as ‘Salva’ in this TV series.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Sergio Marquina",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Sergio Aguero",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Rio",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Tokyo",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "What is Rio’s profession?",
            questionImageSrc: "https://i.imgur.com/1j5F36v.jpg",
            answerDescription: "This hacking genius’ real name is Aníbal Cortés.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. auto-mechanic",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. pizza delivery guy",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. hacker",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. bank worker",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Before she joined the gang, what was Raquel Murillo’s job?",
            questionImageSrc: "https://i.imgur.com/1BfilOp.jpg",
            answerDescription:
                "Raquel Murillo later chooses the name ‘Lisbon’ as her alias when she joins the Professor’s gang.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. money forger",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. art thief",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. jewel thief",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. police inspector",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "What character does this actor play? ",
            questionImageSrc: "https://i.imgur.com/NxKn0y9.jpg",
            answerDescription: "In this TV series, Moscow is a former miner and the father of Denver.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Arturo Román",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Berlin",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Denver",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Moscow",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "What role does this actress play? ",
            questionImageSrc: "https://i.imgur.com/iaXCvJv.jpg",
            answerDescription:
                "Úrsula Corberó is also known for her role as Baroness in Snake Eyes, a film in the G.I. Joe series.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Stockholm",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Tokyo",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Alicia Sierra",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Nairobi",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "The masks which the gang uses depict which famous person?",
            questionImageSrc: "https://i.imgur.com/Rd9CkMh.jpg",
            answerDescription:
                "Salvador Dali was one of Spain’s most famous artists in the early and mid-twentieth century and remains an icon of Spanish culture today.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Pablo Picasso",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Alexander the Great",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Julio Iglesias",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Salvador Dali",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "What character did this actor play in Money Heist?",
            questionImageSrc: "https://i.imgur.com/uUwrxNl.jpg",
            answerDescription: "Did you know that this actor, Jaime Lorente, is also a model?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Arturo Román",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Denver",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Rio",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Berlin",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Money Heist is an original series on what network?",
            questionImageSrc: "https://i.imgur.com/tkA8C1I.jpg",
            answerDescription: "Did you know that Money Heist is one of the most-watched series on Netflix?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. HBO",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Showtime",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Netflix",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Hulu",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "How many days was the first heist expected to take?",
            questionImageSrc: "https://i.imgur.com/RRhF6G0.jpg",
            answerDescription: "The heist took a shorter time than expected to complete - five to six days in total.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 11",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. 4",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 2",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 15",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "What type of currency was printed in the first heist?",
            questionImageSrc: "https://i.imgur.com/San6BYO.jpg",
            answerDescription:
                "The currency shown was not the Euro (Spain’s official currency), as one would have expected it to be.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. US dollar",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Nigerian naira",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Euro",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. British pound",
                    isCorrect: false
                }
            ]
        }
    ]
};

import { Spinner } from "@fluentui/react";
import React from "react";
import { useState } from "react";
import { Modal, InputGroup, FormControl, Button } from "react-bootstrap";

type TeamNameModalProps = {
    onCloseModal: () => void;
    saveTeamName: (teamName: string) => void;
    isLoading: boolean;
};

const TeamNameModal: React.FC<TeamNameModalProps> = ({ onCloseModal, saveTeamName, isLoading }) => {
    const [teamName, setTeamName] = useState("");

    return (
        <Modal centered show={true} onHide={onCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Enter Team Name</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <InputGroup className={"mb-3"}>
                    <FormControl required value={teamName} onChange={(event) => setTeamName(event.target.value)} />
                </InputGroup>
                <span className={"error-message"}>{teamName === "" && "Please enter Team Name"}</span>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onCloseModal}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    disabled={isLoading}
                    onClick={() => {
                        teamName !== "" && saveTeamName(teamName);
                    }}>
                    {isLoading ? <Spinner /> : "Save"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TeamNameModal;

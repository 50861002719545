import { Stack } from "@fluentui/react";
import React, { FC, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { Client } from "../../../Base/Client";
import { eventSurveyType, EventSurveyViewProps } from "./utils/type";
import SurveyPointsBar from "./SurveyPointsBar";

const EventSurveyView: FC<EventSurveyViewProps> = ({ eventId }) => {
    const [surveyDetails, setSurveyDetails] = useState<eventSurveyType[]>([]);

    useEffect(() => {
        Client.getInstance()
            .getData(ApiConstants.getEventSurveyDetailApiUrl(eventId), true)
            .then((response) => {
                response.data.data &&
                    setSurveyDetails(
                        response.data.data.map((item: eventSurveyType) => ({
                            ...item,
                            surveyDetails: JSON.parse(item.surveyDetails.toString())
                        }))
                    );
            });
    }, []);

    if (surveyDetails.length === 0) {
        return (
            <Stack verticalAlign="center" style={{ height: "200px" }} horizontalAlign="center">
                <Stack.Item align="center">
                    <p className="font-size-20">No survey details found</p>
                </Stack.Item>
            </Stack>
        );
    }

    return (
        <div>
            {surveyDetails.map((detail) => (
                <Card bg={"light"} key={detail.id} text={"dark"} style={{ width: "auto" }} className="margin-bottom-25">
                    <Card.Body>
                        <div className="grid-column margin-bottom-10">
                            <span>
                                <p className="cardSubText">Participant Name:</p> {detail.participantName}
                            </span>
                            <span>
                                <p className="cardSubText">Date:</p>
                                {JambarDateUtil.dateFormat(new Date(detail.createdAt), "DD/MM/YYYY")}
                            </span>
                            <span>
                                <p className="cardSubText">Company Name:</p>
                                {detail.surveyDetails.companyName}
                            </span>
                            <span>
                                <p className="cardSubText">Played Before:</p>
                                {detail.surveyDetails.playedBefore}
                            </span>
                            <span>
                                <p className="cardSubText">Code:</p>
                                {detail.participantCode}
                            </span>
                        </div>
                        <div className="grid-column margin-bottom-10">
                            <span>
                                <p className="cardSubText">Overall Experience Points:</p>
                                {detail.surveyDetails.overallExpereincePoints}
                                <SurveyPointsBar points={Number(detail.surveyDetails.overallExpereincePoints) * 10} />
                            </span>
                            <span>
                                <p className="cardSubText">Enjoy Points:</p>
                                {detail.surveyDetails.enjoyPoints}
                                <SurveyPointsBar points={Number(detail.surveyDetails.enjoyPoints) * 10} />
                            </span>
                            <span>
                                <p className="cardSubText">Team Connect Points:</p>
                                {detail.surveyDetails.teamConnectPoints}
                                <SurveyPointsBar points={Number(detail.surveyDetails.teamConnectPoints) * 10} />
                            </span>
                        </div>
                        <hr />
                        <div className="margin-bottom-10">
                            <p className="cardSubText">Likes:</p>
                            {detail.surveyDetails.likes}
                        </div>
                        <hr />
                        <div className="margin-bottom-10">
                            <p className="cardSubText">Mistakes:</p>
                            {detail.surveyDetails.mistakes}
                        </div>
                        <hr />
                        <div>
                            <p className="cardSubText">Improvement:</p>
                            {detail.surveyDetails.improvement}
                        </div>
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
};

export default EventSurveyView;

import { Dialog, Grid } from "@mui/material";
import { FC } from "react";
import { Header1 } from "../../../CommonComponent/v2/CommonStyle";
import { MdLocationOn } from "react-icons/md";

type ShowLocationPermissionModalProps = {
    handleClose: () => void;
    errorMessage: string;
};
const ShowLocationPermissionModal: FC<ShowLocationPermissionModalProps> = ({ handleClose, errorMessage }) => {
    return (
        <Dialog maxWidth={"md"} open onClose={handleClose}>
            <Grid p={"20px"}>
                <Grid container justifyContent={"center"}>
                    <MdLocationOn style={{ fontSize: 50 }} />
                    <Grid item>
                        <Header1 fontsize="24px">{errorMessage}</Header1>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ShowLocationPermissionModal;

import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { StyledActionColumn, StyledColumn, StyledHeader } from "../../../CommonComponent/v2/datagrid/DataGridStyle";
import { UsageListType } from "../gamelibrary/Type";
import { TagListItem } from "./Type";
import { addTag, fetchTagsUsage, getTagsList, tagDelete, updateTagDetail } from "./Util";

export const useTagsListView = (refreshTagList: () => void) => {
    const [searchText, setSearchText] = useState("");
    const [tagList, setTagList] = useState<TagListItem[]>([]);
    const [isLoading, setLoading] = useState({
        listLoading: false,
        usageListLoading: false
    });
    const [selectedDeleteTag, setSelectedDeleteTag] = useState<TagListItem | null>(null);
    const [selectedEditTag, setSelectedEditTag] = useState<TagListItem | null>(null);
    const [usageList, setUsageList] = useState<UsageListType[]>([]);

    useEffect(() => {
        fetchTagList();
    }, []);

    const fetchTagList = () => {
        setSelectedEditTag(null);
        setLoading((prev) => ({
            ...prev,
            listLoading: true
        }));
        getTagsList(true)
            .then((res) => {
                setTagList(res);
                refreshTagList();
            })
            .finally(() => {
                setLoading((prev) => ({
                    ...prev,
                    listLoading: false
                }));
            });
    };

    useEffect(() => {
        if (selectedDeleteTag) {
            setUsageList([]);
            setLoading((prev) => ({
                ...prev,
                usageListLoading: true
            }));
            fetchTagsUsage(true, selectedDeleteTag.id).then((res) => {
                setUsageList(res);
                setLoading((prev) => ({
                    ...prev,
                    usageListLoading: false
                }));
            });
        }
    }, [selectedDeleteTag]);

    const handleTagDelete = () => {
        selectedDeleteTag &&
            tagDelete(selectedDeleteTag.id).then(() => {
                setSelectedDeleteTag(null);
                fetchTagList();
            });
    };

    return {
        searchText,
        setSearchText,
        tagList,
        isLoading,
        selectedDeleteTag,
        selectedEditTag,
        setSelectedDeleteTag,
        setSelectedEditTag,
        usageList,
        handleTagDelete,
        fetchTagList
    };
};

export const useTagList = () => {
    const tagListColumns = (
        handleDelete: (info: TagListItem) => void,
        handleEdit: (info: TagListItem) => void
    ): GridColDef[] =>[
                {
                    field: "tagName",
                    headerName: "Tag Name",
                    flex: 1,
                    renderHeader: () => <StyledHeader label="Tag name" />,
                    renderCell: (params: GridCellParams) => <StyledColumn label={params.row.tagName} />
                },
                {
                    field: "Action",
                    headerName: "Action",
                    width: 150,
                    renderHeader: () => <StyledHeader label="Action" />,
                    renderCell: (params: GridCellParams) => (
                        <StyledActionColumn
                            isDeleteAvailaible={{
                                isAvailable: true,
                                onClick: () =>
                                    handleDelete({
                                        id: params.row.id,
                                        tagName: params.row.tagName
                                    })
                            }}
                            isEditAvailaible={{
                                isAvailable: true,
                                onClick: () => {
                                    handleEdit({
                                        id: params.row.id,
                                        tagName: params.row.tagName
                                    });
                                }
                            }}
                        />
                    )
                }
            ];

    return {
        tagListColumns
    };
};

export const useUpdateTagModal = () => {
    const updateTag = (value: TagListItem, setSubmitting: (isSubmitting: boolean) => void, refreshList: () => void) => {
        if (value.id !== -1) {
            updateTagDetail(value)
                .then(() => {
                    refreshList();
                })
                .finally(() => {
                    setSubmitting(false);
                });
            return;
        }
        addTag(value)
            .then(() => {
                refreshList();
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return {
        updateTag
    };
};

import { IColumn } from "@fluentui/react";
import { Checkbox, Tooltip } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { AiOutlinePlus } from "react-icons/ai";
import { FaMinus } from "react-icons/fa";
import { SiteConstants } from "../../../../../../../constants/SiteConstant";
import { getPermissions } from "../../../../../../../utils/PermissionUtils";
import { extractHtmlContent } from "../../../../../../../utils/JambarUtils";
import {
    StyledActionColumn,
    StyledColumn,
    StyledHeader
} from "../../../../../../CommonComponent/v2/datagrid/DataGridStyle";
import { DeleteIcon, EditIcon } from "../../../../../../CommonComponent/v2/datagrid/Style";
import { useAdminContext } from "../../../../../context/AdminAuthContext";
import { GameQuestionListItem } from "../../Type";

export const useSelectedQuestionHook = () => {
    const { userDetail } = useAdminContext();
    const permissionQuizFullGame: string[] | null = getPermissions(userDetail, "quiz_fullgame");
    const permissionQuizSocketGame: string[] | null = getPermissions(userDetail, "quiz_socket");
    const permissionQuizPhysicalGame: string[] | null = getPermissions(userDetail, "quiz_wildgoat");

    const questionListColumns = (
        selectedRows: GameQuestionListItem[],
        setSelectedRows: (rows: GameQuestionListItem[]) => void,
        allQuestionList: GameQuestionListItem[],
        handleRowSelect: (rows: GameQuestionListItem) => void,
        handleViewClick: (id: number) => void
    ): GridColDef[] => [
        {
            field: "key",
            headerName: "",
            width: 20,
            sortable: false,
            renderHeader: () => (
                <Tooltip title={selectedRows.length === 0 ? "Select all" : "Unselect all"}>
                    <Checkbox
                        checkedIcon={<FaMinus />}
                        icon={<AiOutlinePlus />}
                        onClick={() => {
                            if (selectedRows.length > 0) {
                                setSelectedRows([]);
                                return;
                            }
                            setSelectedRows(allQuestionList);
                        }}
                        checked={selectedRows.length > 0}
                    />
                </Tooltip>
            ),
            renderCell: (params: GridCellParams) => (
                <Checkbox
                    onClick={() => {
                        handleRowSelect(params.row);
                    }}
                    checked={selectedRows.map((item) => item.id).includes(params.row.id)}
                />
            )
        },
        {
            field: "index",
            headerName: "",
            width: 20,
            renderHeader: () => <StyledHeader label="#" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.index + 1} />
        },
        {
            field: "questionTitle",
            headerName: "Title",
            flex: 1,
            renderHeader: () => <StyledHeader label="Challenge title" />,
            renderCell: (params: GridCellParams) => (
                <StyledColumn label={extractHtmlContent(params.row.questionTitle)} />
            )
        },
        {
            field: "id",
            headerName: "Action",
            width: 180,
            renderHeader: () => <StyledHeader label="Action" />,
            renderCell: (params: GridCellParams) => (
                <StyledActionColumn
                    isViewAvailaible={{
                        isAvailable: !!(
                            permissionQuizFullGame?.includes("update") ||
                            permissionQuizSocketGame?.includes("update") ||
                            permissionQuizPhysicalGame?.includes("update")
                        ),
                        onClick: () => handleViewClick(params.row.id)
                    }}
                />
            )
        }
    ];

    const selectedQuestionColumns: IColumn[] = [
        {
            key: "index",
            name: "#",
            fieldName: "index",
            minWidth: SiteConstants.listColumnSerialNumber,
            maxWidth: SiteConstants.listColumnSerialNumber,
            isResizable: true
        },
        {
            key: "questionTitle",
            name: "Challenge title",
            fieldName: "questionTitle",
            minWidth: SiteConstants.listColumnMedium
        },
        {
            key: "edit",
            name: "",
            fieldName: "edit",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "delete",
            name: "",
            fieldName: "delete",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        }
    ];

    const renderItemColumn = (
        item: GameQuestionListItem,
        index: number | undefined,
        column: IColumn | undefined,
        handleEditClick: (id: number) => void,
        handleDeleteClick?: (id: number) => void,
        hideAction?: boolean
    ): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "edit":
                return (
                    <EditIcon
                        style={{ display: hideAction ? "none" : "" }}
                        onClick={() => {
                            handleEditClick(item.id);
                        }}
                    />
                );
            case "delete":
                return (
                    <DeleteIcon
                        style={{ display: hideAction ? "none" : "" }}
                        onClick={() => {
                            handleDeleteClick && handleDeleteClick(item.id);
                        }}
                    />
                );
            case "questionTitle":
                return (
                    <>
                        <span className={"margin-bottom-10 display-block"}>
                            {extractHtmlContent(item.questionTitle)}
                        </span>
                    </>
                );
            case "index":
                return <span>{index?.toString() ? index + 1 : ""}</span>;

            default: {
                const fieldContent = item[column.fieldName as keyof GameQuestionListItem] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    return {
        questionListColumns,
        selectedQuestionColumns,
        renderItemColumn
    };
};

import { Typography } from "@mui/material";
import { PageNotFoundStyledContainer } from "./style";

const PageNotFound = () => {
    return (
        <PageNotFoundStyledContainer
            py={"50px"}
            container
            justifyContent={"center"}
            alignItems={"center"}
            minHeight={"100vh"}>
            <Typography variant="h3" color={"white"}>
                PAGE NOT FOUND...
            </Typography>
        </PageNotFoundStyledContainer>
    );
};

export default PageNotFound;

import React, { useRef, useState, useEffect, useCallback } from "react";
import Webcam from "react-webcam";
import { PrimaryButton, SpinnerSize, Stack, Spinner, Label } from "@fluentui/react";
import { ThemedMediumStackTokens } from "../../../constants/Styles";
import { Client } from "../../Base/Client";
import { ApiConstants } from "../../../constants/ApiConstant";
import { toast, ToastContainer } from "react-toastify";
import { SiteConstants } from "../../../constants/SiteConstant";
import { getErrorMessage } from "../../../utils/APIErrorMessages";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
    facingMode: FACING_MODE_USER
};

const WebcamComponent: React.FC = () => {
    const webcamRef = useRef<Webcam | null>(null);
    const [imgSrc, setImgSrc] = useState<string | undefined>();
    const [isUploading, setIsUploading] = useState(false);
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
    const [showSwitchCameraButton, setShowSwitchCameraButton] = useState(false);
    const [isWebCamAvailable, setIsWebCamAvailable] = useState(true);

    useEffect(() => {
        navigator.mediaDevices
            .getUserMedia({ audio: true, video: true })
            .then(() => {
                setIsWebCamAvailable(true);
            })
            .catch(() => {
                setIsWebCamAvailable(false);
            });

        navigator.mediaDevices.enumerateDevices().then((devices: MediaDeviceInfo[]) => {
            setShowSwitchCameraButton(devices.filter((item) => item.kind === "videoinput").length > 1);
        });
    }, []);

    const capture = useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            setImgSrc(imageSrc || undefined);
        }
    }, [webcamRef, setImgSrc]);

    const onUploadImage = () => {
        setIsUploading(true);
        Client.getInstance()
            .createData(ApiConstants.uploadImage(), {
                image: imgSrc
            })
            .then(() => {
                toast.success("Photo uploaded", SiteConstants.successToastConfiguration);
                setImgSrc("");
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setIsUploading(false);
            });
    };

    if (!isWebCamAvailable) {
        return (
            <Stack
                className={"align-item-to-center"}
                tokens={ThemedMediumStackTokens}
                verticalAlign={"center"}
                horizontalAlign={"center"}>
                <Label>No camera found on this device</Label>
            </Stack>
        );
    }

    if (imgSrc) {
        return (
            <Stack tokens={ThemedMediumStackTokens} horizontalAlign={"center"}>
                <ToastContainer />
                <img className={"webcam-view"} alt={"User photo"} src={imgSrc} />
                <Stack tokens={ThemedMediumStackTokens} horizontal horizontalAlign={"center"}>
                    {isUploading ? (
                        <Spinner size={SpinnerSize.large} />
                    ) : (
                        <>
                            <PrimaryButton text={"Upload"} onClick={onUploadImage} />
                            <PrimaryButton text={"Take another"} onClick={() => setImgSrc("")} />
                        </>
                    )}
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack tokens={ThemedMediumStackTokens} horizontalAlign={"center"}>
            <ToastContainer />
            <Webcam
                className={"webcam-view"}
                audio={false}
                mirrored={true}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                    ...videoConstraints,
                    facingMode
                }}
            />
            <Stack horizontal tokens={ThemedMediumStackTokens}>
                <PrimaryButton className={"capture-button"} text={"Take photo"} onClick={capture} />
                {showSwitchCameraButton && (
                    <PrimaryButton
                        onClick={() =>
                            setFacingMode((prevState) =>
                                prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
                            )
                        }
                        text={"Switch camera"}
                    />
                )}
            </Stack>
        </Stack>
    );
};

export default WebcamComponent;

import Style from "@emotion/styled";
import { purpleColor, whiteColor } from "../../../../constants/Styles";

export const WildGoatsHeader = Style.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
gap: 14px;
`;
export const LogoContainer = Style.div`
border-radius: 50%;
padding: 0px;
height: 65px;
max-width: 64px;
aspect-ratio: 16/9;
`;

export const CompanyLogo = Style.img`
    object-fit: contain;
    background: ${whiteColor};
    height: 100%;
    border-radius: 50%;
    aspect-ratio: 16/9;
`;

type TitleBoxProps = {
    textcolor?: string;
};
export const TitleBox = Style.div<TitleBoxProps>`
background: ${whiteColor};
height: 65px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
border-radius: 30px;
font-weight: bold;
color: ${(props) => props.textcolor || purpleColor};
width: 100%;
& p {
    margin-bottom: 0px;
}
@media (max-width: 380px) {
    font-size: 14px;
}

`;

import { useEffect, useState } from "react";
import { getPaymentList, getPaymentReceipt } from "./util";
import { useAdminContext } from "../../context/AdminAuthContext";
import { superAdmin } from "../../../../constants/SiteConstant";
import { toast } from "react-toastify";

export const usePaymentList = () => {
    const { userDetail } = useAdminContext();
    const [loading, setLoading] = useState(false);
    const [paymentList, setPaymentList] = useState([]);
    const IS_SUPER_ADMIN = userDetail?.currentRoles.find((item) => item.name === superAdmin);
    const [searchText, setSearchText] = useState<string>("");

    useEffect(() => {
        fetchPaymentList();
    }, []);

    const downloadReceipt = (receiptId: string) => {
        toast.success("Download Started");
        getPaymentReceipt(receiptId)
            .then(() => {
                toast.success("Download Successful");
            })
            .catch(() => {
                toast.error("Download failed");
            });
    };

    const fetchPaymentList = () => {
        setLoading(true);
        getPaymentList(userDetail.id, !!IS_SUPER_ADMIN)
            .then((res) => {
                setPaymentList(res);
            })
            .catch(() => {
                setPaymentList([]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        loading,
        paymentList,
        setSearchText,
        searchText,
        downloadReceipt
    };
};

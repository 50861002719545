import {
    ConnectorQuestionInfoTypes,
    QuizConfigType,
    QuizInfoType,
    QuizUserSessionInfoType,
    SelectedDocumentType,
    TimersEndType
} from "./RocketTemplateTypes";

export const QuizInfoInitialValues: QuizInfoType = {
    title: "",
    correctAnswerPoints: "0",
    wrongAnswerPoints: "0",
    summaryPageInfo: "",
    eventLogo: "",
    quizAllowSkipping: false,
    initialEventTimer: "",
    nextButtonText: "Continue",
    answerResultImage: "",
    answerResultText: "",
    showProgressInPercentage: false,
    companyLogo: "",
    showSurveyForm: false,
    quizTitle: "",
    showCorrectAnswerCount: true,
    showHintCount: true,
    showMainTimer: true,
    showWrongAnswerCount: true,
    totalPointsText: ""
};

export const QuizUserSessionInfoInitialValues: QuizUserSessionInfoType = {
    correctAnswerCount: 0,
    eventTimer: "",
    hintUsed: 0,
    hintsAlreadyUsed: "",
    progressId: -1,
    totalHintUsed: 0,
    totalMarks: 0,
    wrongAnswerCount: 0
};

export const QuizConfigInitialValues: QuizConfigType = {
    attachmentTitle: "",
    backgroundColor: "",
    backgroundImage: "",
    progressImage: "",
    progressTitle: "",
    tabTitle: "",
    buttonColor: "",
    correctAnswerBackgroundColor: "",
    wrongAnswerBackgroundColor: ""
};

export const SelectedDocumentInitialValues: SelectedDocumentType = {
    documentSelected: "",
    selectedDocumentType: ""
};

export const TimerEndInitialValues: TimersEndType = {
    connectorQuizEnds: false,
    eventEnds: false,
    questionTimeEnds: false
};

export const ConnectorQuestionInitialValues: ConnectorQuestionInfoTypes = {
    connectorIsClosable: false,
    connectorQuizIsWorking: false,
    connectorTotalTime: "",
    playedConnectorOptionQuizIds: [],
    showConnectorTimer: false,
    timeTakenInConnector: ""
};

import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { LiveTeamPerformanceType } from "./Type";
import { getLiveTeamPerformanceList } from "./Util";

export const useLivePerformanceHook = () => {
    const [liveteamPerformance, setLiveTeamPerformance] = useState<LiveTeamPerformanceType | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const info: any = useLocation();

    useEffect(() => {
        setIsLoading(true);
        getLiveTeamPerformanceList(info.state.eventId, info.state.code)
            .then((res) => {
                setLiveTeamPerformance(res);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return {
        liveteamPerformance,
        isLoading
    };
};

import { IColumn, IconButton, Stack, Modal } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import DeleteConfirmationDialog from "../../../CommonComponent/DeleteConfirmationDialog";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import { HintListItem } from "./QuestionDetailsDataTypes";
import QuestionHintDetailsView from "./QuestionHintDetailsView";
import { Client } from "../../../Base/Client";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import FixedHeaderSortingDetailsList from "../../../CommonComponent/FixedHeaderSortingDetilsList";
import { extractHtmlContent } from "../../../../utils/JambarUtils";
import { useAdminContext } from "../../context/AdminAuthContext";
import { getPermissions } from "../../../../utils/PermissionUtils";

type QuestionHintsListViewProps = {
    questionId: number;
};

const QuestionHintsListView: React.FC<QuestionHintsListViewProps> = ({ questionId }) => {
    const [hintList, setHintList] = useState<HintListItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteConfirmationDialog, setDeleteConfirmationDialogDisplay] = useState(false);
    const [selectedHintId, setSelectedHintId] = useState(-1);
    const [showAddEditHintModal, setAddEditHintModalDisplay] = useState(false);
    const [listkey, setListkey] = useState("initialKey");
    const [hintText, setHintText] = useState("");
    const [scorePenalty, setScorePenalty] = useState("");
    const [timePenalty, setTimePenalty] = useState("");

    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "hint");

    useEffect(() => {
        fetchQuestionHints();
    }, []);

    const fetchQuestionHints = (): void => {
        setIsLoading(true);
        Client.getInstance()
            .getData(ApiConstants.getAllHintsOfQuestion(questionId), true)
            .then((response) => {
                if (!(permission && permission.includes("fetch"))) {
                    setHintList([]);
                    setIsLoading(false);
                    return;
                }
                const hintsData: HintListItem[] = response.data.data.map((hint: any, index: number) => ({
                    id: hint.id,
                    hint: hint.description,
                    createdBy: `${hint.createdByUser.firstName} ${hint.createdByUser.lastName}`,
                    createdDate: JambarDateUtil.formatDate(hint.createdAt),
                    lastUpdatedBy: `${hint.updatedByUser.firstName} ${hint.updatedByUser.lastName}`,
                    lastUpdatedDate: JambarDateUtil.formatDate(hint.updatedAt),
                    scorePenalty: hint.scorePenalty,
                    index: index + 1,
                    timePenalty: hint.timePenalty
                }));
                setHintList(hintsData);
                setIsLoading(false);
                setListkey(Math.random().toString(36).substring(7));
            });
    };

    const listColumns: IColumn[] = [
        {
            key: "id",
            name: "Id",
            fieldName: "id",
            minWidth: SiteConstants.listColumnSerialNumber,
            maxWidth: SiteConstants.listColumnSerialNumber,
            isResizable: true
        },
        {
            key: "hint",
            name: "Hint",
            fieldName: "hint",
            minWidth: SiteConstants.listColumn300
        },
        {
            key: "scorePenalty",
            name: "Score Penalty",
            fieldName: "scorePenalty",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        },
        {
            key: "timePenalty",
            name: "Time Penalty",
            fieldName: "timePenalty",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        },
        {
            key: "createdBy",
            name: "Created By",
            fieldName: "createdBy",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        },
        {
            key: "lastUpdated",
            name: "Updated By",
            fieldName: "lastUpdated",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        }
    ];

    const hintMenuItems = [
        {
            key: "update",
            name: "",
            fieldName: "update",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "delete",
            name: "",
            fieldName: "delete",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        }
    ];

    const getColumns = () => {
        if (permission) {
            return [...listColumns, ...hintMenuItems.filter((column) => permission && permission.includes(column.key))];
        }
        return listColumns;
    };

    const renderItemColumn = (item: HintListItem, _: number | undefined, column: IColumn | undefined): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "hint":
                return <span>{extractHtmlContent(item.hint)}</span>;
            case "createdBy":
                return (
                    <span>
                        {item.createdBy}
                        <br />
                        on <i>{item.createdDate}</i>
                    </span>
                );
            case "lastUpdated":
                return (
                    <span>
                        {item.lastUpdatedBy}
                        <br />
                        on <i>{item.lastUpdatedDate}</i>
                    </span>
                );
            case "update":
                return (
                    <IconButton
                        iconProps={{ iconName: "edit" }}
                        title="Edit"
                        onClick={() => {
                            item.id && setSelectedHintId(item.id);
                            setScorePenalty(item.scorePenalty);
                            item.hint && setHintText(item.hint);
                            setTimePenalty(item.timePenalty);
                            setAddEditHintModalDisplay(true);
                        }}
                    />
                );

            case "delete":
                return (
                    <IconButton
                        iconProps={{ iconName: "delete" }}
                        title="Delete"
                        onClick={() => {
                            item.id && setSelectedHintId(item.id);
                            setDeleteConfirmationDialogDisplay(true);
                        }}
                    />
                );
            case "id":
                return <span>{item.index}</span>;

            default: {
                const fieldContent = item[column.fieldName as keyof HintListItem] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    const onDeleteHint = (): void => {
        Client.getInstance()
            .deleteData(ApiConstants.deleteQuestionHintApiUrl(questionId, selectedHintId))
            .then(() => {
                setSelectedHintId(-1);
                setDeleteConfirmationDialogDisplay(false);
                fetchQuestionHints();
            })
            .catch((error) => {
                setSelectedHintId(-1);
                setDeleteConfirmationDialogDisplay(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const onDismissModal = (): void => {
        setAddEditHintModalDisplay(false);
        setSelectedHintId(-1);
    };

    const onCloseModalAndRefreshList = (): void => {
        setAddEditHintModalDisplay(false);
        setSelectedHintId(-1);
        setHintText("");
        setScorePenalty("");
        fetchQuestionHints();
    };

    const saveListOrder = (hints: HintListItem[]): void => {
        setHintList(hints);
        Client.getInstance()
            .updateData(ApiConstants.saveHintsOrder(questionId), {
                orderedHintIds: hints.map((hint: HintListItem) => hint.id).join(",")
            })
            .then(() => {
                toast.success("Hints updated", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    return (
        <Stack
            tokens={ThemedMediumStackTokens}
            className={"section-background section-border overflowy-auto height-70vh"}>
            <FixedHeaderSortingDetailsList
                onAddButtonClick={() => {
                    setAddEditHintModalDisplay(true);
                    setSelectedHintId(-1);
                    setHintText("");
                    setScorePenalty("");
                }}
                hideAddNewButton={!(permission && permission.includes("add"))}
                isListDraggable={true}
                buttonLabel={"Add Hint"}
                isAddButtonDisabled={hintList.length > 2}
                hideSearchField={true}
                columns={getColumns()}
                isLoading={isLoading}
                items={hintList}
                onRenderItemColumn={renderItemColumn}
                key={listkey}
                getTransformedItems={(items: HintListItem[]) => saveListOrder(items)}
            />
            <DeleteConfirmationDialog
                entryName={"hint"}
                isShown={showDeleteConfirmationDialog}
                onConfirm={onDeleteHint}
                onDismiss={() => {
                    setSelectedHintId(-1);
                    setDeleteConfirmationDialogDisplay(false);
                }}
            />
            <Modal
                isOpen={showAddEditHintModal}
                onDismiss={onDismissModal}
                className={"hints-modal"}
                isBlocking={false}>
                <QuestionHintDetailsView
                    closeModal={onDismissModal}
                    hintId={selectedHintId}
                    questionId={questionId}
                    closeModalAndRefreshList={onCloseModalAndRefreshList}
                    hintTextProp={hintText}
                    scorePenaltyProp={scorePenalty}
                    timePenaltyProp={timePenalty}
                />
            </Modal>
        </Stack>
    );
};

export default QuestionHintsListView;

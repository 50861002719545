import { toast } from "react-toastify";
import { ApiConstants } from "../../../../../../constants/ApiConstant";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../../utils/APIErrorMessages";
import { Client } from "../../../../../Base/Client";

const transformData = (data: any) => {
    if (!data) {
        return [];
    }
    return data.map((item: any, index: number) => ({
        index: index + 1,
        id: item.id,
        requestedDateTime: item.requestedDateTime,
        status: item.status,
        uploadUrl: item.uploadUrl,
        uploadPercentage: item.uploadPercentage || ''
    }));
};

export const getDownloadEventList = (eventId: string) => {
    return Client.getInstance()
        .getData(ApiConstants.getEventDownloadFileListApiUrl(eventId), true)
        .then((res) => Promise.resolve(transformData(res.data.message)))
        .catch((err) => {
            toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const getAllDownloadEventList = () => {
    return Client.getInstance()
        .getData(ApiConstants.getAllEventDownloadFileListApiUrl(), true)
        .then((res) => Promise.resolve(transformData(res.data.message)))
        .catch((err) => {
            toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const DownloadStatusInfoOptions: Record<
    string,
    {
        label: string;
        color: string;
    }
> = {
    completed: {
        label: "Complete",
        color: "#65B44A"
    },
    started: {
        label: "In-progress",
        color: "#F2C94C"
    }
};

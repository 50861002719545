export const GeographyJambarQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "Seems like you didn't pay attention in geography class.",
            imageSrc: "https://i.imgur.com/tccvGSl.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Looks like you've travelled a bit.",
            imageSrc: "https://i.imgur.com/cqcYs9e.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Awesome! Indiana Jones would be proud.",
            imageSrc: "https://i.imgur.com/XnYtpDE.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "Which continent is coloured yellow?",
            questionImageSrc: "https://i.imgur.com/dr7Vxm6.jpg",
            answerDescription:
                "Did you know that there are many tropical islands in Africa? They are famous for their beautiful beaches and rich marine life.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Europe",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. South America",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Africa",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Antarctica",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "What country’s flag is this?",
            questionImageSrc: "https://i.imgur.com/kpOA1e8.jpg",
            answerDescription:
                "Panama is part of the isthmus that connects South America to Central America. The Panama Canal runs horizontally through the country, connecting the Pacific and Atlantic oceans.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Ethiopia",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Chile",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Argentina",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Panama",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Stockholm is the capital of which country?",
            questionImageSrc: "https://i.imgur.com/XQqt2Ui.jpg",
            answerDescription: "Did you know that Stockholm is made up of 14 islands?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Sweden",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. France",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Scotland",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Ireland",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "Which one of these countries is not a kingdom?",
            questionImageSrc: "https://i.imgur.com/kMUWCyB.jpg",
            answerDescription: "Greece was a kingdom until 1973. It is now a democratic republic.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Spain",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Greece",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Sweden",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Denmark",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Name the European country coloured in red.",
            questionImageSrc: "https://i.imgur.com/5PmKYmb.jpg",
            answerDescription:
                "Did you know that the infamous volcano Mount Vesuvius is located in Naples, Italy’s third-largest city?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Italy",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Ireland",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Russia",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Germany",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "Which country is the largest by area?",
            questionImageSrc: "https://i.imgur.com/rieOgJa.jpg",
            answerDescription:
                "Although Russia is the largest country by area, only around 2 percent of the Earth’s population live there.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Mexico",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Russia",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. England",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. China",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "In what country will you find this structure?",
            questionImageSrc: "https://i.imgur.com/jd5P2t2.jpg",
            answerDescription:
                "If you take a closer look, you will see that this structure, the Meenakshi Amman Temple is covered with small, brightly coloured statues synonymous with Hinduism.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Scotland",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Romania",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. India",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Spain",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "This is the national flag of what African country? ",
            questionImageSrc: "https://i.imgur.com/OV330Bo.jpg",
            answerDescription: "One of Ghana’s most famous exports is its colourful Kente cloth. ",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. South Africa",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Algeria",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Botswana",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Ghana",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "What is this geographical feature called?",
            questionImageSrc: "https://i.imgur.com/q5Prrm6.jpg",
            answerDescription: "A fault line shows the location of a fault or crack between two rock masses.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. geyser",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. volcano",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. stratum line",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. fault line",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "How many countries do the Alps extend across?",
            questionImageSrc: "https://i.imgur.com/DEAkmPX.jpg",
            answerDescription:
                "The Alps mountain range is present in 8 countries, including Italy, France, and Monaco.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 14",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 8",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. 2",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 3",
                    isCorrect: false
                }
            ]
        }
    ]
};

import { Grid, Divider } from "@mui/material";
import { FC } from "react";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { whiteColor } from "../../../../../constants/Styles";
import { CaptionText } from "../../../../CommonComponent/v2/CommonStyle";
import { GameButton } from "../../eventmanager/addeventdetail/Style";
import { gameOptions } from "../../eventmanager/addeventdetail/Util";
import { GameSettingsType } from "./Type";

type GameSettingsProps = {
    gameSettingInfo: GameSettingsType;
};
const GameSettings: FC<GameSettingsProps> = ({ gameSettingInfo }) => {
    const selectedGameType = gameOptions().find((item) => item.value === (gameSettingInfo.gameType || ""));

    const renderRightSection = () => {
        if (gameSettingInfo.gameType === SiteConstants.wildgoat) {
            return null;
        }

        return (
            <>
                <Grid item mt={"30px"}>
                    <CaptionText color={"#333333"} fontWeight={700}>
                        Answer result image
                    </CaptionText>
                    <Grid mt={"10px"}>
                        <img
                            width={"100%"}
                            style={{
                                height: 250,
                                objectFit: "cover",
                                borderRadius: 20
                            }}
                            src={gameSettingInfo.answerResultImage}
                            alt="Image"
                        />
                    </Grid>
                    <Grid item mt={"30px"}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Answer result text
                        </CaptionText>
                        <CaptionText gap={"10px"} display={"flex"}>
                            <span
                                style={{ color: "#333333" }}
                                dangerouslySetInnerHTML={{
                                    __html: gameSettingInfo.answerResultText
                                }}
                            />
                        </CaptionText>
                    </Grid>
                    <Grid mt={"30px"} item>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Game time limit
                        </CaptionText>
                        <CaptionText gap={"10px"} display={"flex"}>
                            {gameSettingInfo.gameTimeLimit.minutes} Minutes : {gameSettingInfo.gameTimeLimit.seconds}{" "}
                            Seconds
                        </CaptionText>
                    </Grid>
                    {gameSettingInfo.gameType === SiteConstants.spaceGameQuiz && (
                        <Grid mt={"30px"} item>
                            <CaptionText color={"#333333"} fontWeight={700}>
                                Next button text
                            </CaptionText>
                            <CaptionText gap={"10px"} display={"flex"}>
                                {gameSettingInfo.nextButtonText}
                            </CaptionText>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    };

    return (
        <Grid container mt={"15px"} spacing={"20px"}>
            <Grid item xs={3}>
                <Grid>
                    <CaptionText color={"#333333"} fontWeight={700}>
                        Game type
                    </CaptionText>
                </Grid>
                <GameButton mt={"20px"} xs={7} textAlign={"center"} item isactive={true}>
                    {selectedGameType?.inActiveicon}
                    <CaptionText mt={"5px"} color={whiteColor} textAlign={"center"}>
                        {selectedGameType?.title}
                    </CaptionText>
                </GameButton>
                {renderRightSection()}
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={"10px"}>
                    <Grid item xs={12}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            About game
                        </CaptionText>
                    </Grid>
                    <Grid item mt={"10px"} xs={12}>
                        <CaptionText gap={"10px"} display={"flex"}>
                            Title(for admin):{" "}
                            <span
                                style={{ color: "#333333" }}
                                dangerouslySetInnerHTML={{
                                    __html: gameSettingInfo.aboutGame.titleAdmin
                                }}
                            />
                        </CaptionText>
                    </Grid>
                    <Grid item xs={12}>
                        <CaptionText gap={"10px"} display={"flex"}>
                            Title(In game):{" "}
                            <span
                                style={{ color: "#333333" }}
                                dangerouslySetInnerHTML={{
                                    __html: gameSettingInfo.aboutGame.titleGame
                                }}
                            />
                        </CaptionText>
                    </Grid>
                    <Grid item xs={12}>
                        <CaptionText mt={"10px"}>
                            Visibility type:{" "}
                            <span style={{ color: "#333333" }}>{gameSettingInfo.aboutGame.visibilityType}</span>
                        </CaptionText>
                    </Grid>
                    {gameSettingInfo.gameType !== SiteConstants.wildgoat && (
                        <Grid item xs={12}>
                            <CaptionText mt={"10px"}>
                                Skipping allowed:{" "}
                                <span style={{ color: "#333333" }}>{gameSettingInfo.aboutGame.skippingAllowed}</span>
                            </CaptionText>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <CaptionText mt={"10px"}>
                            Show individual score:{" "}
                            <span style={{ color: "#333333" }}>{gameSettingInfo.aboutGame.showIndividualScore}</span>
                        </CaptionText>
                    </Grid>
                    {gameSettingInfo.gameType === SiteConstants.wildgoat && (
                        <Grid item xs={12}>
                            <CaptionText mt={"10px"}>
                                Total score visibility:{" "}
                                <span style={{ color: "#333333" }}>{gameSettingInfo.totalScoreVisibility}</span>
                            </CaptionText>
                        </Grid>
                    )}
                    {gameSettingInfo.gameType !== SiteConstants.wildgoat && (
                        <>
                            <Grid item xs={12}>
                                <CaptionText mt={"10px"}>
                                    Show progress in percentage:{" "}
                                    <span style={{ color: "#333333" }}>
                                        {gameSettingInfo.aboutGame.showProgressInPercentage}
                                    </span>
                                </CaptionText>
                            </Grid>
                            <Grid item xs={12}>
                                <CaptionText mt={"10px"}>
                                    Show main timer:{" "}
                                    <span style={{ color: "#333333" }}>{gameSettingInfo.aboutGame.showMainTimer}</span>
                                </CaptionText>
                            </Grid>
                            <Grid item xs={12}>
                                <CaptionText mt={"10px"}>
                                    Show hint count:{" "}
                                    <span style={{ color: "#333333" }}>{gameSettingInfo.aboutGame.showHintCount}</span>
                                </CaptionText>
                            </Grid>
                            <Grid item xs={12}>
                                <CaptionText mt={"10px"}>
                                    Show correct answer count:{" "}
                                    <span style={{ color: "#333333" }}>
                                        {gameSettingInfo.aboutGame.showCorrectAnswerCount}
                                    </span>
                                </CaptionText>
                            </Grid>
                            <Grid item xs={12}>
                                <CaptionText mt={"10px"}>
                                    Show wrong answer count:{" "}
                                    <span style={{ color: "#333333" }}>
                                        {gameSettingInfo.aboutGame.showWrongAnswerCount}
                                    </span>
                                </CaptionText>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Divider
                    style={{
                        margin: "20px 0px"
                    }}
                />
                {gameSettingInfo.gameType === SiteConstants.spaceGameQuiz && (
                    <Grid container spacing={"10px"}>
                        <Grid item xs={12}>
                            <CaptionText color={"#333333"} fontWeight={700}>
                                Connector game
                            </CaptionText>
                            <CaptionText mt={"10px"}>
                                Connector game status:{" "}
                                <span style={{ color: "#333333" }}>{gameSettingInfo.connectorQuiz}</span>
                            </CaptionText>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider
                                style={{
                                    margin: "20px 0px"
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={"10px"}>
                    <Grid item xs={12}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Game points
                        </CaptionText>
                    </Grid>
                    <Grid item xs={12}>
                        <CaptionText mt={"10px"}>
                            Initial game points:{" "}
                            <span style={{ color: "#333333" }}>{gameSettingInfo.gamePoints.initialGamePoints}</span>
                        </CaptionText>
                    </Grid>
                    <Grid item xs={12}>
                        <CaptionText mt={"10px"}>
                            Correct answer points increment:{" "}
                            <span style={{ color: "#333333" }}>{gameSettingInfo.gamePoints.correctAnswerPoints}</span>
                        </CaptionText>
                    </Grid>
                    <Grid item xs={12}>
                        <CaptionText mt={"10px"}>
                            Wrong answer points deduction:{" "}
                            <span style={{ color: "#333333" }}>{gameSettingInfo.gamePoints.wrongAnswerPoints}</span>
                        </CaptionText>
                    </Grid>
                    {gameSettingInfo.gameType !== SiteConstants.wildgoat && (
                        <Grid item xs={12}>
                            <CaptionText mt={"10px"}>
                                Skip answer points deduction:{" "}
                                <span style={{ color: "#333333" }}>{gameSettingInfo.gamePoints.skipAnswerPoint}</span>
                            </CaptionText>
                        </Grid>
                    )}
                </Grid>
                <Divider
                    style={{
                        margin: "20px 0px"
                    }}
                />

                {gameSettingInfo.gameType === SiteConstants.wildgoat && (
                    <Grid container spacing={"10px"}>
                        <Grid item xs={12}>
                            <CaptionText color={"#333333"} fontWeight={700}>
                                Total points text
                            </CaptionText>
                        </Grid>
                        <Grid item xs={12}>
                            <CaptionText
                                dangerouslySetInnerHTML={{
                                    __html: gameSettingInfo.totalPointsText
                                }}
                                color={"#333333"}
                                fontWeight={400}
                            />
                        </Grid>
                        <Divider
                            style={{
                                margin: "20px 0px"
                            }}
                        />
                    </Grid>
                )}
                <Grid container spacing={"10px"}>
                    <Grid item xs={12}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Game summary
                        </CaptionText>
                    </Grid>
                    <Grid item xs={12}>
                        <CaptionText
                            dangerouslySetInnerHTML={{
                                __html: gameSettingInfo.gameSummary
                            }}
                            color={"#333333"}
                            fontWeight={400}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Grid container>
                    <Grid item xs={6}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Created by
                        </CaptionText>
                        <CaptionText>
                            {gameSettingInfo.createdBy.firstName} {gameSettingInfo.createdBy.lastName}
                        </CaptionText>
                    </Grid>
                    <Grid item xs={6}>
                        <CaptionText color={"#333333"} fontWeight={700}>
                            Updated by
                        </CaptionText>
                        <CaptionText>
                            {gameSettingInfo.updateBy.firstName} {gameSettingInfo.updateBy.lastName}
                        </CaptionText>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GameSettings;

import {
    IColumn,
    IconButton,
    IDropdownOption,
    Label,
    Panel,
    PanelType,
    PrimaryButton,
    StackItem
} from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import QuestionDetailView from "./QuestionDetailPanel/QuestionDetailView";
import { Client } from "../../Base/Client";
import { ApiConstants } from "../../../constants/ApiConstant";
import { toast, ToastContainer } from "react-toastify";
import { SiteConstants } from "../../../constants/SiteConstant";
import DeleteConfirmationDialog from "../../CommonComponent/DeleteConfirmationDialog";
import {
    MCQ,
    MCQAnswerOption,
    questionInfo,
    Question,
    QuestionTags,
    usageListType,
    bulkQuestionUsageListType
} from "../../../data/question/types";
import { JambarDateUtil } from "../../../constants/JambarDateUtils";
import ConfirmationDialog from "../../CommonComponent/ConfirmationDialog";
import { getDropdownFilterOptions, statusDropdownOptions } from "../../../constants/DropdownOption";
import { QuizQuestionListItem } from "../Quiz/QuizListDataTypes";
import { filterQuestions } from "../../../utils/QuestionBankListViewUtils";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import FixedHeaderSortingDetailsList from "../../CommonComponent/FixedHeaderSortingDetilsList";
import { extractHtmlContent } from "../../../utils/JambarUtils";
import QuestionBatchDelete from "./QuestionBatchDelete";
import ToggleFieldWrapper from "../../CommonComponent/ToggleFieldWrapper";
import { transformBulkUsageList } from "./QuestionDetailPanel/util";
import BatchDeleteConfirmationDialog from "../../CommonComponent/BatchDeleteConfirmationDialog";
import { debounce } from "lodash";
import { useAdminContext } from "../context/AdminAuthContext";
import { getPermissions } from "../../../utils/PermissionUtils";
import { RiGitRepositoryPrivateFill, RiGlobalLine } from "react-icons/ri";
import { SiRelianceindustrieslimited } from "react-icons/si";
import ConfirmationClose from "../../CommonComponent/ConfirmationClose";

type IQuestionBankListViewNativeProps = {
    onSelectQuestion?: (item: QuizQuestionListItem) => void;
    inSearchMode?: boolean;
    quizQuestionList?: QuizQuestionListItem[];
    getUpdatedQuizQuestion?: (question: QuizQuestionListItem) => void;
    isSocketQuiz?: boolean;
};

const QuestionBankListView: React.FC<IQuestionBankListViewNativeProps> = ({
    inSearchMode,
    onSelectQuestion,
    quizQuestionList,
    getUpdatedQuizQuestion,
    isSocketQuiz
}) => {
    const [searchText, setSearchText] = useState("");
    const [questionList, setQuestionList] = useState<Question[]>([]);
    const [showAddEditQuestionPanel, setAddEditQuestionPanelDisplay] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState(-1);
    const [isListLoading, setIsListLoading] = useState(false);
    const [showDeleteConfirmationDialog, setDeleteConfirmationDialogDisplay] = useState(false);
    const [deleteEntryName, setDeleteEntryName] = useState("");
    const [selectedTag, setTagFilter] = useState(-1);
    const [showCloneConfirmationDialog, setCloneConfirmationDialogDisplay] = useState(false);
    const [tagsOption, setTagsOption] = useState<IDropdownOption[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<string>("active");
    const [usageList, setUsageList] = useState<usageListType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalQuestions, setTotalQuestions] = useState<number>(0);
    const [batchDelete, setBatchDelete] = useState<number[]>([]);
    const [batchDeleteAll, setBatchDeleteAll] = useState<boolean>(false);
    const [showDeleteBatchConfirm, setShowDeleteBatchConfirm] = useState<boolean>(false);
    const [batchUsageList, setBatchUsageList] = useState<bulkQuestionUsageListType[]>([]);
    const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
    const [questionItems, setQuestionItems] = useState<Question[]>([]);
    const [accessibilityScope, setAccessibitlyScope] = useState("");
    const [closeConfirmationDialog, setCloseConfirmationDialog] = useState<boolean>(false);

    const { userDetail } = useAdminContext();

    const permission: string[] | null = getPermissions(userDetail, "question");

    useEffect(() => {
        fetchData(-1, selectedStatus);
    }, [selectedStatus]);

    useEffect(() => {
        if (batchDeleteAll) {
            const questionIds = questionList.map((item) => item.itemId);
            setBatchDelete(questionIds);
            return;
        }
        setBatchDelete([]);
    }, [batchDeleteAll]);

    const handleDebounceSearchText = useCallback(
        debounce((text) => {
            setDebouncedSearchText(text);
        }, 500),
        []
    );

    useEffect(() => {
        const filteredQUestionItems = filterQuestions(searchText, selectedTag, questionList);
        setQuestionItems(filteredQUestionItems);
        setTotalQuestions(filteredQUestionItems.length);
    }, [selectedTag, debouncedSearchText, questionList]);

    const fetchData = (questionId = -1, status = "all"): void => {
        setIsListLoading(true);
        fetchTagsDropdownData();
        if (!(permission && permission.includes("fetch"))) {
            setQuestionList([]);
            setIsListLoading(false);
            return;
        }
        Client.getInstance()
            .getData(ApiConstants.getQuestionsListApiUrl(status), true)
            .then((response: any) => {
                const questions: Question[] = [];
                response.data.data.map((element: any) => questions.push(transformData(element)));
                setQuestionList(
                    isSocketQuiz
                        ? questions.filter(
                              (item: Question) => item.questionInfo.questionType !== SiteConstants.connectorQuestion
                          )
                        : questions
                );
                setIsListLoading(false);
                questionId !== -1 && getUpdatedQuizQuestion && refreshQuizQuestion(questions, questionId);
            });
    };

    const refreshQuizQuestion = (questions: Question[], questionId: number): void => {
        const updatedQuestion = questions.find((question: Question) => question.itemId === questionId);
        updatedQuestion &&
            getUpdatedQuizQuestion &&
            getUpdatedQuizQuestion({
                id: updatedQuestion.itemId,
                questionTitle: updatedQuestion.title,
                tags: updatedQuestion.tags
            });
    };

    const fetchTagsDropdownData = (forceRefresh = false): void => {
        Client.getInstance()
            .getData(ApiConstants.getTagsListApiUrl(), forceRefresh)
            .then((response: any) => {
                const tagsDropdownData: IDropdownOption[] = response.data.data.map((tag: any) => ({
                    id: tag.id,
                    key: tag.id,
                    text: tag.value
                }));
                setTagsOption(tagsDropdownData);
            });
    };

    const fetchQuestionUsage = (forceRefresh = true, id: number): void => {
        Client.getInstance()
            .getData(ApiConstants.getQuestionUsageApiUrl(id), forceRefresh)
            .then((response) => {
                setUsageList(response.data.data.quiz);
                setLoading(false);
            });
    };

    const fetchBulkQuestionUsage = (ids: number[]): void => {
        setLoading(true);
        setDeleteEntryName("question");
        setShowDeleteBatchConfirm(true);
        const questionIds = ids.join(";");
        Client.getInstance()
            .getData(ApiConstants.getQuestionUsageBulkApiUrl(questionIds), true)
            .then((response) => {
                response.data.data.length !== 0 &&
                    setBatchUsageList(transformBulkUsageList(response.data.data, questionList));
                setLoading(false);
            });
    };

    const deleteQuestion = (id: number): void => {
        setLoading(true);
        fetchQuestionUsage(true, id);
        setDeleteConfirmationDialogDisplay(true);
        setSelectedQuestionId(id);
        setDeleteEntryName("question");
    };

    const getTransformedMcqOptions = (options: string): MCQAnswerOption[] => {
        const mcqOptions = JSON.parse(options);
        const transformedMcqOptions = mcqOptions.map((element: any) => {
            return {
                text: element.text,
                id: element.id
            } as MCQAnswerOption;
        });
        return transformedMcqOptions;
    };

    const transformData = (result: any): Question => {
        return {
            title: extractHtmlContent(result.title),
            itemId: result.id,
            index: result.id,
            accessibilityScope: result.accessibilityScope,
            tags: result.questionTags.map((tag: any) => ({ id: tag.tag.id, value: tag.tag.value })),
            createdBy: `${result.createdByUser.firstName} ${result.createdByUser.lastName}`,
            createdDate: JambarDateUtil.formatDate(result.createdAt),
            lastUpdatedBy: `${result.updatedByUser.firstName} ${result.updatedByUser.lastName}`,
            lastUpdatedDate: JambarDateUtil.formatDate(result.updatedAt),
            questionInfo: {
                itemId: result.id,
                imagePath: result.fileLocation,
                questionType: result.type,
                questionText: result.questionText,
                correctAnswer: result.answer,
                mcqAnswerOptions: result.type === MCQ ? getTransformedMcqOptions(result.mcqOptions) : [],
                tags: result.questionTags.map((tag: any) => ({ id: tag.tag.id, value: tag.tag.value })),
                timeLimit: result.timeLimit,
                wrongPoints: result.wrongPoints,
                wrongAnswerText: result.wrongAnswerText,
                correctAnswerText: result.correctAnswerText,
                correctPoints: result.correctPoints,
                maxAttempts: result.maxAttempts,
                skipPoints: result.skipPoints,
                correctOptions: result.mcqAnswers
                    ? result.mcqAnswers.split(",").map((element: string) => parseFloat(element))
                    : [],
                skippingAllowed: result.allowSkipping,
                freeTextCorrectAnswerType: result.answerType,
                questionTitle: result.title,
                isVideoQuestion: result.videoQuestionFileLocation ? true : false
            } as questionInfo
        };
    };

    const onConfirmDelete = (): void => {
        Client.getInstance()
            .deleteData(ApiConstants.deleteQuestionApiUrl(selectedQuestionId))
            .then(() => {
                setDeleteConfirmationDialogDisplay(false);
                toast.success("Question deleted", SiteConstants.deleteToastConfiguration);
                setSelectedQuestionId(-1);
                setDeleteEntryName("");
                fetchData(-1, selectedStatus);
            })
            .catch((error) => {
                setSelectedQuestionId(-1);
                setDeleteEntryName("");
                setDeleteConfirmationDialogDisplay(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const onConfirmBatchDelete = () => {
        const questionIds = batchDelete.join(";");
        Client.getInstance()
            .deleteData(ApiConstants.deleteQuestionsBulkApiUrl(questionIds))
            .then(() => {
                setShowDeleteBatchConfirm(false);
                toast.success("Questions in batch deleted", SiteConstants.deleteToastConfiguration);
                setDeleteEntryName("");
                setBatchUsageList([]);
                fetchData(-1, selectedStatus);
            })
            .catch((error) => {
                setDeleteEntryName("");
                setDeleteConfirmationDialogDisplay(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const onCancelDelete = (): void => {
        setDeleteConfirmationDialogDisplay(false);
        setSelectedQuestionId(-1);
        setDeleteEntryName("");
    };

    const getTagsSpan = (tags: QuestionTags[]): JSX.Element[] => {
        let tagsElement = tags.map((tag) => (
            <span key={tag.id} className={"quiz-question-tag-container"}>
                {tag.value}
            </span>
        ));
        if (tagsElement.length > 3) {
            tagsElement = tagsElement.slice(0, 3);
            tagsElement.push(<span> + {tags.length - 3} More</span>);
            return tagsElement;
        }
        return tagsElement;
    };

    const onCloneQuestion = (id: number): void => {
        setSelectedQuestionId(id);
        setCloneConfirmationDialogDisplay(true);
    };

    const onConfirmQuestionClone = (): void => {
        Client.getInstance()
            .createData(ApiConstants.getCloneQuestionApiUrl(selectedQuestionId), {})
            .then((response: any) => {
                setCloneConfirmationDialogDisplay(false);
                setSelectedQuestionId(response.data.data.id);
                setAddEditQuestionPanelDisplay(true);
                toast.success("Challenge cloned", SiteConstants.successToastConfiguration);
                fetchData(-1, selectedStatus);
            })
            .catch((error) => {
                setSelectedQuestionId(-1);
                setCloneConfirmationDialogDisplay(false);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const onAddQuestionToQuiz = (item: questionInfo): void => {
        if (item.isVideoQuestion && !isSocketQuiz) {
            toast.error("Video question can only be added in a socket game", SiteConstants.deleteToastConfiguration);
            return;
        }
        onSelectQuestion &&
            onSelectQuestion({
                id: item.itemId,
                questionTitle: item.questionTitle,
                tags: item.tags
            });
    };

    const onCloseAddQuestionPanel = (id: number): void => {
        setAddEditQuestionPanelDisplay(true);
        setSelectedQuestionId(id);
        fetchData(-1, selectedStatus);
    };

    const questionListColumns: IColumn[] = inSearchMode
        ? [
              {
                  key: "quickAdd",
                  name: "",
                  fieldName: "quickAdd",
                  minWidth: SiteConstants.listColumnIcon,
                  maxWidth: SiteConstants.listColumnIcon
              },
              {
                  key: "title",
                  name: "Title",
                  fieldName: "title",
                  minWidth: SiteConstants.listColumn300
              },
              {
                  key: "edit",
                  name: "",
                  fieldName: "edit",
                  minWidth: SiteConstants.listColumnIcon,
                  maxWidth: SiteConstants.listColumnIcon
              }
          ]
        : [
              {
                  key: "serialNumber",
                  name: "",
                  fieldName: "serialNumber",
                  minWidth: SiteConstants.listColumnSerialNumber,
                  maxWidth: SiteConstants.listColumnSerialNumber
              },
              {
                  key: "title",
                  name: "Title",
                  fieldName: "title",
                  minWidth: SiteConstants.listColumn300
              },
              {
                  key: "createdBy",
                  name: "Created By",
                  fieldName: "createdBy",
                  minWidth: SiteConstants.listColumnSmall,
                  maxWidth: SiteConstants.listColumnMedium
              },
              {
                  key: "lastUpdatedBy",
                  name: "Updated By",
                  fieldName: "lastUpdatedBy",
                  minWidth: SiteConstants.listColumnSmall,
                  maxWidth: SiteConstants.listColumnMedium
              }
          ];

    const questionMenuItems = [
        {
            key: "clone",
            name: "",
            fieldName: "clone",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "update",
            name: "",
            fieldName: "edit",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "delete",
            name: "",
            fieldName: "delete",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon,
            className: "info-color"
        }
    ];

    const renderVisibityIcon = (type: string) => {
        if (type === "private") {
            return <RiGitRepositoryPrivateFill style={{ marginLeft: "10px", fontSize: 14 }} />;
        }
        if (type === "global") {
            return <RiGlobalLine style={{ marginLeft: "10px", fontSize: 14 }} />;
        }

        return <SiRelianceindustrieslimited style={{ marginLeft: "10px", fontSize: 14 }} />;
    };
    const renderItemColumn = (item: Question, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "serialNumber":
                return (
                    <QuestionBatchDelete
                        batchDeleteAll={batchDeleteAll}
                        itemId={item.itemId}
                        index={index}
                        setBatchDelete={(value) => setBatchDelete(value)}
                        batchDelete={batchDelete}
                    />
                );

            case "clone":
                return (
                    <IconButton
                        iconProps={{ iconName: "DependencyAdd" }}
                        title="Duplicate"
                        onClick={() => onCloneQuestion(item.itemId)}
                    />
                );

            case "title":
                return (
                    <>
                        <span className={"margin-bottom-10 display-block"}>
                            {extractHtmlContent(item.title)}
                            {renderVisibityIcon(item.accessibilityScope)}
                        </span>
                        {item.tags && getTagsSpan(item.tags)}
                    </>
                );
            case "createdBy":
                return (
                    <span>
                        {item.createdBy} <br />
                        on <i>{item.createdDate}</i>
                    </span>
                );
            case "lastUpdatedBy":
                return (
                    <span>
                        {item.lastUpdatedBy} <br />
                        on <i>{item.lastUpdatedDate}</i>
                    </span>
                );
            case "update":
                return (
                    <IconButton
                        iconProps={{ iconName: "Edit" }}
                        title="Edit"
                        onClick={() => {
                            item.itemId && setSelectedQuestionId(item.itemId);
                            item.accessibilityScope && setAccessibitlyScope(item.accessibilityScope);
                            item.itemId && setAddEditQuestionPanelDisplay(true);
                        }}
                    />
                );
            case "quickAdd":
                return (
                    <span className={"quickAddSpanTc"}>
                        {quizQuestionList &&
                        quizQuestionList.find((element) => element.id === item.questionInfo.itemId) ? (
                            <IconButton
                                className={"selectedQuestionTc"}
                                styles={SiteConstants.correctOptionIconColor}
                                iconProps={{ iconName: "Accept" }}
                            />
                        ) : (
                            <IconButton
                                className={"addQuestionTc"}
                                iconProps={{ iconName: "Add" }}
                                title="Add"
                                onClick={() => {
                                    onAddQuestionToQuiz(item.questionInfo);
                                }}
                            />
                        )}
                    </span>
                );
            case "delete":
                return (
                    <IconButton
                        iconProps={{ iconName: "delete" }}
                        title="Delete"
                        onClick={() => item.itemId && deleteQuestion(item.itemId)}
                    />
                );
            default: {
                const fieldContent = item[column.fieldName as keyof Question] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    const QuestionsCount = () => {
        return (
            <StackItem grow={120}>
                <div className="flex-center">
                    <Label className="label inline-label  margin-right-5">Total Questions:</Label>
                    {totalQuestions}
                </div>
            </StackItem>
        );
    };

    const getColumns = () => {
        if (permission) {
            return [
                ...questionListColumns,
                ...questionMenuItems.filter((column) => permission && permission.includes(column.key))
            ];
        }
        return questionListColumns;
    };

    const closePanel = () => {
        setAddEditQuestionPanelDisplay(false);
        setSelectedQuestionId(-1);
        fetchTagsDropdownData();
        setAccessibitlyScope("");
    };

    return (
        <>
            <ToastContainer />
            <Panel
                isLightDismiss
                isOpen={showAddEditQuestionPanel}
                onDismiss={() => {
                    selectedQuestionId !== -1 ? closePanel() : setCloseConfirmationDialog(true);
                }}
                headerText={selectedQuestionId === -1 ? "Add Question" : "Edit Question"}
                type={PanelType.large}>
                <QuestionDetailView
                    questionId={selectedQuestionId}
                    accessibilityScope={accessibilityScope}
                    closeAddQuestionPanel={(id) => onCloseAddQuestionPanel(id)}
                    fetchQuestionList={(id) => fetchData(id, selectedStatus)}
                />
            </Panel>
            {permission && permission.includes("delete") && (
                <div className="search-section padding-10 flex-gap">
                    <ToggleFieldWrapper
                        label="Check All"
                        checked={batchDeleteAll}
                        onChange={(value: boolean) => setBatchDeleteAll(value)}
                        inlineLabel={true}
                        removeMargin
                    />
                    <PrimaryButton text={"Batch Delete"} onClick={() => fetchBulkQuestionUsage(batchDelete)} />
                </div>
            )}
            <FixedHeaderSortingDetailsList
                onSearchTextChange={(text: string) => {
                    setSearchText(text);
                    handleDebounceSearchText(text);
                }}
                searchText={searchText}
                onAddButtonClick={() => {
                    setAddEditQuestionPanelDisplay(true);
                    setSelectedQuestionId(-1);
                }}
                hideAddNewButton={inSearchMode || !(permission && permission.includes("add"))}
                showDropdownFilter={true}
                dropdownOptions={getDropdownFilterOptions(tagsOption)}
                selectedDropdownKey={selectedTag}
                onDropdownValueChange={(value: string) => setTagFilter(parseInt(value))}
                searchFieldWithoutLabel={inSearchMode}
                columns={getColumns()}
                isLoading={isListLoading}
                items={questionItems}
                onRenderItemColumn={renderItemColumn}
                statusField={true}
                statusDropdownOptions={statusDropdownOptions}
                selectedStatusKey={selectedStatus}
                onStatusValueChange={(value: string) => setSelectedStatus(value)}
                filterLabel="Tags"
                totalCount={QuestionsCount()}
            />
            <DeleteConfirmationDialog
                entryName={deleteEntryName}
                isShown={showDeleteConfirmationDialog}
                onConfirm={onConfirmDelete}
                onDismiss={onCancelDelete}
                usageList={usageList}
                loading={loading}
                usedBy="quizes"
            />
            <BatchDeleteConfirmationDialog
                entryName={deleteEntryName}
                isShown={showDeleteBatchConfirm}
                onConfirm={onConfirmBatchDelete}
                onDismiss={() => {
                    setShowDeleteBatchConfirm(false);
                    setDeleteEntryName("");
                    setBatchUsageList([]);
                }}
                usageList={batchUsageList}
                loading={loading}
                hideButton={batchUsageList.length === 0}
                usedBy="quizes"
            />
            <ConfirmationDialog
                entryName={"question"}
                dialogHeader={"Confirm Clone"}
                isShown={showCloneConfirmationDialog}
                onConfirm={onConfirmQuestionClone}
                actionName={"clone"}
                onDismiss={() => {
                    setSelectedQuestionId(-1);
                    setCloneConfirmationDialogDisplay(false);
                }}
            />
            <ConfirmationClose
                warningText="You have unsaved changes. Are you sure you want to close this?"
                dialogHeader={"Unsaved Changes"}
                isShown={closeConfirmationDialog}
                onConfirm={() => {
                    setCloseConfirmationDialog(false);
                    closePanel();
                }}
                onDismiss={() => {
                    setCloseConfirmationDialog(false);
                }}
            />
        </>
    );
};

export default QuestionBankListView;

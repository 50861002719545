/* eslint-disable jsx-a11y/alt-text */
import { Grid, IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import { darkPrimaryColor } from "../../../../../../constants/ColorConstant";
import { CaptionText, TextButton } from "../../../../../CommonComponent/v2/CommonStyle";
import FormikTextInput from "../../../../../CommonComponent/v2/fields/FormikTextInput";
import FormikToggle from "../../../../../CommonComponent/v2/fields/FormikToggle";
import { QuestionDetailType } from "../Type";
import DeleteIcon from "../../../../../Images/icons/delete.svg";
import FormikSelect from "../../../../../CommonComponent/v2/fields/FormikSelect";
import { useConnectorQuestionHook } from "./Hooks";

const ConnectorQuestion = () => {
    const { values, setFieldValue } = useFormikContext();

    const data = values as QuestionDetailType;
    const { quizOptions } = useConnectorQuestionHook();

    return (
        <Grid container>
            <Grid mt={"30px"} item xs={12}>
                <FormikToggle
                    id={"answerDetails.connector.connectorTimer"}
                    name={"answerDetails.connector.connectorTimer"}
                    label={"Connector timer"}
                />
            </Grid>
            <Grid item xs={12} mt={"30px"}>
                <CaptionText mb={"10px"} color={darkPrimaryColor} fontWeight={700}>
                    Completion Trigger
                </CaptionText>
                <FormikTextInput
                    fullWidth
                    id={"answerDetails.connector.completionTrigger"}
                    name={"answerDetails.connector.completionTrigger"}
                    placeholder={"Quiz Score"}
                    label={"Quiz score"}
                />
            </Grid>
            <Grid item xs={12} mt={"30px"}>
                <Grid container spacing={"10px"}>
                    <Grid item xs={10}>
                        <CaptionText mb={"10px"} color={darkPrimaryColor} fontWeight={700}>
                            Connector options
                        </CaptionText>
                    </Grid>
                    <Grid item xs={2}>
                        <CaptionText mb={"10px"} color={darkPrimaryColor} fontWeight={700}>
                            Closable
                        </CaptionText>
                    </Grid>
                </Grid>
                {data.answerDetails.connector.connectorOptions.map((option, index) => (
                    <Grid container mt={"5px"} spacing={"10px"} alignItems={"flex-start"} key={index}>
                        <Grid item xs={5}>
                            <FormikTextInput
                                fullWidth
                                id={`answerDetails.connector.connectorOptions[${index}].option`}
                                name={`answerDetails.connector.connectorOptions[${index}].option`}
                                label={`Connector option ${index + 1}`}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormikSelect
                                options={quizOptions}
                                fullWidth
                                id={`answerDetails.connector.connectorOptions[${index}].connectingQuiz`}
                                name={`answerDetails.connector.connectorOptions[${index}].connectingQuiz`}
                                label={`Connector option ${index + 1}`}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            height={"64px"}>
                            <FormikToggle
                                id={`answerDetails.connector.connectorOptions[${index}].isClosable`}
                                name={`answerDetails.connector.connectorOptions[${index}].isClosable`}
                                label={""}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            height={"64px"}>
                            {index !== 0 && (
                                <IconButton
                                    onClick={() => {
                                        setFieldValue(
                                            "answerDetails.connector.connectorOptions",
                                            data.answerDetails.connector.connectorOptions.filter(
                                                (item) => item.id !== option.id
                                            )
                                        );
                                    }}>
                                    <img src={DeleteIcon} style={{ height: 18, cursor: "pointer" }} />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Grid container mt={"20px"} justifyContent={"center"}>
                        <TextButton
                            onClick={() =>
                                setFieldValue("answerDetails.connector.connectorOptions", [
                                    ...data.answerDetails.connector.connectorOptions,
                                    {
                                        id: Math.random(),
                                        option: "",
                                        connectingQuiz: "",
                                        isClosable: false
                                    }
                                ])
                            }>
                            + Add new connector option
                        </TextButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ConnectorQuestion;

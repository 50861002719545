import { SearchOutlined } from "@mui/icons-material";
import { Dialog, Grid } from "@mui/material";
import { FC, useState } from "react";
import LoadingButtonWrapper from "../../../CommonComponent/v2/button/LoadingButtonWrapper";
import { CancelButton, Header1, PrimaryButton } from "../../../CommonComponent/v2/CommonStyle";
import { CustomTextfield } from "../../../CommonComponent/v2/fields/FormikTextInput";
import LoadingWrapper from "../../../CommonComponent/v2/LoadingWrapper";
import BranchQuizList from "./BranchQuizList";
import { useAddNewBranchHook } from "./Hooks";
import { filterQuizList } from "./Util";
import TabWrapper from "../../../CommonComponent/v2/tab/Tab";
import BranchQuestionList from "./BranchQuestionList";
import { BranchQuestionListType } from "./Type";
import { BranchQuestionTabOption } from "../../../../constants/DropdownOption";
import { QUESTION_TAB } from "../../../../constants/SiteConstant";

type AddBranchQuestionProps = {
    handleClose: () => void;
    setQuestionList: (questionList: BranchQuestionListType[]) => void;
    setQuizName: (quizName: string) => void;
    quizType: string;
};
const AddBranchQuestion: FC<AddBranchQuestionProps> = ({ handleClose, setQuestionList, quizType }) => {
    const { quizList, isLoading, importQuestion, quizListLoading, selectedQuizId, selectedTab, setSelectedQuizId, setSelectedTab, selectedQuestionList,
        handleRemoveQuestion
    } = useAddNewBranchHook(quizType);
    const [searchText, setSearchText] = useState("");

    return (
        <Dialog open maxWidth={"md"} fullWidth onClose={handleClose}>
            <Grid container p={"30px"}>
                <Grid item xs={12}>
                    <Grid container alignItems={"center"} justifyContent={"Space-between"}>
                        <Grid item>
                            <Header1>
                                {
                                    selectedTab === QUESTION_TAB ? "Edit Question" : "Select QUiz"
                                }
                            </Header1>
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextfield
                                placeholder={selectedTab === QUESTION_TAB ? "Search Question" : "Search Quiz"}
                                name="search"
                                fullWidth
                                id="search"
                                value={searchText}
                                onChange={({ target }) => setSearchText(target.value)}
                                InputProps={{
                                    startAdornment: <SearchOutlined />
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={"10px"}>
                    <TabWrapper tabsOptions={BranchQuestionTabOption}
                        handleChange={setSelectedTab}
                        selectedTab={selectedTab}
                    />
                </Grid>
                {
                    selectedTab === QUESTION_TAB ? <LoadingWrapper isLoading={isLoading.questionList}>
                        <Grid item xs={12}>
                            <BranchQuestionList
                                questionList={selectedQuestionList}
                                handleRemoveQuestion={handleRemoveQuestion}
                            />
                        </Grid>
                    </LoadingWrapper> : <LoadingWrapper isLoading={isLoading.quizList}>
                        <Grid item xs={12}>
                            <BranchQuizList
                                quizList={filterQuizList(quizList, searchText)}
                                selectedQuizId={selectedQuizId}
                                setSelectedQuizId={setSelectedQuizId}
                            />
                        </Grid>
                    </LoadingWrapper>
                }
                <Grid item xs={12} mt={"30px"}>
                    <Grid container alignItems={"center"} spacing={"20px"} justifyContent={"center"}>
                        <Grid item>
                            <CancelButton onClick={handleClose}>Cancel</CancelButton>
                        </Grid>
                        <Grid item>
                            <LoadingButtonWrapper loading={quizListLoading}>
                                <PrimaryButton
                                    disabled={selectedQuizId.length === 0 || selectedQuizId === undefined}
                                    onClick={() => importQuestion(setQuestionList, handleClose)}
                                >
                                    Import Questions
                                </PrimaryButton>
                            </LoadingButtonWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default AddBranchQuestion;

import { Label, PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { useState, FC } from "react";
import { toast, ToastContainer } from "react-toastify";
import { SiteConstants } from "../../../constants/SiteConstant";
import { ThemedSmall2StackTokens } from "../../../constants/Styles";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import TextFieldWrapper from "../../CommonComponent/TextFieldWrapper";
import { addRole, updateRole } from "./Action";
import { RoleType } from "./Type";

type RoleDetailPanelProps = {
    role: RoleType | null;
    refreshRoleList: () => void;
};

const RoleDetailPanel: FC<RoleDetailPanelProps> = ({ role, refreshRoleList }) => {
    const [name, setName] = useState(role?.name || "");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const saveRole = () => {
        setIsSubmitting(true);
        if (role && role.id === -1) {
            addRole(name)
                .then(() => {
                    toast.success("Role added successfully", SiteConstants.successToastConfiguration);
                    refreshRoleList();
                })
                .catch((err: any) => {
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                });
            return;
        }
        role &&
            updateRole(name, role.id)
                .then(() => {
                    toast.success("Role updated successfully", SiteConstants.successToastConfiguration);
                    refreshRoleList();
                })
                .catch((err: any) => {
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                });
    };

    return (
        <Stack tokens={ThemedSmall2StackTokens}>
            <ToastContainer />
            <Stack.Item>
                <Label>Name</Label>
            </Stack.Item>
            <Stack.Item>
                <TextFieldWrapper value={name} onTextFieldValueChange={(value) => setName(value)} />
            </Stack.Item>
            <Stack horizontalAlign={"center"} style={{ marginTop: 10 }}>
                {isSubmitting ? (
                    <Spinner size={SpinnerSize.large} />
                ) : (
                    <PrimaryButton disabled={name === ''} iconProps={{ iconName: "Save" }} text={"Save"} onClick={saveRole} />
                )}
            </Stack>
        </Stack>
    );
};

export default RoleDetailPanel;

import { Pivot, PivotItem } from "office-ui-fabric-react";
import React, { useState, useEffect } from "react";
import { ApiConstants } from "../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../constants/JambarDateUtils";
import { SiteConstants } from "../../../constants/SiteConstant";
import { extractHtmlContent } from "../../../utils/JambarUtils";
import { Client } from "../../Base/Client";
import EventDetailsView from "./EventDetails/EventDetailsView";
import { EventListItem } from "./EventListDataTypes";
import EventsListView from "./EventsListView";
import "./EventManagerView.scss";
import { useAdminContext } from "../context/AdminAuthContext";
import { getPermissions } from "../../../utils/PermissionUtils";
import SideDrawer from "../../CommonComponent/SideDrawer";
import ConfirmationClose from "../../CommonComponent/ConfirmationClose";

const EventManagerView: React.FC = () => {
    const [showAddEditEventPanel, setShowAddEditEventPanel] = useState(false);
    const [selectedEventId, setSelectedEventId] = useState(-1);
    const [isListLoading, setIsListLoading] = useState(false);
    const [eventList, setEventList] = useState<EventListItem[]>([]);
    const { userDetail } = useAdminContext();
    const [closeConfirmationDialog, setCloseConfirmationDialog] = useState<boolean>(false);

    const permission: string[] | null = getPermissions(userDetail, "event");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (forceRefresh = false): void => {
        setIsListLoading(true);
        if (!(permission && permission.includes("fetch"))) {
            setEventList([]);
            setIsListLoading(false);
            return;
        }
        Client.getInstance()
            .getData(ApiConstants.getEventListApiUrl(), forceRefresh)
            .then((response: any) => {
                setEventList(response.data.data.map((event: any) => transformData(event)));
                setIsListLoading(false);
            });
    };

    const transformData = (event: any): EventListItem => {
        return {
            id: event.id,
            eventName: event.name,
            createdBy: `${event.createdByUser.firstName} ${event.createdByUser.lastName}`,
            startDate: JambarDateUtil.dateFormat(event.eventStartDate, "DD/MM/YYYY"),
            endDate: JambarDateUtil.dateFormat(event.eventEndDate, "DD/MM/YYYY"),
            gameTitle: extractHtmlContent(event.quiz.title),
            createdOn: JambarDateUtil.formatDate(event.createdAt),
            noOfTeams: event.teamCount,
            game: event.quizId,
            updatedBy: `${event.updatedByUser.firstName} ${event.updatedByUser.lastName}`,
            updatedOn: JambarDateUtil.formatDate(event.updatedAt),
            isClosed: event.isClosed
        };
    };

    const closePanel = () => {
        setShowAddEditEventPanel(false);
        setSelectedEventId(-1);
    };

    return (
        <>
            <Pivot className={"eventManagerPivot"}>
                <PivotItem className={"height-100per"} headerText={"Active"} itemKey={SiteConstants.activeEventPivot}>
                    <EventsListView
                        showEventDetailsPanel={(eventId) => {
                            setSelectedEventId(eventId);
                            setShowAddEditEventPanel(true);
                        }}
                        eventsList={eventList.filter((event) => !event.isClosed)}
                        isListLoading={isListLoading}
                        refreshList={() => fetchData(true)}
                    />
                </PivotItem>
                <PivotItem className={"height-100per"} headerText={"Closed"} itemKey={SiteConstants.closedEventPivot}>
                    <EventsListView
                        showEventDetailsPanel={(eventId) => {
                            setSelectedEventId(eventId);
                            setShowAddEditEventPanel(true);
                        }}
                        eventsList={eventList.filter((event) => event.isClosed)}
                        isListLoading={isListLoading}
                        refreshList={() => fetchData(true)}
                    />
                </PivotItem>
            </Pivot>
            <SideDrawer
                open={showAddEditEventPanel}
                handleClose={() => {
                    selectedEventId !== -1 ? closePanel() : setCloseConfirmationDialog(true);
                }}
                width={"80%"}
                title={selectedEventId === -1 ? "Add Event" : "Edit Event"}>
                <EventDetailsView
                    eventId={selectedEventId}
                    closeAddPanel={(id) => {
                        setSelectedEventId(id);
                        fetchData(true);
                    }}
                    refreshEventsList={() => fetchData(true)}
                />
            </SideDrawer>
            <ConfirmationClose
                warningText="You have unsaved changes. Are you sure you want to close this?"
                dialogHeader={"Unsaved Changes"}
                isShown={closeConfirmationDialog}
                onConfirm={() => {
                    setCloseConfirmationDialog(false);
                    closePanel();
                }}
                onDismiss={() => {
                    setCloseConfirmationDialog(false);
                }}
            />
        </>
    );
};

export default EventManagerView;

import { IColumn, IconButton, Panel, PanelType, PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import React, { useState, useEffect, useMemo } from "react";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { ThemedMediumStackTokens, ThemedSmall2StackTokens } from "../../../../constants/Styles";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { Client } from "../../../Base/Client";
import CheckboxWrapper from "../../../CommonComponent/CheckboxWrapper";
import { leaderBoardListColumns } from "./LeaderBoardConstants";
import { toast, ToastContainer } from "react-toastify";
import { LeaderBoardPollingInterval, SiteConstants } from "../../../../constants/SiteConstant";
import { orderBy } from "lodash";
import { convertTimeTo12HoursFormat } from "../../../../utils/TimeUtils";
import FixedHeaderSortingDetailsList from "../../../CommonComponent/FixedHeaderSortingDetilsList";
import ConfirmationDialog from "../../../CommonComponent/ConfirmationDialog";

export type LeaderBoardDetails = {
    position: number;
    questionAnsweredCount: number;
    correctAnswersCount: number;
    hintsUsedCount: number;
    participantCode: string;
    score: number;
    startTime: string;
    status: string;
    teamName: string;
    timeTakenInQuiz: string | null;
    wrongAnswersCount: number;
    timeLeftInQuiz: string | null;
    timeLeftInConnector: string | null;
};

type LeaderBoardDetailsViewProps = {
    isEventLive: boolean;
    eventId: number;
    refreshEventsList?: () => void;
    closeEvent?: () => void;
};

const LeaderBoardDetailsView: React.FC<LeaderBoardDetailsViewProps> = ({
    isEventLive,
    eventId,
    refreshEventsList,
    closeEvent
}) => {
    const [leaderBoardDetails, setLeaderBoardDetails] = useState<LeaderBoardDetails[]>([]);
    const [showManageColumnDialog, setShowManageColumnDialog] = useState(false);
    const [filteredColumns, setFilteredColumns] = useState<IColumn[]>([]);
    const [includeColumns, setIncludeColumns] = useState<string[]>([]);
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [showCloseConfirmationDialog, setShowCloseConfirmationDialog] = useState(false);
    let interval: NodeJS.Timeout;

    useEffect(() => {
        initializeLeaderBoardColumns();
        setIsContentLoading(true);
    }, []);

    useEffect(() => {
        if (!isEventLive) {
            fetchClosedEventLeaderBoardData();
            clearInterval(interval);
            return;
        }
        fetchLeaderBoardData(true);
        interval = setInterval(() => fetchLeaderBoardData(true), LeaderBoardPollingInterval);
        return () => clearInterval(interval);
    }, [isEventLive]);

    const initializeLeaderBoardColumns = (): void => {
        const savedColumns = localStorage.getItem("LeaderBoardColumns");
        if (savedColumns) {
            setIncludeColumns(JSON.parse(savedColumns));
            setFilteredColumns(leaderBoardListColumns.filter((element) => savedColumns.includes(element.key)));
            return;
        }
        setFilteredColumns(leaderBoardListColumns);
        setIncludeColumns(leaderBoardListColumns.map((element) => element.key));
    };

    const fetchLeaderBoardData = (forceRefresh = false): void => {
        Client.getInstance()
            .getData(ApiConstants.getLiveLeaderBoardScores(eventId), forceRefresh)
            .then((response) => {
                const data: LeaderBoardDetails[] = response.data.data.map((item: any) => transformData(item));
                setLeaderBoardDetails(data);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setIsContentLoading(false);
            });
    };

    const fetchClosedEventLeaderBoardData = (forceRefresh = false): void => {
        Client.getInstance()
            .getData(ApiConstants.getClosedEventLeaderBoardScores(eventId), forceRefresh)
            .then((response) => {
                const data: LeaderBoardDetails[] = response.data.data.map((item: any) => transformData(item));
                setLeaderBoardDetails(data);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setIsContentLoading(false);
            });
    };

    const transformData = (item: any): LeaderBoardDetails => ({
        correctAnswersCount: item.correctAnswersCount || 0,
        hintsUsedCount: item.hintsUsedCount || 0,
        participantCode: item.participantCode,
        score: item.score || 0,
        startTime: item.startTime ? convertTimeTo12HoursFormat(item.startTime) : "",
        status: item.status,
        teamName: item.teamName,
        timeTakenInQuiz: item.timeTakenInQuiz,
        timeLeftInConnector: item.timeLeftInConnector,
        timeLeftInQuiz: item.timeLeftInQuiz,
        wrongAnswersCount: item.wrongAnswersCount || 0,
        position: 0,
        questionAnsweredCount: item.correctAnswersCount + item.wrongAnswersCount
    });

    const renderItemColumn = (
        item: LeaderBoardDetails,
        index: number | undefined,
        column: IColumn | undefined
    ): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "participantCode":
                return <span className={"event-codes width-100per"}>{item.participantCode}</span>;

            default: {
                const fieldContent = item[column.fieldName as keyof LeaderBoardDetails] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    const getManageColumnCheckBoxes = (): JSX.Element => (
        <Stack className="section-background section-border" tokens={ThemedSmall2StackTokens}>
            {leaderBoardListColumns.map((item) => (
                <CheckboxWrapper
                    key={Math.random()}
                    label={item.name}
                    checked={!!includeColumns.find((element) => element === item.key)}
                    onChange={(checked) => {
                        checked
                            ? setIncludeColumns((prevState) => [item.key, ...prevState])
                            : setIncludeColumns((prevState) => prevState.filter((element) => element !== item.key));
                    }}
                />
            ))}
        </Stack>
    );

    const onSaveView = (): void => {
        localStorage.setItem("LeaderBoardColumns", JSON.stringify(includeColumns));
    };

    const sortedLeaderBoardDetails = useMemo((): LeaderBoardDetails[] => {
        const sortedList = orderBy(leaderBoardDetails, ["score", "timeTakenInQuiz"], ["desc", "asc"]);
        return sortedList.map((item, index) => ({
            ...item,
            position: index + 1
        }));
    }, [leaderBoardDetails]);

    const onCloseEvent = (): void => {
        Client.getInstance()
            .updateData(ApiConstants.closeEventApiUrl(eventId), {})
            .then(() => {
                toast.success("Event closed", SiteConstants.successToastConfiguration);
                closeEvent && closeEvent();
                refreshEventsList && refreshEventsList();
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setShowCloseConfirmationDialog(false);
            });
    };

    if (isContentLoading) {
        return <Spinner className={"loading-spinner"} size={SpinnerSize.large} label={"Loading..."} />;
    }

    return (
        <>
            <ToastContainer />
            <Stack className="section-background section-border height-100per overflowy-auto margin-top-10">
                <Stack horizontal className={"padding-10"} tokens={ThemedMediumStackTokens} horizontalAlign={"end"}>
                    <IconButton
                        iconProps={{ iconName: "Equalizer" }}
                        onClick={() => setShowManageColumnDialog(true)}
                        style={{ color: "#000" }}
                    />
                    {isEventLive && (
                        <PrimaryButton onClick={() => setShowCloseConfirmationDialog(true)} text={"Close Event"} />
                    )}
                </Stack>
                <FixedHeaderSortingDetailsList
                    hideSearchSection={true}
                    columns={filteredColumns}
                    isLoading={false}
                    items={sortedLeaderBoardDetails}
                    onRenderItemColumn={renderItemColumn}
                />
            </Stack>

            <Panel
                isLightDismiss
                isOpen={showManageColumnDialog}
                onDismiss={() => {
                    setShowManageColumnDialog(false);
                    setIncludeColumns(filteredColumns.map((element) => element.key));
                }}
                headerText={"Manage Columns"}
                type={PanelType.smallFixedFar}>
                <Stack tokens={ThemedMediumStackTokens}>
                    {getManageColumnCheckBoxes()}
                    <Stack horizontalAlign={"center"}>
                        <PrimaryButton
                            onClick={() => {
                                setShowManageColumnDialog(false);
                                setFilteredColumns(
                                    leaderBoardListColumns.filter((element) => includeColumns.includes(element.key))
                                );
                                onSaveView();
                            }}
                            text={"Save"}
                        />
                    </Stack>
                </Stack>
            </Panel>
            <ConfirmationDialog
                entryName={"event"}
                dialogHeader={"Confirm Close"}
                isShown={showCloseConfirmationDialog}
                onConfirm={onCloseEvent}
                actionName={"close"}
                onDismiss={() => setShowCloseConfirmationDialog(false)}
            />
        </>
    );
};

export default LeaderBoardDetailsView;

import React from "react";
import { Checkbox, ICheckboxProps } from "@fluentui/react";

type ICheckboxWrapperProps = Omit<ICheckboxProps, "onChange"> & {
    onChange?: (checked: boolean, event?: React.FormEvent<HTMLElement | HTMLInputElement>) => void;
};
const CheckboxWrapper: React.FC<ICheckboxWrapperProps> = (props) => {
    const onCheckboxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
        if (checked === undefined) {
            return;
        }
        props.onChange && props.onChange(checked, ev);
    };

    return <Checkbox {...props} onChange={onCheckboxChange} />;
};

export default CheckboxWrapper;

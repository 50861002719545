import { WildGoatsContainer } from "../../../WildGoat/CommonStyledComponents/CommonStyle";
import { usePathChallengeQuiz } from "./hook";
import { getOffsetColor } from "../../../WildGoat/Util/Util";
import { ToastContainer } from "react-toastify";
import { pathQuizPrimaryColor, pathQuizSecondaryColor } from "../../../../../constants/ColorConstant";
import LoadingWrapper from "../../../../CommonComponent/v2/LoadingWrapper";
import PathGameQuestionView from "./PathGameQuestionView";
import PathGameSummaryView from "./PathGameSummaryView";
import PathChallengeHeader from "./PathChallengeHeader";

const PathChallengeQuizView = () => {
    const {
        quizInfo,
        questionTiming,
        setQuestionTiming,
        quizUserSessionInfoData,
        participantAnswer,
        quizConfig,
        answerResult,
        answerSubmit,
        updateParticipantAnswer,
        eventInfo,
        loading,
        quizInfoData,
        currentQuestionInfo,
        info,
        connectorQuestionSubmit,
        connectorQuestionInfo,
        endQuiz,
        getConnectorTiming,
        getEventTiming,
        getQuestionTiming,
        refreshKey
    } = usePathChallengeQuiz();

    const getQuestionView = () => {
        if (currentQuestionInfo) {
            return (
                <PathGameQuestionView
                    currentQuestionInfo={currentQuestionInfo}
                    answerResult={answerResult}
                    quizInfoData={quizInfoData}
                    loading={loading}
                    participantAnswer={participantAnswer}
                    quizUserSessionInfoData={quizUserSessionInfoData}
                    questionTiming={questionTiming}
                    setQuestionTiming={setQuestionTiming}
                    saveProgress={answerSubmit}
                    connectorQuestionSubmit={connectorQuestionSubmit}
                    connectorQuestionInfo={connectorQuestionInfo}
                    updateParticipantAnswer={updateParticipantAnswer}
                    getEventTiming={getEventTiming}
                    getConnectorTiming={getConnectorTiming}
                    getQuestionTiming={getQuestionTiming}
                    endQuiz={endQuiz}
                    key={refreshKey}
                />
            );
        }
    };

    const renderQuestionSection = () => {
        if (endQuiz) {
            return (
                <PathGameSummaryView
                    gameBackgroundColor={quizConfig.backgroundColor}
                    info={quizInfoData.summaryPageInfo}
                    eventLink={info.state.eventLink}
                    buttonColor={quizConfig.buttonColor}
                    showSurveyForm={quizInfoData.showSurveyForm}
                    eventId={info.state.eventId.toString()}
                    partcipantName={info.state.participantName}
                    participantCode={info.state.code}
                />
            );
        }
        return getQuestionView();
    };
    return (
        <LoadingWrapper isLoading={loading}>
            <WildGoatsContainer
                bgcolor={quizInfo?.quizConfig?.wildgoatPrimaryColor || pathQuizPrimaryColor}
                bgcolor_offset={getOffsetColor(quizInfo?.quizConfig?.wildgoatPrimaryColor || pathQuizPrimaryColor)}>
                <ToastContainer />
                <PathChallengeHeader
                    quizTitle={quizInfo?.displayTitle}
                    companyLogo={eventInfo?.companyLogo}
                    quizLogo={eventInfo?.logo}
                    secondaryColor={quizInfo?.quizConfig?.wildgoatSecondaryColor || pathQuizSecondaryColor}
                />
                {renderQuestionSection()}
            </WildGoatsContainer>
        </LoadingWrapper>
    );
};

export default PathChallengeQuizView;

import { useField } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { CaptionText } from "../CommonStyle";
import { FileUploadContainer, LogoContainer } from "./Style";
import AddIcon from "../../../Images/icons/addfile.svg";
import { dangerColor } from "../../../../constants/ColorConstant";
import { Grid, IconButton } from "@mui/material";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig } from "../../../../utils/JambarUtils";
import { VALID_VIDEO } from "../../../../constants/SiteConstant";
import { whiteColor } from "../../../../constants/Styles";
import DeleteIcon from "../../../Images/icons/delete.svg";

type VideoDropProps = {
    selectedFile: File | null | string;
    videoSize: number;
    name: string;
    logoInfo?: string;
};
const VideoDrop: FC<VideoDropProps> = ({ selectedFile, videoSize, name, logoInfo }) => {
    // eslint-disable-next-line no-empty-pattern
    const [_, { error }, { setError, setValue }] = useField(name);

    const [videoSrc, setVideoSrc] = useState<string>("");

    useEffect(() => {
        if (typeof selectedFile === "string") {
            setVideoSrc(selectedFile);
            return;
        }
        selectedFile && setVideoSrc(URL.createObjectURL(selectedFile as File));
    }, [selectedFile]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles[0].id = Math.random();
            const file = acceptedFiles[0];
            if (setError && !checkIfFilesAreCorrectType(acceptedFiles, VALID_VIDEO)) {
                setError("Please upload video only");
                return;
            }

            if (videoSize && !checkIfFilesAreTooBig(acceptedFiles, videoSize)) {
                setError(`Video must be less then ${videoSize} MB`);
                return;
            }

            setValue(file);
        },
        [videoSize, setError]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <Grid container spacing={"20px"}>
            <Grid item>
                {videoSrc && (
                    <IconButton
                        style={{
                            position: "absolute",
                            marginLeft: 267,
                            marginTop: 10,
                            zIndex: 10,
                            backgroundColor: whiteColor
                        }}
                        onClick={() => {
                            setValue(null);
                            setVideoSrc("");
                        }}
                    >
                        <img src={DeleteIcon} alt={"deleteIcon"} />
                    </IconButton>
                )}
                {videoSrc ? (
                    <video style={{ marginTop: 10 }} width="302" height="170" controls>
                        <source src={videoSrc} />
                    </video>
                ) :
                    isDragActive ? (
                        <LogoContainer variant={"secondary"} {...getRootProps({ className: "dropzone height-100per" })}>
                            <input {...getInputProps()} />
                            <FileUploadContainer style={{ padding: 20 }}>
                                <img src={AddIcon} style={{ height: 28 }} />
                                <CaptionText color={"#BDBDBD"} textAlign={"center"}>
                                    {logoInfo}
                                </CaptionText>
                            </FileUploadContainer>
                        </LogoContainer>
                    ) : (
                        <LogoContainer variant={"secondary"} {...getRootProps({ className: "dropzone height-100per" })}>
                            <input {...getInputProps()} />
                            <FileUploadContainer style={{ padding: 20 }}>
                                <img src={AddIcon} style={{ height: 28 }} />
                                <CaptionText color={"#BDBDBD"} textAlign={"center"}>
                                    {logoInfo}
                                </CaptionText>
                            </FileUploadContainer>
                        </LogoContainer>
                    )
                }
                {error && <CaptionText color={dangerColor}>{error}</CaptionText>}
            </Grid>
        </Grid>
    );
};

export default VideoDrop;

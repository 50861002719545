export const MovieMediumQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "You don't go to the cinema, do you?",
            imageSrc: "https://i.imgur.com/sBtunwK.jpeg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Pretty good, you have some knowledge of movies.",
            imageSrc: "https://i.imgur.com/sBtunwK.jpeg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Fantastic! You know a lot about movies.",
            imageSrc: "https://i.imgur.com/sBtunwK.jpeg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "What is this character also known as? ",
            questionImageSrc: "https://i.imgur.com/R6E0ORK.jpg",
            answerDescription:
                "There is also a Marvel character named Captain Marvel, who is sometimes portrayed as a woman.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Peacemaker",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. The Flash",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Captain Marvel",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Captain America",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "What is Bruce Lee’s most successful film?",
            questionImageSrc: "https://i.imgur.com/IC6E04W.jpg",
            answerDescription: "This iconic martial arts film was released a month after Bruce Lee’s untimely death.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Enter the Dragon",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Crouching Tiger, Hidden Dragon",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Fist of Fury",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Game of Death",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "What colour is the power stone in the Avengers films?",
            questionImageSrc: "https://i.imgur.com/4sAD04e.jpg",
            answerDescription: "The power stone gives the ability to control all forms of energy.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. green",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. red",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. orange ",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. purple",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "This Marvel character is the alter-ego of _________.",
            questionImageSrc: "https://i.imgur.com/IOPiaRE.jpg",
            answerDescription: "The Falcon is introduced to the MCU in ‘Captain America: The Winter Soldier’.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Peter Parker",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Samuel/ Sam Wilson",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Barry Allen",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Billy Batson",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Which movie is this?",
            questionImageSrc: "https://i.imgur.com/pSJb2qP.jpg",
            answerDescription: "Did you know that the movie 'Gone with the Wind' was released in 1939?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Psycho",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Gone with the Wind",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Miracle on 34th Street",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Casablanca",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "Name the DC character Eartha Kitt played in the 60s.",
            questionImageSrc: "https://i.imgur.com/MHjBrJ1.jpg",
            answerDescription:
                "Eartha Kitt was the second person to play the role of Catwoman in the 60s TV adaptation of Batman.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Wonder Woman",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Gamora",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Cheetah",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Catwoman",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "How many ‘Pirates of the Caribbean’ films are there as of 2021?",
            questionImageSrc: "https://i.imgur.com/JnIrVDP.jpg",
            answerDescription:
                "Did you know that the ‘Pirates of the Caribbean’ movies were inspired by the theme park attractions at Walt Disney World?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 4",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 6",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. 3",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 9",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Which James Bond film is this image from?",
            questionImageSrc: "https://i.imgur.com/AO24d2h.jpg",
            answerDescription: "Did you know that this iconic house is actually located in England, not Scotland?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Goldfinger",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Casino Royale",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. No Time to Die",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Skyfall",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "Which actress plays Rachel's best friend in ‘Crazy Rich Asians’?",
            questionImageSrc: "https://i.imgur.com/xe6cEEf.jpg",
            answerDescription: "Did you know that Awkwafina’s real name is Nora Lum?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Lucy Liu",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Constance Wu",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Keira Knightley",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Awkwafina",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "In which Harry Potter movie does Snape die?",
            questionImageSrc: "https://i.imgur.com/4nBfdwU.jpg",
            answerDescription:
                "Did you know that Alan Rickman, the actor who played the role of Snape also played the role of the enemy in Die Hard?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. The Half-Blood Prince",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. The Order of the Phoenix ",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. The Deathly Hallows – Part 2",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. The Chamber of Secrets",
                    isCorrect: false
                }
            ]
        }
    ]
};

import React, { useEffect, useState } from "react";
import "./Admin.scss";
import { Spinner } from "@fluentui/react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import QuestionBankListView from "./QuestionBank/QuestionBankListView";
import Login from "./Login/Login";
import { Client } from "../Base/Client";
import GamesView from "./Games/GamesView";
import ReferenceListView from "./ReferenceLibrary/ReferenceListView";
import WebcamComponent from "./Experiment/WebcamComponent";
import EventManagerView from "./EventManager/EventManagerView";
import QuizView from "./Quiz/QuizView";
import RolesList from "./Roles/RolesList";
import ChangePasswordView from "./ChangePassword/ChangePasswordView";
import { Grid } from "@mui/material";
import { useAdminContext } from "./context/AdminAuthContext";
import { AdminUserType } from "./context/Type";
import { getPermissions } from "../../utils/PermissionUtils";
import DashboardView from "./v2/dashboard/DashboardView";
import SideBar from "./v2/Navbar/SideBar";
import LiveLocationView from "../Poc/LiveLocationView";
import Users from "./v2/user";
import EventFiles from "./v2/filemanager";
import EventManager from "./v2/eventmanager";
import AddEventDetails from "./v2/eventmanager/addeventdetail";
import EventInfo from "./v2/eventmanager/eventinfo";
import ReferenceListManager from "./v2/referencelistmanager";
import Roles from "./v2/roles";
import UpdateEventDetail from "./v2/eventmanager/updateeventdetail";
import QuestionLibrary from "./v2/questionlibrary";
import QuestionInfo from "./v2/questionlibrary/questionInfo";
import ProfileSettings from "./v2/profileSettings";
import AddQuestion from "./v2/questionlibrary/addquestion";
import AddReference from "./v2/referencelistmanager/addNewReference";
import EditReference from "./v2/referencelistmanager/editReference";
import UpdateReferenceItem from "./v2/referencelistmanager/editReference/items/addItem";
import GameLibrary from "./v2/gamelibrary";
import UpdateGame from "./v2/gamelibrary/updategame";
import UpdateAttachment from "./v2/referencelistmanager/editReference/attachments/editAttachment";
import GameInfo from "./v2/gamelibrary/gameinfo";
import LeaderboardView from "./v2/leaderboard";
import FlowChart from "../Poc/flowchart/FlowChart";
import MultibranchQuestion from "./v2/multibranch";
import { LoginUtil } from "../../utils/LoginUtils";
import Subscription from "./v2/subscription/Subscription";
import PaymentList from "./v2/paymentlist/PaymentList";

const AdminView = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
    const { userDetail, setUserDetail } = useAdminContext();
    const permissionQuestion: string[] | null = getPermissions(userDetail, "question");
    const permissionReferenceLib: string[] | null = getPermissions(userDetail, "referenceList");
    const permissionEvent: string[] | null = getPermissions(userDetail, "event");
    const permissionUser: string[] | null = getPermissions(userDetail, "user");
    const permissionQuizFullGame: string[] | null = getPermissions(userDetail, "quiz_fullgame");
    const permissionQuizSocketGame: string[] | null = getPermissions(userDetail, "quiz_socket");
    const permissionQuizPhysicalGame: string[] | null = getPermissions(userDetail, "quiz_wildgoat");
    const permissionLeaderboard: string[] | null = getPermissions(userDetail, "leaderboard");
    const permissionRoles: string[] | null = getPermissions(userDetail, "role");

    initializeIcons();
    useEffect(() => {
        const token = LoginUtil.getToken();
        const userDetail = LoginUtil.getUser()?.data || null;
        if (token) {
            setIsLoggedIn(true);
            Client.getInstance().setAuthenticationClient(token);
            userDetail && setUserDetail(userDetail as AdminUserType);
            return () => {
                setIsLoggedIn(false);
            };
        }
        setIsLoggedIn(false);
    }, []);

    const onLogin = (): void => {
        setIsLoggedIn(true);
    };

    if (isLoggedIn === null) {
        return (
            <Grid container style={{ height: "100vh" }} justifyContent={"center"} alignItems={"center"}>
                <Spinner className={"loading-component"} label={"loading"} />
            </Grid>
        );
    }
    if (!isLoggedIn) {
        return <Login onLogin={onLogin} />;
    }

    const getInitialRedirectionUrl = () => {
        if (permissionEvent && permissionEvent.includes("fetch")) {
            return "/admin/event";
        }
        if (permissionQuestion && permissionQuestion.includes("fetch")) {
            return "/admin/v2/questionlibrary";
        }

        if (
            permissionQuizFullGame?.includes("fetch") ||
            permissionQuizSocketGame?.includes("fetch") ||
            permissionQuizPhysicalGame?.includes("fetch")
        ) {
            return "/admin/quiz";
        }
        if (permissionReferenceLib && permissionReferenceLib.includes("fetch")) {
            return "/admin/referencelibrary";
        }
        if (permissionUser && permissionUser.includes("fetch")) {
            return "/admin/users";
        }
        if (permissionLeaderboard && permissionLeaderboard.includes("fetch")) {
            return "/admin/leaderboard";
        }
        return "/admin/password";
    };

    return (
        <BrowserRouter>
            <SideBar>
                <Switch>
                    <Route exact path="/admin/login" render={() => <Redirect to={getInitialRedirectionUrl()} />} />
                    <Route exact path={"/admin/dashboard"}>
                        <DashboardView />
                    </Route>
                    {permissionEvent?.includes("fetch") && (
                        <Route exact path={"/admin/event"}>
                            <EventManager />
                        </Route>
                    )}
                    {permissionEvent?.includes("fetch") && (
                        <Route exact path={"/admin/event/add"}>
                            <AddEventDetails />
                        </Route>
                    )}
                    {permissionEvent?.includes("update") && (
                        <Route exact path={"/admin/event/:id/update"}>
                            <UpdateEventDetail />
                        </Route>
                    )}
                    {permissionEvent?.includes("fetch") && (
                        <Route exact path={"/admin/event/:id/info"}>
                            <EventInfo />
                        </Route>
                    )}
                    <Route exact path={"/admin/files"}>
                        <EventFiles />
                    </Route>
                    {permissionQuestion?.includes("fetch") && (
                        <Route exact path={"/admin/questionbank"}>
                            <QuestionBankListView />
                        </Route>
                    )}

                    <Route exact path={"/admin/password"}>
                        <ChangePasswordView />
                    </Route>
                    {permissionReferenceLib?.includes("fetch") && (
                        <Route exact path={"/admin/referencelibrary"}>
                            <ReferenceListView />
                        </Route>
                    )}
                    {permissionQuizFullGame?.includes("fetch") ||
                        permissionQuizSocketGame?.includes("fetch") ||
                        (permissionQuizPhysicalGame?.includes("fetch") && (
                            <Route exact path={"/admin/quiz"}>
                                <QuizView />
                            </Route>
                        ))}
                    {permissionEvent?.includes("fetch") && (
                        <Route exact path={"/admin/eventmanager"}>
                            <EventManagerView />
                        </Route>
                    )}
                    {permissionUser?.includes("fetch") && (
                        <Route exact path={"/admin/users"}>
                            <Users />
                        </Route>
                    )}
                    {permissionUser?.includes("fetch") && (
                        <Route exact path={"/admin/v2/users"}>
                            <Users />
                        </Route>
                    )}
                    <Route exact path={"/admin/game"}>
                        <GamesView />
                    </Route>
                    {permissionRoles?.includes("fetch") && userDetail?.identity.name === "jambar" && (
                        <Route exact path={"/admin/roles"}>
                            <RolesList />
                        </Route>
                    )}
                    <Route exact path={"/admin/settings"}>
                        <h1>Site settings</h1>
                    </Route>
                    {permissionLeaderboard?.includes("fetch") && (
                        <Route exact path={"/admin/leaderboard"}>
                            <LeaderboardView />
                        </Route>
                    )}
                    <Route exact path={"/admin/experiment"}>
                        <WebcamComponent />
                    </Route>
                    <Route exact path="/admin/livelocation">
                        <LiveLocationView />
                    </Route>
                    <Route exact path="/admin/v2/referencelistmanager">
                        <ReferenceListManager />
                    </Route>
                    <Route exact path="/admin/v2/roles">
                        <Roles />
                    </Route>
                    {permissionQuestion?.includes("fetch") && (
                        <Route exact path="/admin/v2/questionlibrary">
                            <QuestionLibrary />
                        </Route>
                    )}
                    {permissionQuestion?.includes("update") && (
                        <Route exact path="/admin/v2/questionlibrary/:id/questionInfo">
                            <QuestionInfo />
                        </Route>
                    )}
                    <Route exact path="/admin/v2/profileSettings">
                        <ProfileSettings />
                    </Route>
                    {permissionQuestion?.includes("update") && (
                        <Route exact path="/admin/questionlibrary/:gameid/add">
                            <AddQuestion />
                        </Route>
                    )}
                    {permissionQuestion?.includes("update") && (
                        <Route exact path="/admin/questionlibrary/:gameid/update/:questionid">
                            <AddQuestion />
                        </Route>
                    )}
                    <Route exact path="/admin/v2/referencelistmanager/addNewReference">
                        <AddReference />
                    </Route>
                    <Route exact path="/admin/v2/referencelistmanager/:id/update">
                        <EditReference />
                    </Route>
                    <Route exact path="/admin/v2/referencelistmanager/:id/update/attachments/addAttachment">
                        <UpdateAttachment />
                    </Route>
                    <Route exact path="/admin/v2/referencelistmanager/:id/refitem/additem">
                        <UpdateReferenceItem />
                    </Route>
                    <Route exact path="/admin/v2/referencelistmanager/:id/refitem/:refItemId/updateitem">
                        <UpdateReferenceItem />
                    </Route>
                    <Route exact path="/admin/v2/referencelistmanager/:id/update/:attachmentId/editAttachment">
                        <UpdateAttachment />
                    </Route>
                    {(permissionQuizFullGame?.includes("fetch") ||
                        permissionQuizSocketGame?.includes("fetch") ||
                        permissionQuizPhysicalGame?.includes("fetch")) && (
                        <Route exact path="/admin/v2/gamelibrary">
                            <GameLibrary />
                        </Route>
                    )}
                    {(permissionQuizFullGame?.includes("update") ||
                        permissionQuizSocketGame?.includes("update") ||
                        permissionQuizPhysicalGame?.includes("update")) && (
                        <Route exact path="/admin/v2/gamelibrary/update/:gameId">
                            <GameLibrary />
                        </Route>
                    )}
                    {(permissionQuizFullGame?.includes("update") ||
                        permissionQuizSocketGame?.includes("update") ||
                        permissionQuizPhysicalGame?.includes("update")) && (
                        <Route exact path={"/admin/v2/gamelibrary/:gameId"}>
                            <GameInfo />
                        </Route>
                    )}
                    {(permissionQuizFullGame?.includes("add") ||
                        permissionQuizSocketGame?.includes("add") ||
                        permissionQuizPhysicalGame?.includes("add")) && (
                        <Route exact path="/admin/gamelibrary/:gameType/add">
                            <UpdateGame />
                        </Route>
                    )}
                    {(permissionQuizFullGame?.includes("update") ||
                        permissionQuizSocketGame?.includes("update") ||
                        permissionQuizPhysicalGame?.includes("update")) && (
                        <Route exact path="/admin/gamelibrary/:gameType/update/:gameId">
                            <UpdateGame />
                        </Route>
                    )}
                    <Route exact path="/admin/subscription">
                        <Subscription />
                    </Route>
                    <Route exact path="/admin/paymentlist">
                        <PaymentList />
                    </Route>
                    <Route exact path="/admin/flowchart">
                        <FlowChart />
                    </Route>
                    <Route exact path="/admin/quiz/:quizid/multibranch">
                        <MultibranchQuestion />
                    </Route>
                </Switch>
            </SideBar>
        </BrowserRouter>
    );
};

export default AdminView;

import { Stack } from "@fluentui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import { CONNECTOR, CONNECTOR_SKIP, MCQ, MCQAnswerOption, SKIP_QUESTION } from "../../../../data/question/types";
import { QuestionConnector } from "../../RocketTemplate/RocketTemplateUtils/RocketTemplateTypes";
import { ScrollViewContainer } from "../../RocketTemplate/style";
import "./style.scss";

type MiniEventAnswerViewProps = {
    updateAnswerText: (answer: string) => void;
    options?: MCQAnswerOption[];
    type: string;
    goToNextQuestion: (answerSubmitted: boolean, userAttemptType: string, quizType?: string) => void;
    showSkipButton: boolean;
    questionTimeEnds: boolean;
    questionConnectors?: QuestionConnector[];
    saveProgressForConnectorQuestion?: (quizId: number) => void;
    completedConnector?: string[];
    answerHeader?: string;
    nextButtonText?: string;
    connectorQuizIsWorking?: boolean;
    setHintDisable: (hintUseType: boolean) => void;
    gameBackgroundColor?: string;
    optionColor?: string;
};

const MiniEventAnswerView: React.FC<MiniEventAnswerViewProps> = ({
    updateAnswerText,
    options,
    type,
    goToNextQuestion,
    questionTimeEnds,
    questionConnectors,
    saveProgressForConnectorQuestion,
    showSkipButton,
    completedConnector,
    answerHeader,
    nextButtonText,
    connectorQuizIsWorking,
    setHintDisable,
    gameBackgroundColor,
    optionColor
}) => {
    const [participantAnswer, setParticipantAnswer] = useState("");
    const [selectedOption, setSelectedOption] = useState<string[]>([]);
    const [disableAnswerSection, setDisableAsnswerSection] = useState(false);
    const [answerSubmitted, setAnswerSubmitted] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState("");

    const answerSelected = (optionId: string) => {
        let selectedOptionArray = [];
        if (selectedOption.includes(optionId)) {
            selectedOptionArray = selectedOption.filter((item: string) => item !== optionId);
            setSelectedOption(selectedOptionArray);
            setParticipantAnswer(selectedOptionArray.toString());
            return;
        }
        selectedOptionArray = [...selectedOption, optionId];
        setSelectedOption(selectedOptionArray);
        setParticipantAnswer(selectedOptionArray.toString());
    };

    const answerGivenByParticipant = () => {
        setDisableAsnswerSection(true);
        updateAnswerText(participantAnswer);
        setHintDisable(true);
        setAnswerSubmitted(true);
    };

    const nextQuestion = (quizType?: string): void => {
        goToNextQuestion(answerSubmitted, quizType ? CONNECTOR_SKIP : SKIP_QUESTION, quizType ? CONNECTOR : "");
        setDisableAsnswerSection(false);
        setSelectedOption([]);
        setParticipantAnswer("");
        setHintDisable(false);
    };

    useEffect(() => {
        selectedQuiz !== "" &&
            saveProgressForConnectorQuestion &&
            saveProgressForConnectorQuestion(parseInt(selectedQuiz));
    }, [selectedQuiz]);

    const getAnswerView = useMemo(() => {
        if (type === MCQ) {
            return (
                <Stack>
                    <Row>
                        {options &&
                            options.map((item: MCQAnswerOption) => {
                                return (
                                    <Col
                                        md={3}
                                        key={`${item.id}${selectedOption.length}${Math.random()}`}
                                        className={
                                            questionTimeEnds || disableAnswerSection ? "pointer-event-none" : undefined
                                        }>
                                        <Button
                                            style={{ backgroundColor: optionColor }}
                                            className={
                                                selectedOption.length === 0
                                                    ? "refrence-options cursor"
                                                    : selectedOption.includes(item.id.toString())
                                                    ? "refrence-options cursor option-select"
                                                    : "refrence-options cursor"
                                            }
                                            onClick={() => answerSelected(item.id.toString())}>
                                            {item.text}
                                        </Button>
                                    </Col>
                                );
                            })}
                        {!questionTimeEnds && !disableAnswerSection ? (
                            <Col md={3}>
                                <Button
                                    disabled={participantAnswer === ""}
                                    className={
                                        participantAnswer === ""
                                            ? "pointer-event-none submit-button-option"
                                            : "submit-button-option cursor"
                                    }
                                    onClick={() => answerGivenByParticipant()}>
                                    Submit
                                </Button>
                            </Col>
                        ) : null}
                    </Row>
                    <Stack horizontal horizontalAlign={"space-between"}>
                        {showSkipButton && !answerSubmitted && !questionTimeEnds && (
                            <Stack className={"margin-top-10"}>
                                <Button
                                    className={"answer-submit"}
                                    onClick={() =>
                                        connectorQuizIsWorking ? nextQuestion(CONNECTOR_SKIP) : nextQuestion()
                                    }>
                                    Skip question
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            );
        }
        if (type === CONNECTOR) {
            return (
                <Stack>
                    <Row className={"padding-left-10"}>
                        {questionConnectors &&
                            questionConnectors.length !== 0 &&
                            questionConnectors.map((item: QuestionConnector) => {
                                return (
                                    <>
                                        <Col md={4} key={`${item.id}${Math.random()}`} className={"padding-left-5"}>
                                            <Button
                                                style={{ backgroundColor: optionColor }}
                                                key={selectedQuiz}
                                                className={
                                                    completedConnector?.includes(item.quizId.toString())
                                                        ? "refrence-options-connector pointer-event-none selected-connector-button"
                                                        : "refrence-options-connector cursor"
                                                }
                                                onClick={() => {
                                                    setSelectedQuiz(item.quizId.toString());
                                                }}>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.optionText
                                                    }}
                                                />
                                            </Button>
                                        </Col>
                                    </>
                                );
                            })}
                    </Row>
                    <Stack horizontal horizontalAlign={"space-between"}>
                        {showSkipButton && completedConnector?.length !== questionConnectors?.length && (
                            <Stack horizontal horizontalAlign={"space-between"}>
                                <Button className={"answer-submit"} onClick={() => nextQuestion()}>
                                    Skip question
                                </Button>
                            </Stack>
                        )}
                        {completedConnector?.length === questionConnectors?.length && (
                            <Stack horizontal horizontalAlign={"space-between"}>
                                <Button className={"answer-submit"} onClick={() => nextQuestion()}>
                                    {nextButtonText}
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            );
        }
        return (
            <>
                <Stack>
                    <Stack horizontal tokens={ThemedMediumStackTokens}>
                        <Stack.Item grow={1}>
                            <InputGroup size="lg">
                                <FormControl
                                    className={"answer-input"}
                                    value={participantAnswer}
                                    onChange={(ev: any) => setParticipantAnswer(ev.target.value)}
                                    disabled={disableAnswerSection}
                                />
                            </InputGroup>
                        </Stack.Item>
                        <Stack.Item>
                            {!questionTimeEnds && !disableAnswerSection ? (
                                <Button
                                    disabled={participantAnswer === ""}
                                    className={"answer-submit"}
                                    onClick={() => answerGivenByParticipant()}>
                                    Submit
                                </Button>
                            ) : null}
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal horizontalAlign={"space-between"}>
                        {showSkipButton && !answerSubmitted && !questionTimeEnds && (
                            <Stack className={"margin-top-10"}>
                                <Button
                                    className={!questionTimeEnds && !answerSubmitted ? "answer-submit" : "display-none"}
                                    onClick={() =>
                                        connectorQuizIsWorking ? nextQuestion(CONNECTOR_SKIP) : nextQuestion()
                                    }>
                                    Skip question
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </>
        );
    }, [type, participantAnswer, selectedQuiz, disableAnswerSection, questionTimeEnds]);

    return (
        <ScrollViewContainer
            className={type !== CONNECTOR ? "quiz-answer-view" : ""}
            tokens={ThemedMediumStackTokens}
            style={{ backgroundColor: gameBackgroundColor }}>
            {type !== CONNECTOR && (
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            answerHeader && !FieldValidation.quillTextEditorValidation(answerHeader) ? answerHeader : ""
                    }}
                    className={
                        answerHeader && !FieldValidation.quillTextEditorValidation(answerHeader)
                            ? "margin-p-0 answer-title quill-editor-text-align"
                            : "component-title margin-p-0"
                    }
                />
            )}
            {getAnswerView}
        </ScrollViewContainer>
    );
};

export default MiniEventAnswerView;

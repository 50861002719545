import { wildgoatPrimaryColor, wildgoatSecondaryColor, wildgoatTertiaryColor } from "../../../constants/ColorConstant";
import { correctAnswerBackgroundColor, wrongAnswerBackgroundColor } from "../../../constants/Styles";
import { extractHtmlContent } from "../../../utils/JambarUtils";
import { WildgoatConfigType } from "./WildgoatType";

export const WildgoatConfigTransformData = (response: any): WildgoatConfigType => {
    const data = response.data.data.quiz;
    return {
        attachmentTitle:
            data.quizConfig && data.quizConfig.attachmentTitle !== "null" && data?.quizConfig.attachmentTitle
                ? data.quizConfig.attachmentTitle
                : "",
        backgroundImage: data?.quizConfig?.backgroundImageFileLocation || "",
        progressImage: data?.quizConfig?.progressImageFileLocation || "",
        progressTitle: data?.quizConfig?.progressTitle || "",
        backgroundColor: data?.quizConfig?.baseColor || "",
        buttonColor: data?.quizConfig?.baseButtonColor || "",
        correctAnswerBackgroundColor: data?.quizConfig?.correctAnswerBackgroundColor || correctAnswerBackgroundColor,
        wrongAnswerBackgroundColor: data?.quizConfig?.wrongAnswerBackgroundColor || wrongAnswerBackgroundColor,
        tabTitle:
            data.quizConfig && data.quizConfig.browserTabTitle
                ? response.data.data.quiz.quizConfig.browserTabTitle
                : extractHtmlContent(response.data.data.quiz.title),
        primaryColor: data?.quizConfig?.wildgoatPrimaryColor || wildgoatPrimaryColor,
        secondaryColor: data?.quizConfig?.wildgoatSecondaryColor || wildgoatSecondaryColor,
        tertiaryColor: data?.quizConfig?.wildgoatTertiaryColor || wildgoatTertiaryColor,
        wildgoatCorrectAnswerHeading: data?.quizConfig?.correctAnswerHeading || "",
        wildgoatCorrectAnswerImage: data?.quizConfig?.correctAnswerImageLocation || "",
        wildgoatTimeoutAnswerImage: data?.quizConfig?.timeoutAnswerImageLocation || "",
        wildgoatTimeoutHeading: data?.quizConfig?.timeoutAnswerHeading || "",
        wildgoatWrongAnswerHeading: data?.quizConfig?.wrongAnswerHeading || "",
        wildgoatWrongAnswerImage: data?.quizConfig?.wrongAnswerImageLocation || "",
        totalScoreVisibility: data?.quizConfig?.totalScoreVisibility || false
    };
};

import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { StyledLinearProgress } from "./style";

type UploadProgressBarProps = {
    progress: number;
    primaryColor?: string;
};

const UploadProgressBar: FC<UploadProgressBarProps> = ({ progress, primaryColor }) => {
    return (
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <StyledLinearProgress linecolor={primaryColor} variant="determinate" value={progress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography style={{ color: primaryColor || "blue" }} variant="body2">{`${progress}%`}</Typography>
            </Box>
        </Box>
    );
};

export default UploadProgressBar;

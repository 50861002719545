import { IColumn } from "@fluentui/react";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { ResourceType, RoleMapType } from "./Type";
import CheckboxWrapper from "../../../CommonComponent/CheckboxWrapper";
import { roleNameMap } from "./Util";

export const renderResourcesListColumn = (
    item: ResourceType,
    _: number | undefined,
    column: IColumn | undefined,
    addRight: (resourceId: number, rightId: number) => void,
    removeRight: (roleId: number, resourceId: number, rightId: number) => void
): JSX.Element => {
    if (!column) {
        return <span />;
    }

    switch (column.key) {
        case "name":
            return <span>{roleNameMap[item.name as keyof RoleMapType]}</span>;

        case "add":
            return (
                <CheckboxWrapper
                    checked={!!item.rights.find((item) => item.id === 1)}
                    onChange={(checked: boolean) =>
                        checked
                            ? addRight(item.id, 1)
                            : removeRight(item.rights.find((item) => item.name === "add")?.roleId || -1, item.rights.find((item) => item.name === "add")?.resourceId || -1, item.rights.find((item) => item.name === "add")?.id || -1)
                    }
                />
            );
        case "clone":
            return (
                <CheckboxWrapper
                    checked={!!item.rights.find((item) => item.id === 41)}
                    onChange={(checked: boolean) =>
                        checked
                            ? addRight(item.id, 41)
                            : removeRight(item.rights.find((item) => item.name === "clone")?.roleId || -1, item.rights.find((item) => item.name === "clone")?.resourceId || -1, item.rights.find((item) => item.name === "clone")?.id || -1)
                    }
                />
            );
        case "update":
            return (
                <CheckboxWrapper
                    checked={!!item.rights.find((item) => item.id === 11)}
                    onChange={(checked: boolean) =>
                        checked
                            ? addRight(item.id, 11)
                            : removeRight(item.rights.find((item) => item.name === "update")?.roleId || -1, item.rights.find((item) => item.name === "update")?.resourceId || -1, item.rights.find((item) => item.name === "update")?.id || -1)
                    }
                />
            );
        case "fetch":
            return (
                <CheckboxWrapper
                    checked={!!item.rights.find((item) => item.id === 21)}
                    onChange={(checked: boolean) =>
                        checked
                            ? addRight(item.id, 21)
                            : removeRight(item.rights.find((item) => item.name === "fetch")?.roleId || -1, item.rights.find((item) => item.name === "fetch")?.resourceId || -1, item.rights.find((item) => item.name === "fetch")?.id || -1)
                    }
                />
            );
        case "delete":
            return (
                <CheckboxWrapper
                    checked={!!item.rights.find((item) => item.id === 31)}
                    onChange={(checked: boolean) =>
                        checked
                            ? addRight(item.id, 31)
                            : removeRight(item.rights.find((item) => item.name === "delete")?.roleId || -1, item.rights.find((item) => item.name === "delete")?.resourceId || -1, item.rights.find((item) => item.name === "delete")?.id || -1)
                    }
                />
            );
        case "change-password":
            return (
                <CheckboxWrapper
                    disabled={item.name !== "user"}
                    checked={!!item.rights.find((item) => item.id === 51)}
                    onChange={(checked: boolean) =>
                        checked
                            ? addRight(item.id, 51)
                            : removeRight(
                                item.rights.find((item) => item.name === "change-password")?.roleId || -1, item.rights.find((item) => item.name === "change-password")?.resourceId || -1,
                                item.rights.find((item) => item.name === "change-password")?.id || -1
                            )
                    }
                />
            );
        case "change-permission":
            return (
                <CheckboxWrapper
                    disabled={item.name !== "role"}
                    checked={!!item.rights.find((item) => item.id === 61)}
                    onChange={(checked: boolean) =>
                        checked
                            ? addRight(item.id, 61)
                            : removeRight(
                                item.rights.find((item) => item.name === "change-permission")?.roleId || -1, item.rights.find((item) => item.name === "change-permission")?.resourceId || -1,
                                item.rights.find((item) => item.name === "change-permission")?.id || -1
                            )
                    }
                />
            );
        default: {
            const fieldContent = item[column.fieldName as keyof ResourceType] as string;
            return <span>{fieldContent}</span>;
        }
    }
};

export const ResourceListColumns: IColumn[] = [
    {
        key: "sno",
        name: "S.No",
        fieldName: "sno",
        minWidth: SiteConstants.listColumnIcon,
        maxWidth: SiteConstants.listColumnIcon
    },
    {
        key: "name",
        name: "Resource",
        fieldName: "name",
        minWidth: SiteConstants.listColumnMedium
    }
];

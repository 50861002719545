import { IColumn, Panel, PanelType } from "@fluentui/react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../constants/SiteConstant";
import { getPermissions } from "../../../utils/PermissionUtils";
import { getErrorMessage } from "../../../utils/APIErrorMessages";
import { TProps } from "../../Base/Base";
import DeleteConfirmationDialog from "../../CommonComponent/DeleteConfirmationDialog";
import FixedHeaderSortingDetailsList from "../../CommonComponent/FixedHeaderSortingDetilsList";
import { useAdminContext } from "../context/AdminAuthContext";
import { deleteRole, getRoles } from "./Action";
import { renderRoleListColumn, RoleListColumns, RoleMenuColumns } from "./GridUtil";
import ResourceRightDetailPanel from "./ResourceRightsDetail/ResourceRightDetailPanel";
import RoleDetailPanel from "./RoleDetailPanel";
import { RoleType } from "./Type";
import { getRoleListFilteredItems } from "./Util";
import { Grid } from "@mui/material";

const RolesList = () => {
    const { userDetail } = useAdminContext();
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [allRoles, setAllRoles] = useState<RoleType[]>([]);
    const [selectedRoleId, setSelectedRoleId] = useState<RoleType | null>(null);
    const [selectedDeleteRoleId, setSelectedDeleteRoleId] = useState<number | null>(null);
    const [selectedRoleIdForResource, setSelectedRoleIdForResource] = useState<RoleType | null>(null);
    const permission: string[] | null = getPermissions(userDetail, "role");
    useEffect(() => {
        setIsLoading(true);
        fetchData();
    }, []);

    const fetchData = (forceRefresh = false) => {
        getRoles(forceRefresh)
            .then((res: RoleType[]) => {
                setAllRoles(res);
                setIsLoading(false);
            })
            .catch((err: any) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            });
    };

    const onConfirmDelete = () => {
        selectedDeleteRoleId &&
            deleteRole(selectedDeleteRoleId)
                .then(() => {
                    setSelectedDeleteRoleId(null);
                    fetchData(true);
                    toast.success("Identity deleted successfully");
                })
                .catch(() => {
                    setSelectedDeleteRoleId(null);
                    toast.error("Something Went wrong");
                });
    };

    const getColumns = () => {
        if (permission) {
            return [
                ...RoleListColumns,
                ...RoleMenuColumns.filter((column) => permission && permission.includes(column.key))
            ];
        }
        return RoleListColumns;
    };

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                height={"calc(100vh - 80px)"}
                sx={{
                    "& .ms-Label": {
                        fontSize: "18px !important"
                    },
                    "& .ms-DetailsRow-cell": {
                        fontSize: "16px !important"
                    },
                    "& .ms-Button-label": {
                        fontSize: "16px"
                    }
                }}>
                <FixedHeaderSortingDetailsList
                    onSearchTextChange={(text: string) => setSearchText(text)}
                    searchText={searchText}
                    onAddButtonClick={() =>
                        setSelectedRoleId({
                            id: -1,
                            name: "",
                            sno: -1
                        })
                    }
                    columns={getColumns()}
                    isLoading={isLoading}
                    items={getRoleListFilteredItems(allRoles, searchText)}
                    onRenderItemColumn={(item: TProps, index: number | undefined, column: IColumn | undefined) =>
                        renderRoleListColumn(
                            item,
                            index,
                            column,
                            (roleDetail: RoleType) => setSelectedRoleId(roleDetail),
                            (roleDetail: RoleType) => setSelectedRoleIdForResource(roleDetail),
                            (id: number) => setSelectedDeleteRoleId(id)
                        )
                    }
                />
            </Grid>

            <Panel
                isLightDismiss
                isOpen={!!selectedRoleId}
                onDismiss={() => {
                    setSelectedRoleId(null);
                }}
                headerText={selectedRoleId && selectedRoleId.id === -1 ? "Add Role" : "Edit Role"}
                type={PanelType.medium}
                className={"event-details-panel"}>
                <RoleDetailPanel
                    role={selectedRoleId}
                    refreshRoleList={() => {
                        fetchData(true);
                        setSelectedRoleId(null);
                    }}
                />
            </Panel>
            <Panel
                isLightDismiss
                isOpen={!!selectedRoleIdForResource}
                onDismiss={() => {
                    setSelectedRoleIdForResource(null);
                }}
                headerText={"Manage Rights"}
                type={PanelType.custom}
                customWidth={"60%"}
                className={"event-details-panel"}>
                <ResourceRightDetailPanel roleId={selectedRoleIdForResource?.id || -1} />
            </Panel>
            <DeleteConfirmationDialog
                entryName={"Roles"}
                isShown={!!selectedDeleteRoleId}
                onConfirm={onConfirmDelete}
                onDismiss={() => setSelectedDeleteRoleId(null)}
            />
        </Grid>
    );
};

export default RolesList;

import googleLogo from "../../../Images/googleLogo.png";
import edgeLogo from "../../../Images/edge.png";
import safari from "../../../Images/safari.png";

export const browsersList = [
    {
        id: 1,
        title: "Chrome",
        imgUrl: googleLogo,
        downloadUrl: "https://www.google.com/intl/en_in/chrome/"
    },
    {
        id: 2,
        title: "Edge",
        imgUrl: edgeLogo,
        downloadUrl: "https://www.microsoft.com/en-us/edge"
    },
    {
        id: 3,
        title: "Safari",
        imgUrl: safari,
        downloadUrl: "https://support.apple.com/downloads/safari"
    }
];

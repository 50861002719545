import { Grid } from "@mui/material";
import { FC } from "react";
import ReactFlow, { Background, Controls } from "react-flow-renderer";
import LoadingButtonWrapper from "../../../CommonComponent/v2/button/LoadingButtonWrapper";
import { PrimaryButton } from "../../../CommonComponent/v2/CommonStyle";
import LoadingWrapper from "../../../CommonComponent/v2/LoadingWrapper";
import AddNewBranch from "./AddNewBranch";
import AddNewTrigger from "./AddNewTrigger";
import { useMultibranchQuestion } from "./Hooks";
import { FlowChartContainer } from "./Style";
import ConfirmationBox from "../../../CommonComponent/v2/ConfirmationBox";

type MultibranchQuestionProps = {
    gameId?: string
}
const MultibranchQuestion: FC<MultibranchQuestionProps> = ({
    gameId
}) => {
    const {
        edges,
        onNodesChange,
        onEdgesChange,
        selectedBoxToEdit,
        setAddNewBranchModal,
        addNewBranchModal,
        refreshChart,
        isLoading,
        onConnect,
        addBranch,
        updateBranch,
        deleteBranch,
        deleteTrigger,
        addTrigger,
        triggerBranchId,
        editTrigger,
        nodes,
        setSelectedBoxToEdit,
        setTriggerBranchId,
        setEditTrigger,
        saveChartinJson,
        quizType,
        loading,
        isBranchAlreadySaved,
        showDeleteBranchModal,
        setShowDeleteBranchModal,
        confirmDelete
    } = useMultibranchQuestion(gameId);

    return (
        <Grid container>
            <Grid container px={"30px"} py={"20px"} justifyContent={"space-between"} spacing={2}>
                <Grid item marginTop={"20px"}>
                    <PrimaryButton onClick={() => setAddNewBranchModal(true)}>Add Branch</PrimaryButton>
                </Grid>
                <Grid item marginTop={"20px"}>
                    <LoadingButtonWrapper loading={loading}>
                        <PrimaryButton onClick={() => saveChartinJson()}>{!isBranchAlreadySaved ? 'Save' : 'Update'}</PrimaryButton>
                    </LoadingButtonWrapper>

                </Grid>
            </Grid>
            {(addNewBranchModal || selectedBoxToEdit) && (
                <AddNewBranch
                    handleClose={() => {
                        setAddNewBranchModal(false);
                        setSelectedBoxToEdit(undefined);
                    }}
                    quizType={quizType}
                    deleteBranch={(branchId: string)=> setShowDeleteBranchModal(branchId)}
                    hideDelete={nodes.findIndex((item) => item.id === selectedBoxToEdit?.id) === 0}
                    updateBranch={updateBranch}
                    addBranch={addBranch}
                    branchInfo={selectedBoxToEdit}
                />
            )}
            {(triggerBranchId || editTrigger) && (
                <AddNewTrigger
                    addTrigger={addTrigger}
                    branches={nodes}
                    triggerBranchId={triggerBranchId}
                    triggerInfo={editTrigger?.triggerInfo}
                    handleClose={() => {
                        setTriggerBranchId("");
                        setEditTrigger(undefined);
                    }}
                    deleteTrigger={deleteTrigger}
                />
            )}
            {
                showDeleteBranchModal &&
                <ConfirmationBox
                    title={`Delete branch`}
                    message={"Please confirm if you really want to delete this branch"}
                    onConfirm={confirmDelete}
                    onClose={() => setShowDeleteBranchModal(null)}
                />
            }
            <Grid item xs={12}>
                <LoadingWrapper isLoading={isLoading}>
                    <FlowChartContainer item xs={12}>
                        <ReactFlow
                            style={{ width: "100%", height: "80vh" }}
                            nodes={nodes}
                            edges={edges}
                            onNodesDelete={(props) => deleteBranch(props[0].id)}
                            key={refreshChart}
                            fitView
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                        >
                            <Controls />
                            <Background />
                        </ReactFlow>
                    </FlowChartContainer>
                </LoadingWrapper>
            </Grid>
        </Grid>
    );
};

export default MultibranchQuestion;

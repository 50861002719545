import { Grid } from "@mui/material";
import { FC } from "react";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { useEventListHook } from "./Hooks";
import LeaderboardDialog from "./LeaderboardDialog";
import { LeaderBoardEventListItem } from "./Type";

type LeaderboardEventListProps = {
    eventList: LeaderBoardEventListItem[];
    isLoading: boolean;
    selectedTab: string;
};
const LeaderboardEventList: FC<LeaderboardEventListProps> = ({ eventList, isLoading, selectedTab }) => {
    const { leaderboardEventListColumns, selectedEvent, setSelectedEvent } = useEventListHook();

    return (
        <Grid height={"calc(100vh - 303px)"}>
            <DataGridWrapper columns={leaderboardEventListColumns()} loading={isLoading} rows={eventList as any} />
            {selectedEvent && (
                <LeaderboardDialog
                    selectedTab={selectedTab}
                    handleClose={() => setSelectedEvent(null)}
                    selectedEvent={selectedEvent}
                />
            )}
        </Grid>
    );
};

export default LeaderboardEventList;

import { ApiConstants } from "../../../../../constants/ApiConstant";
import { Client } from "../../../../Base/Client";

export const fetchQuizInfo = (data: any): Promise<any> =>
    Client.getInstance()
        .getData(ApiConstants.validateParticipantCode(data.eventLink, data.code))
        .then((response) => {
            return Promise.resolve(response);
        });

export const getMultiBranchNextQuestionInfo = (data: any): Promise<any> =>
    Client.getInstance()
        .getData(ApiConstants.fetchNextMultibranchQuestionApiUrl(data.eventLink, data.code))
        .then((response) => {
            return Promise.resolve(response);
        });

export const getIsMultiBranchQuizInfo = (quizId: string): Promise<any> =>
    Client.getInstance()
        .getData(ApiConstants.getIsMultibranchQuizApiUrl(quizId))
        .then((response) => {
            return Promise.resolve(response);
        });

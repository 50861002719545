import React, { FC } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useShowWizard } from "./hooks";
import { InitialSetupContainer } from "./InitialSetupStyle";
import { getBrowserInfo } from "./util";

type InitialSetupProps = {
    closeSetup: () => void;
};

const InitialSetup: FC<InitialSetupProps> = ({ closeSetup }) => {
    const [activeStep, setActiveStep] = useState(1);

    useEffect(() => {
        if (getBrowserInfo()) {
            moveToNextStep();
        }
    }, []);

    const moveToNextStep = () => {
        if (activeStep === 4) {
            closeSetup();
            return;
        }
        setActiveStep((prevState) => prevState + 1);
    };

    const showView = useShowWizard(moveToNextStep, activeStep);

    return <InitialSetupContainer>{showView}</InitialSetupContainer>;
};

export default InitialSetup;

import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { VisibiltyTypeOptions } from "../../../../../../constants/DropdownOption";
import { superAdmin } from "../../../../../../constants/SiteConstant";
import { Header2 } from "../../../../../CommonComponent/v2/CommonStyle";
import FormikSelect, { FormikMultiSelect } from "../../../../../CommonComponent/v2/fields/FormikSelect";
import FormikTextInput from "../../../../../CommonComponent/v2/fields/FormikTextInput";
import { GameTypeOption } from "../../../questionlibrary/addquestion/Util";
import { GameDetailType } from "../Type";
import { useGameIdentificationHooks } from "./Hooks";
import { useUserInfo } from "../../../../../../commonhooks/Hooks";

const GameIdentificationForm = () => {
    const { values } = useFormikContext();
    const data = values as GameDetailType;

    const { gameType, identityDropdownOptions, userDetail } = useGameIdentificationHooks();
    const { onlyOneGameAllowed } = useUserInfo();
    return (
        <Grid container>
            <Grid item xs={12} mt={"20px"}>
                <Header2>Game identification</Header2>
                <Grid container mt={"19px"} spacing={"15px"}>
                    {!onlyOneGameAllowed && (
                        <Grid item xs={12}>
                            <FormikSelect
                                name={"gameIdentification.gameType"}
                                id={"gameIdentification.gameType"}
                                options={GameTypeOption}
                                label={"Game type"}
                                value={gameType}
                                disabled
                            />
                        </Grid>
                    )}

                    {!!userDetail?.currentRoles.find((item) => item.name === superAdmin) &&
                        userDetail?.identity.name === "jambar" && (
                            <>
                                <Grid item xs={12}>
                                    <FormikSelect
                                        name={"gameIdentification.visibilityType"}
                                        id={"gameIdentification.visibilityType"}
                                        options={VisibiltyTypeOptions}
                                        label={"Visibility type"}
                                    />
                                </Grid>
                                {data.gameIdentification.visibilityType === "limited" && (
                                    <Grid item xs={12}>
                                        <FormikMultiSelect
                                            name={"gameIdentification.identity"}
                                            id={"gameIdentification.identity"}
                                            options={identityDropdownOptions}
                                            label={"Identity type"}
                                            multiple
                                        />
                                    </Grid>
                                )}
                            </>
                        )}
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"gameIdentification.title"}
                            id={"gameIdentification.title"}
                            label={"Title(for admin)"}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GameIdentificationForm;

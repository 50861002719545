import { Stack, Spinner } from "@fluentui/react";
import { Grid } from "@mui/material";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { FieldValidation } from "../../../../constants/FieldValidation";
import { ThemedMediumStackTokens, ThemedSmall2StackTokens } from "../../../../constants/Styles";
import { Client } from "../../../Base/Client";
import logo from "../../../Images/logo.svg";
import { cacheImages } from "../../../WebsiteGame/util";
import { useFormik } from "formik";
import { LoginValidation } from "./ValidationSchema";

const MiniLoginView = () => {
    const [loading, setLoading] = useState(true);
    const [loginFailed, setLoginFailed] = useState(false);
    const [code, setCode] = useState("");
    const [event, setEvent] = useState("");
    const [showLogin, setShowLogin] = useState(false);
    const history = useHistory();

    const fetchEvent = (eventLink: string): void => {
        Client.getInstance()
            .getData(ApiConstants.getEventDataApiUrl(eventLink))
            .then(() => {
                Client.getInstance()
                    .getData(ApiConstants.getEventImages(eventLink))
                    .then((res) => cacheImages(res.data.data));
                setLoading(false);
                setCode(FieldValidation.generateAlphaNumericCode());
                setShowLogin(true);
            })
            .catch(() => {
                setLoading(false);
                setShowLogin(false);
            });
    };

    const fetchEventId = (): string => {
        const url = window.location.href;
        const urlSplit = url.split("/");
        fetchEvent(urlSplit[urlSplit.length - 1]);
        setEvent(urlSplit[urlSplit.length - 1]);
        return urlSplit[urlSplit.length - 1];
    };

    useEffect(() => {
        fetchEventId();
    }, []);

    const loginEvent = (values: { name: string; email: string }) => {
        Client.getInstance()
            .getData(ApiConstants.validateMiniParticipantCode(event, code, values.email))
            .then((response) => {
                history.push("/mini/participant/quiz", {
                    code: code,
                    eventLink: event,
                    quizId: response.data.data.quiz.id,
                    eventId: response.data.data.event.id,
                    teamName: values.name,
                    email: values.email
                });
            })
            .catch(() => {
                setLoginFailed(true);
            });
    };

    const { values, handleSubmit, handleChange, errors, touched, setFieldTouched } = useFormik({
        initialValues: {
            name: "",
            email: ""
        },
        validationSchema: LoginValidation,
        validateOnMount: true,
        onSubmit: loginEvent
    });

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
                <Spinner className={"loading-component"} label="I am loading..." />
            </Grid>
        );
    }

    return (
        <div className={"event-main-container"}>
            <Stack horizontalAlign={"center"} verticalAlign={"center"} className={"height-100per"}>
                <Stack className={"event-parent"} tokens={ThemedMediumStackTokens}>
                    <Stack horizontalAlign={"center"} className={"event-logo"}>
                        <img src={logo} />
                    </Stack>
                    <Stack tokens={ThemedMediumStackTokens} className={"event-child"}>
                        <Stack tokens={ThemedSmall2StackTokens}>
                            <span className={"font-size-20 font-weight-bold"}>Hello,</span>
                            <span className={"font-size-20"}>Welcome to Jambar's Virtual Team Building Event</span>
                            {showLogin ? (
                                <span className={"font-size-16"}>
                                    Please enter your team name & game code to continue
                                </span>
                            ) : (
                                <span className={"text-align-center font-size-16 error-message-font-color"}>
                                    Your event hasn’t started yet, please check back on your event day
                                </span>
                            )}
                        </Stack>
                        <br />
                        {showLogin ? (
                            <Stack tokens={ThemedSmall2StackTokens}>
                                <Stack.Item>
                                    <span className={"font-size-16"}>Enter Name</span>
                                    <InputGroup className={values.name === "" ? undefined : "mb-3"}>
                                        <FormControl
                                            required
                                            value={values.name}
                                            name={"name"}
                                            onChange={handleChange}
                                            onBlur={() => setFieldTouched("name")}
                                            isInvalid={!!errors.name && touched.name}
                                        />
                                    </InputGroup>
                                    {!!errors.name && touched.name && (
                                        <span className={"error-message"}>{errors.name}</span>
                                    )}
                                </Stack.Item>
                                <Stack.Item>
                                    <span className={"font-size-16"}>Enter Email</span>
                                    <InputGroup className={values.email === "" ? undefined : "mb-3"}>
                                        <FormControl
                                            value={values.email}
                                            name={"email"}
                                            onChange={handleChange}
                                            onBlur={() => setFieldTouched("email")}
                                            isInvalid={!!errors.email && touched.email}
                                        />
                                    </InputGroup>
                                    {!!errors.email && touched.email && (
                                        <span className={"error-message"}>{errors.email}</span>
                                    )}
                                </Stack.Item>
                                <Stack.Item>
                                    {loginFailed && (
                                        <span className={"error-message"}>
                                            Something went wrong please submit again
                                        </span>
                                    )}
                                </Stack.Item>
                            </Stack>
                        ) : null}

                        <Stack horizontal horizontalAlign={"space-between"}>
                            {showLogin ? (
                                <Button
                                    disabled={values.name === ""}
                                    className={"login-button"}
                                    onClick={() => handleSubmit()}>
                                    Login
                                </Button>
                            ) : null}

                            <Button
                                className={"get-help-button"}
                                onClick={() => window.open("https://jambarteambuilding.com/contact-us-now", "_blank")}>
                                Get Help
                            </Button>
                        </Stack>
                        <Stack.Item className={"text-align-center"}>
                            <span>
                                By logging in, you agree to the{" "}
                                <a href={"https://jambarteambuilding.com/contact-us-now"} target={"_blank"}>
                                    terms and conditions
                                </a>{" "}
                                of using our service
                            </span>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>
        </div>
    );
};

export default MiniLoginView;

import { ApiConstants } from "../../../../../constants/ApiConstant";
import { Client } from "../../../../Base/Client";
import { bulkCodeType } from "./type";

export const addBulkEventCode = (eventId: number, bulkCode: bulkCodeType[]): Promise<string> => {
    return new Promise<any>((resolve, reject) => {
        Client.getInstance()
            .createData(ApiConstants.addBulkEventCodeApiUrl(eventId), { codes: bulkCode })
            .then((response) => {
                if (response.status === 200) {
                    resolve("Bulk codes added");
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};

import { IColumn, IconButton, Panel, PanelType, Stack } from "@fluentui/react";
import React, { useState, useEffect } from "react";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { QuizAttachmentListItem } from "./QuizDetailsDataTypes";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import QuizAttachmentDetailsView from "./QuizAttachmentDetailsView";
import DeleteConfirmationDialog from "../../../CommonComponent/DeleteConfirmationDialog";
import { Client } from "../../../Base/Client";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../../constants/JambarDateUtils";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import FixedHeaderSortingDetailsList from "../../../CommonComponent/FixedHeaderSortingDetilsList";

type QuizAttachmentViewProps = {
    quizId: number;
};

const QuizAttachmentView: React.FC<QuizAttachmentViewProps> = ({ quizId }) => {
    const [attachmentList, setAttachmentList] = useState<QuizAttachmentListItem[]>([]);
    const [showAddEditAttachmentPanel, setAddEditAttachmentPanelDiplay] = useState(false);
    const [selectedAttachmentId, setSelectedAttachmentId] = useState(-1);
    const [showDeleteConfirmationDialog, setDeleteConfirmationDialogDisplay] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        fetchAllAttachment();
    }, []);

    const fetchAllAttachment = (): void => {
        Client.getInstance()
            .getData(ApiConstants.getAllQuizAttachmentsApiUrl(quizId), true)
            .then((response) => {
                const attachmentListData: QuizAttachmentListItem[] = response.data.data.map(
                    (attachment: any, index: number) => ({
                        id: attachment.id,
                        title: attachment.title,
                        createdBy: `${attachment.createdByUser.firstName} ${attachment.createdByUser.lastName}`,
                        createdDate: JambarDateUtil.formatDate(attachment.createdAt),
                        lastUpdatedBy: `${attachment.updatedByUser.firstName} ${attachment.updatedByUser.lastName}`,
                        lastUpdatedDate: JambarDateUtil.formatDate(attachment.updatedAt),
                        index: index + 1
                    })
                );
                setAttachmentList(attachmentListData);
                setLoading(false);
            });
    };

    const onOpenEditPanel = (id: number): void => {
        setAddEditAttachmentPanelDiplay(true);
        setSelectedAttachmentId(id);
    };

    const onCloseAddEditPanel = (): void => {
        setAddEditAttachmentPanelDiplay(false);
        setSelectedAttachmentId(-1);
        fetchAllAttachment();
    };

    const onDeleteAttachment = (id: number): void => {
        setDeleteConfirmationDialogDisplay(true);
        setSelectedAttachmentId(id);
    };

    const onConfirmDelete = (): void => {
        Client.getInstance()
            .deleteData(ApiConstants.deleteQuizAttachmentApiUrl(quizId, selectedAttachmentId))
            .then(() => {
                setDeleteConfirmationDialogDisplay(false);
                setSelectedAttachmentId(-1);
                fetchAllAttachment();
            })
            .catch((error) => {
                setDeleteConfirmationDialogDisplay(false);
                setSelectedAttachmentId(-1);
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    const onCancelDelete = (): void => {
        setDeleteConfirmationDialogDisplay(false);
        setSelectedAttachmentId(-1);
    };

    const listColumns: IColumn[] = [
        {
            key: "id",
            name: "Id",
            fieldName: "id",
            minWidth: SiteConstants.listColumnSerialNumber,
            maxWidth: SiteConstants.listColumnSerialNumber,
            isResizable: true
        },
        {
            key: "title",
            name: "Title",
            fieldName: "title",
            minWidth: SiteConstants.listColumnLarge
        },
        {
            key: "createdBy",
            name: "Created By",
            fieldName: "createdBy",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        },
        {
            key: "lastUpdated",
            name: "Updated By",
            fieldName: "lastUpdated",
            minWidth: SiteConstants.listColumnSmall,
            maxWidth: SiteConstants.listColumnMedium
        },
        {
            key: "select",
            name: "",
            fieldName: "select",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        },
        {
            key: "delete",
            name: "",
            fieldName: "delete",
            minWidth: SiteConstants.listColumnIcon,
            maxWidth: SiteConstants.listColumnIcon
        }
    ];

    const renderItemColumn = (
        item: QuizAttachmentListItem,
        index: number | undefined,
        column: IColumn | undefined
    ): JSX.Element => {
        if (!column) {
            return <span />;
        }

        switch (column.key) {
            case "createdBy":
                return (
                    <>
                        {item.createdBy}
                        <br />
                        on <i>{item.createdDate}</i>
                    </>
                );
            case "lastUpdated":
                return (
                    <>
                        {item.lastUpdatedBy}
                        <br />
                        on <i>{item.lastUpdatedDate}</i>
                    </>
                );
            case "select":
                return (
                    <IconButton
                        iconProps={{ iconName: "Go" }}
                        title="Select"
                        onClick={() => item.id && onOpenEditPanel(item.id)}
                        className={"editAttachmentTc"}
                    />
                );

            case "delete":
                return (
                    <IconButton
                        iconProps={{ iconName: "delete" }}
                        title="Delete"
                        onClick={() => item.id && onDeleteAttachment(item.id)}
                        className={"deleteAttachmentTc"}
                    />
                );
            case "id":
                return <span>{item.index}</span>;
            default: {
                const fieldContent = item[column.fieldName as keyof QuizAttachmentListItem] as string;
                return <span>{fieldContent}</span>;
            }
        }
    };

    const saveListOrder = (list: QuizAttachmentListItem[]): void => {
        setAttachmentList(list);
        Client.getInstance()
            .updateData(ApiConstants.saveQuizAttchmentsOrder(quizId), {
                orderedAttachmentIds: list.map((item: QuizAttachmentListItem) => item.id).join(",")
            })
            .then(() => {
                toast.success("Attachments updated", SiteConstants.successToastConfiguration);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            });
    };

    return (
        <Stack className={"height-100per"} tokens={ThemedMediumStackTokens}>
            <FixedHeaderSortingDetailsList
                addNewButtonClassName={"addAttachmentTc"}
                onAddButtonClick={() => {
                    setAddEditAttachmentPanelDiplay(true);
                    setSelectedAttachmentId(-1);
                }}
                buttonLabel={"Add Attachment"}
                isListDraggable={true}
                hideSearchField={true}
                columns={listColumns}
                isLoading={isLoading}
                items={attachmentList}
                onRenderItemColumn={renderItemColumn}
                getTransformedItems={(list: QuizAttachmentListItem[]) => saveListOrder(list)}
            />

            <DeleteConfirmationDialog
                entryName={"attachment"}
                isShown={showDeleteConfirmationDialog}
                onConfirm={onConfirmDelete}
                onDismiss={onCancelDelete}
            />
            <Panel
                className={"addEditAttachmentPanelTc"}
                isLightDismiss
                isOpen={showAddEditAttachmentPanel}
                onDismiss={() => {
                    setAddEditAttachmentPanelDiplay(false);
                    setSelectedAttachmentId(-1);
                }}
                headerText={selectedAttachmentId === -1 ? "Add Attachment" : "Edit Attachment"}
                type={PanelType.large}>
                <QuizAttachmentDetailsView
                    quizId={quizId}
                    attachmentId={selectedAttachmentId}
                    closePanel={onCloseAddEditPanel}
                />
            </Panel>
        </Stack>
    );
};

export default QuizAttachmentView;

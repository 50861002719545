export const addTime = (prevTime: string, newTiming: string) => {
    const prev = prevTime.split(":");
    const newTime = newTiming.split(":");
    const seconds =
        parseInt(prev[0]) * 60 * 60 +
        parseInt(prev[1]) * 60 +
        parseInt(prev[2]) +
        parseInt(newTime[0]) * 60 * 60 +
        parseInt(newTime[1]) * 60 +
        parseInt(newTime[2]);

    return new Date(seconds * 1000).toISOString().substr(11, 8);
};

export const getRemainingTime = (totalTime: string, timeTaken: string) => {
    const totalTimeArray = totalTime.split(":");
    const timeTakenArray = (timeTaken || "00:00:00").split(":");
    const seconds =
        parseInt(totalTimeArray[0]) * 60 * 60 +
        parseInt(totalTimeArray[1]) * 60 +
        parseInt(totalTimeArray[2]) -
        (parseInt(timeTakenArray[0]) * 60 * 60 + parseInt(timeTakenArray[1]) * 60 + parseInt(timeTakenArray[2]));
    return new Date(Math.abs(seconds) * 1000).toISOString().substr(11, 8);
};

export const covertToMMSS = (time: string): string => {
    const [hours, minutes, seconds] = time.split(":");
    return `${(parseInt(minutes) + parseInt(hours) * 60).toString().padStart(2, "0")}:${seconds.padStart(2, "0")}`;
};

export const convertTimeTo12HoursFormat = (time: string): string => {
    const date = new Date(time);
    return `${
        date.getHours() > 12
            ? (date.getHours() - 12).toString().padStart(2, "0")
            : date.getHours().toString().padStart(2, "0")
    }:${date.getMinutes().toString().padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
};

export const convertTimeTo24HoursFormat = (time: string): string => {
    // Append a dummy date to the time string to use the Date object
    const date = new Date(time);

    // Extract hours, minutes, and seconds in 24-hour format
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
};

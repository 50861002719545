import { Typography } from "@mui/material";
import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { BrowserCheckColumn } from "./BrowserCheckStyle";
import { SetupWizardParent } from "../InitialSetupStyle";
import { useShowBrowserList } from "./hooks";

const BrowserCheckView = () => {
    const showBrowserList = useShowBrowserList();

    return (
        <Container style={{ display: "flex", justifyContent: "center" }}>
            <SetupWizardParent>
                <Col xs={12}>
                    <h3>Welcome to the game</h3>
                </Col>
                <Col xs={12}>
                    <h5>Before you join, let's make sure your game experience will be great</h5>
                </Col>
                <Col xs={12} style={{ marginTop: 30 }}>
                    <h6>Get the best experience with chrome browser</h6>
                </Col>
                <BrowserCheckColumn xs={12}>
                    <Typography style={{ width: "73%" }}>
                        Your current browser may not work properly. To ensure you enjoy the best experience, performance
                        and security, please use the most recent version of either of the following:
                    </Typography>
                </BrowserCheckColumn>
                <BrowserCheckColumn xs={12}>
                    <Row>{showBrowserList}</Row>
                </BrowserCheckColumn>
            </SetupWizardParent>
        </Container>
    );
};

export default BrowserCheckView;

import { array, number, object, string } from "yup";
import { ApiConstants } from "../../../constants/ApiConstant";
import { Client } from "../../Base/Client";
import { cacheImages } from "../../WebsiteGame/util";
import { PublicGameFormType, PublicGameType } from "./type";

const transformEventData = (data: any, quizDetail: any): PublicGameType | null => {
    if (!data) {
        return null;
    }
    return {
        companyLogo: data.companyLogo,
        id: data.id,
        isDemo: data.isDemo,
        logo: data.logo,
        name: data.name,
        quizType: data.quizType,
        showSurveyAtEnd: data.showSurveyAtEnd,
        type: data.type,
        quizTitle: quizDetail.title
    };
};

export const fetchEventData = (eventLink: string) => {
    return Client.getInstance()
        .getData(ApiConstants.getEventDataApiUrl(eventLink))
        .then((res: any) => {
            Client.getInstance()
                .getData(ApiConstants.getEventImages(eventLink), true)
                .then((res) => {
                    cacheImages([...res.data.data, "../Images/Wildgoat/Skip.svg"]);
                });
            return Promise.resolve(transformEventData(res.data.data.event, res.data.data.quiz));
        })
        .catch(() => {
            return Promise.reject();
        });
};

export const formValidationSchema = object().shape({
    noOfPlayer: number().required("Number of players is required").min(1, "At least one player is required"),
    emailIds: array()
        .of(string().email("Invalid email format").required("Email is required"))
        .min(1, "At least one email is required")
});

export const createpPublicEvent = (data: PublicGameFormType, eventId: number) => {
    return Client.getInstance()
        .createData(ApiConstants.preparePublicEventApiUrl(), {
            eventId: eventId,
            numberOfParticipants: data.noOfPlayer,
            participantEmails: data.emailIds
        })
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());
};

import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { getPermissions } from "../../../../utils/PermissionUtils";
import { StyledActionColumn, StyledColumn, StyledHeader } from "../../../CommonComponent/v2/datagrid/DataGridStyle";
import { useAdminContext } from "../../context/AdminAuthContext";
import { EventListItem } from "../../EventManager/EventListDataTypes";
import { fetchLeaderBoardList } from "../../FileManager/Util";
import { downloadEventFile } from "./filedetail/Util";
import { SelectedFileType } from "./Type";

export const useGetFileManagerHook = () => {
    const [selectedTab, setSelectedTab] = useState("active");
    const [searchText, setSearchText] = useState("");
    const [eventList, setEventList] = useState<EventListItem[]>([]);
    const [selectedEventId, setSelectedEventId] = useState<SelectedFileType | null>(null);
    const [openDownloadFileModal, setOpenDownloadFileModal] = useState(false);

    const { userDetail } = useAdminContext();
    const [loading, setIsLoading] = useState(false);

    const permission: string[] | null = getPermissions(userDetail, "event");

    useEffect(() => {
        setIsLoading(true);
        if (!(permission && permission.includes("fetch"))) {
            setEventList([]);
            setIsLoading(false);
            return;
        }
        fetchLeaderBoardList()
            .then((response) => {
                setEventList(response);
                setIsLoading(false);
            })
            .catch(() => {
                setEventList([]);
            });
    }, []);

    const fileTabs = useMemo(
        () => [
            {
                label: "Active",
                value: "active",
                toShow: true
            },
            {
                label: "Closed",
                value: "close",
                toShow: true
            }
        ],
        []
    );

    return {
        selectedTab,
        setSelectedTab,
        fileTabs,
        searchText,
        setSearchText,
        eventList,
        setEventList,
        loading,
        selectedEventId,
        setSelectedEventId,
        setOpenDownloadFileModal,
        openDownloadFileModal
    };
};

export const useFileManagerListHook = () => {
    const fileManagerListColumns = (handleEdit: (eventInfo: SelectedFileType) => void): GridColDef[] => [
        {
            field: "eventName",
            headerName: "Event",
            flex: 1,
            renderHeader: () => <StyledHeader label="Event" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.eventName} />
        },
        {
            field: "startDate",
            headerName: "Start Date",
            flex: 1,
            renderHeader: () => <StyledHeader label="Start Date" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.startDate} />
        },
        {
            field: "gameTitle",
            headerName: "",
            flex: 1,
            renderHeader: () => <StyledHeader label="Game Title" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.gameTitle} />
        },
        {
            field: "id",
            headerName: "Action",
            renderHeader: () => <StyledHeader label="Action" />,
            renderCell: (params: GridCellParams) => (
                <StyledActionColumn
                    isViewAvailaible={{
                        isAvailable: true,
                        onClick: () =>
                            handleEdit({
                                eventId: params.row.id,
                                eventName: params.row.eventName
                            })
                    }}
                    isDownloadAvailaible={{
                        isAvailable: true,
                        onClick: () => downloadEventFile(params.row.id)
                    }}
                />
            )
        }
    ];

    return {
        fileManagerListColumns
    };
};

import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { OptionType } from "./Type";

type MultiSelectWrapperProps = {
    options: OptionType[];
    handleOptionUpdate: (selectedOption: string[]) => void;
} & SelectProps;
const MultiSelectWrapper = ({ options, handleOptionUpdate, ...rest }: MultiSelectWrapperProps) => {
    const handleChange = (event: SelectChangeEvent<any>) => {
        const {
            target: { value }
        } = event;
        handleOptionUpdate(value);
    };

    const renderValue = (selectedIds: string[]) => {
        const optionsLabels: string[] = [];
        selectedIds.forEach((id) => {
            optionsLabels.push(options.find((option) => option.value === id)?.label ?? "");
        });
        return optionsLabels.join(", ");
    };
    return (
        <FormControl style={{ width: "100%", backgroundColor: "#FCFCFC" }}>
            <InputLabel style={{ color: "#828282" }}> {rest.label} </InputLabel>
            <Select
                multiple
                onChange={handleChange}
                input={<OutlinedInput label={rest.label} />}
                renderValue={renderValue}
                {...rest}>
                {options.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={(rest.value as string[]).indexOf(opt.value) > -1} />
                        <ListItemText primary={opt.label || ""} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiSelectWrapper;

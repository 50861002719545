import { CircularProgress, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { FC } from "react";
import { CancelButton, PrimaryButton } from "../../../../../../CommonComponent/v2/CommonStyle";
import FormikSingleDatePicker from "../../../../../../CommonComponent/v2/fields/FormikSingleDatePicker";
import FormikTextInput from "../../../../../../CommonComponent/v2/fields/FormikTextInput";

type GenerateBulkCodeProps = {
    handleClose: () => void;
    loading: boolean;
};
const GenerateBulkCode: FC<GenerateBulkCodeProps> = ({ handleClose, loading }) => {
    const { errors, handleSubmit } = useFormikContext();

    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={8} textAlign={"center"}>
                <Grid container mt={"35px"} spacing={"20px"}>
                    <Grid item xs={6}>
                        <FormikSingleDatePicker name={"startDate"} dateLabel={"Valid from"} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikSingleDatePicker name={"endDate"} dateLabel={"Upto"} fullWidth />
                    </Grid>
                </Grid>
                <Grid container mt={"10px"} spacing={"20px"}>
                    <Grid item xs={12}>
                        <FormikTextInput label={"No. of codes"} name={"numberOfCode"} id={"numberOfCode"} fullWidth />
                    </Grid>
                </Grid>
                <Grid container mt={"10px"} spacing={"20px"}>
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"notes"}
                            id={"notes"}
                            multiline
                            label={"Notes for all codes..."}
                            rows={5}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container mt={"62px"} justifyContent={"center"}>
                    <Grid item>
                        <div style={{ display: "flex", gap: "20px" }}>
                            <CancelButton onClick={handleClose}>Cancel</CancelButton>
                            <PrimaryButton
                                startIcon={loading && <CircularProgress size={"small"} />}
                                onClick={() => handleSubmit()}
                                disabled={loading || !isEmpty(errors)}>
                                Save Code
                            </PrimaryButton>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default GenerateBulkCode;

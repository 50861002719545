import { Stack } from "@fluentui/react";
import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Button } from "react-bootstrap";
import { mobileBreakdownPoint } from "../../../../constants/SiteConstant";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import rocket from "../../../Images/rocket2.png";
import { ReferenceCloseButton, ReferenceCloseContainer } from "./style";

type IReferenceViewCoverImageNativeProps = {
    hideReferenceItemInfo: () => void;
    displayReferenceItemInfo: boolean;
    coverImage: string;
    gameBackgroundColor: string;
};

const ReferenceViewCoverImage: React.FC<IReferenceViewCoverImageNativeProps> = ({
    hideReferenceItemInfo,
    displayReferenceItemInfo,
    coverImage,
    gameBackgroundColor
}) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(mobileBreakdownPoint));

    if (mobileView) {
        return (
            <ReferenceCloseContainer tokens={ThemedMediumStackTokens}>
                {displayReferenceItemInfo && (
                    <ReferenceCloseButton onClick={hideReferenceItemInfo}>
                        <span>Close and Return</span>
                    </ReferenceCloseButton>
                )}
            </ReferenceCloseContainer>
        );
    }
    return (
        <Stack
            className={"refrence-quiz-cover-image"}
            tokens={ThemedMediumStackTokens}
            style={{ backgroundColor: gameBackgroundColor }}>
            <img
                src={coverImage ? coverImage : rocket}
                style={{
                    objectFit: "contain",
                    width: "100%",
                    height: displayReferenceItemInfo ? "calc(100% - 92px)" : "100%"
                }}
            />
            {displayReferenceItemInfo ? (
                <Button className={"reference-close-button"} onClick={hideReferenceItemInfo}>
                    <span>Close and Return</span>
                </Button>
            ) : null}
        </Stack>
    );
};

export default ReferenceViewCoverImage;

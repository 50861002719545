/* eslint-disable @typescript-eslint/no-var-requires */
import { SpinnerSize, Spinner } from "@fluentui/react";
import { Typography, Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { MAP_KEY } from "../../../../constants/SiteConstant";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { GoogleMap, useJsApiLoader, MarkerClusterer, MarkerF } = require("@react-google-maps/api");

type TriggerMapProps = {
    coords: {
        latitude: number;
        longitude: number;
    };
    targetLocation: {
        latitude: number;
        longitude: number;
    };
};
const TriggerMap: FC<TriggerMapProps> = ({ coords, targetLocation }) => {
    const [markers, setMarkers] = useState<
        {
            lat: number;
            lng: number;
        }[]
    >([]);

    useEffect(() => {
        setMarkers([
            {
                lat: coords.latitude,
                lng: coords.longitude
            },
            {
                lat: targetLocation.latitude,
                lng: targetLocation.longitude
            }
        ]);
    }, []);

    const containerStyle = {
        width: "100%",
        height: "400px"
    };

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: MAP_KEY
    });

    const renderView = () => {
        if (loadError) {
            return (
                <Grid height={"400px"} container justifyContent="center" alignItems="center">
                    <Typography variant="h5">Error loading maps</Typography>
                </Grid>
            );
        }

        if (!isLoaded) {
            return (
                <Grid height={"400px"} container justifyContent="center" alignItems="center">
                    <Spinner label="Loading Maps. Please wait" size={SpinnerSize.large} />
                </Grid>
            );
        }

        return (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={{
                    lat: coords.latitude,
                    lng: coords.longitude
                }}
                zoom={7}
            >
                <GoogleMap
                    id="marker-example"
                    mapContainerStyle={containerStyle}
                    zoom={11}
                    center={{
                        lat: coords.latitude,
                        lng: coords.longitude
                    }}
                >
                    <MarkerClusterer>
                        {(clusterer: any) => (
                            <>
                                {markers.map((location) => (
                                    <MarkerF key={Math.random().toString()} position={location} clusterer={clusterer} />
                                ))}
                            </>
                        )}
                    </MarkerClusterer>
                </GoogleMap>
            </GoogleMap>
        );
    };

    return <div>{renderView()}</div>;
};

export default TriggerMap;

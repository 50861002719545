import { Drawer, Grid } from "@mui/material";
import { Formik } from "formik";
import { FC } from "react";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { Header1 } from "../../../../CommonComponent/v2/CommonStyle";
import { SelectIdentityType } from "../Type";
import IdentityForm from "./IdentityForm";
import { addIdentity, IdentityDetailValidationSchema, updateIdentity } from "./Util";

type IdentityDetailDrawerProps = {
    closeDrawer: () => void;
    selectedIdentity?: SelectIdentityType;
    refreshIdentityList: () => void;
};
const IdentityDetailDrawer: FC<IdentityDetailDrawerProps> = ({
    closeDrawer,
    selectedIdentity,
    refreshIdentityList
}) => {
    const saveIdentity = (value: { identityName: string }) => {
        if (selectedIdentity && selectedIdentity.id === -1) {
            addIdentity(value.identityName)
                .then(() => {
                    refreshIdentityList();
                    closeDrawer();
                })
                .catch((err: any) => {
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                });
            return;
        }
        selectedIdentity &&
            updateIdentity(value.identityName, selectedIdentity.id)
                .then(() => {
                    refreshIdentityList();
                    closeDrawer();
                })
                .catch((err: any) => {
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                });
    };

    return (
        <Drawer
            anchor={"right"}
            open
            PaperProps={{
                style: {
                    width: "50%"
                }
            }}
            onClose={closeDrawer}>
            <Grid p={"20px"}>
                <Header1>
                    {selectedIdentity && selectedIdentity.id !== -1 ? "Edit Identity" : "Add new Identity"}
                </Header1>
                <Formik
                    initialValues={{
                        identityName: selectedIdentity?.name || ""
                    }}
                    validationSchema={IdentityDetailValidationSchema}
                    onSubmit={saveIdentity}
                    validateOnMount={false}
                    enableReinitialize>
                    {() => {
                        return <IdentityForm />;
                    }}
                </Formik>
            </Grid>
        </Drawer>
    );
};

export default IdentityDetailDrawer;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Dialog, Grid } from "@mui/material";
import { FC } from "react";
import { CaptionText, Header1 } from "../../../CommonComponent/v2/CommonStyle";
import { useQuestionInfoHook } from "./Hooks";
import LoadingWrapper from "../../../CommonComponent/v2/LoadingWrapper";

type QuestionInfoTooltipProps = {
    particpantCode: string;
    eventLink: string;
    handleClose: () => void;
};
const QuestionInfoTooltip: FC<QuestionInfoTooltipProps> = ({ particpantCode, eventLink, handleClose }) => {
    const { question, isLoading } = useQuestionInfoHook(eventLink, particpantCode);

    return (
        <Dialog maxWidth={"xs"} fullWidth open onClose={handleClose}>
            <Grid p={"40px"}>
                <Header1 mb={"50px"}>Current Question</Header1>
                <LoadingWrapper isLoading={isLoading} loadingHeight="30px">
                    <Box>
                        <CaptionText>
                            Game name: <span style={{ color: "#333333" }}>{question?.title}</span>
                        </CaptionText>
                        <CaptionText
                            dangerouslySetInnerHTML={{
                                __html: question?.questionText || ""
                            }}
                        />
                        <CaptionText
                            dangerouslySetInnerHTML={{
                                __html: question?.questionText2 || ""
                            }}
                        />
                    </Box>
                </LoadingWrapper>
            </Grid>
        </Dialog>
    );
};

export default QuestionInfoTooltip;

import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import ConfirmationBox from "../../../CommonComponent/v2/ConfirmationBox";
import SearchHeader from "../../../CommonComponent/v2/searchheader/SearchHeader";
import TabWrapper from "../../../CommonComponent/v2/tab/Tab";
import { useUserIdentityHook } from "./Hooks";
import IdentitiesList from "./IdentitiesList";
import IdentityDetailDrawer from "./identitydetail/IdentityDetailDrawer";
import TransferOwnerShip from "./transferownership";
import { SelectIdentityType, SelectUserDeleteType } from "./Type";
import UserDetailDrawer from "./userdetail/UserDetailDrawer";
import UsersList from "./UsersList";
import { deleteIdentity, getDeleteUser } from "./Util";

const Users = () => {
    const {
        selectedTab,
        searchText,
        setSearchText,
        setSelectedTab,
        userTabs,
        selectedId,
        setSelectedId,
        selectDeleteId,
        setSelectDeleteId,
        refreshKey,
        setRefreshKey,
        permission,
        IS_SUPER_ADMIN
    } = useUserIdentityHook();

    const renderList = () => {
        if (selectedTab === "user") {
            return (
                <UsersList
                    selectedUserDeleteId={setSelectDeleteId}
                    key={refreshKey}
                    setSelectedId={setSelectedId}
                    searchText={searchText}
                />
            );
        }
        return (
            <IdentitiesList
                setSelectedDeleteUserId={setSelectDeleteId}
                setSelectedIdentity={setSelectedId}
                key={refreshKey}
                searchText={searchText}
            />
        );
    };

    const onUserConfirmDelete = (ownerId: number) => {
        const { id } = selectDeleteId as SelectUserDeleteType;
        id &&
            getDeleteUser(id || -1, ownerId)
                .then(() => {
                    setSelectDeleteId(null);
                    setRefreshKey(Math.random().toString());
                    toast.success("User deleted successfully");
                })
                .catch((err) => {
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                });
    };

    const onIdentityConfirmDelete = () => {
        selectDeleteId &&
            deleteIdentity(selectDeleteId as number)
                .then(() => {
                    setSelectDeleteId(null);
                    setRefreshKey(Math.random().toString());
                    toast.success("Identity deleted successfully");
                })
                .catch(() => {
                    setSelectDeleteId(null);
                    toast.error("Something Went wrong");
                });
    };

    const renderDrawer = () => {
        if (selectedId && selectedTab === "user") {
            return (
                <UserDetailDrawer
                    refreshUserList={() => setRefreshKey(Math.random().toString())}
                    selectedUserId={selectedId as number}
                    closeDrawer={() => setSelectedId(null)}
                    openModalForEdit={(id) => setSelectedId(id)}
                />
            );
        }
        if (selectedId && selectedTab === "identities") {
            return (
                <IdentityDetailDrawer
                    refreshIdentityList={() => setRefreshKey(Math.random().toString())}
                    selectedIdentity={selectedId as SelectIdentityType}
                    closeDrawer={() => setSelectedId(null)}
                />
            );
        }
    };

    const renderDeleteModal = () => {
        if (selectDeleteId && selectedTab === "user") {
            return (
                <TransferOwnerShip
                    saveTransferOwnerShip={onUserConfirmDelete}
                    selectedUserInfo={selectDeleteId as SelectUserDeleteType}
                    handleClose={() => setSelectDeleteId(null)}
                />
            );
        }
        if (selectDeleteId && selectedTab === "identities") {
            return (
                <ConfirmationBox
                    message="Are you sure you want to delete this Identity?"
                    onClose={() => setSelectDeleteId(null)}
                    title="Delete Identity"
                    onConfirm={onIdentityConfirmDelete}
                />
            );
        }
        return null;
    };

    return (
        <Grid container padding={"20px"}>
            <Grid item xs={12}>
                <SearchHeader
                    searchText={searchText}
                    setSearchText={setSearchText}
                    showAddButton={!!(permission && permission.includes("add"))}
                    onButtonClick={() =>
                        selectedTab === "user"
                            ? setSelectedId(-1)
                            : setSelectedId({
                                  id: -1,
                                  name: ""
                              })
                    }
                    buttonLabel={
                        selectedTab === "user" ? `Add new ${IS_SUPER_ADMIN ? "client" : "user"}` : "Add new identity"
                    }
                />
                <Grid container spacing={"20px"} mt={"20px"}>
                    <Grid item xs={12}>
                        <TabWrapper tabsOptions={userTabs} selectedTab={selectedTab} handleChange={setSelectedTab} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={"30px"} item xs={12}>
                {renderList()}
            </Grid>
            {renderDrawer()}
            {renderDeleteModal()}
        </Grid>
    );
};

export default Users;

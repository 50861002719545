import { useFormikContext } from "formik";
import { RegsiterUserFormDetailType } from "../type";
import { Box, CircularProgress, Grid } from "@mui/material";
import FormikTextInput from "../../../CommonComponent/v2/fields/FormikTextInput";
import PasswordValidator from "../../../CommonComponent/v2/PasswordValidator";
import { PrimaryButton } from "../../../CommonComponent/v2/CommonStyle";

type RegisterFormProps = {
    loading: boolean;
};
const RegisterForm = ({ loading }: RegisterFormProps) => {
    const { handleSubmit, values } = useFormikContext();
    const data = values as RegsiterUserFormDetailType;

    return (
        <Grid container spacing={"15px"} mt={"30px"}>
            <Grid item xs={12}>
                <FormikTextInput id={"firstName"} name={"firstName"} label={"First name"} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <FormikTextInput id={"lastName"} name={"lastName"} label={"Last name"} fullWidth />
            </Grid>
            <Grid item xs={12}>
                <FormikTextInput disabled id={"email"} name={"email"} label={"Email"} fullWidth />
            </Grid>

            <Grid item xs={12}>
                <FormikTextInput
                    id={"password"}
                    name={"password"}
                    label={"Password"}
                    textfieldType={"password"}
                    fullWidth
                />
                <Box mt={"10px"}>
                    <PasswordValidator value={data.password} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <FormikTextInput
                    id={"confirmPassword"}
                    name={"confirmPassword"}
                    textfieldType={"password"}
                    label={"Confirm Password"}
                    fullWidth
                />
            </Grid>

            <Grid item xs={12}>
                <Grid container justifyContent={"center"}>
                    <PrimaryButton
                        style={{
                            height: 50,
                            width: 182,
                            padding: 16
                        }}
                        startIcon={loading && <CircularProgress />}
                        disabled={loading}
                        onClick={() => handleSubmit()}>
                        Submit
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RegisterForm;

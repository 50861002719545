import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import { SurveyType } from "./Type";
import { getSurveyDetail } from "./Util";

export const useGetSurveyDetailHook = () => {
    const [surveyDetails, setSurveyDetails] = useState<SurveyType[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const { id } = useParams<{
        id: string;
    }>();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setIsLoading(true);
        getSurveyDetail(id)
            .then((res) => setSurveyDetails(res))
            .catch((error) => toast.error(error, SiteConstants.deleteToastConfiguration))
            .finally(() => setIsLoading(false));
    };

    return {
        isLoading,
        surveyDetails
    };
};

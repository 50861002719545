import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { darkPrimaryColor } from "../../../../../../constants/ColorConstant";
import FormikTextEditor from "../../../../../CommonComponent/v2/fields/FormikTextEditor";
import FormikTextInput from "../../../../../CommonComponent/v2/fields/FormikTextInput";
import ImageDrop from "../../../../../CommonComponent/v2/fields/ImageDrop";
import { AddReferenceType } from "../Type";
import { useLaunchReferenceHook } from "./Hooks";
import { useDebounce } from "../../../../../../commonhooks/Hooks";
import CopyIcon from "../../../../../Images/icons/copy.svg";
import QrCodeModal from "../../../../../CommonComponent/QrCodeModal";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import QrIcon from "../../../../../Images/icons/qr.svg";
import {
    CaptionText,
    CheckingTextfieldButton,
    ErrorTextfieldButton,
    SuccessTextfieldButton
} from "../../../../../CommonComponent/v2/CommonStyle";

const Details = () => {
    const { values } = useFormikContext();
    const data = values as AddReferenceType;
    const { availableType, isChecking, openQrModal, checkLinkStatus, setOpenQrModal, handleRemoveImage } =
        useLaunchReferenceHook();
    const renderIcon = (referenceLink: string) => {
        return (
            <>
                <img
                    src={CopyIcon}
                    onClick={() => window.navigator.clipboard.writeText(referenceLink)}
                    style={{ margin: "0px 21px", cursor: "pointer" }}
                />
                <img style={{ cursor: "pointer" }} src={QrIcon} onClick={() => setOpenQrModal(referenceLink)} />
            </>
        );
    };
    const debounceLinkName = useDebounce(data.referenceListCode, 300);
    useEffect(() => {
        debounceLinkName !== "" && checkLinkStatus(debounceLinkName);
    }, [debounceLinkName]);

    const renderAvailaiblityButton = () => {
        if (data.referenceListCode === "") {
            return null;
        }
        if (isChecking) {
            return <CheckingTextfieldButton>Checking...</CheckingTextfieldButton>;
        }
        if (availableType === "available") {
            return (
                <SuccessTextfieldButton>
                    <AiOutlineCheck style={{ fontSize: 25 }} /> Availaible
                </SuccessTextfieldButton>
            );
        }
        if (availableType === "notavailaible") {
            return (
                <ErrorTextfieldButton style={{ width: 150 }}>
                    <AiOutlineClose /> Not Availaible
                </ErrorTextfieldButton>
            );
        }
        return null;
    };
    return (
        <Grid container mt={"30px"} spacing={"20px"}>
            <Grid item xs={12}>
                <CaptionText fontWeight={700} color={darkPrimaryColor}>
                    Cover image
                </CaptionText>
                <ImageDrop
                    imageSize={2}
                    variant={"secondary"}
                    selectedFile={data.coverImage}
                    removeImage={() => handleRemoveImage("cover")}
                    logoInfo={"Attach cover image (800px * 600px)"}
                    name={"coverImage"}
                />
            </Grid>
            <Grid item xs={12}>
                <FormikTextInput name={"title"} id={"title"} label={"Title"} fullWidth />
            </Grid>

            <Grid item xs={12}>
                <CaptionText fontWeight={700} color={darkPrimaryColor}>
                    Description image
                </CaptionText>
                <ImageDrop
                    imageSize={2}
                    variant={"secondary"}
                    removeImage={() => handleRemoveImage("description")}
                    selectedFile={data.descriptionImage}
                    logoInfo={"Attach description image (800px * 600px)"}
                    name={"descriptionImage"}
                />
            </Grid>
            <Grid item xs={12}>
                <CaptionText mb={"10px"} fontWeight={700} color={darkPrimaryColor}>
                    Description
                </CaptionText>
                <FormikTextEditor name={"description"} placeholder={"Enter description here..."} />
            </Grid>
            <Grid item xs={7} mt={"40px"}>
                <FormikTextInput
                    id={"referenceListCode"}
                    name={"referenceListCode"}
                    label={"Reference link code"}
                    fullWidth
                    InputProps={{
                        endAdornment: renderAvailaiblityButton()
                    }}
                />
            </Grid>
            {data.referenceListCode && (
                <Grid item xs={12} mt={"40px"}>
                    <CaptionText fontWeight={700}>Preview links</CaptionText>
                    <CaptionText mt={"20px"}>
                        <span style={{ fontWeight: 700, marginRight: 10 }}>Reference Link:</span>
                        {window.location.origin}/referencelistmanager/{data.referenceListCode.split(" ").join("_")}
                        {renderIcon(
                            `${window.location.origin}/referencelistmanager/${data.referenceListCode
                                .split(" ")
                                .join("_")}`
                        )}
                    </CaptionText>
                </Grid>
            )}
            {openQrModal && <QrCodeModal url={openQrModal} handleClose={() => setOpenQrModal("")} />}
        </Grid>
    );
};
export default Details;

import { Grid } from "@mui/material";
import { useHistory } from "react-router";
import { Header1, PrimaryButton } from "../../../../../../CommonComponent/v2/CommonStyle";
import ArrowLeft from "../../../../../../Images/icons/arrowLeft.svg";
import BreadcrumbWrapper from "../../../../../../CommonComponent/v2/BreadCrumbWrapper";
import { Formik } from "formik";
import { attachmentBreadcrumbValues, addAttachmentInitialValues, validationSchema } from "./EditAttachmentUtils";
import { useAddAttachment } from "./Hooks";
import LoadingWrapper from "../../../../../../CommonComponent/v2/LoadingWrapper";
import LoadingButtonWrapper from "../../../../../../CommonComponent/v2/button/LoadingButtonWrapper";
import EditAttachmentForm from "./EditAttachmentForm";

const UpdateAttachment = () => {
    const history = useHistory();
    const { saveData, isLoading, attachmentDetails, id, attachmentId } = useAddAttachment();

    return (
        <Grid px={"20px"}>
            <Grid container>
                <Grid item xs={12}>
                    <Header1>
                        <img
                            onClick={() => history.push(`/admin/v2/referencelistmanager/${id}/update`)}
                            src={ArrowLeft}
                            style={{ height: 18, marginRight: 10, cursor: "pointer" }}
                        />
                        {attachmentId ? "Edit attachment" : "Add new attachment"}
                    </Header1>
                    <BreadcrumbWrapper links={attachmentBreadcrumbValues(id, attachmentId)} />
                </Grid>
                <LoadingWrapper isLoading={isLoading}>
                    <Formik
                        initialValues={addAttachmentInitialValues(attachmentDetails)}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, setFieldError }) => {
                            if (!values.imageFile && !values.attachmentFile) {
                                values.type === "image"
                                    ? setFieldError("imageFile", "Please select an image")
                                    : setFieldError("attachmentFile", "Please select a pdf file");
                                setSubmitting(false);
                                return;
                            }
                            saveData(values, setSubmitting);
                        }}
                        enableReinitialize
                        validateOnMount>
                        {({ handleSubmit, isSubmitting }) => (
                            <Grid item xs={12}>
                                <EditAttachmentForm />
                                <Grid container justifyContent={"center"} mt={"40px"} mb={"20px"}>
                                    <LoadingButtonWrapper loading={isSubmitting}>
                                        <PrimaryButton onClick={() => handleSubmit()}>Save</PrimaryButton>
                                    </LoadingButtonWrapper>
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </LoadingWrapper>
            </Grid>
        </Grid>
    );
};
export default UpdateAttachment;

{
    /*eslint-disable*/
}
import { ClickAwayListener, IconButton, TextField, TextFieldProps } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useField } from "formik";
import { FC, useState } from "react";
import { IconContainer } from "../CommonStyle";
import CalendarIcon from "../../../Images/icons/calendar.svg";
import { StyleSingleDatePickerTextfield } from "./Style";

type FormikSingleDatePickerProps = {
    name: string;
    dateLabel?: string;
} & TextFieldProps;
const FormikSingleDatePicker: FC<FormikSingleDatePickerProps> = ({ name, dateLabel, ...rest }) => {
    const [_, { error, value }, { setValue }] = useField(name);
    const [openDatePicker, setOpenDatePicker] = useState(false);

    return (
        <ClickAwayListener onClickAway={() => setOpenDatePicker(false)}>
            <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label={dateLabel}
                        value={value}
                        open={openDatePicker}
                        inputFormat={"dd/MM/yyyy"}
                        onChange={(newValue) => {
                            setValue(newValue);
                            setOpenDatePicker(false);
                        }}
                        renderInput={(params) => (
                            <StyleSingleDatePickerTextfield
                                {...params}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={() => setOpenDatePicker(true)}>
                                            <IconContainer src={CalendarIcon} />
                                        </IconButton>
                                    )
                                }}
                                helperText={!!error ? error : ""}
                                error={!!error}
                                {...rest}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>
        </ClickAwayListener>
    );
};

export default FormikSingleDatePicker;

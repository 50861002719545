import logo from "../../Images/logo.svg";
import React from "react";
import { Col, Row } from "react-bootstrap";

type JambarLogoViewProps = {
    eventLogo?: string;
    companyLogo?: string;
};
const JambarLogoView: React.FC<JambarLogoViewProps> = ({ eventLogo, companyLogo }) => {
    return (
        <Row className={"top-logo-container"}>
            {eventLogo && (
                <Col xs={6} className={"height-100per jambar-logo-view-parent"}>
                    <div className={"logo-view"}>
                        <img src={eventLogo} className={"game-logos"} alt="" />
                    </div>
                </Col>
            )}
            <Col xs={6} className={"event-logo-view-parent"}>
                <div className={"logo-view"}>
                    <img src={companyLogo || logo} className={"game-logos"} />
                </div>
            </Col>
        </Row>
    );
};

export default JambarLogoView;

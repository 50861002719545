import { BreadCrumbType, AddItemType } from "./Types";
import { object, string } from "yup";
import { Client } from "../../../../../../Base/Client";
import { ApiConstants } from "../../../../../../../constants/ApiConstant";
import { SiteConstants } from "../../../../../../../constants/SiteConstant";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../../../../../utils/APIErrorMessages";

export const ItemBreadcrumbValues = (id: string, refItemId?: string): BreadCrumbType[] => [
    {
        label: "Reference list / Edit reference list",
        url: `/admin/v2/referencelistmanager/${id}/update`
    },
    {
        label: refItemId ? "Edit item" : "Add new Item"
    }
];
export const addItemInitialValues = (attachmentDetails?: AddItemType): AddItemType => ({
    title: attachmentDetails?.title || "",
    descriptionImage: attachmentDetails?.descriptionImage || null,
    topDescription: attachmentDetails?.topDescription || "",
    bottomDescription: attachmentDetails?.bottomDescription || ""
});
export const validationSchema = object().shape(
    {
        title: string().required("Title is required"),
        topDescription: string().when("bottomDescription", {
            is: (val: string) => !val,
            then: string().required("Description is mandatory"),
            otherwise: string().nullable()
        }),
        bottomDescription: string().when("topDescription", {
            is: (val: string) => !val,
            then: string().required("Description is mandatory"),
            otherwise: string().nullable()
        })
    },
    [["topDescription", "bottomDescription"]]
);

export const reverseTransformData = (values: AddItemType): any => {
    const attachmentData = new FormData();
    attachmentData.append("name", values.title);
    attachmentData.append("descriptionImageFileLocation", values.descriptionImage as Blob);
    attachmentData.append("description", values.topDescription);
    attachmentData.append("description2", values.bottomDescription);
    return attachmentData;
};

export const addNewItem = (referenceListId: string, values: AddItemType) => {
    return Client.getInstance()
        .createData(ApiConstants.addReferenceItemApiUrl(), reverseTransformData(values))
        .then((referenceItemResponse: any) => {
            return Client.getInstance()
                .getData(ApiConstants.getReferenceDetailsApiUrl(parseInt(referenceListId)), true)
                .then((referenceListDetailsResponse: any) => {
                    const referenceListData = referenceListDetailsResponse.data.data;
                    const referenceItemsId = referenceListData.reference_list_items.map(
                        (element: any) => element.referenceItemId
                    );
                    Client.getInstance()
                        .updateData(ApiConstants.updateReferenceApiUrl(parseInt(referenceListId)), {
                            name: referenceListData.name,
                            description: referenceListData.description,
                            referenceItems: [...referenceItemsId, referenceItemResponse.data.data.id].join(",")
                        })
                        .then(() => {
                            toast.success("Reference item added", SiteConstants.successToastConfiguration);
                            return Promise.resolve();
                        })
                        .catch((error) => {
                            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                            return Promise.reject();
                        });
                })
                .catch((error) => {
                    toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
                    return Promise.reject();
                });
        })
        .catch((error) => Promise.reject(error));
};

export const getReferenceItemDetail = (referenceItemId: string) =>
    Client.getInstance()
        .getData(ApiConstants.getReferenceItemDetailsApiUrl(parseInt(referenceItemId)), true)
        .then((response) => {
            const data = response.data.data;
            return Promise.resolve({
                bottomDescription: data.description2 ? data.description2 : "",
                descriptionImage: data.descriptionImageFileLocation ? data.descriptionImageFileLocation : "",
                title: data.name,
                topDescription: data.description ? data.description : ""
            });
        })
        .catch(() => {
            return Promise.reject();
        });

export const removeReferenceDescriptionImage = (referenceItemId: string) => {
    return Client.getInstance()
        .deleteData(ApiConstants.removeReferenceitemDescrptionImage(parseInt(referenceItemId)))
        .then(() => {
            toast.success("Description image removed", SiteConstants.successToastConfiguration);
            return Promise.resolve();
        })
        .catch(() => {
            return Promise.reject();
        });
};

import { Grid, SliderThumb } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { ApiConstants } from "../../../../constants/ApiConstant";
import { getDistance } from "../../../../utils/JambarUtils";
import { PersonDistanceSlider } from "./Style";
import walking from "../../../Images/walking.png";
import { WildgoatTriggerQuestionType } from "../WildgoatType";
import { QuestionBox, ScrollBox } from "../CommonStyledComponents/CommonStyle";
import { Header2 } from "../../../CommonComponent/v2/CommonStyle";
import TriggerMap from "./TriggerMap";
import ShowLocationPermissionModal from "./ShowLocationPermissionModal";
import { Spinner } from "react-bootstrap";

type WildgoatTriggerProps = {
    trigger: WildgoatTriggerQuestionType;
    setIsReachedPosition: (isReached: boolean) => void;
};

interface PlayerThumbComponentProps extends React.HTMLAttributes<unknown> {}

const WildgoatTrigger: FC<WildgoatTriggerProps> = ({ trigger, setIsReachedPosition }) => {
    const [targetCoords, setTargetCoords] = useState<{
        latitude: number;
        longitude: number;
    }>({
        latitude: 0,
        longitude: 0
    });
    const [currentPosCoord, setCurrentPosCoords] = useState<{
        latitude: number;
        longitude: number;
    }>({
        latitude: 0,
        longitude: 0
    });
    const [distance, setDistance] = useState(0);
    const [initialDistance, setInitialDistance] = useState(0);
    const [words, setWords] = useState<string>("");
    const [targetPlace, setTargetPlace] = useState<string>("");
    const [showLocationPermissionModal, setShowLocationPermissionModal] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setWords(trigger.description);
        fetchCoords();
    }, []);

    useEffect(() => {
        if (currentPosCoord.latitude !== 0 && currentPosCoord.longitude) {
            const distance = getDistance(
                currentPosCoord.latitude,
                currentPosCoord.longitude,
                targetCoords.latitude,
                targetCoords.longitude
            );
            if (initialDistance === 0) {
                setInitialDistance(distance);
            }
            setDistance(distance);

            distance < trigger.radius && setIsReachedPosition(true);
        }
    }, [currentPosCoord]);

    const fetchCoords = () => {
        setIsLoading(true);
        fetch(ApiConstants.getLocationApiUrl(trigger.description))
            .then((res) => res.json())
            .then((data) => {
                setTargetPlace(data.nearestPlace);
                setTargetCoords({
                    latitude: data.coordinates.lat,
                    longitude: data.coordinates.lng
                });
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getLocation();
    }, [navigator.geolocation]);

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(showPosition, () => {
                setShowLocationPermissionModal("Please allow location");
            });
            return;
        }
        setShowLocationPermissionModal("Geolocation is not supported by this browser.");
    };

    const showPosition = (position: GeolocationPosition) => {
        setCurrentPosCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        });
    };

    const PlayerThumbComponent = (props: PlayerThumbComponentProps) => {
        const { children, ...other } = props;
        return (
            <SliderThumb
                style={{
                    height: 0,
                    width: 0
                }}
                {...other}>
                {children}
                <img style={{ position: "absolute", height: 40 }} src={walking} alt={"walking"} />
            </SliderThumb>
        );
    };

    const getResult = () => Math.round((distance / initialDistance) * 100);

    const renderDistance = () => {
        if (distance <= trigger.radius) {
            return "Congrats you are in range";
        }

        return `${distance.toFixed(0)}m left`;
    };

    if (isLoading) {
        return (
            <div style={{ flex: 1 }}>
                <QuestionBox style={{ marginTop: 11 }}>
                    <ScrollBox>
                        <Grid container justifyContent={"center"} alignItems={"center"} height={"100%"} width={"100%"}>
                            <Spinner animation="grow" />
                        </Grid>
                    </ScrollBox>
                </QuestionBox>
            </div>
        );
    }

    return (
        <div style={{ flex: 1 }}>
            <QuestionBox style={{ marginTop: 11 }}>
                <ScrollBox>
                    <Grid padding={"30px"} container justifyContent={"center"}>
                        <Grid item xs={12}>
                            <Header2 fontsize="18px" mb={"20px"} textAlign={"center"}>
                                Hey! you have to reach the position with these words {words}
                            </Header2>
                            <PersonDistanceSlider
                                components={{ Thumb: PlayerThumbComponent }}
                                value={distance <= trigger.radius ? trigger.radius : trigger.radius - getResult()}
                                key={getResult()}
                                valueLabelDisplay="auto"
                                valueLabelFormat={() => `${renderDistance()}`}
                            />
                            <Header2 fontsize="18px" mb={"20px"} textAlign={"center"}>
                                Distance from target - {distance.toFixed(0)}m
                            </Header2>
                            <Header2 fontsize="18px" mb={"20px"} textAlign={"center"}>
                                Location to reach
                            </Header2>
                            <Header2 fontsize="18px" mb={"20px"} textAlign={"center"}>
                                {targetPlace}
                            </Header2>
                        </Grid>
                        <Grid item xs={12}>
                            <TriggerMap targetLocation={targetCoords} coords={currentPosCoord} />
                        </Grid>
                    </Grid>
                </ScrollBox>
            </QuestionBox>
            {showLocationPermissionModal && (
                <ShowLocationPermissionModal
                    errorMessage={showLocationPermissionModal}
                    handleClose={() => {
                        return;
                    }}
                />
            )}
        </div>
    );
};

export default WildgoatTrigger;

export const MusicHardQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "Sorry, you're way off key.",
            imageSrc: "https://i.imgur.com/TFlhuKC.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Not bad, you have an average score.",
            imageSrc: "https://i.imgur.com/FMlgGwT.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "You're ready to start your own band!",
            imageSrc: "https://i.imgur.com/HwtJL76.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "What band is Will Champion a member of?",
            questionImageSrc: "https://i.imgur.com/rae5xWR.jpg",
            answerDescription: "Did you know that Will, Coldplay’s drummer, is also a very good singer?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Imagine Dragons",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Twenty one pilots",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Coldplay",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Panic! at the Disco",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "Who first recorded the best-selling song 'White Christmas'?",
            questionImageSrc: "https://i.imgur.com/ohxNpS8.jpg",
            answerDescription:
                "Did you know that White Christmas is the best-selling Christmas song of all time, according to Guinness World Records?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Bing Crosby",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Nat King Cole",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Elvis Presley",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. The Andrews Sisters",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Which singer had the U.K’s bestselling single in 2011?",
            questionImageSrc: "https://i.imgur.com/ykp70JT.jpg",
            answerDescription: "‘Someone like You’ was the highest-selling song of 2011 in the U.K.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Bruno Mars",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Adele",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Katy Perry",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Rihanna",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "Stefani Germanotta is better known by what name?",
            questionImageSrc: "https://i.imgur.com/ZiA9ryz.jpg",
            answerDescription:
                "Did you know that the inspiration for Lady Gaga’s stage name came from the song 'Radio Ga Ga' by Queen?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Lady Gaga",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Dua Lipa",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Fergie",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Gwen Stefani",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "How many songs are on the album 'Jagged Little Pill'?",
            questionImageSrc: "https://i.imgur.com/MO2u0ZW.jpg",
            answerDescription:
                "This album by 90s pop sensation Alanis Morissette is one of the most successful albums of all time.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 14",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 10",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 7",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 13",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "Roy Hay and Mikey Craig are members of which band?",
            questionImageSrc: "https://i.imgur.com/Ybcuzf4.jpg",
            answerDescription:
                "TThe lead singer of this band is Boy George, one of the most recognizable faces of 80s music.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Nirvana",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Culture Club",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. R.E.M",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Blondie",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "Name this famous 50s crooner.",
            questionImageSrc: "https://i.imgur.com/kzBlajV.jpg",
            answerDescription:
                "Did you know that people confuse Perry Como and Frank Sinatra because of their similar-sounding voices?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Chuck Berry",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Bing Crosby",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Nat King Cole",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Perry Como",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Which song by the animated band, “The Archies” topped the charts?",
            questionImageSrc: "https://i.imgur.com/TUspTWb.jpg",
            answerDescription: "The Archie Show was a television cartoon series based on the Archie comic books.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Sugar Sugar",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Feelin' So Good",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Rock Around the Clock",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. I Love Rock 'n' Roll",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "Who is this legendary country singer?",
            questionImageSrc: "https://i.imgur.com/yHpqgqO.jpg",
            answerDescription:
                "Did you know that Kristofferson wrote “Me and Bobby McGee” which Janis Joplin famously covered?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Garth Brooks",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Johnny Cash",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Kris Kristofferson",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Jim Reeves",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "How many Grammy Awards does this singer have?",
            questionImageSrc: "https://i.imgur.com/ET2V7q6.jpg",
            answerDescription: "It’s hard to believe, but neither Diana Ross nor the Supremes have won a Grammy.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 7",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 4",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 9",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 0",
                    isCorrect: true
                }
            ]
        }
    ]
};

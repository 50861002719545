import { useEffect, useState } from "react";
import { getPermissions } from "../../../../../utils/PermissionUtils";
import { OptionType } from "../../../../CommonComponent/v2/fields/Type";
import { BreadCrumbType } from "../../../../CommonComponent/v2/Type";
import { useAdminContext } from "../../../context/AdminAuthContext";
import { NewEventInfoType } from "./Type";
import { checkLinkStatusAction, getGameList, getReferenceList } from "./Util";

export const useAddEventDetailHook = () => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [eventCreated, setEventCreated] = useState<NewEventInfoType | null>(null);
    const { userDetail } = useAdminContext();
    const permissionQuizFullGame: string[] | null = getPermissions(userDetail, "quiz_fullgame");
    const permissionQuizSocketGame: string[] | null = getPermissions(userDetail, "quiz_socket");
    const permissionQuizPhysicalGame: string[] | null = getPermissions(userDetail, "quiz_wildgoat");

    const breadcrumbValues: BreadCrumbType[] = [
        {
            label: "Event Manager",
            url: "/admin/event"
        },
        {
            label: "Add new event"
        }
    ];

    return {
        breadcrumbValues,
        setActiveStep,
        activeStep,
        eventCreated,
        setEventCreated,
        permissionQuizFullGame,
        permissionQuizSocketGame,
        permissionQuizPhysicalGame
    };
};

export const useDecideGameHook = () => {
    const [gameList, setGameList] = useState<OptionType[]>([]);
    const { userDetail } = useAdminContext();
    const permissionQuizFullGame: string[] | null = getPermissions(userDetail, "quiz_fullgame");
    const permissionQuizSocketGame: string[] | null = getPermissions(userDetail, "quiz_socket");
    const permissionQuizPhysicalGame: string[] | null = getPermissions(userDetail, "quiz_wildgoat");

    useEffect(() => {
        getGameList()
            .then((res: OptionType[]) => setGameList(res))
            .catch(() => setGameList([]));
    }, []);

    return {
        gameList,
        permissionQuizFullGame,
        permissionQuizSocketGame,
        permissionQuizPhysicalGame
    };
};

export const useReviewEventHook = (gameId: string) => {
    const [gameName, setGameName] = useState("");
    const [referenceOptions, setReferenceOptions] = useState<OptionType[]>([]);

    useEffect(() => {
        getGameList()
            .then((res: OptionType[]) => setGameName(res.find((item) => item.value === gameId)?.label || ""))
            .catch(() => setGameName(""));

        getReferenceList()
            .then((res: OptionType[]) => setReferenceOptions(res))
            .catch(() => setReferenceOptions([]));
    }, []);

    return {
        gameName,
        referenceOptions
    };
};

export const useEventLinkHook = (referenceFirstId: string, referenceSecondtId: string) => {
    const [firstReferenceUrl, setFirstReferenceUrl] = useState("");
    const [secondReferenceUrl, setSecondReferenceUrl] = useState("");
    const [referenceOptions, setReferenceOptions] = useState<OptionType[]>([]);
    const [qrCodeModal, setQrCodeModal] = useState("");
    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const togglePreview = () => {
        setShowPreviewModal((prev) => !prev);
    };

    useEffect(() => {
        if (referenceOptions.length) {
            setFirstReferenceUrl(referenceOptions.find((item) => item.value === referenceFirstId)?.type || "");
            setSecondReferenceUrl(referenceOptions.find((item) => item.value === referenceSecondtId)?.type || "");
        }
    }, [referenceFirstId, referenceSecondtId]);

    useEffect(() => {
        getReferenceList()
            .then((res: OptionType[]) => {
                setReferenceOptions(res);
            })
            .catch(() => {
                setReferenceOptions([]);
            });
    }, []);

    return {
        firstReferenceUrl,
        secondReferenceUrl,
        setQrCodeModal,
        qrCodeModal,
        showPreviewModal,
        togglePreview
    };
};

export const usePreviewAllLinksHook = () => {
    const [qrCodeLink, setQrCodeLink] = useState("");
    return {
        qrCodeLink,
        setQrCodeLink
    };
};

export const useLaunchEventHook = (oldLinkName: string) => {
    const [availableType, setAvailableType] = useState<string>("notchecked");
    const [openQrModal, setOpenQrModal] = useState("");
    const [isChecking, setIsChecking] = useState<boolean>(false);
    const [referenceOptions, setReferenceOptions] = useState<OptionType[]>([]);

    const checkLinkStatus = (newLinkName: string) => {
        if (oldLinkName === newLinkName) {
            setAvailableType("available");
            return;
        }
        setIsChecking(true);
        checkLinkStatusAction(newLinkName)
            .then(() => {
                setAvailableType("available");
            })
            .catch(() => {
                setAvailableType("notavailaible");
            })
            .finally(() => setIsChecking(false));
    };

    useEffect(() => {
        getReferenceList()
            .then((res: OptionType[]) => setReferenceOptions(res))
            .catch(() => setReferenceOptions([]));
    }, []);

    return {
        availableType,
        setAvailableType,
        openQrModal,
        setOpenQrModal,
        checkLinkStatus,
        isChecking,
        referenceOptions
    };
};

import { Grid } from "@mui/material";
import React from "react";
import SearchHeader from "../../../CommonComponent/v2/searchheader/SearchHeader";
import TabWrapper from "../../../CommonComponent/v2/tab/Tab";
import { useEventLeaderboard } from "./Hooks";
import LeaderboardEventList from "./LeaderboardEventList";
import { filterEventList, leaderboardEventTabs } from "./Util";

const LeaderboardView = () => {
    const { searchText, selectedTab, setSearchText, setSelectedTab, isLoading, liveEventList, closeEventList } =
        useEventLeaderboard();

    return (
        <Grid container padding={"20px"}>
            <Grid item xs={12}>
                <SearchHeader searchText={searchText} setSearchText={setSearchText} />
                <Grid container spacing={"20px"} mt={"20px"}>
                    <Grid item xs={12}>
                        <TabWrapper
                            tabsOptions={leaderboardEventTabs}
                            selectedTab={selectedTab}
                            handleChange={setSelectedTab}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={"30px"} item xs={12}>
                <LeaderboardEventList
                    selectedTab={selectedTab}
                    eventList={
                        selectedTab === "active"
                            ? filterEventList(liveEventList, searchText)
                            : filterEventList(closeEventList, searchText)
                    }
                    isLoading={isLoading}
                />
            </Grid>
        </Grid>
    );
};

export default LeaderboardView;

import { Grid } from "@mui/material";
import { FC } from "react";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { useGameListHook } from "./Hooks";
import { QuizListItemType, GameInfoType } from "./Type";

type GameListProps = {
    quizList: QuizListItemType[];
    isLoading: boolean;
    setCloneId: (info: GameInfoType) => void;
    setDeleteId: (info: GameInfoType) => void;
    tabOption: string;
    searchText: string;
    setSelectedRows: (rows: QuizListItemType[]) => void;
    selectedRows: QuizListItemType[];
    hideCheckbox: boolean;
};
const GameList: FC<GameListProps> = ({
    quizList,
    isLoading,
    setCloneId,
    setDeleteId,
    tabOption,
    searchText,
    hideCheckbox,
    selectedRows,
    setSelectedRows
}) => {
    const { gameListColumns, isSuperAdmin } = useGameListHook(searchText);

    return (
        <Grid height={hideCheckbox ? "calc(100vh - 280px)" : "calc(100vh - 350px)"}>
            <DataGridWrapper
                columns={gameListColumns(tabOption, setCloneId, setDeleteId)}
                rows={quizList as any}
                checkboxSelection={!hideCheckbox}
                selectionModel={selectedRows.map((item) => item.id)}
                loading={isLoading}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = quizList.filter((row) => selectedIDs.has(row.id));
                    setSelectedRows(selectedRows);
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            identityName: isSuperAdmin
                        }
                    }
                }}
            />
        </Grid>
    );
};

export default GameList;

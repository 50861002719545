import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { DashboardContainer } from "./Style";
import DashboardEventList from "./DashboardEventList";
import DashboardInfoCard from "./DashboardInfoCard";
import DashboardLatestNews from "./DashboardLatestNews";
import DashboardFeedback from "./DashboardFeedback";
import { EventListItem } from "../../EventManager/EventListDataTypes";

const DashboardView = () => {
    const [upcomingEvents, setUpcomingEvents] = useState<EventListItem[]>([]);
    const [isListLoading, setIsListLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (): void => {
        setIsListLoading(true);
        setUpcomingEvents([]);
    };

    return (
        <Grid padding={["10px", "10px", "10px", "40px"]} container>
            <DashboardContainer>
                <Grid item xs={12}>
                    <Grid container spacing={"30px"}>
                        <Grid item xs={6}>
                            <DashboardEventList isLoading={isListLoading} upcomingEvents={upcomingEvents} />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container height={"100%"} spacing={"30px"}>
                                <Grid item xs={4} height={"53%"}>
                                    <DashboardInfoCard description="5" title="Upcoming Events this month" />
                                </Grid>
                                <Grid item xs={4} height={"53%"}>
                                    <DashboardInfoCard
                                        description="Wild Goats 24 Events"
                                        title="Most Popular Game this month"
                                    />
                                </Grid>
                                <Grid item xs={4} height={"53%"}>
                                    <DashboardInfoCard description="74" title="Total Number of Events this month" />
                                </Grid>
                                <Grid item xs={4} height={"53%"}>
                                    <DashboardInfoCard description="17" title="Total Upcoming Events" />
                                </Grid>
                                <Grid item xs={4} height={"53%"}>
                                    <DashboardInfoCard description="Wild goats 46 Events" title="Most Popular Game" />
                                </Grid>
                                <Grid item xs={4} height={"53%"}>
                                    <DashboardInfoCard description="105" title="Total Number of Events" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} marginTop={"30px"}>
                    <Grid container spacing={"30px"}>
                        <Grid item xs={8}>
                            <DashboardLatestNews />
                        </Grid>
                        <Grid item xs={4}>
                            <DashboardFeedback />
                        </Grid>
                    </Grid>
                </Grid>
            </DashboardContainer>
        </Grid>
    );
};

export default DashboardView;

import { attachmentType } from "../RocketTemplateUtils/RocketTemplateTypes";
import { ReferenceInfoType, ReferenceItemDetails } from "./ReferenceItemDataTypes";

export const RefrenceTransformData = (response: any): ReferenceInfoType => {
    const data = response.data.data;
    return {
        description: data.description,
        descriptionImage: data.descriptionImageFileLocation,
        name: data.name,
        referenceLogo: data.fileLocation,
        title: data.centerHeading,
        coverImage: data.progressImageFileLocation,
        attachmentTitle: data.attachmentTitle !== "null" && data.attachmentTitle ? data.attachmentTitle : "",
        backgroundColor: data.backgroundColor,
        backgroundImage: data.backgroundImageFileLocation,
        buttonColor: data.baseButtonColor
    };
};

export const ReferenceItemTransformData = (response: any): ReferenceItemDetails[] => {
    const referenceItems: ReferenceItemDetails[] = response.data.data.reference_list_items.map(
        (referenceItem: any) => ({
            id: referenceItem.reference_item.id,
            title: referenceItem.reference_item.name,
            description: referenceItem.reference_item.description,
            descriptionImage: referenceItem.reference_item.descriptionImageFileLocation,
            bottomDescriptionText: referenceItem.reference_item.description2
        })
    );
    return referenceItems;
};

export const ReferenceAttachmentTransformData = (response: any): attachmentType[] => {
    const attachmentListData: any = response.data.data.map((attachment: any) => ({
        id: attachment.id,
        fileLocation: attachment.fileLocation,
        fileType: getFileType(attachment.fileName),
        fileTitle: attachment.title
    }));

    return attachmentListData;
};

const getFileType = (fileName: string): string => {
    const fileType = fileName.split(".");
    return fileType[fileType.length - 1].toLowerCase();
};

import { Grid, Typography } from "@mui/material";
import Styled from "@emotion/styled";
import CheckIcon from "@mui/icons-material/Check";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { darkPrimaryColor, errorLightColor, successColor } from "../../../../constants/ColorConstant";

export const PlansCardContainer = Styled(Grid)`
    border-radius: 5px;
    padding: 30px;
    padding-bottom: 0px;
    margin-top: 20px;
    height: 100%;
    background-color: #fff;
`;

export const PlanTitle = Styled(Typography)`
    font-size: 24px;
    color: #000;
    font-weight: bold;
    line-height: 1.735rem;
`;

export const Tick = Styled(CheckIcon)`
    color: ${successColor};
`;

export const Wrong = Styled(CloseOutlinedIcon)`
    color: ${errorLightColor};
`;

export const PlanMessage = Styled(Typography)`
    font-size: 18px;
    line-height: 1.735rem;
    color: #000;
    margin-bottom: 10px;
`;

export const HighlightedText = Styled.span`
    font-size: 14px;
    color: #000;
    font-weight: bold;
    line-height: 1.735rem;
`;

export const TitleHighlightText = Styled.span`
    font-size: 32px;
    color: #000;
    font-weight: bold;
    line-height: 1.735rem;
`;

export const TitleText = Styled.span`
    font-size: 64px;
    color: ${darkPrimaryColor};
    font-weight: bold;
    line-height: 1.735rem;
`;

export const CaptionTitleText = Styled(Typography)`
    font-size: 20px;
    color: ${darkPrimaryColor};
    font-weight: 400;
    line-height: 1.735rem;
`;

export const BillingPortalContainer = Styled(Grid)`
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: center;
`;

import Style from "@emotion/styled";
import { greenColor, purpleColor } from "../../../../constants/Styles";

type ScoreTextProps = {
    color?: string;
};
export const ScoreText = Style.h1<ScoreTextProps>`
color: ${(props) => props.color || greenColor};
margin: 10px 0;
text-align: center;
`;

export const DescriptionText = Style.p`
margin:0;
padding:0;
padding-right: 20px;
font-size: 16px;
color : ${purpleColor};
`;

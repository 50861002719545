export class ApiConstants {
    private static base_url = "https://jambar-remote-gtx-default-rtdb.europe-west1.firebasedatabase.app/data";

    private static jambar_backend_base_url = "https://jambar-backend.herokuapp.com";

    public static getQuestionsListApiUrl(status: string): string {
        return `${this.jambar_backend_base_url}/question?status=${status}`;
    }

    public static getQuestionUsageBulkApiUrl(ids: string): string {
        return `${this.jambar_backend_base_url}/question/bulk/usage?questionIds=${ids}`;
    }

    public static deleteQuestionsBulkApiUrl(ids: string): string {
        return `${this.jambar_backend_base_url}/question/bulk?questionIds=${ids}`;
    }

    public static deleteQuestionApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/question/${id}`;
    }

    public static getQuestionDetailsApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/question/${id}`;
    }

    public static getQuestionUsageApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/question/${id}/usage`;
    }

    public static updateQuestionDetailsApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/question/${id}`;
    }

    public static addQuestionApiUrl(): string {
        return `${this.jambar_backend_base_url}/question`;
    }

    public static addQuestionVideoApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/question/${id}/video`;
    }
    public static getCloneQuestionApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/question/${id}/clone`;
    }

    public static addQuestionHintApiUrl(questionId: number): string {
        return `${this.jambar_backend_base_url}/question/${questionId}/hint`;
    }

    public static updateQuestionHintApiUrl(questionId: number, hintId: number): string {
        return `${this.jambar_backend_base_url}/question/${questionId}/hint/${hintId}`;
    }

    public static deleteQuestionHintApiUrl(questionId: number, hintId: number): string {
        return `${this.jambar_backend_base_url}/question/${questionId}/hint/${hintId}`;
    }

    public static getAllHintsOfQuestion(questionId: number): string {
        return `${this.jambar_backend_base_url}/question/${questionId}/hint`;
    }

    public static getQuestionHintDetails(questionId: number, hintId: number): string {
        return `${this.jambar_backend_base_url}/question/${questionId}/hint/${hintId}`;
    }

    public static saveHintsOrder(questionId: number): string {
        return `${this.jambar_backend_base_url}/question/${questionId}/hint/reorder`;
    }

    public static saveQuizAttchmentsOrder(quizId: number): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/attachment/reorder`;
    }

    public static removeQuestionCoverImage(questionId: number): string {
        return `${this.jambar_backend_base_url}/question/${questionId}/image`;
    }

    public static removeQuestionVideo(questionId: number): string {
        return `${this.jambar_backend_base_url}/question/${questionId}/video`;
    }

    public static removeReferenceListCoverImage(referenceListId: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${referenceListId}/coverImage`;
    }

    public static removeReferenceBackgroundImage(referenceListId: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${referenceListId}/backgroundImage`;
    }

    public static removeReferenceProgressImage(referenceListId: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${referenceListId}/progressImage`;
    }

    public static removeReferenceListDescrptionImage(referenceListId: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${referenceListId}/descriptionImage`;
    }

    public static removeReferenceitemDescrptionImage(referenceItemId: number): string {
        return `${this.jambar_backend_base_url}/referenceItem/${referenceItemId}/descriptionImage`;
    }

    public static getReferenceListApiUrl(): string {
        return `${this.jambar_backend_base_url}/referenceList`;
    }

    public static getReferenceDetailsApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${id}`;
    }

    public static addReferenceApiUrl(): string {
        return `${this.jambar_backend_base_url}/referenceList`;
    }

    public static updateReferenceApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${id}`;
    }

    public static deleteReferenceApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${id}`;
    }

    public static getReferenceItemDetailsApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/referenceItem/${id}`;
    }

    public static addReferenceItemApiUrl(): string {
        return `${this.jambar_backend_base_url}/referenceItem`;
    }

    public static updateReferenceItemApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/referenceItem/${id}`;
    }

    public static deleteReferenceItemApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/referenceItem/${id}`;
    }

    public static getReferenceListAttachmentsApiUrl(referenceListId: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${referenceListId}/attachment`;
    }

    public static addReferenceListAttachmentApiUrl(referenceListId: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${referenceListId}/attachment`;
    }

    public static saveReferenceListAttachmentsOrder(referenceListId: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${referenceListId}/attachment/reorder`;
    }

    public static updateReferenceListAttachmentsApiUrl(referenceListId: number, attachmentId: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${referenceListId}/attachment/${attachmentId}`;
    }

    public static getReferenceListAttachmentDetailsApiUrl(referenceListId: number, attachmentId: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${referenceListId}/attachment/${attachmentId}`;
    }

    public static deleteReferenceListAttachmentApiUrl(referenceListId: number, attachmentId: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${referenceListId}/attachment/${attachmentId}`;
    }

    public static addQuizApiUrl(): string {
        return `${this.jambar_backend_base_url}/quiz`;
    }

    public static getQuizListApiUrl(type: string, status: string): string {
        return `${this.jambar_backend_base_url}/quiz?type=${type}&status=${status}`;
    }

    public static getAllQuizzesApiUrl(status: string): string {
        return `${this.jambar_backend_base_url}/quiz?status=${status}`;
    }

    public static deleteQuizApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/quiz/${id}`;
    }

    public static getQuizDetailApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/quiz/${id}`;
    }

    public static getQuizUsageApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/quiz/${id}/usage`;
    }

    public static updateQuizDetailApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/quiz/${id}`;
    }

    public static addQuizTemplateDetailApiUrl(quizId: number): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/config`;
    }

    public static updateQuizTemplateDetailApiUrl(quizId: number): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/config`;
    }

    public static cloneQuizApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/quiz/${id}/clone`;
    }

    public static addEventApiUrl(): string {
        return `${this.jambar_backend_base_url}/event`;
    }

    public static getEventListApiUrl(): string {
        return `${this.jambar_backend_base_url}/event`;
    }

    public static getEventDetailApiUrl(eventId: string): string {
        return `${this.jambar_backend_base_url}/event/${eventId}`;
    }

    public static updateEventApiUrl(eventId: string): string {
        return `${this.jambar_backend_base_url}/event/${eventId}`;
    }

    public static deleteEventApiUrl(id: string): string {
        return `${this.jambar_backend_base_url}/event/${id}`;
    }

    public static closeEventApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/event/${id}/close`;
    }

    public static cloneEventApiUrl(eventId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/clone`;
    }

    public static getEventCodesApiUrl(eventId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/code`;
    }

    public static getEventSurveyDetailApiUrl(eventId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/survey`;
    }

    public static getEventParticipantFileApiUrl(eventId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/participant`;
    }

    public static getEventParticipantFileDownloadApiUrl(eventId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/participant/download`;
    }

    public static getParticipantFileDownloadApiUrl(participantFileLink: string): string {
        return `${this.jambar_backend_base_url}/event/participant/files/download?participantFilesLink=${participantFileLink}`;
    }

    public static getEventCodeDetails(eventId: number, codeId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/code/${codeId}`;
    }

    public static addEventCodeApiUrl(eventId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/code`;
    }

    public static downloadEventCodesApiUrl(eventId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/code/download`;
    }

    public static addBulkEventCodeApiUrl(eventId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/code/bulk`;
    }

    public static updateEventCodeApiUrl(eventId: number, codeId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/code/${codeId}`;
    }

    public static deleteEventCodeApiUrl(eventId: number, codeId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/code/${codeId}`;
    }

    public static getLiveEventsLeaderBoardList(): string {
        return `${this.jambar_backend_base_url}/leaderboard/live`;
    }

    public static getClosedEventsLeaderBoardList(): string {
        return `${this.jambar_backend_base_url}/leaderboard/completed`;
    }

    public static checkLinkAvailability(eventLink: string): string {
        return `${this.jambar_backend_base_url}/event/availabilityCheck/eventLink?eventLink=${eventLink}`;
    }

    public static checkLeaderboardLinkAvailability(leaderboardLink: string): string {
        return `${this.jambar_backend_base_url}/event/availabilityCheck/leaderboardLink?leaderboardLink=${leaderboardLink}`;
    }

    public static checkParticipantFileLinkAvailability(participantFileLink: string): string {
        return `${this.jambar_backend_base_url}/event/availabilityCheck/participantFilesLink?participantFilesLink=${participantFileLink}`;
    }

    public static getPublicLeaderboardDetails(leaderboardLink: string): string {
        return `${this.jambar_backend_base_url}/leaderboard/link/${leaderboardLink}`;
    }

    public static checkReferenceLinkAvailability(referenceLink: string): string {
        return `${this.jambar_backend_base_url}/referenceList/link/${referenceLink}`;
    }

    public static addQuizAttachmentApiUrl(quizId: number): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/attachment`;
    }

    public static getAllQuizAttachmentsApiUrl(quizId: number): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/attachment`;
    }

    public static getQuizAttachmentDetailsApiUrl(quizId: number, attachmentId: number): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/attachment/${attachmentId}`;
    }

    public static updateQuizAttachmentApiUrl(quizId: number, attachmentId: number): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/attachment/${attachmentId}`;
    }

    public static deleteQuizAttachmentApiUrl(quizId: number, attachmentId: number): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/attachment/${attachmentId}`;
    }

    public static getQuizApiUrl(id: string): string {
        return `${this.base_url}/quizzes/${id}.json`;
    }

    public static authenticateUserApiUrl(): string {
        return `${this.jambar_backend_base_url}/user/login`;
    }

    public static getLogoutApiUrl(): string {
        return `${this.jambar_backend_base_url}/user/logout`;
    }

    public static getDuplicateQuizApiUrl(): string {
        return `${this.jambar_backend_base_url}/sample`;
    }

    public static updateTagApiUrl(tagId: number): string {
        return `${this.jambar_backend_base_url}/tag/${tagId}`;
    }

    public static addTagApiUrl(): string {
        return `${this.jambar_backend_base_url}/tag`;
    }

    public static getTagsListApiUrl(): string {
        return `${this.jambar_backend_base_url}/tag`;
    }

    public static getTagsUsageApiUrl(tagId: number): string {
        return `${this.jambar_backend_base_url}/tag/${tagId}/usage`;
    }

    public static getTagDetailsApiUrl(tagId: number): string {
        return `${this.jambar_backend_base_url}/tag/${tagId}`;
    }

    public static deleteTagApiUrl(tagId: number): string {
        return `${this.jambar_backend_base_url}/tag/${tagId}`;
    }

    public static getEventDataApiUrl(eventLink: string): string {
        return `${this.jambar_backend_base_url}/participant/validate?eventLink=${eventLink}`;
    }

    public static validateParticipantCode(eventLink: string, participantCode: string): string {
        return `${this.jambar_backend_base_url}/participant/validate?eventLink=${eventLink}&participantCode=${participantCode}`;
    }

    public static fetchNextQuestionApiUrl(eventLink: string, participantCode: string): string {
        return `${this.jambar_backend_base_url}/participant/fetchNextQuestion?eventLink=${eventLink}&participantCode=${participantCode}`;
    }

    public static saveProgressApiUrl(eventLink: string, participantCode: string): string {
        return `${this.jambar_backend_base_url}/participant/saveProgress?eventLink=${eventLink}&participantCode=${participantCode}`;
    }

    public static getReferenceDetailsLinkApiUrl(link: string): string {
        return `${this.jambar_backend_base_url}/referenceList/link/${link}`;
    }

    public static saveProgressAfterXSeconds(participantProgressId: number): string {
        return `${this.jambar_backend_base_url}/participant/saveProgress/${participantProgressId}`;
    }

    public static getLiveLeaderBoardScores(eventId: number): string {
        return `${this.jambar_backend_base_url}/leaderboard/live/${eventId}`;
    }

    public static getClosedEventLeaderBoardScores(eventId: number): string {
        return `${this.jambar_backend_base_url}/leaderboard/completed/${eventId}`;
    }

    public static uploadImage(): string {
        return `${this.jambar_backend_base_url}/sample`;
    }

    public static connectSocket(): string {
        return `${this.jambar_backend_base_url}/playTeamEvent`;
    }

    public static socketHintUsed(eventLink: string, participantCode: string): string {
        return `${this.jambar_backend_base_url}/participant/useHint?eventLink=${eventLink}&participantCode=${participantCode}`;
    }

    public static socketQuestionSubmit(eventLink: string, participantCode: string): string {
        return `${this.jambar_backend_base_url}/participant/submit?eventLink=${eventLink}&participantCode=${participantCode}`;
    }

    public static getTeamNameApiUrl(eventLink: string, participantCode: string): string {
        return `${this.jambar_backend_base_url}/participant/teamName?eventLink=${eventLink}&participantCode=${participantCode}`;
    }

    public static addTeamNameApiUrl(eventLink: string, participantCode: string): string {
        return `${this.jambar_backend_base_url}/participant/teamName?eventLink=${eventLink}&participantCode=${participantCode}`;
    }

    public static addSurveyDetails(): string {
        return `${this.jambar_backend_base_url}/participant/survey`;
    }

    public static sampleCall(): string {
        return `${this.jambar_backend_base_url}/sample`;
    }

    public static uploadFileApiUrl(eventLink: string, participantCode: string): string {
        return `${this.jambar_backend_base_url}/participant/upload?eventLink=${eventLink}&participantCode=${participantCode}`;
    }

    public static getFileUploadLink(fileCode: string): string {
        return `${this.jambar_backend_base_url}/event/participant/files?participantFilesLink=${fileCode}`;
    }

    public static deletePublicFileUploadLink(fileCode: number, eventCode: string): string {
        return `${this.jambar_backend_base_url}/event/participant/files/${fileCode}?participantFilesLink=${eventCode}`;
    }

    public static deleteEventFileUpload(fileCode: number): string {
        return `${this.jambar_backend_base_url}/participant/upload/${fileCode}`;
    }

    public static getEventImages(event: string): string {
        return `${this.jambar_backend_base_url}/event/images?eventLink=${event}`;
    }

    public static getAllUsersList(): string {
        return `${this.jambar_backend_base_url}/user`;
    }
    public static getAllIdentitiesList(): string {
        return `${this.jambar_backend_base_url}/identity`;
    }
    public static addIdentitiyApiUrl(): string {
        return `${this.jambar_backend_base_url}/identity`;
    }
    public static updateIdentitiyApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/identity/${id}`;
    }
    public static updateIdentitiyCreditApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/identity/${id}`;
    }
    public static deleteIdentitiyApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/identity/${id}`;
    }
    public static addUserDetailApiUrl(): string {
        return `${this.jambar_backend_base_url}/user`;
    }
    public static updateUserDetailApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/user/${id}`;
    }
    public static getUserDetailApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/user/${id}`;
    }
    public static validateMiniParticipantCode(eventLink: string, participantCode: string, email: string): string {
        return `${this.jambar_backend_base_url}/participant/validate?eventLink=${eventLink}&participantCode=${participantCode}&email=${email}`;
    }
    public static getEmailListApiUrl(eventId: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/participant-email`;
    }
    public static getRolesListApiUrl(): string {
        return `${this.jambar_backend_base_url}/role`;
    }
    public static addRolesApiUrl(): string {
        return `${this.jambar_backend_base_url}/role`;
    }
    public static updateRoleApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/role/${id}`;
    }
    public static getUserRoleApiUrl(userId: number): string {
        return `${this.jambar_backend_base_url}/user/${userId}/role`;
    }
    public static addUserRoleApiUrl(userId: number): string {
        return `${this.jambar_backend_base_url}/user/${userId}/role`;
    }
    public static deleteUserRoleApiUrl(userId: number): string {
        return `${this.jambar_backend_base_url}/user/${userId}/role`;
    }
    public static assignRightUserRoleApiUrl(roleId: number, resourceId: number, rightId: number): string {
        return `${this.jambar_backend_base_url}/role/${roleId}/resource/${resourceId}/right/${rightId}`;
    }
    public static fetchAllRightUserRoleApiUrl(roleId: number): string {
        return `${this.jambar_backend_base_url}/role/${roleId}/right`;
    }
    public static fetchAllResourcesApiUrl(): string {
        return `${this.jambar_backend_base_url}/resource`;
    }
    public static fetchAllRight(): string {
        return `${this.jambar_backend_base_url}/right`;
    }
    public static revokeRightUserRoleApiUrl(roleId: number, resourceId: number, rightId: number): string {
        return `${this.jambar_backend_base_url}/role/${roleId}/resource/${resourceId}/right/${rightId}`;
    }
    public static changePasswordApiUrl(): string {
        return `${this.jambar_backend_base_url}/user/change-password`;
    }
    public static changeUserPasswordApiUrl(): string {
        return `${this.jambar_backend_base_url}/user/password`;
    }
    public static deleteUserApiUrl(userId: number, ownerId: number): string {
        return `${this.jambar_backend_base_url}/user/${userId}?newOwnerId=${ownerId}`;
    }
    public static getLocationApiUrl(what3Word: string): string {
        return `https://api.what3words.com/v3/convert-to-coordinates?words=${what3Word}&key=A9QI2CZZ`;
    }
    public static getCloneReferenceApiUrl(id: number): string {
        return `${this.jambar_backend_base_url}/referenceList/${id}/clone`;
    }
    public static updateQuestionGameTypeApiUrl(): string {
        return `${this.jambar_backend_base_url}/question/game-type`;
    }
    public static getLiveTeamPerformanceApiUrl(eventId: string, participantCode: string): string {
        return `${this.jambar_backend_base_url}/team/live/performance?eventId=${eventId}&participantCode=${participantCode}`;
    }

    public static getDownloadFileApiUrl(eventId: string): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/participant/download/all?eventId=${eventId}`;
    }
    public static getEventDownloadFileListApiUrl(eventId: string): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/downloads`;
    }
    public static getAllEventDownloadFileListApiUrl(): string {
        return `${this.jambar_backend_base_url}/event/downloads`;
    }
    public static getUsageQuestionListApiUrl(questionId: string): string {
        return `${this.jambar_backend_base_url}/question/${questionId}/quiz`;
    }
    //multibranch apis
    public static addQuizBranchApiUrl(quizId: string): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/branch`;
    }
    public static getQuizBranchApiUrl(quizId: string): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/branch`;
    }
    public static updateBranchApiUrl(quizId: string, branchId: string): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/branch/${branchId}`;
    }
    public static deleteBranchApiUrl(quizId: string, branchId: string): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/branch/${branchId}`;
    }
    public static quizMultiBranchApiUrl(quizId: string): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/multibranch`;
    }
    public static createTriggerApiUrl(quizId: string, branchId: string): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/branch/${branchId}/trigger`;
    }
    public static updateTriggerApiUrl(quizId: string, branchId: string, triggerId: string): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/branch/${branchId}/trigger/${triggerId}`;
    }
    public static deleteTriggerApiUrl(quizId: string, branchId: string, triggerId: string): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/branch/${branchId}/trigger/${triggerId}`;
    }
    public static getIsMultibranchQuizApiUrl(quizId: string): string {
        return `${this.jambar_backend_base_url}/quiz/${quizId}/multibranch`;
    }
    public static fetchNextMultibranchQuestionApiUrl(eventLink: string, participantCode: string): string {
        return `${this.jambar_backend_base_url}/participant/fetchNextQuestionV2?eventLink=${eventLink}&participantCode=${participantCode}`;
    }
    public static createPaymentApiUrl(): string {
        return `${this.jambar_backend_base_url}/payment/create-payment-intent`;
    }
    public static createPaymentUpdateApiUrl(): string {
        return `${this.jambar_backend_base_url}/payment/update`;
    }
    public static fetchPaymentsApiUrl(userId: string): string {
        return `${this.jambar_backend_base_url}/payment${userId ? `?userId=${userId}` : ""}`;
    }
    public static validatePromoCodeApiUrl(promoCode: string): string {
        return `${this.jambar_backend_base_url}/payment/validate-promo-code?promoCode=${promoCode}`;
    }
    public static downloadPaymentReceiptApiUrl(receiptId: string): string {
        return `${this.jambar_backend_base_url}/payment/${receiptId}/download`;
    }
    public static getIdentityApiUrl(identityId: string): string {
        return `${this.jambar_backend_base_url}/identity/${identityId}`;
    }
    public static registerNewUserApiUrl(): string {
        return `${this.jambar_backend_base_url}/user/create`;
    }
    public static increaseCreditApiUrl(): string {
        return `${this.jambar_backend_base_url}/identity/increase-credits`;
    }
    public static forgotPasswordApiUrl(): string {
        return `${this.jambar_backend_base_url}/user/forgot-password`;
    }
    public static verifyUserApiUrl(): string {
        return `${this.jambar_backend_base_url}/user/verify`;
    }
    public static undoSoftChallengeApiUrl(questionId: string): string {
        return `${this.jambar_backend_base_url}/question/undo-soft-deletion/${questionId}`;
    }
    public static markEventAsLiveApiUrl(eventId: string, liveStatus: number): string {
        return `${this.jambar_backend_base_url}/event/${eventId}/live?live=${liveStatus}`;
    }
    public static signupNewUserApiUrl(userId: string): string {
        return `${this.jambar_backend_base_url}/user/${userId}/signup`;
    }
    public static preparePublicEventApiUrl(): string {
        return `${this.jambar_backend_base_url}/public/event/facilitate-public-event`;
    }
    public static updateVisibilityOfQuestionApiUrl(): string {
        return `${this.jambar_backend_base_url}/question/visibility`;
    }
    public static updateVisibilityOfQuizApiUrl(): string {
        return `${this.jambar_backend_base_url}/quiz/visibility`;
    }
    public static updateTagsOfChallengeApiUrl(): string {
        return `${this.jambar_backend_base_url}/questions/manage-tags`;
    }
}

import { Stack, Spinner } from "@fluentui/react";
import React, { useEffect, useState, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import {
    CONNECTOR,
    CONNECTOR_SKIP,
    CORRECT_ANSWER_COUNT,
    HINT,
    MCQ,
    MCQAnswerOption,
    NON_SCORING_QUESTION,
    SKIP_QUESTION,
    WRONG_ANSWER_COUNT,
    QUESTION_TIMEOUT,
    EVENT_TIMEOUT
} from "../../../data/question/types";
import { Client } from "../../Base/Client";
import QuizCoverImage from "./QuizCoverImage";
import QuizDocument from "./QuizDocument";
import QuizInfo from "./QuizInfo";
import QuizQuestionView from "./QuizQuestionView";
import "./RocketTemplateView.scss";
import { useHistory, useLocation } from "react-router";
import {
    answerType,
    attachmentType,
    ConnectorQuestionInfoTypes,
    currentQuestionInfoType,
    hintType,
    QuestionContextType,
    QuizConfigType,
    QuizInfoType,
    QuizUserSessionInfoType,
    SelectedDocumentType,
    TimersEndType
} from "./RocketTemplateUtils/RocketTemplateTypes";
import { ApiConstants } from "../../../constants/ApiConstant";
import { EventClosedMessage, RocketGame, SiteConstants } from "../../../constants/SiteConstant";
import QuizSummaryTextView from "./QuizEndTextView";
import { getRemainingTime } from "../../../utils/TimeUtils";
import QuizDocumentModal from "./QuizDocumentModal";
import RocketTemplateQuestionAnswerTypeCheck from "./RocketTemplateQuestionAnswerTypeCheck";
import { FieldValidation } from "../../../constants/FieldValidation";
import QuizConnectorView from "./QuizConnectorView";
import QuizHintsView from "./QuizHintsView";
import QuizTitleLogoView from "./QuizTitleLogoView";
import BackgroundImage from "../../Images/background.png";
import RocketImage from "../../Images/rocket2.png";
import { SaveProgressInterval } from "../../../constants/SiteConstant";
import NoInternetConnectionModal from "./NoInternetConnectionModal";
import {
    CurrentQuestionTransformData,
    QuestionHintsTransformData,
    QuizAttachmentTransformData,
    QuizConfigTransformData,
    QuizInfoTransformData,
    QuizUserSessionInfoTransformData
} from "./RocketTemplateTransformedData";
import {
    ConnectorQuestionInitialValues,
    QuizConfigInitialValues,
    QuizInfoInitialValues,
    QuizUserSessionInfoInitialValues,
    SelectedDocumentInitialValues,
    TimerEndInitialValues
} from "./RocketTemplateUtils/RocketTemplateInitialValues";
import { Grid } from "@mui/material";

//combine individual state items into logical objects
//combine useEffects where possible
//Move functions that are not using props or state to outside this file. Create a separate util file
//Read about custom hooks

const RocketTemplateView = () => {
    const info: any = useLocation();
    const history = useHistory();
    const [quizInfoData, setQuizInfoData] = useState<QuizInfoType>(QuizInfoInitialValues);
    const [quizUserSessionInfoData, setQuizUserSessionInfoData] = useState<QuizUserSessionInfoType>(
        QuizUserSessionInfoInitialValues
    );
    const [quizConfig, setQuizConfig] = useState<QuizConfigType>(QuizConfigInitialValues);
    const [selectedDocument, setSelectedDocument] = useState<SelectedDocumentType>(SelectedDocumentInitialValues);
    const [timerEnd, setTimerEnd] = useState<TimersEndType>(TimerEndInitialValues);
    const [connectorQuestionInfo, setConnectorQuestionInfo] = useState<ConnectorQuestionInfoTypes>(
        ConnectorQuestionInitialValues
    );
    const [questionContext, setQuestionContext] = useState<QuestionContextType>();
    const [answerText, setSelectedAnswerText] = useState("");
    const [loading, setLoading] = useState(true);
    const [hints, setHint] = useState<hintType[]>([]);
    const [attachment, setAttachment] = useState<attachmentType[]>([]);
    const [endQuiz, showEndQuiz] = useState(false);
    const [currentQuestionInfo, setCurrentQuestionInfo] = useState<currentQuestionInfoType>();
    const [questionTiming, setQuestionTiming] = useState("00:00:00");
    const [allowSkipping, setAllowSkipping] = useState(false);
    const [hideTimer, setHideTimer] = useState(false);
    const [totalTimeTakeninEvent, setTotalTimeTakenInEvent] = useState("");
    const [key, setKey] = useState("initialKey");
    const [hintDisable, setHintDisable] = useState(false);
    const [submitResponseCompleted, setSubmitResponseCompleted] = useState(false);
    const [saveData, setSaveData] = useState(0);
    const [internetLost, setInternetLost] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    //TODO - This can be moved to some parent component. See useContext
    useEffect(() => {
        if (navigator.onLine) {
            return setInternetLost(false);
        }
        return setInternetLost(true);
    }, [navigator.onLine]);

    useEffect(() => {
        if (quizConfig.tabTitle !== "") {
            document.title = quizConfig.tabTitle;
        }
    }, [quizConfig.tabTitle]);

    useEffect(() => {
        if (connectorQuestionInfo.connectorQuizIsWorking) {
            SaveProgressAfterXSeconds();
        }
    }, [saveData]);

    const SaveProgressAfterXSeconds = (): void => {
        const data = {
            timeTakenInQuiz: getRemainingTime(quizInfoData.initialEventTimer, quizUserSessionInfoData.eventTimer),
            timeTakenInConnector: getRemainingTime(
                connectorQuestionInfo.connectorTotalTime,
                connectorQuestionInfo.timeTakenInConnector
            )
        };
        if (quizUserSessionInfoData.progressId !== -1) {
            Client.getInstance().updateData(
                ApiConstants.saveProgressAfterXSeconds(quizUserSessionInfoData.progressId),
                data
            );
        }
    };

    const checkEventEnded = (message: string) => {
        message === EventClosedMessage &&
            history.push("/thankyou", {
                eventClosed: true
            });
    };

    const fetchNextQuestion = (eventTime: string, initialProgress = 0): void => {
        setTimerEnd((prevState) => ({ ...prevState, questionTimeEnds: false }));
        setLoading(true);
        setHintDisable(false);
        setTimerEnd((prevState) => ({ ...prevState, connectorQuizEnds: false }));
        setConnectorQuestionInfo((prevState) => ({ ...prevState, connectorQuizIsWorking: false }));
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, hintUsed: 0, hintsAlreadyUsed: "" }));
        setSubmitResponseCompleted(false);
        Client.getInstance()
            .getData(ApiConstants.fetchNextQuestionApiUrl(info.state.eventLink, info.state.code), true)
            .then((response: any) => {
                const data = response.data.data;
                setQuestionContext(data.context);
                if (data?.context?.connectorQuizId) {
                    setConnectorQuestionInfo((prevState) => ({
                        ...prevState,
                        connectorTotalTime: data.context.questionTimeLimit,
                        connectorQuizIsWorking: !!data.context.connectorQuizId
                    }));
                } else {
                    data.nextQuestionInfo &&
                        data.nextQuestionInfo.type === "connector" &&
                        setConnectorQuestionInfo((prevState) => ({
                            ...prevState,
                            connectorTotalTime: data.nextQuestionInfo.timeLimit
                        }));

                    data.nextQuestionInfo &&
                        setConnectorQuestionInfo((prevState) => ({
                            ...prevState,
                            connectorQuizIsWorking: data.nextQuestionInfo.type === "connector"
                        }));
                }

                if (
                    data?.context?.quizId === data?.userQuizSessionInfo?.quizId &&
                    data.nextQuestionInfo.type === "connector"
                ) {
                    setConnectorQuestionInfo((prevState) => ({
                        ...prevState,
                        timeTakenInConnector: data.userQuizSessionInfo.timeTakenInConnector
                            ? getRemainingTime(
                                data.nextQuestionInfo.timeLimit,
                                data.userQuizSessionInfo.timeTakenInConnector
                            )
                            : data.nextQuestionInfo.timeLimit
                    }));
                }
                data.context &&
                    setConnectorQuestionInfo((prevState) => ({
                        ...prevState,
                        showConnectorTimer: data.context.showConnectorTimer
                    }));

                data.context &&
                    setConnectorQuestionInfo((prevState) => ({
                        ...prevState,
                        connectorIsClosable: data.context.isConnectorQuizClosable
                    }));

                if (data?.context?.playedConnectorOptionQuizIds) {
                    const quizIds = data.context.playedConnectorOptionQuizIds.split(",");
                    setConnectorQuestionInfo((prevState) => ({ ...prevState, playedConnectorOptionQuizIds: quizIds }));
                }
                if (data?.userQuizSessionInfo?.timeTakenInQuestion) {
                    data.nextQuestionInfo &&
                        setQuestionTiming(
                            getRemainingTime(
                                data.nextQuestionInfo.timeLimit,
                                data.userQuizSessionInfo.timeTakenInQuestion
                            )
                        );
                } else {
                    data.nextQuestionInfo && setQuestionTiming(data.nextQuestionInfo.timeLimit);
                }

                if (data.userQuizSessionInfo) {
                    setQuizUserSessionInfoData(QuizUserSessionInfoTransformData(eventTime, response));
                    data.context?.questionTimeLimit &&
                        data.userQuizSessionInfo?.timeTakenInConnector &&
                        setConnectorQuestionInfo((prevState) => ({
                            ...prevState,
                            timeTakenInConnector: getRemainingTime(
                                data.context.questionTimeLimit,
                                data.userQuizSessionInfo.timeTakenInConnector
                            )
                        }));
                } else {
                    setQuizUserSessionInfoData((prevState) => ({ ...prevState, eventTimer: eventTime }));
                    setQuizUserSessionInfoData((prevState) => ({ ...prevState, totalMarks: initialProgress }));
                }

                if (data.message) {
                    showEndQuiz(true);
                    if (data.userQuizSessionInfo.userAttemptType === "quiz-timeout") {
                        setTotalTimeTakenInEvent("00:00:00");
                        return;
                    }
                    setTotalTimeTakenInEvent(
                        getRemainingTime(quizInfoData.initialEventTimer, data.userQuizSessionInfo.timeTakenInQuiz)
                    );
                    return;
                }
                currentQuestionTransformData(data.nextQuestionInfo);
                setHint(QuestionHintsTransformData(response));
                setKey(FieldValidation.generateAlphaNumericCode());
            })
            .catch((error) => {
                checkEventEnded(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
                setInitialLoading(false);
            });
    };

    const currentQuestionTransformData = (data: any): void => {
        setAllowSkipping(data.allowSkipping);
        setHideTimer(data.timeLimit === "00:00:00");
        setCurrentQuestionInfo(CurrentQuestionTransformData(data));
    };

    useEffect(() => {
        fetchQuizData();
        const interval = setInterval(() => {
            setSaveData(Math.random);
        }, SaveProgressInterval);
        return () => clearInterval(interval);
    }, []);

    const fetchQuizData = (): void => {
        Client.getInstance()
            .getData(ApiConstants.validateParticipantCode(info.state.eventLink, info.state.code))
            .then((response) => {
                setQuizInfoData(QuizInfoTransformData(response));
                setQuizConfig(QuizConfigTransformData(response));
                setAttachment(QuizAttachmentTransformData(response));
                fetchNextQuestion(
                    response.data.data.quiz.timeLimit,
                    response.data.data.quiz.initialScore ? response.data.data.quiz.initialScore : 0
                );
            })
            .catch((error) => {
                checkEventEnded(error.response.data.message);
            });
    };

    const saveProgressForConnectorWhenTimeOuts = (): void => {
        Client.getInstance()
            .createData(
                ApiConstants.saveProgressApiUrl(info.state.eventLink, info.state.code),
                reverseTransformData(
                    { type: CONNECTOR, quizType: CONNECTOR },
                    0,
                    quizUserSessionInfoData.totalMarks,
                    "",
                    questionContext?.connectorQuizId,
                    true
                )
            )
            .then(() => {
                setSubmitResponseCompleted(true);
                fetchNextQuestion(quizUserSessionInfoData.eventTimer);
                setTimerEnd((prevState) => ({ ...prevState, connectorQuizEnds: false }));
            })
            .catch((error) => {
                checkEventEnded(error.response.data.message);
            });
    };

    const saveProgressForConnectorQuestion = (connectorQuizId: number): void => {
        Client.getInstance()
            .createData(
                ApiConstants.saveProgressApiUrl(info.state.eventLink, info.state.code),
                reverseTransformData(
                    { type: CONNECTOR, quizType: CONNECTOR },
                    0,
                    quizUserSessionInfoData.totalMarks,
                    "",
                    connectorQuizId
                )
            )
            .then(() => {
                setSubmitResponseCompleted(true);
                fetchNextQuestion(quizUserSessionInfoData.eventTimer);
            })
            .catch((error) => {
                checkEventEnded(error.response.data.message);
            });
    };

    useEffect(() => {
        if (timerEnd.connectorQuizEnds) {
            saveProgressForConnectorWhenTimeOuts();
        }
    }, [timerEnd.connectorQuizEnds]);

    useEffect(() => {
        if (timerEnd.eventEnds) {
            saveProgressWhenEventEnds();
        }
    }, [timerEnd.eventEnds]);

    useEffect(() => {
        if (timerEnd.questionTimeEnds) {
            saveProgressForQuestionWhenTimeOuts();
        }
    }, [timerEnd.questionTimeEnds]);

    const saveProgressWhenEventEnds = (): void => {
        Client.getInstance()
            .createData(
                ApiConstants.saveProgressApiUrl(info.state.eventLink, info.state.code),
                reverseTransformData({ type: EVENT_TIMEOUT }, 0, quizUserSessionInfoData.totalMarks)
            )
            .then(() => {
                fetchNextQuestion(quizUserSessionInfoData.eventTimer);
                setSubmitResponseCompleted(true);
            })
            .catch((error) => {
                checkEventEnded(error.response.data.message);
            });
    };

    const saveProgressForQuestionWhenTimeOuts = (): void => {
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, wrongAnswerCount: prevState.wrongAnswerCount + 1 }));
        let marksAfterTimeOut: number = quizUserSessionInfoData.totalMarks;
        marksAfterTimeOut = marksAfterTimeOut + parseInt(quizInfoData.wrongAnswerPoints);
        if (currentQuestionInfo && currentQuestionInfo?.wrongPoints !== 0) {
            marksAfterTimeOut = marksAfterTimeOut + currentQuestionInfo.wrongPoints;
        }
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, totalMarks: marksAfterTimeOut }));
        if (connectorQuestionInfo.connectorQuizIsWorking) {
            saveProgressTest(
                { type: WRONG_ANSWER_COUNT, quizType: CONNECTOR },
                quizUserSessionInfoData.wrongAnswerCount + 1,
                marksAfterTimeOut,
                "",
                true
            );
            return;
        }
        Client.getInstance()
            .createData(
                ApiConstants.saveProgressApiUrl(info.state.eventLink, info.state.code),
                reverseTransformData(
                    { type: QUESTION_TIMEOUT },
                    quizUserSessionInfoData.wrongAnswerCount + 1,
                    marksAfterTimeOut
                )
            )
            .then(() => {
                setSubmitResponseCompleted(true);
            })
            .catch((error) => {
                checkEventEnded(error.response.data.message);
            });
    };

    const reverseTransformData = (
        answerType: answerType,
        answerCount: number,
        totalScore: number,
        hintsUsedId?: string,
        connectorQuizId?: number,
        timeout?: boolean,
        hintsUsed?: number
    ): any => {
        let quizProgressData: any = {
            teamName: info.state.teamName,
            quizId: questionContext && questionContext.quizId,
            questionId: questionContext && questionContext.questionId,
            timeTakenInQuiz: getRemainingTime(quizInfoData.initialEventTimer, quizUserSessionInfoData.eventTimer),
            timeTakenInQuestion: getRemainingTime(
                currentQuestionInfo && currentQuestionInfo.timeLimit ? currentQuestionInfo.timeLimit : "00:00:00",
                questionTiming
            ),
            hintsUsedCount: quizUserSessionInfoData.totalHintUsed,
            connectorQuizId: connectorQuizId,
            connectorQuestionId: questionContext && questionContext.connectorQuestionId,
            correctAnswersCount: quizUserSessionInfoData.correctAnswerCount,
            wrongAnswersCount: quizUserSessionInfoData.wrongAnswerCount
        };
        if (answerType.type === EVENT_TIMEOUT) {
            return {
                ...quizProgressData,
                userAttemptType: "quiz-timeout",
                quizScore: totalScore
            };
        }
        if (answerType.type === HINT) {
            const saveHint = {
                ...quizProgressData,
                userAttemptType: "none",
                quizScore: totalScore,
                usedQuestionHintIds: hintsUsedId,
                hintsUsedCount: hintsUsed
            };

            if (connectorQuestionInfo.connectorQuizIsWorking) {
                return {
                    ...saveHint,
                    timeTakenInConnector: getRemainingTime(
                        connectorQuestionInfo.connectorTotalTime,
                        connectorQuestionInfo.timeTakenInConnector
                    )
                };
            }
            return saveHint;
        }

        if (answerType.type === NON_SCORING_QUESTION) {
            quizProgressData = {
                ...quizProgressData,
                userAttemptType: "correct",
                quizScore: totalScore
            };
        }
        if (answerType.type === CORRECT_ANSWER_COUNT) {
            quizProgressData = {
                ...quizProgressData,
                correctAnswersCount: answerCount,
                userAttemptType: "correct",
                quizScore: totalScore
            };
        }
        if (answerType.type === WRONG_ANSWER_COUNT) {
            quizProgressData = {
                ...quizProgressData,
                wrongAnswersCount: answerCount,
                userAttemptType: "wrong",
                quizScore: totalScore
            };
        }
        if (answerType.type === SKIP_QUESTION) {
            return {
                ...quizProgressData,
                userAttemptType: "skip",
                quizScore:
                    quizUserSessionInfoData.totalMarks + (currentQuestionInfo ? currentQuestionInfo?.skipPoints : 0)
            };
        }
        if (answerType.type === CONNECTOR_SKIP) {
            quizProgressData = {
                ...quizProgressData,
                userAttemptType: "skip",
                quizScore: totalScore,
                usedQuestionHintIds: quizUserSessionInfoData.hintsAlreadyUsed
            };
        }
        if (answerType.type === CONNECTOR) {
            quizProgressData = {
                ...quizProgressData,
                userAttemptType: "skip",
                quizScore: totalScore
            };
        }
        if (timeout) {
            quizProgressData = {
                ...quizProgressData,
                userAttemptType: "timeout",
                quizScore: totalScore
            };
        }
        if (answerType.type === QUESTION_TIMEOUT) {
            return {
                ...quizProgressData,
                wrongAnswersCount: answerCount,
                userAttemptType: "wrong",
                quizScore: totalScore
            };
        }

        if (answerType.quizType === CONNECTOR) {
            return {
                ...quizProgressData,
                timeTakenInConnector: getRemainingTime(
                    connectorQuestionInfo.connectorTotalTime,
                    connectorQuestionInfo.timeTakenInConnector
                )
            };
        }
        return {
            ...quizProgressData,
            userAttemptType: "correct",
            quizScore: totalScore
        };
    };

    const goToNextQuestion = (answerSubmitted: boolean, userAttemptType: string, quizType?: string) => {
        !answerSubmitted
            ? saveProgressTest(
                { type: userAttemptType, quizType: quizType },
                0,
                quizUserSessionInfoData.totalMarks,
                "",
                answerSubmitted
            )
            : fetchNextQuestion(quizUserSessionInfoData.eventTimer);
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, hintUsed: 0 }));
        setSelectedAnswerText("");
    };

    const helpTaken = (prevHintCount: number, totalMarks: number): void => {
        if (quizUserSessionInfoData.hintUsed > hints.length - 1) {
            return;
        }
        const usedHintsId = hints.slice(0, prevHintCount + 1);
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, hintsAlreadyUsed: getHintsUsedId(usedHintsId) }));
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, hintUsed: prevState.hintUsed + 1 }));
        setQuizUserSessionInfoData((prevState) => ({ ...prevState, totalHintUsed: prevState.totalHintUsed + 1 }));
        saveProgressForHints(
            { type: HINT },
            0,
            totalMarks,
            getHintsUsedId(usedHintsId),
            quizUserSessionInfoData.totalHintUsed + 1
        );
    };

    const getHintsUsedId = (usedHintsId: hintType[]): string => {
        return usedHintsId.map((hints: hintType) => hints.questionId).join(",");
    };

    const saveProgressForHints = (
        answerType: answerType,
        answerCount: number,
        totalScore: number,
        hintsUsedId: string,
        hintsUsed: number
    ): void => {
        Client.getInstance().createData(
            ApiConstants.saveProgressApiUrl(info.state.eventLink, info.state.code),
            reverseTransformData(
                answerType,
                answerCount,
                totalScore,
                hintsUsedId,
                questionContext?.connectorQuizId,
                false,
                hintsUsed
            )
        );
    };

    const saveProgressTest = (
        answerType: answerType,
        answerCount: number,
        totalScore: number,
        hintsUsedId?: string,
        isProgressSaved?: boolean
    ): void => {
        Client.getInstance()
            .createData(
                ApiConstants.saveProgressApiUrl(info.state.eventLink, info.state.code),
                reverseTransformData(answerType, answerCount, totalScore, hintsUsedId, questionContext?.connectorQuizId)
            )
            .then(() => {
                setSubmitResponseCompleted(true);
                !isProgressSaved && fetchNextQuestion(quizUserSessionInfoData.eventTimer);
            })
            .catch((error) => {
                checkEventEnded(error.response.data.message);
            });
    };

    const getQuestionView = (): JSX.Element => {
        if (currentQuestionInfo) {
            return (
                <QuizQuestionView
                    showProgressInPercentage={quizInfoData.showProgressInPercentage}
                    timing={questionTiming}
                    getTiming={getQuestionTiming}
                    correctAnswerText={currentQuestionInfo.correctAnswerText}
                    wrongAnswerText={currentQuestionInfo.wrongAnswerText}
                    answerGivenByParticipant={answerText}
                    correctAnswerOfQuestion={
                        currentQuestionInfo.type === MCQ ? currentQuestionInfo.mcqAnswers : currentQuestionInfo.answer
                    }
                    textAnswerType={currentQuestionInfo.answerType}
                    question={currentQuestionInfo.questionText}
                    coverImage={currentQuestionInfo.fileLocation}
                    wrongAnswerPoints={
                        currentQuestionInfo.wrongPoints === 0
                            ? currentQuestionInfo.wrongPoints.toString() :
                            quizInfoData.wrongAnswerPoints
                    }
                    correctAnswerPoints={
                        currentQuestionInfo.correctPoints === 0
                            ? quizInfoData.correctAnswerPoints
                            : currentQuestionInfo.correctPoints.toString()
                    }
                    totalMarks={quizUserSessionInfoData.totalMarks}
                    setTotalMarks={(marks: number) =>
                        setQuizUserSessionInfoData((prevState) => ({ ...prevState, totalMarks: marks }))
                    }
                    increaseCorrectAnswerCount={() =>
                        setQuizUserSessionInfoData((prevState) => ({
                            ...prevState,
                            correctAnswerCount: prevState.correctAnswerCount + 1
                        }))
                    }
                    increaseWrongAnswerCount={() =>
                        setQuizUserSessionInfoData((prevState) => ({
                            ...prevState,
                            wrongAnswerCount: prevState.wrongAnswerCount + 1
                        }))
                    }
                    questionIsNonScoring={currentQuestionInfo.isNonScoringQuestion}
                    saveProgress={(answerType: answerType, answerCount: number, totalScore: number) =>
                        saveProgressTest(answerType, answerCount, totalScore, "", true)
                    }
                    correctAnswerCount={quizUserSessionInfoData.correctAnswerCount}
                    wrongAnswerCount={quizUserSessionInfoData.wrongAnswerCount}
                    optionsId={
                        currentQuestionInfo.mcqOptions
                            ? currentQuestionInfo.mcqOptions.map((option: MCQAnswerOption) => option.id.toString())
                            : []
                    }
                    hideTimer={hideTimer}
                    fetchNextQuestion={goToNextQuestion}
                    timeoutText={currentQuestionInfo.timeOutText}
                    key={key}
                    documentSelected={(coverImage) => {
                        setSelectedDocument((prevState) => ({
                            ...prevState,
                            documentSelected: coverImage,
                            selectedDocumentType: "jpg"
                        }));
                    }}
                    bottomQuestionText={currentQuestionInfo.bottomQuestionText}
                    nextButtonText={quizInfoData.nextButtonText}
                    connectorTiming={connectorQuestionInfo.timeTakenInConnector}
                    getConnectorTiming={getConnectorTiming}
                    connectorQuizIsWorking={connectorQuestionInfo.connectorQuizIsWorking}
                    submitResponseIsCompleted={submitResponseCompleted}
                    questionTimeFinished={timerEnd.questionTimeEnds}
                    connectorIsClosable={connectorQuestionInfo.connectorIsClosable}
                    hideConnectorTime={connectorQuestionInfo.showConnectorTimer}
                    gameBackgroundColor={quizConfig.backgroundColor}
                    answerResultImage={quizInfoData.answerResultImage}
                    answerResultText={quizInfoData.answerResultText}
                    correctAnswerBackgroundColor={quizConfig.correctAnswerBackgroundColor}
                    wrongAnswerBackgroundColor={quizConfig.wrongAnswerBackgroundColor}
                />
            );
        }
        return <span />;
    };

    const getQuestionTiming = (time: string): void => {
        if (time === "0:0:0" && !timerEnd.questionTimeEnds) {
            setTimerEnd((prevState) => ({ ...prevState, questionTimeEnds: true }));
            return;
        }
        time !== "" && setQuestionTiming(time);
    };

    const getConnectorTiming = (time: string): void => {
        if (connectorQuestionInfo.connectorQuizIsWorking && time === "0:0:0" && !timerEnd.connectorQuizEnds) {
            setTimerEnd((prevState) => ({ ...prevState, connectorQuizEnds: true }));
            return;
        }
        time !== "" && setConnectorQuestionInfo((prevState) => ({ ...prevState, timeTakenInConnector: time }));
    };

    const getEventTiming = (time: string): void => {
        if (time === "0:0:0" && !timerEnd.eventEnds) {
            setTimerEnd((prevState) => ({ ...prevState, eventEnds: true }));
            return;
        }
        time !== "" && setQuizUserSessionInfoData((prevState) => ({ ...prevState, eventTimer: time }));
    };

    const internetLostModal = useMemo(() => internetLost && <NoInternetConnectionModal />, [internetLost]);

    if (initialLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
                <Spinner className={"loading-component"} label="I am loading..." />
            </Grid>
        );
    }

    return (
        <>
            <Stack
                className={"main-container"}
                style={{
                    backgroundImage: `url('${quizConfig?.backgroundImage && quizConfig ? quizConfig?.backgroundImage : BackgroundImage
                        }')`
                }}>
                {selectedDocument.documentSelected !== "" && (
                    <div className={"document-cover-image-view position-absolute zIndex-9999 width-100per"}>
                        <div
                            className={
                                FieldValidation.imageTypes.includes(selectedDocument.selectedDocumentType)
                                    ? "document-cover-image-section"
                                    : "document-cover-image-section width-100per"
                            }>
                            <QuizDocumentModal
                                document={selectedDocument.documentSelected}
                                documentType={selectedDocument.selectedDocumentType}
                                documentClose={() => {
                                    setSelectedDocument((prevState) => ({
                                        ...prevState,
                                        documentSelected: "",
                                        selectedDocumentType: ""
                                    }));
                                }}
                                key={selectedDocument.documentSelected}
                            />
                        </div>
                    </div>
                )}
                <Stack
                    horizontal
                    className={
                        selectedDocument.documentSelected === "" || selectedDocument.selectedDocumentType === ""
                            ? "quiz-container"
                            : "quiz-container opacity-point3 pointer-event-none"
                    }>
                    <Row className={"height-100per width-100per margin-0"}>
                        <Col sm={3} className={"margin-0 padding-0 height-100per left-section-column"}>
                            <div className={"left-section-logo height-100per"}>
                                <QuizTitleLogoView
                                    eventLogo={quizInfoData.eventLogo}
                                    companyLogo={quizInfoData.companyLogo}
                                />
                            </div>
                            <div className={"left-section-info height-100per"}>
                                <QuizInfo
                                    quizType={RocketGame}
                                    title={quizInfoData.quizTitle}
                                    correctAnswerCount={quizUserSessionInfoData.correctAnswerCount}
                                    wrongAnswerCount={quizUserSessionInfoData.wrongAnswerCount}
                                    timing={quizUserSessionInfoData.eventTimer}
                                    getTiming={getEventTiming}
                                    hintsUsed={quizUserSessionInfoData.totalHintUsed}
                                    eventFinishes={endQuiz}
                                    totalTimeTakeninEvent={totalTimeTakeninEvent}
                                    referenceItemText1={
                                        currentQuestionInfo ? currentQuestionInfo.referenceItemText1 : ""
                                    }
                                    referenceItemText2={
                                        currentQuestionInfo ? currentQuestionInfo.referenceItemText2 : ""
                                    }
                                    gameBackgroundColor={quizConfig.backgroundColor}
                                    gameInfoBackgroundColor={quizConfig.buttonColor}
                                    showInfo={{
                                        showCorrectAnswerCount: quizInfoData.showCorrectAnswerCount,
                                        showWrongAnswerCount: quizInfoData.showWrongAnswerCount,
                                        showHintCount: quizInfoData.showHintCount,
                                        showMainTimer: quizInfoData.showMainTimer
                                    }}
                                />
                            </div>
                            <div className={"left-section-document height-100per"}>
                                <QuizDocument
                                    selectedDocumentType={(documentUrl: string, fileType: string) => {
                                        setSelectedDocument((prevState) => ({
                                            ...prevState,
                                            documentSelected: documentUrl,
                                            selectedDocumentType: fileType
                                        }));
                                    }}
                                    attachmentType={attachment}
                                    attachmentTitle={
                                        quizConfig && quizConfig?.attachmentTitle
                                            ? quizConfig?.attachmentTitle
                                            : "Ship Manuals"
                                    }
                                    gameBackgroundColor={quizConfig.backgroundColor}
                                />
                            </div>
                        </Col>
                        <Col sm={6} className={"margin-0 padding-0 height-100per"}>
                            {currentQuestionInfo && currentQuestionInfo.type === CONNECTOR ? (
                                <div className={"middle-section-connector-question height-100per"}>
                                    {endQuiz ? (
                                        <QuizSummaryTextView
                                            gameBackgroundColor={quizConfig.backgroundColor}
                                            info={quizInfoData.summaryPageInfo}
                                            eventLink={info.state.eventLink}
                                            buttonColor={quizConfig.buttonColor}
                                            showSurveyForm={quizInfoData.showSurveyForm}
                                            eventId={info.state.eventId}
                                            partcipantName={info.state.teamName}
                                            participantCode={info.state.code}
                                        />
                                    ) : !loading ? (
                                        <QuizConnectorView
                                            bottomQuestionText={currentQuestionInfo.bottomQuestionText}
                                            coverImage={currentQuestionInfo.fileLocation}
                                            documentSelected={(coverImage) => {
                                                setSelectedDocument((prevState) => ({
                                                    ...prevState,
                                                    documentSelected: coverImage,
                                                    selectedDocumentType: "jpg"
                                                }));
                                            }}
                                            updateAnswerText={(answer: string) => {
                                                setSelectedAnswerText(answer);
                                                setAllowSkipping(true);
                                            }}
                                            type={currentQuestionInfo.type}
                                            description={currentQuestionInfo.questionText}
                                            goToNextQuestion={goToNextQuestion}
                                            showContinueButton={allowSkipping && quizInfoData.quizAllowSkipping}
                                            questionTimeEnds={timerEnd.questionTimeEnds}
                                            key={key}
                                            questionConnectors={
                                                currentQuestionInfo ? currentQuestionInfo.questionConnector : []
                                            }
                                            options={currentQuestionInfo?.mcqOptions}
                                            saveProgressForConnectorQuestion={saveProgressForConnectorQuestion}
                                            completedConnector={connectorQuestionInfo.playedConnectorOptionQuizIds}
                                            nextButtonText={quizInfoData.nextButtonText}
                                            timeLimit={connectorQuestionInfo.timeTakenInConnector}
                                            getTiming={getConnectorTiming}
                                            hideTimer={hideTimer}
                                            hideConnectorTimer={connectorQuestionInfo.showConnectorTimer}
                                            setHintDisable={(hintViewType: boolean) => setHintDisable(hintViewType)}
                                            gameBackgroundColor={quizConfig.backgroundColor}
                                            optionsColor={quizConfig.buttonColor}
                                        />
                                    ) : null}
                                </div>
                            ) : (
                                <>
                                    {endQuiz ? (
                                        <QuizSummaryTextView
                                            gameBackgroundColor={quizConfig.backgroundColor}
                                            info={quizInfoData.summaryPageInfo}
                                            eventLink={info.state.eventLink}
                                            buttonColor={quizConfig.buttonColor}
                                            showSurveyForm={quizInfoData.showSurveyForm}
                                            eventId={info.state.eventId}
                                            partcipantName={info.state.teamName}
                                            participantCode={info.state.code}
                                        />
                                    ) : !loading ? (
                                        <div
                                            className={
                                                selectedDocument.documentSelected === "" ||
                                                    selectedDocument.selectedDocumentType === ""
                                                    ? "middle-section-question height-100per"
                                                    : "middle-section-question height-100per opacity-point3"
                                            }>
                                            {getQuestionView()}
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                selectedDocument.documentSelected === "" ||
                                                    selectedDocument.selectedDocumentType === ""
                                                    ? "middle-section-question height-100per"
                                                    : "middle-section-question height-100per opacity-point3"
                                            }
                                        />
                                    )}
                                </>
                            )}

                            {!endQuiz && currentQuestionInfo && currentQuestionInfo.type !== CONNECTOR && (
                                <div
                                    className={
                                        selectedDocument.documentSelected === ""
                                            ? "middle-section-answer height-100per"
                                            : "middle-section-answer height-100per opacity-point3"
                                    }>
                                    <Stack.Item grow={1} styles={SiteConstants.height100per}>
                                        {currentQuestionInfo && (
                                            <RocketTemplateQuestionAnswerTypeCheck
                                                updateAnswerText={(answer: string) => {
                                                    setSelectedAnswerText(answer);
                                                    setAllowSkipping(true);
                                                }}
                                                type={currentQuestionInfo.type}
                                                goToNextQuestion={goToNextQuestion}
                                                showContinueButton={allowSkipping && quizInfoData.quizAllowSkipping}
                                                questionTimeEnds={timerEnd.questionTimeEnds}
                                                key={key}
                                                options={currentQuestionInfo?.mcqOptions}
                                                completedConnector={connectorQuestionInfo.playedConnectorOptionQuizIds}
                                                answerHeader={currentQuestionInfo.answerHeader}
                                                connectorQuizIsWorking={connectorQuestionInfo.connectorQuizIsWorking}
                                                setHintDisable={(hintViewType: boolean) => setHintDisable(hintViewType)}
                                                gameBackgroundColor={quizConfig.backgroundColor}
                                                optionColor={quizConfig.buttonColor}
                                            />
                                        )}
                                    </Stack.Item>
                                </div>
                            )}
                        </Col>
                        <Col sm={3} className={"margin-0 padding-0 height-100per"}>
                            <div
                                className={
                                    hints && hints.length > 0 && currentQuestionInfo?.type !== CONNECTOR && !endQuiz
                                        ? "right-section-cover-image"
                                        : "right-section-cover-image-height-100"
                                }>
                                <QuizCoverImage
                                    correctAnswerBgColor={quizConfig.correctAnswerBackgroundColor}
                                    wrongAnswerBgColor={quizConfig.wrongAnswerBackgroundColor}
                                    totalMarks={quizUserSessionInfoData.totalMarks}
                                    progressImage={
                                        quizConfig && quizConfig.progressImage ? quizConfig.progressImage : RocketImage
                                    }
                                    progressTitle={
                                        quizConfig && quizConfig.progressTitle
                                            ? quizConfig.progressTitle
                                            : "Repair Status"
                                    }
                                    showProgressInPercentage={quizInfoData.showProgressInPercentage}
                                    progressColor={quizConfig.buttonColor}
                                />
                            </div>
                            {hints && hints.length > 0 && currentQuestionInfo?.type !== CONNECTOR && !endQuiz && (
                                <div className={"right-section-hint"}>
                                    <QuizHintsView
                                        getTotalMarks={(marks: number) =>
                                            setQuizUserSessionInfoData((prevState) => ({
                                                ...prevState,
                                                totalMarks: marks
                                            }))
                                        }
                                        isProgressInPercentage={quizInfoData.showProgressInPercentage}
                                        hintCount={quizUserSessionInfoData.hintUsed}
                                        hints={hints}
                                        increaseHintCount={helpTaken}
                                        questionTimeEnds={timerEnd.questionTimeEnds}
                                        totalMarks={quizUserSessionInfoData.totalMarks}
                                        hintDisable={hintDisable}
                                        gameBackgroundColor={quizConfig.backgroundColor}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Stack>
            </Stack>
            {internetLostModal}
        </>
    );
};

export default RocketTemplateView;

import { Dialog, DialogContent, DialogTitle, Drawer, Grid } from "@mui/material";
import { Header1, PrimaryButton } from "../../../CommonComponent/v2/CommonStyle";
import SearchHeader from "../../../CommonComponent/v2/searchheader/SearchHeader";
import TabWrapper from "../../../CommonComponent/v2/tab/Tab";
import DownloadFile from "../eventmanager/eventinfo/downloadfile";
import FileDetailDrawer from "./filedetail";
import FileManagerList from "./FileManagerList";
import { useGetFileManagerHook } from "./Hooks";
import { SelectedFileType } from "./Type";
import { getFilterFileListByType } from "./Util";

const EventFiles = () => {
    const {
        fileTabs,
        selectedTab,
        setSelectedTab,
        searchText,
        setSearchText,
        loading,
        eventList,
        selectedEventId,
        setSelectedEventId,
        setOpenDownloadFileModal,
        openDownloadFileModal
    } = useGetFileManagerHook();

    return (
        <Grid container padding={"20px"}>
            <Grid item xs={12}>
                <SearchHeader
                    searchText={searchText}
                    setSearchText={setSearchText}
                    showDownloadButton
                    buttonLabel="Download all data"
                    onDownloadButtonClick={() => setOpenDownloadFileModal(true)}
                />
                <Grid container spacing={"20px"} mt={"20px"}>
                    <Grid item xs={12}>
                        <TabWrapper tabsOptions={fileTabs} selectedTab={selectedTab} handleChange={setSelectedTab} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid mt={"30px"} item xs={12}>
                <FileManagerList
                    eventList={getFilterFileListByType(eventList, selectedTab)}
                    isLoading={loading}
                    searchText={searchText}
                    openFileDetailPanel={(eventInfo: SelectedFileType) => setSelectedEventId(eventInfo)}
                />
            </Grid>
            {selectedEventId && (
                <Drawer
                    anchor={"right"}
                    open
                    PaperProps={{
                        style: {
                            width: "70%"
                        }
                    }}
                    onClose={() => setSelectedEventId(null)}>
                    <FileDetailDrawer eventInfo={selectedEventId} />
                </Drawer>
            )}
            {openDownloadFileModal && (
                <Dialog maxWidth={"md"} fullWidth open onClose={() => setOpenDownloadFileModal(false)}>
                    <DialogTitle>
                        <Header1>Download</Header1>
                    </DialogTitle>
                    <DialogContent>
                        <DownloadFile />
                        <Grid container mt={"20px"} justifyContent={"center"}>
                            <PrimaryButton onClick={() => setOpenDownloadFileModal(false)} variant="contained">
                                Close
                            </PrimaryButton>
                        </Grid>
                    </DialogContent>
                </Dialog>
            )}
        </Grid>
    );
};

export default EventFiles;

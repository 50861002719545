import { GridCellParams } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../../utils/APIErrorMessages";
import { StyledColumn, StyledHeader } from "../../../../../CommonComponent/v2/datagrid/DataGridStyle";
import { ParticipantEmailType } from "./Type";
import { getEmailList } from "./Util";

export const useParticpantEmailHook = () => {
    const [participantEmail, setParticipantEmail] = useState<ParticipantEmailType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");

    const { id } = useParams<{
        id: string;
    }>();

    useEffect(() => {
        setIsLoading(true);
        getEmailList(parseInt(id))
            .then((res) => {
                setParticipantEmail(res);
            })
            .catch((err: any) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                setParticipantEmail([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const useColumns = useMemo(
        () => [
            {
                field: "id",
                flex: 1,
                renderHeader: () => <StyledHeader label="Id" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.id} />
            },
            {
                field: "email",
                flex: 1,
                renderHeader: () => <StyledHeader label="Email address" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.email} />
            }
        ],
        []
    );

    return {
        participantEmail,
        isLoading,
        useColumns,
        setSearchText,
        searchText
    };
};

import React, { ReactNode } from "react";
import { Stack, StackItem, PrimaryButton, IStackTokens, IDropdownOption } from "@fluentui/react";
import SameLineLabelTextField from "./SameLineLabelTextField";
import DropdownWrapper from "./DropdownWrapper";
import TextFieldWrapper from "./TextFieldWrapper";

export type IListSearchProps = {
    searchText?: string;
    onSearchTextChange?: (newSearchText: string) => void;
    descriptionText?: string;
    onAddButtonClick?: () => void;
    hideAddNewButton?: boolean;
    showDropdownFilter?: boolean;
    onDropdownValueChange?: (value: string) => void;
    dropdownOptions?: IDropdownOption[];
    selectedDropdownKey?: string | number;
    buttonLabel?: string;
    searchFieldWithoutLabel?: boolean;
    addNewButtonClassName?: string;
    hideSearchField?: boolean;
    searchFieldWidth?: string;
    isAddButtonDisabled?: boolean;
    statusField?: boolean;
    onStatusValueChange?: (value: string) => void;
    selectedStatusKey?: string | number;
    statusDropdownOptions?: IDropdownOption[];
    filterLabel?: string;
    addBulkCodeButton?: ReactNode;
    totalCount?: ReactNode;
    downloadZip?: ReactNode;
    addRefreshButton?: ReactNode;
};

const ListSearch: React.FC<IListSearchProps> = ({
    searchText,
    onSearchTextChange,
    descriptionText,
    onAddButtonClick,
    hideAddNewButton,
    showDropdownFilter,
    onDropdownValueChange,
    dropdownOptions,
    selectedDropdownKey,
    buttonLabel,
    searchFieldWithoutLabel,
    addNewButtonClassName,
    hideSearchField,
    searchFieldWidth,
    isAddButtonDisabled,
    statusField,
    onStatusValueChange,
    selectedStatusKey,
    statusDropdownOptions,
    filterLabel,
    addBulkCodeButton,
    totalCount,
    downloadZip,
    addRefreshButton
}) => {
    const _onSearchTextValueChanged = (siteSearchText: string): void => {
        onSearchTextChange && onSearchTextChange(siteSearchText);
    };
    const horizontalGapStackTokens: IStackTokens = {
        childrenGap: 5,
        padding: 10
    };

    return (
        <Stack horizontal tokens={horizontalGapStackTokens} className={"search-section"}>
            {hideAddNewButton !== null && hideAddNewButton
                ? null
                : onAddButtonClick && (
                      <StackItem grow={addBulkCodeButton ? 0 : 10}>
                          <PrimaryButton
                              className={addNewButtonClassName}
                              text={buttonLabel ? buttonLabel : "Add New"}
                              onClick={onAddButtonClick}
                              disabled={isAddButtonDisabled}
                          />
                      </StackItem>
                  )}
            {addBulkCodeButton && addBulkCodeButton}
            {downloadZip && downloadZip}
            {addRefreshButton && addRefreshButton}
            {totalCount && totalCount}

            {!hideSearchField && (
                <Stack horizontalAlign={searchFieldWidth ? "end" : undefined} grow={2}>
                    {searchFieldWithoutLabel ? (
                        <Stack.Item styles={{ root: { width: searchFieldWidth } }}>
                            <TextFieldWrapper
                                value={searchText || ""}
                                onTextFieldValueChange={_onSearchTextValueChanged}
                                placeholder={"Search"}
                            />
                        </Stack.Item>
                    ) : (
                        <Stack.Item styles={{ root: { width: searchFieldWidth } }}>
                            <SameLineLabelTextField
                                label={"Search"}
                                textFieldValue={searchText || ""}
                                onTextFieldValueChanged={_onSearchTextValueChanged}
                                width={60}
                                description={descriptionText}
                            />
                        </Stack.Item>
                    )}
                </Stack>
            )}

            {showDropdownFilter ? (
                <Stack.Item>
                    <div className="center-content">
                        {filterLabel && <span className="labelText mr-3 ml-4">{filterLabel}</span>}
                        <DropdownWrapper
                            width={130}
                            selectedKey={selectedDropdownKey}
                            options={dropdownOptions ? dropdownOptions : []}
                            onChangeDropdownOption={(value) => onDropdownValueChange && onDropdownValueChange(value)}
                        />
                    </div>
                </Stack.Item>
            ) : null}

            {statusField ? (
                <Stack.Item>
                    <div className="center-content">
                        {" "}
                        <span className="labelText mr-3 ml-4">Status</span>
                        <DropdownWrapper
                            width={130}
                            selectedKey={selectedStatusKey}
                            options={statusDropdownOptions ? statusDropdownOptions : []}
                            onChangeDropdownOption={(value) => onStatusValueChange && onStatusValueChange(value)}
                        />
                    </div>
                </Stack.Item>
            ) : null}
        </Stack>
    );
};

export default ListSearch;

import { ResourceType, RoleMapType } from "./Type";

export const getResourceListFilteredItems = (list: ResourceType[], searchText: string): any =>
    list.filter((item: ResourceType) => searchText === "" || includeInFilteredItems(searchText.toLowerCase(), item));

const includeInFilteredItems = (searchText: string, item: ResourceType): boolean => {
    if (item.name.toLowerCase().includes(searchText)) {
        return true;
    }

    if (item.id.toString().includes(searchText)) {
        return true;
    }

    return false;
};

export const roleNameMap: RoleMapType = {
    event: "Event",
    hint: "Hint",
    question: "Question",
    quiz: "Quiz",
    referenceList: "Reference List",
    tag: "Tag",
    user: "User",
    leaderboard: "Leaderboard",
    role: "Role",
    quiz_fullgame: "Virtual Unlinked",
    quiz_socket: "Virtual Linked",
    quiz_wildgoat: "Physical"
};

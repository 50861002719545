import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { darkPrimaryColor, primaryDarkColor } from "../../../../../constants/ColorConstant";
import { WrongAttemptPenaltyTypeOptions } from "../../../../../constants/DropdownOption";
import { SCORE_PENALTY, TIMEOUT_PENALTY, WildGoatGame } from "../../../../../constants/SiteConstant";
import { CONNECTOR } from "../../../../../data/question/types";
import { CaptionText, Header2 } from "../../../../CommonComponent/v2/CommonStyle";
import FormikRadioGroup from "../../../../CommonComponent/v2/fields/FormikRadioGroup";
import FormikTextEditor from "../../../../CommonComponent/v2/fields/FormikTextEditor";
import FormikTextInput from "../../../../CommonComponent/v2/fields/FormikTextInput";
import FormikToggle from "../../../../CommonComponent/v2/fields/FormikToggle";
import { QuestionDetailType } from "./Type";

const QuestionSettingsForm = () => {
    const { values, setFieldValue } = useFormikContext();
    const { questionSettings, answerDetails, questionIdentification } = values as QuestionDetailType;

    const renderTimepenality = () => {
        if (questionSettings.wrongAttemptPenaltyType.type === TIMEOUT_PENALTY) {
            return (
                <Grid container spacing={"10px"}>
                    {Array(questionSettings.maximumAttempts === "" ? 0 : parseInt(questionSettings.maximumAttempts))
                        .fill("1")
                        .map((item, index) => (
                            <Grid item xs={12} key={item}>
                                <FormikTextInput
                                    label={`Time Penalty ${index + 1}`}
                                    name={`questionSettings.wrongAttemptPenaltyType.timeoutPenalty[${index}]`}
                                    id={`questionSettings.wrongAttemptPenaltyType.timeoutPenalty[${index}]`}
                                />
                            </Grid>
                        ))}
                </Grid>
            );
        }
        return <></>;
    };

    const renderWrongPenalty = () => {
        return (
            <Grid container spacing={"10px"} mt={"30px"}>
                <Grid item xs={3}>
                    <FormikTextInput
                        name={"questionSettings.wrongAttemptPenaltyType.correctPoints"}
                        id={"questionSettings.wrongAttemptPenaltyType.correctPoints"}
                        label={"Correct points"}
                        fullWidth
                    />
                </Grid>
                {questionSettings.wrongAttemptPenaltyType.type === SCORE_PENALTY && (
                    <Grid item xs={3}>
                        <FormikTextInput
                            name={"questionSettings.wrongAttemptPenaltyType.wrongPoints"}
                            id={"questionSettings.wrongAttemptPenaltyType.wrongPoints"}
                            label={"Wrong points"}
                            fullWidth
                        />
                    </Grid>
                )}
                {questionSettings.skippingAllowed && (
                    <Grid item xs={3}>
                        <FormikTextInput
                            name={"questionSettings.wrongAttemptPenaltyType.skipPoints"}
                            id={"questionSettings.wrongAttemptPenaltyType.skipPoints"}
                            label={"Skip points"}
                            fullWidth
                        />
                    </Grid>
                )}
            </Grid>
        );
    };

    return (
        <Grid container mt={"20px"}>
            <Grid item xs={12}>
                <Header2>
                    Challenge settings
                    <span style={{ fontSize: 12, color: "#828282" }}>(optional)</span>
                </Header2>
                <CaptionText>This is how the challenge would react to the player's response</CaptionText>
            </Grid>
            <Grid item xs={12} mt={"20px"}>
                <FormikTextInput
                    name={"questionSettings.maximumAttempts"}
                    id={"questionSettings.maximumAttempts"}
                    label={"Maximum attempts"}
                    fullWidth
                    disabled={questionSettings.forceCorrect}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid mt={"30px"} container spacing={"20px"}>
                    <Grid item xs={4}>
                        <FormikToggle
                            id={"questionSettings.forceCorrect"}
                            name="questionSettings.forceCorrect"
                            label="Force correct"
                            disabled={questionSettings.maximumAttempts !== ""}
                            onChange={(_, checked) => {
                                setFieldValue("questionSettings.forceCorrect", checked);
                                checked && setFieldValue("questionSettings.skippingAllowed", false);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormikToggle
                            id={"questionSettings.nonScoring"}
                            name="questionSettings.nonScoring"
                            label="Non scoring"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormikToggle
                            id={"questionSettings.skippingAllowed"}
                            name="questionSettings.skippingAllowed"
                            label="Skipping allowed"
                            disabled={questionSettings.forceCorrect}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormikToggle
                            id={"questionSettings.isCorrectAnswerText"}
                            name="questionSettings.isCorrectAnswerText"
                            label="Correct answer text"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormikToggle
                            id={"questionSettings.isWrongAnswerText"}
                            name="questionSettings.isWrongAnswerText"
                            label="Wrong answer text"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormikToggle
                            id={"questionSettings.timeoutText.specifyTimeout"}
                            name="questionSettings.timeoutText.specifyTimeout"
                            label="Specify timeout"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    {questionSettings.isCorrectAnswerText && (
                        <Grid item xs={12} mt={"30px"}>
                            <CaptionText my={"10px"} color={darkPrimaryColor} fontWeight={700}>
                                Correct answer text
                            </CaptionText>
                            <FormikTextEditor
                                name={"questionSettings.correctAnswerText"}
                                id={"questionSettings.correctAnswerText"}
                                basicControl
                                placeholder="Enter correct answer text"
                            />
                        </Grid>
                    )}
                    {questionSettings.isWrongAnswerText && (
                        <Grid item xs={12} mt={"60px"}>
                            <CaptionText my={"10px"} color={darkPrimaryColor} fontWeight={700}>
                                Wrong answer text
                            </CaptionText>
                            <FormikTextEditor
                                name={"questionSettings.wrongAnswerText"}
                                id={"questionSettings.wrongAnswerText"}
                                basicControl
                                placeholder="Enter wrong answer text"
                            />
                        </Grid>
                    )}
                    {questionSettings.timeoutText.specifyTimeout && (
                        <>
                            <Grid item xs={12} mt={"60px"}>
                                <CaptionText my={"10px"} color={darkPrimaryColor} fontWeight={700}>
                                    Timeout text
                                </CaptionText>
                                <FormikTextEditor
                                    name={"questionSettings.timeoutText.text"}
                                    id={"questionSettings.timeoutText.text"}
                                    basicControl
                                    placeholder="Enter timeout text"
                                />
                            </Grid>
                            <Grid item xs={12} mt={"60px"}>
                                <CaptionText my={"10px"} color={darkPrimaryColor} fontWeight={700}>
                                    Challenge time limit
                                </CaptionText>
                                <Grid container spacing={"10px"}>
                                    <Grid item xs={3}>
                                        <FormikTextInput
                                            name="questionSettings.timeoutText.timelimit.minutes"
                                            id="questionSettings.timeoutText.timelimit.minutes"
                                            label="Minutes"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormikTextInput
                                            name="questionSettings.timeoutText.timelimit.seconds"
                                            id="questionSettings.timeoutText.timelimit.seconds"
                                            label="Seconds"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
            {answerDetails.answerType !== CONNECTOR && !questionSettings.nonScoring && (
                <Grid item xs={12} mt={"90px"}>
                    {questionIdentification.gameType !== WildGoatGame && (
                        <>
                            <CaptionText color={primaryDarkColor} fontWeight={700}>
                                Wrong attempt penalty type
                            </CaptionText>
                            <FormikRadioGroup
                                row
                                radioOptions={WrongAttemptPenaltyTypeOptions}
                                name="questionSettings.wrongAttemptPenaltyType.type"
                                labelfontweight={400}
                            />
                        </>
                    )}
                    {renderTimepenality()}
                    {renderWrongPenalty()}
                </Grid>
            )}
        </Grid>
    );
};

export default QuestionSettingsForm;

import { AppBar, Toolbar, IconButton, Grid, Menu, Avatar, Box } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { InfoText, MenuItemButton, MenuText } from "./Style";
import MenuIcon from "@mui/icons-material/Menu";
import { useAdminContext } from "../../context/AdminAuthContext";
import moment from "moment";
import CalendarIcon from "../../../Images/icons/calendar.svg";
import ClockIcon from "../../../Images/icons/clock.svg";
import { Header1 } from "../../../CommonComponent/v2/CommonStyle";
import { getMenuName } from "./Util";
import { useLocation } from "react-router";
import { getSingaporeTime } from "../../../../utils/JambarUtils";
import { CiCreditCard1 } from "react-icons/ci";
import { useHistory } from "react-router";
import { getPermissions } from "../../../../utils/PermissionUtils";
import { useUserInfo } from "../../../../commonhooks/Hooks";
import { HIDE_SIDEBAR_URL } from "../../../../constants/SiteConstant";
import JambarLogo from "../../../Images/logo.svg";

type HeaderProps = {
    handleDrawerToggle: () => void;
    drawerWidth: number;
};
const Header: FC<HeaderProps> = ({ handleDrawerToggle, drawerWidth }) => {
    const [time, setTime] = useState("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);
    const open = Boolean(anchorEl);
    const { onLogout } = useAdminContext();
    const location = useLocation();
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const history = useHistory();

    const handleClose = () => {
        setAnchorEl(null);
    };
    const { userDetail, userCredit } = useAdminContext();
    const { isSuperAdmin } = useUserInfo();
    const permissionUser: string[] | null = getPermissions(userDetail, "user");
    const updateTime = () => {
        if (userDetail) {
            setTime(moment(getSingaporeTime()).format("hh:mm:ss a"));
        }
    };

    setInterval(updateTime, 100);

    useEffect(() => {
        if (userDetail) {
            updateTime();
        }
    }, [userDetail]);

    return (
        <AppBar
            sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
                height: { sm: "80px" },
                backgroundColor: "white",
                boxShadow: "none"
            }}>
            <Toolbar style={{ height: "100%" }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: "none" } }}>
                    <MenuIcon />
                </IconButton>
                <Grid container alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item>
                        <Box display={"flex"} alignItems={"center"} gap={"30px"}>
                            {HIDE_SIDEBAR_URL.includes(location.pathname) && (
                                <img
                                    style={{ height: 64, cursor: "pointer" }}
                                    onClick={() => history.push("/admin/event")}
                                    src={JambarLogo}
                                    alt={"logo"}
                                />
                            )}

                            <Header1>
                                {getMenuName(isSuperAdmin)[location.pathname.split("/").pop() || "jambar"]}
                            </Header1>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box display={"flex"} gap={"20px"}>
                            <InfoText display={"flex"} gap={"10px"} alignItems={"center"}>
                                <img style={{ height: 20 }} src={CalendarIcon} alt="Jambar" />
                                {moment(getSingaporeTime()).format("MMMM Do YYYY")}
                            </InfoText>
                            <InfoText display={"flex"} gap={"10px"} alignItems={"center"}>
                                <span style={{ display: "flex", alignItems: "center" }}>
                                    <img style={{ height: 20, paddingRight: 10 }} src={ClockIcon} alt="Jambar" />
                                    <p
                                        style={{
                                            width: "10ch",
                                            marginBottom: 0,
                                            textAlign: "center",
                                            display: "inline-block"
                                        }}>
                                        {time}
                                    </p>
                                </span>
                            </InfoText>
                            {!isSuperAdmin && (
                                <InfoText width={120} display={"flex"} gap={"10px"} alignItems={"center"}>
                                    <CiCreditCard1 size={"30px"} />
                                    {userCredit} Credit
                                </InfoText>
                            )}

                            {userDetail && (
                                <Avatar onClick={handleClick} sx={{ height: 50, width: 50 }}>
                                    {`${userDetail?.firstName[0]?.toUpperCase() || ""}${
                                        userDetail?.lastName[0]?.toUpperCase() || ""
                                    }`}
                                </Avatar>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                {userDetail && (
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button"
                        }}>
                        <MenuItemButton hoverBg="#fff" disableTouchRipple mt={"0px"} isactive={0}>
                            <MenuText
                                textweight="bold"
                                textsize={"18px"}
                                isactive={0}
                                secondary={`${userDetail?.identity?.name || ""}`}
                                primary={`${userDetail?.firstName} ${userDetail?.lastName}`}
                            />
                        </MenuItemButton>
                        {permissionUser?.includes("fetch") && !isSuperAdmin && (
                            <MenuItemButton
                                mt={"0px"}
                                borderRadius="0px"
                                onClick={() => {
                                    history.push("/admin/users");
                                    handleClose();
                                }}
                                isactive={0}>
                                <MenuText isactive={0} primary={"Users"} />
                            </MenuItemButton>
                        )}

                        <MenuItemButton
                            mt={"0px"}
                            onClick={() => {
                                history.push("/admin/subscription");
                                handleClose();
                            }}
                            borderRadius="0px"
                            isactive={0}>
                            <MenuText isactive={0} primary={"Payments"} />
                        </MenuItemButton>
                        <MenuItemButton
                            borderRadius="0px"
                            onClick={() => {
                                history.push("/admin/paymentlist");
                                handleClose();
                            }}
                            isactive={0}>
                            <MenuText isactive={0} primary={"Payments List"} />
                        </MenuItemButton>
                        <MenuItemButton
                            mt={"0px"}
                            borderRadius="0px"
                            onClick={() => {
                                history.push("/admin/password");
                                handleClose();
                            }}
                            isactive={0}>
                            <MenuText isactive={0} primary={"Change Password"} />
                        </MenuItemButton>
                        <MenuItemButton
                            mt={"0px"}
                            borderRadius="0px"
                            onClick={() => {
                                onLogout();
                            }}
                            isactive={0}>
                            <MenuText isactive={0} primary={"Logout"} />
                        </MenuItemButton>
                    </Menu>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;

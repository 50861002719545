import { Grid } from "@mui/material";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { useRoleListHook } from "./Hooks";
import { FC } from "react";
import { RoleDeleteType, RoleType, InfoType } from "./Type";

type RoleListProps = {
    roleList: RoleType[];
    isLoading: boolean;
    setSelectedRoleDeleteId: (info: RoleDeleteType) => void;
    setSelectedRoleRightsId: (info: InfoType) => void;
};

const RefrenceList: FC<RoleListProps> = ({ isLoading, roleList, setSelectedRoleDeleteId, setSelectedRoleRightsId }) => {
    const { roleListColumns } = useRoleListHook();
    return (
        <Grid height={"calc(100vh - 210px)"}>
            <DataGridWrapper
                columns={roleListColumns(setSelectedRoleRightsId, setSelectedRoleDeleteId, (_) => {
                    return;
                })}
                rows={roleList as any}
                loading={isLoading}
            />
        </Grid>
    );
};

export default RefrenceList;

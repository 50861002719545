import { Drawer, Grid } from "@mui/material";
import { Formik } from "formik";
import { FC, useState } from "react";
import FormikTextInput from "../../../CommonComponent/v2/fields/FormikTextInput";
import FormikRadioGroup from "../../../CommonComponent/v2/fields/FormikRadioGroup";
import { YesNoRadioOption } from "../../../../constants/DropdownOption";
import { CancelButton, Header1, PrimaryButton, SubText, TextButton } from "../../../CommonComponent/v2/CommonStyle";
import AddBranchQuestion from "./AddBranchQuestion";
import { BranchInfoType, BranchQuestionListType, FlowBoxValueType } from "./Type";
import ImportQuestionList from "./ImportQuestionList";

type AddNewBranchProps = {
    handleClose: () => void;
    addBranch: (branchInfo: BranchInfoType) => void;
    updateBranch: (branchInfo: FlowBoxValueType) => void;
    branchInfo?: FlowBoxValueType;
    deleteBranch: (branchId: string) => void;
    hideDelete?: boolean;
    quizType: string;
};
const AddNewBranch: FC<AddNewBranchProps> = ({ handleClose, addBranch, branchInfo, updateBranch, deleteBranch, hideDelete, quizType }) => {
    const [openAddQuestionModal, setOpenAddQuestionModal] = useState(false);

    const handleQuestionRemove = (
        questionId: number,
        questionList: BranchQuestionListType[],
        setQuestionList: (questionList: BranchQuestionListType[]) => void
    ) => {
        setQuestionList(questionList.filter((item) => item.id !== questionId).map((item, index) => ({
            ...item,
            id: index
        })));
    };

    return (
        <Drawer
            anchor={"right"}
            open
            PaperProps={{
                style: {
                    width: 800
                }
            }}
            onClose={handleClose}
        >
            <Grid container p={"30px"}>
                <Header1>{branchInfo ? "Update Branch Detail" : "Add Branch Detail"}</Header1>
                <Formik
                    initialValues={{
                        branchName: branchInfo?.branchInfo?.branchName || "",
                        interruptable: branchInfo?.branchInfo?.interruptable ? "yes" : "no",
                        resumable: branchInfo?.branchInfo?.resumable ? "yes" : "no",
                        questionList: branchInfo?.branchInfo?.questionList || [],
                        gameTitle: branchInfo?.branchInfo?.gameTitle || ""
                    }}
                    onSubmit={(values) => {
                        const data: BranchInfoType = {
                            branchName: values.branchName,
                            interruptable: values.interruptable === "yes",
                            questionList: values.questionList,
                            resumable: values.resumable === "yes",
                            gameTitle: values.gameTitle
                        };
                        if (branchInfo) {
                            updateBranch &&
                                updateBranch({
                                    ...branchInfo,
                                    branchInfo: {
                                        ...data
                                    }
                                });
                            return;
                        }
                        addBranch && addBranch(data);
                    }}
                >
                    {({ values, setFieldValue, handleSubmit }) => (
                        <Grid container spacing={"20px"} mt={"40px"}>
                            <Grid item xs={12}>
                                <FormikTextInput
                                    name={"branchName"}
                                    id={"branchName"}
                                    label={"Branch Name"}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SubText>Intruptable</SubText>
                                <FormikRadioGroup
                                    row
                                    radioOptions={YesNoRadioOption}
                                    name="interruptable"
                                    labelfontweight={400}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SubText>Resumable</SubText>
                                <FormikRadioGroup
                                    row
                                    radioOptions={YesNoRadioOption}
                                    name="resumable"
                                    labelfontweight={400}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextButton onClick={() => setOpenAddQuestionModal(true)}>Import questions</TextButton>
                                <ImportQuestionList
                                    handleQuestionRemove={(questionId) => {
                                        handleQuestionRemove(questionId,
                                            values.questionList,
                                            (questionList) => setFieldValue("questionList", questionList)
                                        );
                                    }}
                                    questionList={values.questionList} />
                                {openAddQuestionModal && (
                                    <AddBranchQuestion
                                        setQuizName={(quizName) => setFieldValue("gameTitle", quizName)}
                                        setQuestionList={(questionList) => setFieldValue("questionList", questionList)}
                                        handleClose={() => setOpenAddQuestionModal(false)}
                                        quizType={quizType}
                                    />
                                )}
                            </Grid>
                            {branchInfo && (
                                <Grid item xs={12}>
                                    <SubText>Triggers</SubText>
                                    <Grid container pb={"30px"} borderRadius={"10px"} bgcolor={"#efefef"}>
                                        <Grid p={"20px"} borderBottom={"1px #efefef98 solid"} item xs={12}>
                                            <Grid container>
                                                <Grid item xs={1}>
                                                    <SubText style={{ fontWeight: 700 }}>#</SubText>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <SubText style={{ fontWeight: 700 }}>Trigger name</SubText>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {branchInfo?.triggers.map((item, index) => (
                                            <Grid px={"20px"} py={"10px"} item xs={12} key={item.triggerId}>
                                                <Grid container>
                                                    <Grid item xs={1}>
                                                        <SubText>{index + 1}</SubText>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <SubText>{item.triggerName}</SubText>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12} mt={"30px"}>
                                <Grid container alignItems={"center"} spacing={"20px"} justifyContent={"center"}>
                                    {branchInfo && !hideDelete && (
                                        <Grid item>
                                            <CancelButton
                                                onClick={() => {
                                                    deleteBranch(branchInfo.id);
                                                }}
                                            >
                                                Delete Branch
                                            </CancelButton>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <PrimaryButton
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                        >
                                            Save
                                        </PrimaryButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Grid>
        </Drawer>
    );
};

export default AddNewBranch;

import { useState, useEffect } from "react";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { getPermissions } from "../../../../../../utils/PermissionUtils";
import { useAdminContext } from "../../../../context/AdminAuthContext";
import { Info, ReferenceItemList, ItemsDeleteType } from "./Types";
import {
    StyledActionColumn,
    StyledColumn,
    StyledHeader
} from "../../../../../CommonComponent/v2/datagrid/DataGridStyle";
import { getReferenceItemsData, onConfirmDelete } from "./Utils";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../constants/SiteConstant";

export const useItem = () => {
    const [searchText, setSearchText] = useState("");
    const [itemlist, setItemlist] = useState<ReferenceItemList[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "referenceList");
    const [selectedItemDeleteId, setSelectedItemDeleteId] = useState<ItemsDeleteType | null>();

    const { id } = useParams<{
        id: string;
    }>();
    const Id: number = parseInt(id);

    useEffect(() => {
        fetchItemListData(Id);
    }, []);
    const fetchItemListData = (Id: number): void => {
        setIsLoading(true);
        if (!(permission && permission.includes("fetch"))) {
            setItemlist([]);
            setIsLoading(false);
            return;
        }
        getReferenceItemsData(Id, true)
            .then((response: any) => {
                setItemlist(response);
            })
            .catch(() => {
                setItemlist([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const onItemDelete = () => {
        selectedItemDeleteId &&
            onConfirmDelete(selectedItemDeleteId.id)
                .then(() => {
                    setSelectedItemDeleteId(null);
                    fetchItemListData(Id);
                })
                .catch((error) => {
                    setSelectedItemDeleteId(null);
                    toast.error(error, SiteConstants.deleteToastConfiguration);
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };
    const onItemDeleteClose = () => {
        setSelectedItemDeleteId(null);
    };
    return {
        searchText,
        itemlist,
        isLoading,
        selectedItemDeleteId,
        setSearchText,
        onItemDeleteClose,
        onItemDelete,
        fetchItemListData,
        setSelectedItemDeleteId
    };
};

export const useItemsHooks = () => {
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "referenceList");
    const itemColumns = (
        handleDeleteClick: (info: Info) => void,
        handleEditClick: (id: number) => void
    ): GridColDef[] => [
        {
            field: "title",
            headerName: "Title",
            flex: 1,
            renderHeader: () => <StyledHeader label="Title" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.title} />
        },
        {
            field: "Action",
            headerName: "Action",
            width: 150,
            renderHeader: () => <StyledHeader label="Action" />,

            renderCell: (params: GridCellParams) => (
                <StyledActionColumn
                    isDeleteAvailaible={{
                        isAvailable: !!(permission && permission.includes("delete")),
                        onClick: () =>
                            handleDeleteClick({
                                id: params.row.id,
                                name: params.row.title
                            })
                    }}
                    isEditAvailaible={{
                        isAvailable: !!(permission && permission.includes("update")),
                        onClick: () => {
                            handleEditClick(params.row.id);
                        }
                    }}
                />
            )
        }
    ];

    return {
        itemColumns
    };
};

export const SportsMediumQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "Looks like you don't watch sports at all!",
            imageSrc: "https://i.imgur.com/Aksk6Ik.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Good try! Better luck next time.",
            imageSrc: "https://i.imgur.com/Vp4fJQ6.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Congrats! You're a champion.",
            imageSrc: "https://i.imgur.com/OmyDrVM.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "All of these are real sports except:",
            questionImageSrc: "https://i.imgur.com/a31MYfx.jpg",
            answerDescription:
                "“Cocoball” is not a real sport, has the potential to become an extreme sport……. if someone decides to play dodgeball using coconuts.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Shinty",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Chess Boxing",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Camel racing",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Cocoball",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "Where in Greece were the ancient Olympic games held?",
            questionImageSrc: "https://i.imgur.com/aYq84nd.jpg",
            answerDescription:
                "The place where the ancient games took place was actually a religious site dedicated to the worship of Zeus.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Sicily",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Olympia",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Troy",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Rome",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "When was the first FIFA women’s world cup held?",
            questionImageSrc: "https://i.imgur.com/JLrN9jg.jpg",
            answerDescription:
                "Did you know that women’s football was banned in many countries until the 1970s and 1980s?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 1950",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 2003",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. 1964",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 1991",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "Where is this boxer from?",
            questionImageSrc: "https://i.imgur.com/sBYK6wk.jpg",
            answerDescription: "This boxer is Manny Pacquiao, one of the greatest boxers of all time.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Turkey",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. The Philippines",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Pakistan",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. U.S.A",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "Where did this sport originate?",
            questionImageSrc: "https://i.imgur.com/9pfNqO3.jpg",
            answerDescription: "A professional sumo wrestler is called a rikishi..",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Japan",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Algeria",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. China",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. The Netherlands",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "In which game is the ball pitched underhand?",
            questionImageSrc: "[Imgur](https://i.imgur.com/lsksx1k.jpg)",
            answerDescription:
                "Softball and baseball are different in ways like how the ball is pitched and the number of innings in a game..",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. cricket ",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. baseball",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. softball",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. lawn tennis",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "In cricket, how many balls are bowled in one over?",
            questionImageSrc: "https://i.imgur.com/aEFfJCO.jpg",
            answerDescription: "When the ball is bowled 6 times, this is called an over. ",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. 20",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. 6",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. 12",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. 4",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "When Germany hosted the 1936 Olympics, Adolf Hitler was Chancellor.",
            questionImageSrc: "https://i.imgur.com/GNewEAi.jpg",
            answerDescription:
                "Did you know that track and field athlete Jesse Owens won his four Olympic gold medals at the 1936 games?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. False",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. True",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "On which continent was The Rumble in the Jungle held?",
            questionImageSrc: "https://i.imgur.com/D6XNYbQ.jpg",
            answerDescription:
                "This famous fight took place in Zaire, which is now known as the Democratic Republic of the Congo.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. North America",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Africa",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. South America",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Australia",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "Which of these is not a horse racing event?",
            questionImageSrc: "https://i.imgur.com/7EZ6kXF.jpg",
            answerDescription:
                "The Indianapolis 500 is an actual race - but for cars, not horses. It is held annually on Memorial Day in the United States.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Melbourne Cup",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Indianapolis 500",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Epsom Derby ",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Kentucky Derby",
                    isCorrect: false
                }
            ]
        }
    ]
};

import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { darkLightColor, darkPrimaryColor } from "../../../../../../constants/ColorConstant";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import { CaptionText, Header2 } from "../../../../../CommonComponent/v2/CommonStyle";
import FormikTextInput from "../../../../../CommonComponent/v2/fields/FormikTextInput";
import FormikToggle from "../../../../../CommonComponent/v2/fields/FormikToggle";
import ImageDrop from "../../../../../CommonComponent/v2/fields/ImageDrop";
import { GameDetailType } from "../Type";

const AnswerSettingsForm = () => {
    const { values } = useFormikContext();

    const data = values as GameDetailType;

    const renderSettingForm = () => {
        if (data.gameIdentification.gameType === SiteConstants.wildgoat) {
            return <></>;
        }
        return (
            <>
                <Grid item xs={12}>
                    <FormikTextInput
                        name={"answerSettings.answerResultText"}
                        id={"answerSettings.answerResultText"}
                        label={"Answer result text"}
                        fullWidth
                    />
                    <CaptionText mt={"5px"} style={{ fontStyle: "italic" }} fontWeight={400} color={darkLightColor}>
                        {`{score} for quiz score and {questionscore} for question score`}
                    </CaptionText>
                </Grid>
                <Grid item xs={12}>
                    <CaptionText fontWeight={700} color={darkPrimaryColor}>
                        Answer result image
                    </CaptionText>
                    <Grid>
                        <ImageDrop
                            imageSize={2}
                            variant={"secondary"}
                            selectedFile={data.answerSettings.answerResultImage}
                            logoInfo={"Attach image for the answer result(800px * 600px)"}
                            name={"answerSettings.answerResultImage"}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormikTextInput
                        name={"answerSettings.nextButtonText"}
                        id={"answerSettings.nextButtonText"}
                        label={"Next button text"}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormikToggle
                        id={"answerSettings.skippingAllowed"}
                        name="answerSettings.skippingAllowed"
                        label="Skipping allowed"
                    />
                </Grid>
            </>
        );
    };

    return (
        <Grid container mt={"60px"}>
            <Grid item xs={12}>
                <Header2>Answer settings</Header2>
                <CaptionText mt={"5px"}>This is how the player would interact with the game answers.</CaptionText>
            </Grid>
            <Grid item xs={12}>
                <Grid container mt={"19px"} spacing={"15px"}>
                    {renderSettingForm()}
                    <Grid item xs={12}>
                        <CaptionText fontWeight={700} color={darkPrimaryColor}>
                            Game points
                        </CaptionText>
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"answerSettings.gamePoints.initialGamePoint"}
                            id={"answerSettings.gamePoints.initialGamePoint"}
                            label={"Initial game points"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"answerSettings.gamePoints.correctAnswerPoint"}
                            id={"answerSettings.gamePoints.correctAnswerPoint"}
                            label={"Correct answer points increment"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextInput
                            name={"answerSettings.gamePoints.wrongAnswerPoint"}
                            id={"answerSettings.gamePoints.wrongAnswerPoint"}
                            label={"Wrong answer points deduction"}
                            fullWidth
                        />
                    </Grid>
                    {data.answerSettings.skippingAllowed && (
                        <Grid item xs={12}>
                            <FormikTextInput
                                name={"answerSettings.gamePoints.skipAnswerPoint"}
                                id={"answerSettings.gamePoints.skipAnswerPoint"}
                                label={"Skip answer points"}
                                fullWidth
                            />
                        </Grid>
                    )}
                    {data.gameIdentification.gameType === SiteConstants.wildgoat && (
                        <>
                            <Grid item xs={12}>
                                <CaptionText fontWeight={700} color={darkPrimaryColor}>
                                    Total points text
                                </CaptionText>
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextInput
                                    name={"answerSettings.totalPointsText"}
                                    id={"answerSettings.totalPointsText"}
                                    label={"Total points text"}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AnswerSettingsForm;

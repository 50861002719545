import React, { FC } from "react";
import { DataCaptionText } from "../../../CommonComponent/Style";
import { EventContainer, EventTitle } from "./Style";

type DashboardInfoCardProps = {
    title: string;
    description: string;
};
const DashboardInfoCard: FC<DashboardInfoCardProps> = ({ description, title }) => (
    <EventContainer height={"100%"} textAlign={"center"}>
        <EventTitle textAlign={"center"}>{title}</EventTitle>
        <DataCaptionText textAlign={"center"} marginTop={"30px"}>
            {description}
        </DataCaptionText>
    </EventContainer>
);

export default DashboardInfoCard;

import React, { FC, useCallback, useState } from "react";
import {
    CircleButtonBig,
    InfoText,
    QuestionBox,
    MainText,
    ScrollBox,
    CircleButtonSmall,
    QuestionDetailBox,
    CustomInputBox,
    CircleSkipButton
} from "../CommonStyledComponents/CommonStyle";
import { scoreBoxColor, whiteColor } from "../../../../constants/Styles";
import { Icon } from "@fluentui/react";
import fullScreenIcon from "../../../Images/Wildgoat/fullScreenIcon.svg";
import resizeIcon from "../../../Images/Wildgoat/resize.svg";
import stopWatchIcon from "../../../Images/Wildgoat/stopWatch.svg";
import {
    IconContainer,
    HeadPointsContainer,
    StarPointsContainer,
    OptionBox,
    FullScreenIconImage,
    GreyCircle,
    FlexBox,
    StopWatchContainer,
    PointsText,
    StickyContainer,
    QuestionImage,
    FullScreenImage,
    FullScreenImageContainer,
    ArrowForwardIconWrapper,
    QuestionImageContainer,
    StyledReactPlayer
} from "./Style";
import WildGoatDialog from "../WildgoatDialog/WildGoatDialog";
import SkipImage from "../../../Images/Wildgoat/Skip.svg";
import { FILE_UPLOAD, MCQ, MCQAnswerOption, QUESTION_TIMER_TYPE } from "../../../../data/question/types";
import Timer from "../../../QuizView/Timer";
import { InputGroup } from "react-bootstrap";
import PhotoQuestion from "../PhotoQuestion/PhotoQuestion";
import { McqLoading, SelectedButton, UnSelectedButton } from "../../../../constants/SiteConstant";
import loadingGif from "../../../Images/loading.gif";
import { Drawer } from "@mui/material";
import SideBar from "../SideBar/SideBar";
import ParticipantLocationMap from "./ParticipantLocationMap";
import { youtubeParser } from "../../../../utils/JambarUtils";
import LiveTeamPerformance from "../LiveTeamPerformance";
import { QR_CODE } from "../../../Admin/v2/questionlibrary/questionInfo/Type";
import QRReaderView from "../QrReader/QRReaderView";

interface QuestionViewProps {
    mcqOptions: MCQAnswerOption[];
    questionTextone: string;
    questionTexttwo: string;
    questionTime: string;
    setQuestionTiming: (time: string) => void;
    backgroundColor: string;
    isSkipAllowed: boolean;
    teamPoints: number;
    correctAnswerPoints: number;
    participantAnswer: string;
    mcqColor: string;
    updateParticipantAnswer: (answer: string) => void;
    saveProgress: ({ isSkipped, submittedAnswer }: { isSkipped?: boolean; submittedAnswer?: string }) => void;
    questionCoverImage: string;
    type: string;
    hideTimer: boolean;
    questionTitle: string;
    uploadFile: (file: File[]) => void;
    uploadedPercentage: number;
    isFileUploadFailed: boolean;
    isQuestionNonScoring: string;
    isFileUploading: boolean;
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    userName: string;
    teamName: string;
    videoLocation?: string;
    youtubeVideoLink?: string;
    totalPointsText?: string;
    totalScoreVisibility: boolean;
}
const QuestionView: FC<QuestionViewProps> = ({
    mcqOptions,
    questionTextone,
    questionTexttwo,
    questionTime,
    setQuestionTiming,
    isSkipAllowed,
    teamPoints,
    correctAnswerPoints,
    participantAnswer,
    updateParticipantAnswer,
    saveProgress,
    questionCoverImage,
    type,
    hideTimer,
    uploadFile,
    uploadedPercentage,
    isFileUploadFailed,
    isQuestionNonScoring,
    isFileUploading,
    primaryColor,
    secondaryColor,
    tertiaryColor,
    userName,
    teamName,
    videoLocation,
    youtubeVideoLink,
    totalPointsText,
    totalScoreVisibility
}) => {
    const getParticipantAnswer = () => {
        if (participantAnswer) {
            return participantAnswer.split(";");
        }
        return [];
    };
    const [isAnswerSubmitting, setIsAnswerSubmitting] = useState(false);
    const [submitQuestion, setSubmitQuestion] = useState<boolean>(false);
    const [skipQuestion, setSkipQuestion] = useState<boolean>(false);
    const [fullScreenImage, setFullScreenImage] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string[]>(getParticipantAnswer);
    const [selectedOptionName, setSelectedOptionName] = useState<string[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [drawerContent, setDrawerContent] = useState<string>("");

    const answerSelected = (option: MCQAnswerOption) => {
        let selectedOptionArray = [];
        if (selectedOption.includes(option.id.toString())) {
            selectedOptionArray = selectedOption.filter((item: string) => item !== option.id.toString());
            const selectedOptionArrayName = selectedOptionName.filter((item: string) => item !== option.text);
            setSelectedOptionName(selectedOptionArrayName);
            setSelectedOption(selectedOptionArray);
            updateParticipantAnswer(selectedOptionArray.join(";"));
            return;
        }
        setSelectedOptionName([...selectedOptionName, option.text]);

        selectedOptionArray = [...selectedOption, option.id.toString()];
        setSelectedOption(selectedOptionArray);
        updateParticipantAnswer(selectedOptionArray.join(";").toString());
    };

    const getQuestionFieldView = () => {
        if (type === FILE_UPLOAD) {
            return (
                <PhotoQuestion
                    files={files}
                    setFiles={setFiles}
                    uploadFiles={uploadFile}
                    primaryColor={primaryColor}
                    uploadedPercentage={uploadedPercentage}
                    isFileUploadFailed={isFileUploadFailed}
                />
            );
        }

        if (type === QR_CODE) {
            return (
                <QRReaderView
                    submitAnswer={(qrCodeValue) => {
                        saveProgress({
                            isSkipped: false,
                            submittedAnswer: qrCodeValue
                        });
                    }}
                />
            );
        }
        if (type === MCQ) {
            return mcqOptions.map((option) => (
                <OptionBox
                    fontcolor={primaryColor}
                    onClick={() => answerSelected(option)}
                    background={selectedOption.includes(option.id.toString()) ? secondaryColor : scoreBoxColor}
                    key={option.id}>
                    {option.text}
                </OptionBox>
            ));
        }

        return (
            <InputGroup size="sm" className={"margin-top-15"}>
                <CustomInputBox
                    placeholder={"Enter answer here"}
                    value={participantAnswer}
                    fontcolor={primaryColor}
                    onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                        updateParticipantAnswer(target.value)
                    }
                />
            </InputGroup>
        );
    };

    const getButtonType = useCallback(() => {
        if (isAnswerSubmitting) {
            return McqLoading;
        }
        if (type === FILE_UPLOAD) {
            if (files.length === 0 || isFileUploading) {
                return UnSelectedButton;
            }
            return SelectedButton;
        }
        if (participantAnswer === "") {
            return UnSelectedButton;
        }
        return SelectedButton;
    }, [isAnswerSubmitting, isFileUploading, type, participantAnswer]);

    const renderFile = () => {
        if (!videoLocation && !questionCoverImage && !youtubeVideoLink) {
            return <></>;
        }
        if (youtubeVideoLink) {
            return (
                <StyledReactPlayer
                    url={`https://www.youtube.com/watch?v=${youtubeParser(youtubeVideoLink)}`}
                    width="100%"
                    height="100%"
                    muted
                    controls
                    playing
                />
            );
        }
        if (videoLocation) {
            return (
                <video muted width="100%" playsInline style={{ borderRadius: 5 }} autoPlay controls>
                    <source src={videoLocation} />
                </video>
            );
        }
        if (questionCoverImage) {
            return <QuestionImage src={questionCoverImage} alt="lockImage" />;
        }
    };

    const renderFullScreenImage = () => {
        if (videoLocation || !questionCoverImage) {
            return <></>;
        }
        if (questionCoverImage) {
            return (
                <IconContainer bottom_pos="10%" onClick={() => setFullScreenImage(true)}>
                    <GreyCircle>
                        <FullScreenIconImage src={fullScreenIcon} alt="fullscreenIcon" />
                    </GreyCircle>
                </IconContainer>
            );
        }
        return <></>;
    };

    return (
        <>
            {fullScreenImage ? (
                <FullScreenImageContainer>
                    <FullScreenImage src={questionCoverImage} alt="lockImage" />
                    <IconContainer bottom_pos="2%" onClick={() => setFullScreenImage(false)}>
                        <GreyCircle>
                            <FullScreenIconImage src={resizeIcon} alt="resizeIcon" />
                        </GreyCircle>
                    </IconContainer>
                </FullScreenImageContainer>
            ) : (
                <>
                    <div style={{ flex: 1 }}>
                        <QuestionBox style={{ marginTop: 11 }}>
                            <ScrollBox>
                                <StickyContainer
                                    onClick={() => !videoLocation && setFullScreenImage(true)}
                                    iscoverimage={questionCoverImage ? 1 : 0}>
                                    <QuestionImageContainer hidebackground={!videoLocation}>
                                        {renderFile()}
                                        {totalScoreVisibility && (
                                            <HeadPointsContainer>
                                                <PointsText color={primaryColor}>
                                                    {totalPointsText || "Total points:"} {teamPoints}
                                                </PointsText>
                                            </HeadPointsContainer>
                                        )}

                                        {((questionCoverImage && !isQuestionNonScoring) || videoLocation) && (
                                            <StarPointsContainer isimage={!videoLocation}>
                                                <Icon iconName={"FavoriteStarFill"} style={{ fontSize: 15 }} />
                                                <span className="margin-left-10">{correctAnswerPoints} points</span>
                                            </StarPointsContainer>
                                        )}
                                        {renderFullScreenImage()}
                                    </QuestionImageContainer>
                                </StickyContainer>
                                <QuestionDetailBox>
                                    <FlexBox>
                                        {!hideTimer && (
                                            <div>
                                                <StopWatchContainer className="margin-left-10">
                                                    <img src={stopWatchIcon} alt="stopWatchIcon" />
                                                    <div className="margin-left-10">
                                                        <MainText color={secondaryColor}>
                                                            <Timer
                                                                type={QUESTION_TIMER_TYPE}
                                                                timer={questionTime}
                                                                getTiming={setQuestionTiming}
                                                                key={"quizTimer"}
                                                                eventFinishes={false}
                                                            />
                                                        </MainText>
                                                    </div>
                                                </StopWatchContainer>
                                            </div>
                                        )}
                                    </FlexBox>
                                    {questionTextone && (
                                        <InfoText className={"quill-editor-text-align"} style={{ margin: "15px 0px" }}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: questionTextone
                                                }}
                                            />
                                        </InfoText>
                                    )}
                                    {questionTexttwo && (
                                        <InfoText className={"quill-editor-text-align"} style={{ marginBottom: 15 }}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: questionTexttwo
                                                }}
                                            />
                                        </InfoText>
                                    )}
                                    <div style={{ paddingBottom: 50 }}>{getQuestionFieldView()}</div>

                                    <CircleButtonBig
                                        selectionType={getButtonType()}
                                        disabled={
                                            type === FILE_UPLOAD
                                                ? files.length === 0 || isFileUploading
                                                : participantAnswer === ""
                                        }
                                        secondaryColor={secondaryColor}
                                        tertiarycolor={tertiaryColor}
                                        onClick={() => {
                                            setSubmitQuestion(true);
                                            setIsAnswerSubmitting(true);
                                        }}>
                                        {isFileUploading ? (
                                            <img src={loadingGif} style={{ height: 10 }} alt={"loading"} />
                                        ) : (
                                            <ArrowForwardIconWrapper
                                                tertiaryColor={tertiaryColor}
                                                secondaryColor={primaryColor}
                                                selectionType={getButtonType()}
                                                primaryColor={secondaryColor}
                                            />
                                        )}
                                    </CircleButtonBig>
                                    {false && (
                                        <CircleButtonSmall onClick={() => setOpenDrawer(true)}>
                                            <Icon
                                                iconName={"globalnavbutton"}
                                                style={{ fontSize: 20, color: whiteColor }}
                                            />
                                        </CircleButtonSmall>
                                    )}
                                    <Drawer
                                        anchor={"left"}
                                        PaperProps={{
                                            style: {
                                                width: "80%",
                                                background: primaryColor
                                            }
                                        }}
                                        open={openDrawer}
                                        onClose={() => setOpenDrawer(false)}>
                                        <SideBar
                                            menuSelect={(value) => {
                                                setDrawerContent(value);
                                                setOpenDrawer(false);
                                            }}
                                            userName={userName}
                                            teamName={teamName}
                                            primaryColor={primaryColor}
                                        />
                                    </Drawer>
                                    {isSkipAllowed && (
                                        <CircleSkipButton
                                            fontcolor={primaryColor}
                                            secondaryColor={secondaryColor}
                                            tertiarycolor={tertiaryColor}
                                            onClick={() => setSkipQuestion(true)}>
                                            Skip
                                        </CircleSkipButton>
                                    )}
                                </QuestionDetailBox>
                            </ScrollBox>
                        </QuestionBox>
                    </div>
                    <WildGoatDialog
                        submitButtonText="Submit"
                        cancelButtonText="Cancel"
                        modalTitle={selectedOptionName.join(",")}
                        modalSubText="Are you sure you want to submit this answer?"
                        show={submitQuestion}
                        backgroundColor={primaryColor}
                        onHide={() => {
                            setSubmitQuestion(false);
                            setIsAnswerSubmitting(false);
                        }}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        onSubmit={() => {
                            saveProgress({
                                isSkipped: false
                            });
                            setSubmitQuestion(false);
                        }}
                    />
                    <WildGoatDialog
                        submitButtonText="Yes"
                        cancelButtonText="Cancel"
                        modalTitle="Skip Question?"
                        modalSubText="Are you sure you want to skip this question?"
                        show={skipQuestion}
                        onHide={() => setSkipQuestion(false)}
                        onSubmit={() => {
                            setSkipQuestion(false);
                            saveProgress({
                                isSkipped: true
                            });
                        }}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        modalImageSrc={SkipImage}
                    />
                    {drawerContent === "map" && <ParticipantLocationMap handleClose={() => setDrawerContent("")} />}
                    {drawerContent === "leaderboard" && (
                        <LiveTeamPerformance handleClose={() => setDrawerContent("")} />
                    )}
                </>
            )}
        </>
    );
};

export default QuestionView;

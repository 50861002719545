import { Stack } from "@fluentui/react";
import React, { useState } from "react";
import { FC } from "react";
import { Button } from "react-bootstrap";
import { ThemedMediumStackTokens } from "../../../../constants/Styles";
import SurveyForm from "../../RocketTemplate/Feedback/SurveyForm";
import { FeedbackContentBox } from "../CommonStyledComponents/CommonStyle";

interface WildGoatSummaryViewProps {
    gameBackgroundColor: string;
    info: string;
    eventLink: string;
    buttonColor: string;
    showSurveyForm: boolean;
    eventId: string;
    partcipantName: string;
    participantCode: string;
}
const WildGoatSummaryView: FC<WildGoatSummaryViewProps> = ({
    buttonColor,
    eventId,
    eventLink,
    gameBackgroundColor,
    info,
    partcipantName,
    participantCode,
    showSurveyForm
}) => {
    const [showFeedBackForm, setShowFeedBackForm] = useState(false);

    return (
        <FeedbackContentBox style={{ marginTop: 11 }} backgroundcolor={gameBackgroundColor}>
            {showFeedBackForm ? (
                <SurveyForm
                    eventLink={eventLink}
                    ratingOptionColor={buttonColor}
                    eventId={eventId}
                    participantName={partcipantName}
                    participantCode={participantCode}
                />
            ) : (
                <>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: info
                        }}
                    />
                </>
            )}
            {!showFeedBackForm && (
                <>
                    <Stack
                        horizontal
                        verticalAlign="center"
                        horizontalAlign={"center"}
                        tokens={ThemedMediumStackTokens}>
                        {showSurveyForm && (
                            <Button className={"answer-submit"} onClick={() => setShowFeedBackForm(true)}>
                                Submit survey
                            </Button>
                        )}
                        <Button
                            className={"answer-submit"}
                            onClick={() => (window.location.href = `/event/${eventLink}`)}>
                            Finish
                        </Button>
                    </Stack>
                </>
            )}
        </FeedbackContentBox>
    );
};

export default WildGoatSummaryView;

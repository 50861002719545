import Style from "@emotion/styled";
import { scoreBoxColor, whiteColor, purpleColor } from "../../../../constants/Styles";

export const CenterContainer = Style.div`
display: grid;
place-items: center;
color: ${purpleColor};
`;

type BackgroundContainerProps = {
    bgcolor: string;
    bgcolor_offset: string;
};

export const BackgroundContainer = Style.div<BackgroundContainerProps>`
width: 100%;
height: 200px;
background: repeating-linear-gradient(
    -55deg,
    ${(props) => props.bgcolor},
    ${(props) => props.bgcolor} 90px,
    ${(props) => props.bgcolor_offset} 5px,
    ${(props) => props.bgcolor_offset} 150px
);
border-bottom-left-radius: 35px;
border-bottom-right-radius: 35px;
display:grid;
place-items:center;
`;

export const CrownRectangle = Style.div`
background: ${whiteColor};
display: flex;
padding: 5px 15px;
border-radius: 30px;
gap: 10px;
align-items: center;
margin-bottom: 10px;
`;

export const PositionText = Style.p`
margin:0;
padding:0;
font-size: 16px;
`;

export const AvatarContainer = Style.div`
display: flex;
align-items: center;
gap: 38px;
`;

export const PosAvatarBox = Style.div`
display: flex;
flex-direction: column;
gap: 5px;
align-items: center;
`;

export const RunnerUpLogo = Style.img`
border-radius: 50%;
height: 60px;
width: 60px;
background: ${whiteColor};
`;

export const WinnerLogo = Style.img`
border-radius: 50%;
height: 80px;
width: 80px;
background: ${whiteColor};
`;

export const ScoreBoxContainer = Style.div`
margin-top: 8px;
width: 90%;
`;

export const ScoreBox = Style.div`
display: flex;
justify-content: space-between;
align-items: center;
width:100%;
background: ${scoreBoxColor};
border-radius: 30px;
padding: 8px 18px;
margin-bottom: 10px;
`;

export const PlayerDetail = Style.div`
display: flex;
align-items: center;
gap: 10px;
color: ${purpleColor};
`;

export const LogoContainer = Style.div`
border-radius: 50%;
background: ${whiteColor};
`;

export const PlayerLogo = Style.img`
    width: 40px;
    height: 40px;
`;

export const NameText = Style.p`
margin: 0;
padding: 0;
`;
export const ScoreText = Style.p`
margin: 0;
padding: 0;
`;

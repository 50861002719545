import { ApiConstants } from "../../../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../../../constants/JambarDateUtils";
import { extractHtmlContent } from "../../../../../utils/JambarUtils";
import { BreadCrumbType } from "../../../../CommonComponent/v2/Type";
import { Client } from "../../../../Base/Client";
import { IMcqOptions, McqOptionType, QuestionDetailType, QuestionHintsType, YOUTUBE_LINK } from "./Type";
import { OptionType } from "../../../../CommonComponent/v2/fields/Type";
import { SiteConstants } from "../../../../../constants/SiteConstant";

export const questionDetailsBreadcrumbValues: BreadCrumbType[] = [
    {
        label: "Challenge library / Challenges / Challenge details",
        url: "/admin/v2/questionlibrary"
    },
    {
        label: "Challenge"
    }
];

export const tranformGameTypeOption: Record<string, string> = {
    virtualLinked: SiteConstants.spaceGameQuiz,
    socketGame: SiteConstants.socketQuiz,
    wildgoatGame: SiteConstants.wildgoat
};

export const revTranformGameTypeOption: Record<string, string> = {
    fullgame: "virtualLinked",
    simplesocket: "socketGame",
    wildgoat: "wildgoatGame"
};

const transformMcqData = (data: any): McqOptionType => {
    if (!data || !data.mcqOptions || !data.mcqAnswers) {
        return {
            correctOptionsText: [],
            wrongOptionsText: []
        };
    }

    const mcqOptions = JSON.parse(data.mcqOptions);
    const mcqAnswer = data.mcqAnswers.split(",").map((item: any) => parseInt(item));
    const correctAnswerIndex: IMcqOptions[] = [];
    const wrongAnswerIndex: IMcqOptions[] = [];

    mcqOptions.forEach((option: { text: string; id: number }) => {
        if (mcqAnswer.includes(option.id)) {
            correctAnswerIndex.push(option);
        } else {
            wrongAnswerIndex.push(option);
        }
    });
    return {
        correctOptionsText: correctAnswerIndex,
        wrongOptionsText: wrongAnswerIndex
    };
};

const transformData = (data: any): QuestionDetailType => {
    const timeLimit = data.timeLimit.split(":");
    return {
        visibility: data?.accessibilityScope || "",
        questionType: data.type,
        gameType: tranformGameTypeOption[data.gameType] || "",
        startDate: JambarDateUtil.dateFormat(data.eventStartDate, "YYYY-MM-DD"),
        endDate: JambarDateUtil.dateFormat(data.eventEndDate, "YYYY-MM-DD"),
        coverImage: data.fileLocation ? data.fileLocation : "",
        answerType: data.answerType ? data.answerType : "",
        videoType: data.youtubeVideoLink ? YOUTUBE_LINK : null,
        youtubeVideoText: data.youtubeVideoLink ? data.youtubeVideoLink : null,
        isVideo: !!data.videoQuestionFileLocation,
        videoQuestion: data.videoQuestionFileLocation,
        tags: data.questionTags ? data.questionTags.map((tag: any) => tag.tag.id) : [],
        question: data.questionText,
        correctAnswerText: data.correctAnswerText,
        wrongAnswerText: data.wrongAnswerText,
        maxAttempts: data.maxAttempts,
        fileInfo: {
            fileName: data.fileName,
            fileSize: data?.fileSize ? data.fileSize / 1024 : 0
        },
        minutes: timeLimit[1],
        seconds: timeLimit[2],
        accessibleIdentityIds: data?.accessibleIdentityIds || [],
        specifyAnswerText: data.specifyAnswerText,
        timeOutText: data.timeOutText,
        skippingAllowed: data.allowSkipping,
        timeOutAllowed: data.timeLimit !== "00:00:00",
        timePenalty: data.timePenaltyOnWrongAttempts,
        QuestionTitle: data.title ? extractHtmlContent(data.title) : "",
        isNonScoring: data.isNonScoringQuestion,
        bottomQuestionText: data.questionText2 ? data.questionText2 : "",
        answerHeader: data.answerHeader ? data.answerHeader : "",
        referenceItemText1: data.referenceItemText1 ? data.referenceItemText1 : "",
        referenceItemText2: data.referenceItemText2 ? data.referenceItemText2 : "",
        showConnectorTimer: data.showConnectorTimer,
        completionTriggerQuizScore: data.thresholdQuizScore || "",
        forceCorrect: data.forceCorrect || false,
        createdUserDetail: {
            firstName: data.createdByUser.firstName,
            id: data.createdByUser.id,
            lastName: data.createdByUser.lastName
        },
        updatedUserDetail: {
            firstName: data.updatedByUser.firstName,
            id: data.updatedByUser.id,
            lastName: data.updatedByUser.lastName
        },
        mcqOptions: transformMcqData(data),
        freeTextAnswer: data.answer ? data.answer.split(",") : [],
        correctPoints: data.correctPoints,
        wrongPoints: data.wrongPoints,
        skipPoints: data.skipPoints,
        isDeleted: data?.isDeleted || false
    };
};

export const getQuestionDetails = (id: number) =>
    Client.getInstance()
        .getData(ApiConstants.getQuestionDetailsApiUrl(id), true)
        .then((response) => Promise.resolve(transformData(response.data.data)))
        .catch((error) => Promise.reject(error));

const transformTagDropdown = (data: any): OptionType[] => {
    if (!data) {
        return [];
    }
    return data.map((tag: any) => ({
        value: tag.id,
        label: tag.value
    }));
};

export const fetchTagsDropdownData = (forceRefresh = false) =>
    Client.getInstance()
        .getData(ApiConstants.getTagsListApiUrl(), forceRefresh)
        .then((response: any) => Promise.resolve(transformTagDropdown(response.data.data)))
        .catch((error) => Promise.reject(error));

export const QuestionDetailsTabs = (showHints = false) => [
    {
        label: "Challenge",
        value: "details",
        toShow: true
    },
    {
        label: "Answer",
        value: "answer",
        toShow: true
    },
    {
        label: "Settings",
        value: "settings",
        toShow: true
    },
    {
        label: "Hints",
        value: "hints",
        toShow: showHints
    }
];

const transformHints = (data: any): QuestionHintsType[] => {
    if (!data) {
        return [];
    }
    return data.map((hint: any) => ({
        id: hint.id,
        scorePenality: hint.scorePenalty,
        timePenality: hint.timePenalty,
        description: extractHtmlContent(hint.description)
    }));
};

export const getQuestionHints = (id: number) => {
    return Client.getInstance()
        .getData(ApiConstants.getAllHintsOfQuestion(id), true)
        .then((response) => Promise.resolve(transformHints(response.data.data)))
        .catch((error) => Promise.reject(error));
};

import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import FormikTextInput from "../../../CommonComponent/v2/fields/FormikTextInput";
import { useFormikContext } from "formik";
import { PublicGameFormType } from "../type";
import { Delete } from "@mui/icons-material";
import { PrimaryButton } from "../../../CommonComponent/v2/CommonStyle";

type PublicGameFormProps = {
    submitFormLoading: boolean;
};

const PublicGameForm = ({ submitFormLoading }: PublicGameFormProps) => {
    const { values, setFieldValue, handleSubmit } = useFormikContext<PublicGameFormType>();
    const { noOfPlayer, emailIds } = values;

    const handlePlayerCountChange = (newCount: number) => {
        if (newCount < 1) newCount = 1;
        const currentEmailCount = emailIds.length;
        if (newCount > currentEmailCount) {
            setFieldValue("emailIds", [...emailIds, ...Array(newCount - currentEmailCount).fill("")]);
        } else if (newCount < currentEmailCount) {
            setFieldValue("emailIds", emailIds.slice(0, newCount));
        }
        setFieldValue("noOfPlayer", newCount);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormikTextInput
                    name="noOfPlayer"
                    id="noOfPlayer"
                    label="Number of Players"
                    fullWidth
                    type="number"
                    onChange={(e) => handlePlayerCountChange(parseInt(e.target.value))}
                    value={noOfPlayer}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {emailIds.map((_, index) => (
                        <Grid item xs={12} key={index}>
                            <Box display="flex" gap="10px">
                                <FormikTextInput
                                    name={`emailIds[${index}]`}
                                    id={`emailIds[${index}]`}
                                    label="Email Id"
                                    fullWidth
                                />
                                {emailIds.length > 1 && (
                                    <IconButton
                                        onClick={() => {
                                            const updatedEmailIds = emailIds.filter(
                                                (_, emailIndex) => emailIndex !== index
                                            );
                                            handlePlayerCountChange(updatedEmailIds.length);
                                        }}>
                                        <Delete />
                                    </IconButton>
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <PrimaryButton
                    onClick={() => handleSubmit()}
                    disabled={submitFormLoading}
                    startIcon={submitFormLoading && <CircularProgress size="20px" />}
                    fullWidth>
                    Submit
                </PrimaryButton>
            </Grid>
        </Grid>
    );
};

export default PublicGameForm;

import React from "react";
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType, Spinner } from "@fluentui/react";
import { usageListType } from "../../data/question/types";
import { extractHtmlContent } from "../../utils/JambarUtils";

interface IDeleteConfirmationDialogProps {
    isShown: boolean;
    entryName: string;
    onDismiss: () => void;
    onConfirm: () => void;
    usageList?: usageListType[];
    loading?: boolean;
    usedBy?: string;
    hideButton?: boolean;
}

const DeleteConfirmationDialog: React.FC<IDeleteConfirmationDialogProps> = ({
    isShown,
    entryName,
    onDismiss,
    onConfirm,
    usageList,
    loading,
    usedBy,
    hideButton
}) => {
    const showUsageList = usageList?.length !== 0;
    const dialogContentProps = {
        type: DialogType.normal,
        title: "Confirm delete",
        closeButtonAriaLabel: "Close",
        subText: showUsageList && usageList ? "" : `Are you sure you want to delete this ${entryName} ?`
    };

    return (
        <Dialog hidden={!isShown} onDismiss={onDismiss} dialogContentProps={dialogContentProps}>
            {loading ? (
                <div>
                    <Spinner label="Fetching usage list" />
                </div>
            ) : (
                <div>
                    {showUsageList && usageList && (
                        <div className="margin-bottom-10">
                            This {entryName} is being used in following {usedBy}
                            {!hideButton ? (
                                <span>
                                    ,<br /> do you want to continue to delete the {entryName}?
                                </span>
                            ) : (
                                <span>
                                    , please update those {usedBy} before deleting the {entryName}
                                </span>
                            )}
                        </div>
                    )}
                    {usageList &&
                        usageList.map((item) => <li className="margin-bottom-5">{extractHtmlContent(item.name)}</li>)}
                </div>
            )}

            <DialogFooter>
                {!hideButton && <PrimaryButton onClick={onConfirm} text="Confirm" />}
                <DefaultButton onClick={onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
};

export default DeleteConfirmationDialog;

import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { FC } from "react";
import { PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import FormikSelect from "../../../../CommonComponent/v2/fields/FormikSelect";
import FormikTextInput from "../../../../CommonComponent/v2/fields/FormikTextInput";
import LoadingWrapper from "../../../../CommonComponent/v2/LoadingWrapper";
import { useUserDetailHook } from "./Hooks";
import { getPermissions } from "../../../../../utils/PermissionUtils";
import { useAdminContext } from "../../../context/AdminAuthContext";

type UserDetailFormProps = {
    selectedUserId: number;
};
const UserDetailForm: FC<UserDetailFormProps> = ({ selectedUserId }) => {
    const { setFieldValue, handleSubmit, setValues } = useFormikContext();
    const { identityDropdown, loading } = useUserDetailHook(setFieldValue, setValues, selectedUserId);
    const { userDetail } = useAdminContext();
    const permissionUser: string[] | null = getPermissions(userDetail, "user");

    return (
        <LoadingWrapper isLoading={loading}>
            <Grid container spacing={"15px"} mt={"30px"}>
                <Grid item xs={12}>
                    <FormikTextInput id={"firstName"} name={"firstName"} label={"First name"} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <FormikTextInput id={"lastName"} name={"lastName"} label={"Last name"} fullWidth />
                </Grid>
                {userDetail.identity.name === "jambar" && (
                    <Grid item xs={12}>
                        <FormikSelect
                            options={identityDropdown}
                            id={"identityId"}
                            name={"identityId"}
                            label={"Identity"}
                            disabled={selectedUserId !== -1}
                            fullWidth
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <FormikTextInput
                        id={"email"}
                        name={"email"}
                        label={"Email"}
                        disabled={selectedUserId !== -1}
                        fullWidth
                    />
                </Grid>
                {permissionUser && permissionUser.includes("update") && (
                    <Grid item xs={12}>
                        <Grid container justifyContent={"center"}>
                            <PrimaryButton
                                fontsize="16px"
                                style={{
                                    height: 50,
                                    width: 182,
                                    padding: 16
                                }}
                                onClick={() => handleSubmit()}>
                                Save User
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </LoadingWrapper>
    );
};

export default UserDetailForm;

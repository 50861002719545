import moment, { Moment } from "moment";
import { date, number, object } from "yup";

export type BulkCodeType = {
    numberOfCode: number;
    endDate: Moment;
    generatedBy: string;
    startDate: Moment;
    notes: string;
};

export type BulkTransformCodeType = {
    endDate: string;
    generatedBy: string;
    startDate: string;
    notes: string;
    participantCode: string;
};

export const BulkCodeInitialValue: BulkCodeType = {
    numberOfCode: 5,
    endDate: moment().add(7, "days"),
    generatedBy: "",
    startDate: moment(),
    notes: ""
};

export const bulkEventCodeValidation = object().shape({
    numberOfCode: number().typeError("Please enter number").required("Enter number of code"),
    startDate: date().typeError("Please select a valid date").required("From date is mandatory"),
    endDate: date()
        .when(
            "startDate",
            (startDate: Date, schema: any) =>
                startDate && schema.min(startDate, "To date should be greater than From date")
        )
        .typeError("Please select a valid date")
        .required("To date is mandatory")
});

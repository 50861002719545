import { toast } from "react-toastify";
import { object, string } from "yup";
import { ApiConstants } from "../../../../../../../constants/ApiConstant";
import { SiteConstants } from "../../../../../../../constants/SiteConstant";
import { Client } from "../../../../../../Base/Client";

export type UpdateAttachmentType = {
    attachmentTitle: string;
    attachmentFile: File | string | null;
};

export const UpdateAttachmentInitialValue = {
    attachmentTitle: "",
    attachmentFile: null
};

export const UpdateAttachmentValidationSchema = object().shape({
    attachmentTitle: string().required("Please specify attachment title")
});

const reverseTransformData = (data: UpdateAttachmentType): any => {
    const attachmentData = new FormData();
    attachmentData.append("title", data.attachmentTitle);
    typeof data.attachmentFile !== "string" && attachmentData.append("quizAttachment", data.attachmentFile as Blob);
    return attachmentData;
};

export const addNewAttachment = (gameId: string, data: UpdateAttachmentType) => {
    return Client.getInstance()
        .createData(ApiConstants.addQuizAttachmentApiUrl(parseInt(gameId)), reverseTransformData(data))
        .then(() => {
            return Promise.resolve();
        })
        .catch(() => {
            return Promise.reject();
        });
};

export const updateAttachmentData = (gameId: string, attachmentId: string, data: UpdateAttachmentType) => {
    return Client.getInstance()
        .updateData(
            ApiConstants.updateQuizAttachmentApiUrl(parseInt(gameId), parseInt(attachmentId)),
            reverseTransformData(data)
        )
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));
};

const transformData = (data: any): UpdateAttachmentType => {
    return {
        attachmentFile: data.fileLocation,
        attachmentTitle: data.title
    };
};

export const getAttachmentData = (quizId: string, attachmentId: string) => {
    return Client.getInstance()
        .getData(ApiConstants.getQuizAttachmentDetailsApiUrl(parseInt(quizId), parseInt(attachmentId)), true)
        .then((response) => Promise.resolve(transformData(response.data.data)))
        .catch((err) => {
            toast.error(err, SiteConstants.deleteToastConfiguration);
            return Promise.reject(err);
        });
};

import { Button, Grid } from "@mui/material";
import { useState } from "react";
import TextFieldWrapper from "../CommonComponent/TextFieldWrapper";

const LiveLocationView = () => {
    const [word, setWord] = useState("");

    const setAddress = () => {
        localStorage.setItem("3wordsAddress", word);
    };
    return (
        <Grid container justifyContent="center">
            <Grid height={"70vh"} item xs={12}>
                <iframe src={`https://w3w.co/`} height={"100%"} width={"100%"} />
            </Grid>
            <Grid item xs={8}>
                <TextFieldWrapper
                    value={word}
                    onTextFieldValueChange={(value) => setWord(value)}
                    label={"Enter 3 world word"}
                    placeholder={"Difference block by comma"}
                />
                <Button onClick={setAddress}>Submit</Button>
            </Grid>
        </Grid>
    );
};

export default LiveLocationView;

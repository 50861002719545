import { FC } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { pointsBarRedColor, pointsBarOrangeColor, pointsBarGreeneColor } from "../../../../constants/Styles";
import { getPointsBarClass } from "./utils/helperFunctions";
import { createStyles, makeStyles, withStyles } from "@mui/styles";

const BorderLinearProgress: any = withStyles(() =>
    createStyles({
        root: {
            height: 8,
            borderRadius: 5
        },
        bar: {
            borderRadius: 5
        }
    })
)(LinearProgress);

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    redBackground: {
        backgroundColor: pointsBarRedColor
    },
    orangeBackground: {
        backgroundColor: pointsBarOrangeColor
    },
    greenBackground: {
        backgroundColor: pointsBarGreeneColor
    }
});

type surveyPointsBarProps = {
    points: number;
};

const SurveyPointsBar: FC<surveyPointsBarProps> = ({ points }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <BorderLinearProgress
                variant="determinate"
                classes={{
                    bar: getPointsBarClass(classes, points)
                }}
                value={points}
            />
        </div>
    );
};

export default SurveyPointsBar;

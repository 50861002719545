import { Grid } from "@mui/material";
import { FC, useState } from "react";
import DataGridWrapper from "../../../CommonComponent/v2/datagrid/DataGrid";
import { useQuestionListHook } from "./Hooks";
import { Question, QuestionDeleteType, QuestionCloneType, Info } from "./Type";
import { useHistory } from "react-router";
import UpdateQuestionModal from "./updatequestion/UpdateQuestionModal";
import { EditGameType } from "../gamelibrary/updategame/updategameform/Type";
import { questionGameType } from "./Utils";

type QuestionListProps = {
    questionList: Question[];
    isLoading: boolean;
    setCloneId: (id: QuestionCloneType) => void;
    setDeleteId: (id: QuestionDeleteType) => void;
    setViewId: (id: number) => void;
    setRestoreId: (info: Info) => void;
    refreshList: () => void;
    selectedStatus: string;
    setSelectedRows: (rows: Question[]) => void;
    selectedRows: Question[];
    hideCheckbox: boolean;
};
const QuestionList: FC<QuestionListProps> = ({
    questionList,
    isLoading,
    setCloneId,
    setDeleteId,
    setViewId,
    refreshList,
    setRestoreId,
    selectedStatus,
    selectedRows,
    setSelectedRows,
    hideCheckbox
}) => {
    const { questionListColumns, isSuperAdmin } = useQuestionListHook(selectedStatus);
    const history = useHistory();
    const searchParams = new URLSearchParams(window.location.search);
    const [selectedPage, setSelectedPage] = useState(parseInt(searchParams.get("pageNo") || "0"));
    const [showEditModal, setShowModal] = useState<EditGameType | null>(null);

    return (
        <Grid height={hideCheckbox ? "calc(100vh - 200px)" : "calc(100vh - 260px)"}>
            <DataGridWrapper
                page={selectedPage}
                checkboxSelection={!hideCheckbox}
                selectionModel={selectedRows.map((item) => item.id)}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = questionList.filter((row) => selectedIDs.has(row.id));
                    setSelectedRows(selectedRows);
                }}
                disableSelectionOnClick
                onPageChange={(page) => {
                    history.push(`/admin/v2/questionlibrary?pageNo=${page}`);
                    setSelectedPage(page);
                }}
                rowsPerPageOptions={[100]}
                hideFooter={false}
                pageSize={100}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            identityName: isSuperAdmin
                        }
                    }
                }}
                columns={questionListColumns(
                    setCloneId,
                    setDeleteId,
                    setViewId,
                    (gameId: string, id: number) => {
                        setShowModal({
                            gameId: questionGameType[gameId],
                            questionId: id.toString()
                        });
                    },
                    (id: number) => {
                        history.push(`/admin/v2/questionlibrary/${id}/questioninfo?pageNo=${selectedPage}`);
                    },
                    setRestoreId
                )}
                rows={questionList as any}
                loading={isLoading}
            />
            {!!showEditModal && (
                <UpdateQuestionModal
                    handleClose={() => setShowModal(null)}
                    open={!!showEditModal}
                    refreshList={refreshList}
                    selectedQuestionInfo={showEditModal}
                />
            )}
        </Grid>
    );
};
export default QuestionList;

import { Grid } from "@mui/material";
import ConfirmationBox from "../../../../../CommonComponent/v2/ConfirmationBox";
import DataGridWrapper from "../../../../../CommonComponent/v2/datagrid/DataGrid";
import LoadingWrapper from "../../../../../CommonComponent/v2/LoadingWrapper";
import SearchHeader from "../../../../../CommonComponent/v2/searchheader/SearchHeader";
import AddNewCode from "./addcode";
import EditCode from "./editcode";
import { useEventCodeHook } from "./Hooks";
import { filterCodeBySearchText } from "./Util";

const EventCode = () => {
    const {
        eventCodeList,
        codeEdit,
        setCodeEdit,
        onConfirmDelete,
        setCodeDeleteModal,
        codeDeleteModal,
        isLoading,
        fetchData,
        eventCodeListColumns,
        searchText,
        setSearchText,
        addNewCodeModal,
        toggleAddNewCodeModal
    } = useEventCodeHook();

    return (
        <Grid>
            <Grid mb={"20px"} container>
                <Grid item xs={12}>
                    <SearchHeader
                        searchText={searchText}
                        setSearchText={setSearchText}
                        showAddButton
                        searchPlaceholder={"Search code"}
                        onButtonClick={toggleAddNewCodeModal}
                        buttonLabel={"Add new Code"}
                    />
                </Grid>
            </Grid>
            <Grid height={"calc(100vh - 350px)"}>
                <LoadingWrapper
                    isLoading={isLoading}
                    loadingHeight="300px"
                    isNoItem={eventCodeList.length === 0}
                    emptyTileLabel={`No codes found to start the event `}
                    emptyTileSubLabel="Try adding a new code">
                    <DataGridWrapper
                        columns={eventCodeListColumns()}
                        rows={filterCodeBySearchText(eventCodeList, searchText)}
                        loading={isLoading}
                    />
                </LoadingWrapper>
                {codeDeleteModal && (
                    <ConfirmationBox
                        title={`Remove "${codeDeleteModal.code}" Code`}
                        message={"Please confirm if you really want to remove this code"}
                        onConfirm={onConfirmDelete}
                        onClose={() => setCodeDeleteModal(null)}
                    />
                )}
            </Grid>
            {addNewCodeModal && (
                <AddNewCode
                    eventCodeList={eventCodeList.map((item) => item.code)}
                    handleClose={toggleAddNewCodeModal}
                    refreshList={() => {
                        toggleAddNewCodeModal();
                        fetchData();
                    }}
                />
            )}
            {codeEdit && (
                <EditCode
                    codeInfo={codeEdit}
                    refreshList={() => {
                        setCodeEdit(null);
                        fetchData();
                    }}
                    eventCodeList={eventCodeList.map((item) => item.code)}
                    handleClose={() => setCodeEdit(null)}
                />
            )}
        </Grid>
    );
};

export default EventCode;

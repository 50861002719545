import { ApiConstants } from "../../../constants/ApiConstant";
import { JambarDateUtil } from "../../../constants/JambarDateUtils";
import { extractHtmlContent } from "../../../utils/JambarUtils";
import { Client } from "../../Base/Client";
import { EventListItem } from "../EventManager/EventListDataTypes";

export const transformData = (event: any): EventListItem => {
    return {
        id: event.id,
        eventName: event.name,
        createdBy: `${event.createdByUser.firstName} ${event.createdByUser.lastName}`,
        startDate: JambarDateUtil.dateFormat(event.eventStartDate, "DD/MM/YYYY"),
        endDate: JambarDateUtil.dateFormat(event.eventEndDate, "DD/MM/YYYY"),
        gameTitle: extractHtmlContent(event.quiz.title),
        createdOn: JambarDateUtil.formatDate(event.createdAt),
        noOfTeams: event.teamCount,
        game: event.quizId,
        updatedBy: `${event.updatedByUser.firstName} ${event.updatedByUser.lastName}`,
        updatedOn: JambarDateUtil.formatDate(event.updatedAt),
        isClosed: event.isClosed
    };
};

export const fetchLeaderBoardList = () => Client.getInstance()
    .getData(ApiConstants.getEventListApiUrl(), true)
    .then((response: any) => {
        return Promise.resolve(response.data.data.map((event: any) => transformData(event)));
    }).catch(() => {
        return [];
    });

import React from "react";
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType, Spinner } from "@fluentui/react";
import { bulkQuestionUsageListType } from "../../data/question/types";
import { extractHtmlContent } from "../../utils/JambarUtils";

interface IBatchDeleteConfirmationDialogProps {
    isShown: boolean;
    entryName: string;
    onDismiss: () => void;
    onConfirm: () => void;
    usageList?: bulkQuestionUsageListType[];
    loading?: boolean;
    usedBy?: string;
    hideButton?: boolean;
}

const BatchDeleteConfirmationDialog: React.FC<IBatchDeleteConfirmationDialogProps> = ({
    isShown,
    entryName,
    onDismiss,
    onConfirm,
    usageList,
    loading,
    usedBy,
    hideButton
}) => {
    const showUsageList = usageList?.length !== 0;
    const dialogContentProps = {
        type: DialogType.normal,
        title: "Confirm delete",
        closeButtonAriaLabel: "Close",
        subText: showUsageList && usageList ? "" : `Are you sure you want to delete these ${entryName}?`
    };

    return (
        <Dialog hidden={!isShown} onDismiss={onDismiss} dialogContentProps={dialogContentProps}>
            {loading ? (
                <div>
                    <Spinner label="Fetching usage list" />
                </div>
            ) : (
                <div>
                    {showUsageList && usageList && (
                        <div className="margin-bottom-10">
                            {usageList.map((item) => (
                                <div key={item.questionId} className="margin-bottom-10">
                                    <b>{item.name}</b> is being used in following {usedBy}:
                                    {item.quiz &&
                                        item.quiz.map((item) => (
                                            <li key={item.id} className="margin-bottom-5">
                                                {extractHtmlContent(item.name)}
                                            </li>
                                        ))}
                                    <hr />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}

            <DialogFooter>
                {!loading && hideButton && <PrimaryButton onClick={onConfirm} text="Confirm" />}
                <DefaultButton onClick={onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
};

export default BatchDeleteConfirmationDialog;

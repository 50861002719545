import { Step, StepLabel, Stepper } from "@mui/material";
import { FC } from "react";
import { CaptionText, StyledVerticalStepper } from "../CommonStyle";
import { StepperContainer } from "./Style";
import { StepperType } from "./Type";

type VerticalStepperProps = {
    steps: StepperType[];
    activeStep: number;
};
const VerticalStepper: FC<VerticalStepperProps> = ({ activeStep, steps }) => {
    return (
        <StyledVerticalStepper>
            <StepperContainer sx={{ maxWidth: 400, height: 500 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step) => (
                        <Step key={step.label}>
                            <StepLabel>
                                <CaptionText color={"#333333"}>{step.label}</CaptionText>
                                <CaptionText>{step.description}</CaptionText>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </StepperContainer>
        </StyledVerticalStepper>
    );
};

export default VerticalStepper;

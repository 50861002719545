export enum STATUS_INFO {
    success = "Success",
    failed = "Failed",
    initiated = "Initiated"
}

export type PaymentListType = {
    amount: string;
    createdAt: string;
    currency: string;
    id: number;
    status: STATUS_INFO;
    userName: string;
};

import { useState } from "react";
import { UserDetailsType } from "./Type";
export const useProfile = () => {
    const [selectedTab, setSelectedTab] = useState("profile");
    const [showDropDown, setShowDropDown] = useState(false);
    const [profileDetails, setProfileDetails] = useState<UserDetailsType | undefined>();
    const saveData = (values: UserDetailsType, setIsSubmitting: (value: boolean) => void) => {
        setIsSubmitting(true);
        setProfileDetails(values);
    };
    return {
        selectedTab,
        showDropDown,
        profileDetails,
        setShowDropDown,
        setSelectedTab,
        setProfileDetails,
        saveData
    };
};
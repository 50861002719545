import { ApiConstants } from "../../../../../../constants/ApiConstant";
import { Client } from "../../../../../Base/Client";

export const eventDetailsTab = [
    {
        id: 1,
        value: "about",
        label: "About"
    },
    {
        id: 2,
        value: "refGame",
        label: "Reference games"
    },
    {
        id: 3,
        value: "eventLinks",
        label: "Event links"
    }
];

export const closeEventStatus = (eventId: string) =>
    Client.getInstance()
        .updateData(ApiConstants.closeEventApiUrl(parseInt(eventId)), {})
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const cloneSelectedEvent = (eventId: string) =>
    Client.getInstance()
        .createData(ApiConstants.cloneEventApiUrl(parseInt(eventId)), {})
        .then((response) => Promise.resolve(response.data.data.id))
        .catch((error) => Promise.reject(error));

import moment from "moment";

export class JambarDateUtil {
    public static formatDate = (date: string): string => {
        const formatedDate = new Date(date);
        const month = formatedDate.getMonth() + 1;
        return `${formatedDate.getDate()}/${month < 10 ? "0" + month : month}/${formatedDate.getFullYear()}`;
    };

    public static dateFormat = (date: Date, format: string): string => {
        return moment(date).format(format);
    };

    public static returnNDateFromToday = (noOfDays: number, format: string): string => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + noOfDays);
        return moment(currentDate).format(format);
    };
}

import { Grid } from "@mui/material";
import { FC } from "react";
import { CaptionText } from "../../../../CommonComponent/v2/CommonStyle";
import DataGridWrapper from "../../../../CommonComponent/v2/datagrid/DataGrid";
import SearchHeader from "../../../../CommonComponent/v2/searchheader/SearchHeader";
import { useGameQuestionHook } from "./Hooks";
import { GameInfoQuestionType } from "./Type";
import { getFilteredQuestionList } from "./Util";

type GameQuestionsProps = {
    gameQuestions: GameInfoQuestionType[];
};
const GameQuestions: FC<GameQuestionsProps> = ({ gameQuestions }) => {
    const { columns, searchText, setSearchText } = useGameQuestionHook();
    return (
        <Grid>
            <Grid container spacing={"5px"} alignItems={"center"}>
                <Grid item xs={1}>
                    <CaptionText>Total questions: {gameQuestions.length}</CaptionText>
                </Grid>
                <Grid item xs={8}>
                    <SearchHeader searchText={searchText} setSearchText={setSearchText} />
                </Grid>
            </Grid>
            <Grid my={"30px"}>
                <DataGridWrapper
                    autoHeight
                    columns={columns}
                    rows={getFilteredQuestionList(gameQuestions, searchText)}
                />
            </Grid>
        </Grid>
    );
};

export default GameQuestions;

import { AdminUserType } from "../context/Type";

export const transformLoginData = (data: any): AdminUserType => {
    return {
        currentRoles: data.currentRoles,
        email: data.email,
        firstName: data.firstName,
        id: data.id,
        identity: {
            credits: data.identity.credits,
            id: data.identity.id,
            name: data.identity.name
        },
        lastName: data.lastName,
        permissions: data.permissions,
        serverDateTime: data.serverDateTime
    };
};

import { CircularProgress, Grid } from "@mui/material";
import { StyledBackground } from "./Style";
import JambarLogo from "../../Images/logo.svg";
import { useVerifyUser } from "./hook";

import { Header2, PrimaryButton } from "../../CommonComponent/v2/CommonStyle";

const VerifyUser = () => {
    const { loading, error, history } = useVerifyUser();

    const renderView = () => {
        if (!error) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Header2 textAlign={"center"} mt={"30px"}>
                            Your email address has already been verified.
                        </Header2>
                    </Grid>
                    <Grid item xs={12} mt={"20px"}>
                        <PrimaryButton
                            startIcon={loading && <CircularProgress size={"20px"} />}
                            disabled={loading}
                            onClick={() => history.push("/admin/login")}
                            fullWidth>
                            Go to login page
                        </PrimaryButton>
                    </Grid>
                </Grid>
            );
        }
        return (
            <Grid item xs={12} mt={"30px"}>
                <Grid container>
                    <Grid item xs={12}>
                        <Header2 textAlign={"center"} mt={"30px"}>
                            Your email address is been verifying
                        </Header2>
                    </Grid>
                    <Grid item xs={12} mt={"20px"}>
                        <PrimaryButton
                            startIcon={loading && <CircularProgress size={"20px"} />}
                            disabled={loading}
                            onClick={() => history.push("/admin/login")}
                            fullWidth>
                            Verifying
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    return (
        <StyledBackground container alignItems={"center"} justifyContent={"center"}>
            <Grid p={"30px"} borderRadius={"5px"} minHeight={"50vh"} bgcolor={"#fff"} item xs={11} md={4}>
                <Grid container>
                    <Grid display={"flex"} justifyContent={"center"} alignItems={"center"} item xs={12}>
                        <img width={"300px"} src={JambarLogo} alt={"jambar"} loading="lazy" />
                    </Grid>
                    {renderView()}
                </Grid>
            </Grid>
        </StyledBackground>
    );
};

export default VerifyUser;

import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { LeaderboardColumns } from "./Types";

type SettingsModalProps = {
    modalShow: boolean;
    setModalShow: (show: boolean) => void;
    setShowColumns: (obj: LeaderboardColumns) => void;
    showColumns: LeaderboardColumns;
};

const SettingsModal: React.FC<SettingsModalProps> = ({ modalShow, setModalShow, setShowColumns, showColumns }) => {
    return (
        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modalWidth">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Customize Columns</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6>Choose What To View</h6>
                <Form>
                    <div className="mb-3 flexColumn">
                        <Form.Check
                            inline
                            label="Position"
                            checked={showColumns.position}
                            onChange={() => setShowColumns({ ...showColumns, position: !showColumns.position })}
                            name="Position"
                            type="checkbox"
                            id="checkbox1"
                        />
                        <Form.Check
                            inline
                            label="Name"
                            checked={showColumns.name}
                            onChange={() => setShowColumns({ ...showColumns, name: !showColumns.name })}
                            name="Name"
                            type="checkbox"
                            id="checkbox2"
                        />
                        <Form.Check
                            inline
                            label="Score"
                            checked={showColumns.score}
                            onChange={() => setShowColumns({ ...showColumns, score: !showColumns.score })}
                            name="Score"
                            type="checkbox"
                            id="checkbox3"
                        />
                        <Form.Check
                            inline
                            label="Hints"
                            checked={showColumns.hints}
                            onChange={() => setShowColumns({ ...showColumns, hints: !showColumns.hints })}
                            name="Hints"
                            type="checkbox"
                            id="checkbox4"
                        />
                        <Form.Check
                            inline
                            label="Time Taken"
                            checked={showColumns.timeTaken}
                            onChange={() => setShowColumns({ ...showColumns, timeTaken: !showColumns.timeTaken })}
                            name="Time"
                            type="checkbox"
                            id="checkbox5"
                        />
                        <Form.Check
                            inline
                            label="Time Left"
                            checked={showColumns.timeLeft}
                            onChange={() => setShowColumns({ ...showColumns, timeLeft: !showColumns.timeLeft })}
                            name="TimeLeft"
                            type="checkbox"
                            id="checkbox5"
                        />
                        <Form.Check
                            inline
                            label="Time Left In Connector"
                            checked={showColumns.timeLeftInConnector}
                            onChange={() =>
                                setShowColumns({
                                    ...showColumns,
                                    timeLeftInConnector: !showColumns.timeLeftInConnector
                                })
                            }
                            name="TimeLeftInConnector"
                            type="checkbox"
                            id="checkbox5"
                        />
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setModalShow(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SettingsModal;

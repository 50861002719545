import { Row } from "react-bootstrap";
import Style from "@emotion/styled";
import backgroundImage from "../../Images/eventBackground.jpg";

export const InitialSetupContainer = Style.div`
    background-image: url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    overflow-y: auto;
`;

export const SetupWizardParent = Style(Row)`
    text-align: center;
    background: #ffff;
    box-shadow: 1px 1px 10px black;
    margin-top: 50px;
    padding-top: 30px;
    width: 80%;
    border-radius: 10px;
    padding-bottom: 30px;
    justify-content: center;
`;

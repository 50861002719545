/* eslint-disable jsx-a11y/alt-text */
import { Grid } from "@mui/material";
import { Formik } from "formik";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { Header1 } from "../../../../CommonComponent/v2/CommonStyle";
import VerticalStepper from "../../../../CommonComponent/v2/stepper/VerticalStepper";
import ArrowLeft from "../../../../Images/icons/arrowLeft.svg";
import DecideGame from "./DecideGame";
import EventCreatedSuccessfulModal from "./EventCreatedSuccessfulModal";
import { useAddEventDetailHook } from "./Hooks";
import LaunchEvent from "./LaunchEvent";
import ProvideDetails from "./ProvideDetails";
import { EventType, NewEventInfoType } from "./Type";
import { addEventSteps, eventDetailInitialValues, eventValidationSchema, getAddNewEvent } from "./Util";

const AddEventDetails = () => {
    const {
        activeStep,
        setActiveStep,
        eventCreated,
        setEventCreated,
        permissionQuizFullGame,
        permissionQuizPhysicalGame,
        permissionQuizSocketGame
    } = useAddEventDetailHook();
    const history = useHistory();

    const renderView = () => {
        if (activeStep === 0) {
            return (
                <DecideGame
                    backButtonClick={() => history.push("/admin/event")}
                    nextButtonClick={() => setActiveStep(activeStep + 1)}
                />
            );
        }
        if (activeStep === 1) {
            return (
                <ProvideDetails
                    backButtonClick={() => setActiveStep(activeStep - 1)}
                    nextButtonClick={() => setActiveStep(activeStep + 1)}
                />
            );
        }
        if (activeStep === 2) {
            return <LaunchEvent backButtonClick={() => setActiveStep(activeStep - 1)} prevLinkName={""} />;
        }

        return null;
    };

    const saveData = (values: EventType, setIsSubmitting: (value: boolean) => void) => {
        setIsSubmitting(true);
        getAddNewEvent(values)
            .then((res: NewEventInfoType) => {
                setEventCreated({
                    eventLink: res.eventLink,
                    type: res.type,
                    id: res.id
                });
            })
            .catch((err) => {
                toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => setIsSubmitting(false));
    };

    return (
        <Grid px={"20px"}>
            <Grid container>
                <Grid item xs={12}>
                    <Header1>
                        <img
                            onClick={() => history.push("/admin/event")}
                            src={ArrowLeft}
                            style={{ height: 18, marginRight: 10, cursor: "pointer" }}
                        />
                        Add new event
                    </Header1>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={3}>
                    <VerticalStepper activeStep={activeStep} steps={addEventSteps} />
                </Grid>
                <Grid item xs={7}>
                    <Formik
                        initialValues={eventDetailInitialValues({
                            permissions: {
                                permissionQuizFullGame,
                                permissionQuizPhysicalGame,
                                permissionQuizSocketGame
                            }
                        })}
                        validationSchema={eventValidationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            saveData(values, setSubmitting);
                        }}
                        enableReinitialize
                        validateOnMount>
                        {() => renderView()}
                    </Formik>
                </Grid>
            </Grid>
            {eventCreated && <EventCreatedSuccessfulModal newEventInfo={eventCreated} />}
        </Grid>
    );
};

export default AddEventDetails;

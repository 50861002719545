import { Grid } from "@mui/material";
import SearchHeader from "../../../../../CommonComponent/v2/searchheader/SearchHeader";
import ConfirmationBox from "../../../../../CommonComponent/v2/ConfirmationBox";
import { useHistory, useParams } from "react-router";
import { useAttachments } from "./Hooks";
import ReferenceAttachment from "./Attachments";
import { filterDataBySearchText } from "./Utils";
const Attachments = () => {
    const history = useHistory();
    const { id } = useParams<{
        id: string;
    }>();

    const {
        searchText,
        isLoading,
        attachmentList,
        onAttachmentDelete,
        selectedAttachmentDeleteId,
        setSearchText,
        setSelectedAttachmentDeleteId,
        onAttachmentDeleteClose,
    } = useAttachments();
    return (
        <Grid container mt={"30px"} spacing={"20px"}>
            <Grid item xs={12}>
                <SearchHeader
                    searchText={searchText}
                    setSearchText={setSearchText}
                    showAddButton
                    onButtonClick={() => history.push(`/admin/v2/referencelistmanager/${id}/update/attachments/addAttachment`)}
                    buttonLabel={"Add new "}
                />
            </Grid>
            <Grid mt={"30px"} item xs={12}>
                <ReferenceAttachment
                    referenceAttachments={filterDataBySearchText(searchText, attachmentList)}
                    setItemDeleteId={setSelectedAttachmentDeleteId}
                    isLoading={isLoading}
                />
            </Grid>
            {selectedAttachmentDeleteId && (
                <ConfirmationBox
                    title={`Delete "${selectedAttachmentDeleteId.name}" attachment`}
                    message={"Please confirm if you really want to delete this attachment"}
                    onConfirm={onAttachmentDelete}
                    onClose={onAttachmentDeleteClose}
                />
            )}
        </Grid>
    );
};
export default Attachments;
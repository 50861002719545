/* eslint-disable react-hooks/exhaustive-deps */
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { LeaderBoardPollingInterval, SiteConstants } from "../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../utils/APIErrorMessages";
import { StyledActionColumn, StyledColumn, StyledHeader } from "../../../CommonComponent/v2/datagrid/DataGridStyle";
import { LeaderBoardDetailsType, LeaderBoardEventListItem, QuestionInfoTooltipType } from "./Type";
import {
    fetchClosedEventLeaderBoardData,
    fetchClosedEventsData,
    fetchCurrentQuestion,
    fetchLeaderBoardData,
    fetchLiveEvent,
    getEventUrl,
    getStatusColor
} from "./Util";
import FirstIcon from "../../../Images/icons/first.svg";
import SecondIcon from "../../../Images/icons/second.svg";
import ThirdIcon from "../../../Images/icons/third.svg";
import { CaptionText, StyledTag } from "../../../CommonComponent/v2/CommonStyle";
import { orderBy } from "lodash";
import { Box, IconButton } from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";

export const useLeaderboardListHook = (eventId: string, isEventLive: boolean, hideCurrentQuestion?: boolean) => {
    const [leaderBoardlist, setLeaderBoardlist] = useState<LeaderBoardDetailsType[]>([]);
    const [showManageColumnDialog, setShowManageColumnDialog] = useState(false);
    const [filteredColumns, setFilteredColumns] = useState<GridColDef[]>([]);
    const [includeColumns, setIncludeColumns] = useState<string[]>([]);
    const [isContentLoading, setIsContentLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [showQuestionDialog, setShowQuestionDialog] = useState<string | null>(null);

    let interval: NodeJS.Timeout;

    useEffect(() => {
        getInitializeLeaderBoardColumns(handleQuestionSelect);
        setIsContentLoading(true);
    }, []);

    const handleQuestionSelect = (code: string) => {
        setShowQuestionDialog(code);
    };

    const handleCloseQuestionModal = () => {
        setShowQuestionDialog(null);
    };

    useEffect(() => {
        if (!isEventLive) {
            getOldLeaderboardData();
            clearInterval(interval);
            return;
        }

        interval = setInterval(() => getLiveLeaderboardData(), LeaderBoardPollingInterval);
        return () => clearInterval(interval);
    }, [isEventLive]);

    const getImagePosition = (position: number) => {
        if (position === 1) {
            return <img height={"30px"} width={"30px"} src={FirstIcon} alt={"first_icon"} />;
        }
        if (position === 2) {
            return <img height={"30px"} width={"30px"} src={SecondIcon} alt={"second_icon"} />;
        }
        if (position === 3) {
            return <img height={"30px"} width={"30px"} src={ThirdIcon} alt={"third_icon"} />;
        }
        return <StyledColumn label={`${position}`} />;
    };
    const getOldLeaderboardData = () => {
        fetchClosedEventLeaderBoardData(eventId)
            .then((response) => {
                setLeaderBoardlist(response);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setIsContentLoading(false);
            });
    };

    const getLiveLeaderboardData = () => {
        fetchLeaderBoardData(eventId, true)
            .then((res) => {
                setLeaderBoardlist(res);
            })
            .catch((error) => {
                toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            })
            .finally(() => {
                setIsContentLoading(false);
            });
    };

    const showCurentQuestion = (showQuestion: (code: string) => void): GridColDef => {
        if (!hideCurrentQuestion) {
            return {
                field: "currentQuestion",
                minWidth: 100,
                sortable: false,
                renderHeader: () => <StyledHeader label="Current Question" />,
                renderCell: (params: GridCellParams) => (
                    <IconButton onClick={() => showQuestion(params.row.participantCode)}>
                        <FaInfoCircle size={"16px"} />
                    </IconButton>
                )
            };
        }
        return {
            field: "",
            width: 0
        };
    };

    const initializeLeaderBoardColumns = (showQuestion: (code: string) => void): GridColDef[] => {
        return [
            {
                field: "position",
                minWidth: 100,
                renderHeader: () => <StyledHeader label="Position" />,
                renderCell: (params: GridCellParams) => (
                    <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                        {getImagePosition(params.row.position)}
                    </Box>
                )
            },
            {
                field: "teamName",
                minWidth: 200,
                renderHeader: () => <StyledHeader label="Team Name" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.teamName} />
            },
            {
                field: "participantCode",
                minWidth: 200,
                renderHeader: () => <StyledHeader label="Participant Code" />,
                renderCell: (params: GridCellParams) => (
                    <StyledTag>
                        <CaptionText fontWeight={500} color={"#1F71B6"}>
                            {params.row.participantCode}
                        </CaptionText>
                    </StyledTag>
                )
            },
            {
                field: "status",
                minWidth: 100,
                renderHeader: () => <StyledHeader label="Status" />,
                renderCell: (params: GridCellParams) => (
                    <StyledColumn color={getStatusColor(params.row.status)} label={params.row.status} />
                )
            },
            {
                field: "startTime",
                minWidth: 100,
                renderHeader: () => <StyledHeader label="Start Time" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.startTime} />
            },
            {
                field: "score",
                minWidth: 80,
                renderHeader: () => <StyledHeader label="Score" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.score} />
            },
            {
                field: "questionAnsweredCount",
                minWidth: 80,
                renderHeader: () => <StyledHeader label="Q.answered" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.questionAnsweredCount} />
            },
            {
                field: "correctAnswersCount",
                minWidth: 80,
                renderHeader: () => <StyledHeader label="C.answers" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.correctAnswersCount} />
            },
            {
                field: "wrongAnswersCount",
                minWidth: 80,
                renderHeader: () => <StyledHeader label="W.answers" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.wrongAnswersCount} />
            },
            {
                field: "hintsUsedCount",
                minWidth: 80,
                renderHeader: () => <StyledHeader label="Hints Used" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.hintsUsedCount} />
            },
            {
                field: "timeTakenInQuiz",
                minWidth: 100,
                renderHeader: () => <StyledHeader label="Time Taken In Quiz" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.timeTakenInQuiz} />
            },
            {
                field: "timeLeftInConnector",
                minWidth: 100,
                renderHeader: () => <StyledHeader label="Time Left In Connector" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.timeLeftInConnector} />
            },
            {
                field: "timeLeftInQuiz",
                minWidth: 100,
                renderHeader: () => <StyledHeader label="Time Left In Quiz" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.timeLeftInQuiz} />
            },
            showCurentQuestion(showQuestion)
        ];
    };

    const getInitializeLeaderBoardColumns = (showQuestion: (code: string) => void): void => {
        const savedColumns = localStorage.getItem("LeaderBoardListColumns");
        if (savedColumns) {
            setIncludeColumns(JSON.parse(savedColumns));
            setFilteredColumns(
                initializeLeaderBoardColumns(showQuestion).filter((element) => savedColumns.includes(element.field))
            );
            return;
        }
        setFilteredColumns(initializeLeaderBoardColumns(showQuestion));
        setIncludeColumns(initializeLeaderBoardColumns(showQuestion).map((element) => element.field));
    };

    const sortedLeaderBoardDetails = useMemo((): LeaderBoardDetailsType[] => {
        const sortedList = orderBy(leaderBoardlist, ["score", "timeTakenInQuiz"], ["desc", "asc"]);
        return sortedList.map((item, index) => ({
            ...item,
            position: index + 1
        }));
    }, [leaderBoardlist]);

    return {
        leaderBoardlist,
        isContentLoading,
        filteredColumns,
        includeColumns,
        showManageColumnDialog,
        setShowManageColumnDialog,
        setIncludeColumns,
        initializeLeaderBoardColumns,
        setFilteredColumns,
        setSearchText,
        searchText,
        sortedLeaderBoardDetails,
        handleQuestionSelect,
        handleCloseQuestionModal,
        showQuestionDialog
    };
};

export const useEventLeaderboard = () => {
    const [searchText, setSearchText] = useState("");
    const [selectedTab, setSelectedTab] = useState("active");
    const [liveEventList, setLiveEventList] = useState<LeaderBoardEventListItem[]>([]);
    const [closeEventList, setCloseEventList] = useState<LeaderBoardEventListItem[]>([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetchCloseEventsList();
        fetchLiveEventsList();
    }, []);

    const fetchLiveEventsList = (): void => {
        fetchLiveEvent(true)
            .then((res) => {
                setLiveEventList(res);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchCloseEventsList = (): void => {
        fetchClosedEventsData(true)
            .then((res) => {
                setCloseEventList(res);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        searchText,
        setSearchText,
        selectedTab,
        setSelectedTab,
        liveEventList,
        closeEventList,
        setCloseEventList,
        isLoading
    };
};

export const useEventListHook = () => {
    const [selectedEvent, setSelectedEvent] = useState<LeaderBoardEventListItem | null>(null);

    const leaderboardEventListColumns = (): GridColDef[] => [
        {
            field: "eventName",
            headerName: "Event Name",
            flex: 1,
            renderHeader: () => <StyledHeader label="Event Name" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.eventName} />
        },
        {
            field: "startDate",
            width: 120,
            renderHeader: () => <StyledHeader label="Starting On" />,
            renderCell: (params: GridCellParams) => (
                <StyledColumn
                    fontWeight={"500"}
                    color={"#1F71B6"}
                    label={params.row.startDate !== "Invalid date" ? params.row.startDate : "-"}
                />
            )
        },
        {
            field: "quizName",
            flex: 1,
            renderHeader: () => <StyledHeader label="Game Title" />,
            renderCell: (params: GridCellParams) => <StyledColumn label={params.row.quizName} />
        },
        {
            field: "Action",
            headerName: "Action",
            width: 150,
            sortable: false,
            renderHeader: () => <StyledHeader label="Action" />,
            renderCell: (params: GridCellParams) => (
                <StyledActionColumn
                    isEditAvailaible={{
                        isAvailable: true,
                        onClick: () => {
                            setSelectedEvent(params.row);
                        }
                    }}
                />
            )
        }
    ];

    return {
        leaderboardEventListColumns,
        selectedEvent,
        setSelectedEvent
    };
};

export const useQuestionInfoHook = (eventLink: string, participantCode: string) => {
    const [question, setQuestion] = useState<QuestionInfoTooltipType | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        fetchQuestion();
    }, []);

    const fetchQuestion = () => {
        setIsLoading(true);
        fetchCurrentQuestion(eventLink, participantCode, true)
            .then((res) => {
                setQuestion(res);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return {
        question,
        isLoading
    };
};

export const useLeaderBoardDialog = (eventId: string) => {
    const [eventLink, setEventLink] = useState<string>("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = (): void => {
        getEventUrl(eventId).then((res) => setEventLink(res));
    };

    return {
        eventLink
    };
};

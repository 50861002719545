export const GeographyHardQuizData = {
    endings: [
        {
            scoreMin: 0,
            scoreMax: 4,
            text: "Seems like you didn't pay attention in geography class.",
            imageSrc: "https://i.imgur.com/tccvGSl.jpg"
        },
        {
            scoreMin: 5,
            scoreMax: 8,
            text: "Not bad, you know quite a bit.",
            imageSrc: "https://i.imgur.com/cqcYs9e.jpg"
        },
        {
            scoreMin: 9,
            scoreMax: 10,
            text: "Great job! You know a lot about planet Earth.",
            imageSrc: "https://i.imgur.com/XnYtpDE.jpg"
        }
    ],
    questions: [
        {
            questionId: 1,
            questionTitle: "Which country is this?",
            questionImageSrc: "https://i.imgur.com/vAQ1WVN.jpg",
            answerDescription: "Mexico borders the U.S.A to the north and Guatemala and Honduras to the south.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Cuba",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Mexico",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Venezuela",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Brazil",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 2,
            questionTitle: "What is this famous landscape known as?",
            questionImageSrc: "https://i.imgur.com/0WuhB3t.jpg",
            answerDescription:
                "These hills located in the Philippines are named for their chocolate-brown colour which emerges when the grass turns brown in the dry season.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Valley of a Thousand Hills",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Cave of the Mounds",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Rock City",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. The Chocolate Hills",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 3,
            questionTitle: "Manila is the capital of what country?",
            questionImageSrc: "https://i.imgur.com/9tu87Y1.jpg",
            answerDescription:
                "Did you know that this city was originally called Maynilad, a name derived from a flower that grows there?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Spain",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Italy",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. The Philippines",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Portugal",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 4,
            questionTitle: "This is the flag of which country?",
            questionImageSrc: "https://i.imgur.com/OW7bVZ1.jpg",
            answerDescription:
                "Did you know that this Central American country’s national animal is the white-tailed deer?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Puerto Rico",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Costa Rica",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Haiti",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Guatemala",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 5,
            questionTitle: "If you're looking for Mt. Kilimanjaro, you'll find it in ______.",
            questionImageSrc: "https://i.imgur.com/0nfnxsw.jpg",
            answerDescription:
                "This iconic mountain is actually a dormant volcano that scientists believe last erupted more than 300,000 years ago.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Tanzania",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. Egypt",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Senegal",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Nepal",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 6,
            questionTitle: "Where is Hawaii located?",
            questionImageSrc: "https://i.imgur.com/fDmocsV.jpg",
            answerDescription:
                "You'll find the Pacific Ocean to the left of the Americas and the Atlantic Ocean on the right.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. North Pacific Ocean",
                    isCorrect: true
                },
                {
                    id: 2,
                    text: "B. South Pacific Ocean",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Gulf of Mexico",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. North Atlantic Ocean",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 7,
            questionTitle: "Name the country that lies closest to the South pole.",
            questionImageSrc: "https://i.imgur.com/2zMLm4D.jpg",
            answerDescription: "Argentina lies further south than Australia.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Greenland",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Australia",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. Iceland",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Argentina",
                    isCorrect: true
                }
            ]
        },
        {
            questionId: 8,
            questionTitle: "Name this European country:",
            questionImageSrc: "https://i.imgur.com/k19LsXX.jpg",
            answerDescription: "Did you know that French is seventh on the list of most spoken languages in the world?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Spain",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. France",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. England",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Switzerland",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 9,
            questionTitle: "The Prime Meridian divides Earth into:",
            questionImageSrc: "https://i.imgur.com/wrZVnEo.jpg",
            answerDescription:
                "Also known as the Greenwich meridian, this imaginary vertical line encircles the Earth.",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Four seasons",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. North and South",
                    isCorrect: false
                },
                {
                    id: 3,
                    text: "C. East and West",
                    isCorrect: true
                },
                {
                    id: 4,
                    text: "D. Two seasons",
                    isCorrect: false
                }
            ]
        },
        {
            questionId: 10,
            questionTitle: "This is the flag of what Asian country?",
            questionImageSrc: "https://i.imgur.com/8oGL1Xe.jpg",
            answerDescription: "Did you know that Cambodia’s most famous landmark is Angkor Wat?",
            wrongAnswerText: "Incorrect",
            correctAnswerText: "Correct",
            options: [
                {
                    id: 1,
                    text: "A. Sri Lanka",
                    isCorrect: false
                },
                {
                    id: 2,
                    text: "B. Cambodia",
                    isCorrect: true
                },
                {
                    id: 3,
                    text: "C. Mongolia",
                    isCorrect: false
                },
                {
                    id: 4,
                    text: "D. Taiwan",
                    isCorrect: false
                }
            ]
        }
    ]
};

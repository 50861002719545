import { Grid } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { SiteConstants } from "../../../../../../constants/SiteConstant";
import { getPermissions } from "../../../../../../utils/PermissionUtils";
import { getErrorMessage } from "../../../../../../utils/APIErrorMessages";
import {
    StyledActionColumn,
    StyledColumn,
    StyledHeader
} from "../../../../../CommonComponent/v2/datagrid/DataGridStyle";
import { useAdminContext } from "../../../../context/AdminAuthContext";
import { AddHintType, HintListItemType } from "./Type";
import { addNewHint, deleteHint, getHintList, updateHint } from "./Util";

export const useQuestionHook = () => {
    const [selectedDeleteId, setSelectedDeleteId] = useState<number | null>(null);
    const [selectedEditItem, setSelectedEditItem] = useState<HintListItemType>();
    const [openAddModal, setOpenAddModal] = useState(false);
    const { userDetail } = useAdminContext();
    const permission: string[] | null = getPermissions(userDetail, "hint");
    const [hintList, setHintList] = useState<HintListItemType[]>([]);
    const { id } = useParams<{
        id: string;
    }>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchQuestionHints();
    }, []);

    const fetchQuestionHints = (forceRefresh = false) => {
        setIsLoading(true);
        getHintList(parseInt(id), forceRefresh)
            .then((res) => setHintList(res))
            .catch((err) => toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration))
            .finally(() => setIsLoading(false));
    };

    const onDeleteHint = () => {
        selectedDeleteId &&
            deleteHint(parseInt(id), selectedDeleteId)
                .then(() => fetchQuestionHints(true))
                .catch((err) => toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration))
                .finally(() => setSelectedDeleteId(null));
    };

    const hintListColumns: GridColDef[] = useMemo(
        () => [
            {
                field: "index",
                width: 20,
                renderHeader: () => <StyledHeader label="Id." />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.index} />
            },
            {
                field: "hint",
                flex: 1,
                renderHeader: () => <StyledHeader label="Hint" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.hint} />
            },
            {
                field: "scorePenalty",
                flex: 1,
                renderHeader: () => <StyledHeader label="Score Penalty" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.scorePenalty} />
            },
            {
                field: "timePenalty",
                flex: 1,
                renderHeader: () => <StyledHeader label="Time Penalty" />,
                renderCell: (params: GridCellParams) => <StyledColumn label={params.row.timePenalty} />
            },
            {
                field: "createdBy",
                flex: 1,
                renderHeader: () => <StyledHeader label="Created By" />,
                renderCell: (params: GridCellParams) => (
                    <Grid>
                        <StyledColumn label={params.row.createdBy} />
                        <StyledColumn label={`${params.row.createdDate}`} fontWeight={340} />
                    </Grid>
                )
            },
            {
                field: "lastUpdatedBy",
                flex: 1,
                renderHeader: () => <StyledHeader label="Updated By" />,
                renderCell: (params: GridCellParams) => (
                    <Grid>
                        <StyledColumn label={params.row.lastUpdatedBy} />
                        <StyledColumn label={`${params.row.lastUpdatedDate}`} fontWeight={340} />
                    </Grid>
                )
            },
            {
                field: "id",
                headerName: "Action",
                width: 180,
                renderHeader: () => <StyledHeader label="Action" />,
                renderCell: (params: GridCellParams) => (
                    <StyledActionColumn
                        isDeleteAvailaible={{
                            isAvailable: !!(permission && permission.includes("delete")),
                            onClick: () => setSelectedDeleteId(params.row.id)
                        }}
                        isEditAvailaible={{
                            isAvailable: !!(permission && permission.includes("update")),
                            onClick: () => {
                                setSelectedEditItem(params.row);
                            }
                        }}
                    />
                )
            }
        ],
        []
    );

    return {
        selectedDeleteId,
        selectedEditItem,
        setSelectedDeleteId,
        setSelectedEditItem,
        hintListColumns,
        hintList,
        isLoading,
        onDeleteHint,
        openAddModal,
        setOpenAddModal,
        fetchQuestionHints,
        permission
    };
};

export const useAddHintHook = (handleRefresh: () => void, handleClose: () => void, hintId?: number) => {
    const { id } = useParams<{
        id: string;
    }>();

    const saveHint = (value: AddHintType, setSubmitting: (submitting: boolean) => void) => {
        if (hintId) {
            updateHint(parseInt(id), hintId, value)
                .then(() => {
                    handleRefresh();
                    toast.success("Hint updated successfully", SiteConstants.successToastConfiguration);
                })
                .catch((err) => {
                    handleClose();
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => setSubmitting(false));
            return;
        }
        id &&
            addNewHint(parseInt(id), value)
                .then(() => {
                    handleRefresh();
                    toast.success("Hint added successfully", SiteConstants.successToastConfiguration);
                })
                .catch((err) => {
                    handleClose();
                    toast.error(getErrorMessage(err), SiteConstants.deleteToastConfiguration);
                })
                .finally(() => setSubmitting(false));
    };

    return {
        saveHint
    };
};

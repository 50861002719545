import { Grid } from "@mui/material";
import { useHistory } from "react-router";
import { Header1, PrimaryButton } from "../../../../CommonComponent/v2/CommonStyle";
import ArrowLeft from "../../../../Images/icons/arrowLeft.svg";
import { referenceBreadcrumbValues, validationSchema, addReferenceInitialValues } from "./Utils";
import BreadcrumbWrapper from "../../../../CommonComponent/v2/BreadCrumbWrapper";
import { useAddReference } from "./Hooks";
import TabWrapper from "../../../../CommonComponent/v2/tab/Tab";
import { Formik } from "formik";
import Details from "./details";
import Template from "./template";
import LoadingButtonWrapper from "../../../../CommonComponent/v2/button/LoadingButtonWrapper";

const AddReference = () => {
    const history = useHistory();
    const { addReferenceTabs, selectedTab, setSelectedTab, saveData } = useAddReference();

    const renderTab = () => {
        if (selectedTab == "detail") {
            return <Details />;
        }
        return <Template />;
    };

    return (
        <Grid px={"20px"}>
            <Grid container>
                <Grid item xs={12}>
                    <Header1>
                        <img
                            onClick={() => history.push("/admin/v2/referencelistmanager")}
                            src={ArrowLeft}
                            style={{ height: 18, marginRight: 10, cursor: "pointer" }}
                        />
                        Add new reference
                    </Header1>
                    <BreadcrumbWrapper links={referenceBreadcrumbValues} />
                </Grid>
                <Grid item xs={12} mt={"20px"}>
                    <TabWrapper
                        tabsOptions={addReferenceTabs}
                        selectedTab={selectedTab}
                        handleChange={setSelectedTab}
                    />
                </Grid>
                <Formik
                    initialValues={addReferenceInitialValues()}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        saveData(values, setSubmitting);
                    }}
                    enableReinitialize
                    validateOnMount>
                    {({ handleSubmit, isSubmitting }) => (
                        <Grid item xs={12}>
                            {renderTab()}
                            <Grid container justifyContent={"center"} mt={"40px"} mb={"20px"}>
                                <LoadingButtonWrapper loading={isSubmitting}>
                                    <PrimaryButton fontsize="16px" onClick={() => handleSubmit()}>
                                        Save
                                    </PrimaryButton>
                                </LoadingButtonWrapper>
                            </Grid>
                        </Grid>
                    )}
                </Formik>
            </Grid>
        </Grid>
    );
};

export default AddReference;

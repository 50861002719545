import { ApiConstants } from "../../../../../../constants/ApiConstant";
import { Client } from "../../../../../Base/Client";

export const removeReferenceBackgroundImage = (referenceId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.removeReferenceBackgroundImage(referenceId))
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());

export const removeReferenceProgressImage = (referenceId: number) =>
    Client.getInstance()
        .deleteData(ApiConstants.removeReferenceProgressImage(referenceId))
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());

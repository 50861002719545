import { Grid } from "@mui/material";
import React from "react";
import { CaptionText, PrimaryButton } from "../../../../../CommonComponent/v2/CommonStyle";
import ConfirmationBox from "../../../../../CommonComponent/v2/ConfirmationBox";
import DataGridWrapper from "../../../../../CommonComponent/v2/datagrid/DataGrid";
import { useGameAttachmentHook } from "./Hooks";
import UpdateAttachmentModal from "./updateattachment";

const GameAttachmentList = () => {
    const {
        isLoading,
        columns,
        attachmentList,
        selectedEditItem,
        setSelectedEditItem,
        fetchData,
        selectedDeleteItem,
        confirmDelete,
        setSelectedDeletItem
    } = useGameAttachmentHook();
    return (
        <Grid>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
                <Grid item xs={2}>
                    <CaptionText>Total attachments: {attachmentList.length} </CaptionText>
                </Grid>
                <Grid item xs={2}>
                    <PrimaryButton onClick={() => setSelectedEditItem("-1")} style={{ padding: "20px 20px" }}>
                        +Add attachments
                    </PrimaryButton>
                </Grid>
            </Grid>
            <Grid mt={"30px"} height={"500px"}>
                <DataGridWrapper columns={columns} loading={isLoading} rows={attachmentList as any} />
            </Grid>
            {selectedEditItem && (
                <UpdateAttachmentModal
                    attachmentId={selectedEditItem}
                    refreshData={() => fetchData(true)}
                    handleClose={() => setSelectedEditItem(null)}
                />
            )}
            {selectedDeleteItem && (
                <ConfirmationBox
                    title={`Delete "${selectedDeleteItem.name}" attachment`}
                    message={"Please confirm if you really want to delete this attachment"}
                    onConfirm={confirmDelete}
                    onClose={() => setSelectedDeletItem(null)}
                />
            )}
        </Grid>
    );
};

export default GameAttachmentList;

import { Grid } from "@mui/material";
import { FC } from "react";
import { deleteColor, primaryDarkColor } from "../../../../../../constants/ColorConstant";
import { extractHtmlContent } from "../../../../../../utils/JambarUtils";
import { CaptionText, SubText } from "../../../../../CommonComponent/v2/CommonStyle";
import { FREE_TEXT, MCQ, QuestionDetailType } from "../Type";
import { AnswerTypeLabel, QuestionTypeLabel } from "./Util";

type QuestionAnswerProps = {
    questionDetails?: QuestionDetailType;
};

const QuestionAnswer: FC<QuestionAnswerProps> = ({ questionDetails }) => {
    if (!questionDetails) {
        return <></>;
    }

    const options = [
        ...questionDetails.mcqOptions.correctOptionsText.map((item) => ({
            ...item,
            type: "correct"
        })),
        ...questionDetails.mcqOptions.wrongOptionsText.map((item) => ({
            ...item,
            type: "wrong"
        }))
    ].sort((a, b) => a.id - b.id);

    const renderAnswerOptions = () => {
        if (questionDetails.questionType === MCQ) {
            return (
                <>
                    <CaptionText mt={"30px"} color="#333333" fontWeight="600">
                        Answer Options
                    </CaptionText>
                    <CaptionText mt={"20px"}>
                        <SubText>
                            {options.map((item, index) => (
                                <p
                                    style={{ color: item.type === "wrong" ? deleteColor : "#65B44A" }}
                                    key={`wrong-${index}-${item}`}>
                                    Option {item.id} - {item.text}
                                </p>
                            ))}
                        </SubText>
                    </CaptionText>
                </>
            );
        }
        if (questionDetails.questionType === FREE_TEXT) {
            return (
                <>
                    <CaptionText mt={"30px"} color="#333333" fontWeight="600">
                        Answer
                    </CaptionText>
                    <CaptionText color={"#65B44A"}>
                        Correct: <SubText>{questionDetails.freeTextAnswer.join(", ")}</SubText>
                    </CaptionText>
                </>
            );
        }
        return <></>;
    };

    return (
        <Grid container mt={"15px"}>
            <Grid item xs={12}>
                <CaptionText color="#333333" fontWeight="600">
                    Answer Header
                </CaptionText>
                {extractHtmlContent(questionDetails.answerHeader) !== "" ? (
                    <p
                        dangerouslySetInnerHTML={{
                            __html: questionDetails.answerHeader
                        }}
                    />
                ) : (
                    <SubText style={{ fontStyle: "italic" }}>No answer header</SubText>
                )}
                <CaptionText mt={"30px"} color="#333333" fontWeight="600">
                    Answer type
                </CaptionText>
                <CaptionText mt={"10px"} color="#333333" fontWeight="400">
                    {QuestionTypeLabel[questionDetails.questionType]}
                </CaptionText>
                <CaptionText mt={"30px"} color="#333333" fontWeight="600">
                    Correct answer frequency
                </CaptionText>
                <CaptionText mt={"10px"} color="#333333" fontWeight="400">
                    {AnswerTypeLabel[questionDetails.answerType]}
                </CaptionText>
                {renderAnswerOptions()}
            </Grid>
            <Grid item xs={8}>
                <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                    Correct answer text
                </CaptionText>
                <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                    {extractHtmlContent(questionDetails.correctAnswerText).trim() ? (
                        <span dangerouslySetInnerHTML={{ __html: questionDetails.correctAnswerText }} />
                    ) : (
                        <SubText style={{ fontStyle: "italic" }}>No text found</SubText>
                    )}
                </CaptionText>
            </Grid>
            <Grid item xs={8}>
                <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                    Wrong answer text
                </CaptionText>
                <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                    {extractHtmlContent(questionDetails.wrongAnswerText).trim() ? (
                        <span dangerouslySetInnerHTML={{ __html: questionDetails.wrongAnswerText }} />
                    ) : (
                        <SubText style={{ fontStyle: "italic" }}>No text found</SubText>
                    )}
                </CaptionText>
            </Grid>
            <Grid item xs={8}>
                <CaptionText mt={"30px"} color={primaryDarkColor} fontWeight={600}>
                    Skipping answer text
                </CaptionText>
                <CaptionText mt={"10px"} color={primaryDarkColor} fontWeight={400}>
                    {extractHtmlContent(questionDetails.timeOutText).trim() ? (
                        <span dangerouslySetInnerHTML={{ __html: questionDetails.timeOutText }} />
                    ) : (
                        <SubText style={{ fontStyle: "italic" }}>No text found</SubText>
                    )}
                </CaptionText>
            </Grid>
        </Grid>
    );
};

export default QuestionAnswer;

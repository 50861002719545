import { BreadCrumbType, EditReferenceType } from "./Type";
import { Client } from "../../../../Base/Client";
import { ApiConstants } from "../../../../../constants/ApiConstant";

export const referenceBreadcrumbValues: BreadCrumbType[] = [
    {
        label: "Reference list",
        url: "/admin/v2/referenceList"
    },
    {
        label: "Edit reference"
    }
];
export const editReferenceTabs = [
    {
        label: "Details",
        value: "details",
        toShow: true
    },
    {
        label: "Items",
        value: "items",
        toShow: true
    },
    {
        label: "Attachments",
        value: "attachments",
        toShow: true
    },
    {
        label: "Template",
        value: "template",
        toShow: true
    }
];

const transformData = (data: any): EditReferenceType => ({
    title: data.name || "",
    centreHeading: data.centerHeading || "",
    attachmentTitle: data.attachmentTitle || "",
    backgroundColor: data.backgroundColor || "",
    buttonColor: data.baseButtonColor || "",
    backgroundImage: data.backgroundImageFileLocation || null,
    progressImage: data.progressImageFileLocation || null,
    description: data.description || "",
    coverImage: data.fileLocation || null,
    descriptionImage: data.descriptionImageFileLocation || null,
    referenceListCode: data.referenceLink || "",
    referendeListItems: data.reference_list_items?.map((ref: any) => ref.referenceItemId)?.join(",") || ""
});

export const reverseTransformData = (values: EditReferenceType): any => {
    const referenceData = new FormData();
    referenceData.append("name", values.title);
    referenceData.append("centerHeading", values.centreHeading);
    referenceData.append("attachmentTitle", values.attachmentTitle);
    referenceData.append("backgroundColor", values.backgroundColor);
    referenceData.append("baseButtonColor", values.buttonColor);
    referenceData.append("backgroundImage", values.backgroundImage as Blob);
    referenceData.append("progressImage", values.progressImage as Blob);
    referenceData.append("description", values.description);
    referenceData.append("referenceListImage", values.coverImage as Blob);
    referenceData.append("descriptionImage", values.descriptionImage as Blob);
    referenceData.append("referenceItems", values.referendeListItems);
    values.referenceListCode && referenceData.append("referenceLink", values.referenceListCode);
    return referenceData;
};

export const updateReferenceDetails = (id: number, values: EditReferenceType) =>
    Client.getInstance()
        .updateData(ApiConstants.updateReferenceApiUrl(id), reverseTransformData(values))
        .then(() => Promise.resolve())
        .catch((error) => Promise.reject(error));

export const getReferenceDetails = (id: number) =>
    Client.getInstance()
        .getData(ApiConstants.getReferenceDetailsApiUrl(id), true)
        .then((response) => Promise.resolve(transformData(response.data.data)))
        .catch((error) => Promise.reject(error));

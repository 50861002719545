import { toast } from "react-toastify";
import { array, mixed, object, string } from "yup";
import { ApiConstants } from "../../../../../constants/ApiConstant";
import { FieldValidation } from "../../../../../constants/FieldValidation";
import { SiteConstants } from "../../../../../constants/SiteConstant";
import { getErrorMessage } from "../../../../../utils/APIErrorMessages";
import { extractHtmlContent } from "../../../../../utils/JambarUtils";
import { Client } from "../../../../Base/Client";
import { BreadCrumbType } from "../../../../CommonComponent/v2/Type";
import { GameDetailType, GameQuestionListItem } from "./Type";

export const GamebreadcrumbValues = (isEdit: boolean): BreadCrumbType[] => [
    {
        label: "Game library",
        url: "/admin/v2/gamelibrary"
    },
    {
        label: "Games",
        url: "/admin/v2/gamelibrary"
    },
    {
        label: isEdit ? "Update game" : "Add new game"
    }
];

export const GameValidationSchema = object().shape({
    gameIdentification: object().shape({
        gameType: string().required("Game type is mandatory"),
        visibilityType: string().required("Visibility type is mandatory"),
        title: string().required("Title is mandatory")
    }),
    gameQuestionList: array().min(1, "Please select at least one challenge"),
    answerSettings: object().shape({
        gamePoints: object().shape({
            initialGamePoint: mixed().test("validateCorrectPoint", "Please enter a valid number", (value) => {
                return value ? FieldValidation.floatValidation(value.toString()) : true;
            }),
            correctAnswerPoint: mixed().test("validateCorrectPoint", "Please enter a valid number", (value) => {
                return value ? FieldValidation.floatValidation(value.toString()) : true;
            }),
            wrongAnswerPoint: mixed().test("validateWrongPoint", "Please enter a valid number", (value) => {
                return value ? FieldValidation.floatValidation(value.toString()) : true;
            }),
            skipAnswerPoint: mixed().when("skippingAllowed", {
                is: true,
                then: mixed()
                    .test("validateSkipPoint", "Please enter a valid number", (value) => {
                        return value ? FieldValidation.floatValidation(value.toString()) : true;
                    })
                    .required("Skip points is mandatory"),
                otherwise: mixed().test("validateSkipPoint", "Please enter a valid number", (value) => {
                    return value ? FieldValidation.floatValidation(value.toString()) : true;
                })
            })
        })
    })
});
export const GameInitialValue = (gameType: string, gameInfo?: GameDetailType): GameDetailType => {
    return {
        gameIdentification: {
            gameType: gameType,
            visibilityType: gameInfo?.gameIdentification.visibilityType || "private",
            title: gameInfo?.gameIdentification.title || "",
            identity: gameInfo?.gameIdentification.identity || []
        },
        gameDetails: {
            title: gameInfo?.gameDetails.title || "",
            timeLimit: {
                minutes: gameInfo?.gameDetails.timeLimit.minutes || "",
                seconds: gameInfo?.gameDetails.timeLimit.seconds || ""
            },
            description: gameInfo?.gameDetails.description || "",
            isConnectorQuiz: gameInfo?.gameDetails.isConnectorQuiz || false,
            gameSummary: gameInfo?.gameDetails.gameSummary || ""
        },
        gameQuestionList: gameInfo?.gameQuestionList || [],
        answerSettings: {
            answerResultText: gameInfo?.answerSettings.answerResultText || "",
            answerResultImage: gameInfo?.answerSettings.answerResultImage || "",
            nextButtonText: gameInfo?.answerSettings.nextButtonText || "",
            skippingAllowed: gameInfo?.answerSettings.skippingAllowed || false,
            totalPointsText: gameInfo?.answerSettings.totalPointsText || "",
            gamePoints: {
                initialGamePoint: gameInfo?.answerSettings.gamePoints.initialGamePoint || "",
                correctAnswerPoint: gameInfo?.answerSettings.gamePoints.correctAnswerPoint || "0",
                wrongAnswerPoint: gameInfo?.answerSettings.gamePoints.wrongAnswerPoint || "0",
                skipAnswerPoint: gameInfo?.answerSettings.gamePoints.skipAnswerPoint || ""
            }
        },
        moreSettings: {
            totalScoreVisibility: gameInfo?.moreSettings.totalScoreVisibility || false,
            showIndividualScore: gameInfo?.moreSettings.showIndividualScore || false,
            showProgressInPercentage: gameInfo?.moreSettings.showProgressInPercentage || false,
            showMainTimer: gameInfo?.moreSettings.showMainTimer || false,
            showHintCount: gameInfo?.moreSettings.showHintCount || false,
            showCorrectAnswerCount: gameInfo?.moreSettings.showCorrectAnswerCount || false,
            showWrongAnswerCount: gameInfo?.moreSettings.showWrongAnswerCount || false
        },
        gameTemplate: {
            gameScreenBackgroundImage: gameInfo?.gameTemplate.gameScreenBackgroundImage || "",
            progressImage: gameInfo?.gameTemplate.progressImage || "",
            title: {
                progressTitle: gameInfo?.gameTemplate.title.progressTitle || "",
                attachmentSectionTitle: gameInfo?.gameTemplate.title.attachmentSectionTitle || "",
                browserTabTitle: gameInfo?.gameTemplate.title.browserTabTitle || ""
            },
            answerScreen: {
                correctAnswerBackgroundImage: gameInfo?.gameTemplate.answerScreen.correctAnswerBackgroundImage || "",
                correctAnswerHeading: gameInfo?.gameTemplate.answerScreen.correctAnswerHeading || "",
                wrongAnswerBackgroundImage: gameInfo?.gameTemplate.answerScreen.wrongAnswerBackgroundImage || "",
                wrongAnswerHeading: gameInfo?.gameTemplate.answerScreen.wrongAnswerHeading || "",
                timeoutAnswerAnswerHeading: gameInfo?.gameTemplate.answerScreen.timeoutAnswerAnswerHeading || "",
                timeoutAnswerBackgroundImage: gameInfo?.gameTemplate.answerScreen.timeoutAnswerBackgroundImage || ""
            },
            colors: {
                backgroundColor: gameInfo?.gameTemplate.colors.backgroundColor || "",
                buttonColor: gameInfo?.gameTemplate.colors.buttonColor || "",
                correctAnswerBackgroundColor: gameInfo?.gameTemplate.colors.correctAnswerBackgroundColor || "",
                wrongAnswerBackgroundColor: gameInfo?.gameTemplate.colors.wrongAnswerBackgroundColor || "",
                primaryColor: gameInfo?.gameTemplate.colors.primaryColor || "",
                secondaryColor: gameInfo?.gameTemplate.colors.secondaryColor || "",
                tertiaryColor: gameInfo?.gameTemplate.colors.tertiaryColor || ""
            }
        }
    };
};

const getTime = (data: GameDetailType): string => {
    if (parseInt(data.gameDetails.timeLimit.minutes) === 59 && parseInt(data.gameDetails.timeLimit.seconds) === 60) {
        return `01:00:00`;
    }
    if (parseInt(data.gameDetails.timeLimit.minutes) >= 60 && parseInt(data.gameDetails.timeLimit.seconds) === 60) {
        return `${Math.floor(parseInt(data.gameDetails.timeLimit.minutes) / 60)}:${
            (parseInt(data.gameDetails.timeLimit.minutes) % 60) + 1
        }:00`;
    }
    if (parseInt(data.gameDetails.timeLimit.minutes) < 60 && parseInt(data.gameDetails.timeLimit.seconds) === 60) {
        return `00:${data.gameDetails.timeLimit.minutes + 1}:00`;
    }
    return `${
        data.gameDetails.timeLimit.minutes ? Math.floor(parseInt(data.gameDetails.timeLimit.minutes) / 60) : "00"
    }:${data.gameDetails.timeLimit.minutes ? parseInt(data.gameDetails.timeLimit.minutes) % 60 : "00"}:${
        data.gameDetails.timeLimit.seconds ? data.gameDetails.timeLimit.seconds : "00"
    }`;
};

const reverseTransformData = (data: GameDetailType): any => {
    const quizSettingsFormData = new FormData();
    const timelimit =
        data.gameDetails.timeLimit.minutes || data.gameDetails.timeLimit.seconds
            ? {
                  timeLimit: getTime(data)
              }
            : {};

    const settingsData: any = {
        title: data.gameIdentification.title,
        accessibilityScope: data.gameIdentification.visibilityType,
        type: data.gameIdentification.gameType,
        accessibleIdentityIds: data.gameIdentification.identity.join(";"),
        allowSkipping: data.answerSettings.skippingAllowed ? 1 : 0,
        showMainTimer: data.moreSettings.showMainTimer ? 1 : 0,
        showHintCount: data.moreSettings.showHintCount ? 1 : 0,
        showCorrectAnswerCount: data.moreSettings.showCorrectAnswerCount ? 1 : 0,
        showWrongAnswerCount: data.moreSettings.showWrongAnswerCount ? 1 : 0,
        questions: data.gameQuestionList.map((element) => element.id).join(","),
        correctPoints: data.answerSettings.gamePoints.correctAnswerPoint || 0,
        wrongPoints: data.answerSettings.gamePoints.wrongAnswerPoint || 0,
        skipPoints: data.answerSettings.skippingAllowed ? data.answerSettings.gamePoints.skipAnswerPoint : null,
        showIndividualScore: data.moreSettings.showIndividualScore ? 1 : 0,
        description: data.gameDetails.description,
        summaryPageText: data.gameDetails.gameSummary,
        ...timelimit,
        initialScore:
            data.answerSettings.gamePoints.initialGamePoint === ""
                ? null
                : data.answerSettings.gamePoints.initialGamePoint,
        nextButtonText: data.answerSettings.nextButtonText,
        showProgressInPercentage: data.moreSettings.showProgressInPercentage,
        answerResultImage: data.answerSettings.answerResultImage as Blob,
        answerResultText: data.answerSettings.answerResultText,
        displayTitle: data.gameDetails.title,
        isConnectorQuiz: data.gameDetails.isConnectorQuiz,
        totalPointsText: data.answerSettings.totalPointsText
    };

    for (const key in settingsData) {
        quizSettingsFormData.append(key, settingsData[key]);
    }

    return quizSettingsFormData;
};

const reverseTransformTemplateData = (data: GameDetailType): FormData => {
    const quizTemplateData = new FormData();
    const templateData: any = {
        attachmentTitle: data.gameTemplate.title.attachmentSectionTitle,
        progressTitle: data.gameTemplate.title.progressTitle,
        backgroundImage:
            typeof data.gameTemplate.gameScreenBackgroundImage === "string"
                ? null
                : (data.gameTemplate.gameScreenBackgroundImage as Blob),
        progressImage:
            typeof data.gameTemplate.progressImage === "string" ? null : (data.gameTemplate.progressImage as Blob),
        baseColor: data.gameTemplate.colors.backgroundColor,
        baseButtonColor: data.gameTemplate.colors.buttonColor,
        browserTabTitle: data.gameTemplate.title.browserTabTitle,
        wrongAnswerBackgroundColor: data.gameTemplate.colors.wrongAnswerBackgroundColor,
        correctAnswerBackgroundColor: data.gameTemplate.colors.correctAnswerBackgroundColor,
        wildgoatPrimaryColor: data.gameTemplate.colors.primaryColor,
        wildgoatSecondaryColor: data.gameTemplate.colors.secondaryColor,
        wildgoatTertiaryColor: data.gameTemplate.colors.tertiaryColor,
        correctAnswerHeading: data.gameTemplate.answerScreen.correctAnswerHeading,
        wrongAnswerHeading: data.gameTemplate.answerScreen.wrongAnswerHeading,
        timeoutAnswerHeading: data.gameTemplate.answerScreen.timeoutAnswerAnswerHeading,
        timeoutAnswerImage:
            typeof data.gameTemplate.answerScreen.timeoutAnswerBackgroundImage === "string"
                ? null
                : (data.gameTemplate.answerScreen.timeoutAnswerBackgroundImage as Blob),
        totalScoreVisibility: data.moreSettings.totalScoreVisibility,
        correctAnswerImage:
            typeof data.gameTemplate.answerScreen.correctAnswerBackgroundImage === "string"
                ? null
                : (data.gameTemplate.answerScreen.correctAnswerBackgroundImage as Blob),
        wrongAnswerImage:
            typeof data.gameTemplate.answerScreen.wrongAnswerBackgroundImage === "string"
                ? null
                : (data.gameTemplate.answerScreen.wrongAnswerBackgroundImage as Blob)
    };
    for (const key in templateData) {
        quizTemplateData.append(key, templateData[key]);
    }
    return quizTemplateData;
};

export const saveQuiz = (data: GameDetailType) => {
    return Client.getInstance()
        .createData(ApiConstants.addQuizApiUrl(), reverseTransformData(data))
        .then((res) => {
            toast.success("Game added", SiteConstants.successToastConfiguration);
            return Promise.resolve(res.data.data.id);
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const updateQuizDetail = (quizId: string, data: GameDetailType) => {
    return Client.getInstance()
        .updateData(ApiConstants.updateQuizDetailApiUrl(parseInt(quizId)), reverseTransformData(data))
        .then(() => {
            toast.success("Game updated", SiteConstants.successToastConfiguration);
            return Promise.resolve();
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
            return Promise.reject();
        });
};

export const saveTemplate = (data: GameDetailType, quizId: number) => {
    return Client.getInstance()
        .createData(ApiConstants.addQuizTemplateDetailApiUrl(quizId), reverseTransformTemplateData(data))
        .then(() => {
            return Promise.resolve();
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
        });
};

export const updateTemplate = (data: GameDetailType, quizId: number) => {
    return Client.getInstance()
        .updateData(ApiConstants.addQuizTemplateDetailApiUrl(quizId), reverseTransformTemplateData(data))
        .then(() => {
            return Promise.resolve();
        })
        .catch((error) => {
            toast.error(getErrorMessage(error), SiteConstants.deleteToastConfiguration);
        });
};

const transformData = (data: any): GameDetailType => {
    const quizTimeLimit = data?.timeLimit?.split(":") || [0, 0, 0];
    const questions: GameQuestionListItem[] = data.quiz_questions.map((quizQuestion: any, index: number) => ({
        id: quizQuestion.question.id,
        questionTitle: quizQuestion.question.title,
        tags: quizQuestion.question.questionTags.map((tag: any) => ({ id: tag.tag.value, value: tag.tag.value })),
        index: index + 1
    }));

    return {
        gameIdentification: {
            gameType: data?.type || "",
            identity: data?.accessibleIdentityIds || [],
            title: extractHtmlContent(data?.title || ""),
            visibilityType: data?.accessibilityScope || "private"
        },
        gameDetails: {
            title: extractHtmlContent(data?.displayTitle || ""),
            description: data?.summaryPageText || "",
            gameSummary: data?.summaryPageText || "",
            isConnectorQuiz: data?.isConnectorQuiz || false,
            timeLimit: {
                minutes: (parseInt(quizTimeLimit[1]) + parseInt(quizTimeLimit[0]) * 60).toString(),
                seconds: quizTimeLimit[2].toString()
            }
        },
        answerSettings: {
            answerResultText: data?.answerResultText || "",
            answerResultImage: data?.answerResultImageFileLocation || "",
            gamePoints: {
                correctAnswerPoint: data?.correctPoints || "",
                initialGamePoint: data?.initialScore || "",
                skipAnswerPoint: data?.skipPoints || "",
                wrongAnswerPoint: data?.wrongPoints || ""
            },
            nextButtonText: data?.nextButtonText || "",
            skippingAllowed: data?.allowSkipping || false,
            totalPointsText: data?.totalPointsText || ""
        },
        gameTemplate: {
            answerScreen: {
                correctAnswerBackgroundImage: data?.quizConfig?.correctAnswerImageLocation || "",
                correctAnswerHeading: data?.quizConfig?.correctAnswerHeading || "",
                timeoutAnswerAnswerHeading: data?.quizConfig?.timeoutAnswerHeading || "",
                timeoutAnswerBackgroundImage: data?.quizConfig?.timeoutAnswerImageLocation || "",
                wrongAnswerBackgroundImage: data?.quizConfig?.wrongAnswerImageLocation || "",
                wrongAnswerHeading: data?.quizConfig?.wrongAnswerHeading || ""
            },
            colors: {
                backgroundColor: data?.quizConfig?.baseColor || "",
                buttonColor: data?.quizConfig?.baseButtonColor || "",
                correctAnswerBackgroundColor: data?.quizConfig?.correctAnswerBackgroundColor || "",
                wrongAnswerBackgroundColor: data?.quizConfig?.wrongAnswerBackgroundColor || "",
                primaryColor: data?.quizConfig?.wildgoatPrimaryColor || "",
                secondaryColor: data?.quizConfig?.wildgoatSecondaryColor || "",
                tertiaryColor: data?.quizConfig?.wildgoatTertiaryColor || ""
            },
            gameScreenBackgroundImage: data?.quizConfig?.backgroundImageFileLocation || "",
            progressImage: data?.quizConfig?.progressImageFileLocation || "",
            title: {
                attachmentSectionTitle: data?.quizConfig?.attachmentTitle || "",
                browserTabTitle: data?.quizConfig?.browserTabTitle || "",
                progressTitle: data?.quizConfig?.progressTitle || ""
            }
        },
        gameQuestionList: questions,
        moreSettings: {
            showCorrectAnswerCount: data?.showCorrectAnswerCount ? true : false,
            showHintCount: data?.showHintCount ? true : false,
            showIndividualScore: data?.showIndividualScore || false,
            showMainTimer: data?.showMainTimer ? true : false,
            showProgressInPercentage: data?.showProgressInPercentage ? true : false,
            showWrongAnswerCount: data?.showWrongAnswerCount ? true : false,
            totalScoreVisibility: data?.quizConfig?.totalScoreVisibility ? true : false
        }
    };
};
export const getGameDetailData = (gameId: string) => {
    return Client.getInstance()
        .getData(ApiConstants.getQuizDetailApiUrl(parseInt(gameId)), true)
        .then((res) => Promise.resolve(transformData(res.data.data)));
};

export const displayErrors = (errors: any) => {
    Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        if (typeof errorMessage === "object" && errorMessage !== null) {
            displayErrors(errorMessage);
        } else {
            toast.error(`${errorMessage}`);
        }
    });
};

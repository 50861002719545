import { Stack } from "@fluentui/react";
import React from "react";
import { useLocation } from "react-router";
import logo from "../Images/logo.svg";
import { ThemedMediumStackTokens, ThemedSmall2StackTokens } from "../../constants/Styles";
import { ThankYouPageText } from "./Style";

const ThankYouPage = () => {
    const info: any = useLocation();

    return (
        <div className={"event-main-container"}>
            <Stack horizontalAlign={"center"} verticalAlign={"center"} className={"height-100per"}>
                <Stack className={"event-parent"} tokens={ThemedMediumStackTokens}>
                    <Stack horizontalAlign={"center"} className={"event-logo"}>
                        <img src={logo} alt={"logo"} />
                    </Stack>
                    <Stack tokens={ThemedMediumStackTokens} className={"event-child"}>
                        <Stack tokens={ThemedSmall2StackTokens}>
                            {info.state.eventClosed ? (
                                <ThankYouPageText>
                                    Jambar's Virtual Team Building Event has been closed
                                </ThankYouPageText>
                            ) : (
                                <ThankYouPageText>
                                    Thank you for playing one of our virtual experiences. See you again soon.
                                </ThankYouPageText>
                            )}
                            <ThankYouPageText>Now you can close the window</ThankYouPageText>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </div>
    );
};

export default ThankYouPage;
